import { SVGProps, useEffect, useState } from "react"
import SVG from 'react-inlinesvg';
import { ReactImageTint } from 'react-image-tint';
import { ReactSVG } from 'react-svg'
import { Add } from "../assets/IconComponents";

interface IIconProps {
    data?: string
    viewBox?: string
    size?: string | undefined
    width?: string | undefined
    height?: string | undefined
    color?: string
    stroke?: string
    fill?: string
    className?: string
    component?: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | undefined
}
function Icon(props: IIconProps) {
    const { component: Comp = Add, color } = props

    return (
        <div className={props.className} style={{width: props.width ?? props.size ?? 20, height: props.height ?? props.size ?? 20}}>
            <Comp fill={color} viewBox={props.viewBox} width={props.width} height={props.height}/>
        </div>
    );
}

export default Icon;
