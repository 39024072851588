import { FunctionComponent, useEffect, useState } from "react";
import ToggleButton from "../../../components/ToggleButton";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setNewContent } from "../../../store/contentSlice";
import { ContentWizardView } from "../../../models/ContentWizardView";
import { FileProps } from "../../../services/BrandApi";
import LogoSelectorIcon from "../../../assets/IconComponents/LogoSelectorIcon";
import UploadCompanyMaterialNew from "../../../components/UploadFile/UploadCompanyMaterialNew";
import { useLazyFindById4Query } from "../../../services/BrandApi";
import { setSelectedTechnicalDocument } from "../../../store/contentSlice";


interface TechnicalDocumentSelectorProps {
    className?: string
    headerClassName?: string
    contentClassName?: string
    referenceFileSelectionList: FileProps[]
    onSelectionChanged?: (data: FileProps[]) => void
    onListingChanged?: (data: FileProps[]) => void
    isInSide?: boolean
}

const TechnicalDocumentSelector: FunctionComponent<TechnicalDocumentSelectorProps> = (
    props: TechnicalDocumentSelectorProps
) => {
    const dispatch = useAppDispatch();
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const newContent = useAppSelector((k) => k.content.newContent);
    const selectedTechnicalDocument = useAppSelector((k) => k.content.selectedTechnicalDocument)
    const [selectionList, setSelectionList] = useState<FileProps[]>([])
    const selectedBrandId = useAppSelector((k) => k.persisted.lastSelectedBrandId);
    const [technicalLinks, setTechnicalLinks] = useState<FileProps[]>([])
    const [getBrand, { data: brand, isLoading: brandLoading }] = useLazyFindById4Query()


    useEffect(() => {
        setTechnicalLinks(brand?.technical_links ? brand?.technical_links : [])
    }, [brand])

    useEffect(() => {
        if (selectedTechnicalDocument) {
            console.log(selectedTechnicalDocument)
            setCollapsed(false);
        }
    }, [selectedTechnicalDocument])

    useEffect(() => {
        if (selectedBrandId) {
            getBrand({ id: selectedBrandId })
        }
    }, [selectedBrandId])

    useEffect(() => {
        dispatch(setSelectedTechnicalDocument(!collapsed))
    }, [collapsed])

    useEffect(() => {
        if (selectionList.length === 0) {
            setSelectionList(props.referenceFileSelectionList)
        }
    }, [props.referenceFileSelectionList])

    useEffect(() => {
        if (selectionList.length > 0 && newContent?.reference_files && selectionList.filter(k => k.id === newContent.referenceFileId).length === 0) {
            setTechnicalLinks(selectionList)
            dispatch(
                setNewContent({
                    ...newContent,
                    referenceFileId: selectionList[0].id,
                    reference_files: selectionList
                })
            );
        }

    }, [selectionList])


    function selectReferenceFile() {
        if (!newContent?.reference_files) {
            if (props.referenceFileSelectionList.length > 0) {
                dispatch(
                    setNewContent({
                        ...newContent,
                        reference_files: props.referenceFileSelectionList
                    })
                );
            }
        } else {
            if (props.referenceFileSelectionList.length > 0) {
                if (props.referenceFileSelectionList.filter(k => k.id === newContent.referenceFileId).length === 0) {
                    dispatch(
                        setNewContent({
                            ...newContent,
                            reference_files: props.referenceFileSelectionList
                        })
                    );
                }
            }
        }
    }

    return <>
        <div className={`w-full p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-4 inline-flex ${props.className} ${collapsed ? "" : "h-[230px]"}`}>
            <div className={`w-full flex justify-between items-center ${props.headerClassName}`}>
                <div className="justify-start items-center gap-2.5 flex">
                    <LogoSelectorIcon fill={collapsed ? "#8E8E8E" : "#FF5555"} className="flex shrink-0" />
                    <text className="text-sm font-semibold text-black w-72 sm:w-full truncate text-ellipsis overflow-hidden">
                        { "Add Reference Doc"}
                    </text>
                </div>

                {
                    < ToggleButton isChecked={selectedTechnicalDocument}
                        handleCheckBoxChange={function (checked: boolean): void {
                            setCollapsed(!checked);
                            if (checked) {
                                selectReferenceFile();
                            } else {
                                dispatch(setNewContent({
                                    ...newContent,
                                    reference_files: undefined,
                                }))
                            }
                        }}
                        checkedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                        checkedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition translate-x-full"
                        uncheckedBoxClassName="box block h-6 w-[40px] rounded-full bg-neutral-200 border-2 border-neutral"
                        uncheckedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-neutral-400 transition"
                    />
                }
            </div>

            {!collapsed && (<div className={`self-stretch h-[142px] flex-col justify-start items-start gap-2.5 flex ${props.contentClassName}`}>
                {
                    <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                        <UploadCompanyMaterialNew
                            size={"small"}
                            maxFileCount={10}
                            uploadedFiles={selectionList.length ? selectionList.slice(0,2) : technicalLinks}
                            description="Upload .txt .pdf .pptx or .doc files."
                            filesChanged={async (f: any) => {
                                if (f && f.length > 0) {
                                    if(!selectionList.length){
                                        setTechnicalLinks(
                                            [{id:f[0].uploadedId, name: f[0].name, summary: f[0].summary, uploaded: true}, ...technicalLinks]
                                        )
                                    }
                                    else if(selectionList.length === 2){
                                        selectionList.pop();
                                        setSelectionList([{id:f[0].uploadedId, name: f[0].name, summary: f[0].summary, uploaded: true}, ...selectionList])
                                    }
                                    else {
                                        setSelectionList([{id:f[0].uploadedId, name: f[0].name, summary: f[0].summary, uploaded: true}, ...selectionList])
                                    }
                                }
                            }}


                            selectionChanged={(pickedFiles) => {
                                    console.log(`picked file ids => ${JSON.stringify(pickedFiles)}`)
                                    console.log(pickedFiles)
                                    dispatch(
                                        setNewContent({
                                            ...newContent,
                                            reference_files: pickedFiles,
                                        })
                                    );
                            }}
                            selectionEnabled={true}
                            maxSelectionSize={2}
                            selectedFiles={selectionList}
                            maxThumbnailCount={props.isInSide ? 1 : 4}
                            addNewFileToFront={true}
                            uploadEnabled={true}
                            brandHubUpload={false}
                            deleteEnabled={false}
                        />
                    </div>
                }

                {!props.isInSide ?
                    <div
                        onClick={() => {
                            dispatch(setNewContent({
                                ...newContent,
                                wizardView: ContentWizardView.technicalDocumentsLibrary
                            }));
                        }}
                        className="self-stretch text-center text-red text-[13px] font-normal font-['Inter Tight'] underline leading-none cursor-pointer"
                    >
                        <a>View Custom Trainings</a>
                    </div> : <></>
                }
            </div>
            )}
        </div >
    </>
}

export default TechnicalDocumentSelector;
