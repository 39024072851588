import * as React from "react";
import type { SVGProps } from "react";
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.308 10.9508C16.0109 10.7329 15.8334 10.3829 15.8334 10.0142C15.8334 9.64541 16.0109 9.29541 16.3075 9.07791L17.68 8.07166C17.8988 7.91083 17.9892 7.62791 17.9038 7.36999C17.5505 6.30833 16.9917 5.33999 16.2425 4.49124C16.0621 4.28791 15.7713 4.22374 15.5225 4.33291L13.9771 5.01249C13.6405 5.16083 13.2484 5.13958 12.9288 4.95499C12.6096 4.77083 12.3955 4.44249 12.355 4.07624L12.1692 2.37958C12.1396 2.10958 11.9388 1.88958 11.673 1.83499C10.5909 1.61374 9.45379 1.60999 8.35254 1.83083C8.08504 1.88458 7.88379 2.10458 7.85421 2.37583L7.67004 4.06166C7.62962 4.42833 7.41546 4.75666 7.09546 4.94083C6.77629 5.12499 6.38546 5.14708 6.04754 4.99833L4.49379 4.31499C4.24712 4.20583 3.95546 4.26916 3.77504 4.47208C3.02337 5.31749 2.46212 6.28458 2.10504 7.34541C2.01837 7.60291 2.10837 7.88749 2.32796 8.04874L3.69171 9.04874C3.98921 9.26708 4.16671 9.61707 4.16671 9.98583C4.16671 10.3546 3.98921 10.7046 3.69254 10.9221L2.32004 11.9283C2.10129 12.0892 2.01087 12.3721 2.09629 12.63C2.44962 13.6917 3.00837 14.66 3.75754 15.5087C3.93796 15.7125 4.22962 15.7771 4.47754 15.6671L6.02296 14.9875C6.35962 14.8392 6.75129 14.8604 7.07129 15.045C7.39046 15.2292 7.60462 15.5575 7.64504 15.9237L7.83087 17.6204C7.86046 17.8904 8.06129 18.1104 8.32712 18.165C8.87462 18.2767 9.43712 18.3333 10 18.3333C10.5492 18.3333 11.1034 18.2779 11.6471 18.1687C11.9146 18.115 12.1159 17.895 12.1455 17.6237L12.33 15.9379C12.3705 15.5712 12.5846 15.2429 12.9046 15.0587C13.2238 14.875 13.615 14.8533 13.9525 15.0012L15.5063 15.6846C15.7538 15.7942 16.0446 15.7308 16.225 15.5275C16.9767 14.6821 17.538 13.715 17.895 12.6542C17.9817 12.3967 17.8917 12.1121 17.6721 11.9508L16.308 10.9508ZM10 12.9167C8.38921 12.9167 7.08337 11.6108 7.08337 9.99999C7.08337 8.38916 8.38921 7.08333 10 7.08333C11.6109 7.08333 12.9167 8.38916 12.9167 9.99999C12.9167 11.6108 11.6109 12.9167 10 12.9167Z"
      fill={props.fill ?? "#8E8E8E"}
    />
  </svg>
);
export default SvgSettings;






