import { t } from "i18next";
import { SVGProps, useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";
import { Facebook, FacebookColor, Instagram, InstagramColor, Linkedin, LinkedinColor, Pinterest, PinterestColor, TwitterColor } from "../../assets/IconComponents";
import ChannelContentSelectorPopup from "./ChannelContentSelectorPopup";
import SvgContentType from "../../assets/IconComponents/ContentType";
import Tag from "../Tag";
import Twitter from "../../assets/IconComponents/Twitter";

export interface IChannelContentData {
    id: string,
    title: string,
    icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
    colorIcon: (props: SVGProps<SVGSVGElement>) => JSX.Element
}

export const ChannelContentDatas = {
    "facebook": [{ id: "1", title: "Post", icon: Facebook, colorIcon: FacebookColor }],
    "twitter": [{ id: "2", title: "Single Post", icon: Twitter, colorIcon: TwitterColor }, { id: "3", title: "Thread", icon: Twitter, colorIcon: TwitterColor }],
    "instagram": [{ id: "4", title: "Caption", icon: Instagram, colorIcon: InstagramColor }],
    "linkedin": [{ id: "5", title: "Post", icon: Linkedin, colorIcon: LinkedinColor }],
    "pinterest": [{ id: "6", title: "Pinterest Blog", icon: Pinterest, colorIcon: PinterestColor }, { id: "7", title: "Blog", icon: Pinterest, colorIcon: PinterestColor }],
}

export interface IChannelContentSelectorProps {
    channelContentsSelected: (data: IChannelContentData[] | undefined) => void
    filterChannels: string[]
    defaultValues?: string[]
    closeOnSelect?: boolean
    deselectable?: boolean
    className?: string
}

function ChannelSelector(props: IChannelContentSelectorProps) {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [selectedChannelContents, setSelectedChannelContents] = useState<IChannelContentData[] | undefined>()
    const [channelContents, setChannelContents] = useState<IChannelContentData[]>([])

    useEffect(() => {
        const filteredChannelContent = props.filterChannels.flatMap(channel => ChannelContentDatas[channel.toLowerCase()])
        setChannelContents(filteredChannelContent)
    }, [props.filterChannels])


    useEffect(() => {
        if (props.defaultValues) {
            const filteredSelectedChannelContents = channelContents.filter(k => props.defaultValues?.findIndex(a => a === k.id) !== -1)
            if (filteredSelectedChannelContents) {
                setSelectedChannelContents(filteredSelectedChannelContents)
            }
        }
    }, [props.defaultValues, channelContents])

    return (
        <>
            <Popover
                isOpen={isPopoverOpen}
                positions={['bottom']}
                align="start"
                padding={10}
                onClickOutside={() => { setIsPopoverOpen(false) }}
                content={
                    <ChannelContentSelectorPopup
                        deselectable={props.deselectable}
                        data={channelContents}
                        closePopup={() => setIsPopoverOpen(false)}
                        selectedChannelContents={selectedChannelContents}
                        selectedChannelContentsChanged={(e) => {
                            if (e) {
                                setSelectedChannelContents(e)
                                props.channelContentsSelected(e)
                                if (props.closeOnSelect) {
                                    setIsPopoverOpen(false)
                                }
                            }
                        }}
                    />}
            >
                <div className={`bg-white border rounded-[7px] px-2 py-2.5 justify-start items-center gap-[5px] flex flex-row ${props.className}`} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                    <button
                        onClick={() => {
                        }}>
                        <div className="flex flex-row gap-[5px]">
                            <SvgContentType width={20} height={20} fill={selectedChannelContents !== undefined ? "#121315" : "#999999"} ></SvgContentType>
                            <text className="text-black text-sm font-semibold font-['Inter Tight'] leading-tight">{t('content')}</text>
                            {(selectedChannelContents !== undefined && selectedChannelContents.length > 0) &&
                                <div className="w-[20px] h-[20px] rounded-full bg-red inline-flex justify-center items-center text-H7">{selectedChannelContents.length}</div>}
                        </div>
                    </button>
                    {/* <div className="flex flex-row gap-[7px] h-[30px] justify-start items-center ">
                        {(selectedChannelContents !== undefined && selectedChannelContents.length > 0) &&
                            selectedChannelContents.map((content) => {
                                return <Tag tagText={content.title} onClose={() => {
                                    const reducedChannels = selectedChannelContents.filter(c => c.id !== content.id)
                                    setSelectedChannelContents([...reducedChannels])
                                    props.channelContentsSelected([...reducedChannels])
                                }} />
                            })
                        }
                    </div> */}
                </div >
            </Popover >
        </>
    );
}

export default ChannelSelector;
