import * as React from "react";
import type { SVGProps } from "react";
const SvgHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M3.542 2.083a.625.625 0 0 0-.625.625v.625h-.625a.625.625 0 0 0-.625.625v12.917c0 .345.28.625.625.625h15.416c.345 0 .625-.28.625-.625V3.958a.625.625 0 0 0-.625-.625h-.625v-.625a.625.625 0 0 0-.625-.625H12.5A3.12 3.12 0 0 0 10 3.341a3.12 3.12 0 0 0-2.5-1.258H3.542Zm.625 1.25H7.5c1.034 0 1.875.842 1.875 1.875v9.167a.625.625 0 1 0 1.25 0V5.208c0-1.033.841-1.875 1.875-1.875h3.333V15H12.5c-1.01 0-1.923.49-2.5 1.263A3.117 3.117 0 0 0 7.5 15H4.167V3.333Zm8.958 2.5a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667Zm-.01 2.491a.625.625 0 0 0-.615.634v3.334a.624.624 0 1 0 1.25 0V8.958a.625.625 0 0 0-.635-.634Z" />
  </svg>
);
export default SvgHelp;
