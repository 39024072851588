import ReviewColumn from "./ReviewColumn";
import Reviews from "./Reviews";

export interface ITestimonialsProps {
    className: string
}

function Testimonials(props: ITestimonialsProps) {
    let columns = splitArray(Reviews, 3);
    //@ts-ignore
    let lastColumns = splitArray(columns[2], 2);

    function splitArray<T>(array: T[], numParts: number) {
        let result: T[][] = [];
        for (let i = 0; i < array.length; i++) {
            let index = i % numParts;
            if (!result[index]) {
                result[index] = [];
            }
            result[index].push(array[i]);
        }
        return result;
    }

    return (<>
        <section className={`testimonials white-elevated-section relative ${props.className || ''}`}>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                    <h2
                        className="mx-auto max-w-4xl font-display text-4xl font-medium text-sky-800 sm:text-4xl relative"
                    >
                        Changing The Marketing Game With Evercopy
                    </h2>
                    <p className="mt-2 text-lg text-gray-600 sm:text-center">
                        Thousands of entrepreneurs have doubled their content output in the last 30 days
                    </p>
                </div>
            </div>

            <div
                className="relative  mt-16 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 sm:mt-20 md:grid-cols-2 lg:grid-cols-3"
            >
                <ReviewColumn
                    reviews={[...columns[0], ...lastColumns.flat(), ...columns[1]]}
                    msPerPixel={10}
                    reviewClassName={(index: number) => {
                        return [
                            index >= columns[0].length + lastColumns[0].length && 'md:hidden',
                            index >= columns[0].length && 'lg:hidden'
                        ]
                            .filter(Boolean)
                            .join(' ')
                    }}
                />
                <ReviewColumn
                    reviews={[...columns[1], ...lastColumns[1]]}
                    className="hidden md:block"
                    reviewClassName={(reviewIndex: number) => (reviewIndex >= columns[1].length ? 'lg:hidden' : '')}
                    msPerPixel={15}
                />
                <ReviewColumn reviews={lastColumns.flat()} className="hidden lg:block" msPerPixel={10} />
                <div
                    className="pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-slate-100"
                />
                <div
                    className="pointer-events-none absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-slate-100"
                />
            </div>
        </section>
    </>);
}

export default Testimonials;