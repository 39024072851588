import * as React from "react";
import type { SVGProps } from "react";
const SvgConnectBrandChannelsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.1" width="28" height="28" rx="4" fill="#FF5555" />
    <rect
      x="7"
      y="7"
      width="14"
      height="14"
      fill="url(#pattern_AHdV7HgKQBeE3RPeig9sx9qwpimAa)"
    />
    <defs>
      <pattern
        id="pattern_AHdV7HgKQBeE3RPeig9sx9qwpimAa"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#image0_6505_28504" transform="scale(0.0104167)" />
      </pattern>
      <image
        id="image0_6505_28504"
        width="96"
        height="96"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAE5ElEQVR4nO2dXW8WRRTHh6gXGk1IxAQIoHeiBLgAipSXQoNG2sI+u2fPme5Fg34FY7+atZ/DSOqt9TvIhfWY2Wc2DQ/0ZdeZ2TOz80v+CSGQdP9ne3b2zJmzSmUymUwmk8lkMplMwvCtWx/w5uZFJrrJiCsMsMFEwIi61fzP60z0iAFucFle4JWV98f+uaOHjZFE9xmRGLHpKWr/b1leGPs6ooO1vsxVtTbA9HcL4BkXxeWxr0s8/OLFJ4y46sz4RRE9YcSPx75OkXBRfMGItTfzD1VzVX0+9vWKghG/DmD84m/DzbGvWwRclneCm3/4bLitpgwD3B7N/ENdV1OEEZcEmD/X1J4JLMn8uerJrI5YRtp5W0RPVOrwGKudPirLSypVGPHK6AafJIBnKkV4Y+OjQC9ZLn4L0qsdsdYPRzf2tCK6r1KCy/LS6Kb2EyVVymaA7wSY2lfnVQow4mcCzOwvgBsqBRhgeXQzh4jokYodVuqM3SpsItS6ih3+/+lnmxF3GHGfEV9b7du/+9lrALSuVOwwwLWBBmwx4i4j/suIfIQO7L/Z8hQErWKHEe/1vnCiHxnx1THGL+o3T0FIIgDfDjB/r4f5ncxvQk5BizBA0SPn/sBEvw8wv0tH206DQLSmYoeJZgHM77TjNABaP1STCAANTjuL2ncagBRexJjoeYA7v9Pfjp8D8Zci+LgGK7fmuw1AXSMjvqeS3fclZ2nHTwoCWFYpwFV1NcCd3+mXnH4W4JcvzwYy/8BZaULqlmRbWDO1HVNeIPqmfckyD9lOpl9/nvOX2ju/KM6+sRLyk3Y6/Zrsprw1fXlQVZNoxgCVZ/NfOStFADxWorYRXexkaW9ph7mu/7DBTacxq+1eMMd8XFyUjsZ8oytS+nbqCZp/Pa2ONR2R+WV5J61eTR2R+YhLY3s/afNZqTPZfJyq+S7783VEd76EI0lOz2TpSMyv603W+isJOf/G5MwnAhGHtO053GmZj/iAET+UcgJ9Oi9ZRE/NcA8lBYfH/7d69u1wD+215s8LeLMBN0bV9iLNZp8qcYMvXKWeeQ8OezN/fvfO2p+7KMx+wpd2xbZqR9QclsDNXQ5wty2RN8250ZeWR+Fw6si23fxg72nH7iekMW/H3d2/EyznV9VVlQJ2FeAqAH95SzsosE7jZMzXsElTR+l1wNXOqoqddsaaO/Mb23sTwvyGq+q5ih074K4RloL2TrXOByhU7LTTBd0GYCfYSxakEYB1xwH4iYn+8W4+tu8CSaQgHwfkdr2lHXwjAE9V7Nihpq4DsGWPBfGpZGpGQ2o7AHdV7HgKQBeE3RPeig9sx9qwpimAayp2ApzR3X7HMdI/nRwjbZpzKnY8PITDCKASW1jrg7MOt/C6p1LAw4tYGM2E1fOFVELDiBJYfnaYoUOOi3H+tSloG9EFdvZ+E4keqNSIJg1Ru2Qev3vBB+3HC8Y2GE9ompLQt+MLc3Gjm4zHSELHmm/slyMacQIBvZohMOefBA5OXVJTwoxoF2B6M0nzO8x5KAnmcwq1nijHx5cCzmRJIH80R84zIcxno1DAOVyxqyOfS1SAxyJOoEvHjiZw+cb8vbjBF8l/PLOukbVeTvIDCKOUsk0wzJkys7NGtPbW52OJ1toPM8zPnZ1PYsxXJpPJZDKZTCaTyaij+Q8tn8yZoImezwAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
);
export default SvgConnectBrandChannelsIcon;
