import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    save2: build.mutation<Save2ApiResponse, Save2ApiArg>({
      query: (queryArg) => ({
        url: `/keyword`,
        method: "POST",
        body: queryArg.keyword,
      }),
    }),
    findById2: build.query<FindById2ApiResponse, FindById2ApiArg>({
      query: (queryArg) => ({ url: `/keyword/${queryArg.id}` }),
    }),
    deleteById2: build.mutation<DeleteById2ApiResponse, DeleteById2ApiArg>({
      query: (queryArg) => ({
        url: `/keyword/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    refresh: build.query<RefreshApiResponse, RefreshApiArg>({
      query: (queryArg) => ({
        url: `/keyword/refresh`,
        params: {
          brand_id: queryArg.brandId,
          count: queryArg.count,
          only_brand: queryArg.onlyBrand,
        },
      }),
    }),
    list3: build.query<List3ApiResponse, List3ApiArg>({
      query: () => ({ url: `/keyword/list` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type Save2ApiResponse = /** status 200 OK */ Keyword;
export type Save2ApiArg = {
  keyword: Keyword;
};
export type FindById2ApiResponse = /** status 200 OK */ Keyword;
export type FindById2ApiArg = {
  id: string;
};
export type DeleteById2ApiResponse = unknown;
export type DeleteById2ApiArg = {
  id: string;
};
export type RefreshApiResponse = /** status 200 OK */ Keyword[];
export type RefreshApiArg = {
  brandId?: string;
  count?: number;
  onlyBrand?: boolean;
};
export type List3ApiResponse = /** status 200 OK */ Keyword[];
export type List3ApiArg = void;
export type Keyword = {
  id?: string;
  name: string;
};
export const {
  useSave2Mutation,
  useFindById2Query,
  useLazyFindById2Query,
  useDeleteById2Mutation,
  useRefreshQuery,
  useLazyRefreshQuery,
  useList3Query,
  useLazyList3Query,
} = injectedRtkApi;
