import * as React from "react";
import type { SVGProps } from "react";
const SvgGoogleAdsColor = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" fill="none" viewBox="0 0 20 20" {...props} xmlns="http://www.w3.org/2000/svg">
    <g id="Icons=GoogleAds">
      <path d="M2.33008 13.1688L7.65009 3.95361L12.26 6.61526L6.94063 15.8305L2.33008 13.1688Z" fill="#FBBC04" />
      <path d="M17.6245 13.1368L12.3013 3.9183C11.6141 2.61859 10.0038 2.12203 8.70414 2.80922C7.40444 3.49642 6.90853 5.10664 7.59507 6.40634C7.6252 6.46334 7.6573 6.51902 7.69137 6.57405L13.0147 15.7925C13.7326 17.0752 15.3546 17.5331 16.6373 16.8151C17.92 16.0971 18.3779 14.4751 17.6599 13.1925C17.6481 13.1715 17.6363 13.1512 17.6245 13.1309V13.1368Z" fill="#4285F4" />
      <path d="M4.66164 17.1407C6.13163 17.1407 7.32329 15.949 7.32329 14.479C7.32329 13.009 6.13163 11.8174 4.66164 11.8174C3.19166 11.8174 2 13.009 2 14.479C2 15.949 3.19166 17.1407 4.66164 17.1407Z" fill="#34A853" />

    </g>
  </svg>

);
export default SvgGoogleAdsColor;
