import * as React from "react";
const SvgBrandTechnicalDocsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <rect width={28} height={28} fill="#F55" opacity={0.1} rx={4} />
    <path
      fill="#F55"
      d="M16 7.333h-3.334a2 2 0 0 0-2 2V16a2 2 0 0 0 2 2h5.333a2 2 0 0 0 2-2v-4.667l-4-4Z"
      opacity={0.5}
    />
    <path
      fill="#F55"
      d="M12.667 18a2 2 0 0 1-2-2v-6H10a2 2 0 0 0-2 2v6.667a2 2 0 0 0 2 2h5.333a2 2 0 0 0 2-2V18h-4.666Z"
      opacity={0.2}
    />
    <path
      fill="#F55"
      d="M20 11.333h-2.857A1.143 1.143 0 0 1 16 10.191V7.333l4 4Z"
    />
  </svg>
);
export default SvgBrandTechnicalDocsIcon;
