import { ContentType } from "./ContentType"
import {
  Back,
  Blog,
  BlogColor,
  CustomImage,
  CustomText,
  Facebook,
  FacebookColor,
  Instagram,
  InstagramColor,
  Linkedin,
  LinkedinColor,
  Pinterest,
  PinterestColor,
  Text,
  TwitterColor,
} from "../assets/IconComponents";
import Twitter from "../assets/IconComponents/Twitter";
import { SVGProps } from "react";
import SvgAIProductPhotoshootHeaderLogo from "../assets/IconComponents/AIProductPhotoshootHeaderLogo";

export interface CustomMediumOption {
  count?: string;
  value?: string;
  description?: string;
  width?: string;
  height?: string;
  title?: string;
  galleryTpe?: string;
  ratio?: number;
}

export interface MediumOption {
    title: string
    value: string
    description: string
    width?: string
    height?: string
    iconColor?: (props: SVGProps<SVGSVGElement>) => JSX.Element
    galleryTpe?: string
    ratio?: number
}

export interface Medium {
    contentType: ContentType
    name: string
    icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element
    iconColor?: (props: SVGProps<SVGSVGElement>) => JSX.Element
    isCustom: boolean
    color?: string
    options?: MediumOption[]
}

export const ChannelContentTypeIds = {
    FACEBOOK_POST: "0",
    TWITTER_SINGLE_POST: "1",
    TWITTER_THREAD: "2",
    INSTAGRAM_CAPTION: "3",
    LINKEDIN_POST: "4",
    PINTEREST_PINTEREST: "5",
    BLOG_BLOG: "6"
}

export const Mediums: Medium[] = [
  // {
  //     contentType: ContentType.all,
  //     name: "All Channels",
  //     icon: undefined,
  //     iconColor: undefined,
  //     isCustom: false,
  //     color: "#00acee",
  //     options: [{
  //         title: "Post",
  //         description: "Post",
  //         value: ChannelContentTypeIds.FACEBOOK_POST,
  //         iconColor: FacebookColor
  //     },
  //     {
  //         title: "Single post",
  //         description: "Single post",
  //         value: ChannelContentTypeIds.TWITTER_SINGLE_POST,
  //         iconColor: TwitterColor,
  //     },
  //     {
  //         title: "Thread",
  //         description: "Thread",
  //         value: ChannelContentTypeIds.TWITTER_THREAD,
  //         iconColor: TwitterColor,
  //     },
  //     {
  //         title: "Caption",
  //         description: "Caption",
  //         value: ChannelContentTypeIds.INSTAGRAM_CAPTION,
  //         iconColor: InstagramColor,
  //     },
  //     {
  //         title: "Post",
  //         description: "Post",
  //         value: ChannelContentTypeIds.LINKEDIN_POST,
  //         iconColor: LinkedinColor
  //     },
  //     {
  //         title: "Pinterest",
  //         description: "Pinterest",
  //         value: ChannelContentTypeIds.PINTEREST_PINTEREST,
  //         iconColor: PinterestColor,
  //     },
  //     {
  //         title: "Blog",
  //         description: "Blog",
  //         value: ChannelContentTypeIds.BLOG_BLOG,
  //         iconColor: BlogColor,
  //     }
  //     ]
  // },
  {
    contentType: ContentType.text,
    name: "Facebook",
    icon: Facebook,
    iconColor: FacebookColor,
    isCustom: false,
    color: "#00acee",
    options: [
      {
        title: "Post",
        description: "Post",
        value: "0",
      },
    ],
  },
  {
    contentType: ContentType.text,
    name: "Twitter",
    icon: Twitter,
    iconColor: TwitterColor,
    isCustom: false,
    color: "#00acee",
    options: [
      {
        title: "Single post",
        description: "Single post",
        value: "0",
      },
      {
        title: "Thread",
        description: "Thread",
        value: "1",
      },
    ],
  },
  {
    contentType: ContentType.text,
    name: "Instagram",
    icon: Instagram,
    iconColor: InstagramColor,
    isCustom: false,
    options: [
      {
        title: "Caption",
        description: "Caption",
        value: "0",
      },
    ],
  },
  {
    contentType: ContentType.text,
    name: "LinkedIn",
    icon: Linkedin,
    iconColor: LinkedinColor,
    isCustom: false,
    color: "#0A66C2",
    options: [
      {
        title: "Post",
        description: "Post",
        value: "0",
      },
    ],
  },
  {
    contentType: ContentType.text,
    name: "Pinterest",
    icon: Pinterest,
    iconColor: PinterestColor,
    isCustom: false,
    options: [
      {
        title: "Pinterest",
        description: "Pinterest",
        value: "0",
      },
    ],
  },
  {
    contentType: ContentType.text,
    name: "Blog",
    icon: Blog,
    iconColor: BlogColor,
    isCustom: false,
    options: [
      {
        title: "Blog",
        description: "Blog",
        value: "0",
      },
    ],
  },
  {
    contentType: ContentType.text,
    name: "Custom",
    icon: CustomText,
    iconColor: CustomText,
    isCustom: true,
  },
  {
    contentType: ContentType.image,
    name: "Facebook",
    icon: Facebook,
    iconColor: FacebookColor,
    isCustom: false,
    options: [
      {
        title: "Profile picture",
        description: "176 x 176 pixels",
        value: "0",
        width: "176",
        height: "176",
      },
      {
        title: "Cover photo",
        description: "816 x 312 pixels",
        value: "1",
        width: "816",
        height: "312",
      },
      {
        title: "Shared image",
        description: "1200 x 632 pixels",
        value: "2",
        width: "1200",
        height: "632",
      },
      {
        title: "Shared link preview",
        description: "1200 x 632 pixels",
        value: "3",
        width: "1200",
        height: "632",
      },
      {
        title: "Event image",
        description: "1920 x 1080 pixels",
        value: "4",
        width: "1920",
        height: "1080",
      },
    ],
  },
  {
    contentType: ContentType.image,
    name: "Twitter",
    icon: Twitter,
    iconColor: TwitterColor,
    isCustom: false,
    options: [
      {
        title: "Profile picture",
        description: "400 x 400 pixels",
        value: "0",
        width: "400",
        height: "400",
      },
      {
        title: "Header image",
        description: "1504 x 504 pixels",
        value: "1",
        width: "1504",
        height: "504",
      },
      {
        title: "In-stream image",
        description: "1024 x 512 pixels",
        value: "2",
        width: "1024",
        height: "512",
      },
    ],
  },
  {
    contentType: ContentType.image,
    name: "Instagram",
    icon: Instagram,
    iconColor: InstagramColor,
    isCustom: false,
    options: [
      {
        title: "Profile picture",
        description: "320 x 320 pixels",
        value: "0",
        width: "320",
        height: "320",
      },
      {
        title: "Post image",
        description: "1080 x 1080 pixels",
        value: "1",
        width: "1080",
        height: "1080",
      },
      {
        title: "Story image",
        description: "1080 x 1920 pixels",
        value: "2",
        width: "1080",
        height: "1920",
      },
    ],
  },
  {
    contentType: ContentType.image,
    name: "LinkedIn",
    icon: Linkedin,
    iconColor: LinkedinColor,
    isCustom: false,
    options: [
      {
        title: "Profile picture",
        description: "400 x 400 pixels",
        value: "0",
        width: "400",
        height: "400",
      },
      {
        title: "Background image",
        description: "1584 x 400 pixels",
        value: "1",
        width: "1584",
        height: "400",
      },
      {
        title: "Shared image",
        description: "1200 x 624 pixels",
        value: "2",
        width: "1200",
        height: "624",
      },
      {
        title: "Company logo",
        description: "304 x 304 pixels",
        value: "3",
        width: "304",
        height: "304",
      },
    ],
  },
  {
    contentType: ContentType.image,
    name: "Pinterest",
    icon: Pinterest,
    iconColor: PinterestColor,
    isCustom: false,
    options: [
      {
        title: "Profile picture",
        description: "168 x 168 pixels",
        value: "0",
        width: "168",
        height: "168",
      },
      {
        title: "Board cover image",
        description: "224 x 152 pixels",
        value: "1",
        width: "224",
        height: "152",
      },
      {
        title: "Pin image",
        description: "1000 x 1504 pixels",
        value: "2",
        width: "1000",
        height: "1504",
      },
    ],
  },
  {
    contentType: ContentType.image,
    name: "Banner",
    icon: CustomImage,
    iconColor: CustomImage,
    isCustom: false,
    options: [
      {
        title: "Medium Rectangle",
        description: "304 x 248 pixels",
        value: "0",
        width: "304",
        height: "248",
      },
      {
        title: "Leaderboard",
        description: "728 x 88 pixels",
        value: "1",
        width: "728",
        height: "88",
      },
      {
        title: "Wide Skyscraper",
        description: "160 x 600 pixels",
        value: "2",
        width: "160",
        height: "600",
      },
      {
        title: "Large Rectangle",
        description: "336 x 280 pixels",
        value: "3",
        width: "336",
        height: "280",
      },
      {
        title: "Half Page",
        description: "304 x 600 pixels",
        value: "4",
        width: "304",
        height: "600",
      },
      {
        title: "Billboard",
        description: "968 x 248 pixels",
        value: "5",
        width: "968",
        height: "248",
      },
      {
        title: "Mobile Leaderboard",
        description: "320 x 48 pixels",
        value: "6",
        width: "320",
        height: "48",
      },
      {
        title: "Mobile Large Banner",
        description: "320 x 104 pixels",
        value: "7",
        width: "320",
        height: "104",
      },
      {
        title: "Post",
        description: "1080 x 1080 pixels",
        value: "8",
        width: "1080",
        height: "1080",
      },
      {
        title: "Landscape",
        description: "1200 x 628 pixels",
        value: "9",
        width: "1200",
        height: "628",
      },
      {
        title: "Story",
        description: "1080 x 1920 pixels",
        value: "10",
        width: "1080",
        height: "1920",
      },
    ],
  },
  {
    contentType: ContentType.image,
    name: "Custom",
    icon: Text,
    iconColor: Text,
    isCustom: true,
  },
  {
    contentType: ContentType.image,
    name: "Gallery",
    icon: Back,
    iconColor: Back,
    isCustom: false,
    options: [
      {
        title: "",
        description: "1080 x 1920 pixels",
        value: "10",
        width: "1080",
        height: "1920",
        galleryTpe: "inspiration",
      },
      {
        title: "",
        description: "1080 x 1920 pixels",
        value: "10",
        width: "1080",
        height: "1920",
        galleryTpe: "theme",
      },
    ],
  },
  {
    contentType: ContentType.image,
    name: "Product",
    icon: SvgAIProductPhotoshootHeaderLogo,
    iconColor: SvgAIProductPhotoshootHeaderLogo,
    isCustom: false,
    options: [
      {
        title: "Square",
        description: "1024 x 1024 pixels",
        value: "10",
        width: "1024",
        height: "1024",
        ratio: 1,
      },
      {
        title: "Landscape",
        description: "1200 x 600 pixels",
        value: "10",
        width: "1200",
        height: "600",
        ratio: 2,
      },
      {
        title: "Whatsapp Landscape",
        description: "1910 x 1000 pixels",
        value: "10",
        width: "1910",
        height: "1000",
        ratio: 1.91,
      },
    ],
  },
];
