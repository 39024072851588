import {DownOutlined, LeftOutlined} from "@ant-design/icons";
import ChannelList from "./SubStepComponents/ChannelList";
import Icon from "../../Icon";
import SvgQuestionMark from "../../../assets/IconComponents/QuestionMark";
import {useState} from "react";
import {Facebook, FacebookColor, Google, Instagram, InstagramColor} from "../../../assets/IconComponents";
import MarketingAudiences from "../../MarketingPlanner/MarketingAudiences";
import ContentContainer from "../../ContentContainer";
import {Button, Dropdown, Space} from "antd";
import {t} from "i18next";
import {AudienceResponse} from "../../../services/AudiencetApi";


const EditAudienceStep = (props) => {

    const [selectedTab, setSelectedTab] = useState<string>('');
    const [selectedAudience, setSelectedAudience] = useState<AudienceResponse | undefined>()

    return (
        <div className={'w-full h-full'}>
            {props.title && (
                <div>
                    <div className="p-3 flex justify-between items-center">
                        <div className={'flex items-center'}>
                            <span className="text-black mr-1 cursor-pointer" onClick={props.closeSideModal}><LeftOutlined/></span>
                            <span className="text-H8"> {props.title}</span>
                        </div>
                        <div>
                           <span className="text-gray text-xs"> {props.description ?? ''}</span>
                        </div>
                    </div>
                    <hr className="bg-border mx-1"/>
                </div>
            )}
            <div className={'w-full h-[calc(100%-55px)] p-3'}>
                <ContentContainer
                    ref={props.ref}
                    className={`w-full`}
                    hoverable={false}
                    children={
                        <>
                            <div className={'text-black'}>
                                <MarketingAudiences
                                    selectedBrandId={props.selectedBrandId}
                                    selectedAudience={props.selectedAudience}
                                    setSelectedTab={setSelectedTab}
                                    selectedTab={selectedTab}
                                    setAudience={(a) => {
                                        setSelectedAudience(a)
                                    }}
                                />
                            </div>
                        </>
                    }
                />
                <div className="flex justify-between mt-3">
                    <Button onClick={() => {
                        props.closeSideModal();
                    }}>Back</Button>
                    <div>
                        {selectedTab === 'saved' ? (
                            <Button type="primary" onClick={() => {
                                props.closeSideModal()
                                props.setSelectedAudience(selectedAudience)
                            }}>
                                {t("continue")}
                            </Button>
                        ) : (<Button type="primary" onClick={() => {
                            props.closeSideModal()
                            props.setSelectedAudience(selectedAudience)
                        }}>
                            {t("continue")}
                        </Button>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditAudienceStep;
