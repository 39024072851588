import * as React from "react";
const SvgTextToSpeechDownload = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    fill="none"
    {...props}
  >
    <g fill="#fff" clipPath="url(#a)">
      <path fillOpacity={0.01} d="M0 .4h18v18H0z" />
      <path d="M8.99 2.64a.75.75 0 0 0-.74.76v7.19l-.97-.97a.75.75 0 1 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.06 0l2.25-2.25a.751.751 0 1 0-1.06-1.06l-.97.97V3.4a.75.75 0 0 0-.76-.76Zm-6 9.375a.75.75 0 0 0-.74.76V13.9a2.262 2.262 0 0 0 2.25 2.25h9a2.262 2.262 0 0 0 2.25-2.25v-1.125a.751.751 0 1 0-1.5 0V13.9c0 .423-.327.75-.75.75h-9a.739.739 0 0 1-.75-.75v-1.125a.75.75 0 0 0-.76-.76Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .4h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTextToSpeechDownload;
