import { FunctionComponent, useState } from "react";
import Header from "./Header";
import ControlButtons from "./ControlButtons";
import BrandHubCreateNewAudience from "../../BrandHub/CreateNew/BrandHubCreateNewAudience";
import { AudienceResponse } from "../../../services/AudiencetApi";

interface CreateAudienceProps {
    audience?: AudienceResponse
    onAudienceSaved: (data: AudienceResponse) => void
}

const CreateAudience: FunctionComponent<CreateAudienceProps> = (props: CreateAudienceProps) => {
    const [triggerAddAudience, setTriggerAddAudience] = useState<boolean>(false);
    const [saveToBrand, setSaveToBrand] = useState<boolean>(false);

    return (<div className="flex-col w-full h-full inline-flex">
        <Header />
        <div className="w-full h-[calc(100vh-135px)] flex-col p-[20px] gap-[12px] inline-flex border-b-1 overflow-scroll scrollbar-hide">
            <div className="self-stretch justify-start items-center  flex-1">
                <BrandHubCreateNewAudience
                    audience={props.audience}
                    hideHeader={true}
                    hideButtons={true}
                    hideMetaAdsSettings
                    onClose={() => {
                        setTriggerAddAudience(false)
                    }}
                    onAudienceSaved={(audienceResponse: AudienceResponse) => {
                        setTriggerAddAudience(false);
                        props.onAudienceSaved(audienceResponse)
                    }}
                    triggerAddAudience={triggerAddAudience}
                    saveToBrand={saveToBrand}
                />
            </div>
        </div>
        <ControlButtons
            onSaveClicked={() => {
                setTriggerAddAudience(true)
            }}
            onSaveToBrandChanged={(e: boolean) => setSaveToBrand(e)}
            isBrandAudience={props.audience?.brand_id !== undefined}
        />
    </div >)
}

export default CreateAudience;
