import * as React from "react";
import type { SVGProps } from "react";
const SvgCustomText = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      d="M18.542 3.417c-6.327 0-10.94 1.447-13.709 4.3-2.394 2.465-2.535 5.107-2.54 5.61v.01a10.244 10.244 0 0 0-1.748 3.526c-.035.12-.066.225-.096.314a.625.625 0 1 0 1.186.396c.034-.102.069-.222.11-.358.205-.702.541-1.839 1.568-3.187l-1.015-.697c.036-.048.072-.096.11-.144.13-.167.263-.335.413-.507 1-1.146 2.45-2.397 4.583-3.634l.11-.063.248-.14c.09-.051.18-.102.272-.152.13-.071.266-.142.402-.213.089-.047.175-.094.266-.14.155-.079.316-.157.476-.236.078-.038.153-.077.233-.115.243-.117.492-.232.75-.348a.624.624 0 1 1 .512 1.14c-.485.217-.94.436-1.371.655l-.014.007-.097.05c-1.288.662-2.348 1.326-3.218 1.975l-.062.046c-1.171.881-2.002 1.734-2.597 2.515 1.542-.08 7.308-.607 11.68-4.099.229-.183.3-.5.172-.763l-.402-.824 1.572.044a.617.617 0 0 0 .491-.217 12.78 12.78 0 0 0 2.3-3.91.623.623 0 0 0-.585-.841ZM9.968 9.106l-.219.107.219-.107Zm-4.366 2.643Z"
    />
  </svg>
);
export default SvgCustomText;
