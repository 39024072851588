import { FunctionComponent, useEffect, useState } from "react";
import SvgUploadIcon from "../../assets/IconComponents/UploadIcon";
import { Accept, useDropzone } from "react-dropzone";
import { useAppDispatch } from "../../helpers/hooks";
import { toggleLoading } from "../../store/appSlice";
import {
  useDeleteFileDeleteByIdMutation,
  useLazyDownloadQuery,
  useLazySummarizeQuery,
  useUploadMutation,
} from "../../services/FileApi";
import { blobToDataURL, dataURLToBlob } from "blob-util";
import { FileProps } from "../../services/BrandApi";
import { useRemoveBackgroundMutation } from "../../services/ImageopsApi";
import PrimaryButton from "../PrimaryButton";
import { Delete } from "../../assets/IconComponents";
import { useRef } from "react";
import borderSvg from "../../assets/icons/dashed-border.svg";
import borderSvgRed from "../../assets/icons/dashed-border-red.svg";
import { useAppSelector } from "../../helpers/hooks";
import SvgCompanyMaterialDocIcon from "../../assets/IconComponents/CompanyMaterialDocIcon";
import SvgCompanyMaterialPdfIcon from "../../assets/IconComponents/CompanyMaterialPdfIcon";

interface UploadFileProps {
  description: string;
  className?: string;
  maxFileCount?: number;
  size?: string;
  acceptedFileTypes?: Accept;
  maxFileSize?: number;
  disabled?: boolean;
  filesChanged: (filesIds: File[]) => void;
  selectionChanged?: (selectedFiles: FileProps[]) => void;
  uploadedFiles?: FileProps[];
  tooltip? : string;
  uploadInputCount?: number;
  selectionEnabled?: boolean;
  maxSelectionSize?: number;
  selectedFiles?: FileProps[];
  maxThumbnailCount?: number
  addNewFileToFront?: boolean;
  uploadEnabled?: boolean;
  brandHubUpload?: boolean;
  deleteEnabled?: boolean;
}

const UploadCompanyMaterialNew: FunctionComponent<UploadFileProps> = (
  props: UploadFileProps
) => {
  const refreshInputRef = useRef<HTMLInputElement | null>(null);
  const buttonClickRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState("");
  const brandInfo = useAppSelector((k) => k.brand.newBrand);
  const [gridColSize, setGridColSize] = useState("")
  const [selectedFiles, setFiles] = useState<any[]>([]);
  const [fileToRefresh, setFileToRefresh] = useState<number>(-1);
  const [pickedFiles, setPickedFiles] = useState<FileProps[]>([]);
  const [fileIndexToRemoveBackground, setFileIndexToRemoveBackground] =
    useState<number>(-1);
  const dispatch = useAppDispatch();
  const [
    uploadFiles,
    {
      data: uploadedFileData,
      isSuccess: filesUploaded,
      isLoading: filesUploading,
    },
  ] = useUploadMutation();
  const [
    deleteFile,
    { data: fileDeletedData, isSuccess: fileDeleted, isLoading: fileDeleting },
  ] = useDeleteFileDeleteByIdMutation();
  const [
    downloadFile,
    {
      data: fileDownloaddData,
      isSuccess: fileDownloaded,
      isLoading: fileDownloading,
      currentData: dt,
    },
  ] = useLazyDownloadQuery();

  const [
    summarizeFile,
    {
      data: summarizedFileData,
      isSuccess: fileSummarized,
      isLoading: fileSummarizing,
      currentData: sfd,
    },
  ] = useLazySummarizeQuery();

  const [
    removeBackground,
    { data: backgroundRemovedImage, isLoading: backgroundRemovalInProgress },
  ] = useRemoveBackgroundMutation();

  const [errorIndex, setErrorIndex] = useState(-1);

  useEffect(() => {
    if (props.uploadedFiles && props.uploadedFiles.length > 0) {
        setFiles(props.uploadedFiles);
      };
  }, [props.uploadedFiles]);

  useEffect(() => {
    console.log(error)
    if (error !== "") {
      setTimeout(() => {
        setError("");
        setErrorIndex(-1);
      }, 3000);
    }
  }, [error]);

  useEffect(() => {
    console.log(
      `background removed ${backgroundRemovedImage?.backgroundRemovedFile?.id}`
    );
    if (backgroundRemovedImage?.backgroundRemovedFile?.id) {
      if (fileIndexToRemoveBackground > -1) {
        const backgroundRemovedFile =
          backgroundRemovedImage?.backgroundRemovedFile;
        Promise.all([downloadFileFrom(backgroundRemovedFile.id ?? "")]).then(
          (fileProps) => {
            console.log(
              `background removed downloaded ${fileProps[0].uploadedId}`
            );
            let selectedFilesCopy = [...selectedFiles];
            selectedFilesCopy[fileIndexToRemoveBackground] = fileProps[0];
            setFiles(selectedFilesCopy);
            setFileIndexToRemoveBackground(-1);
            props.filesChanged(selectedFilesCopy);
          }
        );
      }
    }
  }, [backgroundRemovedImage]);

  useEffect(() => {
    if(props.selectedFiles){
      const newPickedFiles = props.selectedFiles
      setPickedFiles(newPickedFiles)
    }
  }, [props.selectedFiles])

  useEffect(() => {
    dispatch(toggleLoading(backgroundRemovalInProgress));
  }, [backgroundRemovalInProgress]);

  useEffect(() => {
    if(props.size === "library"){
      setGridColSize("grid-cols-5")
    }
    else if (props.size === "small") {
      setGridColSize("grid-cols-3")
    }
  }, [])

  const getSummary = async (fileId: string) =>{
    return new Promise<any>((resolve) => {
      summarizeFile({
        id: fileId
      })
      .unwrap()
      .then(async(k) => {
        const summary = k.summary;
        return resolve(summary)
      })
      .catch((e) => {
        console.log(e)
      })
    })

  }

  const pickFiles = (file) => {
    if (pickedFiles.filter(pf => pf.id === file.id).length === 0) {
      if (pickedFiles.length === props.maxSelectionSize) {
        let updatedPickedFiles = [...pickedFiles.slice(1), { id: file.id, name: file.name, summary: file.summary, file_name: file.file_name, file_type: file.file_type, create_date: file.create_date, uploaded: true }];
        setPickedFiles(updatedPickedFiles);
        props.selectionChanged && props.selectionChanged(updatedPickedFiles)
      } else {
        let updatedPickedFiles = [...pickedFiles, { id: file.id, name: file.name, summary: file.summary, file_name: file.file_name, file_type: file.file_type, create_date: file.create_date, uploaded: true }];
        setPickedFiles(updatedPickedFiles);
        props.selectionChanged && props.selectionChanged(updatedPickedFiles)
      }
    } else {
      let newPickedFiles = pickedFiles.filter(pf => pf.id !== file.id);
      setPickedFiles(newPickedFiles);
      props.selectionChanged && props.selectionChanged(newPickedFiles)
    }
  }

  const downloadFileFrom = async (fileId: string) => {
    return new Promise<any>((resolve) => {
      downloadFile({
        id: fileId,
      })
        .unwrap()
        .then(async (k) => {
          const item = {
            uploadedId: fileId,
            name: k.name,
            type: k.file.type.includes("ima") ? "image" : "other",
            preview: await blobToDataURL(k.file),
          };

          return resolve(item);
        })
        .catch((e) => {
          console.log("error", e);
        });
    });
  };

  const handleRefreshButtonClick = (index: number) => {
    if (refreshInputRef.current) {
      console.log("first");
      if (index !== -1) {
        setFileToRefresh(index);
      }
      refreshInputRef.current.click();
    } else {
      if (buttonClickRef.current) {
        buttonClickRef.current?.click();
      }
    }
  };

  const downloadFileToDesktop = (uploadId: string) => {
    downloadFile({
      id: uploadId,
    })
      .unwrap()
      .then(async (k) => {
        const dataUrl = await blobToDataURL(k.file);

        var link = document.createElement("a");
        link.download = k.name;
        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxSize: props.maxFileSize ?? 25000000,
    maxFiles: 1,
    accept: props.acceptedFileTypes ?? {
      ".txt": [".txt"],
      ".pdf": [".pdf"],
      ".docx": [".docx"],
      ".doc": [".doc"],
      ".ppt": [".ppt"],
      ".pptx": [".pptx"],
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      const files = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      if (rejectedFiles.length > 0) {
        let message = rejectedFiles[0].errors[0].message;
        setError(message);
        return;
      }

      selectFile(files, fileToRefresh);
    },
    onFileDialogOpen() {
      console.log("file dialog");
      setError("");
    },
  });

  const fileToBlob = (file?: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (!!reader.result) {
          resolve(reader.result);
        } else {
          reject(Error("Failed converting to base64"));
        }
      };
    });
  };

  const selectFile = async (files: any[], index: number) => {
      dispatch(toggleLoading(true));

      let selectedFile = files[0];
      let a = (await fileToBlob(selectedFile)) as string;
      let blob = dataURLToBlob(a);
      const formData: FormData = new FormData();
      formData.append("files", blob, selectedFile.name);
      uploadFiles({
        body: formData,
      })
        .unwrap()
        .then(async (k) => {
          const summary = await Promise.resolve(getSummary(k.ids ?? ""))
          selectedFile["summary"] = summary
          selectedFile["uploadedId"] = k.ids ?? "";
          console.log("success", k);
            const toBeDeleted = selectedFiles[0];

            setFiles([
              ...selectedFiles.filter(
                (k) => k.id !== toBeDeleted.id
              ),
              selectedFile,
            ]);
            props.filesChanged([
              ...selectedFiles.filter(
                (k) => k.id !== toBeDeleted.id
              ),
              selectedFile,
            ]);
            if(!props.brandHubUpload){
              if (!props.addNewFileToFront) {
                setFiles((selectedFiles) => [...selectedFiles, selectedFile]);
                props.filesChanged([...selectedFiles, selectedFile]);
              }
              else {
                setFiles((selectedFiles) => [selectedFile, ...selectedFiles]);
                props.filesChanged([selectedFile, ...selectedFiles]);
              }
            }
          const formattedSelectedFile = {
            id: selectedFile.uploadedId,
            name: selectedFile.name,
            summary: selectedFile.summary,
            uploaded: true,
          }
          pickFiles(formattedSelectedFile)
          dispatch(toggleLoading(false));
          setFileToRefresh(-1);
        });

  };

  const thumbs = selectedFiles.slice(0, props.maxThumbnailCount ? props.maxThumbnailCount : selectedFiles.length).map((file, index) => (
    <>
      {file &&  (
        <div
          key={file.id}
          style={{
            backgroundImage: `url(${borderSvg})`, // Set the SVG file as the background image
            backgroundSize: "cover", // Adjust the sizing as needed
            backgroundRepeat: "no-repeat", // Prevent the SVG from repeating
            borderRadius: "6px"
          }}
          className={`relative flex cursor-pointerrounded-[8px] h-[120px] min-w-[120px] justify-center ${pickedFiles.filter(pf => pf.id === file.id).length !== 0 ? `border-1 border-red` : ''}`}
          onClick={() => {
            if (props.selectionEnabled) {
              pickFiles(file)
            }
          }}
        >
          <div className="flex flex-row h-10 absolute -right-1 -top-1 m-3">
            <input {...getInputProps()} ref={refreshInputRef} />
            {props.deleteEnabled &&<PrimaryButton
              icon={<Delete fill={"#121315"} />}
              noFill
              className=" ml-[3px] h-[30px] w-[30px]  bg-bg border-1 border-border "
              isDisabled={false}
              tooltip={props.tooltip ? props.tooltip : ""}
              onClick={() => {
                //e.stopPropagation();
                const id = file.uploadedId;

                setFiles(
                  selectedFiles.filter((k) => k.uploadedId !== file.uploadedId)
                );
                props.filesChanged(
                  selectedFiles.filter((k) => k.uploadedId !== file.uploadedId)
                );
                deleteFile({
                  id: id,
                });
              }}
            />}
          </div>
          <div className="flex flex-row h-10 absolute -bottom-3 m-3">
            <text className="text-BodySmall text-black w-18 truncate text-ellipsis overflow-hidden">
              {file.file_name ? (file.file_name.length > 10
                ? `${file.file_name.substring(0, 10)}...`
                : file.file_name) : (file.name && file.name.length > 10
                  ? `${file.name.substring(0, 10)}...`
                  : file.name)}
            </text>
          </div>

          { <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {((file.name ?? "") as string).endsWith(".pdf") && (
              <SvgCompanyMaterialPdfIcon />
            )}
            {!((file.name ?? "") as string).endsWith(".pdf") && (
              <SvgCompanyMaterialDocIcon />
            )}
          </div>}
        </div>
      )}
    </>
  ));

  console.log("Uploaded files are: ", props.uploadedFiles);
  return (
    <>
      <div
        className={`relative flex flex-col mt-[20px] w-full  ${
          props.disabled ? "p-3" : ""
        } ${props.className}`}
        style={{ pointerEvents: props.disabled ? "none" : "all" }}
      >
        {props.disabled && (
          <div
            className="absolute left-0 top-0 right-0 bottom-0 bg-black/5 z-50 rounded-[8px]"
            style={{ pointerEvents: "none" }}
          ></div>
        )}

        <aside className="flex flex-row gap-3 flex-wrap">
        { (
            <>
              {[...Array(props.uploadInputCount)].map((_, index) => (
                <>
                  {error && index == errorIndex && props.uploadEnabled && (
                    <div
                      style={{
                        backgroundImage: `url(${borderSvgRed})`, // Set the SVG file as the background image
                        backgroundSize: "cover", // Adjust the sizing as needed
                        backgroundRepeat: "no-repeat", // Prevent the SVG from repeating
                      }}
                      className={`flex ${
                        index != 0 ? "max-md:ml-2" : ""
                      } mr-2 cursor-pointer flex-col justify-center items-center h-[120px] ${props.brandHubUpload ? "w-[450px]" : "w-[120px]"} rounded-[8px] mt-2 items`}
                      onClick={() => {}}
                    >
                      <input {...getInputProps()} ref={buttonClickRef} />
                      <SvgUploadIcon fill="#FF5555" />
                      <p className="text-BodySmall text-red mt-2 text-center">
                        {props.description}
                      </p>
                    </div>
                  )}
                  {(!error || index != errorIndex) && props.uploadEnabled && (
                    <div
                      style={{
                        borderRadius: "8px",
                        border: "1px dashed #EAEBE6"
                      }}
                      className={`flex ${
                        index != 0 ? "max-md:ml-2" : ""
                      }  cursor-pointer flex-col justify-center items-center h-[120px] ${props.brandHubUpload ? "w-[450px]" : "w-[120px]"} rounded-[8px] items`}
                      onClick={() => {
                        console.log(index)
                        setErrorIndex(index);
                        handleRefreshButtonClick(-1);
                      }}
                    >
                      <input {...getInputProps()} ref={buttonClickRef} />
                      <SvgUploadIcon />
                      <p className="text-BodySmall text-gray mt-2 text-center">
                        {props.description}
                      </p>
                    </div>
                  )}
                </>
              ))}
            </>
          )}
          {thumbs}
        </aside>
      </div>
    </>
  );
};

export default UploadCompanyMaterialNew;
