import { BrandRequest } from "../../services/BrandApi";
import SidebarBrandLogo from "./SidebarBrandLogo";
import SvgBrandSelect from "../../assets/IconComponents/BrandSelect";
import { useNavigate } from "react-router-dom";

export interface IMenuBrandSelectorItemProps {
  data: BrandRequest;
  selected: boolean;
  itemSelected: (data: BrandRequest) => void;
}

function MenuBrandSelectorItem(props: IMenuBrandSelectorItemProps) {
  const { selected = false } = props;
  const navigate = useNavigate()

  return (
    <div
      className={`p-[10px] h-14  cursor-pointer rounded flex items-center justify-between hover:bg-menuBrandHoverBg
        ${selected ? "p-[10px] bg-menuBrandHoverBg  rounded" : ""}
        `}
      onClick={() => {
        props.itemSelected(props.data);
      }}
    >
      <div className="flex flex-row items-center justify-start">
        <div className="rounded">
          <SidebarBrandLogo brand={props.data} bgColor="bg-menuBg" />
        </div>
        <div className="flex flex-col ml-2 max-w-[90px]">
          <span className={`text-BodyMedium font-medium text-black truncate`}>
            {props.data.brand_name}
          </span>
          <span className="text-BodyMedium text-footnote truncate">
            {`${props.data?.connected_account_count ?? 0} Channel${(props.data?.connected_account_count ?? 0) > 1 ? 's' : ''} `}
          </span>
        </div>
      </div>
      <div>
        <div
          className={`w-full  flex items-center justify-center ml-1`}
          onClick={(event) => {
            navigate("/app/brandhub/new", { state: { brand: props.data } });
          }}
        >
          <SvgBrandSelect />
        </div>
      </div>
    </div>
  );
}

export default MenuBrandSelectorItem;
