import * as React from "react";
import type { SVGProps } from "react";
const SvgBrandTritoneColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    fill="none"
    {...props}
  >
    <rect width={56} height={56} y={0.5} fill="#F55" opacity={0.1} rx={8} />
    <path
      fill="#F55"
      d="M24.889 21.078h-9.333v18.667h9.333V21.078zM40.444 41.3h-14V16.411a3.11 3.11 0 013.112-3.111h9.333A3.11 3.11 0 0142 16.411v23.334c0 .858-.697 1.555-1.556 1.555z"
      opacity="0.35"
    ></path>
    <path
      fill="#F55"
      d="M38.889 19.522h-3.111v-3.11h3.11v3.11zM32.667 19.522h-3.112v-3.11h3.112v3.11zM38.889 25.744h-3.111v-3.11h3.11v3.11zM32.667 25.744h-3.112v-3.11h3.112v3.11zM38.889 31.967h-3.111v-3.111h3.11v3.11zM32.667 31.967h-3.112v-3.111h3.112v3.11zM38.889 38.189h-3.111v-3.111h3.11v3.111zM32.667 38.189h-3.112v-3.111h3.112v3.111zM23.333 17.967h-6.222A3.11 3.11 0 0014 21.077v18.668c0 .858.697 1.555 1.556 1.555h10.888V21.078a3.11 3.11 0 00-3.11-3.111zm0 20.222h-6.222v-3.111h6.222v3.111zm0-6.222h-6.222v-3.111h6.222v3.11zm0-6.222h-6.222v-3.111h6.222v3.11z"
    ></path>
  </svg>
);
export default SvgBrandTritoneColor;
