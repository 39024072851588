import * as React from "react";
const SvgForYouLightBulb = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    width={19}
    height={19}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill ?? "#FFFFFF"}
      d="m17.029 7.237-1.12-.516 1.12-.51.505-1.12.514 1.121 1.117.51-1.117.514-.514 1.116-.505-1.115Zm-2.915-4.302-1.661-.779 1.661-.779.774-1.656.784 1.656 1.656.78-1.655.78-.785 1.654-.774-1.656ZM6.383 18.563a1.65 1.65 0 0 1-1.228-.515 1.739 1.739 0 0 1-.502-1.263h3.47c0 .5-.169.92-.506 1.263a1.665 1.665 0 0 1-1.234.515Zm-2.753-3.1a.643.643 0 0 1-.655-.659.636.636 0 0 1 .655-.651h5.51a.64.64 0 0 1 .655.657.634.634 0 0 1-.654.654H3.63Zm-.55-2.61a7.061 7.061 0 0 1-2.24-2.29C.287 9.645.011 8.61.011 7.457c0-1.727.63-3.221 1.89-4.483 1.26-1.261 2.754-1.892 4.483-1.892 1.729 0 3.223.631 4.484 1.892 1.261 1.262 1.892 2.756 1.892 4.483 0 1.151-.273 2.186-.819 3.103-.546.918-1.296 1.682-2.25 2.292H3.08Zm.513-1.42h5.602a4.8 4.8 0 0 0 1.57-1.711c.384-.7.576-1.455.576-2.263 0-1.363-.486-2.528-1.457-3.498-.97-.969-2.138-1.453-3.501-1.453s-2.529.484-3.496 1.453c-.968.97-1.452 2.135-1.452 3.498 0 .808.191 1.563.574 2.263.382.701.91 1.271 1.584 1.712Z"
    />
  </svg>
);
export default SvgForYouLightBulb;
