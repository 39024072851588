import { t } from "i18next";
import {
  FunctionComponent,
  RefObject,
  useEffect,
  useState,
} from "react";
import SvgImportIcon from "../../../assets/IconComponents/ImportIcon";
import InputText from "../../../components/InputText";
import PrimaryButton from "../../../components/PrimaryButton";
import TextView from "../../../components/TextView";
import {
  BrandKeywords,
  FileProps,
  useGenerateWithAiMutation,
  useImportDataFromWebsiteMutation,
} from "../../../services/BrandApi";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { toggleLoading } from "../../../store/appSlice";
import { setNewBrandInfo } from "../../../store/brandSlice";
import { TonOfVoices } from "../../../constants/TonOfVoices";
import SvgLoading from "../../../assets/IconComponents/Loading";
import TagSelector from "../../../components/TagSelector/TagSelector";
import PickerView, { PickerOptions } from "../../../components/PickerView";
import SvgGeneralAnnouncementIcon from "../../../assets/IconComponents/GeneralAnnouncementIcon";
import SvgBrandVoiceToneIcon from "../../../assets/IconComponents/BrandVoiceToneIcon";
import { CSSObjectWithLabel, StylesConfig } from "react-select";
import SvgGenerateSimilarBrushIcon from "../../../assets/IconComponents/GenerateSimilarBrushIcon";
import { hostname } from "node:os";

interface IBrandHubGeneralProps {
  ref: RefObject<HTMLDivElement>;
  expanded: () => void;
  brandAboutEntered: () => void;
  errorOccured: (e: string) => void;
  className?: string;
  keywords: BrandKeywords[];
  refreshKeywords: () => void;
}

const BrandHubGeneral: FunctionComponent<IBrandHubGeneralProps> = (
  props: IBrandHubGeneralProps
) => {
  const [collapsed, setCollapsed] = useState(false);
  const brandInfo = useAppSelector((k) => k.brand.newBrand);
  const dispatch = useAppDispatch();
  const [firstCompanyMaterial, setFirstCompanyMaterial] = useState<
    FileProps | undefined
  >();
  const [secondCompanyMaterial, setSecondCompanyMaterial] = useState<
    FileProps | undefined
  >();
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
  const [
    getBrandDatasFromAI,
    {
      data: brandDataFromAI,
      isLoading: brandsDataLoading,
      error: brandDataFromAIError,
    },
  ] = useImportDataFromWebsiteMutation();
  const [
    generateAboutWithAI,
    {
      data: aboutWithAI,
      isLoading: aboutWithAILoading,
      error: generateWithAIError,
    },
  ] = useGenerateWithAiMutation();

  function isValidUrl(string: string) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }

  const getPickerStyles = (
    smallItemms?: boolean,
    optionsOnSide?: boolean,
    isFontPicker?: boolean
  ): StylesConfig<PickerOptions> => {
    return {
      multiValue: (styles) => ({
        ...styles,
        backgroundColor: "#FAFBF9",
        borderColor: "#EAEBE6",
        borderWidth: 1,
        maxHeight: 40,
        borderRadius: 7,
        padding: 6,
        margin: 0,
        gap: 10,
      } as CSSObjectWithLabel),
      singleValue: (styles, { data }) => ({
        ...styles,
        fontFamily: isFontPicker ? data.title : "",
      } as CSSObjectWithLabel),
      valueContainer: (styles) => ({
        ...styles,
        paddingBottom: 6,
        paddingTop: 6,
        paddingLeft: 6,
        paddingRight: 6,
        gap: 10,
      } as CSSObjectWithLabel),
      multiValueLabel: (styles) => ({
        ...styles,
        fontSize: 15,
        padding: 0,
      } as CSSObjectWithLabel),
      multiValueRemove: (styles) => ({
        ...styles,
        color: "black",
        padding: 10,
      } as CSSObjectWithLabel),
      indicatorSeparator: (styles) => ({ color: "white" }),
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        borderColor: "#EAEBE6",
        borderWidth: 1,
        borderRadius: 4,
        minHeight: 40,
        minWidth: "100%",
        fontSize: 15,
        border: "1px solid #EAEBE6",
        boxShadow: "none",
        "&:hover": {
          border: "1px solid #EAEBE6",
          boxShadow: "none",
        },
      } as CSSObjectWithLabel),
      menu: (styles) => ({
        ...styles,
        borderRadius: 12,
        overflow: "hidden",
        borderColor: "#EAEBE6",
        borderWidth: 1,
        padding: 5,
        paddingTop: 0,
        outline: "none",
        zIndex: 999,
      } as CSSObjectWithLabel),

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          padding: 10,
          paddingLeft: smallItemms ? 5 : 10,
          backgroundColor: isFocused
            ? "#FAFBF9"
            : isSelected
              ? "#FAFBF9"
              : "white",
          borderWidth: isFocused ? 1 : isSelected ? 1 : 0,
          borderColor: isSelected ? "#EAEBE6" : "",
          fontSize: smallItemms ? 12 : 15,
          borderRadius: 7,
          height: 41,
          color: "#121315",
          fontFamily: isFontPicker ? data.title : "",
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor: "#FAFBF9",
          } as CSSObjectWithLabel,
        } as CSSObjectWithLabel;
      },
    };
  };

  useEffect(() => {
    if (brandDataFromAIError || generateWithAIError) {
      let errorString = "";

      if (brandDataFromAIError) {
        errorString = "There was an unexpected error. Please retry later."; //(brandDataFromAIError as any)?.data?.message
      }

      if (generateWithAIError) {
        errorString = "There was an unexpected error. Please retry later."; //(generateWithAIError as any)?.data?.message
      }

      props.errorOccured(errorString);
    }
  }, [brandDataFromAIError, generateWithAIError]);

  const getBrandInfoFromAI = () => {
    if (!checkURLValid(brandInfo?.webSiteUrl ?? "")) {
      props.errorOccured("The URL Is Not In The Correct Format");
    } else {
      getBrandDatasFromAI({
        importDataFromWebsiteRequest: {
          url: brandInfo?.webSiteUrl ?? "",
        },
      });
    }
  };

  function checkURLValid(str) {
    var urlRegex =
      "^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$";
    var url = new RegExp(urlRegex, "i");
    return str.length < 2083 && url.test(str);
  }
  const generateCompanyAboutWithAI = () => {
    generateAboutWithAI({
      withAiRequest: {
        url: brandInfo?.webSiteUrl ?? "",
        instagram: brandInfo?.instagram,
        linkedin: brandInfo?.linkedIn,
        twitter: brandInfo?.twitter,
      },
    });
  };

  const dispatchCompanyMaterials = () => {
    const companyMaterials: (FileProps | undefined)[] = [];
    if (firstCompanyMaterial) {
      companyMaterials.push(firstCompanyMaterial);
    }

    if (secondCompanyMaterial) {
      companyMaterials.push(secondCompanyMaterial);
    }
    const is = companyMaterials.map((k: any) => ({
      id: k.uploadedId ?? k.id,
      name: k.name,
      uploaded: true,
    }));
    dispatch(
      setNewBrandInfo({
        ...brandInfo,
        selectedCompanyFiles: is,
      })
    );
  };

  useEffect(() => {
    if (brandInfo && isFirstTime) {
      setIsFirstTime(false);

      const selectedCompanyFiles = brandInfo.selectedCompanyFiles ?? [];

      if (selectedCompanyFiles.length > 0) {
        // If there is at least one file, assign the first file to firstCompanyMaterial.
        setFirstCompanyMaterial(selectedCompanyFiles[0]);
      }

      if (selectedCompanyFiles.length > 1) {
        // If there are at least two files, assign the second file to secondCompanyMaterial.
        setSecondCompanyMaterial(selectedCompanyFiles[1]);
      }
    }
  }, [brandInfo]);

  useEffect(() => {
    dispatchCompanyMaterials();
  }, [firstCompanyMaterial, secondCompanyMaterial]);

  useEffect(() => {
    if (aboutWithAI) {
      dispatch(
        setNewBrandInfo({
          ...brandInfo,
          aboutCompany: aboutWithAI.about ?? brandInfo?.aboutCompany,
        })
      );

      props.brandAboutEntered();
    }
  }, [aboutWithAI]);

  useEffect(() => {
    if (brandDataFromAI) {
      if (
        brandDataFromAI.twitter?.trim() === "" &&
        brandDataFromAI.instagram?.trim() === "" &&
        brandDataFromAI.linkedin?.trim() === "" &&
        brandDataFromAI.about?.trim() === ""
      ) {
        props.errorOccured(
          "Data could not be accessed. Please check the Website URL."
        );
        return;
      }
      dispatch(
        setNewBrandInfo({
          ...brandInfo,
          brandName: getBrandName(brandInfo?.webSiteUrl),
          twitter: brandDataFromAI.twitter ?? brandInfo?.twitter,
          instagram: brandDataFromAI.instagram ?? brandInfo?.instagram,
          linkedIn: brandDataFromAI.linkedin ?? brandInfo?.linkedIn,
          aboutCompany: brandDataFromAI.about ?? brandInfo?.aboutCompany,
          facebook: brandDataFromAI.facebook ?? brandInfo?.facebook,
          sector: brandDataFromAI.sector ?? brandInfo?.sector,
          subSector: brandDataFromAI.sub_sector ?? brandInfo?.subSector,
          selectedCompanyPrimaryFont:
            brandDataFromAI.primary_font_file_id ??
            brandInfo.selectedCompanyPrimaryFont,
          selectedCompanySecondaryFont:
            brandDataFromAI.secondary_font_file_id ??
            brandInfo.selectedCompanySecondaryFont,
          selectedCompanyAccentFont:
            brandDataFromAI.accent_font_file_id ??
            brandInfo.selectedCompanyAccentFont,
          selectedCompanyLogos: brandDataFromAI.logo_file_ids
            ? brandDataFromAI.logo_file_ids
            : brandInfo.selectedCompanyLogos,
          brandTones: brandDataFromAI.text_tone
            ? brandDataFromAI.text_tone
              ?.split(",")
              .map(
                (k) => TonOfVoices.find((l) => l.key === k)?.value ?? ""
              ) ?? []
            : brandInfo.brandTones,
          brandColors:
            brandDataFromAI.most_freq_colors ?? brandInfo.brandColors,
        })
      );

      setTimeout(() => {
        props.refreshKeywords();
      }, 1000);

    }
  }, [brandDataFromAI]);

  function getBrandName(url: string | undefined) {
    var parsedUrl = new URL(url ?? "");
    parsedUrl.hostname
    var hostName = parsedUrl.hostname;
    if (hostName != undefined && (hostName?.length ?? 0) > 0) {
      hostName = hostName.replace("www.", "")
      hostName = hostName.substring(0, hostName.indexOf("."));
      return hostName?.charAt(0).toUpperCase() + hostName?.slice(1);
    }
    return hostName;
  }


  useEffect(() => {
    dispatch(toggleLoading(brandsDataLoading || aboutWithAILoading));
  }, [brandsDataLoading, aboutWithAILoading]);

  return (
    <>
      <div className="flex flex-col w-full gap-2">
        <div
          className={`flex flex-col bg-white w-full rounded-[10px] border-1 transform-gpu h-[80px] `}
        >
          <div className="flex w-full px-3 flex-col md:flex-row justify-between">
            <InputText
              defaultValue={brandInfo.webSiteUrl}
              value={brandInfo.webSiteUrl}
              sideBySide={true}
              placeholder={t("enter-website-url") ?? ""}
              className="w-full pt-[20px]"
              innerClassName="w-3/4 rounded-[4px]"
              title={t("import-from-website") ?? ""}
              onChange={(e) => {
                if (
                  !e.toLowerCase().startsWith("http://") &&
                  !e.toLowerCase().startsWith("https://")
                ) {
                  dispatch(
                    setNewBrandInfo({
                      ...brandInfo,
                      webSiteUrl: `https://${e}`,
                    })
                  );
                } else {
                  dispatch(setNewBrandInfo({ ...brandInfo, webSiteUrl: e }));
                }
              }}
              onBlur={(e) => {
                if (
                  !e.toLowerCase().startsWith("http://") &&
                  !e.toLowerCase().startsWith("https://")
                ) {
                  dispatch(
                    setNewBrandInfo({
                      ...brandInfo,
                      webSiteUrl: `https://${e}`,
                    })
                  );
                }
              }}
            />
            <PrimaryButton
              isDisabled={
                brandInfo?.webSiteUrl == undefined ||
                brandInfo?.webSiteUrl == "" ||
                !isValidUrl(brandInfo.webSiteUrl)
              }
              onClick={() => {
                getBrandInfoFromAI();
              }}
              icon={
                !brandsDataLoading ? (
                  <SvgImportIcon fill="#fff" className="mr-2" />
                ) : (
                  <SvgLoading fill="#fff" className="mr-2" />
                )
              }
              title={!brandsDataLoading ? t("import") : t("importing")}
              className="h-[46px] w-full md:w-[100px] mt-[10px] md:mt-[21px] md:ml-2"
            />
          </div>
        </div>
        <div
          className={`bg-white w-full flex-col rounded-[10px] border-1 transform-gpu h-[500px] px-5`}
        >
          <div className="mt-2 flex flex-row w-full items-center">
            <SvgGeneralAnnouncementIcon />
            <span className="text-H7 text-black ml-2 w-72 sm:w-full truncate text-ellipsis overflow-hidden">
              {t("general")}
            </span>
          </div>
          <div className="mt-5">
            <InputText
              title={t("brand-name") ?? ""}
              value={brandInfo.brandName}
              placeholder={t("enter-brand-name") ?? ""}
              sideBySide={true}
              innerClassName="w-3/4 rounded-[4px]"
              onChange={(e) => {
                console.log(`set new brand name ${e}`);
                dispatch(setNewBrandInfo({ ...brandInfo, brandName: e }));
              }}
            />
            <div className="relative flex flex-col mt-[20px]">
              <TextView
                onSide={true}
                initialValue={brandInfo?.aboutCompany}
                className="w-full h-[300px]"
                placeholder={t("enter-about-company") ?? ""}
                title={t("company-summary") ?? ""}
                innerClassName="rounded-[4px] w-3/4"
                onChange={(e) => {
                  dispatch(setNewBrandInfo({ ...brandInfo, aboutCompany: e }));
                }}
                additionalContent={
                  <div
                    className="w-3/4  -mr-2 py-3 border-t-1 border-1 justify-end absolute -bottom-1 right-2 flex items-end rounded-[3px] p-1 items-center bg-white"
                    onClick={() => {
                      generateCompanyAboutWithAI();
                    }}
                  >
                    <div className="flex flex-row cursor-pointer items-center justify-center">
                      <SvgGenerateSimilarBrushIcon />
                      <p className="ml-2 mr-2 text-H8 text-red">
                        Generate with AI
                      </p>
                    </div>
                  </div>
                }
                onBlur={() => {
                  props.brandAboutEntered();
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={`bg-white w-full rounded-[10px] border-1  transform-gpu `}
        >
          <>
            <div className="mt-3 ml-3 flex flex-row w-full items-center">
              <SvgBrandVoiceToneIcon />
              <span className="text-H7 text-black ml-2 w-72 sm:w-full truncate text-ellipsis overflow-hidden">
                {t("brand-tone")}
              </span>
            </div>

            <div className="flex flex-col w-full p-[20px]">
              <PickerView
                optionsOnSide={true}
                className="mt-2 flex-1"
                title={t("voice-of-tone") ?? ""}
                getPickerStyles={getPickerStyles}
                selectContainerClassname="w-3/4 "
                items={TonOfVoices}
                selectedValue={brandInfo.brandTones}
                // maxSelectionCount={1}
                allowMultiSelect={false}
                onChange={(e) => {
                  dispatch(
                    setNewBrandInfo({
                      ...brandInfo,
                      brandTones: e.map((k) => k.value ?? ""),
                    })
                  );
                }}
              />

              <div className="mt-2">
                <TagSelector
                  selectedTags={brandInfo.selectedKeywords}
                  refreshKeywords={() => {
                    props.refreshKeywords();
                  }}
                  tagsSelected={(e) => {
                    dispatch(
                      setNewBrandInfo({
                        ...brandInfo,
                        selectedKeywords: e,
                      })
                    );
                  }}
                />
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default BrandHubGeneral;
