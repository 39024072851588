import * as React from "react";
const SvgRoundAudioPlayButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={42}
    fill="none"
    {...props}
  >
    <g filter="url(#a)">
      <rect width={40} height={40} fill="#F55" rx={20} />
      <rect width={39} height={39} x={0.5} y={0.5} stroke="#F55" rx={19.5} />
      <g clipPath="url(#b)">
        <path fill="#F55" d="M11 11h18v18H11z" />
        <path
          fill="#fff"
          d="M15.396 25.52V14.48c0-.302.107-.556.32-.76a1.045 1.045 0 0 1 1.027-.267c.098.027.191.067.28.12l8.694 5.52c.16.107.28.24.36.4a1.118 1.118 0 0 1 0 1.014c-.08.16-.2.293-.36.4l-8.694 5.52a1.113 1.113 0 0 1-.56.16c-.284 0-.533-.103-.747-.307a1.01 1.01 0 0 1-.32-.76Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="b">
        <path fill="#fff" d="M11 11h18v18H11z" />
      </clipPath>
      <filter
        id="a"
        width={40}
        height={42}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={2} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.043 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_106_972" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_106_972"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default SvgRoundAudioPlayButton;
