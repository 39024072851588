import CreatePresetContentCard from "./CreatePresetContentCard";
import { LoopsResponse, useLazyLoopsQuery } from "../../services/ContentApi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  InspirationResponse,
  useLazyGetCreativeInspirationQuery,
} from "../../services/InspirationApi";
import moment from "moment";
import InspirationsForQuickstartGrid from "./InspirationsForQuickstartGrid";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { setNewContent } from "../../store/contentSlice";
import { useAppSelector } from "../../helpers/hooks";
import { ContentType } from "../../constants/ContentType";
import { ContentWizardView } from "../../models/ContentWizardView";
import { INewContent } from "../../models/NewContent";
import { toggleContentWizard, toggleLoading } from "../../store/appSlice";
import { ContentSize } from "../../constants/ContentSize";
import CreativePopularThemesGrid from "./CreativePopularThemesGrid";
import { CreativeTheme, useLazyTrendingThemesQuery } from "../../services/CreativeApi";
import { Mediums } from "../../constants/Mediums";
import { Medium } from "../../assets/IconComponents";
import CustomRightArrow from "./CustomRightArrow";
import CustomLeftArrow from "./CustomLeftArrow";
import { useWindowSize } from "@uidotdev/usehooks";
import { Carousel } from "antd";

function ContentCreativePage() {
  const lastSelectedBrandId = useAppSelector(
    (k) => k.persisted.lastSelectedBrandId
  );
  const newContent = useAppSelector((k) => k.content.newContent);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inspirations, setInspirations] = useState<InspirationResponse[]>([]);
  const [trendingThemes, setTrendingThemes] = useState<CreativeTheme[]>([]);
  const size = useWindowSize();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentThemePage, setCurrentThemePage] = useState(1);

  const [
    getInspirations,
    {
      error: errorInspirationsLoading,
      isFetching: inspirationsFetching,
      data: inspirationsList,
    },
  ] = useLazyGetCreativeInspirationQuery();

  const [
    getTrendingThemes,
    {
      error: errorTrendingThemesLoading,
      isFetching: trendingThemesFetching,
      data: trendingThemeList,
    },
  ] = useLazyTrendingThemesQuery();

  useEffect(() => {
    dispatch(toggleLoading(inspirationsFetching));
  }, [inspirationsFetching]);

  useEffect(() => {
    if (inspirationsList && inspirationsList.length > 0) {
      setInspirations(inspirationsList);
    }
  }, [inspirationsList]);

  useEffect(() => {
    if (trendingThemeList && trendingThemeList.length > 0) {
      setTrendingThemes(trendingThemeList);
    }
  }, [trendingThemeList]);

  useEffect(() => {
    getInspirations({
      page: 0,
      size: 7,
    });
  }, []);

  useEffect(() => {
    getTrendingThemes({ page: 0, size: 8 });
  }, []);

  const isMobile = () => {
    return size.width && size.width <= 500;
  };

  return (
    <div className="flex-col w-full h-full mt-2">
      <div className="flex-col w-[1050px]">
        <Carousel
          slidesToShow={isMobile() ? 1 : 6}
          nextArrow={<CustomRightArrow />}
          prevArrow={<CustomLeftArrow />}
          dots={false}
          arrows={isMobile() ? true : false}
        >
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  medium: Mediums.filter(
                    (m) =>
                      m.contentType === ContentType.image && m.isCustom === true
                  ).at(0),
                  contentType: ContentType.image,
                  wizardView: ContentWizardView.contentDetails,
                  contentSize: ContentSize.creative_post,
                  imageHeight: "1080",
                  imageWidth: "1080",
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                  sliderCount: 4,
                  isCreative: true,
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="Post"
            sampleImagePath="content-creative-ad-post-sample.png"
          />

          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  medium: Mediums.filter(
                    (m) =>
                      m.contentType === ContentType.image && m.isCustom === true
                  ).at(0),
                  contentType: ContentType.image,
                  wizardView: ContentWizardView.contentDetails,
                  contentSize: ContentSize.creative_landscape,
                  imageHeight: "628",
                  imageWidth: "1200",
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                  isCreative: true,
                  sliderCount: 4,
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="Landscape"
            sampleImagePath="content-creative-ad-landscape-sample.png"
          />
          <CreatePresetContentCard
            onClick={() => {
              dispatch(
                setNewContent({
                  ...newContent,
                  medium: Mediums.filter(
                    (m) =>
                      m.contentType === ContentType.image && m.isCustom === true
                  ).at(0),
                  contentType: ContentType.image,
                  wizardView: ContentWizardView.contentDetails,
                  contentSize: ContentSize.creative_story,
                  imageHeight: "1920",
                  imageWidth: "1080",
                  loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                  brand: { id: lastSelectedBrandId },
                  isCreative: true,
                  sliderCount: 4,
                } as INewContent)
              );
              dispatch(toggleContentWizard());
            }}
            title="Story"
            sampleImagePath="content-creative-ad-story-sample.png"
          />
        </Carousel>
      </div>

      {trendingThemes.length > 0 && (
        <>
          <div className="mt-[50px] mb-[20px]">
            <div className="flex justify-between items-center w-full">
              <span className="text-H4 font-medium text-black w-72 sm:w-full truncate text-ellipsis overflow-hidden">
                Start with a Theme
              </span>
              <span className="text-BodySmall font-normal text-gray-500 text-right whitespace-nowrap ml-auto">
                Create your ad based on a theme. For example, select the Retro
                theme for a retro-style visual.
              </span>
            </div>
            <hr className="bg-border mt-1" />
          </div>
          <CreativePopularThemesGrid
            onClick={(revisionId) => {
              console.log("revisionId", revisionId);
            }}
            isCreative={true}
            onRefresh={() => {
              setCurrentThemePage(currentThemePage + 1);
              getTrendingThemes({
                page: currentThemePage,
                size: 8,
              });
            }}
            trendingThemes={trendingThemes}
          />
        </>
      )}

      {inspirations?.length > 0 && (
        <>
          <div className="mt-[50px] mb-[20px] ">
            <div className="flex justify-between items-center w-full">
              <span className="text-H4 font-medium text-black w-72 sm:w-full truncate text-ellipsis overflow-hidden">
                Inspirations For Quick Start
              </span>
              <span className="text-BodySmall font-normal text-gray-500 text-right whitespace-nowrap ml-auto">
                Get inspired! Apply an ad idea you like to your own ad.
              </span>
            </div>

            <hr className="bg-border mt-1" />
          </div>
          <InspirationsForQuickstartGrid
            onClick={(revisionId) => {}}
            isCreative={true}
            onRefresh={() => {
              setCurrentPage(currentPage + 1);
              getInspirations({
                page: currentPage,
                size: 7,
              });
            }}
            inspirations={inspirations}
          />
        </>
      )}
    </div>
  );
}

export default ContentCreativePage;
