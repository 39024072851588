
import type { SVGProps } from "react";
const SvgCake = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        viewBox="0 0 15 15"
        fill="none"
        {...props}
    >
        <path id="Vector" d="M2.5 13.75C2.32292 13.75 2.17448 13.6901 2.05469 13.5703C1.9349 13.4505 1.875 13.3021 1.875 13.125V10C1.875 9.65625 1.9974 9.36198 2.24219 9.11719C2.48698 8.8724 2.78125 8.75 3.125 8.75V6.25C3.125 5.90625 3.2474 5.61198 3.49219 5.36719C3.73698 5.1224 4.03125 5 4.375 5H6.875V4.09375C6.6875 3.96875 6.53646 3.81771 6.42188 3.64063C6.30729 3.46354 6.25 3.25 6.25 3C6.25 2.84375 6.28125 2.6901 6.34375 2.53906C6.40625 2.38802 6.5 2.25 6.625 2.125L7.28125 1.46875C7.30208 1.44792 7.375 1.41667 7.5 1.375C7.52083 1.375 7.59375 1.40625 7.71875 1.46875L8.375 2.125C8.5 2.25 8.59375 2.38802 8.65625 2.53906C8.71875 2.6901 8.75 2.84375 8.75 3C8.75 3.25 8.69271 3.46354 8.57812 3.64063C8.46354 3.81771 8.3125 3.96875 8.125 4.09375V5H10.625C10.9688 5 11.263 5.1224 11.5078 5.36719C11.7526 5.61198 11.875 5.90625 11.875 6.25V8.75C12.2188 8.75 12.513 8.8724 12.7578 9.11719C13.0026 9.36198 13.125 9.65625 13.125 10V13.125C13.125 13.3021 13.0651 13.4505 12.9453 13.5703C12.8255 13.6901 12.6771 13.75 12.5 13.75H2.5ZM4.375 8.75H10.625V6.25H4.375V8.75ZM3.125 12.5H11.875V10H3.125V12.5Z" fill="#8E8E8E" />
    </svg>
);
export default SvgCake;
