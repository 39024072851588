import * as React from "react";
import type { SVGProps } from "react";
const SvgLinkedin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M15.778 18A2.222 2.222 0 0 0 18 15.778V4.222A2.222 2.222 0 0 0 15.778 2H4.222A2.222 2.222 0 0 0 2 4.222v11.556C2 17.005 2.995 18 4.222 18h11.556ZM6.888 7.778H4.668v7.555h2.222V7.778Zm-1.13-.89h.013c.689 0 1.118-.494 1.118-1.11-.013-.631-.429-1.111-1.105-1.111-.675 0-1.117.48-1.117 1.11 0 .618.429 1.112 1.092 1.112Zm7.353 8.445h2.222v-4.322c0-2.233-1.193-3.233-2.783-3.233-1.283 0-1.785.666-2.106 1.162V7.778H8.222v7.555h2.222v-4.127c-.004-.229-.008-.547.045-.676.175-.434.536-.884 1.203-.884.875 0 1.42.666 1.42 1.643v4.044Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLinkedin;
