import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Button, Input } from "antd";
import PrimaryButton from "../../components/PrimaryButton";
import { toggleLoading } from "../../store/appSlice";

import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputText from "../../components/InputText";
import { setAccessToken, setRefreshToken, toggleLoggedIn } from "../../store/persistedSlice";

import Icon from "../../components/Icon";
import WarningMessage from "../../components/WarningMessage";
import { Email, FacebookColor, Google, LogoDark, Password, TwitterColor } from "../../assets/IconComponents";
import { getAuth, signInWithPopup, getRedirectResult, signInWithRedirect, TwitterAuthProvider, FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";
import SuccessMessage from "../../components/SuccessMessage";
import { SocialProvider } from "../../services/ContentApi.old/Model/LoginRequestModel";
import { useLazyList1Query } from "../../services/AIModelApi";
import { useLoginMutation, useSocialLoginMutation } from "../../services/AuthApi";


const LoginPage = () => {
  const dispatch = useDispatch()
  const fromSocialLogin = localStorage.getItem('fromSocialLogin');
  if (fromSocialLogin !== null) {
    console.log('Loading...')
    dispatch(toggleLoading(true));
  }


  useEffect(() => {
    const auth = getAuth();
    getRedirectResult(auth)
      .then((result) => {
        console.log("Redirect result: ", result);
        console.log("Redirect called for :", result?.user);
        if (result?.user === undefined) {
          console.log('Stop loading')
          dispatch(toggleLoading(false))
        }
        const user = result?.user;
        var email = ""
        if (user?.providerData != undefined && user.providerData.length > 0)
          email = user.providerData[0].email ?? ""



        user?.getIdToken().then(k => {
          socialLogin({
            socialLoginRequest: {
              email: email ?? "",
              // ai_model_names: "GPT-4,GPT-3.5",
              access_token: k ?? "",
            }
          })
        })

      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        console.log('Stop loading')
        dispatch(toggleLoading(false))
      });
  }, []);


  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const navigate = useNavigate();
  const { t } = useTranslation();
  const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
  const [warningMessage, setWarningMessage] = useState("")
  const [hasEmailError, setEmailError] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [loginUser, { isLoading: logining, isSuccess: loginSuccess, isError: loginError, error, data }] = useLoginMutation();
  const [socialLogin, { isLoading: socialLoginLoading, data: socialLoginData, isError: socialLoginError }] = useSocialLoginMutation()
  const [searchParams] = useSearchParams();
  const [fetchAiModels, { data: aiModelDatas }] = useLazyList1Query()

  const isFormValid = () => {
    if (email?.trim() === "" ||
      password?.trim() === "") {
      setWarningMessage(t('please-enter-all-informations') ?? "")
      return false
    }

    if (!emailRegex.test(email ?? "")) {
      setEmailError(true);
      return false
    }

    return true
  }



  useEffect(() => {
    fetchAiModels()

    const isVerified = searchParams.get("verified")
    if (isVerified) {
      setSuccessMessage(t('password-activation-is-successful') ?? "")
      setTimeout(() => {
        setSuccessMessage("")
      }, 2000);
    }

  }, [])

  const login = () => {
    if (!isFormValid()) { return }

    loginUser({
      authenticationRequest: {
        email: (email ?? "").toLowerCase(),
        password: password ?? ""
      }
    })
  }

  useEffect(() => {
    if (data) {
      dispatch(setAccessToken(data.access_token ?? ""))
      dispatch(setRefreshToken(data.refresh_token ?? ""))
    }
  }, [data])

  useEffect(() => {
    if (loginSuccess) {
      if ((data?.account_status ?? "") == "frozen") {
        dispatch(toggleLoading(false));
        navigate("/account-frozen");
      } else {
        dispatch(toggleLoggedIn(true));
        dispatch(toggleLoading(false));
        navigate("/app/homepage");
      }
    }
  }, [loginSuccess]);

  useEffect(() => {
    if (logining) {
      dispatch(toggleLoading(logining))
    }
    else {
      const fromSocialLogin = localStorage.getItem('fromSocialLogin');
      if (fromSocialLogin) {
        localStorage.removeItem('fromSocialLogin');
      }
      else {
        dispatch(toggleLoading(false))
      }
    }
  }, [logining])


  useEffect(() => {
    if (error) {
      setWarningMessage((error as any)?.data?.message);
    }

  }, [loginError])

  const signup = () => {
    navigate("/register")
  }

  const forgotPassword = () => {
    navigate("/forgotpassword")
  }

  const twitterLogin = () => {
    console.log('Stop loading')
    dispatch(toggleLoading(false))
    const provider = new TwitterAuthProvider();
    provider.addScope("email")

    const auth = getAuth();
    localStorage.setItem('fromSocialLogin', 'google');
    dispatch(toggleLoading(true));
    signInWithRedirect(auth, provider);
  }

  const facebookLogin = () => {
    console.log('Stop loading')
    dispatch(toggleLoading(false))

    const provider = new FacebookAuthProvider();
    provider.addScope("email")

    const auth = getAuth();
    localStorage.setItem('fromSocialLogin', 'facebook');
    dispatch(toggleLoading(true));
    signInWithRedirect(auth, provider);
  }

  const googleLogin = () => {
    console.log('Stop loading')
    dispatch(toggleLoading(false))
    const provider = new GoogleAuthProvider();
    provider.addScope("email")

    const auth = getAuth();
    localStorage.setItem('fromSocialLogin', 'google');
    dispatch(toggleLoading(true));
    signInWithRedirect(auth, provider);
  }

  useEffect(() => {
    if (socialLoginData) {
      console.log('Stop loading')
      dispatch(toggleLoading(false))
      dispatch(setAccessToken(socialLoginData.access_token ?? ""))
      dispatch(setRefreshToken(socialLoginData.refresh_token ?? ""))
      dispatch(toggleLoggedIn(true))
      navigate("/app/homepage")
    }
  }, [socialLoginData])

  useEffect(() => {
    if (socialLoginError) {
      console.log('Stop loading')
      dispatch(toggleLoading(false))
    }
  }, [socialLoginError])









  return (
    <>
      <div className="flex flex-col w-full items-center max-lg:pt-6 lg:justify-center">
        {/* <img src={require("../../assets/Logo_Dark.png")} className="mb-10" /> */}
        <LogoDark
          width={200}
          height={32}
          preserveAspectRatio="xMinYMin meet"
          viewBox="0 0 150 75"
          className="mb-[40px] cursor-pointer"
          onClick={() => {
            window.location.replace("https://www.evercopy.ai/");
          }}
        />
        <div
          className={`w-full sm:w-2/3 md:w-2/3 lg:w-2/5 rounded-xl bg-white p-6 flex items-center justify-center flex-col border`}
        >
          <div className="flex justify-between w-full">
            <span className="text-H3 font-medium text-black">
              {t("auth.login.header")}
            </span>
            {!successMessage && (
              <button
                className="text-red text-BodyMedium underline"
                onClick={() => {
                  signup();
                }}
              >
                {t("i-dont-have-an-account")}
              </button>
            )}
          </div>

          {(warningMessage != "" && !loginSuccess) &&
            <Alert message={warningMessage ? warningMessage : t("warning")} type="error" showIcon className="w-full mt-5" />
          }
          {successMessage &&
            <Alert message={successMessage} type="success" showIcon className="w-full mt-5" />
          }

          {!successMessage && (
            <>
              <div className="flex flex-col items-stretch w-full mt-5">
                <Input size="large" placeholder={t("email") ?? ""} prefix={<Email width={16} height={16} fill="" />}
                  className={`bg-inputBg ${hasEmailError ? "border-red" : "mb-2"}`}
                  onChange={(e) => {
                    setWarningMessage("");
                    setEmailError(false);
                    setEmail(e.target.value);
                  }} />
                {hasEmailError && <span className="text-BodyMedium text-red mt-1 mb-2">{t("please-check-email-address")}</span>}

                <Input.Password size="large" visibilityToggle={false} placeholder={t("password") ?? ""} prefix={<Password width={16} height={16} fill="" />}
                  className="bg-inputBg"
                  onChange={(e) => {
                    setWarningMessage("");
                    setPassword(e.target.value);
                  }} />

                <PrimaryButton
                  title={t("continue")}
                  onClick={() => {
                    login();
                  }}
                  className="mt-5"
                />

                <Button
                  type="link"
                  className="text-gray text-BodyMedium underline mt-3 hover:!text-red"
                  onClick={() => {
                    forgotPassword();
                  }}
                >
                  {t("forgotPassword")}
                </Button>
              </div>
              <div className="mt-5 min-w-full flex flex-col justify-between items-stretch">
                <Button className="text-H5 font-normal" icon={<Google width={16} height={16} />} iconPosition="start" onClick={() => googleLogin()}>
                  {t("login-with-google")}
                </Button>

                <Button className="text-H5 font-normal mt-2" icon={<TwitterColor width={16} height={16} />} iconPosition="start" onClick={() => twitterLogin()}>
                  {t("login-with-twitter")}
                </Button>

                <Button className="text-H5 font-normal mt-2" icon={<FacebookColor width={16} height={16} />} iconPosition="start" onClick={() => facebookLogin()}>
                  {t("login-with-facebook")}
                </Button>

              </div>
              <span className="text-BodySmall text-gray hover:text-gray mt-2">
                {t("by-continuing-you-agree-to-our")}
                <a
                  href="https://www.evercopy.ai/terms"
                  target="_blank"
                  className="underline mx-1"
                >
                  {t("terms-of-use")}
                </a>{" "}
                {t("and")}
                <a
                  href="https://www.evercopy.ai/privacy-policy"
                  target="_blank"
                  className="underline mx-1"
                >
                  {t("privacy-policy")}
                </a>
                .
              </span>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default LoginPage;
