import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import { useNavigate } from "react-router-dom";
import { Carousel } from "antd";
import CustomRightArrow from "../ContentWizard/CustomRightArrow";
import CustomLeftArrow from "../ContentWizard/CustomLeftArrow";
import PreviousDesignsGridCard from "../ContentWizard/PreviousDesignsGridCards";
import { useWindowSize } from "@uidotdev/usehooks";
import { LoopsResponse, useLazyLoopsQuery } from "../../services/ContentApi";
import { toggleLoading } from "../../store/appSlice";
import { t } from "i18next";


const ContinueYouLeft = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const size = useWindowSize();
    const [items, setItems] = useState<LoopsResponse[]>([]);
    const collapsedModeEnabled = useAppSelector(
        (state) => state.app.collapsedModeEnabled
    );

    const [
        getContentList,
        {
            isLoading: contentListLoading,
            isFetching: contentsFetching,
            data: contentList,
        },
    ] = useLazyLoopsQuery();


    useEffect(() => {
        dispatch(
            toggleLoading(
                contentListLoading
            )
        );
    }, [
        contentsFetching,
        contentListLoading
    ]);

    useEffect(() => {
        setItems([]);
        getLoopList(0);
        return () => {
            setItems([]);
        };
    }, []);


    useEffect(() => {
        if (contentList && contentList.length > 0) {
            setItems(contentList);
        }
    }, [contentList]);

    const getLoopList = (page) => {
        var payload = {
            page: page,
            size: 20,
        };

        payload["contentType"] = "";
        payload["platform"] = "";
        payload["brandId"] = "";

        getContentList(payload);
    };

    const isMobile = () => {
        return size.width && size.width <= 500;
    };

    return (
        <> {
            items?.length &&
            <div className="w-full flex flex-col gap-5">
                <div className="mt-[50px] mb-[20px]">
                    <div className="flex justify-between items-center w-full">
                        <span className="text-H4 font-medium text-black   truncate text-ellipsis overflow-hidden">
                            Continue Where You Left Off
                        </span>
                        <button
                            className="text-red text-BodyMedium underline font-inter-tight"
                            onClick={() => {
                                navigate("/app/loops")
                            }}
                        >
                            {t("see-all")}
                        </button>
                    </div>

                    <hr className="bg-border mt-1 " />
                </div>
                <div className={`flex-col ${collapsedModeEnabled ? 'max-w-[1180px]' : 'max-w-[1050px]'} `}>
                    <Carousel
                        slidesToShow={isMobile() ? 1 : (collapsedModeEnabled ? 6 : 5)}
                        nextArrow={<CustomRightArrow />}
                        prevArrow={<CustomLeftArrow />}
                        dots={false}
                        arrows={items?.length > 5}
                        adaptiveHeight={true}
                    >
                        {items.map((k, index) => (
                            <PreviousDesignsGridCard
                                key={index}
                                loop={k}
                                updateLoops={() => { }}
                                onSelect={(contentId) => {
                                    navigate(`/app/loopdetails/` + contentId);
                                }}
                            />
                        ))}
                    </Carousel>
                </div>
            </div>
        }
        </>

    );
};

export default ContinueYouLeft;
