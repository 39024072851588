import * as React from "react";
import type { SVGProps } from "react";
const SvgInspireMenuCollapsedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="40" height="40" fill="#121315" />
    <g clipPath="url(#clip0_4709_23362)">
      <path
        d="M18.0552 25.1986C17.8775 25.5894 17.3225 25.5894 17.1448 25.1986L15.3026 21.1457C15.2526 21.0356 15.1644 20.9474 15.0543 20.8974L11.0014 19.0552C10.6106 18.8775 10.6106 18.3225 11.0014 18.1448L15.0543 16.3026C15.1644 16.2526 15.2526 16.1644 15.3026 16.0543L17.1448 12.0014C17.3225 11.6106 17.8775 11.6106 18.0552 12.0014L19.8974 16.0543C19.9474 16.1644 20.0356 16.2526 20.1457 16.3026L24.1986 18.1448C24.5894 18.3225 24.5894 18.8775 24.1986 19.0552L20.1457 20.8974C20.0356 20.9474 19.9474 21.0356 19.8974 21.1457L18.0552 25.1986ZM26.6595 27.9948C26.4807 28.386 25.9242 28.3838 25.7484 27.9912L25.1029 26.5491C25.0527 26.437 24.963 26.3473 24.8509 26.2971L23.4088 25.6516C23.0162 25.4758 23.014 24.9193 23.4052 24.7405L24.8545 24.078C24.9644 24.0277 25.0524 23.9393 25.1022 23.8291L25.7492 22.3976C25.9256 22.0072 26.4792 22.005 26.6588 22.394L27.3228 23.8327C27.3726 23.9407 27.4593 24.0274 27.5673 24.0772L29.006 24.7412C29.395 24.9208 29.3928 25.4744 29.0024 25.6508L27.5709 26.2978C27.4607 26.3476 27.3723 26.4356 27.322 26.5455L26.6595 27.9948Z"
        fill={props.fill ?? "#8E8E8E"}
      />
    </g>
    <defs>
      <clipPath id="clip0_4709_23362">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(10 10)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgInspireMenuCollapsedIcon;

