import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { FunctionComponent, useState } from "react";
import LogoSelector from "./LogoSelector";
import ProductSelector from "./ProductSelector";
import { ContentType } from "../../../constants/ContentType";
import Subject from "./Subject";
import ApplyFilters from "./ApplyFilters";
import { FileProps } from "../../../services/BrandApi";
import { AudienceResponse } from "../../../services/AudiencetApi";
import { ContentSize } from "../../../constants/ContentSize";
import LogoSelectorIcon from "../../../assets/IconComponents/LogoSelectorIcon";
import ToggleButton from "../../../components/ToggleButton";
import { setNewContent } from "../../../store/contentSlice";
import ProductSelectorIcon from "../../../assets/IconComponents/ProductSelectorIcon";
import TechnicalDocumentSelector from "../BlogDetails/TechnicalDocumentSelector";
import CreativitySlider from "../BlogDetails/CreativitySlider";

interface ContentProps {
  logoSelectionList: FileProps[]
  productSelectionList: FileProps[]
  referenceFilesSelectionList: FileProps[]
  onAudienceEdit: (data: AudienceResponse) => void
  onAudienceCreate: () => void
  recentlyAddedAudience: AudienceResponse[]
  isInSide?: boolean
  setIsInspirationThemeSelectionOpen?: (status: boolean) => void
  setIsLogoSelectionOpen?: (status: boolean) => void
  setIsProductSelectorOpen?: (status: boolean) => void
  setAdId?: (id: string) => void
  onCreditError?: () => void
}

const Content: FunctionComponent<ContentProps> = (props: ContentProps) => {
  const dispatch = useAppDispatch();
  const newContent = useAppSelector((k) => k.content.newContent);

  return (
    <>
      <div className={`w-full ${props.isInSide ? 'min-h-[calc(70vh-135px)] max-h-[calc(85vh-80px)] overflow-scroll mb-[80px]' : 'h-[calc(100vh-135px)]'} flex-col p-[20px] gap-[12px] inline-flex border-b-1 overflow-scroll scrollbar-hide`}>
        <div className="w-full h-44 p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-5 inline-flex">
          <Subject isCreativePages={true} hideTitle={props.isInSide} useKeywords={newContent && newContent?.isCreative} />
        </div>
        <div className="w-full p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-5 inline-flex">
          <ApplyFilters
            hideTitle={props.isInSide}
            onAudienceEdit={props.onAudienceEdit}
            onAudienceCreate={props.onAudienceCreate}
            recentlyAddedAudience={props.recentlyAddedAudience}
            audiencePopupClassname={`${props.isInSide ? 'w-[240px]' : 'w-[520px]'}`}
          />
        </div>
        {props.isInSide && newContent?.contentType === ContentType.image && <>
          {props.setIsInspirationThemeSelectionOpen && <>
            <div className={`w-full flex justify-between items-center relative border-1 p-3 rounded-[6px]`}>
              <div className="justify-start items-center gap-2.5 flex">
                <LogoSelectorIcon fill={!(newContent?.adId || newContent?.creativeImageId || newContent?.creative_theme) ? "#8E8E8E" : "#FF5555"} className="flex shrink-0" />
                <text
                  className="text-sm font-semibold text-black w-72 sm:w-full truncate text-ellipsis overflow-hidden">
                  Choose Inspiration or Theme
                </text>
              </div>

              <ToggleButton isChecked={!!(newContent?.adId || newContent?.creativeImageId || newContent?.creative_theme)}
                handleCheckBoxChange={function (checked: boolean): void {
                  if (props.setIsInspirationThemeSelectionOpen) {
                    props.setIsInspirationThemeSelectionOpen(checked)
                  }
                  if (!checked) {
                    dispatch(
                      setNewContent({
                        ...newContent,
                        adId: undefined,
                        creative_theme: undefined,
                        creativeImageId: undefined,
                      })
                    );
                  }
                }}
                checkedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                checkedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition translate-x-full"
                uncheckedBoxClassName="box block h-6 w-[40px] rounded-full bg-neutral-200 border-2 border-neutral"
                uncheckedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-neutral-400 transition"
              />
            </div>
          </>}
          {props.setIsLogoSelectionOpen && <>
            <div className={`w-full flex justify-between items-center relative border-1 p-3 rounded-[6px]`}>
              <div className="justify-start items-center gap-2.5 flex">
                <LogoSelectorIcon fill={!(newContent?.logoFileId) ? "#8E8E8E" : "#FF5555"} className="flex shrink-0" />
                <text
                  className="text-sm font-semibold text-black w-72 sm:w-full truncate text-ellipsis overflow-hidden">
                  Add Logo
                </text>
              </div>

              <ToggleButton isChecked={!!(newContent?.logoFileId)}
                handleCheckBoxChange={function (checked: boolean): void {
                  if (props.setIsLogoSelectionOpen) {
                    props.setIsLogoSelectionOpen(checked)
                  }
                  if (!checked) {
                    dispatch(
                      setNewContent({
                        ...newContent,
                        logoFileId: undefined,
                      })
                    );
                  }
                }}
                checkedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                checkedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition translate-x-full"
                uncheckedBoxClassName="box block h-6 w-[40px] rounded-full bg-neutral-200 border-2 border-neutral"
                uncheckedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-neutral-400 transition"
              />
            </div>
          </>}
          {props.setIsProductSelectorOpen && <>
            <div className={`w-full flex justify-between items-center relative border-1 p-3 rounded-[6px]`}>
              <div className="justify-start items-center gap-2.5 flex">
                <ProductSelectorIcon fill={!(newContent?.productFileId) ? "#8E8E8E" : "#FF5555"} className="flex shrink-0" />
                <text
                  className="text-sm font-semibold text-black w-72 sm:w-full truncate text-ellipsis overflow-hidden">
                  Add Images
                </text>
              </div>

              <ToggleButton isChecked={!!(newContent?.productFileId)}
                handleCheckBoxChange={function (checked: boolean): void {
                  if (props.setIsProductSelectorOpen) {
                    props.setIsProductSelectorOpen(checked)
                  }
                  if (!checked) {
                    dispatch(
                      setNewContent({
                        ...newContent,
                        productFileId: undefined,
                      })
                    );
                  }
                }}
                checkedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                checkedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition translate-x-full"
                uncheckedBoxClassName="box block h-6 w-[40px] rounded-full bg-neutral-200 border-2 border-neutral"
                uncheckedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-neutral-400 transition"
              />
            </div>
          </>}
        </>
        }
        {!props.isInSide && newContent?.contentType === ContentType.image
          && newContent?.contentSize !== ContentSize.mobileleaderb
          &&
          (
            <div className={`w-full ${props.isInSide ? '' : 'h-[214px] justify-start items-start gap-3 inline-flex'} `}>
              <LogoSelector logoSelectionList={props.logoSelectionList} />
              <ProductSelector
                productSelectionList={props.productSelectionList}
              />
            </div>
          )}
        {newContent?.medium?.name === "Blog" &&
          <>
            <div className="w-full justify-start items-start gap-3 inline-flex">
              <CreativitySlider isInSide={props.isInSide} />
            </div>
            <div className="w-full h-[214px] justify-start items-start gap-3 inline-flex">
              <TechnicalDocumentSelector isInSide={props.isInSide} referenceFileSelectionList={props.referenceFilesSelectionList} />
            </div>

          </>
        }
      </div>
    </>
  );
}

export default Content;
