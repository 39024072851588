import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'
import { ILoop } from '../models/Loop'
import { INewContent } from '../models/NewContent'

interface ContentState {
    loops: ILoop[],
    newContent?: INewContent
    topParentId?: string
    isContentLoading: boolean,
    guidelinesState: number,
    selectedInspirationForYou?: any,
    selectedThemeImage?: any,
    selectedTechnicalDocument?: any,
    creativeThemeSearchTerm?: string,
}

// Define the initial state using that type
const initialState: ContentState = {
    loops: [],
    topParentId: "",
    isContentLoading: false,
    guidelinesState: 0
}

export const contentSlice = createSlice({
    name: 'content',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setLoops: (state, loops: PayloadAction<ILoop[]>) => {
            state.loops = loops.payload
        },
        setNewContent: (state, content: PayloadAction<INewContent | undefined>) => {
            state.newContent = content.payload
        },
        setTopParentId: (state, content: PayloadAction<string | undefined>) => {
            state.topParentId = content.payload
        },
        setContentLoading: (state, isLoading: PayloadAction<boolean>) => {
            state.isContentLoading = isLoading.payload
        },
        setGuidelinesState: (state, content: PayloadAction<number>) => {
            state.guidelinesState = content.payload
        },
        setSelectedForYouInspiration: (state, content: PayloadAction<any>) => {
            state.selectedInspirationForYou = {
                ...content.payload,
                id: content.payload.fileId ?? content.payload.id,
                name: content.payload.subCategory ?? content.payload.name
            }
        },
        setSelectedThemeImage: (state, content: PayloadAction<any>) => {
            state.selectedThemeImage = {
                ...content.payload,
                subChildConcept: content.payload.name ?? content.payload.subChildConcept,
                imageId: content.payload.uploadedId ?? content.payload.imageId
            };
        },
        setSelectedTechnicalDocument: (state, content: PayloadAction<any>) => {
            state.selectedTechnicalDocument = content.payload
        },
        setCreativeThemeSearchTerm: (state, content: PayloadAction<any>) => {
            state.creativeThemeSearchTerm = content.payload
        }
    },
})

export const { setLoops, setNewContent, setTopParentId, setContentLoading, setGuidelinesState, setSelectedForYouInspiration, setSelectedThemeImage, setSelectedTechnicalDocument, setCreativeThemeSearchTerm } = contentSlice.actions

export const loops = (state: RootState) => state.content.loops
export const newContent = (state: RootState) => state.content.newContent
export const guidelinesState = (state: RootState) => state.content.guidelinesState
export const selectedInspirationsForYou = (state: RootState) => state.content.selectedInspirationForYou
export const selectedThemeImage = (state: RootState) => state.content.selectedThemeImage
export const selectedTechnicalDocument = (state: RootState) => state.content.selectedTechnicalDocument
export const creativeThemeSearchTerm = (state: RootState) => state.content.creativeThemeSearchTerm

export default contentSlice.reducer
