import * as React from "react";
const ProductSelectorIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect opacity="0.1" width="28" height="28" rx="4" fill={props.fill} />
    <path d="M14 17C13.2928 17 12.6145 16.719 12.1144 16.219C11.6143 15.7189 11.3334 15.0406 11.3334 14.3333C11.3334 13.6261 11.6143 12.9478 12.1144 12.4477C12.6145 11.9476 13.2928 11.6667 14 11.6667C14.7073 11.6667 15.3856 11.9476 15.8857 12.4477C16.3858 12.9478 16.6667 13.6261 16.6667 14.3333C16.6667 15.0406 16.3858 15.7189 15.8857 16.219C15.3856 16.719 14.7073 17 14 17Z" fill={props.fill} />
    <path opacity="0.5" d="M18.6667 10.3333H17.8134L17.6034 9.7C17.4707 9.30178 17.2161 8.95544 16.8755 8.71012C16.5349 8.46481 16.1258 8.33298 15.706 8.33333H12.2934C11.8736 8.33298 11.4645 8.46481 11.1239 8.71012C10.7833 8.95544 10.5287 9.30178 10.396 9.7L10.1854 10.3333H9.33337C8.8031 10.3339 8.2947 10.5447 7.91975 10.9197C7.54479 11.2947 7.3339 11.8031 7.33337 12.3333V17.6667C7.3339 18.1969 7.54479 18.7053 7.91975 19.0803C8.2947 19.4553 8.8031 19.6661 9.33337 19.6667H18.6667C19.197 19.6661 19.7054 19.4553 20.0803 19.0803C20.4553 18.7053 20.6662 18.1969 20.6667 17.6667V12.3333C20.6662 11.8031 20.4553 11.2947 20.0803 10.9197C19.7054 10.5447 19.197 10.3339 18.6667 10.3333ZM14 17C13.2928 17 12.6145 16.719 12.1144 16.219C11.6143 15.7189 11.3334 15.0406 11.3334 14.3333C11.3334 13.6261 11.6143 12.9478 12.1144 12.4477C12.6145 11.9476 13.2928 11.6667 14 11.6667C14.7073 11.6667 15.3856 11.9476 15.8857 12.4477C16.3858 12.9478 16.6667 13.6261 16.6667 14.3333C16.6667 15.0406 16.3858 15.7189 15.8857 16.219C15.3856 16.719 14.7073 17 14 17Z" fill={props.fill} />
  </svg>

);
export default ProductSelectorIcon;
