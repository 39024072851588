
import * as React from "react";
import type { SVGProps } from "react";
const SvgTritoneUnlock = (props: SVGProps<SVGSVGElement>) => (
  <svg
  width="56"
  height="57"
  viewBox="0 0 56 57"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect opacity="0.1" y="0.5" width="56" height="56" rx="4" fill="#FF5555" />
  <path
    opacity="0.5"
    d="M37.3333 27.3333H18.6667C18.0223 27.3333 17.5 27.8557 17.5 28.5V39C17.5 39.6443 18.0223 40.1667 18.6667 40.1667H37.3333C37.9777 40.1667 38.5 39.6443 38.5 39V28.5C38.5 27.8557 37.9777 27.3333 37.3333 27.3333Z"
    fill="#FF5555"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M28 18C25.4226 18 23.3333 20.0892 23.3333 22.6667V27.3333C23.3333 27.9777 22.811 28.5 22.1667 28.5C21.5223 28.5 21 27.9777 21 27.3333V22.6667C21 18.8006 24.1339 15.6667 28 15.6667C31.8661 15.6667 35 18.8006 35 22.6667V27.3333C35 27.9777 34.4777 28.5 33.8333 28.5C33.189 28.5 32.6667 27.9777 32.6667 27.3333V22.6667C32.6667 20.0892 30.5774 18 28 18ZM28 30.8333C28.6443 30.8333 29.1667 31.3557 29.1667 32V35.5C29.1667 36.1443 28.6443 36.6667 28 36.6667C27.3557 36.6667 26.8333 36.1443 26.8333 35.5V32C26.8333 31.3557 27.3557 30.8333 28 30.8333Z"
    fill="#FF5555"
  />
</svg>
);
export default SvgTritoneUnlock;
