import { useEffect } from "react";
import { setDialog, toggleDialog } from "../../../store/appSlice";
import { useAppDispatch } from "../../../helpers/hooks";
import { toggleLoading } from "../../../store/appSlice";
import { t } from "i18next";
import { useRef } from "react";
import { clickOutside } from "../../../helpers/ClickOutside";
import ConnectSocialAccountCard from "./ConnectSocialAccountCard";
import SvgFacebookColor from "../../../assets/IconComponents/FacebookColor";
import SvgInstagramColor from "../../../assets/IconComponents/InstagramColor";
import SvgGoogleAdsColor from "../../../assets/IconComponents/GoogleAdsColor";
import SvgXSocialsIcon from "../../../assets/IconComponents/XSocialsIcon";
import SvgLinkedinColor from "../../../assets/IconComponents/LinkedinColor";
import { useSaveTokenInfoMutation } from "../../../services/SocialMediaApi";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { TwitterAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { useGoogleLogin } from "@react-oauth/google";
import { useAddGoogleAdsAccountMutation, useAddMetaAccountMutation } from "../../../services/AdManagerApi";
import { fbLogin, getFacebookLoginStatus, initFacebookSdk } from "../../../helpers/FacebookSdk";

export interface IAddSocialAccountsProps {
  onClose: () => void
}

function AddSocialAccountsPopupOpener(props: IAddSocialAccountsProps) {
  const dispatch = useAppDispatch();
  useEffect(() => { showSubscriptionDialog(); }, [])

  const showSubscriptionDialog = () => {
    dispatch(setDialog({
      actions: <></>,
      title: "",
      message: "",
      customTemplate: (
        <AddSocialAccountPopup {...props}></AddSocialAccountPopup>
      ),
    }));
    dispatch(toggleDialog(true));
  }

  return (<></>)
}

function AddSocialAccountPopup(props: IAddSocialAccountsProps) {


  const dispatch = useAppDispatch();
  const [saveSocialToken, { isLoading: isTokenSaving, isSuccess: tokenSaved }] = useSaveTokenInfoMutation()
  const [addGoogleAdsAccount, { isSuccess: googleSuccess }] = useAddGoogleAdsAccountMutation();


  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      return clickOutside(ref.current, () => {
        dispatch(toggleDialog(false));
        props.onClose();
      });
    }
  }, [ref.current]);

  const [addMetaAccount, { isSuccess: metaSuccess }] = useAddMetaAccountMutation();

  useEffect(() => {
    dispatch(toggleDialog(false));
    props.onClose();
  }, [metaSuccess, googleSuccess])

  function login() {
    fbLogin().then((response) => {
      console.log(response);
      if (response.status === "connected") {
        addMetaAccount({ metaAddAccountRequest: { access_token: response.authResponse.accessToken, user_id: response.authResponse.userID } })
      } else {
      }
    });
  }

  useEffect(() => {
    initFacebookSdk().then(() => {
      getFacebookLoginStatus().then((response) => {
        if (response == null) {
          console.log("No login status for the person");
        } else {
          console.log(response);
        }
      });
    });
  }, [])

  const instagramLogin = () => {
    return new Promise<any>((resolve, reject) => {
      window.FB.login((response) => {
        saveSocialToken({
          socialMediaRequest: {
            // brandId: brandInfo.id ?? "",
            channelType: "INSTAGRAM",
            userId: response?.authResponse?.userID,
            accessToken: response?.authResponse?.accessToken ?? ""
          }
        })
        resolve(response)
      }, {
        scope: 'pages_read_engagement,pages_show_list,pages_manage_posts,instagram_content_publish,business_management,instagram_basic',
        return_scopes: true,
      })
    })
  }

  const twitterLogin = () => {
    console.log('Stop loading')
    dispatch(toggleLoading(false))
    const provider = new TwitterAuthProvider();
    provider.addScope("tweet.write")
    const auth = getAuth();
    // localStorage.setItem('fromSocialLogin', 'google');
    // dispatch(toggleLoading(true));

    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        const credential = TwitterAuthProvider.credentialFromResult(result);
        saveSocialToken({
          socialMediaRequest: {
            // brandId: brandInfo.id ?? "",
            channelType: "X",
            consumerKey: credential?.accessToken,
            consumerSecret: credential?.secret
          }
        })
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = TwitterAuthProvider.credentialFromError(error);
        debugger
        // ...
      });
  }

  const { linkedInLogin } = useLinkedIn({
    clientId: '77ponl8o7gn1fa',
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      console.log('linked in connection successfull')
      saveSocialToken({
        socialMediaRequest: {
          // brandId: brandInfo.id ?? "",
          channelType: "LINKEDIN",
          code: code,
          redirectUri: `${window.location.origin}/linkedin`
        }
      })
    },
    onError: (error) => {
      console.log('linked in connection failed')
      console.log(error);
    },
    scope: "profile openid w_member_social email"
  });

  const googleLogin = useGoogleLogin({
    scope: "https://www.googleapis.com/auth/adwords",
    ux_mode: "popup",
    onSuccess: (tokenResponse) => {
      console.log('Google login successful', tokenResponse);
      addGoogleAdsAccount({ googleAdsAddAccountRequest: { access_code: tokenResponse.code } });
    },
    onError: () => {
      console.error('Google login failed');
    },
    flow: 'auth-code',
  });


  useEffect(() => {
    if (tokenSaved) {
      dispatch(toggleDialog(false));
      props.onClose();
    }
  }, [tokenSaved])


  return (
    <>
      <div className="fixed lg:top-0 left-0 right-0 bottom-0 z-50 flex flex-col items-center max-lg:justify-end max-lg:mb-1 justify-center h-screen" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <div
          ref={ref}
          className="w-full mx-2 md:mx-0 md:w-3/5 flex flex-col justify-center bg-white p-5 rounded-[10px]"
        >
          <>
            <div className="h-10 items-center flex justify-between">
              <text className="text-black text-H6 mb-3">
                {t("add-new-account")}
              </text>
            </div>
            <hr className="bg-border -ml-5 -mr-5" />
            <div className="w-full mt-[20px] flex-col">
              <div className="flex flex-row w-full gap-[16px] p-[9px] shrink-0">
                <ConnectSocialAccountCard name="Facebook" icon={SvgFacebookColor} onClicked={() => { login() }} />
                <ConnectSocialAccountCard name="Instagram" icon={SvgInstagramColor} onClicked={() => { instagramLogin() }} />
                <ConnectSocialAccountCard name="Google Ads" icon={SvgGoogleAdsColor} onClicked={() => { googleLogin() }} />
                <ConnectSocialAccountCard name="X(Twitter)" icon={SvgXSocialsIcon} onClicked={() => { twitterLogin() }} />
                <ConnectSocialAccountCard name="Linkedin" icon={SvgLinkedinColor} onClicked={() => {
                  linkedInLogin();
                }} />
              </div>
            </div>

          </>
        </div>
      </div>
    </>
  );
}
export default AddSocialAccountsPopupOpener;

