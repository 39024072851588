import { t } from "i18next";
import { Close, Picture } from "../../assets/IconComponents";
import SvgCloseLittle from "../../assets/IconComponents/CloseLittle";
import SvgText from "../../assets/IconComponents/Text";
import ContentContainer from "../ContentContainer";
import IconButton from "../IconButton";
import BrandSelectorItem from "./BrandSelectorItem";
import { useEffect, useState } from "react";
import { IBrandData } from "./BrandSelector";
import { BrandRequest } from "../../services/BrandApi";
import PrimaryButton from "../PrimaryButton";
import { useNavigate } from "react-router";

export interface IBrandSelectorPopupProps {
    data: BrandRequest[]
    closePopup: () => void
    selectedBrand: BrandRequest | undefined
    selectedBrandChanged: (data: BrandRequest | undefined) => void
}

function BrandSelectorPopup(props: IBrandSelectorPopupProps) {
    const [selectedItem, setSelectedItem] = useState<BrandRequest>()
    const navigate = useNavigate()
    useEffect(() => {
        setSelectedItem(props.selectedBrand)
    }, [props.selectedBrand])


    return (
        <ContentContainer
            hoverable={false}
            className="w-[300px] h-fit  overflow-hidden"
            children={
                <>
                    {/*Body*/}
                    <div className="p-[12px] h-full max-h-[120px] overflow-scroll scrollbar-hide">
                        {
                            props.data.length > 0 &&
                            props.data.map(k => (
                                <BrandSelectorItem
                                    data={k}
                                    selected={selectedItem?.id === k.id}
                                    itemSelected={(e) => {
                                        var selectedBrand: BrandRequest | undefined = undefined

                                        if (e.id === selectedItem?.id) {
                                            selectedBrand = undefined
                                        } else {
                                            selectedBrand = e
                                        }

                                        setSelectedItem(selectedBrand)
                                        props.selectedBrandChanged(selectedBrand)
                                    }} />
                            ))
                        }
                        {
                            props.data.length === 0 &&
                            <div>
                                <PrimaryButton
                                    className="w-full h-[40px] mt-2"
                                    title="Add New Brand"
                                    onClick={() => { navigate("/app/brandhub/new") }} />
                            </div>
                        }
                    </div>
                </>
            } />
    );
}

export default BrandSelectorPopup;
