import * as React from "react";
const SvgNotificationManualScheduledActivity = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={40} height={40} fill="#F55" opacity={0.1} rx={5} />
    <path
      fill="#F55"
      d="M28.055 17.916H11.39v-2.5a2.5 2.5 0 0 1 2.5-2.5h11.666a2.5 2.5 0 0 1 2.5 2.5v2.5Z"
      opacity={0.5}
    />
    <path
      fill="#F55"
      d="M11.389 26.25a2.503 2.503 0 0 0 2.5 2.5h11.666a2.502 2.502 0 0 0 2.5-2.5v-8.334H11.39v8.333Z"
      opacity={0.2}
    />
    <path
      fill="#F55"
      d="M15.556 16.25a.833.833 0 0 1-.834-.833v-3.334a.834.834 0 0 1 1.667 0v3.334a.834.834 0 0 1-.833.833Zm8.333 0a.833.833 0 0 1-.834-.833v-3.334a.833.833 0 0 1 1.667 0v3.334a.833.833 0 0 1-.833.833Z"
    />
    <path
      fill="#F55"
      fillRule="evenodd"
      d="M25.825 29.329a1.085 1.085 0 0 0 1.37-.135l2.146-2.146.025-.025a1.086 1.086 0 0 0 0-1.535l-2.17-2.172a1.085 1.085 0 0 0-1.536 1.535l.319.32h-3.893a1.085 1.085 0 1 0 0 2.17h3.892l-.318.318a1.086 1.086 0 0 0 .165 1.67Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNotificationManualScheduledActivity;
