import { useState } from "react";
import Icon from "./Icon";
import * as React from "react";

export interface IIconButtonProps {
    title: string
    icon: ((props: React.SVGProps<SVGSVGElement>) => JSX.Element) | undefined
    onClick: (e) => void
    className?: string
    color?: string
    hoverColor?: string
    maxWidth?: string
    size?: string
    tooltip?: string
    disableAnimation?: boolean
}

function IconButton(props: IIconButtonProps) {
    const [isHovering, setHovering] = useState(false)
    const { size = "20" } = props

    return (<>
        <button
            className={`h-[20px] w-5 overflow-hidden transition-all duration-500 text-H6 flex flex-row ${isHovering ? "text-black" : "text-gray"} ${props.className}`}
            onClick={(e) => props.onClick(e)}
            onMouseEnter={() => { setHovering(true) }}
            onMouseLeave={() => { setHovering(false) }}

            data-tooltip-id={props.tooltip ? "app-tooltip" : ""}
            data-tooltip-html={props.tooltip}
        >
            <Icon
                className="w-5"
                size={size}
                component={props.icon}
                color={isHovering ? props.hoverColor : (props.color ? props.color : "#999999")} />

            {!props.disableAnimation &&
                <span className={`ml-2  ${isHovering ? "animate-fade-in" : "animate-fade-out-hidden hidden"}`}>{props.title}</span>
            }
            {props.disableAnimation &&
                <span className={`ml-2 `}>{props.title}</span>
            }
        </button>
    </>);
}

export default IconButton;
