import * as React from "react";
import type { SVGProps } from "react";
const SvgFacebookColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fill="#039BE5"
      fillRule="evenodd"
      d="M4.402 4.902a7.917 7.917 0 1 1 11.196 11.196A7.917 7.917 0 0 1 4.402 4.902Zm8.719 7.696h-2.05v5.738a7.97 7.97 0 0 1-1.07.08c-.404 0-.797-.039-1.185-.097v-5.72H6.857v-2.082h1.959V9.133c0-2.293 1.117-3.3 3.022-3.3.897 0 1.379.066 1.612.098l.012.001v1.816h-1.3c-.808 0-1.09.767-1.09 1.632v1.137h2.37L13.12 12.6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFacebookColor;
