
import * as React from "react";
import type { SVGProps } from "react";
const SvgBrandTritoneSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={40} height={40} fill="#F55" opacity={0.1} rx={8} />
    <path
      opacity="0.35"
      d="M17.778 15.0555H11.1113V28.3889H17.778V15.0555Z"
      fill="#FF5555"
    />
    <path
      opacity="0.35"
      d="M28.8887 29.5H18.8887V11.7222C18.8887 10.4944 19.8831 9.5 21.1109 9.5H27.7776C29.0053 9.5 29.9998 10.4944 29.9998 11.7222V28.3889C29.9998 29.0022 29.502 29.5 28.8887 29.5Z"
      fill="#FF5555"
    />
    <path
      d="M27.7779 13.9445H25.5557V11.7222H27.7779V13.9445Z"
      fill="#FF5555"
    />
    <path
      d="M23.3336 13.9445H21.1113V11.7222H23.3336V13.9445Z"
      fill="#FF5555"
    />
    <path
      d="M27.7779 18.3889H25.5557V16.1667H27.7779V18.3889Z"
      fill="#FF5555"
    />
    <path
      d="M23.3336 18.3889H21.1113V16.1667H23.3336V18.3889Z"
      fill="#FF5555"
    />
    <path
      d="M27.7779 22.8333H25.5557V20.6111H27.7779V22.8333Z"
      fill="#FF5555"
    />
    <path
      d="M23.3336 22.8333H21.1113V20.6111H23.3336V22.8333Z"
      fill="#FF5555"
    />
    <path
      d="M27.7779 27.2778H25.5557V25.0555H27.7779V27.2778Z"
      fill="#FF5555"
    />
    <path
      d="M23.3336 27.2778H21.1113V25.0555H23.3336V27.2778Z"
      fill="#FF5555"
    />
    <path
      d="M16.6667 12.8333H12.2222C10.9944 12.8333 10 13.8278 10 15.0556V28.3889C10 29.0022 10.4978 29.5 11.1111 29.5H18.8889V15.0556C18.8889 13.8278 17.8944 12.8333 16.6667 12.8333ZM16.6667 27.2778H12.2222V25.0556H16.6667V27.2778ZM16.6667 22.8333H12.2222V20.6111H16.6667V22.8333ZM16.6667 18.3889H12.2222V16.1667H16.6667V18.3889Z"
      fill="#FF5555"
    />
  </svg>
);
export default SvgBrandTritoneSmall;


