import * as React from "react";
import type { SVGProps } from "react";
const SvgCelebrationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.1" width="56" height="56" rx="4" fill="#FF5555" />
    <g clip-path="url(#clip0_4830_14458)">
      <path
        opacity="0.5"
        d="M18.2547 39.6127C17.1862 39.6086 16.3645 38.7548 16.4164 37.7535C16.4378 37.3464 16.5741 36.9582 16.7125 36.5766C17.2892 34.9891 17.8679 33.4026 18.4486 31.8171C18.9152 30.5451 19.3904 29.2766 19.8595 28.0056C20.0004 27.6281 20.0823 27.2311 20.1022 26.8287C20.1201 26.4568 20.1399 26.0843 20.1806 25.7144C20.2035 25.5068 20.3526 25.3582 20.5103 25.2331C20.7189 25.0698 20.9761 24.981 21.241 24.981C21.5059 24.981 21.7631 25.0698 21.9716 25.2331C22.419 25.5891 22.8371 25.9804 23.2218 26.4033C23.381 26.5733 23.5474 26.7366 23.7123 26.9015L30.4556 33.6448C30.7365 33.9252 30.9537 34.2376 31.026 34.6411C31.1313 35.2308 30.6968 35.8149 30.105 35.878C29.7799 35.9126 29.4543 35.8994 29.1291 35.9187C28.8846 35.9314 28.643 35.9787 28.4117 36.0591C26.9636 36.571 25.5166 37.0834 24.0786 37.6232C22.407 38.2508 20.7289 38.86 19.0444 39.4509C18.7913 39.5458 18.5248 39.6004 18.2547 39.6127Z"
        fill="#FF5555"
      />
      <path
        d="M37.8329 18.851C37.6803 18.851 37.5002 18.8546 37.3241 18.851C37.231 18.851 37.1842 18.8698 37.1862 18.9762C37.1893 19.196 37.1806 19.4168 37.1776 19.6376C37.172 20.0874 37.1557 20.5377 36.9908 20.9606C36.7568 21.566 36.3467 22.0092 35.7402 22.2585C35.4655 22.3702 35.1853 22.4675 34.9006 22.5501C34.8682 22.5571 34.8385 22.5732 34.8149 22.5965C34.7914 22.6199 34.7751 22.6495 34.7678 22.6819C34.6727 22.9984 34.5816 23.3174 34.4351 23.615C34.0789 24.3401 33.5156 24.7818 32.698 24.8769C32.4112 24.9021 32.126 24.9429 31.8437 24.999C31.5897 25.0537 31.3565 25.1794 31.171 25.3613C30.9704 25.5566 30.76 25.7415 30.5406 25.9154C29.846 26.4669 28.8177 26.0772 28.6503 25.2031C28.5852 24.8621 28.6427 24.5523 28.8569 24.2694C29.1912 23.8287 29.5469 23.4115 30.0002 23.0854C30.5955 22.6546 31.318 22.4357 32.0523 22.4636C32.1764 22.4666 32.2278 22.4305 32.2599 22.3109C32.3214 22.0784 32.3998 21.85 32.4787 21.6225C32.7142 20.9402 33.1778 20.4838 33.8591 20.2487C34.1069 20.1643 34.3572 20.0864 34.6086 20.0116C34.6389 20.0068 34.6662 19.9907 34.6851 19.9665C34.704 19.9424 34.713 19.9119 34.7103 19.8814C34.7266 19.5003 34.7205 19.1181 34.7388 18.7375C34.7968 17.5383 35.6038 16.6275 36.7904 16.4321C37.3099 16.3466 37.8278 16.3578 38.3484 16.4036C38.4793 16.4073 38.6097 16.4223 38.7381 16.4484C39.2846 16.5944 39.6479 17.1271 39.6117 17.725C39.5797 18.2618 39.1462 18.7152 38.5911 18.7991C38.3397 18.8327 38.0865 18.85 37.8329 18.851Z"
        fill="#FF5555"
      />
      <path
        d="M26.1557 22.5603C26.1557 22.9841 26.1725 23.4085 26.1506 23.8323C26.1277 24.279 25.9013 24.6174 25.4978 24.8204C25.0943 25.0234 24.7005 24.997 24.3092 24.7726C23.9372 24.5584 23.7963 24.2129 23.7454 23.8165C23.6859 23.3525 23.7403 22.8849 23.7174 22.4198C23.6986 22.0448 23.7017 21.6642 23.5353 21.3137C23.2076 20.6247 22.6855 20.2024 21.9071 20.1174C21.5061 20.0737 21.1031 20.0798 20.7022 20.0472C20.4739 20.0347 20.2478 19.9958 20.0285 19.9312C19.3162 19.7017 18.8338 19.0113 18.8389 18.2282C18.8435 17.4294 19.3116 16.7582 20.0453 16.5028C20.2518 16.4397 20.4656 16.4033 20.6813 16.3944C22.1233 16.284 23.4188 16.6606 24.5107 17.6232C25.4087 18.4144 25.9491 19.4117 26.1068 20.6095C26.1884 21.2565 26.2047 21.91 26.1557 22.5603Z"
        fill="#FF5555"
      />
      <path
        d="M33.618 28.6147C34.2495 28.5976 34.8813 28.626 35.5087 28.6996C36.4409 28.8212 37.2479 29.2242 37.9429 29.8485C38.8079 30.624 39.3635 31.5785 39.5508 32.73C39.6525 33.3527 39.6907 33.9867 39.5385 34.61C39.4419 35.0046 39.2124 35.3538 38.8887 35.5993C38.565 35.8447 38.1667 35.9713 37.7607 35.9579C37.1135 35.9406 36.6073 35.6633 36.2399 35.1305C36.0669 34.8802 36.0221 34.5917 35.9855 34.3007C35.926 33.8168 35.9346 33.3283 35.8883 32.8429C35.8028 31.9657 35.062 31.2462 34.2092 31.1252C33.7976 31.0666 33.3814 31.0707 32.9662 31.0743C32.6568 31.0743 32.348 31.0799 32.0412 31.0234C31.486 30.9216 31.08 30.4688 31.0561 29.9472C31.0261 29.2858 31.481 28.777 31.9781 28.6828C32.2696 28.6289 32.5688 28.6289 32.8649 28.6172C33.1173 28.607 33.3676 28.6147 33.618 28.6147Z"
        fill="#FF5555"
      />
      <path
        d="M39.0105 27.3976C38.5247 27.4009 38.0575 27.2112 37.7116 26.8701C37.3657 26.5289 37.1694 26.0644 37.1661 25.5785C37.1627 25.0927 37.3524 24.6255 37.6936 24.2796C38.0347 23.9337 38.4993 23.7375 38.9851 23.7341C40.0002 23.7275 40.829 24.5355 40.8341 25.5348C40.8392 26.5804 40.0465 27.3925 39.0105 27.3976Z"
        fill="#FF5555"
      />
      <path
        d="M30.4477 18.8332C30.0843 18.8332 29.7291 18.725 29.4273 18.5225C29.1256 18.32 28.8908 18.0323 28.753 17.6961C28.6152 17.3598 28.5805 16.9901 28.6534 16.6341C28.7263 16.278 28.9035 15.9517 29.1624 15.6967C29.4212 15.4416 29.7501 15.2693 30.1072 15.2017C30.4643 15.1341 30.8334 15.1742 31.1676 15.317C31.5018 15.4598 31.786 15.6988 31.984 16.0035C32.182 16.3083 32.2848 16.665 32.2795 17.0284C32.2782 17.2673 32.2298 17.5037 32.1369 17.7238C32.0441 17.944 31.9087 18.1436 31.7385 18.3113C31.5683 18.479 31.3667 18.6114 31.1452 18.701C30.9237 18.7905 30.6867 18.8355 30.4477 18.8332Z"
        fill="#FF5555"
      />
      <path
        d="M16.9969 21.2918C17.359 21.2918 17.713 21.3991 18.0141 21.6002C18.3153 21.8013 18.5501 22.0871 18.6888 22.4216C18.8276 22.7561 18.8641 23.1242 18.7937 23.4794C18.7234 23.8346 18.5493 24.161 18.2936 24.4173C18.0378 24.6737 17.7118 24.8484 17.3567 24.9196C17.0017 24.9907 16.6335 24.955 16.2987 24.817C15.964 24.679 15.6776 24.4448 15.4759 24.1441C15.2741 23.8435 15.166 23.4897 15.1652 23.1276C15.1622 22.886 15.2076 22.6463 15.2986 22.4226C15.3895 22.1988 15.5243 21.9954 15.6949 21.8244C15.8655 21.6534 16.0686 21.5182 16.2922 21.4267C16.5158 21.3353 16.7554 21.2894 16.9969 21.2918Z"
        fill="#FF5555"
      />
      <path
        d="M35.9447 39.0337C35.932 40.0625 35.1148 40.8735 34.1053 40.8588C33.7431 40.8573 33.3895 40.7484 33.0891 40.5459C32.7887 40.3434 32.5551 40.0564 32.4179 39.7212C32.2806 39.386 32.2458 39.0176 32.3179 38.6626C32.39 38.3076 32.5657 37.9819 32.8228 37.7268C33.0799 37.4717 33.4069 37.2985 33.7625 37.2292C34.118 37.1599 34.4861 37.1975 34.8203 37.3374C35.1544 37.4773 35.4396 37.7131 35.6397 38.015C35.8399 38.3169 35.946 38.6714 35.9447 39.0337Z"
        fill="#FF5555"
      />
    </g>
    <defs>
      <clipPath id="clip0_4830_14458">
        <rect
          width="25.6667"
          height="25.6921"
          fill="white"
          transform="translate(15.167 15.1667)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCelebrationIcon;
  