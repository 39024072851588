import * as React from "react";
const AwarenessIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={19}
        fill="none"
        {...props}
    >
        <path opacity="0.5"
              d="M21 18C21 18.2099 20.934 18.4145 20.8112 18.5847C20.6885 18.755 20.5153 18.8823 20.3162 18.9487C20.1171 19.0151 19.9022 19.0171 19.7018 18.9545C19.5015 18.8919 19.3259 18.7679 19.2 18.6C18.3593 17.4848 17.2718 16.5794 16.0226 15.9548C14.7734 15.3302 13.3966 15.0034 12 15H8C7.73496 15 7.48075 14.8948 7.29325 14.7075C7.10574 14.5201 7.00027 14.266 7 14.001V8.00001C7 7.73496 7.10522 7.48076 7.29254 7.29325C7.47986 7.10575 7.73396 7.00027 7.999 7.00001H12C13.3966 6.99661 14.7734 6.66981 16.0226 6.04523C17.2718 5.42065 18.3593 4.51525 19.2 3.40001C19.3258 3.2323 19.5011 3.10837 19.7011 3.04571C19.9012 2.98306 20.1158 2.98485 20.3148 3.05084C20.5138 3.11683 20.687 3.24369 20.8099 3.41347C20.9329 3.58325 20.9994 3.78738 21 3.99701V18Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path
            d="M9 15H6C5.20435 15 4.44129 14.6839 3.87868 14.1213C3.31607 13.5587 3 12.7956 3 12V10C3 9.20435 3.31607 8.44129 3.87868 7.87868C4.44129 7.31607 5.20435 7 6 7H9V15ZM20 20C19.735 20 19.4808 19.8948 19.2932 19.7075C19.1057 19.5201 19.0003 19.266 19 19.001V3C19 2.73478 19.1054 2.48043 19.2929 2.29289C19.4804 2.10536 19.7348 2 20 2C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V19C21 19.265 20.8948 19.5192 20.7075 19.7068C20.5201 19.8943 20.265 19.9997 20 20Z"
            fill={props.fill ?? "#FFFFFF"}/>
        <path opacity="0.5"
              d="M8.99991 15H6.48391L4.07991 20.606C4.01468 20.7582 3.98825 20.9242 4.003 21.0891C4.01774 21.254 4.07321 21.4126 4.1644 21.5508C4.2556 21.689 4.37968 21.8024 4.52551 21.8808C4.67134 21.9592 4.83435 22.0001 4.99991 22H8.99991C9.39991 22 9.76191 21.762 9.91891 21.394L12.6419 15.039C12.4289 15.023 12.2159 15.001 11.9999 15H8.99991Z"
              fill={props.fill ?? "#FFFFFF"}/>
    </svg>
);
export default AwarenessIcon;
