import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listP: build.query<ListPApiResponse, ListPApiArg>({
      query: () => ({ url: `/plan/list` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type ListPApiResponse = /** status 200 OK */ Plan[];
export type ListPApiArg = void;
export type Plan = {
  id: string;
  name?: string;
  description?: string;
  currency?: string;
  monthlyPrice?: number;
  yearlyPrice?: number;
  futureMonthlyPrice?: number;
  futureYearlyPrice?: number;
  futureMonthlyAddSpendsPercentage?: number;
  futureYearlyAddSpendsPercentage?: number;
  credits?: number;
  numberOfBrands?: number;
  aiModels?: string;
  marketingOperator?: string;
  support?: string;
  stripeProduct?: string;
  stripeMonthlyPrice?: string;
  stripeYearlyPrice?: string;
  benefits?: string;
  mostPopular?: boolean;
  adCreation?: string;
  creativeAds?: string;
  active?: boolean;
  created_date?: string;
  created_user?: string;
  updated_date?: string;
  updated_user?: string;
};
export const { useListPQuery, useLazyListPQuery } = injectedRtkApi;
