import { t } from "i18next";
import ContentContainer from "../ContentContainer";
import { useEffect, useState } from "react";
import { AudienceResponse } from "../../services/AudiencetApi";
import AudienceSelectorItem from "./AudienceSelectorItem";
import SvgAddPlusIcon from "../../assets/IconComponents/SvgAddPlus";
import { Button } from "antd";

export interface IAudienceSelectorPopupProps {
  data: AudienceResponse[]
  closePopup: () => void
  selectedAudience: AudienceResponse | undefined
  selectedAudienceChanged: (data: AudienceResponse | undefined) => void
  onCreateAudience: () => void
  onAudienceEdit: (data: AudienceResponse) => void
  className?: string;
  editingDisabled?: boolean
}

function AudienceSelectorPopup(props: IAudienceSelectorPopupProps) {
  const [selectedItem, setSelectedItem] = useState<AudienceResponse>()
  useEffect(() => {
    setSelectedItem(props.selectedAudience)
  }, [props.selectedAudience])


  return (
    <ContentContainer
      hoverable={false}
      className={`h-fit overflow-hidden ${props.className ?? ""}`}
      children={
        <>
          {/*Body*/}
          <div className="flex flex-col p-[12px] h-full max-h-[240px] overflow-scroll scrollbar-hide">
            {props.data.length > 0 &&
              props.data.map((k) => (
                <AudienceSelectorItem
                  data={k}
                  selected={selectedItem?.audience_id === k.audience_id}
                  itemSelected={(e) => {
                    var selectedAudience: AudienceResponse | undefined =
                      undefined;

                    if (e.audience_id === selectedItem?.audience_id) {
                      selectedAudience = undefined;
                    } else {
                      selectedAudience = e;
                    }

                    setSelectedItem(selectedAudience);
                    props.selectedAudienceChanged(selectedAudience);
                  }}
                  onItemEdit={props.onAudienceEdit}
                  editingDisabled={props.editingDisabled}
                />
              ))}
          </div>
          {!props.editingDisabled &&
            <div>
              <div className="w-full h-[38px] pl-2.5 pr-[5px] py-[5px] bg-stone-50 rounded-bl-md rounded-br-md border border-stone-200 justify-end items-center inline-flex">
                <Button
                  type="text"
                  className="flex flex-row w-fit cursor-pointer"
                  onClick={props.onCreateAudience}
                >
                  <SvgAddPlusIcon />
                  <text className="text-H8 text-red ml-2 w-72 sm:w-full truncate text-ellipsis overflow-hidden">
                    {t("create-new")}
                  </text>
                </Button>
              </div>
            </div>
          }
        </>
      }
    />
  );
}

export default AudienceSelectorPopup;
