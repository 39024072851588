export enum PaymentErrorCode {
    BrandLimitExceeded = 0,
    StripeCustomerNotFound = 1,
    SubscriptionNotFound = 2,
    PriceNotFound = 3,
    StripeApiError = 4,
    CreditLimitExceeded = 5,
    PaymentMethodNotFound = 6,
    ActiveSubscriptionFound = 7,
    DiscountAlreadyApplied = 8
}