import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    save3: build.mutation<Save3ApiResponse, Save3ApiArg>({
      query: (queryArg) => ({
        url: `/font`,
        method: "POST",
        body: queryArg.font,
      }),
    }),
    findById3: build.query<FindById3ApiResponse, FindById3ApiArg>({
      query: (queryArg) => ({ url: `/font/${queryArg.fileId}` }),
    }),
    deleteById3: build.mutation<DeleteById3ApiResponse, DeleteById3ApiArg>({
      query: (queryArg) => ({
        url: `/font/${queryArg.fileId}`,
        method: "DELETE",
      }),
    }),
    listFonts: build.query<List3ApiResponse, List3ApiArg>({
      query: () => ({ url: `/font/list` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type Save3ApiResponse = /** status 200 OK */ Font;
export type Save3ApiArg = {
  font: Font;
};
export type FindById3ApiResponse = /** status 200 OK */ Font;
export type FindById3ApiArg = {
  fileId: string;
};
export type DeleteById3ApiResponse = unknown;
export type DeleteById3ApiArg = {
  fileId: string;
};
export type List3ApiResponse = /** status 200 OK */ Font[];
export type List3ApiArg = void;
export type Font = {
  name: string;
  file_id: string;
};
export const {
  useSave3Mutation,
  useFindById3Query,
  useLazyFindById3Query,
  useDeleteById3Mutation,
  useListFontsQuery,
  useLazyListFontsQuery,
} = injectedRtkApi;
