import {createSlice} from '@reduxjs/toolkit'
import {GetAllResponse, MarketingPlannerRequest} from "../services/Activity";

export interface IActivity {
    activity: Activity
    activities?: GetAllResponse
}

interface Activity {
    name?: string
}

// Define the initial state using that type
const initialState: IActivity = {
    activity: {
        name: ''
    },
    activities: undefined
}

export const activitySlice = createSlice({
    name: "activity",
    initialState,
    reducers: {
        setNewActivity: (state, {payload}) => {
            state.activity = payload;
        },
        setActivities: (state, {payload}) => {
            state.activities = payload;
        },
    },
});

export const {setNewActivity,setActivities} = activitySlice.actions

export default activitySlice.reducer
