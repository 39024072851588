import * as React from "react";
const Frame = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={19}
        fill="none"
        {...props}
    >
        <g clipPath="url(#clip0_8177_44679)">
            <path opacity="0.5"
                  d="M18.5824 6.8694C18.6933 6.9143 18.7811 6.99577 18.8792 7.06094C19.5985 7.53782 20.3548 7.95508 21.0828 8.41805C21.4007 8.62032 21.7218 8.81982 22.0155 9.05627C22.4423 9.40041 22.6676 9.85861 22.7344 10.4007C22.7852 10.8232 22.7063 11.2512 22.5083 11.6278C22.3465 11.9481 22.0886 12.1818 21.8025 12.3924C21.3383 12.735 20.8325 13.0092 20.3464 13.3168C20.193 13.4137 20.0329 13.5007 19.8938 13.6188C19.8541 13.6096 19.831 13.6351 19.8036 13.6522C18.1345 14.706 16.4456 15.7214 14.7718 16.7646C14.3116 17.0515 13.8605 17.3547 13.3602 17.5713C12.8217 17.8041 12.2626 17.8494 11.6912 17.7429C11.2616 17.6635 10.8809 17.4604 10.5141 17.2339C8.58484 16.0441 6.65654 14.8528 4.72917 13.6601C4.69897 13.6414 4.67314 13.6124 4.633 13.614C4.06552 13.2198 3.45313 12.8987 2.88446 12.5088C2.64085 12.3431 2.39566 12.181 2.20888 11.9461C1.98151 11.6598 1.83349 11.3187 1.77969 10.957C1.69266 10.4007 1.81943 9.89 2.13735 9.4326C2.35751 9.11468 2.67185 8.89333 2.99732 8.69305C4.32382 7.87653 5.65139 7.06134 6.98002 6.24747C8.22744 5.4797 9.47129 4.70518 10.7215 3.94257C11.1285 3.69238 11.5895 3.5433 12.0659 3.50782C12.6469 3.46292 13.1738 3.61433 13.6805 3.88495C14.4356 4.28751 15.1326 4.78108 15.8662 5.21782C16.1424 5.3875 16.4285 5.54051 16.723 5.67602C16.7436 5.68516 16.7687 5.68873 16.7754 5.71576C16.7564 5.7555 16.7154 5.76543 16.6828 5.7861C15.5103 6.52552 14.3371 7.26467 13.1635 8.00357C13.0401 8.08042 12.9062 8.13916 12.7661 8.17802C12.4553 8.26584 12.1339 8.27658 11.8163 8.31552C11.3871 8.36798 11.0152 8.53568 10.7104 8.84287C10.2395 9.31736 10.2287 10.0426 10.6949 10.4984C11.2512 11.0421 11.93 11.2217 12.6819 11.085C13.5017 10.9359 14.0557 10.4658 14.2591 9.63249C14.2652 9.60723 14.2765 9.58354 14.2924 9.56299C14.3083 9.54243 14.3284 9.52549 14.3513 9.51327C14.9893 9.11588 15.6264 8.71755 16.2628 8.3183C17.0335 7.83454 17.8067 7.35157 18.5824 6.8694Z"
                  fill={props.fill ?? "#FFFFFF"}/>
            <path
                d="M4.63234 13.6172C4.67208 13.6172 4.6983 13.6446 4.72851 13.6633C6.65667 14.8555 8.58497 16.0467 10.5134 17.2371C10.8802 17.4636 11.2609 17.6655 11.6905 17.7461C12.2631 17.8538 12.8223 17.8073 13.3596 17.5745C13.8599 17.3579 14.3109 17.0547 14.7711 16.7677C16.4449 15.7246 18.1359 14.7092 19.8029 13.6553C19.8304 13.6382 19.8554 13.6128 19.8931 13.622C19.8931 15.2115 19.8931 16.8011 19.8931 18.3907C19.8931 18.8346 19.8761 19.2805 19.7235 19.7021C19.3658 20.6928 18.6636 21.308 17.6411 21.5572C17.4025 21.6128 17.1581 21.6395 16.9131 21.6366C13.8012 21.6343 10.6892 21.6323 7.57704 21.6307C6.81325 21.6307 6.14006 21.4006 5.5698 20.8903C5.05755 20.4321 4.74202 19.8615 4.65578 19.1776C4.60651 18.7933 4.6224 18.4038 4.61604 18.0172C4.5926 16.5671 4.61883 15.1162 4.62399 13.6669C4.62536 13.6501 4.62815 13.6335 4.63234 13.6172Z"
                fill={props.fill ?? "#FFFFFF"}/>
            <path
                d="M18.5827 6.87239C17.8075 7.35536 17.0321 7.83833 16.2564 8.3213C15.6189 8.71869 14.9818 9.11702 14.3449 9.51627C14.322 9.52848 14.3019 9.54543 14.286 9.56598C14.2701 9.58653 14.2587 9.61022 14.2527 9.63549C14.0492 10.47 13.4953 10.9389 12.6754 11.088C11.9255 11.2247 11.2448 11.045 10.6884 10.5014C10.2223 10.0456 10.233 9.32035 10.7039 8.84586C11.0087 8.53867 11.3795 8.37097 11.8099 8.31851C12.1278 8.27877 12.4489 8.26884 12.7597 8.18101C12.8998 8.14216 13.0336 8.08341 13.1571 8.00656C14.3304 7.26793 15.5036 6.52878 16.6764 5.78909C16.709 5.76843 16.7499 5.75769 16.769 5.71875C17.0428 5.72749 17.3162 5.73902 17.5825 5.81492C17.8486 5.8909 18.08 6.05711 18.237 6.28504C18.3722 6.46835 18.4881 6.66518 18.5827 6.87239Z"
                fill={props.fill ?? "#FFFFFF"}/>
        </g>
        <defs>
            <clipPath id="clip0_8177_44679">
                <rect width="21" height="18.134" fill="white" transform="translate(1.75 3.5)"/>
            </clipPath>
        </defs>
    </svg>
);
export default Frame;
