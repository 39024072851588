
import * as React from "react";
import type { SVGProps } from "react";
const SvgGenerateSimilarWhiteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M13.575 15.9379C13.525 15.9379 13.475 15.9254 13.425 15.9004C13.375 15.8754 13.3375 15.8316 13.3125 15.7691L12.7687 14.5504L11.4187 13.9691C11.3562 13.9441 11.3125 13.9098 11.2875 13.866C11.2625 13.8223 11.25 13.7691 11.25 13.7066C11.25 13.6441 11.2625 13.591 11.2875 13.5473C11.3125 13.5035 11.3562 13.4691 11.4187 13.4441L12.7687 12.8629L13.3125 11.5691C13.3375 11.5066 13.3719 11.4629 13.4156 11.4379C13.4594 11.4129 13.5125 11.4004 13.575 11.4004C13.6375 11.4004 13.6906 11.4129 13.7344 11.4379C13.7781 11.4629 13.8125 11.5066 13.8375 11.5691L14.3812 12.8629L15.7313 13.4441C15.7938 13.4691 15.8375 13.5035 15.8625 13.5473C15.8875 13.591 15.9 13.6441 15.9 13.7066C15.9 13.7691 15.8875 13.8223 15.8625 13.866C15.8375 13.9098 15.7938 13.9441 15.7313 13.9691L14.3812 14.5504L13.8375 15.7691C13.8125 15.8316 13.775 15.8754 13.725 15.9004C13.675 15.9254 13.625 15.9379 13.575 15.9379Z"
    fill="white"
  />
  <path
    d="M13.5752 6.56289C13.5127 6.56289 13.4596 6.55039 13.4158 6.52539C13.3721 6.50039 13.3377 6.45664 13.3127 6.39414L12.7689 5.11914L11.4002 4.51914C11.3377 4.49414 11.2939 4.45977 11.2689 4.41602C11.2439 4.37227 11.2314 4.31914 11.2314 4.25664C11.2314 4.19414 11.2439 4.14102 11.2689 4.09727C11.2939 4.05352 11.3377 4.01914 11.4002 3.99414L12.7689 3.41289L13.3127 2.19414C13.3377 2.13164 13.3721 2.08789 13.4158 2.06289C13.4596 2.03789 13.5127 2.02539 13.5752 2.02539C13.6377 2.02539 13.6908 2.03789 13.7346 2.06289C13.7783 2.08789 13.8127 2.13164 13.8377 2.19414L14.3814 3.41289L15.7502 3.99414C15.8127 4.01914 15.8564 4.05352 15.8814 4.09727C15.9064 4.14102 15.9189 4.19414 15.9189 4.25664C15.9189 4.31914 15.9064 4.37227 15.8814 4.41602C15.8564 4.45977 15.8127 4.49414 15.7502 4.51914L14.3814 5.11914L13.8377 6.39414C13.8127 6.45664 13.7783 6.50039 13.7346 6.52539C13.6908 6.55039 13.6377 6.56289 13.5752 6.56289Z"
    fill="#FFEEEE"
  />
  <path
    d="M6.24395 13.5941C6.14395 13.5941 6.04707 13.566 5.95332 13.5098C5.85957 13.4535 5.7877 13.3754 5.7377 13.2754L4.51895 10.6691L1.89395 9.48789C1.79395 9.43789 1.71582 9.36601 1.65957 9.27226C1.60332 9.17851 1.5752 9.08164 1.5752 8.98164C1.5752 8.88164 1.60332 8.78477 1.65957 8.69102C1.71582 8.59727 1.79395 8.52539 1.89395 8.47539L4.51895 7.29414L5.7377 4.70664C5.7877 4.59414 5.85957 4.50977 5.95332 4.45352C6.04707 4.39727 6.14395 4.36914 6.24395 4.36914C6.34395 4.36914 6.44082 4.39727 6.53457 4.45352C6.62832 4.50977 6.7002 4.58789 6.7502 4.68789L7.9877 7.29414L10.5939 8.47539C10.7064 8.52539 10.7908 8.59727 10.8471 8.69102C10.9033 8.78477 10.9314 8.88164 10.9314 8.98164C10.9314 9.08164 10.9033 9.17851 10.8471 9.27226C10.7908 9.36601 10.7064 9.43789 10.5939 9.48789L7.9877 10.6691L6.7502 13.2754C6.7002 13.3879 6.62832 13.4691 6.53457 13.5191C6.44082 13.5691 6.34395 13.5941 6.24395 13.5941Z"
    fill="white"
  />
</svg>
);
export default SvgGenerateSimilarWhiteIcon;
