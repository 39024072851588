import * as React from "react";
import type { SVGProps } from "react";
const SvgArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill ?? "#000"}
      d="M18.065 12.735a.913.913 0 0 1 0 1.296.917.917 0 0 1-1.296 0L11.917 9.18 11 8.25l-.916.93-4.852 4.851a.917.917 0 0 1-1.297-1.296l6.417-6.416a.917.917 0 0 1 1.296 0l6.417 6.416Z"
    />
  </svg>
);
export default SvgArrowUp;
