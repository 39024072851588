import * as React from "react";
import type { SVGProps } from "react";
const SvgMedium = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icons">
      <path id="Vector" d="M5.38855 4.75C7.25625 4.75 8.7771 6.20367 8.7771 7.99865C8.7771 9.79362 7.26292 11.2473 5.38855 11.2473C3.51417 11.2473 2 9.79362 2 7.99865C2 6.20367 3.51417 4.75 5.38855 4.75ZM10.7916 4.93961C11.7254 4.93961 12.4858 6.31112 12.4858 7.99865C12.4858 9.68617 11.7254 11.0577 10.7916 11.0577C9.8577 11.0577 9.09728 9.68617 9.09728 7.99865C9.09728 6.31112 9.8577 4.93961 10.7916 4.93961ZM13.4063 5.25563C13.7332 5.25563 14 6.48177 14 7.99865C14 9.51552 13.7332 10.7417 13.4063 10.7417C13.0795 10.7417 12.8127 9.51552 12.8127 7.99865C12.8127 6.48177 13.0795 5.25563 13.4063 5.25563Z" fill="black" />
    </g>
  </svg>
);
export default SvgMedium;
