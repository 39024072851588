import { FunctionComponent } from "react";
import { Back } from "../../../assets/IconComponents";
import IconButton from "../../../components/IconButton";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { ContentWizardView } from "../../../models/ContentWizardView";
import { setNewContent } from "../../../store/contentSlice";

const Header: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const newContent = useAppSelector((k) => k.content.newContent);


    return <><div className="w-full h-14 px-5 bg-white shadow-inner justify-start items-center gap-5 inline-flex border-b-1">
        <div className="grow shrink basis-0 h-[22px] justify-start items-center gap-2.5 flex">
            <div className="w-5 h-5 relative">
                <IconButton
                    title={""}
                    icon={Back}
                    onClick={function (e: any): void {
                        dispatch(setNewContent({
                            ...newContent,
                            wizardView: ContentWizardView.contentDetails
                        }));
                    }} />
            </div>
            <div className="grow shrink basis-0 text-black text-base font-semibold font-['Inter Tight'] leading-snug">{`View Custom Trainings`}</div>
            
        </div>
    </div></>
}

export default Header;