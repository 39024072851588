import * as React from "react";
const SvgBackgroundRemoverUpload = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    fill="none"
    {...props}
  >
    <path
      fill="#121315"
      d="m8.25 10.037-.675.657a.7.7 0 0 1-.516.206.73.73 0 0 1-.534-.225.711.711 0 0 1-.206-.525c0-.212.069-.387.206-.525l1.95-1.95A.72.72 0 0 1 9 7.45c.2 0 .375.075.525.225l1.95 1.95c.138.138.21.31.216.516a.698.698 0 0 1-.216.534.733.733 0 0 1-.516.216.73.73 0 0 1-.534-.197l-.675-.657V13.9h4.125a1.81 1.81 0 0 0 1.331-.544 1.81 1.81 0 0 0 .544-1.331 1.81 1.81 0 0 0-.544-1.331 1.81 1.81 0 0 0-1.331-.544H12.75v-1.5a3.614 3.614 0 0 0-1.097-2.653A3.614 3.614 0 0 0 9 4.9a3.614 3.614 0 0 0-2.653 1.097A3.614 3.614 0 0 0 5.25 8.65h-.375a2.53 2.53 0 0 0-1.856.769 2.53 2.53 0 0 0-.769 1.856c0 .725.256 1.344.769 1.856a2.53 2.53 0 0 0 1.856.769H6c.213 0 .39.072.534.216a.725.725 0 0 1 .216.534c0 .213-.072.39-.216.534A.726.726 0 0 1 6 15.4H4.875c-1.138 0-2.11-.394-2.916-1.181C1.153 13.43.75 12.469.75 11.33c0-.975.294-1.843.881-2.606a3.898 3.898 0 0 1 2.306-1.462 5.09 5.09 0 0 1 1.876-2.794C6.75 3.756 7.813 3.4 9 3.4c1.463 0 2.703.51 3.722 1.528 1.019 1.019 1.528 2.26 1.528 3.722.863.1 1.578.472 2.147 1.116.569.643.853 1.396.853 2.259 0 .938-.328 1.734-.984 2.39-.657.657-1.453.985-2.391.985H9.75c-.412 0-.766-.147-1.06-.44a1.445 1.445 0 0 1-.44-1.06v-3.863Z"
    />
  </svg>
);
export default SvgBackgroundRemoverUpload;
