import * as React from "react";
import type { SVGProps } from "react";
const SvgWarningIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g clipPath="url(#WarningIcon_svg__a)">
      <path
        stroke="#999"
        strokeWidth={1.5}
        d="M8 1.25A6.757 6.757 0 0 0 1.25 8 6.757 6.757 0 0 0 8 14.75 6.757 6.757 0 0 0 14.75 8 6.757 6.757 0 0 0 8 1.25Z"
      />
      <path
        fill="#999"
        d="M8 4c.415 0 .75.298.75.667v4c0 .368-.335.666-.75.666s-.75-.298-.75-.666v-4C7.25 4.298 7.585 4 8 4Zm0 6.222c.553 0 1 .398 1 .89 0 .49-.447.888-1 .888-.553 0-1-.398-1-.889s.447-.889 1-.889Z"
      />
    </g>
    <defs>
      <clipPath id="WarningIcon_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWarningIcon;
