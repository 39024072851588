import { SVGProps, useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";
import { t } from "i18next";
import RadioSelectorItem from "./RadioSelectorItem";

export interface IGenericSelectorData {
  id: string;
  title: string;
}

export interface IRadioSelectorProps {
  ItemSelected: (data: IGenericSelectorData | undefined) => void;
  Items: IGenericSelectorData[];
  tooltip?: string;
  selectedItem?: IGenericSelectorData;
}

function RadioSelector(props: IRadioSelectorProps) {
  const [selectedItem, setSelectedItem] = useState<
    IGenericSelectorData | undefined
  >();

  useEffect(() => {
    setSelectedItem(props.selectedItem);
  }, [props.selectedItem]);

  return (
    <div className="w-full p-[15px] pt-[5px] flex flex-col">
      <div className="h-full overflow-scroll scrollbar-hide">
        {props.Items.map((k) => (
          <RadioSelectorItem
            data={k}
            selected={selectedItem?.id === k.id}
            itemSelected={(e) => {
              setSelectedItem(e);
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default RadioSelector;
