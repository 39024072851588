import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    applyDiscount: build.mutation<
      ApplyDiscountApiResponse,
      ApplyDiscountApiArg
    >({
      query: () => ({ url: `/payment/subscription/discount`, method: "POST" }),
    }),
    changePlan: build.mutation<ChangePlanApiResponse, ChangePlanApiArg>({
      query: (queryArg) => ({
        url: `/payment/subscription/change`,
        method: "POST",
        body: queryArg.changePlanRequest,
      }),
    }),
    subscribe: build.mutation<SubscribeApiResponse, SubscribeApiArg>({
      query: (queryArg) => ({
        url: `/payment/subscribe`,
        method: "POST",
        body: queryArg.subscribeRequest,
      }),
    }),
    cardSetup: build.mutation<CardSetupApiResponse, CardSetupApiArg>({
      query: (queryArg) => ({
        url: `/payment/card_setup`,
        method: "POST",
        body: queryArg.cardSetupRequest,
      }),
    }),
    updateBillingAddress: build.mutation<
      UpdateBillingAddressApiResponse,
      UpdateBillingAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/payment/address`,
        method: "POST",
        body: queryArg.updateAddressRequest,
      }),
    }),
    getCoupon: build.query<GetCouponApiResponse, GetCouponApiArg>({
      query: (queryArg) => ({
        url: `/payment/subscription/coupon/${queryArg.promotionCode}`,
      }),
    }),
    cancelSubscription: build.mutation<
      CancelSubscriptionApiResponse,
      CancelSubscriptionApiArg
    >({
      query: () => ({ url: `/payment/subscription`, method: "DELETE" }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type ApplyDiscountApiResponse = unknown;
export type ApplyDiscountApiArg = void;
export type ChangePlanApiResponse = /** status 200 OK */ ChangePlanResponse;
export type ChangePlanApiArg = {
  changePlanRequest: ChangePlanRequest;
};
export type SubscribeApiResponse = /** status 200 OK */ SubscribeResponse;
export type SubscribeApiArg = {
  subscribeRequest: SubscribeRequest;
};
export type CardSetupApiResponse = /** status 200 OK */ CardSetupResponse;
export type CardSetupApiArg = {
  cardSetupRequest: CardSetupRequest;
};
export type UpdateBillingAddressApiResponse =
  /** status 200 OK */ CardSetupResponse;
export type UpdateBillingAddressApiArg = {
  updateAddressRequest: UpdateAddressRequest;
};
export type GetCouponApiResponse = /** status 200 OK */ CouponResponse;
export type GetCouponApiArg = {
  promotionCode: string;
};
export type CancelSubscriptionApiResponse = unknown;
export type CancelSubscriptionApiArg = void;
export type ChangePlanResponse = {
  effective_at?: string;
};
export type ChangePlanRequest = {
  new_plan_id: string;
  new_subscription_type?: "MONTHLY" | "YEARLY";
  promotion_code?: string;
};
export type SubscribeResponse = {
  subscription_id: string;
};
export type SubscribeRequest = {
  plan_id: string;
  subscription_type?: "MONTHLY" | "YEARLY";
  skip_trial?: boolean;
  apply_discount?: boolean;
  promotion_code?: string;
};
export type CardSetupResponse = {
  client_secret: string;
};
export type CardSetupRequest = {
  referral?: string;
};
export type UserAddress = {
  city?: string;
  country?: string;
  line1: string;
  line2?: string;
  postal_code?: string;
  state?: string;
  name?: string;
};
export type UpdateAddressRequest = {
  billing_address?: UserAddress;
};
export type CouponResponse = {
  id?: string;
  percent_off?: number;
  amount_off?: number;
  duration_in_months?: number;
};
export const {
  useApplyDiscountMutation,
  useChangePlanMutation,
  useSubscribeMutation,
  useCardSetupMutation,
  useUpdateBillingAddressMutation,
  useGetCouponQuery,
  useLazyGetCouponQuery,
  useCancelSubscriptionMutation,
} = injectedRtkApi;
