import Logo, { LogoType } from "../../components/Logo";

export interface IFooterProps {
    className: string
}


function Footer(props: IFooterProps) {
    return (<>
        <footer className={`pb-8 ${props.className || ''}`}>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex md:flex-row gap-16">
                <div className="md: flex-1">
                    <a aria-label="Home" href="/#">
                        <Logo type={LogoType.light} className="h-6 sm:h-8" />
                    </a>
                </div>
                <ul className="flex gap-4 md:flex-row md:gap-8 font-display text-lg text-slate-100">
                    <li><a href="#">twitter</a></li>
                    <li><a href="#">instagram</a></li>
                    <li><a href="mailto:info@evercopy.com">contact</a></li>
                </ul>
            </div>
        </footer>
    </>);
}

export default Footer;