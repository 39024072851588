import * as React from "react";
import type { SVGProps } from "react";
const SvgShare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      d="M14.783 2.492a.834.834 0 0 0-.58 1.43l.455.456c-4.876.072-8.825 4.062-8.825 8.955a.834.834 0 1 0 1.667 0 7.275 7.275 0 0 1 7.148-7.284l-.445.445a.832.832 0 0 0 .262 1.373.833.833 0 0 0 .916-.194l1.8-1.801a.833.833 0 0 0-.003-1.33l-1.797-1.798a.834.834 0 0 0-.598-.252ZM5.208 2.5A2.721 2.721 0 0 0 2.5 5.208v9.584A2.721 2.721 0 0 0 5.208 17.5h9.584a2.721 2.721 0 0 0 2.708-2.708v-3.125a.832.832 0 1 0-1.667 0v3.125a1.03 1.03 0 0 1-1.041 1.041H5.208a1.03 1.03 0 0 1-1.041-1.041V5.208a1.03 1.03 0 0 1 1.041-1.041h3.125a.833.833 0 1 0 0-1.667H5.208Z"
    />
  </svg>
);
export default SvgShare;
