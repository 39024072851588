import { t } from "i18next";
import { FunctionComponent, useState } from "react";
import PopupRadioItem from "./PopupRadioItem";

interface PopupRadioProps {
    selectedItem: (item: changeBrandPopupItemModel) => void
}

export interface changeBrandPopupItemModel {
    title: string,
    id: number
}

export const ChangeBrandPopupItems: changeBrandPopupItemModel[] = [
    {
        title: 'delete-all-previous-revisions-within-this-loop-and-start-with-the-new-selected-brand',
        id: 1
    },
    {
        title: 'start-a-new-loop-with-the-new-selected-brand',
        id: 2
    }
]

const PopupRadio: FunctionComponent<PopupRadioProps> = (props: PopupRadioProps) => {
    const [selectedItem, setSelectedItem] = useState<changeBrandPopupItemModel>(ChangeBrandPopupItems[0])

    return (
      <div className="m-2">
        <text className="text-black text-BodyMedium my-5">
          <p className="text-black text-BodyMedium mb-2">
            {t("how-would-you-like-to-proceed")}
          </p>
          {ChangeBrandPopupItems.map((k,index) => (
            <div key={k.id} style={{ marginTop: index === 0 ? 0 : "9px" }}>
              <PopupRadioItem
                isSelected={selectedItem.id === k.id}
                item={k}
                selected={(status) => {
                  if (status) {
                    props.selectedItem(k);
                    setSelectedItem(k);
                  }
                }}
              />
            </div>
          ))}
        </text>
      </div>
    );
}

export default PopupRadio;