import CreativityIcon from "../../../assets/IconComponents/CreativityIcon"
import MultiRangeSliderWrapper from "../../../components/Slider/MultiRangeSliderWrapper"
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setNewContent } from "../../../store/contentSlice";
import {Slider} from '@mui/material'
import { useEffect, useState } from "react";

const CreativitySlider = ({isInSide}) => {
    const dispatch = useAppDispatch()
    const newContent = useAppSelector((k) => k.content.newContent);
    const [defaultValue, setDefaultValue] = useState(1)
    const [sliderValue, setSliderValue] = useState("Balanced Consistency and Creativity");
    const creativityLevelHeaders = [
        "High Consistency and Less Creativity",
        "Balanced Consistency and Creativity",
        "High Creativity"
    ]
    enum creativityDefaultValue {
        low,
        balanced,
        high
    }
    const creativityLevelMarks = [

            {
                value: 0,
                label: 'Low',
            },
            {
                value: 1,
                label: 'Balanced',
            },
            {
                value: 2,
                label: 'High',
            },
      ];

      useEffect(() => {
        if(newContent?.creativity){
            setDefaultValue(creativityDefaultValue[newContent.creativity])
            setSliderValue(creativityLevelHeaders[creativityDefaultValue[newContent.creativity]])
        }
        else {
            dispatch(setNewContent({
                ...newContent,
                creativity: "balanced"
            }))
        }
      }, [])

      const CustomSliderStyles = {
        '& .MuiSlider-thumb': {
            color: "#FF5555",
            height: "16px",
            width: "16px"

        },
        '& .MuiSlider-track': {
            display: "none"
        },
        '& .MuiSlider-rail': {
            height: "10px",
            background: "#EAEBE6",
            border: "1px solid #EAEBE6",
            borderRadius: "100px"
        },
        '& .MuiSlider-mark': {
            display: "none"
        },
        '& .MuiSlider-active': {
            color: "#f5e278"
        },
        '& .MuiSlider-markLabel': {
            color:  "#8E8E8E",
            fontFamily: "Inter Tight",
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "140%",
        },
        '&': {
            width: isInSide ? '100%': "500px",
            marginLeft: isInSide ? '0px': "12px",
            marginBottom: isInSide ? '20px': "12px"
        },
    };


    return(
        <div className="flex w-full p-[14px] flex-col items-start gap-[20px] bg-white" style={{border: "1px solid #EAEBE6", borderRadius: "6px"}}>
            <div className={`flex ${isInSide ? 'flex-col' : ''} content-center items-start gap-[12px] self-stretch`}>
                <div className="flex w-[240px] items-center gap-[10px]">
                    <CreativityIcon />
                    <div className="text-black text-sm font-semibold font-['Inter Tight'] leading-tight">
                        Creativity Filter
                    </div>
                </div>
                <div className="flex flex-col content-center items-start gap-[8px] flex-1 self-stretch w-[300px]-">
                    <div className="text-black font-['Inter Tight'] font-normal" style={{fontSize: "13px", fontStyle: "normal", lineHeight: "140%"}}>
                        {sliderValue}
                    </div>
                    {<Slider
                        value={defaultValue}
                        step={1}
                        min={0}
                        max={2}
                        sx={CustomSliderStyles}
                        marks={creativityLevelMarks}
                        onChange={(e, val, activeThumb) => {
                            if(typeof(val) === "number"){
                                setSliderValue(creativityLevelHeaders[val])
                                setDefaultValue(val)
                                dispatch(setNewContent({
                                    ...newContent,
                                    creativity: creativityLevelMarks[val].label.toLowerCase()
                                }))
                            }
                        }}
                    />}

                </div>

            </div>
        </div>
    )
}

export default CreativitySlider
