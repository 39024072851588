import { useEffect, useState } from "react";
import { useLazyDownloadQuery } from "../services/ImageApi.old/ImageApi";
import { ShimmerThumbnail } from "react-shimmer-effects";

export interface IContentImageProps {
  id: string;
  className?: string;
  title?: string;
  forceDirectRender?: boolean;
}

function ContentImage(props: IContentImageProps) {
  const [downloadImage, { data, isLoading, isFetching }] =
    useLazyDownloadQuery();

  useEffect(() => {
    if (props.id === "") {
      console.error("no image id to download");
      return;
    }

    downloadImage({ id: props.id });
  }, [props.id]);

  return (
    <>
      {data && props.forceDirectRender && !isLoading && !isFetching && (
        <img
          title={props.title}
          src={`${process.env.REACT_APP_BASE_URL}file/download/image/${props.id}`}
          className={props.className}
        />
      )}
      {data &&
        !props.forceDirectRender &&
        !data?.includes("PNG") &&
        !isLoading &&
        !isFetching && (
          <img
            title={props.title}
            src={`data:image;base64,${data}`}
            className={props.className}
          />
        )}
      {data &&
        !props.forceDirectRender &&
        data?.includes("PNG") &&
        !isLoading &&
        !isFetching && (
          <img
            title={props.title}
            src={`${process.env.REACT_APP_BASE_URL}file/download/image/${props.id}`}
            className={props.className}
          />
        )}
      {(isLoading || isFetching) && (
        <ShimmerThumbnail className={props.className} rounded fitOnFrame />
      )}
    </>
  );
}

export default ContentImage;
