import * as React from "react";
const SvgAddBriefCreativeTritoneLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <rect width={28} height={28} fill="#F55" opacity={0.1} rx={4} />
    <path
      fill="#F55"
      d="m13.927 15.073-1.654-1.66a1.904 1.904 0 0 0-2.62 0l-2.32 2.32v2.934a2.006 2.006 0 0 0 2 2h8c.092 0 .184-.008.274-.027a1.93 1.93 0 0 0 1.226-.66l-4.906-4.907Zm7.21-6.211-2-2a.667.667 0 0 0-.942 0l-2 2a.667.667 0 0 0 .943.943L18 8.943v3.724a.667.667 0 0 0 1.333 0V8.943l.862.862a.667.667 0 0 0 .943-.943Z"
    />
    <path
      fill="#F55"
      d="m14.593 15.74 4.24 4.24c.322-.361.5-.829.5-1.313V16.92l-2.32-2.313a1.897 1.897 0 0 0-2.62 0l-.465.465.665.668Z"
      opacity={0.2}
    />
    <path
      fill="#F55"
      d="M18.667 13.333a.666.666 0 0 1-.667-.666V8.943l-.862.862a.667.667 0 0 1-.943-.943l.196-.195H9.333a2.006 2.006 0 0 0-2 2v5.066l2.32-2.32a1.904 1.904 0 0 1 2.62 0l1.654 1.66v-.001l.466-.465a1.897 1.897 0 0 1 2.62 0l2.32 2.313v-4.253a.667.667 0 0 1-.666.666Z"
      opacity={0.5}
    />
  </svg>
);
export default SvgAddBriefCreativeTritoneLogo;
