import { FC } from "react";
import { Progress } from "antd";
import { reformatText } from "../../../helpers/Utils";
import svgIcons from "../../../assets/IconComponents/svgs";
import { MarketingPlannerResponse } from "../../../services/MarketingPlanner";

const campaignsInfo = [
    { key: 'FEATURE_UNVEILING', icon: svgIcons.FEATURE_UNVEILING_SVG },
    { key: 'SALES_CONVERSATION', icon: svgIcons.SALES_CONVERSATION_SVG },
    { key: 'TRAFFIC', icon: svgIcons.TRAFFIC_SVG },
    { key: 'AWARENESS', icon: svgIcons.AWARENESS_SVG },
]
interface CampaignTableProps {
    campaigns: (MarketingPlannerResponse & {
        startTime: string;
        duration: string;
        status: string | undefined;
    })[];
}

const CampaignTable: FC<CampaignTableProps> = ({ campaigns }) => {
    return (
        <div className="w-full">
            <div className="container mx-auto">
                <div className="space-y-4">
                    <div className="grid grid-cols-7 gap-4 px-4 text-BodyMedium text-footnote">
                        <div className="col-span-3">Name</div>
                        <div>Start Time</div>
                        <div>Activities</div>
                        <div>Duration</div>
                        <div>Status</div>
                    </div>
                    {campaigns.map((item, index) => (
                        <div
                            key={index}
                            className="grid grid-cols-7 gap-4 p-4 bg-white border border-border rounded-lg hover:bg-lightBg items-center"
                        >
                            <div className="col-span-3 text-BodyMedium text-black flex flex-row align-middle items-center truncate">
                                <div className="mx-0 my-1 w-8">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: `${(campaignsInfo.filter(channel => channel.key === item.campaignType)?.[0]?.icon || svgIcons.FEATURE_UNVEILING_SVG)
                                                .replace(/width="[^"]*"/, 'width="32px"')
                                                .replace(/height="[^"]*"/, 'height="32px"')}`
                                        }}
                                    />
                                </div>
                                <div className="flex flex-col pl-3 pr-4 truncate">
                                    <span className="truncate">{item.description}</span>
                                    <span className="text-BodySmall text-footnote mt-1">{reformatText(item.campaignType)}</span>
                                </div>
                            </div>
                            <div className="text-BodyMedium font-medium text-black">{item.startTime}</div>
                            <div className="text-BodyMedium font-medium text-black">{item.activitySize}</div>
                            <div className="text-BodyMedium font-medium text-black">{item.duration}</div>
                            <div className="text-BodyMedium font-medium text-black">
                                <Progress
                                    percent={parseInt(item?.status ? item.status : "0")}
                                    size="small"
                                    style={{ width: "60px" }}
                                    strokeColor={item.status === "100" ? '' : "#FFA841"}
                                    format={percent => `%${percent}`}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CampaignTable;
