import * as React from "react";
import type { SVGProps } from "react";
const SvgGeneralAnnouncementIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.1" width="28" height="28" rx="4" fill="#FF5555" />
    <rect
      x="7"
      y="7"
      width="14"
      height="14"
      fill="url(#pattern_BgCIABpl8xzxlVwWAukY/nIkSNPxZTN95Z)"
    />
    <defs>
      <pattern
        id="pattern_BgCIABpl8xzxlVwWAukY/nIkSNPxZTN95Z"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#image0_6690_42659" transform="scale(0.0104167)" />
      </pattern>
      <image
        id="image0_6690_42659"
        width="96"
        height="96"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAEj0lEQVR4nO2dTYgcRRiGWyMxkU00YIwxRgRFg5hL1hxChIVocE3WHXu63urp5BCIhBzyBzl526tXLwZB4t2jBxVyTwgIe0xEBM+55fcQlVeqpmfd2cSZ6Z6ururp74UPlt1lt+d96vvqp7urokgkEolEIpFIJBKJREPiiRM7GMcHmSRLBFIq1SNwnMA8O52Xhn9bVJkIbKLWBwlk/xsGhoEDbKruP4siApup9ccjzR+OIwKhIvHUqS0EFguY348k+aCqa2ituLy8La/1xcwflCPpE8qLcfwK07Rbyvz/Yn6KS2ivmKZv2RHOdOZn1PqY78/SvJFObEcxWUWhfX+mxoinT28r1dmOCd+fqxFip7OXWidVmy8AxoiLi88TOOTCeAEwRgT2UOvPXZovGfAUcWFhC7U+7Np4AbBBXFl5lsC7rmq9ABghAm9Q68/qNF4yILLGv0qtP/FhfGsBMIqeyVu8V+NbB4DAVmr9PpOk49v01gAgMMc0fceu1ffvTHk3fOYA0IxezITp5Mnt7PVeY7e7z06eAmvpQQCwtVepC1TqFwK3qdQDApwysiZHXcbvIfAtgb8rMJwCoIj5/en8fQfGUzJgfMu/RKX+cWg+fZeQYEuQbfnuzadvA4MEwDh+3XHZoQAYBQC4WpP59N2Cg8sAO9R0M9qhAJgMwMUazafvFjx1ZNnLVWfAzwIARSA8olJXKgNB4HcBgCIABpl8xzxlVwWAukY/nIkSNPxZTN95ZloAdZrPGQNAO3dKko4AgCcAfQh3zcquZAA8AejH9wIAHgEo9disIksfAG8ZYOKyAIBHAEr9KADgNQP+FADwCuChAIDXEvRAAEBKULtmwpBOOAsGgAxD4XciVmY5QhbjUFUGXJWlCHhcjIvj3QIAzVyOlhsymOqGzBelzbcAlPpNbsigDIA7ZmOnqczPM0BuyqMQgEcEvqnypvx5yQAUAbCzEuPXAKTpXnkwCxMDqNT8NQjAdzVmQdbkcPlCxj0BAD8ALIQk+bSmUpQ1OZwBWHtOVF7QyLwByCEsOy5HWZPDOYAcwk4q9TWBvwQA6gew4c2ZcwR+InCromWLrMkRhSICv5YCsLLuRe043i0vapcH8EMZANHovzmX7wEkWxWMBaDUV1UDWC8uLb1AYD/TNKgtDKJQRKXOugTwxHY1DragbDYA4GgdANbL9hn9CaMAyLcDrhXAhoxYbncGLCw8lz+SXSuAoW2JgfcIqFYCMCLwhy8AG84D+LCdAJS65hvAQLYsJUm3XQD6ewoFAcDITvAcb+IahSQCX4YEYCCm6ZtUCm0AoEMEYGSXOhwMWaOQZA62CRXAxEdVNfkAB3sIWsAABiLw9sweYVJoiVqpu96us9fbNZOH+BC4WQDADc/XOjfVMVbAi1FosvuKTl6Cznu/3lk7yC0/NmR1AvNXzRGCUShHGSbJRwUAHDE3kqJQlT9XNArCaqnX+N0fADE/co9q87P+SC/8wzxtqzLPmSp1I++Y7xO4nj97GkTLf5pMXafWB9jtHrOTNxPma/O9EGu+SCQSiUQikUgkikLXv+3wAqKqvDJLAAAAAElFTkSuQmCC"
      />
    </defs>
  </svg>
);
export default SvgGeneralAnnouncementIcon;
