import * as React from "react";
const Search2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    fill="none"
    {...props}
  >
    <path opacity="0.5"
          d="M11.25 19.5C15.9445 19.5 19.75 15.6945 19.75 11C19.75 6.3055 15.9445 2.5 11.25 2.5C6.5555 2.5 2.75 6.3055 2.75 11C2.75 15.6945 6.5555 19.5 11.25 19.5Z"
          fill={props.fill ?? "#FFFFFF"}/>
    <path fill-rule="evenodd"
          d="M11.2504 5.5C10.5937 5.4993 9.94329 5.6283 9.33656 5.87959C8.72942 6.13106 8.17796 6.50003 7.71387 6.9653C7.32384 7.35632 7.32465 7.98948 7.71567 8.37951C8.10669 8.76954 8.73985 8.76873 9.12988 8.37771C9.40787 8.09901 9.73819 7.878 10.1019 7.72737C10.4655 7.57675 10.8554 7.49948 11.249 7.5H11.2517C11.6453 7.49948 12.0352 7.57675 12.3989 7.72737C12.7626 7.878 13.0929 8.09901 13.3709 8.37771C13.7609 8.76873 14.3941 8.76954 14.7851 8.37951C15.1761 7.98948 15.1769 7.35632 14.7869 6.9653C14.3228 6.50003 13.7713 6.13106 13.1642 5.87959C12.5575 5.6283 11.9071 5.4993 11.2504 5.5ZM16.6543 16.4039C17.0448 16.0134 17.678 16.0134 18.0685 16.4039L22.311 20.6464C22.7015 21.0369 22.7015 21.6701 22.311 22.0606C21.9205 22.4511 21.2873 22.4511 20.8968 22.0606L16.6543 17.8181C16.2637 17.4276 16.2637 16.7944 16.6543 16.4039Z"
          fill={props.fill ?? "#FFFFFF"}/>
  </svg>
);
export default Search2;
