import { useEffect, useState } from "react"

export interface ITextViewProps {
  title?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  className?: string;
  initialValue?: string;
  maxWordCount?: number;
  maxCharCount?: number;
  onBlur?: () => void;
  innerClassName?: string;
  onSide?: boolean;
  additionalContent?: React.ReactNode;
}

function TextView(props: ITextViewProps) {
  const [value, setValue] = useState(props.initialValue)
  const [wordCount, setWordCount] = useState(0)
  const [charCount, setCharCount] = useState(0)

  useEffect(() => {
    setValue(props.initialValue)
    handleChange({ target: { value: props.initialValue } })
  }, [props.initialValue])


  const handleChange = (event) => {
    var value = event.target.value ?? ""

    const wordCount = value != "" ? value.split(" ").length : 0
    const shouldSetValue = props.maxWordCount ? wordCount <= props.maxWordCount : true
    const shouldSetValueCharFilter = props.maxCharCount ? value.length <= props.maxCharCount : true

    if (shouldSetValue && shouldSetValueCharFilter) {
      setWordCount(wordCount)
      setCharCount(value.length)
      props.onChange(value)
      setValue(event.target.value)
    }
  }

  return (
    <div
      className={`flex flex-col justify-center items-center ${props.className}`}
    >
      <div
        className={`flex ${
          props.onSide ? "flex-row justify-between" : "flex-col"
        } w-full h-full pb-2`}
      >
        <text className="text-black text-H8">{props.title}</text>
        <textarea
          defaultValue={value}
          value={value}
          className={`${
            value != "" ? "bg-white" : "bg-lightBg"
          } mt-2 resize-none outline-none h-full border-1 border-border ${
            props.innerClassName ? props.innerClassName : "rounded-xl"
          } bg-light p-3 pb-[50px] text-BodyMedium text-black placeholder:text-BodyMedium placeholder:text-gray`}
          placeholder={props.placeholder}
          onChange={handleChange}
          onBlur={() => {
            props.onBlur && props.onBlur();
          }}
        />
        {props.maxWordCount && (
          <div className="-mt-8 flex justify-end pr-4">
            <text className="text-black text-BodySmall bg-white p-1 rounded-md">
              {wordCount}/{props.maxWordCount}
            </text>
          </div>
        )}
        
        {props.additionalContent}
        {/* {props.maxCharCount && (
          <div className="-mt-8 flex justify-end pr-4">
            <text className="text-black text-BodySmall bg-white p-1 rounded-md">
              {charCount}/{props.maxCharCount}
            </text>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default TextView;