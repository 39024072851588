import * as React from "react";
import type { SVGProps } from "react";
const SvgLogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={25}
    fill="none"
    {...props}
  >
    <mask
      id="logoIcon_svg__a"
      width={18}
      height={11}
      x={0}
      y={14}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path fill="#fff" d="M.318 14.086h17.364v10.16H.318v-10.16Z" />
    </mask>
    <g mask="url(#logoIcon_svg__a)">
      <path
        fill="#FD3C34"
        fillRule="evenodd"
        d="M15.128 20.368.15 24.328v-2.7c0-1.486 1.148-3 2.546-3.376l14.986-3.96v2.7c0 1.484-1.148 3-2.554 3.376Z"
        clipRule="evenodd"
      />
    </g>
    <mask
      id="logoIcon_svg__b"
      width={18}
      height={11}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path fill="#fff" d="M.318 0h17.364v10.258H.318V0Z" />
    </mask>
    <g mask="url(#logoIcon_svg__b)">
      <path
        fill="#FFC2C2"
        fillRule="evenodd"
        d="M15.128 6.078.15 10.039V7.338c0-1.485 1.148-3 2.546-3.375L17.682.002v2.701c0 1.485-1.148 3-2.554 3.375Z"
        clipRule="evenodd"
      />
    </g>
    <mask
      id="logoIcon_svg__c"
      width={18}
      height={12}
      x={0}
      y={6}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path fill="#fff" d="M.318 6.908h17.364v10.529H.318V6.907Z" />
    </mask>
    <g mask="url(#logoIcon_svg__c)">
      <path
        fill="#FC5D56"
        fillRule="evenodd"
        d="M15.128 13.137.15 17.104v-2.7c0-1.486 1.148-3 2.546-3.376l14.986-3.96v2.7c0 1.486-1.148 3-2.554 3.369Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
);
export default SvgLogoIcon;
