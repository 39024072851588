import { SVGProps, useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";
import Icon from "../Icon";
import ActionSheet from "../ActionSheet/ActionSheet";
import PrimaryButton from "../PrimaryButton";
import { t } from "i18next";
import FilterSelectorPopup from "./FilterSelectorPopup";
import FilterSelectorItem from "./FilterSelectorItem";

export interface IFilterSelectorData {
  id: string,
  title: string,
}

export interface IFilterSelectorProps {
  icon?: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | undefined;
  title?: string;
  subTitle: string;
  ItemsSelected: (data: IFilterSelectorData[]) => void;
  Items: IFilterSelectorData[];
  tooltip?: string;
  selectedItems?: IFilterSelectorData[];
  isPopoverOpen: boolean
  setIsFilterPopoverOpen: (boolean) => void
}

function FilterSelector(props: IFilterSelectorProps) {
  const [selectedItems, setSelectedItems] = useState<
    IFilterSelectorData[]
  >([]);
  const [actionSheetOpened, setActionSheetOpened] = useState(false);

  useEffect(() => {
    setSelectedItems(props.selectedItems ?? []);
  }, [props.selectedItems]);

  return (
    <>
      <div className={`max-lg:hidden lg:flex`}>
        <Popover
          isOpen={props.isPopoverOpen}
          positions={["bottom"]}
          align="start"
          padding={10}
          onClickOutside={() => {
            props.setIsFilterPopoverOpen(false);
          }}
          content={
            <FilterSelectorPopup
              subTitle={props.subTitle}
              data={props.Items}
              closePopup={() => props.setIsFilterPopoverOpen(false)}
              selectedItems={selectedItems}
              selectedItemsChanged={(e) => {
                setSelectedItems(e);
                props.ItemsSelected(e);
                props.setIsFilterPopoverOpen(false);
              }}
            />
          }
        >
          <div
            className="h-[36px]"
            onClick={() => props.setIsFilterPopoverOpen(!props.isPopoverOpen)}
            data-tooltip-id={props.tooltip ? "app-tooltip" : ""}
            data-tooltip-html={props.tooltip}
          >
            <button
              className={`
                                    h-[36px]
                                    w-[36px]
                                    bg-white p-[10px]
                                    rounded-lg flex flex-row items-center justify-center
                                    border-1 border-border
                                `}
              onClick={() => { }}
            >
              <div className=" rounded-sm p-[2px]">
                <Icon
                  component={props.icon}
                  className="w-[18px] h-[18px] overflow-hidden"
                />
              </div>
            </button>
          </div>
        </Popover>
      </div>
      <div className={`lg:hidden max-lg:flex`}>
        <ActionSheet
          title={props.subTitle}
          isExpanded={actionSheetOpened}
          setIsExpanded={setActionSheetOpened}
          triggerView={
            <button
              className={`
                                    h-full
                                    bg-white p-[10px]
                                    rounded-lg flex flex-row items-center
                                    border-1 border-border
                                `}
              onClick={() => { }}
            >
              <div className=" rounded-sm p-[2px]">
                <Icon
                  component={props.icon}
                  className="w-[20px] overflow-hidden"
                />
              </div>
            </button>
          }
          body={
            <div className="w-full p-[15px] pt-[5px] flex flex-col">
              <div className="h-full overflow-scroll scrollbar-hide">
                {props.Items.map((k) => (
                  <FilterSelectorItem
                    data={k}
                    selected={!!selectedItems?.find((item) => item.id === k.id)}
                    itemSelected={(e) => {
                      const itemExists = !!selectedItems?.find(
                        (item) => item.id === e.id
                      );

                      if (itemExists) {
                        // Item is already selected, remove it
                        const updatedSelectedItems = selectedItems?.filter(
                          (item) => item.id !== e.id
                        );
                        setSelectedItems(updatedSelectedItems);
                      } else {
                        // Item is not selected, include it
                        setSelectedItems([...(selectedItems || []), e]);
                      }
                    }}
                  />
                ))}
                <PrimaryButton
                  className="w-full h-[36px] mt-2"
                  title={t("apply-filters-0")}
                  onClick={() => {
                    props.ItemsSelected(selectedItems);
                    setActionSheetOpened(false);
                  }}
                />
              </div>
            </div>
          }
        />
      </div>
    </>
  );
}

export default FilterSelector;