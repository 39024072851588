import * as React from "react";
import type { SVGProps } from "react";
const SvgResults = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      
      d="M3.958 1.667A1.46 1.46 0 0 0 2.5 3.125v4.167A1.46 1.46 0 0 0 3.958 8.75h1.459v-.208a2.288 2.288 0 0 1 4.167-1.313V3.124a1.46 1.46 0 0 0-1.459-1.458H3.958Zm7.917 0c-.798 0-1.458.66-1.458 1.458v4.167c0 .798.66 1.458 1.458 1.458h4.167c.798 0 1.458-.66 1.458-1.458V3.125c0-.798-.66-1.458-1.458-1.458h-4.167Zm0 1.25h4.167c.123 0 .208.085.208.208v4.167a.199.199 0 0 1-.208.208h-4.167a.199.199 0 0 1-.208-.208V3.125c0-.123.085-.208.208-.208Zm-4.167.416A.625.625 0 0 1 8.15 4.4L6.065 6.484a.62.62 0 0 1-.883 0l-1.25-1.25a.625.625 0 1 1 .884-.884l.807.808 1.642-1.642a.623.623 0 0 1 .442-.183Zm.001 3.75A1.46 1.46 0 0 0 6.25 8.542v3.877C5 14.146 5 14.69 5 14.898c0 .864.604 1.922 1.796 3.143a2.354 2.354 0 0 0 2.09.674l2.488-.43a2.592 2.592 0 0 0 1.958-1.602c.385-.986.632-1.663.733-2.014a2.652 2.652 0 0 0-.254-2.051 2.992 2.992 0 0 0-1.822-1.401c-.017-.005-1.163-.21-2.822-.544V8.542A1.46 1.46 0 0 0 7.71 7.083Z"
    />
  </svg>
);
export default SvgResults;
