import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Close, Copy, Delete, Edit, Like, Liked, Refresh } from "../assets/IconComponents";
import SvgCloseLittle from "../assets/IconComponents/CloseLittle";
import { useAppDispatch, useAppSelector } from "../helpers/hooks";
import { ContentWizardStep } from "../models/ContentWizardStep";
import { LoopChannels, LoopDetailChannel } from "../pages/LoopDetails/LoopDetailsHeader/Channel/LoopDetailChannels";
import { Content } from "../services/ContentApi";
import { useEditNameMutation } from "../services/ContentApi.old/ContentApi";
import { useDeleteImageMutation, useFavoriteImageMutation } from "../services/ImageApi.old/ImageApi";
import { Revision, useEditTextMutation, useFavoriteMutation } from "../services/RevisionApi";
import { refreshPage, setDialog, toggleDialog, updateMenu } from "../store/appSlice";
import { setNewContent } from "../store/contentSlice";
import IconButton from "./IconButton";
import PrimaryButton from "./PrimaryButton";
import { useCopyToClipboard } from "@uidotdev/usehooks";

export interface IImageContentDetailPopupProps {
  show: boolean
  onClose: () => void
  className?: string
  closable?: boolean
  fullScreen?: boolean
  content?: Content
  revision?: Revision
  generateSimilar: () => void
  updatePage: () => void
  deleteRevisionItem: (revision: Revision) => void
  editingEnabled?: boolean
  imageContent?: string
}

function ImageContentDetailPopup(props: IImageContentDetailPopupProps) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const newContent = useAppSelector(k => k.content.newContent)

  const [updateText, { isSuccess: textUpdated }] = useEditTextMutation()
  const [editName, { isSuccess: nameUpdated }] = useEditNameMutation()

  const [setTextFavorite, { isSuccess: favoriteTextUpdated }] = useFavoriteMutation()

  const [setImageFavorite, { isSuccess: favoriteImageUpdated }] = useFavoriteImageMutation()
  const [setImageDelete, { isSuccess: isImageDeleted }] = useDeleteImageMutation()

  const [editing, setEditing] = useState(false)
  const [isFavori, setFavori] = useState(props.revision?.is_favorite ?? false)
  const [contentName, setContentName] = useState(props.content?.name ?? "")
  const [text, setText] = useState(props.revision?.generated_content)
  const [showUpdateBrief, setShowUpdateBrief] = useState(false)
  const [showCopyRevision, setShowCopyRevision] = useState(false)
  const [channel, setChannel] = useState<LoopDetailChannel>(LoopChannels[0])
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const [copyButtonText, setCopyButtonText] = useState(t('copy'))
  const [showContentEdit, setShowContentEdit] = useState(false);
  const isBanner = (props.content?.image_height ?? 1080) < 500;
  const imageClass = isBanner ? `relative overflow-hidden m-[135px] p-[162px] border-0 border-border flex rounded-[20px] flex-col` : `relative overflow-hidden m-[15px] p-[18px] border-1 border-border flex rounded-[20px] flex-col`;
  const [revisionEditorEnabled, setRevisionEditorEnabled] = useState(true)

  useEffect(() => {
    setContentName(props.content?.name ?? "")
  }, [props.content?.name])

  useEffect(() => {
    if ((props.content && props.content.creative && props.content.creative.length > 0) ) {
      setRevisionEditorEnabled(false);
    }
  }, [props.content])

  useEffect(() => {
    if (props.revision) {
      setFavori(props.revision.is_favorite ?? false)
      setChannel(LoopChannels.find(k => k.name.toLowerCase() === props.revision?.ai_model?.toLowerCase())!)
      if((props.content && (props.content.platform ?? "") === "Product" && (!props.revision.ctatext &&  !props.revision.headerText)))
      {
        setRevisionEditorEnabled(false);
      }
    }
  }, [props.revision])

  useEffect(() => {
    setText(props.revision?.generated_content ?? "")
    setFavori(props.revision?.is_favorite ?? false)
  }, [props.revision?.generated_content])

  useEffect(() => {
    if (props.editingEnabled)
      setEditing(true)

  }, [props.editingEnabled])

  useEffect(() => {
    dispatch(updateMenu())
    dispatch(refreshPage())
    props.updatePage()

    if (newContent?.wizardStep === ContentWizardStep.contentResult) {
      dispatch(setNewContent(
        {
          ...newContent,
          loopName: contentName
        }
      ))
    }

  }, [textUpdated, nameUpdated, favoriteTextUpdated, favoriteImageUpdated, isImageDeleted])

  useEffect(() => {
    dispatch(updateMenu())
    props.updatePage()
    props.onClose()
  }, [isImageDeleted])


  const updateFields = () => {
    setEditing(false)

    updateText({
      revisionUpdateRequest: {
        revision_id: props.revision?.id?.revision_id ?? "",
        updated_text_content: text ?? ""
      }
    })
  }

  const updateFavorite = () => {
    setFavori(!isFavori);
    setTextFavorite({
      isFavorite: !isFavori ?? false,
      revisionId: props.revision?.id?.revision_id ?? "",
    })
  }



  return (
    <>
      {
        props.show &&
        <div
          className={`${props.fullScreen
            ? "fixed left-0 right-0 top-0 bottom-0"
            : "absolute  w-full h-[430px] lg:w-[calc(100%-220px)]"
            } backdrop-blur-sm z-40 flex items-center justify-center ${props.className}`}
          onClick={() => {
            if (props.closable) {
              setEditing(false);
              props.onClose();
            }
          }}
        >
          <div
            className={`flex flex-col mt-5 mx-2 sm:mt-0 lg:mx-0 sm:justify-center items-center w-full lg:w-[calc(40%)]`}
          >
            <div
              className="flex flex-col w-full bg-white border-border border-1 rounded-xl shadow-lg shadow-red-100"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className={imageClass}>
                <div style={{ paddingTop: "100%" }}></div>
                <img
                  src={`data:image/png;base64,${props.imageContent}`}
                  alt="Scaled Image"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                    borderRadius: "15px",
                  }}
                />
              </div>

              {!editing && (
                <div className="w-full flex flex-row p-[15px] pt-0">
                  <PrimaryButton
                    noFill
                    icon={<Close fill="#121315" className="mr-2" />}
                    className="mt-4 h-10 flex flex-1 !rounded-full"
                    title={t("close")}
                    onClick={() => {
                      props.onClose();
                    }}
                  />
                  {!props.content?.read_only && (
                    <PrimaryButton
                      isDisabled={!revisionEditorEnabled}
                      icon={<Edit fill="#FFFFFF" className="mr-2" />}
                      className="mt-4 ml-4 h-10 flex flex-1 !rounded-full"
                      title={t("edit")}
                      onClick={() => {
                        navigate('/app/revisionedit/' + (props.revision?.id?.revision_id ?? ""))
                      }}
                    />
                  )}
                  {!props.content?.read_only && (
                    <PrimaryButton
                      icon={<Refresh fill="#FFFFFF" className="mr-2" />}
                      className="mt-4 ml-4 h-10 flex flex-1 !rounded-full"
                      title={t("generate-similiar")}
                      onClick={() => {
                        props.generateSimilar();
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default ImageContentDetailPopup;