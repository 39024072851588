
export const initFacebookSdk = () => {
    return new Promise<void>((resolve, reject) => {
        // Load the Facebook SDK asynchronously
        console.log("FB INIT STARTED")

        window.FB.init({
            appId: '1067907954285318',
            cookie: true,
            xfbml: true,
            version: 'v19.0'
        });
        // Resolve the promise when the SDK is loaded
        resolve();
    });
};


export const getFacebookLoginStatus = () => {
    return new Promise((resolve, reject) => {
        window.FB.getLoginStatus((response) => {
            resolve(response);
        });
    });
};

export const fbLogin = () => {
    return new Promise<any>((resolve, reject) => {
        window.FB.login((response) => {
            resolve(response)
        }, {
            scope: 'ads_management,ads_read,pages_show_list,business_management,pages_read_engagement,pages_manage_posts',
            return_scopes: true,
            // response_type: 'code',
            // config_id: '389558253966493',
        })
    })
}
