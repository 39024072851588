import * as React from "react";
const Event = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={19}
        fill="none"
        {...props}
    >
        <path opacity="0.5"
              d="M12 11C12 9.89543 12.8954 9 14 9H19C20.1046 9 21 9.89543 21 11V13C21 14.1046 20.1046 15 19 15H14C12.8954 15 12 14.1046 12 13V11Z"
              fill="#8E8E8E"/>
        <path opacity="0.5"
              d="M5 4C5 2.89543 5.89543 2 7 2H12C13.1046 2 14 2.89543 14 4V6C14 7.10457 13.1046 8 12 8H7C5.89543 8 5 7.10457 5 6V4Z"
              fill="#8E8E8E"/>
        <path opacity="0.5"
              d="M3 18C3 16.8954 3.89543 16 5 16H10C11.1046 16 12 16.8954 12 18V20C12 21.1046 11.1046 22 10 22H5C3.89543 22 3 21.1046 3 20V18Z"
              fill="#8E8E8E"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M11 11C10.4477 11 10 11.4477 10 12C10 12.5523 10.4477 13 11 13H12V11H11ZM21 11V13H22C22.5523 13 23 12.5523 23 12C23 11.4477 22.5523 11 22 11H21Z"
              fill="#8E8E8E"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M4 4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6L5 6V4H4ZM14 4V6L15 6C15.5523 6 16 5.55228 16 5C16 4.44772 15.5523 4 15 4H14Z"
              fill="#8E8E8E"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M2 18C1.44772 18 1 18.4477 1 19C1 19.5523 1.44772 20 2 20L3 20V18H2ZM12 18V20L13 20C13.5523 20 14 19.5523 14 19C14 18.4477 13.5523 18 13 18H12Z"
              fill="#8E8E8E"/>
    </svg>
);
export default Event;
