import * as React from "react";
import type { SVGProps } from "react";
const SvgSettingsCollapsedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="40" height="40" fill="#121315" />
    <path
      d="M26.3079 20.9508C26.0108 20.7329 25.8333 20.3829 25.8333 20.0142C25.8333 19.6454 26.0108 19.2954 26.3075 19.0779L27.68 18.0717C27.8987 17.9108 27.9891 17.6279 27.9037 17.37C27.5504 16.3083 26.9916 15.34 26.2425 14.4912C26.0621 14.2879 25.7712 14.2237 25.5225 14.3329L23.9771 15.0125C23.6404 15.1608 23.2483 15.1396 22.9287 14.955C22.6096 14.7708 22.3954 14.4425 22.355 14.0762L22.1691 12.3796C22.1396 12.1096 21.9387 11.8896 21.6729 11.835C20.5908 11.6137 19.4537 11.61 18.3525 11.8308C18.085 11.8846 17.8837 12.1046 17.8541 12.3758L17.67 14.0617C17.6296 14.4283 17.4154 14.7567 17.0954 14.9408C16.7762 15.125 16.3854 15.1471 16.0475 14.9983L14.4937 14.315C14.2471 14.2058 13.9554 14.2692 13.775 14.4721C13.0233 15.3175 12.4621 16.2846 12.105 17.3454C12.0183 17.6029 12.1083 17.8875 12.3279 18.0487L13.6916 19.0487C13.9891 19.2671 14.1666 19.6171 14.1666 19.9858C14.1666 20.3546 13.9891 20.7046 13.6925 20.9221L12.32 21.9283C12.1012 22.0892 12.0108 22.3721 12.0962 22.63C12.4496 23.6917 13.0083 24.66 13.7575 25.5087C13.9379 25.7125 14.2296 25.7771 14.4775 25.6671L16.0229 24.9875C16.3596 24.8392 16.7512 24.8604 17.0712 25.045C17.3904 25.2292 17.6046 25.5575 17.645 25.9237L17.8308 27.6204C17.8604 27.8904 18.0612 28.1104 18.3271 28.165C18.8746 28.2767 19.4371 28.3333 20 28.3333C20.5491 28.3333 21.1033 28.2779 21.6471 28.1687C21.9146 28.115 22.1158 27.895 22.1454 27.6237L22.33 25.9379C22.3704 25.5712 22.5846 25.2429 22.9046 25.0587C23.2237 24.875 23.615 24.8533 23.9525 25.0012L25.5062 25.6846C25.7537 25.7942 26.0446 25.7308 26.225 25.5275C26.9766 24.6821 27.5379 23.715 27.895 22.6542C27.9816 22.3967 27.8916 22.1121 27.6721 21.9508L26.3079 20.9508ZM20 22.9167C18.3891 22.9167 17.0833 21.6108 17.0833 20C17.0833 18.3892 18.3891 17.0833 20 17.0833C21.6108 17.0833 22.9166 18.3892 22.9166 20C22.9166 21.6108 21.6108 22.9167 20 22.9167Z"
      fill={props.fill ?? "#8E8E8E"}
    />
  </svg>
);
export default SvgSettingsCollapsedIcon;


