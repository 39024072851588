import * as React from "react";
const Trophy1 = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={19}
        fill="none"
        {...props}
    >
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M1.25 6C1.25 5.44772 1.69772 5 2.25 5H6.25C6.80229 5 7.25 5.44772 7.25 6V11C7.25 11.5523 6.80229 12 6.25 12C3.60124 12 1.25 9.7841 1.25 6ZM3.32355 7C3.56582 8.55496 4.37355 9.43208 5.25 9.79692V7H3.32355ZM17.25 6C17.25 5.44772 17.6977 5 18.25 5H22.25C22.8023 5 23.25 5.44772 23.25 6C23.25 9.7841 20.8988 12 18.25 12C17.6977 12 17.25 11.5523 17.25 11V6ZM19.25 7V9.79692C20.1265 9.43208 20.9342 8.55496 21.1765 7H19.25Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path opacity="0.5"
              d="M12.25 15.5C15.5635 15.5 18.25 12.735 18.25 9.3235V2.5H6.25V9.3235C6.25 12.735 8.9365 15.5 12.25 15.5Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12.25 14.9375C12.8023 14.9375 13.25 15.3852 13.25 15.9375V17.9375C13.25 18.4898 12.8023 18.9375 12.25 18.9375C11.6977 18.9375 11.25 18.4898 11.25 17.9375V15.9375C11.25 15.3852 11.6977 14.9375 12.25 14.9375Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path opacity="0.5" d="M7.75 21.5L9.595 18.5H14.771L16.75 21.5H7.75Z" fill={props.fill ?? "#FFFFFF"}/>
    </svg>
);
export default Trophy1;
