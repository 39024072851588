import * as React from "react";
import type { SVGProps } from "react";
const SvgSend = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      
      d="M3.042 2.5a.562.562 0 0 0-.504.765l2.063 5.326a.563.563 0 0 0 .434.351l5.603.91c.14.023.126.063.126.148 0 .085.014.125-.126.148l-5.603.91a.562.562 0 0 0-.434.351l-2.063 5.326a.563.563 0 0 0 .776.706l13.875-6.938a.564.564 0 0 0 0-1.006L3.314 2.559a.563.563 0 0 0-.272-.058Z"
    />
  </svg>
);
export default SvgSend;
