import * as React from "react";
import type { SVGProps } from "react";
const SvgDoubleTick = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M14.965 4.16a.834.834 0 0 0-.567.264L7.819 11.3 5.836 9.035a.833.833 0 1 0-1.255 1.097l2.082 2.379-.817.853L1.864 9.02a.833.833 0 1 0-1.228 1.127l4.583 5a.833.833 0 0 0 1.216.012l1.33-1.389 1.19 1.362a.832.832 0 0 0 1.23.027l9.167-9.583a.834.834 0 1 0-1.204-1.152L9.61 13.349l-.69-.788 6.681-6.985a.834.834 0 0 0-.637-1.417Z" />
  </svg>
);
export default SvgDoubleTick;
