import { useEffect, useState } from "react";
import { setDialog, toggleDialog } from "../../../store/appSlice";
import { useAppDispatch } from "../../../helpers/hooks";
import { AddressElement, Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import { useUpdateBillingAddressMutation } from "../../../services/PaymentApi";
import { toggleLoading } from "../../../store/appSlice";
import { t } from "i18next";
import PrimaryButton from "../../../components/PrimaryButton";
import { UserAddress } from "../../../services/UsertApi";
import { useRef } from "react";
import { clickOutside } from "../../../helpers/ClickOutside";

export interface IEditAddressPopupProps {
  userAddress?: UserAddress,
  onClose: () => void
  onAddressUpdated: () => void
}

function EditAddressPopupOpener(props: IEditAddressPopupProps) {
  const dispatch = useAppDispatch();
  useEffect(() => { showSubscriptionDialog(); }, [])

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? "")

  const showSubscriptionDialog = () => {
    dispatch(setDialog({
      actions: <></>,
      title: "",
      message: "",
      customTemplate: (
        <Elements stripe={stripePromise ?? Promise.resolve(null)} options={{
          mode: "subscription",
          currency: "usd",
          amount: 100,
        }}>
          <EditAddressPopup {...props}></EditAddressPopup>
        </Elements>
      ),
    }));
    dispatch(toggleDialog(true));
  }

  return (<></>)
}

function EditAddressPopup(props: IEditAddressPopupProps) {

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const [updateBillingAddress, { isLoading: updatingAddress, isSuccess: addressUpdated }] = useUpdateBillingAddressMutation();

  useEffect(() => { dispatch(toggleLoading(updatingAddress)) }, [updatingAddress]);

  useEffect(() => { if (addressUpdated) { props.onAddressUpdated() } }, [addressUpdated])

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      return clickOutside(ref.current, () => {
        dispatch(toggleDialog(false));
        props.onClose();
      });
    }
  }, [ref.current]);
  const updateAddress = async () => {
    if (!stripe || !elements) {
      dispatch(toggleLoading(false));
      return false;
    }
    var addressElement = elements.getElement('address');

    addressElement?.getValue()
      .then(function (result) {
        const newAddress = result.value.address
        if (result.complete) {
          updateBillingAddress({
            updateAddressRequest: {
              billing_address: {
                country: newAddress.country,
                city: newAddress.city,
                postal_code: newAddress.postal_code,
                line1: newAddress.line1,
                line2: newAddress.line2 === null ? undefined : newAddress.line2,
                state: newAddress.state,
                name: result.value.name,
              }
            }
          })
        }
      })
  };

  return (
    <>
      <div className="fixed lg:top-0 left-0 right-0 bottom-0 z-50 flex flex-col items-center max-lg:justify-end max-lg:mb-1 justify-center h-screen" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
        <div
          ref={ref}
          className="w-full mx-2 md:mx-0 md:w-2/5 flex flex-col justify-center bg-white p-5 rounded-[10px]"
        >
          <>
            <div className="h-10 items-center flex justify-between">
              <text className="text-black text-H6 mb-3">
                {t("edit-billing-address")}
              </text>
            </div>
            <hr className="bg-border -ml-5 -mr-5" />
            <div className="w-full mt-[20px] flex-col">
              <div className="w-full">
                <AddressElement
                  options={{
                    mode: "billing",
                    defaultValues: {
                      name: props.userAddress?.name,
                      address: {
                        country: props.userAddress?.country ?? "",
                        city: props.userAddress?.city,
                        line1: props.userAddress?.line1,
                        line2: props.userAddress?.line2,
                        postal_code: props.userAddress?.postal_code,
                        state: props.userAddress?.state,
                      },
                    },
                  }}
                ></AddressElement>
              </div>
            </div>
            <hr className="bg-border -ml-5 -mr-5 my-5" />
            <div className="flex-col lg:flex lg:flex-row lg:justify-end">
              <PrimaryButton
                className="h-12 w-full lg:ml-1 lg:w-[100px]"
                noFill
                title={t("cancel")}
                onClick={() => {
                  dispatch(toggleDialog(false));
                  props.onClose();
                }}
              />
              <PrimaryButton
                className="h-12 w-full lg:ml-1 lg:w-[100px]"
                isDisabled={false}
                title={t("save")}
                onClick={() => {
                  updateAddress();
                }}
              />
            </div>
          </>
        </div>
      </div>
    </>
  );
}
export default EditAddressPopupOpener;

