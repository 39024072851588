import * as React from "react";
const SvgArrowRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="#F55"
      d="M6.738 2.862a.664.664 0 0 0-.942 0 .667.667 0 0 0 0 .943l3.528 3.528L10 8l-.676.667-3.528 3.528a.667.667 0 1 0 .942.943l4.667-4.667a.666.666 0 0 0 0-.942L6.738 2.862Z"
    />
  </svg>
);
export default SvgArrowRight;
