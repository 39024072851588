import { PickerModel } from "../models/PickerModel";

export const ContentObjectives: PickerModel[] = [
    {
        title: "Brand Awareness",
        value: "0"
    },
    {
        title: "Sales/Conversions",
        value: "1"
    },
    {
        title: "Lead Generation",
        value: "2"
    },
    {
        title: "Drive Traffic",
        value: "3"
    },
    {
        title: "Retargeting",
        value: "4"
    },
    {
        title: "Engage with Audience",
        value: "5"
    },
    {
        title: "Product Launch",
        value: "6"
    },
    {
        title: "Event Promotion",
        value: "7"
    },
    {
        title: "Educate Audience",
        value: "8"
    },
    {
        title: "Increase Loyalty",
        value: "9"
    },
]