
import * as React from "react";
const SvgNotificationPostedSuccess = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={40} height={40} fill="#F55" opacity={0.1} rx={5} />
    <path
      fill="#F55"
      d="M28.055 17.916H11.39v-2.5a2.5 2.5 0 0 1 2.5-2.5h11.666a2.5 2.5 0 0 1 2.5 2.5v2.5Z"
      opacity={0.5}
    />
    <path
      fill="#F55"
      d="M11.389 26.25a2.503 2.503 0 0 0 2.5 2.5h11.666a2.502 2.502 0 0 0 2.5-2.5v-8.334H11.39v8.333Z"
      opacity={0.2}
    />
    <path
      fill="#F55"
      d="M15.556 16.25a.833.833 0 0 1-.834-.833v-3.334a.834.834 0 0 1 1.667 0v3.334a.834.834 0 0 1-.833.833Zm8.333 0a.833.833 0 0 1-.834-.833v-3.334a.833.833 0 0 1 1.667 0v3.334a.833.833 0 0 1-.833.833Z"
    />
    <path
      fill="#F55"
      d="M21.8 29.165a.997.997 0 0 1-.707-.293l-2.812-2.813a1 1 0 0 1 1.414-1.414l2.105 2.106 5.48-5.48a1 1 0 0 1 1.415 1.413l-6.188 6.188a.996.996 0 0 1-.706.293Z"
      opacity={0.99}
    />
  </svg>
);
export default SvgNotificationPostedSuccess;
