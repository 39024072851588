import {FunctionComponent, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../helpers/hooks";
import {FileProps} from "../../../../services/BrandApi";
import {useLazyGetCreativeInspirationQuery} from "../../../../services/InspirationApi";
import {useLazyThemesQuery} from "../../../../services/CreativeApi";
import {toggleLoading} from "../../../../store/appSlice";
import ChooseAnAspirationOrTheme from "../../../../pages/NewContentWizard/CreativeDetails/ChooseAnInspirationOrTheme";
import {Button} from "antd";
import {setNewContent} from "../../../../store/contentSlice";

interface ICreativeAddBriefProps {
  backToSelection?:() => void;
}


const ThemeInspirationSelector: FunctionComponent<ICreativeAddBriefProps> = (
  props: ICreativeAddBriefProps
) => {
  const newContent = useAppSelector((k) => k.content.newContent);
  const dispatch = useAppDispatch();
  const [uploadedImagesList, setUploadedImagesList] = useState<FileProps[]>([]);
  const [jointInspirations, setJointInspirations] = useState<FileProps[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [tempPicks, setTempPicks] = useState<FileProps>();
  const [jointThemesList, setJointThemesList] = useState<FileProps[]>([]);
  const [
    getInspirations,
    {
      error: errorInspirationsLoading,
      isFetching: inspirationsFetching,
      data: inspirationsList,
    },
  ] = useLazyGetCreativeInspirationQuery();

  const [
    getThemes,
    {
      error: errorThemesLoading,
      isFetching: themesFetching,
      data: themesList
    },
  ] = useLazyThemesQuery();

  useEffect(() => {
    if (newContent?.mediumOption?.galleryTpe === 'theme') {
      setSelectedTab(1)
    } else {
      setSelectedTab(0)
    }
  }, [newContent]);

  useEffect(() => {
    if (selectedTab === 1) {
      getThemes({
        page: currentPage,
        size: 20,
      }).then(response => response.data? setThemesList(response.data): null);
    } else {
      getInspirations({
        page: currentPage,
        size: 20,
      }).then(response => setInspirationList(response.data));
    }
  }, [selectedTab, uploadedImagesList]);

  const setInspirationList = (data) => {
    if (data) {
      const inspoFileList: FileProps[] = data?.map((inspo) => {
        const file: FileProps = {
          id: inspo.fileId,
          adId: inspo.adId,
          name: inspo.subCategory,
          uploaded: false,
        };
        return file;
      });
      setJointInspirations([...uploadedImagesList, ...inspoFileList])
    }
  }

  const setThemesList = (data) => {
    if (data) {
      const themeFileList: FileProps[] = data?.map((theme) => {
        const file: FileProps = {
          ...theme,
          uploaded: false,
        };
        return file;
      });
      setJointThemesList([...uploadedImagesList, ...themeFileList])
    }
  }

  useEffect(() => {
    console.log(tempPicks)
  }, [tempPicks]);

  useEffect(() => {
    dispatch(toggleLoading(inspirationsFetching || themesFetching));
  }, [inspirationsFetching, themesFetching]);
  return (
    <div
      className={`w-full justify-center items-center flex flex-row`}
    >
      <div>
        <div className="flex flex-col h-[64vh] w-full overflow-scroll items-center">
          <div className="flex-row border-1 border-border grid grid-cols-2 max-w-[375px] p-[4px] h-[40px] items-center rounded-[4px]">
            <div
                onClick={() => {
                  setUploadedImagesList([])
                  setSelectedTab(0)
                }}
              className="flex flex-row rounded-[2px] h-[30px] items-center justify-center cursor-pointer"
              style={{
                backgroundColor: selectedTab === 0 ? "#FFEEEE" : "",
                transition: "background-color 0.3s ease",
              }}
            >
              <p
                className={`${selectedTab === 0 ? "text-red" : "text-gray"
                  } text-BodySmall`}
              >
                Choose an Inspiration
              </p>
            </div>
            <div
              onClick={() => {
                setUploadedImagesList([])
                setSelectedTab(1)
              }}
              className="flex flex-row rounded-[2px] h-[30px] items-center justify-center cursor-pointer"
              style={{
                backgroundColor: selectedTab === 1 ? "#FFEEEE" : "",
                transition: "background-color 0.3s ease",
              }}
            >
              <p
                className={`${selectedTab === 1 ? "text-red" : "text-gray"
                  } text-BodySmall`}
              >
                Choose a Theme
              </p>
            </div>
          </div>
          <ChooseAnAspirationOrTheme
              type={selectedTab === 1 ? 'theme' : ''}
              className={'w-[375px] pl-4'}
              isGrid={false}
              isInSide={true}
              uploadButtonEnabled={!selectedTab}
              uploadedFiles={selectedTab === 1 ? jointThemesList : jointInspirations}
              title="Add up to 2 Logo Files (Ex. Light and Dark Logos)"
              description="Upload .jpg, .png files. Max 25mb."
              currentPage={currentPage}
              onRefresh={(demandedPage)=> {
                if (selectedTab === 1) {
                  if (jointThemesList.length <= (demandedPage)*20 + 1) {
                    getThemes({
                      page: currentPage + 1,
                      size: 20,
                    }).then(response => {
                      if (response?.data) {
                        const newList: FileProps[] = response.data
                        setJointThemesList(prevState => [...prevState, ...newList])
                        setCurrentPage(demandedPage)
                      }
                    })
                  }
                } else {
                  if (jointInspirations.length <= (demandedPage)*20 + 1) {
                    getInspirations({
                      page: currentPage + 1,
                      size: 20,
                    }).then(response => {
                      if (response?.data) {
                        const inspoFileList: FileProps[] = response.data?.map((inspo) => {
                          const file: FileProps = {
                            id: inspo.fileId,
                            adId: inspo.adId,
                            name: inspo.subCategory,
                            uploaded: false,
                          };
                          return file;
                        });
                        setJointInspirations(prevState => [...prevState, ...inspoFileList])
                        setCurrentPage(demandedPage)
                      }
                    })

                  }
                }
              }}
              fileChanged={(f: any) => {
                console.log("File Changed");
                if (f) {
                  const lastFile: FileProps = {
                    id: f.uploadedId,
                    name: f.uploadedId,
                    uploaded: true,
                  };
                  console.log("Last file is:", lastFile);
                  setUploadedImagesList((prevItems) => [lastFile, ...prevItems]);
                }
              }}
              selectionChanged={(pickedFile) => {
                console.log(pickedFile)
                if (pickedFile) {
                  setTempPicks(pickedFile)
                }
              }}
              selectionEnabled={true}
              selectedFileId={undefined}
          />
        </div>
        <div className={'flex justify-end p-4'}>
          <Button disabled={!tempPicks} type={'primary'} onClick={() => {
            if (tempPicks) {
              dispatch(
                  setNewContent({
                    ...newContent,
                    adId: selectedTab === 0 ? tempPicks.uploaded ? undefined: tempPicks.adId : undefined,
                    creative_theme:  selectedTab === 1 ? tempPicks.id : undefined,
                    creativeImageId: selectedTab === 0 && tempPicks.uploaded ? tempPicks.id: undefined,
                  })
              );
              if (props.backToSelection) {
                props.backToSelection();
              }
            }
          }}>Add Selected</Button>
        </div>

      </div>
    </div>
  );
};

export default ThemeInspirationSelector;
