import { useEffect, useState } from "react";
import Icon from "../Icon";
import { IChannelContentData } from "./ChannelContentSelector";
import SvgCheckboxSelectedIcon from "../../assets/IconComponents/CheckboxSelectedIcon";
import SvgCheckboxUnchecked from "../../assets/IconComponents/CheckboxUnchecked";

export interface IChannelContentSelectorItemProps {
    data: IChannelContentData
    selected: boolean
    itemSelected: (data: IChannelContentData, isSelected: boolean) => void
}

function ChannelContentSelectorItem(props: IChannelContentSelectorItemProps) {
    const [selected, setSelected] = useState<boolean>()

    useEffect(() => {
        if (props.selected !== undefined)
            setSelected(props.selected)
        else
            setSelected(false)
    }, [props.selected])

    // useEffect(() => {
    //     console.log(`${props.data.title} selected changed ${selected}`)
    // }, [selected])

    return (
        <div className={`h-[42px] mt-1 hover:bg-lightBg hover:border-1 hover:border-border cursor-pointer hover:rounded-[5px] flex items-center p-2 justify-between
        ${selected ? "bg-lightBg border-1 border-border rounded-[5px]" : ""}
        `}
            onClick={() => {
                setSelected(!selected)
                props.itemSelected(props.data, !selected)
            }}
        >
            <div className="flex flex-row items-center">
                <Icon width="15" height="15" viewBox="0 0 20 20" component={props.data.colorIcon} color="#999999" className="mr-1 w-[20px] " />
                <text className="text-BodyMedium text-black ml-2">{props.data.title}</text>
            </div>
            {selected &&
                <SvgCheckboxSelectedIcon />
            }
            {!selected &&
                <SvgCheckboxUnchecked />
            }
        </div>
    );
}

export default ChannelContentSelectorItem;