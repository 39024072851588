import Modal from 'react-modal';
import ToggleButton from '../../../components/ToggleButton';
import { useEffect, useState } from 'react';
import { Elements } from "@stripe/react-stripe-js";
import CreditCardForm from "./CreditCardForm";
import ExistingCardForm from "./ExistingCardForm";
import { loadStripe } from "@stripe/stripe-js";
import { SubscribeResponse} from "../../../services/PaymentApi";
import { Plan } from "../../../services/PlanApi";
import { useAppSelector } from "../../../helpers/hooks";
import Close from '../../../assets/IconComponents/Close'
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import { toggleLoading } from '../../../store/appSlice';
import { useDispatch } from 'react-redux';

export interface MobileModalProps {
    isOpen: boolean,
    selectedPlan: Plan,
    skipTrial: boolean
    setSkipTrialFlag
    paymentTimeframe: "monthly" | "yearly"
    setIsOpen
    onSubscriptionCompleted: (trialEndDate: Date) => void;
}

const SubscriptionModelMobilePopup = (props: MobileModalProps) => {

    const [selectedPlanType, setSelectedPlanType] = useState<"yearly" | "monthly">(props.paymentTimeframe);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? "");
    const [updateCardMode, setUpdateCardMode] = useState<boolean>(false); 
    const [price, setPrice] = useState(0);
    const [subscriptionDetails, setSubscriptionDetails] = useState<SubscribeResponse>();
    const [trialEndDate, setTrialEndDate] = useState<Date>();
    const [promotionCode, setPromotionCode] = useState<string>("");
    const [referral, setReferral] = useState<string>("")
    const userPaymentMethod = useAppSelector(state => state.userSettings.payment_method);
    const [isModalOpen, setIsModalOpen] = useState(props.isOpen)
    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        if(props.selectedPlan.monthlyPrice && props.selectedPlan.yearlyPrice)
            if(props.skipTrial)
                setPrice(selectedPlanType === "monthly" ? props.selectedPlan.monthlyPrice * 0.8 : props.selectedPlan.yearlyPrice * 0.8)
            else
                setPrice(selectedPlanType === "monthly" ? props.selectedPlan.monthlyPrice  : props.selectedPlan.yearlyPrice )
    }, [selectedPlanType])

    useEffect(() => {
        setTrialEndDate(moment().add(7, "days").toDate());
    }, []);

    return(
        <Modal fullscreen={true} isOpen={isModalOpen} style={{
            overlay: {
                zIndex: 1000
            },
            content: {
              position: "static",  
              top: '40px',
              left: '40px',
              right: '40px',
              bottom: '40px',
              border: '1px solid #ccc',
              background: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '20px',
              height: '100vh',
              zIndex: '1000'
            }
          }}>
                <div className='flex flex-col gap-[20px]'>
                    <div className='flex flex-col justify-center items-start gap-[20px]'>
                        <div className='flex justify-between w-full'>
                            <div className='h5 text-black ml-1'>
                                {!props.skipTrial ? `Start 7-day Free Trial` : `Start Your Plan`}
                            </div>
                            <Close className="w-4 h-4 relative" color="#999999" fill="#999999" onClick={() =>  {
                                props.setSkipTrialFlag(false)
                                props.setIsOpen(false)
                                }}/>
                        </div>
                        <div className='flex justify-center items-center gap-[10px] ml-1'>
                            <div className='h6 text-black'>
                                Monthly
                            </div>
                            <ToggleButton
                                        isChecked={selectedPlanType === "yearly"}
                                        handleCheckBoxChange={function (yearly: any) {
                                            yearly
                                                ? setSelectedPlanType("yearly")
                                                : setSelectedPlanType("monthly");
                                        }}
                                        checkedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                                        checkedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition translate-x-full"
                                        uncheckedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                                        uncheckedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition transition"
                            />
                            <div className='h6 text-black'>
                                Yearly
                            </div>
                            <div className='text-rose-400 text-[10px] font-semibold leading-normal'>
                                2 MONTHS FREE
                            </div>
                        </div>
                        <div className='h-[1px]' style={{background: "--Border, #EAEBE6"}}></div>
                        <div className='flex p-[16px] flex-col justify-between items-start flex-1 rounded-lg border-solid border-1 border-grey-500 self-stretch'>
                            <div className='flex flex-col gap-[20px] self-stretch'>
                                <div className='flex flex-col items-start gap-[10px] '>
                                    <div className='h7 text-black'>{`${props.selectedPlan.name} Plan`}</div>
                                    <div className='flex gap-[10px] self-stretch'>
                                        <div className='flex-1 body-small' style={{color: "#8E8E8E"}}>
                                            Monthly Credits
                                        </div>
                                        <div className='flex-1 body-small text-right' style={{color: "#8E8E8E"}}>
                                            {props.selectedPlan.credits ?? "Unlimited"}
                                        </div>
                                    </div>
                                    <div className='flex gap-[10px] self-stretch'>
                                        <div className='flex-1 body-small' style={{color: "#8E8E8E"}}>
                                            Number of Brands
                                        </div>
                                        <div className='flex-1  body-small text-right' style={{color: "#8E8E8E"}}>
                                            {props.selectedPlan.numberOfBrands ?? "Unlimited"}
                                        </div>

                                    </div>
                                </div>
                                <div className='h8 text-black'>
                                    {`${selectedPlanType === "monthly" ? `Monthly` : `Yearly Total`} on ${props.skipTrial ? moment().format("D MMM, YYYY") : moment(trialEndDate).format("D MMM, YYYY")}`}
                                </div>
                                <div className='h8 text-black'>{`$${selectedPlanType === "monthly" ? price : price * 12}`}</div>
                                
                               {!props.skipTrial && 
                               <div className='flex gap-[10px] flex-col  self-stretch'>
                                    <div className='flex self-stretch justify-between'>
                                        <div className='h8 text-red'>Total Today (7 days free)</div>
                                        <div className='h8 text-red'>$0</div>
                                    </div>

                                    <div className='flex-1 body-small' style={{color: "#8E8E8E"}}>
                                        {`You won’t be charged for your free trial. On ${moment(trialEndDate).format("D MMM, YYYY")
                                        }, your monthly subscription will start. Cancel anytime before then.`}</div>
                               </div>
                               }
                            </div>
                        </div>
                    </div>
                    {(!userPaymentMethod || updateCardMode) &&
                        (
                            <Elements stripe={stripePromise ?? Promise.resolve(null)} options={{ mode: "subscription", currency: "usd", amount: 100 }}>
                                <CreditCardForm
                                    mode={"subscription"}
                                    plan={props.selectedPlan}
                                    selectedPlanType={selectedPlanType}
                                    onSubscriptionCreated={(subscription) => {
                                        dispatch(toggleLoading(true))
                                        setIsModalOpen(false);
                                        navigate('/app/homepage')
                                        setSubscriptionDetails(subscription);
                                        props.onSubscriptionCompleted(trialEndDate ?? new Date());
                                        dispatch(toggleLoading(false))
                                    }}
                                    onPlanUpdated={(effectiveAt?: string) => {}}
                                    skipTrial={props.skipTrial}
                                    applyDiscount={false}
                                    promotionCode={promotionCode}
                                    referral={referral}
                                />
                            </Elements>
                        )}
                    {(userPaymentMethod && !updateCardMode) &&
                        (
                            <ExistingCardForm
                                plan={props.selectedPlan}
                                selectedPlanType={selectedPlanType}
                                paymentMethod={userPaymentMethod}
                                onPlanUpdated={(effectiveAt?: string) => {}}
                                onUpdatePaymentMethod={() => setUpdateCardMode(true)}
                                promotionCode={promotionCode}
                                onSubscriptionCreated={function (subscription?: SubscribeResponse | undefined): void {
                                    dispatch(toggleLoading(true))
                                    setIsModalOpen(false);
                                    navigate('/app/homepage')
                                    setSubscriptionDetails(subscription);
                                    props.onSubscriptionCompleted(trialEndDate ?? new Date());
                                    dispatch(toggleLoading(false))
                                
                                }}
                                mode={"subscription"}
                                skipTrial={props.skipTrial}
                                applyDiscount={false}
                            />
                        )}
                </div>

        </Modal>
    )
}

export default SubscriptionModelMobilePopup;