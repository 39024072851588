import * as React from "react";
import type { SVGProps } from "react";
const SvgBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#Back_svg__a)">
      <path
        
        d="M8.578 16.422a.83.83 0 0 0 1.178 0 .833.833 0 0 0 0-1.178l-4.411-4.41h11.322a.834.834 0 0 0 0-1.667H5.345l4.41-4.411a.833.833 0 1 0-1.177-1.179L2.744 9.412a.833.833 0 0 0 0 1.178l5.834 5.833Z"
      />
    </g>
    <defs>
      <clipPath id="Back_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBack;
