import * as React from "react";
import type { SVGProps } from "react";
const SvgNewsLetterColor = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icons=NewsLetter">
    <path  id="Vector" d="M10.0002 2.08337C7.90053 2.08337 5.8869 2.91745 4.40223 4.40211C2.91757 5.88678 2.0835 7.90041 2.0835 10C2.0835 12.0997 2.91757 14.1133 4.40223 15.598C5.8869 17.0826 7.90053 17.9167 10.0002 17.9167C12.0998 17.9167 14.1134 17.0826 15.5981 15.598C17.0828 14.1133 17.9168 12.0997 17.9168 10C17.9168 7.90041 17.0828 5.88678 15.5981 4.40211C14.1134 2.91745 12.0998 2.08337 10.0002 2.08337Z" fill="#00A6CB"/>
<path  id="Vector" d="M6.41342 13.5C6.17769 13.5 5.97816 13.4183 5.81483 13.255C5.65149 13.0917 5.56982 12.8921 5.56982 12.6564V7.3436C5.56982 7.10787 5.65149 6.90833 5.81483 6.745C5.97816 6.58167 6.17769 6.5 6.41342 6.5H13.5929C13.8286 6.5 14.0282 6.58167 14.1915 6.745C14.3548 6.90833 14.4365 7.10787 14.4365 7.3436V12.6564C14.4365 12.8921 14.3548 13.0917 14.1915 13.255C14.0282 13.4183 13.8286 13.5 13.5929 13.5H6.41342ZM10.0032 10.1831C10.042 10.1831 10.0806 10.1772 10.1189 10.1656C10.1572 10.1539 10.1943 10.1379 10.2302 10.1176L13.5678 7.98074C13.621 7.94784 13.6587 7.90521 13.6809 7.85286C13.703 7.80051 13.7096 7.74532 13.7006 7.68729C13.6946 7.57959 13.642 7.49958 13.5427 7.44723C13.4433 7.39488 13.3431 7.39951 13.242 7.46114L10.0032 9.53333L6.7643 7.46114C6.66319 7.39951 6.56372 7.39458 6.46591 7.44633C6.36809 7.49808 6.31469 7.5769 6.30571 7.68279C6.29674 7.74562 6.30332 7.80395 6.32545 7.85779C6.34759 7.91164 6.38528 7.95262 6.43853 7.98074L9.77611 10.1176C9.81201 10.1379 9.8491 10.1539 9.88739 10.1656C9.92568 10.1772 9.96427 10.1831 10.0032 10.1831Z" fill="white"/>

    </g>
  </svg>

);
export default SvgNewsLetterColor;
