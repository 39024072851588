import * as React from "react";
import type { SVGProps } from "react";
const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      d="M3.333 1.167c-.92 0-1.666.745-1.666 1.666v10.834a.833.833 0 0 0 1.666 0V2.833h10.834a.833.833 0 1 0 0-1.666H3.333ZM6.667 4.5C5.746 4.5 5 5.246 5 6.167v10c0 .92.746 1.666 1.667 1.666h10c.92 0 1.666-.745 1.666-1.666v-10c0-.921-.745-1.667-1.666-1.667h-10Zm0 1.667h10v10h-10v-10Z"
    />
  </svg>
);
export default SvgCopy;
