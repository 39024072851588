type OptionType = {
    value: string;
    label: string;
  };
  

export const TechnicalDocumentTypes: OptionType[]  = [
  {
    value: "marketing",
    label: "Marketing",
  },
  {
    value: "sales",
    label: "Sales",
  },
  {
    value: "regulation",
    label: "Regulation",
  },
  {
    value: "product",
    label: "Product",
  },
  {
    value: "q&a",
    label: "Q&A",
  },
  {
    value: "finance",
    label: "Finance",
  },
  {
    value: "humanResources",
    label: "Human Resources",
  },
  {
    value: "researchAndDevelopment",
    label: "Research and Development",
  },
  {
    value: "customerInsights",
    label: "Customer Insights",
  },
  {
    value: "internalCommunications",
    label: "Internal Communications",
  },
  {
    value: "technical",
    label: "Technical",
  },
  {
    value: "strategy",
    label: "Strategy",
  },
  {
    value: "contentSamples",
    label: "Content Samples",
  },
  {
    value: "design",
    label: "Design",
  },
];
