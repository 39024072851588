import * as React from "react";
import type { SVGProps } from "react";
const SvgFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M4.402 4.402a7.917 7.917 0 1 1 11.196 11.196A7.917 7.917 0 0 1 4.402 4.402Zm8.718 7.696h-2.048v5.738c-.352.047-.707.08-1.072.08-.403 0-.796-.039-1.184-.097v-5.72H6.857v-2.082h1.959V8.632c0-2.292 1.117-3.298 3.022-3.298.897 0 1.379.065 1.612.096l.012.002v1.816h-1.3c-.808 0-1.09.767-1.09 1.632v1.137h2.37l-.322 2.081Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFacebook;
