import { PickerModel } from "../models/PickerModel";

export const EducationLevels: PickerModel[] = [
  {
    key: "all",
    title: "All",
    value: "0",
  },
  {
    key: "no-formal-education",
    title: "No Formal Education",
    value: "1",
  },
  {
    key: "primary-education",
    title: "Primary Education",
    value: "2",
  },
  {
    key: "secondary-education",
    title: "Secondary Education",
    value: "3",
  },
  {
    key: "tertiary-education",
    title: "Tertiary Education",
    value: "4",
  },
  {
    key: "bachelors-degree",
    title: "Bachelor's Degree",
    value: "5",
  },
  {
    key: "masters-degree",
    title: "Master's Degree",
    value: "6",
  },
  {
    key: "doctorate-or-higher",
    title: "Doctorate or Higher",
    value: "7",
  },
];
