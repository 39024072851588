import { SVGProps } from "react";
import SvgCheckboxSelectedIcon from "../assets/IconComponents/CheckboxSelectedIcon";
import SvgCheckIcon from "../assets/IconComponents/CheckIcon";
import { IGenericSelectorData } from "./GenericSelector/GenericSelector";
import SvgCheckboxUnchecked from "../assets/IconComponents/CheckboxUnchecked";
export interface IGenericSelectorItemProps {
  data: IGenericSelectorData;
  selected: boolean;
  itemSelected: (data: IGenericSelectorData) => void;
}

function GenerateSimilarFiltersItem(props: IGenericSelectorItemProps) {
  const { selected = false } = props;

  return (
    <div
      className={`h-[30px] mt-1 hover:bg-lightBg hover:border-1 hover:border-border cursor-pointer hover:rounded-xl flex items-center p-2
        ${selected ? "bg-lightBg border-1 border-border rounded-xl" : ""}
        `}
      onClick={() => {
        props.itemSelected(props.data);
      }}
    >
      {selected && <SvgCheckboxSelectedIcon />}
      {!selected && <SvgCheckboxUnchecked />}
      <div className="flex flex-row items-center">
        <text className="text-BodyMedium text-black ml-4 text-left">
          {props.data.title}
        </text>
      </div>
    </div>
  );
}

export default GenerateSimilarFiltersItem;
