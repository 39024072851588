import { dataURLToBlob } from "blob-util";
import { t } from "i18next";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import "../../styles/MultiRange.css";

import { useLocation, useNavigate } from "react-router-dom";

import MultiRangeSlider from "multi-range-slider-react";
import Icon from "../Icon";
import SvgQuestionMark from "../../assets/IconComponents/QuestionMark";
import { Slider } from "antd";

interface IMultiRangeSliderWrapperProps {
    disabled: boolean
    title?: string;
    labels?: string[];
    minValue?: number;
    maxValue?: number;
    smallerScaled?: boolean;
    onValuesChanged?: (e: number[]) => void;
}

const MultiRangeSliderWrapper: FunctionComponent<
    IMultiRangeSliderWrapperProps
> = (props) => {
    const [minValue, set_minValue] = useState(props.minValue ?? 18);
    const [maxValue, set_maxValue] = useState(props.maxValue ?? 65);

    useEffect(() => {
        if (props.onValuesChanged) {
            props.onValuesChanged([minValue, maxValue]);
        }
    }, [minValue, maxValue]);

    useEffect(() => {
        if (props.minValue) {
            set_minValue(props.minValue < 18 ? 18 : props.minValue)
        } else {
            set_minValue(18)
        }
        if (props.maxValue) {
            set_maxValue(props.maxValue > 65 ? 65 : props.maxValue)
        } else {
            set_maxValue(65)
        }
    }, [props]);




    return (
        <div className="flex flex-row w-full justify-between items-center justify-center ">
            {props.title &&
                <div className="flex flex-row items-center">
                    <div className="text-H8 text-black">{props.title}</div>
                    <div>
                        <Icon
                            width="15"
                            height="15"
                            component={SvgQuestionMark}
                            viewBox="0 0 20 20"
                            className="ml-1"
                        />
                    </div>
                </div>
            }
            <div className={`flex flex-row ${!props.title ? 'w-full' : 'w-3/4 mr-5'} items-center justify-between`}>
                <span className="text-BodySmall text-black whitespace-nowrap w-14 text-left">
                    {minValue}
                </span>
                <div className={`${props.smallerScaled ? "w-2/3" : "w-3/4"} ${!props.title ? 'w-full' : ''}`}>
                    <Slider disabled={props.disabled} range min={18} max={65} value={[minValue, maxValue]} defaultValue={[20, 50]} onChange={(e) => {
                        if (e[0] <= 25) {
                            if (e[0] >= e[1]) {
                                set_minValue(e[1] - 1);
                            } else {
                                set_minValue(e[0]);
                            }
                        } else {
                            set_minValue(25);
                        }
                        if (e[1] <= e[0]) {
                            set_maxValue(e[0] + 1);
                        } else {
                            set_maxValue(e[1]);
                        }

                    }} styles={{
                        handle: {
                            border: '12px solid #f03d30',
                            borderRadius: '50%',
                            top: '0'
                        }
                    }} />
                </div>
                <text className="text-BodySmall text-black whitespace-nowrap w-10 text-right">
                    {maxValue}{maxValue === 65 ? '+' : ''}
                </text>
            </div>
        </div>
    );
};

export default MultiRangeSliderWrapper;
