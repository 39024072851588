import { combineReducers, configureStore } from '@reduxjs/toolkit'
import appSlice from './appSlice'
import contentSlice from './contentSlice'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import persistedSlice from './persistedSlice'
import { AuthenticationApi } from '../services/AuthenticationApi.old/AuthenticationApi'
import { ContentApi } from '../services/ContentApi.old/ContentApi'
import { TextApi } from '../services/TextApi.old/TextApi'
import { ImageApi } from '../services/ImageApi.old/ImageApi'
import { coreApi } from '../services/Core/BaseApi'
import brandSlice from './brandSlice'
import userSettingsSlice from './userSettingsSlice'
import plannerSlice from "./plannerSlice";
import activitySlice from "./activitySlice";

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ["persisted"], //, "content"
  debug: true
};

const persistedReducer = persistReducer(persistConfig,
  combineReducers({
    app: appSlice,
    content: contentSlice,
    persisted: persistedSlice,
    brand: brandSlice,
    userSettings: userSettingsSlice,
    planner: plannerSlice,
    activity: activitySlice,
    [AuthenticationApi.reducerPath]: AuthenticationApi.reducer,
    [ContentApi.reducerPath]: ContentApi.reducer,
    [TextApi.reducerPath]: TextApi.reducer,
    [ImageApi.reducerPath]: ImageApi.reducer,

    [coreApi.reducerPath]: coreApi.reducer,
  }));

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      AuthenticationApi.middleware,
      ContentApi.middleware,
      TextApi.middleware,
      ImageApi.middleware,
      coreApi.middleware
    ]),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

const persistor = persistStore(store);
export { store, persistor };
