import * as React from "react";
const SvgNotificationAwaitingConfirmation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={40} height={40} fill="#F55" opacity={0.1} rx={5} />
    <path
      fill="#F55"
      d="M28.055 17.916H11.39v-2.5a2.5 2.5 0 0 1 2.5-2.5h11.666a2.5 2.5 0 0 1 2.5 2.5v2.5Z"
      opacity={0.5}
    />
    <path
      fill="#F55"
      d="M11.389 26.25a2.503 2.503 0 0 0 2.5 2.5h11.666a2.502 2.502 0 0 0 2.5-2.5v-8.334H11.39v8.333Z"
      opacity={0.2}
    />
    <path
      fill="#F55"
      d="M15.556 16.25a.833.833 0 0 1-.834-.833v-3.334a.834.834 0 0 1 1.667 0v3.334a.834.834 0 0 1-.833.833Zm8.333 0a.833.833 0 0 1-.834-.833v-3.334a.833.833 0 0 1 1.667 0v3.334a.833.833 0 0 1-.833.833Z"
    />
    <path
      fill="#F55"
      stroke="#F55"
      strokeWidth={0.5}
      d="M25.222 20a.6.6 0 0 0-.6.6v1.2a.6.6 0 0 0 1.2 0v-1.2a.6.6 0 0 0-.6-.6Zm0 9.6a.6.6 0 0 0-.6.6v1.2a.6.6 0 0 0 1.2 0v-1.2a.6.6 0 0 0-.6-.6Zm5.4-4.2h-1.2a.6.6 0 1 0 0 1.2h1.2a.6.6 0 1 0 0-1.2Zm-9 .6a.6.6 0 0 0-.6-.6h-1.2a.6.6 0 1 0 0 1.2h1.2a.6.6 0 0 0 .6-.6Zm6.718-1.8a.6.6 0 0 0 .82.219l1.038-.6a.6.6 0 1 0-.6-1.039l-1.038.6a.6.6 0 0 0-.22.82Zm-6.235 3.6a.6.6 0 0 0-.82-.22l-1.04.6a.6.6 0 1 0 .6 1.039l1.04-.6a.6.6 0 0 0 .22-.819Zm4.917-4.918a.598.598 0 0 0 .82-.22l.6-1.038a.6.6 0 1 0-1.04-.6l-.6 1.038a.6.6 0 0 0 .22.82Zm-6.776.937 1.038.6a.594.594 0 0 0 .666-.043.6.6 0 0 0-.066-.996l-1.038-.6a.6.6 0 1 0-.6 1.039Zm3.176 5.298a.6.6 0 0 0-.82.22l-.6 1.04a.6.6 0 0 0 1.04.6l.6-1.04a.6.6 0 0 0-.22-.82Zm6.777-.937-1.039-.6a.6.6 0 1 0-.6 1.039l1.039.6a.594.594 0 0 0 .665-.043.6.6 0 0 0-.065-.996Zm-2.357 1.157a.6.6 0 1 0-1.04.6l.6 1.04a.599.599 0 1 0 1.04-.6l-.6-1.04Z"
    />
  </svg>
);
export default SvgNotificationAwaitingConfirmation;
