import { t } from "i18next";
import { FunctionComponent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Scroll from "react-scroll";
import { v4 as uuidv4 } from "uuid";
import BottomUpdateBrief from "../../components/BottomUpdateBrief";
import ConfirmationPopupOpener from "../../components/ConfirmationPopup/ConfirmationPopup";
import PrimaryButton from "../../components/PrimaryButton";
import UpdateBrief from "../../components/UpdateBrief";
import WarningMessage from "../../components/WarningMessage";
import StompTopicListener from "../../components/WebsocketTopicListener";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import {
    Revision,
    useDeleteByContentIdMutation,
    useDeleteSharedMutation,
    useLazyFindByContentId1Query,
    useUpdateBrandMutation,
} from "../../services/ContentApi";
import { instanceOfApiErrorWithCode } from "../../services/Core/BaseApi";
import { PaymentErrorCode } from "../../services/Error/PaymentErrorCode";
import { useLazyFindByContentIdQuery as organizationAIModels } from "../../services/OrganizationApi";
import {
    useApplyMutation,
    useDeleteRevisionByIdMutation,
    useDeleteRevisionsByGroupIdMutation,
    useLazyFindRevisionsByContentIdQuery,
} from "../../services/RevisionApi";
import { setActivePage, setCollapsedModeEnabled, setDialog, setMenuCollapsed, toggleDialog, toggleLoading, updateMenu } from "../../store/appSlice";
import { setOrganizationAIModels } from "../../store/brandSlice";
import { newContent, setContentLoading } from "../../store/contentSlice";
import { setActiveAIModels } from "../../store/persistedSlice";
import PlanEditPopupOpener from "../PlanPage/PlanEditPopup";
import ContentGroup from "./ContentGroup/ContentGroup";
import LoopDetailsHeader from "./LoopDetailsHeader/LoopDetailsHeader";
import { useApply1Mutation, useApplyProductUpdatesMutation } from "../../services/CreativeApi";
import { Button } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import SvgRefreshIcon from "../../assets/IconComponents/RefreshIcon";
import { useLazyFindByContentId2Query } from "../../services/ConfigurationApi";
import { LoopDetailChannel } from "./LoopDetailsHeader/Channel/LoopDetailChannels";

interface LoopDetailsProps {
    newLoopCreated?: boolean
    isInSide?: boolean
    contentCreationDataID?: string
    moveContent?: (param: any) => void;
    isCreative?: boolean;
    onCreditError?: () => void;
}
interface AiModelData {
    name: string;
    tooltip: string;
    bg_color: string;
    text_color: string;
    icon: string;
    disabled_icon: string;
}

const LoopDetails: FunctionComponent<LoopDetailsProps> = (props) => {
    let params = useParams();
    const navigate = useNavigate();
    let dispatch = useAppDispatch();
    const { state } = useLocation();
    const userSettings = useAppSelector(state => state.userSettings);
    const organizationAIModel = useAppSelector(
        (k) => k.brand.organizationAIModels
    );
    const [showPlanUpdateSuccessPopup, setShowPlanUpdateSuccessPopup] = useState(false);
    const [showUpgradePlanDialog, setShowUpgradePlanDialog] = useState(false);
    const [isUpgradePlanPopupOpen, setIsUpgradePlanPopupOpen] = useState(false);
    const [downloadedCount, setDownloadedCount] = useState(0);
    const [showImageContent, setShowImageContent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedImageItems, setSelectedImageItems] = useState<any[]>([])
    const [aiModelsLocal, setAiModelsLocal] = useState<LoopDetailChannel[]>([]);
    const [
        getContent,
        { isLoading: contentFetching, data: content },
    ] = useLazyFindByContentId1Query();
    const [
        getTextContentDetails,
        { isLoading: textContentDetailsFetching, data: textContentDetail },
    ] = useLazyFindRevisionsByContentIdQuery();
    const [getAiModels, { data: aiModels, isLoading: aiModelsLoading }] =
        useLazyFindByContentId2Query();
    // const [getImageContentDetails, { isLoading: imageContentDetailsFetching, data: imageContentDetail }] = useLazyFindImageByContentIdQuery()
    const [
        createRevision,
        {
            data: createRevisionData,
            isSuccess: newRevisionCreated,
            isLoading: revisionCreating,
            error: revisionCreateError,
        },
    ] = useApplyMutation();
    const [
        createCreativeRevision,
        {
            data: createCreativeRevisionData,
            isSuccess: newCreativeRevisionCreated,
            isLoading: creativeRevisionCreating,
            error: creativeRevisionCreateError,
        },
    ] = useApply1Mutation();
    const [
        updateProduct,
        {
            data: updateProductData,
            isSuccess: productUpdatedSuccessfully,
            isLoading: productUpdating,
            error: productUpdatingError,
        },
    ] = useApplyProductUpdatesMutation();
    const [
        refreshRevision,
        {
            isSuccess: refreshRevisionCreated,
            isLoading: revisionRefreshing,
            error: revisionRefreshError,
        },
    ] = useApplyMutation();
    //const [createRevisionData, setCreateRevisionData] = useState()
    //const [createCreativeRevisionData, setCreateCreativeRevisionData] = useState()
    const [error, setError] = useState("");
    const [gruppedRevisions, setGrouppedRevisions] = useState<Revision[][]>([]);
    const [recentCreatedWsRevisions, setRecentCreatedWsRevisions] = useState<Revision[]>([]);
    const [
        deleteRevisionGroup,
        { isSuccess: revisionGroupDeleted, isLoading: revisionGroupDeleting },
    ] = useDeleteRevisionsByGroupIdMutation();
    const [
        deleteRevisionItem,
        { isSuccess: revisionItemDeleted, isLoading: revisionItemDeleting },
    ] = useDeleteRevisionByIdMutation();
    const [sortingRevisions, setSortingRevisions] = useState(false);
    const [shouldCreateRevisionAfterUpdate, setShouldCreateRevisionAfterUpdate] = useState(true);
    const [getOrganizationAIModels, { data: organizationData }] = organizationAIModels();
    const activeAIModels = useAppSelector((k) => k.persisted.activeAIModels);
    const [
        updateBrand,
        { isSuccess: updateBrandSuccess, reset: resetUpdateBrand },
    ] = useUpdateBrandMutation();
    const [
        deleteLoop,
        { isSuccess: loopDeletionSuccess, isLoading: loopDeleting },
    ] = useDeleteByContentIdMutation();

    const [deleteShared, { isSuccess: sharedDeleted, isLoading: sharedDeleting }] = useDeleteSharedMutation();
    const [latestRequestId, setLatestRequestId] = useState();
    const [effectiveAt, setEffectiveAt] = useState("");
    const [newLoopCreated, setNewLoopCreated] = useState(props.newLoopCreated)
    const newContent = useAppSelector(k => k.content.newContent)

    useEffect(() => {
        console.log("params", params)
        if (props.contentCreationDataID) {
            params = { contentId: props.contentCreationDataID }
        }
        if (params?.contentId) getContentObj();
    }, [params.contentId]);

    useEffect(() => {
        dispatch(toggleLoading(aiModelsLoading));
    }, [aiModelsLoading]);


    const createSvgIconComponent = (svgBase64) => {
        // Decode the base64 string to SVG markup
        const svgMarkup = atob(svgBase64);
        return (props) => (
            <div dangerouslySetInnerHTML={{ __html: svgMarkup }} {...props} />
        );
    };

    useEffect(() => {
        if (error !== "") {
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    }, [error]);

    useEffect(() => {
        getAiModels({ type: "AIMODELTEXT" });
    }, []);

    useEffect(() => {
        if (aiModels) {
            const transformedData: LoopDetailChannel[] = aiModels
                .map((modelOriginal) => {
                    if (!modelOriginal.data) {
                        return null;
                    }
                    const model: AiModelData = modelOriginal.data as AiModelData;
                    if (model && organizationAIModel.includes(model.name)) {
                        return {
                            name: model.name,
                            tooltip: model.tooltip,
                            bgColor: model.bg_color,
                            textColor: model.text_color,
                            icon: createSvgIconComponent(model.icon),
                            disabledIcon: createSvgIconComponent(model.disabled_icon),
                        };
                    } else {
                        return null;
                    }
                })
                .filter(Boolean) as LoopDetailChannel[]; // This will remove any null entries resulting from missing data.
            setAiModelsLocal(transformedData);
        }
    }, [aiModels, organizationAIModel]);

    useEffect(() => {
        if (
            downloadedCount > 0 && gruppedRevisions.flat().length >= downloadedCount
        ) {
            setTimeout(() => {
                setShowImageContent(true);
            }, 3000);
        }
    }, [downloadedCount, gruppedRevisions]);

    useEffect(() => {
        console.log(createCreativeRevisionData, createRevisionData)
        if (createRevisionData) setRecentCreatedWsRevisions(createRevisionData)
        if (createCreativeRevisionData) setRecentCreatedWsRevisions(createCreativeRevisionData)
    }, [createCreativeRevisionData, createRevisionData]);


    useEffect(() => {
        getOrganizationAIModels();
        dispatch(setCollapsedModeEnabled(true));
        dispatch(setMenuCollapsed(false));
        return () => {
            if (props.isInSide != true) {
                dispatch(setMenuCollapsed(true));
                dispatch(setCollapsedModeEnabled(false));
            }
        };
    }, []);

    useEffect(() => {
        dispatch(toggleLoading(false));
        if (revisionCreateError || creativeRevisionCreateError) {
            setError("There was an unexpected error. Please retry later.");
        }

        if (revisionRefreshError) {
            setError("There was an unexpected error. Please retry later.");
        }

        if (productUpdatingError) {
            setError("There was an unexpected error. Please retry later.");
        }


    }, [revisionCreateError, revisionRefreshError, creativeRevisionCreateError, productUpdatingError]);

    useEffect(() => {
        if (organizationData) {
            const models = organizationData.ai_models?.split(",") ?? [];
            dispatch(setOrganizationAIModels(models));
        }
    }, [organizationData]);

    useEffect(() => {
        if (state?.newLoopCreated === true || newLoopCreated) {
            // Loop created, create content with id
            const requestId = uuidv4();
            setLatestRequestId(requestId);

            if (state?.isCreative || props.isCreative) {
                createCreativeRevision({
                    revisionRequest: {
                        request_id: getRequestId(),
                        content_id: params.contentId ?? "",
                        ai_model_names: activeAIModels.join(","),
                        number_of_revisions: (newContent?.sliderCount ?? undefined)
                    },
                });

            } else {
                createRevision({
                    revisionRequest: {
                        request_id: getRequestId(),
                        content_id: params.contentId ?? "",
                        ai_model_names: activeAIModels.join(","),
                    },
                });
            }
            const models = organizationData?.ai_models?.split(",") ?? [];
            dispatch(setActiveAIModels(models));

            // reset state
            if (!props.isInSide) {
                navigate(".", { state: { newLoopCreated: false } });
            }
            setNewLoopCreated(false);
        }
    }, [state?.newLoopCreated, newLoopCreated]);

    useEffect(() => {
        dispatch(
            toggleLoading(
                textContentDetailsFetching ||
                revisionGroupDeleting ||
                contentFetching ||
                revisionItemDeleting ||
                loopDeleting ||
                sharedDeleting ||
                revisionRefreshing ||
                productUpdating
            )
        );
    }, [
        textContentDetailsFetching,
        revisionGroupDeleting,
        contentFetching,
        revisionItemDeleting,
        loopDeleting,
        sharedDeleting,
        revisionRefreshing,
        productUpdating
    ]);

    useEffect(() => {
        if (revisionCreateError && instanceOfApiErrorWithCode(revisionCreateError)) {
            const errorData = revisionCreateError.data;
            if (errorData.errorCode === PaymentErrorCode.CreditLimitExceeded) {
                if (props.onCreditError !== undefined) {
                    props.onCreditError()
                } else {
                    setShowUpgradePlanDialog(true);
                    setError("");
                }
            } else {
                setError(errorData.message);
            }
        }

        else if (creativeRevisionCreateError && instanceOfApiErrorWithCode(creativeRevisionCreateError)) {
            const errorData = creativeRevisionCreateError.data;
            if (errorData.errorCode === PaymentErrorCode.CreditLimitExceeded) {
                setShowUpgradePlanDialog(true);
                setError("");
            } else {
                setError(errorData.message);
            }
        }
    }, [revisionCreateError, creativeRevisionCreateError]);

    useEffect(() => {
        if (revisionRefreshError && instanceOfApiErrorWithCode(revisionRefreshError)) {
            const errorData = revisionRefreshError.data;
            if (errorData.errorCode === PaymentErrorCode.CreditLimitExceeded) {
                setShowUpgradePlanDialog(true);
            } else {
                setError(errorData.message);
            }
        }
        else if (productUpdatingError && instanceOfApiErrorWithCode(productUpdatingError)) {
            const errorData = productUpdatingError.data;
            if (errorData.errorCode === PaymentErrorCode.CreditLimitExceeded) {
                setShowUpgradePlanDialog(true);
            } else {
                setError(errorData.message);
            }
        }
    }, [revisionRefreshError, productUpdatingError]);

    const getRequestId = () => {
        const requestId = uuidv4();
        setLatestRequestId(requestId);
        return requestId;
    };

    const generateSimilar = (revision: Revision, optionalArg?: any) => {

        let preserve: string[] | undefined = undefined;
        if (optionalArg) {
            preserve = optionalArg as string[];
        }
        if ((content?.platform ?? "") === "Product") {

            updateProduct({
                productRevisionRequest: {
                    request_id: getRequestId(),
                    content_id:
                        params.contentId ??
                        content?.id?.content_id ??
                        props.contentCreationDataID ??
                        "",
                    refresh: false,
                    update_brief: false,
                    generate_similar: true,
                    keep: preserve,
                    revision_parent_id: revision.id?.revision_id ?? "",
                },
            });
        }
        else {
            if (content?.creative && content.creative.length > 0) {
                createCreativeRevision({
                    revisionRequest: {
                        request_id: getRequestId(),
                        content_id:
                            params.contentId ??
                            content?.id?.content_id ??
                            props.contentCreationDataID ??
                            "",
                        ai_model_names: activeAIModels.join(","),
                        revision_parent_id: revision.id?.revision_id ?? "",
                        number_of_revisions: newContent?.sliderCount ?? undefined,
                    },
                    updateBrief: true,
                });
            } else {
                createRevision({
                    revisionRequest: {
                        request_id: getRequestId(),
                        content_id:
                            params.contentId ??
                            content?.id?.content_id ??
                            props.contentCreationDataID ??
                            "",
                        ai_model_names: activeAIModels.join(","),
                        revision_parent_id: revision.id?.revision_id ?? "",
                        preserve: preserve,
                    },
                });
            }
        }

    };

    useEffect(() => {
        if (
            (revisionGroupDeleting ||
                revisionItemDeleting ||
                loopDeleting ||
                sharedDeleting)
        ) {
            setShowImageContent(false);
            setTimeout(() => {
                setDownloadedCount(0);
                setShowImageContent(true);
            }, 2000);
        }
    }, [
        revisionGroupDeleting,
        revisionItemDeleting,
        loopDeleting,
        sharedDeleting,
    ]);

    useEffect(() => {
        if (
            content ||
            newRevisionCreated ||
            revisionGroupDeleted ||
            revisionItemDeleted ||
            refreshRevisionCreated ||
            newCreativeRevisionCreated ||
            productUpdatedSuccessfully
        ) {
            getContentDetails();
        }
    }, [
        content,
        newRevisionCreated,
        revisionGroupDeleted,
        revisionItemDeleted,
        refreshRevisionCreated,
        newCreativeRevisionCreated,
        productUpdatedSuccessfully,
    ]);

    useEffect(() => {
        if (updateBrandSuccess) getContentObj();
    }, [updateBrandSuccess]);

    const getContentObj = () => {
        setDownloadedCount(0);
        if (content?.type === "image") {
            dispatch(toggleLoading(true));
            setShowImageContent(false);
        }
        getContent({
            contentId: params.contentId ?? props.contentCreationDataID ?? "",
        });
    };

    const getContentDetails = () => {
        setDownloadedCount(0);
        if (content?.type === "image") {
            setShowImageContent(false);
        }
        if (updateBrandSuccess === true) {
            if (shouldCreateRevisionAfterUpdate) {
                if (content?.creative && content.creative.length > 0) {
                    createCreativeRevision({
                        revisionRequest: {
                            request_id: getRequestId(),
                            content_id:
                                params.contentId ?? props.contentCreationDataID ?? "",
                            ai_model_names: activeAIModels.join(","),
                            number_of_revisions:
                                newContent?.sliderCount ?? undefined,
                        },
                    });
                } else {
                    createRevision({
                        revisionRequest: {
                            request_id: getRequestId(),
                            content_id: params.contentId ?? props.contentCreationDataID ?? "",
                            ai_model_names: activeAIModels.join(","),
                        },
                    });
                }
            }
            resetUpdateBrand();
            return;
        }


        if (content?.type === "text") {
            getTextContentDetails({
                contentId: params.contentId ?? props.contentCreationDataID ?? "",
            });
        } else {
            getTextContentDetails({
                contentId: params.contentId ?? props.contentCreationDataID ?? "",
            });
        }
    };

    useEffect(() => {
        if ((loopDeletionSuccess || sharedDeleted) && !props.isInSide) {
            dispatch(updateMenu());
            navigate("/app/homepage");
        }
    }, [loopDeletionSuccess, sharedDeleted]);

    useEffect(() => {
    }, [downloadedCount]);

    useEffect(() => {
        setSortingRevisions(true);
        setGrouppedRevisions(Object.values(sortedItems(textContentDetail ?? [])));
        setSortingRevisions(false);
        setRecentCreatedWsRevisions([]);
    }, [textContentDetail]);

    useEffect(() => {
        if (recentCreatedWsRevisions.length > 0) {
            setSortingRevisions(true);
            const sortedRevisions = Object.values(sortedItems(recentCreatedWsRevisions ?? []));
            setGrouppedRevisions(sortedRevisions);
            setSortingRevisions(false);
        }
    }, [recentCreatedWsRevisions]);

    const groupedItems = (items: Revision[]) => {
        return items.reduce((acc, curr) => {
            const key = curr.revision_group ?? 0;
            const existingItems = acc[key] as Revision[];

            if (!existingItems) {
                acc[key] = [] as Revision[];
            }

            acc[key].push(curr);

            return acc;
        }, {}) as Revision[][];
    };

    const sortedItems = (items: Revision[]) => {
        const grupped = groupedItems(items);
        return Object.values(grupped).sort((a, b) => {
            return b[0].revision_group ?? 0 > (a[0].revision_group ?? 0) ? -1 : 1;
        });
    };

    const showDeleteGroupDialog = (groupId: number) => {
        dispatch(
            setDialog({
                actions: (
                    <>
                        <PrimaryButton
                            className="h-12 w-[100px]"
                            noFill
                            title={t("cancel")}
                            onClick={() => {
                                dispatch(toggleDialog(false));
                            }}
                        />
                        <PrimaryButton
                            className="h-12 w-[150px] ml-2"
                            title={t("yes-delete")}
                            onClick={() => {
                                dispatch(toggleDialog(false));
                                deleteRevisionGroup({
                                    groupId: groupId,
                                    contentId: params.contentId ?? props.contentCreationDataID ?? "",
                                });
                            }}
                        />
                    </>
                ),
                title: t("delete-revision-group"),
                message: t(
                    "are-you-sure-that-you-would-like-to-delete-this-group-this-cant-be-undone",
                ),
                onClose: () => {
                    dispatch(toggleDialog(false));
                },
            }),
        );
        dispatch(toggleDialog(true));
    };

    const showDeleteItemDialog = (revision: Revision) => {
        dispatch(
            setDialog({
                actions: (
                    <>
                        <div className="flex w-full flex-col lg:flex-row lg:justify-end">
                            <PrimaryButton
                                className="w-full h-10 lg:h-[36px] lg:w-[62px] rounded-[5px]"
                                noFill
                                title={t("cancel")}
                                onClick={() => {
                                    dispatch(toggleDialog(false));
                                }}
                            />
                            <PrimaryButton
                                className="w-full h-10 lg:h-[36px] lg:w-[93px] lg:ml-2 rounded-[5px] max-lg:mt-1"
                                title={t("yes-delete")}
                                onClick={() => {
                                    dispatch(toggleDialog(false));

                                    deleteRevisionItem({
                                        revisionId: revision.id?.revision_id ?? "",
                                    });
                                }}
                            />
                        </div>
                    </>
                ),
                title: t("delete-content"),
                message: t(
                    "are-you-sure-that-you-would-like-to-delete-this-content-this-cant-be-undone",
                ),

                onClose: () => {
                    dispatch(toggleDialog(false));
                },
            }),
        );
        dispatch(toggleDialog(true));
    };

    useEffect(() => {
        dispatch(setContentLoading(revisionCreating));
        if (revisionCreating) {
            Scroll.animateScroll.scrollToTop({
                containerId: "revisionGroupsContainer",
            });
        }
    }, [revisionCreating, dispatch]);

    useEffect(() => {
        dispatch(setContentLoading(creativeRevisionCreating));
        if (creativeRevisionCreating) {
            Scroll.animateScroll.scrollToTop({
                containerId: "revisionGroupsContainer",
            });
        }
    }, [creativeRevisionCreating, dispatch]);

    function refreshRevisionGroup(groupId: number) {
        if ((content?.platform ?? "") === "Product") {
            updateProduct({
                productRevisionRequest: {
                    request_id: getRequestId(),
                    content_id:
                        params.contentId ??
                        content?.id?.content_id ??
                        props.contentCreationDataID ??
                        "",
                    revision_group: groupId,
                    refresh: true,
                    update_brief: false,
                    generate_similar: false
                },
            });
        }
        else {
            if (content?.creative && content.creative.length > 0) {
                createCreativeRevision({
                    revisionRequest: {
                        request_id: getRequestId(),
                        content_id:
                            params.contentId ?? props.contentCreationDataID ?? "",
                        ai_model_names: activeAIModels.join(","),
                        revision_group: groupId,
                        number_of_revisions: newContent?.sliderCount ?? undefined,
                    },
                    refresh: true,
                });
            } else {
                refreshRevision({
                    revisionRequest: {
                        request_id: getRequestId(),
                        content_id:
                            params.contentId ??
                            content?.id?.content_id ??
                            props.contentCreationDataID ??
                            "",
                        ai_model_names: activeAIModels.join(","),
                        revision_group: groupId,
                    },
                    refresh: true,
                });
            }
        }
    }

    const WS_URL = `${process.env.REACT_APP_BASE_URL}websocket/`;
    const GENERATED_CONTENT_TOPIC = "/topic/generated_content";

    useEffect(() => {
        setIsLoading(
            revisionCreating ||
            sortingRevisions ||
            revisionRefreshing ||
            creativeRevisionCreating ||
            productUpdating
        );
    }, [revisionCreating, sortingRevisions, revisionRefreshing, creativeRevisionCreating, productUpdating]);

    function getRevisionPlaceHolder() {
        if (content?.type === "image" && content?.creative) {
            return Array(newContent?.sliderCount ?? 3).fill({ type: "loading" } as Revision);
        }
        else if (content?.type === "image") {

            return [
                { type: "loading" } as Revision,
                { type: "loading" } as Revision,
                { type: "loading" } as Revision,
                { type: "loading" } as Revision,
                { type: "loading" } as Revision,
                { type: "loading" } as Revision,
            ];
        }
        else {
            return [
                recentCreatedWsRevisions.length > 0 ? recentCreatedWsRevisions[0] : { type: "loading" } as Revision,
                recentCreatedWsRevisions.length > 1 ? recentCreatedWsRevisions[1] : { type: "loading" } as Revision,
                recentCreatedWsRevisions.length > 2 ? recentCreatedWsRevisions[2] : { type: "loading" } as Revision,
                recentCreatedWsRevisions.length > 3 ? recentCreatedWsRevisions[3] : { type: "loading" } as Revision,
            ];
        }
    }

    return (
        <>
            {showPlanUpdateSuccessPopup && (
                <ConfirmationPopupOpener
                    heading={"Your Plan Has Been Updated Successfully!"}
                    subHeading={effectiveAt == "IMMEDIATELY" ? "Your plan has been successfully updated. Adjusted features are now in effect." : "The current plan continues until the end of your billing cycle. Your new plan will start in the next billing cycle."}
                    buttonText={"Continue"}
                    onClose={() => {
                        setShowPlanUpdateSuccessPopup(false);
                        dispatch(toggleDialog(false));
                    }}
                />
            )}
            {isUpgradePlanPopupOpen &&
                (
                    <PlanEditPopupOpener
                        onClosed={() => {
                            setIsUpgradePlanPopupOpen(false);
                            dispatch(toggleDialog(false));
                        }}
                        currentPlanId={userSettings?.subscription?.plan_id}
                        paymentMethod={userSettings?.payment_method}
                        onPlanUpdateSucceeded={(effectiveAt?: string) => {
                            setEffectiveAt(effectiveAt ?? "");
                            setIsUpgradePlanPopupOpen(false);
                            dispatch(toggleDialog(false));
                            setShowPlanUpdateSuccessPopup(true);
                        }}
                        onUpdatePaymentMethod={() => {
                            setIsUpgradePlanPopupOpen(false);
                            dispatch(toggleDialog(false));
                        }}
                    />
                )} {showUpgradePlanDialog &&
                    (
                        <ConfirmationPopupOpener
                            heading={"Need More Credits?"}
                            subHeading={"Upgrade your plan for more credits and never hit a roadblock in your workflow.."}
                            buttonText={"View Plans"}
                            onClose={function (): void {
                                setShowUpgradePlanDialog(false);
                                dispatch(toggleDialog(false));
                                setIsUpgradePlanPopupOpen(true);
                            }}
                        />
                    )}
            <div className="">

                <StompTopicListener
                    wsUrl={WS_URL}
                    topic={GENERATED_CONTENT_TOPIC}
                    onMessageReceived={(message: any) => {
                        if (message) {
                            const revisionWithRequestId = JSON.parse(message);
                            if (revisionWithRequestId.request_id === latestRequestId) {
                                var copyRecentCreatedWsRevisions = [...recentCreatedWsRevisions];
                                copyRecentCreatedWsRevisions.push(revisionWithRequestId.revision);
                                setRecentCreatedWsRevisions(copyRecentCreatedWsRevisions);
                            }
                        }
                    }}
                />
                {!props.isInSide && <LoopDetailsHeader
                    filterItems={(filters) => {
                        const items = filters.reduce((details, filter) => {
                            // Apply the filter function of each filter to details
                            return filter.filter(details);
                        }, textContentDetail ?? []);
                        const groupped = Object.values(sortedItems(items));
                        setGrouppedRevisions(groupped);
                    }}
                    loopChannels={aiModelsLocal}
                    content={content}
                    updateLoop={(brand, shouldCreateRevision) => {
                        setShouldCreateRevisionAfterUpdate(shouldCreateRevision);
                        updateBrand({
                            contentBrandUpdateRequest: {
                                brand_id: brand?.id ?? "",
                                content_id: params.contentId ?? props.contentCreationDataID ?? "",
                            },
                        });
                    }}
                    deleteLoop={(content) => {
                        if (content?.read_only !== true) {
                            deleteLoop({
                                contentId: content?.id?.content_id ?? "",
                            });
                        } else {
                            deleteShared({
                                contentId: content?.id?.content_id ?? "",
                            });
                        }
                    }}
                />}


                <div className="flex flex-row">
                    {!props.isInSide && <UpdateBrief
                        content={content}
                        className="hidden md:flex"
                        newRequestId={(id) =>
                            setLatestRequestId(id)
                        }
                        updateBrief={() => {
                            getContentDetails();
                        }}
                        onClose={() => { }}
                        onContentCreated={() => {
                            if ((content?.platform ?? "") === "Product") {
                                getContentDetails();
                            }
                            else {
                                if (
                                    content?.creative &&
                                    content.creative.length > 0
                                ) {
                                    createCreativeRevision({
                                        revisionRequest: {
                                            request_id: getRequestId(),
                                            content_id:
                                                params.contentId ??
                                                props.contentCreationDataID ??
                                                "",
                                            ai_model_names: activeAIModels.join(","),
                                            number_of_revisions: (newContent?.sliderCount ?? undefined)
                                        },
                                        updateBrief: true,
                                    });
                                } else {
                                    createRevision({
                                        revisionRequest: {
                                            request_id: getRequestId(),
                                            content_id: content?.id?.content_id ?? "",
                                            ai_model_names: activeAIModels.join(","),
                                        },
                                        updateBrief: true,
                                    });
                                }
                            }

                        }}
                        onCreditError={() => {
                            dispatch(toggleLoading(false));
                            setShowUpgradePlanDialog(true);
                            setError("");
                        }}
                    />}
                    <div
                        id="revisionGroupsContainer"
                        className={`flex flex-1 flex-col w-full ${props.isInSide ? 'h-[calc(min-h-screen-ios-200px)] lg:h-[calc(70vh-60px)] mx-[10px] md:mx-[10px] ' : 'h-[calc(min-h-screen-ios-200px)] lg:h-[calc(100vh-60px)] mx-[20px] md:mx-[30px] '} m-[30px] my-0 pb-[30px] overflow-scroll scrollbar-hide`}
                    >
                        <WarningMessage
                            title={error}
                            show={error !== ""}
                            className="w-full mt-5"
                            onClose={() => {
                                setError("");
                            }}
                        />
                        {isLoading && (
                            <ContentGroup
                                isInSide={props.isInSide}
                                className="loading"
                                content={{}}
                                loopChannels={aiModelsLocal}
                                type={content?.type ?? "text"}
                                incrementDownloadedCount={() => { }}
                                imageContentVisible={false}
                                revisions={
                                    getRevisionPlaceHolder()
                                }
                                isRenewable={false}
                                generateSimilar={(rev) => { }}
                                deleteRevisionGroup={(groupId: number) => { }}
                                refreshRevisionGroup={(groupId: number) => { }}
                                deleteRevisionItem={(revision: Revision) => { }}
                                updatePage={() => { }}
                            />
                        )}
                        {!isLoading && gruppedRevisions.map((k, index) => (
                            <ContentGroup
                                loopChannels={aiModelsLocal}
                                isInSide={props.isInSide}
                                moveContent={props.moveContent}
                                key={index}
                                selectedImageItems={selectedImageItems}
                                setSelectedImageItems={(img) => {
                                    setSelectedImageItems(prevState => {
                                        if (!prevState.filter(prevImage => prevImage.generated_content === img.generated_content).length) {
                                            return [...prevState, img];
                                        } else {
                                            return prevState.filter(prevImage => prevImage.generated_content !== img.generated_content)
                                        }
                                    })
                                }}
                                className={`content_group_${content?.revisionGroupCount}`}
                                content={content ?? {}}
                                type={content?.type ?? "text"}
                                imageContentVisible={showImageContent}
                                incrementDownloadedCount={() => {
                                    setDownloadedCount((prevDownloadedCount) => prevDownloadedCount + 1);
                                }}
                                revisions={k}
                                isRenewable={index === 0 ? true : false}
                                generateSimilar={(rev, optionalArg) => {
                                    generateSimilar(rev, optionalArg);
                                }}
                                deleteRevisionGroup={(
                                    groupId: number,
                                ) => {
                                    showDeleteGroupDialog(groupId);
                                }}
                                refreshRevisionGroup={(
                                    groupId: number,
                                ) => {
                                    refreshRevisionGroup(groupId);
                                }}
                                deleteRevisionItem={(
                                    revision: Revision,
                                ) => {
                                    showDeleteItemDialog(revision);
                                }}
                                updatePage={() => {
                                    getTextContentDetails({
                                        contentId: params.contentId ?? props.contentCreationDataID ?? "",
                                    });
                                }}
                            />
                        ))}
                    </div>
                </div>
                {props.isInSide && <div className={'my-3 flex justify-between'}>
                    <Button
                        type={'default'}
                        onClick={() => {
                            generateSimilar(gruppedRevisions[0][0] as Revision)
                        }}
                        icon={<SvgRefreshIcon />}
                        className="ml-2 text-H7 text-red bg-[#FFEEEE] flex flex-row items-center border-none">
                        Regenarete
                    </Button>
                    {content?.type !== 'text' && <Button
                        type="primary"
                        onClick={() => {
                            if (props.moveContent) {
                                props.moveContent(selectedImageItems)
                            }
                        }}
                        disabled={!selectedImageItems.length}>
                        Insert Selected
                    </Button>}
                </div>}
                <BottomUpdateBrief
                    content={content}
                    getContentDetails={() => {
                        getContentDetails();
                        Scroll.animateScroll.scrollToTop();
                    }}
                    onContentCreated={() => {
                        if (content?.creative && content.creative.length > 0) {
                            createCreativeRevision({
                                revisionRequest: {
                                    request_id: getRequestId(),
                                    content_id:
                                        params.contentId ??
                                        props.contentCreationDataID ??
                                        "",
                                    ai_model_names: activeAIModels.join(","),
                                    number_of_revisions:
                                        newContent?.sliderCount ?? undefined,
                                },
                                updateBrief: true,
                            });
                        } else {
                            createRevision({
                                revisionRequest: {
                                    request_id: getRequestId(),
                                    content_id: content?.id?.content_id ?? "",
                                    ai_model_names: activeAIModels.join(","),
                                },
                                updateBrief: true,
                            });
                        }
                    }}

                />
            </div>
        </>
    );
};

export default LoopDetails;
