import { t } from "i18next";
import { FunctionComponent, useEffect, useState } from "react";
import { DonutChart } from "react-circle-chart";
import { Delete, Edit } from "../../assets/IconComponents";
import SvgWarningIcon from "../../assets/IconComponents/WarningIcon";
import BrandLogo from "../../components/BrandLogo";
import ContentContainer from "../../components/ContentContainer";
import IconButton from "../../components/IconButton";
import { BrandRequest } from "../../services/BrandApi";
import Icon from "../../components/Icon";
import SvgMoreIcon from "../../assets/IconComponents/MoreIcon";

interface BrandHubBrandLineProps {
  brand: BrandRequest
  editBrand: (brand: BrandRequest) => void
  deleteBrand: (brand: BrandRequest) => void
}

const BrandHubBrandLine: FunctionComponent<BrandHubBrandLineProps> = (props: BrandHubBrandLineProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <ContentContainer
      hoverable={false}
      onClick={() => {
        props.editBrand(props.brand);
      }}
      className="mt-[10px] w-full md:h-[112px] border-1 border-border !rounded-[8px] p-[18px] flex items-center justify-between cursor-pointer"
      children={
        <div className="flex flex-row justify-stretch w-full">
          <div className="w-[80px] h-[80px] rounded-md flex items-center justify-center">
            <BrandLogo width={80} height={80} brand={props.brand} />
          </div>
          <div className="flex flex-col justify-between w-full">
            <div className="ml-[20px]">
              <p className="text-H7 text-black">{props.brand.brand_name}</p>
              <p className="text-BodySmall text-gray mt-1 line-clamp-1">
                {props.brand.about_company.slice(0, 100)}...
              </p>
            </div>
            <div className="w-full md:w-fit flex flex-row items-center max-md:justify-end md:justify-between md:min-w-[150px] mt-[25px] md:mt-0 ml-2">
              {props.brand.definition_ratio !== undefined &&
                props.brand.definition_ratio < 100 && (
                  <div className="md:ml-3 flex flex-row items-center">
                    <div
                      data-tooltip-id="app-tooltip"
                      data-tooltip-html={t(
                        "complete-your-brand-profile-to-get-more-accurate-results"
                      )}
                    >
                      <DonutChart
                        items={[
                          {
                            value: props.brand.definition_ratio ?? 0,
                            label: "",
                            color:
                              props.brand.definition_ratio > 80
                                ? "#00CB2D"
                                : "#FF5555",
                          },
                        ]}
                        showTotal={false}
                        size={20}
                        trackColor="#EAEBE6"
                      />
                    </div>

                    <span
                      className={`text-H8  mr-[3px] ml-[5px] mt-[1px]`}
                      style={{
                        color:
                          props.brand.definition_ratio > 80
                            ? "#00CB2D"
                            : "#FF5555",
                      }}
                    >
                      %{props.brand.definition_ratio}
                    </span>
                    <div
                      className="ml-3"
                      style={{
                        width: "1px",
                        height: "10px",
                        backgroundColor: "#D9D9D9",
                      }}
                    ></div>
                  </div>
                )}
              {props.brand.definition_ratio === undefined ||
                (props.brand.definition_ratio >= 100 && (
                  <div
                    className="md:ml-3"
                    style={{
                      minWidth: "75px", // Adjust the min-width value
                      height: "26px",
                    }}
                  ></div>
                ))}
            </div>
          </div>
          <div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(true);
                setTimeout(() => {
                  setIsExpanded(false);
                }, 6000);
              }}
            >
              <Icon component={SvgMoreIcon} className="w-[20px]" width="20" height="20" viewBox="0 0 20 20" />
            </div>
            {isExpanded && (
              <div className="absolute mr-2  bg-white top-[-10%] right-0 flex border-border border-1 rounded-[8px] py-2 w-[100px] flex-row  z-[1500px] items-center justify-center">
                <IconButton
                  className="hover:w-16"
                  title={t("edit")}
                  icon={Edit}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.editBrand(props.brand);
                  }}
                />

                <IconButton
                  className="hover:w-20"
                  title={t("delete")}
                  icon={Delete}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.deleteBrand(props.brand);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      }
    />
  );
}

export default BrandHubBrandLine;
