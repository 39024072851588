import * as React from "react";
import type { SVGProps } from "react";
const SvgLogoExtractor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    width={20}
    height={20}
    fill="none"
    {...props}>
    <path fill={props.fill ?? "#FFFFFF"} d="M9.66602 14.1663H15.4993C15.7355 14.1663 15.9334 14.0865 16.0931 13.9268C16.2528 13.767 16.3327 13.5691 16.3327 13.333V9.99967C16.3327 9.76356 16.2528 9.56565 16.0931 9.40592C15.9334 9.2462 15.7355 9.16634 15.4993 9.16634H9.66602C9.4299 9.16634 9.23199 9.2462 9.07227 9.40592C8.91254 9.56565 8.83268 9.76356 8.83268 9.99967V13.333C8.83268 13.5691 8.91254 13.767 9.07227 13.9268C9.23199 14.0865 9.4299 14.1663 9.66602 14.1663ZM3.83268 16.6663C3.37435 16.6663 2.98199 16.5031 2.6556 16.1768C2.32921 15.8504 2.16602 15.458 2.16602 14.9997V4.99967C2.16602 4.54134 2.32921 4.14898 2.6556 3.82259C2.98199 3.4962 3.37435 3.33301 3.83268 3.33301H17.166C17.6243 3.33301 18.0167 3.4962 18.3431 3.82259C18.6695 4.14898 18.8327 4.54134 18.8327 4.99967V14.9997C18.8327 15.458 18.6695 15.8504 18.3431 16.1768C18.0167 16.5031 17.6243 16.6663 17.166 16.6663H3.83268ZM3.83268 14.9997H17.166V4.99967H3.83268V14.9997Z" />
  </svg>

);
export default SvgLogoExtractor;