import * as React from "react";
import type { SVGProps } from "react";
const SvgPostImageSizeColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect opacity="0.1" width="50" height="50" rx="8" fill="#FF5555" />
    <path
      opacity="0.35"
      d="M32.5833 11.3335H17.4166C15.6217 11.3335 14.1666 12.7886 14.1666 14.5835V25.4168C14.1666 27.2118 15.6217 28.6668 17.4166 28.6668H32.5833C34.3782 28.6668 35.8333 27.2118 35.8333 25.4168V14.5835C35.8333 12.7886 34.3782 11.3335 32.5833 11.3335Z"
      fill="#FF5555"
    />
    <path
      d="M30.4167 17.8333C31.6133 17.8333 32.5833 16.8633 32.5833 15.6667C32.5833 14.47 31.6133 13.5 30.4167 13.5C29.22 13.5 28.25 14.47 28.25 15.6667C28.25 16.8633 29.22 17.8333 30.4167 17.8333Z"
      fill="#FF5555"
    />
    <path
      d="M16.3334 24.3333C16.3334 24.6207 16.4475 24.8962 16.6507 25.0994C16.8538 25.3025 17.1294 25.4167 17.4167 25.4167H32.5834C32.8707 25.4167 33.1462 25.3025 33.3494 25.0994C33.5526 24.8962 33.6667 24.6207 33.6667 24.3333C33.6667 22.8557 31.838 21.0833 30.9584 21.0833C30.0787 21.0833 29.2814 22.1667 27.1667 22.1667C24.5212 22.1667 22.8334 16.75 20.6667 16.75C18.5 16.75 16.3334 21.4755 16.3334 24.3333Z"
      fill="#FF5555"
    />
    <path
      d="M26.375 36.25H16.625C15.728 36.25 15 35.5231 15 34.625C15 33.7269 15.728 33 16.625 33H26.375C27.272 33 28 33.7269 28 34.625C28 35.5231 27.272 36.25 26.375 36.25Z"
      fill="#FF5555"
    />
    <path
      d="M23.125 41.5H16.625C15.728 41.5 15 40.7731 15 39.875C15 38.9769 15.728 38.25 16.625 38.25H23.125C24.022 38.25 24.75 38.9769 24.75 39.875C24.75 40.7731 24.022 41.5 23.125 41.5Z"
      fill="#FF5555"
    />
  </svg>
);
export default SvgPostImageSizeColorIcon;
