import { t } from "i18next";
import { useEffect, useState } from "react";
import PrimaryButton from "../../components/PrimaryButton";
import ActionSheet from "../../components/ActionSheet/ActionSheet";
import WarningMessage from "../../components/WarningMessage";
import Dragger from "antd/es/upload/Dragger";
import { UploadProps } from "antd";
import { FileProps } from "../../services/ContentApi";
import { InboxOutlined } from "@ant-design/icons";
import { dataURLToBlob } from "blob-util";
import { useDispatch } from "react-redux";
import { toggleLoading } from "../../store/appSlice";
import { useUploadMutation } from "../../services/FileApi";
import ContentImage from "../../components/ContentImage";
import ContentContainer from "../../components/ContentContainer";
import SvgDelete from "../../assets/IconComponents/Delete";
import SvgRefreshWhiteIcon from "../../assets/IconComponents/RefreshWhiteIconFile";
import SvgDeleteWhiteDownloadIcon from "../../assets/IconComponents/DeleteWhiteFileDownloadIcon";
export interface IAddYourOwnInspirationPopupProps {
  show: boolean;
  onClose: () => void;
  className?: string;
  closable?: boolean;
  fullScreen?: boolean;
  fileUploaded?: (file: any) => void
}

function AddYourOwnInspirationPopup(props: IAddYourOwnInspirationPopupProps) {

  const [selectedCard, setSelectedCard] = useState<null | number>(null);

  const [uploadedFileId, setUploadedFileId] = useState<any | undefined>(
    undefined
  );
  

   const [
     uploadFiles,
     {
       data: uploadedFileData,
       isSuccess: filesUploaded,
       isLoading: filesUploading,
     },
   ] = useUploadMutation();

  const [warningMessage, setWarningMessage] = useState("");

  const dispatch = useDispatch();

  const handleCardClick = (cardIndex: number) => {
    setSelectedCard((prevCard) => (prevCard === cardIndex ? null : cardIndex));
  };
  useEffect(() => {
    if (warningMessage !== "") {
      setTimeout(() => {
        setWarningMessage("");
      }, 5000);
    }
  }, [warningMessage]);


    const selectFile = async (file: any) => {
      if (file) {
        const MAX_FILE_SIZE = (5) * 1024 * 1024;
        console.log("MAX FILE SIZE IS:", MAX_FILE_SIZE);
        if (file.size > MAX_FILE_SIZE) {
          alert(
            "File size exceeds the maximum limit of" +
              5 +
              "MB"
          );
          return;
        }
        dispatch(toggleLoading(true));

        let selectedFile = file;
        let a = (await fileToBlob(selectedFile)) as string;
        let blob = dataURLToBlob(a);
        const formData: FormData = new FormData();
        formData.append("files", blob, selectedFile.name);
        uploadFiles({
          body: formData,
        })
          .unwrap()
          .then((k) => {
            selectedFile["uploadedId"] = k.ids ?? "";
            selectedFile.isUploaded = true;
            setUploadedFileId(selectedFile);
            dispatch(toggleLoading(false));
          });
      }
    };
    
  const fileToBlob = (file?: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        if (!!reader.result) {
          resolve(reader.result);
        } else {
          reject(Error("Failed converting to base64"));
        }
      };
    });
  };

  const handleFilesChanged = (e) => {
    console.log("Handle files changed: ", e);
    selectFile(e);
  };

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    //disabled: !!uploadedFileId,
    showUploadList: false,
    accept: ".png, .jpeg, .jpg",
    action: (file) => {
      handleFilesChanged(file);
      return "";
    },
    onDrop(file) {
      handleFilesChanged(file);
      return "";
    },
  };
  return (
    <>
      {props.show && (
        <>
          <div
            className={`${"fixed left-0 right-0 top-0 bottom-0"} backdrop-blur-sm z-40 flex items-center justify-center flex ${
              props.className
            }`}
          >
            <div
              className={`flex flex-col mt-5 mx-2 sm:mt-0 lg:mx-0 sm:justify-center items-center h-fit w-full lg:w-[calc(45%)]`}
            >
              <div
                className="flex flex-col w-full bg-white border-border border-1 rounded-xl shadow-lg shadow-red-100"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <p className="text-black outline-none text-H5 p-[15px]">
                  Add Your Own Inspiration
                </p>
                <hr className="bg-border w-full" />
                <p className="text-black outline-none text-BodyMedium p-[15px] mt-1">
                  Please Select
                </p>
                <div className="flex flex-row  mb-5 justify-center">
                  <div
                    style={{
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      borderRadius: "6px",
                    }}
                    className={`relative flex flex-col mt-[8px] mr-[5px] cursor-pointer rounded-[8px] h-auto pb-2 w-[300px] border-1  ${
                      selectedCard === 1 ? "border-red" : "border-border"
                    }`}
                    onClick={() => handleCardClick(1)}
                  >
                    <img
                      src={require("../../assets/images/add_own_inspo_adapted.png")}
                      style={{
                        width: `300px`,
                        height: `200px`,
                        borderRadius: 10,
                      }}
                      className="object-contain"
                      alt="Adapted"
                    />
                    <span className="text-BodySmall mt-1  pl-1 text-black w-[100px] inline-block truncate text-ellipsis overflow-hidden">
                      Adapted
                    </span>
                  </div>

                  <div
                    style={{
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      borderRadius: "6px",
                    }}
                    className={`relative flex flex-col mt-[8px] mr-[5px] cursor-pointer rounded-[8px] h-auto w-[300px] border-1  ${
                      selectedCard === 2 ? "border-red" : "border-border"
                    }`}
                    onClick={() => handleCardClick(2)}
                  >
                    <img
                      src={require("../../assets/images/add_own_inspo_reimagined.png")}
                      style={{
                        width: `300px`,
                        height: `200px`,
                        borderRadius: 10,
                      }}
                      className="object-contain"
                      alt="Reimagined"
                    />
                    <span className="text-BodySmall mt-1 pl-1 text-black w-[100px] inline-block truncate text-ellipsis overflow-hidden">
                      Reimagined
                    </span>
                  </div>
                </div>

                <div className="flex flex-col mx-4">
                  <Dragger {...uploadProps}>
                    <div className="flex flex-col w-full h-[190px] items-center justify-center rounded-[4px] cursor-pointer">
                      {!uploadedFileId && (
                        <>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <text className="text-H8 mt-2 text-black truncate text-ellipsis overflow-hidden">
                            Click or drag file to this area to upload
                          </text>
                          <text className="text-gray text-BodyMedium  truncate text-ellipsis overflow-hidden">
                            {".jpg, .jpeg or .png files. MAX 5MB"}
                          </text>
                        </>
                      )}
                      {uploadedFileId && (
                        <>
                          <ContentContainer
                            hoverable
                            overlayActiveOnHover={true}
                            onHoverElement={
                              <div
                                style={{
                                  left: "50%",
                                  top: "45%",
                                  transform: "translateX(-50%)",
                                }}
                                className="flex flex-row  p-1 py-1.5 items-center gap-2 text-black justify-center  absolute  w-2/3 h-9"
                              >
                                <div>
                                  <SvgRefreshWhiteIcon />
                                </div>
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setUploadedFileId(undefined);
                                  }}
                                >
                                  <SvgDeleteWhiteDownloadIcon />
                                </div>
                              </div>
                            }
                            children={
                              <div className="flex-col p-0.5">
                                <div
                                  className={`w-full relative  h-[180px] rounded-[8px] cursor-pointer`}
                                >
                                  <ContentImage
                                    forceDirectRender={true}
                                    id={uploadedFileId.uploadedId}
                                    className="py-1 h-[180px] bg-bg w-full rounded-[8px] object-contain"
                                  />
                                </div>
                              </div>
                            }
                          />
                        </>
                      )}
                    </div>
                  </Dragger>
                </div>

                <div className="w-full flex  mt-3 p-[15px] justify-end items-end">
                  <PrimaryButton
                    className="h-8 w-[100px]"
                    noFill
                    title={t("cancel")}
                    onClick={() => {
                      props.onClose();
                    }}
                  />
                  <PrimaryButton
                    className="h-8 w-26 ml-2"
                    title={"Add Inspiration"}
                    isDisabled={!(uploadedFileId && selectedCard)}
                    onClick={() => {
                      if (uploadedFileId && selectedCard) {
                        uploadedFileId["isAdapted"] =
                          (selectedCard ?? 1) === 1 ? true : false;
                        if (props.fileUploaded) {
                          props.fileUploaded(uploadedFileId!);
                        }
                        props.onClose();
                      } else {
                        if (!uploadedFileId) {
                          setWarningMessage("Please upload a file!");
                        } else if (!selectedCard) {
                          setWarningMessage("Please select one of the cards!");
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AddYourOwnInspirationPopup;
