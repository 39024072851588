import * as React from "react";
import type { SVGProps } from "react";
const SvgMagic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path fill="black" d="M8.05518 15.1986C7.87755 15.5894 7.32245 15.5894 7.14482 15.1986L5.30259 11.1457C5.25256 11.0356 5.16436 10.9474 5.05431 10.8974L1.0014 9.05518C0.610606 8.87755 0.610605 8.32245 1.0014 8.14482L5.05431 6.30259C5.16436 6.25256 5.25256 6.16436 5.30259 6.05431L7.14482 2.0014C7.32245 1.61061 7.87755 1.6106 8.05518 2.0014L9.89741 6.05431C9.94744 6.16436 10.0356 6.25256 10.1457 6.30259L14.1986 8.14482C14.5894 8.32245 14.5894 8.87755 14.1986 9.05518L10.1457 10.8974C10.0356 10.9474 9.94744 11.0356 9.89741 11.1457L8.05518 15.1986ZM16.6595 17.9948C16.4807 18.386 15.9242 18.3838 15.7484 17.9912L15.1029 16.5491C15.0527 16.437 14.963 16.3473 14.8509 16.2971L13.4088 15.6516C13.0162 15.4758 13.014 14.9193 13.4052 14.7405L14.8545 14.078C14.9644 14.0277 15.0524 13.9393 15.1022 13.8291L15.7492 12.3976C15.9256 12.0072 16.4792 12.005 16.6588 12.394L17.3228 13.8327C17.3726 13.9407 17.4593 14.0274 17.5673 14.0772L19.006 14.7412C19.395 14.9208 19.3928 15.4744 19.0024 15.6508L17.5709 16.2978C17.4607 16.3476 17.3723 16.4356 17.322 16.5455L16.6595 17.9948Z" />
  </svg>
);
export default SvgMagic;
