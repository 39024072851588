import * as React from "react";
import type { SVGProps } from "react";
const SvgUnion = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0452 8.13565C14.1466 8.24117 14.2857 8.30232 14.432 8.30578C14.5438 8.30842 14.6537 8.27724 14.7475 8.21633C14.8412 8.15542 14.9143 8.06762 14.9573 7.96443C15.0003 7.86124 15.0111 7.74747 14.9884 7.63802C14.9656 7.52858 14.9103 7.42857 14.8297 7.35108L10.3911 2.91244C10.287 2.80843 10.1459 2.75 9.9988 2.75C9.85168 2.75 9.71057 2.80843 9.60652 2.91244L5.16788 7.35108C5.08878 7.42806 5.03444 7.52689 5.0118 7.63491C4.98916 7.74294 4.99925 7.85527 5.04078 7.95753C5.08232 8.05979 5.1534 8.14734 5.24496 8.20899C5.33651 8.27064 5.44437 8.30359 5.55474 8.30361C5.62889 8.30361 5.70229 8.28874 5.7706 8.25989C5.8389 8.23104 5.90074 8.1888 5.95244 8.13565L9.9988 4.08929L14.0452 8.13565ZM14.0452 11.7976C14.1466 11.692 14.2857 11.6309 14.432 11.6274C14.5438 11.6248 14.6537 11.656 14.7475 11.7169C14.8412 11.7778 14.9143 11.8656 14.9573 11.9688C15.0003 12.072 15.0111 12.1858 14.9884 12.2952C14.9656 12.4046 14.9103 12.5047 14.8297 12.5821L10.3911 17.0208C10.287 17.1248 10.1459 17.1832 9.9988 17.1832C9.85168 17.1832 9.71057 17.1248 9.60652 17.0208L5.16788 12.5821C5.08878 12.5052 5.03444 12.4063 5.0118 12.2983C4.98916 12.1903 4.99925 12.078 5.04078 11.9757C5.08232 11.8734 5.1534 11.7859 5.24496 11.7242C5.33651 11.6626 5.44437 11.6296 5.55474 11.6296C5.62889 11.6296 5.70229 11.6445 5.7706 11.6733C5.8389 11.7022 5.90074 11.7444 5.95244 11.7976L9.9988 15.8439L14.0452 11.7976Z"
      fill="#121315"
    />
  </svg>
);
export default SvgUnion;



