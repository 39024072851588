import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SvgBack from "../../../assets/IconComponents/Back";
import SvgLogoIcon from "../../../assets/IconComponents/LogoIcon";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { setCollapsedModeEnabled, setMenuCollapsed } from "../../../store/appSlice";
import IconButton from "../../../components/IconButton";
import { t } from "i18next";
import { Revision, useFavoriteMutation } from "../../../services/RevisionApi";
import { Delete, Download, Like, Liked } from "../../../assets/IconComponents";
import PrimaryButton from "../../../components/PrimaryButton";

interface ContentEditorHeaderProps {
    revision?: Revision
    deleteItem: () => void
    downloadImageToLocal: () => void
}


const ContentEditorHeader: FunctionComponent<ContentEditorHeaderProps> = (props: ContentEditorHeaderProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isContentLoading = useAppSelector(k => k.content.isContentLoading)
    const [favRev, { isSuccess: favSuccess }] = useFavoriteMutation();
    const [isFavori, setFavori] = useState(false);

    useEffect(() => {
        setFavori(props.revision?.is_favorite ?? false)
    }, [props.revision])


    return (
        <>
            <div className="h-[56px] min-w-full text-xl flex flex-row bg-white -ml-[0px] md:-ml-[10px]  md:-mr-[12px] border-1 border-border justify-between items-center">
                <div className="w-full flex items-center gap-[20px]">
                    <div className="flex items-start gap-[1px]">
                        <button
                            disabled={isContentLoading}
                            className="w-16 h-[56px] border-r-1 border-border"
                            onClick={() => {
                                navigate(-1);
                            }}>
                            <div className='flex justify-center items-center'>
                                <SvgBack width='20' height='20' color="#999999" fill="#999999" className="" />
                            </div>
                        </button>
                    </div>
                    <div className="flex justify-start items-center gap-[15px]">
                        {/* <div className="text-H6 text-black">{props.revision?.id?.revision_id ?? ""}</div> */}
                        {/* <IconButton
                            className="hover:w-20 ml-[15px]"
                            title={isFavori ? t("unlike") : t("like")}
                            color={isFavori ? "red" : "gray"}
                            icon={isFavori ? Liked : Like}
                            onClick={(e) => {
                                if (!isContentLoading) {
                                    e.stopPropagation();
                                    favRev({
                                        revisionId: props.revision?.id?.revision_id ?? "",
                                        isFavorite: !isFavori,
                                    });
                                    setFavori(!isFavori);
                                }
                            }}
                        /> */}
                    </div>
                </div>

                <div className="p-[20px] gap-[10px] flex justify-center align-center ">
                    <PrimaryButton
                        icon={<Download fill={isContentLoading ? "#ffffff" : "#121315"} />}
                        noFill
                        className="h-10 w-10 min-w-[40px] min-h-[40px]"
                        isDisabled={isContentLoading}
                        tooltip={t("download-content") ?? ""}
                        onClick={() => {
                            if (!isContentLoading) {
                                props.downloadImageToLocal();
                            }
                        }} />
                    <PrimaryButton
                        icon={<Delete fill={isContentLoading ? "#ffffff" : "#121315"} />}
                        noFill
                        className="h-10 w-10 min-w-[40px] min-h-[40px]"
                        isDisabled={isContentLoading}
                        tooltip={t("delete-content") ?? ""}
                        onClick={() => {
                            if (!isContentLoading) {
                                props.deleteItem();
                            }
                        }} />
                </div>

            </div>
            {/* <div className="h-[56px] min-w-full text-xl max-lg:flex lg:hidden flex-row justify-between items-center  bg-white -ml-[0px] border-1 border-t-0 border-border">
                <div className='lg:hidden max-lg:flex flex-row justify-center'>
                </div>
                <div className="flex flex-row">

                </div>
            </div> */}
        </>
    );
}

export default ContentEditorHeader;