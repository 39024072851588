import * as React from "react";
import type { SVGProps } from "react";
const SvgBrandVoiceToneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.1" width="28" height="28" rx="4" fill="#FF5555" />
    <rect
      x="7"
      y="7"
      width="14"
      height="14"
      fill="url(#pattern_gQNh82RrKMd7urZydBSkCQXxApPsQxnIIQZyS+r)"
    />
    <defs>
      <pattern
        id="pattern_gQNh82RrKMd7urZydBSkCQXxApPsQxnIIQZyS+r"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#image0_6690_42810" transform="scale(0.0104167)" />
      </pattern>
      <image
        id="image0_6690_42810"
        width="96"
        height="96"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAFYUlEQVR4nO1dTW8cRRBtAgSIBIeARUBwI3CIhIIsRRgQNh82RrKMd7urZydBSkCQXxApPsQxnIIQZyS+rsAt3AK/IBAiSMQhCldOfOQESImR/FDPdOIk3rW3e3a3amb7SU+y7PFM16vuqu7a7lmlEhISEhIShAGHDj0AohxEn8GYcyD6A8asgQhbsrzGXXsOxnwKYzpYXLyf257aAFn2JIz5AkT/bit2/3T3+hzt9l5u+8QCRPeB6CMQ/TdA4buNjg9x+PC93PaKAtrtvTDml6EJv9kRZ9FqPcJttwhA62eKmD0q8Tf4G7R+Wqlx7/nEIv6GE4j2qHGEi8Mw5gKj+Nd53uUfNW7wCRdC+L4au6nmMGc7FMy/xyoU+Xk+hPFjNUYr3EEusjAQGvPPWKyYfXkBQpmppqOo7fALjR78RDUdvrDGLTR6hKGzqumAMX+yC009+btqOkB0TYDQ6MGrqukQIDK2omo6uAVGcgC/yEgjgF9opBDEL3Y3qqaDW2AkB/CLjDQC+IVGCkH8Ynejajq4BUZyAL/ISCOAX2ikEMQvdjeqpoNbYCQH8IuMNAL4hUYKQfxid6NqOrgFRnIAv8hII4BfaDQtBGF1dQeInoLWczCGCrqf3T7Q1dUdN64bzCmXM7D2BLLsSEGileJ3RGuDckC/9ogAFhZ2QevX/a63zXR/W1jYVVxbTaArIDre8zlEy/6aSg4IsYcdRU/ZqrE3N7rsVVV6/vFtn1M6IX4kBNojZat53hfLa2N755m+n0P0bfRzAu2RcMZrLkCY2WhhrD0R8JyV6OcE2iNhqyH13eDy2lgHvNX3c8prY0NdkD3c+rukmgX0mDx6a6INcADR25EOuBpoC/92dlirAxt9RWwIMsXG4f5tybK2hBzwRqADLo4gCX8X6YALgbYsSghBs4GN/ibSAWt+irnd/ZejDwAaczrIFmtfleCAqaBGG/NBdIKkInwtD3EhdirIllbrWQkhaF/gCHgXROsVRFrz8/yVIjGXyfmkDztVjr6uw9p3gmzRep+EEbAn0AGOP1YQCkPi98F2dDoPc+uvMD19V/FSpLDGvydAcNzGlUAb3PT7TiUBLhkFNv4giH4VIPp1XvJtCrHhZSUFQfWTDR4TIHxJrY8Ft1/S27eKt12Fh6EcRF+zi2/Ml8HtdrZKe+sWiF6IcMCbIPqZTXxrz/s2hLXb2ueVNIBoIsIBOYiOMOWDy/7TtPA25/lDSiIiknF+wwnG/DTSnh8rvtavKKlwPSPSAbkPBV+NKOaHh50N7laSgSx7roJxuZ8dXR6C+JeiZju3ckpJB+bn74HW7YqGHoS1qyD6oWLZYt2vcE9WbI9jy9mm6gBk2eMDMDgvWNZnXJHsNIy56Itt3T7UuQZj/vIlZVfVPBVc29mKrdZjqobvA80bwv2qboBSd8DalwSIl1ei1jPOFlVHuGJVhalpLoCzmJy8W9UZINqJdvu1Gvb8ORw9Wm/xbylZW/tijcSfqX3P75oT6pGY99c25vf9CZq1SwJ7fRudzqNqHFDkBaLJyBL2MDhVm0XWIIGlpQeLeMvX62fE13ZGARDthtauhmRHILz1W2mS8D1C0xMgmg7aJLsd3b3KWZi7985ND07YDH+YY8J/3nwgQvgD/n8nRBykqDsQ6ADu9jYOSA5IDhhrII2AigLefu6Wd+GVd50pST0HXBXbnrslYZR0Drgq+j53S8Io5Rww077RXAQlnAOuisBzt7kw8p8DrgqRCZdqdA54rB1AZFTdkUIQM1ISlrEAmxcQTvJAzjdiGnrT98XXyQnzjfuO4WIklGuCWZGJ2RRtmm1kKSIhISEhQUnE//EdwBIPo8jLAAAAAElFTkSuQmCC"
      />
    </defs>
  </svg>
);
export default SvgBrandVoiceToneIcon;
  