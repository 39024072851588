import { SVGProps } from "react";
import Icon from "../Icon";
import SvgRadioSelected from "../../assets/IconComponents/RadioSelected";
import SvgRadioUnselected from "../../assets/IconComponents/RadioUnselected";
import { IGenericSelectorData } from "./RadioSelector";


export interface IRadioSelectorItemProps {
    data: IGenericSelectorData
    selected: boolean
    itemSelected: (data: IGenericSelectorData) => void
}

function RadioSelectorItem(props: IRadioSelectorItemProps) {
  const { selected = false } = props;

  return (
    <div
      className={`w-[300px] h-[42px]  cursor-pointer flex items-center 

        `}
      onClick={() => {
        props.itemSelected(props.data);
      }}
    >
      {selected && <SvgRadioSelected />}
      {!selected && <SvgRadioUnselected />}
      <div className="flex flex-row items-center">
        <text className="text-BodyMedium text-black ml-2">
          {props.data.title}
        </text>
      </div>
    </div>
  );
}

export default RadioSelectorItem;