import * as React from "react";
import type { SVGProps } from "react";
const SvgInstagramColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    {...props}
  >
    <path
      fill="url(#InstagramColor_svg__a)"
      d="M6.667 3A4.171 4.171 0 0 0 2.5 7.167v6.666A4.171 4.171 0 0 0 6.667 18h6.666a4.171 4.171 0 0 0 4.167-4.167V7.167A4.171 4.171 0 0 0 13.333 3H6.667Zm0 1.667h6.666c1.379 0 2.5 1.121 2.5 2.5v6.666c0 1.379-1.121 2.5-2.5 2.5H6.667a2.503 2.503 0 0 1-2.5-2.5V7.167c0-1.379 1.121-2.5 2.5-2.5Zm7.5.833a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667ZM10 6.333A4.171 4.171 0 0 0 5.833 10.5 4.171 4.171 0 0 0 10 14.667a4.171 4.171 0 0 0 4.167-4.167A4.171 4.171 0 0 0 10 6.333ZM10 8c1.378 0 2.5 1.122 2.5 2.5S11.378 13 10 13a2.503 2.503 0 0 1-2.5-2.5C7.5 9.122 8.622 8 10 8Z"
    />
    <defs>
      <linearGradient
        id="InstagramColor_svg__a"
        x1={10}
        x2={10}
        y1={3}
        y2={18}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#304FFE" />
        <stop offset={0.194} stopColor="#8E24AA" />
        <stop offset={0.348} stopColor="#F50057" />
        <stop offset={0.521} stopColor="#FF5722" />
        <stop offset={0.768} stopColor="#FFC107" />
        <stop offset={0.904} stopColor="#FFD54F" />
        <stop offset={1} stopColor="#FFECB3" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgInstagramColor;
