import * as React from "react";
const SvgLeftMenuTextToSpeechButton = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    {...props}
  >
    <path
      d="M3.59 18.416a1.45 1.45 0 0 1-1.064-.442 1.45 1.45 0 0 1-.443-1.064V4.09c0-.415.148-.77.443-1.064a1.45 1.45 0 0 1 1.063-.443h4.608c.177 0 .325.06.445.18s.18.268.18.445-.06.326-.18.445a.605.605 0 0 1-.445.18H3.589a.25.25 0 0 0-.184.072.25.25 0 0 0-.072.185v12.82a.25.25 0 0 0 .072.184.25.25 0 0 0 .184.072h8.654a.25.25 0 0 0 .185-.072.25.25 0 0 0 .072-.184v-.994c0-.177.06-.325.18-.445s.268-.18.445-.18.325.06.445.18.18.268.18.445v.994c0 .414-.148.769-.443 1.064a1.45 1.45 0 0 1-1.064.442H3.59Zm2.243-3.125a.604.604 0 0 1-.445-.18.605.605 0 0 1-.18-.445c0-.177.06-.325.18-.445s.268-.18.445-.18H10c.177 0 .325.06.445.18s.18.268.18.446c0 .177-.06.325-.18.445a.605.605 0 0 1-.445.18H5.833Zm0-2.404a.604.604 0 0 1-.445-.18.605.605 0 0 1-.18-.445c0-.177.06-.325.18-.445s.268-.18.445-.18h2.5c.177 0 .325.06.445.18s.18.269.18.446-.06.325-.18.445a.605.605 0 0 1-.445.18h-2.5Zm2.051-3.349a.973.973 0 0 1-.715-.294.973.973 0 0 1-.294-.715V6.798c0-.28.098-.519.294-.715a.973.973 0 0 1 .715-.295h1.37l1.861-1.86a.72.72 0 0 1 .212-.145.633.633 0 0 1 .267-.057c.194 0 .358.068.494.205a.68.68 0 0 1 .203.497v6.47a.68.68 0 0 1-.203.497.669.669 0 0 1-.494.206.632.632 0 0 1-.267-.057.721.721 0 0 1-.212-.145l-1.86-1.86h-1.37Zm7.532-1.875a3.17 3.17 0 0 1-.881 2.2c-.103.112-.224.135-.366.071-.14-.064-.211-.181-.211-.35v-3.84c0-.157.071-.269.213-.337.142-.069.264-.05.364.056.29.298.51.637.659 1.016.148.38.222.774.222 1.184Zm1.667 0c0-.933-.226-1.8-.678-2.602A4.995 4.995 0 0 0 14.52 3.16a.7.7 0 0 1-.338-.374.602.602 0 0 1 .005-.477.579.579 0 0 1 .347-.338.526.526 0 0 1 .467.035 6.314 6.314 0 0 1 2.446 2.37 6.378 6.378 0 0 1 .887 3.287c0 1.184-.296 2.28-.887 3.287A6.314 6.314 0 0 1 15 13.32a.526.526 0 0 1-.467.035.58.58 0 0 1-.348-.337.602.602 0 0 1-.004-.478.7.7 0 0 1 .338-.374 4.996 4.996 0 0 0 1.886-1.901 5.211 5.211 0 0 0 .678-2.602Z"
    />
  </svg>
);
export default SvgLeftMenuTextToSpeechButton;
