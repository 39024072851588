import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SvgSidebarNotificationsIcon from "../../assets/IconComponents/SidebarNotificationsIcon";
import SvgNotificationUnreadIcon from "../../assets/IconComponents/NotificationUnreadIcon";
import { toggleNotificationsSidebar } from "../../store/appSlice";

export interface IMenuNotificationComponentProps {
  collapsed?: boolean
  unreadsExist?: boolean
  isActive?: boolean
  onClick?: () => (void);
}
const MenuNotificationComponent = (props) => {
  const dispatch = useDispatch();


  return (
    <>
      <div
        onClick={() => {
          props.onClick();
        }}
        className={`flex flex-row ml-[15px]  mt-[2px] ${props.collapsed ?? true ? "mb-4" : ""}`}
      >
        <SvgSidebarNotificationsIcon
          fill={props.isActive ?? false ? "#FFFFFF" : "#8E8E8E"}
        />
        {(props.unreadsExist ?? false) && <SvgNotificationUnreadIcon />}
      </div>
    </>
  );
};

export default MenuNotificationComponent;
