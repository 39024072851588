import * as React from "react";
const SvgAIMarketingToolsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#8E8E8E"
      d="m6.47 18.854-.708-1.562-1.562-.709 1.562-.708.709-1.563.708 1.563 1.563.708-1.563.709-.708 1.562ZM15.5 8.083l-.468-1.032L14 6.583l1.032-.468.468-1.032.468 1.032L17 6.583l-1.032.468-.468 1.032ZM7.5 13c-1.389 0-2.57-.486-3.542-1.458C2.986 10.569 2.5 9.389 2.5 8c0-.278.02-.556.063-.833.041-.278.118-.542.229-.792a.786.786 0 0 1 .26-.313c.104-.069.219-.118.344-.145a.804.804 0 0 1 .75.23l2.187 2.186 1.5-1.5-2.187-2.187a.804.804 0 0 1-.23-.75c.028-.125.077-.24.146-.344a.786.786 0 0 1 .313-.26c.25-.111.514-.188.792-.23A5.61 5.61 0 0 1 7.5 3c1.389 0 2.57.486 3.542 1.458C12.014 5.431 12.5 6.611 12.5 8c0 .32-.028.622-.083.906a4.688 4.688 0 0 1-.25.844l4.208 4.167c.403.402.604.896.604 1.479 0 .583-.201 1.076-.604 1.479a2.012 2.012 0 0 1-1.48.604 1.978 1.978 0 0 1-1.478-.625L9.25 12.667a4.688 4.688 0 0 1-.844.25A4.732 4.732 0 0 1 7.5 13Zm0-1.667c.361 0 .722-.055 1.083-.166.361-.111.688-.285.98-.521l5.062 5.062c.07.07.163.101.281.094a.417.417 0 0 0 .281-.114.38.38 0 0 0 .105-.282.38.38 0 0 0-.104-.281l-5.063-5.042c.25-.277.43-.6.542-.968.11-.368.166-.74.166-1.115 0-.833-.267-1.559-.802-2.177a3.223 3.223 0 0 0-1.99-1.115L9.584 6.25a.8.8 0 0 1 .25.583.8.8 0 0 1-.25.584l-2.666 2.666a.8.8 0 0 1-.584.25.8.8 0 0 1-.583-.25L4.208 8.542a3.223 3.223 0 0 0 1.115 1.99 3.222 3.222 0 0 0 2.177.801Z"
    />
  </svg>
);
export default SvgAIMarketingToolsIcon;
