import * as React from "react";
const SvgCreateBanner = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill ?? "#FFFFFF"}
      d="M1.795 15.624c-.383 0-.716-.14-.997-.422a1.364 1.364 0 0 1-.423-.997V1.795c0-.385.141-.719.423-1.001.281-.282.614-.424.997-.424h12.41c.385 0 .718.142 1 .424.283.282.425.616.425 1v12.411c0 .383-.142.716-.424.997a1.371 1.371 0 0 1-1 .422H1.794Zm0-1.419h12.41V1.795H1.795v12.41Zm1.762-1.931h8.907c.14 0 .242-.063.307-.19a.362.362 0 0 0-.022-.38l-2.422-3.25a.364.364 0 0 0-.286-.142.341.341 0 0 0-.284.142l-2.465 3.18L5.644 9.37a.359.359 0 0 0-.575 0l-1.776 2.333c-.094.127-.108.254-.043.38.065.127.167.19.307.19Z"
    />
  </svg>
);
export default SvgCreateBanner;
