import {t} from "i18next";
import {useEffect, useRef, useState} from "react";
import PrimaryButton from "../../../components/PrimaryButton";
import {useAppDispatch, useAppSelector} from "../../../helpers/hooks";
import Gantt from "../../../components/Gantt";
import Toolbar from "../../../components/Toolbar";
import GanttEditModal from "../../../components/Gantt/GanttEditModal";
import {
    MarketingPlannerRequest,
    MarketingPlannerResponse, useDeleteEventAndCampaignsMutation,
} from "../../../services/MarketingPlanner";
import dayjs from "dayjs";
import {useCreateActivityFromCampaignsMutation} from "../../../services/Activity";
import {setActivities} from "../../../store/activitySlice";
import {reformatText} from "../../../helpers/Utils";
import { toggleLoading } from "../../../store/appSlice";
import {useBlocker, useNavigate} from "react-router-dom";
import {Button, Modal} from "antd";
import WarningIconRed from "../../../assets/IconComponents/WarningIconRed";

const ReviewCampaigns = (props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [currentZoom, setCurrentZoom] = useState('Weekly')
    const [processData, setProcessData] = useState({})
    const [isGanttEditModalOpen, setIsGanttEditModalOpen] = useState(false)
    const [selectedGanttItem, setSelectedGanttItem] = useState<any>()
    const [selectedMarketingItem, setSelectedMarketingItem] = useState<any>()
    const [data, setData] = useState<MarketingPlannerResponse[]>()
    const {plan, event, savedData, ganttData} = useAppSelector((k) => k.planner);
    const [ganttItems, setGanttItems] = useState<any[]>()
    const deleteRef = useRef(() => {});
    const ganttRef = useRef({});
    const initGanttRef = useRef();
    const [createActivityFromCampaigns, { data: createdActivitiesFromCampaigns, isLoading: createActivityIsLoading }] = useCreateActivityFromCampaignsMutation()
    const [isCloseHappyPath, setIsCloseHappyPath] = useState(false);
    const [closeModal, setCloseModal] = useState(false);
    const [wantToRedirectPath, setWantToRedirectPath] = useState('');
    const [leaveType, setLeaveType] = useState('');
    const [deleteEventAndCampaigns] = useDeleteEventAndCampaignsMutation();


    let blocker = useBlocker(
        ({ currentLocation, nextLocation }) => {
            setWantToRedirectPath(nextLocation.pathname)
            setCloseModal(!isCloseHappyPath && currentLocation.pathname !== nextLocation.pathname)
            setLeaveType('outOfPage')
            return !isCloseHappyPath && currentLocation.pathname !== nextLocation.pathname
        }
    );

    const leavePage = () => {
        deleteEventAndCampaigns({body: ganttItems?.map(a => a.id) || []})
        switch(leaveType) {
            case 'back':
                let hasPaid = false;
                if(props[props.type === "event" ? "event" : "plan"]?.channels?.length >0 ){
                    props[props.type === "event" ? "event" : "plan"]?.channels?.forEach(channel =>
                        {
                            if (channel === "GOOGLE_ADS" || channel === "META_ADS") {
                                hasPaid = true
                            }
                        }

                    )
                }
                props.onCancel({pageCount: hasPaid ? 1 : 2 });
                props.onCancel({pageCount: props.type === "event" ? 1 :  (hasPaid ? 2 : 3)  });
                break;
            case 'outOfPage':
                setIsCloseHappyPath(true);
                setTimeout(() => navigate(wantToRedirectPath),100)
                break;
            default:
            // code block
        }
    }

    useEffect(() => {
        dispatch(toggleLoading(createActivityIsLoading));
    }, [createActivityIsLoading]);

    useEffect(() => {
        // @ts-ignore
        if (ganttRef.current?.clearAll) {
            // @ts-ignore
            ganttRef.current?.clearAll()
        }
         // @ts-ignore
         if (ganttRef.current?.refreshData) {
            // @ts-ignore
            ganttRef.current?.refreshData()
        }
        if (savedData?.length) {
                const data = savedData.map((item, i) => {
                const ganttObj = {
                    ...item,
                    realId: item.id,
                    id: item.id,
                    text: reformatText(item.headerType === "EVENT" ? item.eventType : item.campaignType),
                    activity: item.activitySize,
                    start_date: dayjs(item.startDate).format('YYYY-MM-DD'),
                    end_date: dayjs(item.endDate).format('YYYY-MM-DD'),
                } as any

                if (item.rootId !== item.id) {
                    savedData.forEach((baseItem , index) => {
                        if (item.rootId === baseItem.id) {
                            ganttObj.parent = baseItem.id
                        }
                    })

                }
                if (item.headerType === "EVENT") {
                    let connectedCampaigns = 0
                    savedData.forEach((baseItem , index) => {
                        if (item.id === baseItem.rootId) {
                            connectedCampaigns ++;
                        }
                    })
                    ganttObj.duration = (connectedCampaigns - 1) *7
                } else {
                    ganttObj.duration = 7
                }
                if (item.rootId === item.id) {
                    ganttObj.open = true
                }

                return ganttObj as MarketingPlannerResponse
            })
            // @ts-ignore
            if (ganttRef.current?.clearAll) {
                // @ts-ignore
                ganttRef.current?.clearAll()
            }

            // @ts-ignore
            if (ganttRef.current?.parse) {
                // @ts-ignore
                ganttRef.current?.parse({data:data})
            }

            setGanttItems(data.sort((a,b) => dayjs(a.startDate).valueOf() - dayjs(b.startDate).valueOf()))

            // @ts-ignore
            if (ganttRef.current?.refreshData) {
                // @ts-ignore
                ganttRef.current?.refreshData()
            }
        }
    }, [savedData]);

    return (
        <div className={'w-full'}>
            <div className={'mb-7 ml-2'}>
                <div className={'text-H4'}>
                    <h2>Review Campaigns</h2>
                </div>
                <div className={'text-BodySmall mt-2'}>
                    Once you confirm, Evercopy will plan the activities for each.
                </div>
            </div>
            <div className="h-[56px] w-full flex items-center bg-white border-b-1 border-border justify-end px-4 rounded">
                <div className="zoom-bar text-black">
                    <Toolbar
                        zoom={currentZoom}
                        onZoomChange={setCurrentZoom}
                        isFilterEnabled={false}
                    />
                </div>
            </div>
            <div className="w-full text-black">
                <div className="gantt-container-reviewCampaigns">
                    { ganttItems && <Gantt
                        isPreview={true}
                        onChange={(e) => console.log(e)}
                        tasks={{data: ganttItems}}
                        setGantt={(ref) => {
                            ganttRef.current = ref;
                        }}
                        initGantt={(ref) => {
                            initGanttRef.current = ref;
                        }}
                        zoom={currentZoom}
                        handleTaskClick={(item) => {
                            setIsGanttEditModalOpen(true);
                            setSelectedGanttItem(item);
                            setSelectedMarketingItem(savedData.filter(data => data.id === item.id )?.[0] )
                        }}
                    />}
                </div>
            </div>
            <div className="flex justify-between mt-6">
                <PrimaryButton
                    className="h-10 w-full lg:w-[140px]"
                    noFill
                    title={"Back"}
                    onClick={() => {
                        setLeaveType('back');
                        setCloseModal(true)
                    }}
                />
                <PrimaryButton
                    className="h-10 w-full lg:w-[180px] lg:ml-2 max-lg:mt-1"
                    title={t("create-campaign-events")}
                    onClick={() => {
                        setIsCloseHappyPath(true);
                        props.onComplate();
                        createActivityFromCampaigns({body: savedData as MarketingPlannerRequest[]}).then(activities => {
                            console.log(activities)
                            // @ts-ignore
                            dispatch(setActivities(activities.data))
                        })
                    }}
                />
            </div>
            {isGanttEditModalOpen &&
                <GanttEditModal isOpen={isGanttEditModalOpen} selectedItem={selectedGanttItem} ganttRef={ganttRef} close={() => {
                    setIsGanttEditModalOpen(false)
                }} isEditMode={true}  marketingData={selectedMarketingItem}/>
            }

            <Modal open={closeModal} onCancel={() => {}} closeIcon={false} footer={<div className={'flex justify-center'}>
                <Button type="default" className={'mr-3'} onClick={() => {
                    leavePage()
                }} size={'large'}>Yes, Delete</Button>
                <Button type="primary" onClick={() => setCloseModal(false)} size={'large'}>Go Back</Button>
            </div>} cancelText={'Back'} okText={'OK'}>
                <div className={'flex justify-center items-center flex-col text-center mb-6'}>
                    <WarningIconRed />
                    <h1 className={'text-H5 text-black mt-3'}>Are you sure? Data will be lost.</h1>
                    <p className={'text-gray block mt-3'}>If you cancel, all campaign details and inputs entered so far
                        will be deleted, and you will need to start from scratch.</p>
                </div>
            </Modal>
        </div>
    )
}

export default ReviewCampaigns
