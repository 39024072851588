import { t } from "i18next";
import PrimaryButton from "../../../components/PrimaryButton";
import { Add } from "../../../assets/IconComponents";
import SocialAccountConnectionCard from "./SocialAccountConnectionCard";
import { SocialApiKeyModel, useLazyGetApiKeysByOrganizationIdQuery } from "../../../services/AdManagerApi";
import { useEffect, useState } from "react";
import SocialAccountsEmpty from "./SocialAccountsEmpty";


export interface ISocialAccountListingProps {
    onAddNewClicked: () => void
}

function SocialAccountListing(props: ISocialAccountListingProps) {

    const [connectionInfo, setConnectionInfo] = useState<SocialApiKeyModel[]>([])

    const [getApiKeys, { data: apiKeys, isLoading: apiKeysLoading }] = useLazyGetApiKeysByOrganizationIdQuery()

    useEffect(() => {
        if (apiKeys !== undefined) {
            setConnectionInfo(apiKeys)
        }
    }, [apiKeys])

    useEffect(() => { getApiKeys() }, [])

    return (
        <>
            {
                (connectionInfo.length === 0) && <SocialAccountsEmpty onClick={() => {
                    props.onAddNewClicked()
                }}></SocialAccountsEmpty>

            }
            {(connectionInfo.length > 0) &&
                < div className="flex flex-col w-full gap-[16px]" >
                    <div className="mb-2 mt-3  flex flex-row w-full items-center justify-between">
                        <span className="text-H7 text-black ml-2 w-72 sm:w-full truncate text-ellipsis overflow-hidden">
                            {t("added-accounts")}
                        </span>
                        <PrimaryButton
                            onClick={props.onAddNewClicked}
                            icon={<Add fill="#ffffff" className="mr-2" />}
                            title={t('add-new')}
                            className="h-[40px] w-[105px] md:w-[105px] mt-5 md:mt-0"
                        />
                    </div>
                    {
                        connectionInfo?.map(apiKey => {
                            return <SocialAccountConnectionCard
                                social_account={apiKey} onApiKeyDeleted={(deletedApiKey) => {
                                    getApiKeys();
                                }} />
                        }) ?? <></>

                    }

                </div >
            }
        </>
    )
}


export default SocialAccountListing;