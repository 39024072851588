import { t } from "i18next";
import { Close, Picture } from "../../assets/IconComponents";
import SvgCloseLittle from "../../assets/IconComponents/CloseLittle";
import SvgText from "../../assets/IconComponents/Text";
import ContentContainer from "../ContentContainer";
import IconButton from "../IconButton";
import { useEffect, useState } from "react";
import { IFilterSelectorData } from "./FilterSelector";

import PrimaryButton from "../PrimaryButton";
import FilterSelectorItem from "./FilterSelectorItem";

export interface IFilterSelectorPopupProps {
  data: IFilterSelectorData[];
  subTitle: string;
  closePopup: () => void;
  selectedItems: IFilterSelectorData[];
  selectedItemsChanged: (data: IFilterSelectorData[]) => void;
}

function FilterSelectorPopup(props: IFilterSelectorPopupProps) {
  const [selectedItems, setSelectedItems] = useState<IFilterSelectorData[]>([]);

  useEffect(() => {
    setSelectedItems(props.selectedItems);
  }, [props.selectedItems]);

  return (
    <ContentContainer
      hoverable={false}
      className="w-[340px] h-fit  overflow-hidden"
      children={
        <>
          {/*Header*/}
          {/*Body*/}
          <div className="p-[15px] pt-[10px] h-full overflow-scroll scrollbar-hide">
            {props.data.map((k) => (
              <FilterSelectorItem
                data={k}
                selected={!!selectedItems?.find((item) => item.id === k.id)}
                itemSelected={(e) => {
                  const itemExists = !!selectedItems?.find(
                    (item) => item.id === e.id
                  );

                  if (itemExists) {
                    // Item is already selected, remove it
                    const updatedSelectedItems = selectedItems?.filter(
                      (item) => item.id !== e.id
                    );
                    setSelectedItems(updatedSelectedItems);
                  } else {
                    // Item is not selected, include it
                    setSelectedItems([...(selectedItems || []), e]);
                  }
                }}
              />
            ))}
            <PrimaryButton
              className="w-full h-[36px] mt-2"
              title={t("apply-filters-0")}
              onClick={() => {
                props.selectedItemsChanged(selectedItems);
              }}
            />
          </div>
        </>
      }
    />
  );
}

export default FilterSelectorPopup;