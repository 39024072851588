export enum ContentSize {
  post,
  landscape,
  story,
  mobilemedr,
  mobileleaderb,
  vertical,
  invalid,
  creative_post,
  creative_landscape,
  creative_story,
}

export const SizeConstants = {
  [ContentSize.post]: {
    width: 1080,
    height: 1080,
    renderedHeight: 300,
    paddingSide: 3,
    marginTop: 1,
  },
  [ContentSize.landscape]: {
    width: 1200,
    height: 628,
    renderedHeight: 170,
    paddingSide: 3,
    marginTop: 1,
  },
  [ContentSize.story]: {
    width: 1080,
    height: 1920,
    renderedHeight: 420,
    paddingSide: 3,
    marginTop: 1,
  },
  [ContentSize.mobilemedr]: {
    width: 300,
    height: 250,
    renderedHeight: 250,
    paddingSide: 3,
    marginTop: 1,
  },
  [ContentSize.mobileleaderb]: {
    width: 320,
    height: 50,
    renderedHeight: 170,
    paddingSide: 3,
    marginTop: 1,
  },
  [ContentSize.vertical]: {
    width: 1080,
    height: 1350,
    renderedHeight: 350,
    paddingSide: 3,
    marginTop: 1,
  },
  [ContentSize.creative_post]: {
    width: 1080,
    height: 1080,
    renderedHeight: 300,
    paddingSide: 3,
    marginTop: 1,
  },
  [ContentSize.creative_landscape]: {
    width: 1200,
    height: 628,
    renderedHeight: 170,
    paddingSide: 3,
    marginTop: 1,
  },
  [ContentSize.creative_story]: {
    width: 1080,
    height: 1920,
    renderedHeight: 420,
    paddingSide: 3,
    marginTop: 1,
  },
};