import "./Testimonial.scss"

export interface ITestimonialUserProps {
    img?: string
    name?: string
    company?: string
}

export interface ITestimonialProps {
    user?: ITestimonialUserProps
    dark?: boolean
    children: string
}

function Testimonial(props: ITestimonialProps) {
    const { user, dark = false, children } = props

    return (<>
        <figure className={`rounded-xl max-w-lg ${props.dark ? "text-slate-800" : "text-slate-400"}`
        }>
            <blockquote>
                <p>
                   {props.children}
                </p>
            </blockquote>
            <div className="user mt-8 flex gap-6 items-center">
                {user?.img &&
                    <img className="w-12 h-12 rounded-full" src={user.img} alt="John profile" />
                }
                < div className="flex flex-col gap-1/2">
                    <div className={props.dark ? "text-slate-900" : "text-slate-200"}>{user?.name}</div>
                    <div className="company">{user?.company}</div>
                </div>
            </div>
        </figure >
    </>);
}

export default Testimonial;