import { t } from "i18next";
import { FunctionComponent } from "react";
import SvgUploadIcon from "../../assets/IconComponents/UploadIcon";
import { BrandRequest } from "../../services/BrandApi";
import InputText, { InputTextState } from "../../components/InputText";
import SvgAdd from "../../assets/IconComponents/Add";
import { useAppDispatch } from "../../helpers/hooks";
import { setNewBrandInfo } from "../../store/brandSlice";
import { useAppSelector } from "../../helpers/hooks";
import { useState } from "react";
import SvgBookThumbnailIcon from "../../assets/IconComponents/BookThumbnail";
import { Close } from "../../assets/IconComponents";
interface BrandHubLinkToTechnicalDocProps {
  errorOccured: (e: string) => void;
}

const BrandHubLinkToTechnicalDoc: FunctionComponent<
  BrandHubLinkToTechnicalDocProps
> = (props: BrandHubLinkToTechnicalDocProps) => {
  const dispatch = useAppDispatch();
  const brandInfo = useAppSelector((k) => k.brand.newBrand);
  const [newTechnicalLink, setNewTechnicalLink] = useState("");
  const [newLinkButtonClicked, setNewLinkButtonClicked] =
    useState<boolean>(false);

  /*
  const handleAddNewLink = () => {
    if (newTechnicalLink !== "" && !(brandInfo.technicalLinks || []).includes(newTechnicalLink)) {
      setNewLinkButtonClicked(true);
      dispatch(
        setNewBrandInfo({
          ...brandInfo,
          technicalLinks: [
            ...(brandInfo.technicalLinks || []),
            newTechnicalLink,
          ],
        })
      );
    }
  }
 */
  function checkURLValid(str) {
    var urlRegex =
      "^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$";
    var url = new RegExp(urlRegex, "i");
    return str.length < 2083 && url.test(str);
  }

  return (
    <div className="flex flex-col w-full mt-[20px]">
      <p className="text-H8 text-black">
        {t("link-to-your-technical-documentation")}
      </p>
      {brandInfo.technicalLinks?.map((link) => (
        <>
          <div className="flex flex-row">
            <InputText
              value={"link"}
              state={InputTextState.disabled}
              innerClassName="rounded-[4px]"
              forcedBg="bg-white"
            />
            <button
              className="w-16 h-16 mr-5"
              onClick={() => {
                dispatch(
                  setNewBrandInfo({
                    ...brandInfo,
                    technicalLinks: [
                      ...(brandInfo.technicalLinks ?? []).filter(
                        (a) => a !== link
                      ),
                    ],
                  })
                );
              }}
            >
              <div className="h-full flex justify-center items-center">
                <Close
                  width={20}
                  height={20}
                  color="#999999"
                  fill="#999999"
                  className=""
                />
              </div>
            </button>
          </div>
        </>
      ))}
      <div className="flex flex-col md:flex-row items-center">
        <InputText
          placeholder={"https://yourwebsite.io"}
          innerClassName="rounded-[4px]"
          value={newLinkButtonClicked ? "" : undefined}
          onChange={(e) => {
            if (
              !e.toLowerCase().startsWith("http://") &&
              !e.toLowerCase().startsWith("https://")
            ) {
              setNewLinkButtonClicked(false);
              const link = `https://${e}`;
              if (!checkURLValid(link)) {
                props.errorOccured("The URL Is Not In The Correct Format");
                setNewTechnicalLink("");
              } else {
                setNewTechnicalLink(link);
              }
            } else {
              if (!checkURLValid(e)) {
                props.errorOccured("The URL Is Not In The Correct Format");
                setNewTechnicalLink("");
              } else {
                setNewTechnicalLink(e);
              }
            }
          }}
        />
        <button
          //onClick={handleAddNewLink}
          className="flex flex-row border-1 border-border rounded-[5px] h-[40px] items-center justify-center h-[46px] w-full md:w-[246px] mt-2 md:ml-2 pl-1"
        >
          <SvgAdd fill="#121315" className="mr-2" />
          <span className="text-H7 text-black">{t("add-another-link")}</span>
        </button>
      </div>
      <div className="flex items-center justify-center gap-[5px] mt-2 relative">
        <SvgBookThumbnailIcon />
        <p className="relative flex-1 mt-[-0.50px] text-gray text-BodySmall ">
          Knowing technical details will allow Evercopy to prepare expert-level
          contents - just like you.
        </p>
      </div>
    </div>
  );
};

export default BrandHubLinkToTechnicalDoc;
