import { SVGProps } from "react";
import Icon from "../Icon";
import SvgRadioSelected from "../../assets/IconComponents/RadioSelected";
import SvgRadioUnselected from "../../assets/IconComponents/RadioUnselected";
import { IFilterSelectorData } from "./FilterSelector";
import SvgCheckboxSelectedIcon from "../../assets/IconComponents/CheckboxSelectedIcon";
import SvgCheckboxUnchecked from "../../assets/IconComponents/CheckboxUnchecked";


export interface IFilterSelectorItemProps {
  data: IFilterSelectorData;
  selected: boolean;
  itemSelected: (data: IFilterSelectorData) => void;
}

function FilterSelectorItem(props: IFilterSelectorItemProps) {
  const { selected = false } = props;

  return (
    <div
      className={`p-[10px] h-[42px] mt-1 hover:bg-lightBg hover:border-1 hover:border-border cursor-pointer hover:rounded-[5px] flex items-center justify-between
        ${
          selected
            ? "p-[10px] bg-lightBg border-1 border-border rounded-[5px]"
            : ""
        }
        `}
      onClick={() => {
        props.itemSelected(props.data);
      }}
    >
      <div className="flex flex-row items-center">
        <text className="text-BodyMedium text-black ml-2">
          {props.data.title}
        </text>
      </div>
      {selected && <SvgCheckboxSelectedIcon />}
      {!selected && <SvgCheckboxUnchecked />}
    </div>
  );
}

export default FilterSelectorItem;