

import * as React from "react";
const SvgTritoneApplyFiltersLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <rect width={28} height={28} fill="#F55" opacity={0.1} rx={4} />
    <path
      fill="#F55"
      d="M18.205 14.147c-.605.546-1.39.85-2.205.853a3.24 3.24 0 0 1-.53-.045c-.019-.016-.04-.028-.058-.044A5.309 5.309 0 0 1 17.333 19a.667.667 0 0 1-.666.667h4a.667.667 0 0 0 .666-.667 5.33 5.33 0 0 0-3.128-4.853Z"
    />
    <path
      fill="#F55"
      d="M12 15a3.333 3.333 0 1 1 0-6.666A3.333 3.333 0 0 1 12 15Z"
      opacity={0.2}
    />
    <path
      fill="#F55"
      d="M14.205 14.147c-.605.546-1.39.85-2.205.853a3.307 3.307 0 0 1-2.206-.851A5.33 5.33 0 0 0 6.667 19a.667.667 0 0 0 .666.667h9.334a.667.667 0 0 0 .666-.667 5.33 5.33 0 0 0-3.128-4.853Z"
      opacity={0.5}
    />
    <path
      fill="#F55"
      d="M16 8.333c-.753 0-1.441.26-2 .682.232.176.44.382.618.612.156.196.288.41.396.636.028.061.057.121.081.184.065.16.117.326.156.5l.022.123c.036.193.06.392.06.597 0 .21-.024.413-.062.611-.006.03-.008.06-.015.089a3.24 3.24 0 0 1-.151.486c-.024.062-.048.125-.076.186-.056.12-.12.235-.188.347a3.33 3.33 0 0 1-.637.762c.46.21.887.48 1.265.807A3.333 3.333 0 1 0 16 8.334Z"
      opacity={0.5}
    />
  </svg>
);
export default SvgTritoneApplyFiltersLogo;
