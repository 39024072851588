import { t } from "i18next";
import { useAppDispatch } from "../../helpers/hooks";
import { setDialog, toggleDialog } from "../../store/appSlice";
import { useEffect } from "react";
import { Button } from "antd"

export interface IPlanSelectionPopupProps {
    onTrialClicked: () => void
    onPlanSelectionClicked: () => void
}

function PlanSelectionPopup(props: IPlanSelectionPopupProps) {

    return <>
        <div className="fixed lg:top-0 left-0 right-0 bottom-0 z-50 flex flex-col items-center max-lg:justify-end max-lg:mb-1 justify-center h-screen" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div
                className="w-full md:mx-0 md:w-2/5 flex flex-col justify-center bg-white p-6 gap-10 rounded-[10px]"
            >
                <>
                    <div className="items-center flex justify-center">
                        <text className="text-black text-H3 mb-3">
                            {t("choose-a-way")}
                        </text>
                    </div>
                    <div className="w-full flex-col">
                        <div className="flex flex-row w-full gap-[16px] shrink-0">
                            <div className="relative flex flex-col justify-between h-[235px] w-full rounded-[8px] border-1 px-4 pt-7 pb-4 gap-8">
                                <div className="absolute top-[-10px] left-1/2 transform -translate-x-1/2  w-[165px] h-5 px-[3px] py-[6px] bg-white border-1 rounded-[100px] justify-center items-center gap-2.5 inline-flex">
                                    <div className="text-right text-[10px] text-red font-bold font-['Inter Tight'] leading-[14px] tracking-wide">
                                        NO CREDIT CARD REQUIRED
                                    </div>
                                </div>
                                <div className="flex flex-row w-full justify-center h-[58px]">
                                    <text className="text-black text-H4">30 Days of Free Trial</text>
                                </div>
                                <div className="flex flex-col w-full justify-center gap-5">
                                    <text className="text-black text-sm text-center">Full access to all features. Feel free to upgrade your plan at any time.</text>
                                    <Button className="h-[38px]" onClick={props.onTrialClicked}>Start Trial</Button>

                                </div>
                            </div>
                            <div className="relative flex flex-col justify-between h-[235px] w-full rounded-[8px] border-1 border-red px-4 pt-7 pb-4 gap-8">
                                <div className="absolute top-[-10px] left-1/2 transform -translate-x-1/2  w-[165px] h-5 px-[3px] py-[6px] bg-red border-red border-1 rounded-[100px] justify-center items-center gap-2.5 inline-flex">
                                    <div className="text-right text-[10px] text-white font-bold font-['Inter Tight'] leading-[14px] tracking-wide">
                                        ONE TIME OFFER
                                    </div>
                                </div>
                                <div className="flex flex-row w-full justify-center h-[58px]">
                                    <text className="text-black text-H4">%20 Off on All Plans</text>
                                </div>
                                <div className="flex flex-col w-full justify-center gap-5">
                                    <text className="text-black text-sm text-center">Choose a plan now and get one time offer discount.</text>
                                    <Button className="h-[38px]" type="primary" onClick={props.onPlanSelectionClicked}>Choose Plan</Button>

                                </div>
                            </div>
                        </div>
                    </div>

                </>
            </div>
        </div>
    </>
}


function PlanSelectionPopupOpener(props: IPlanSelectionPopupProps) {
    const dispatch = useAppDispatch();
    useEffect(() => { showPlanSelectionPopup(props); }, [])

    const showPlanSelectionPopup = (props: IPlanSelectionPopupProps) => {
        dispatch(setDialog({
            actions: <></>,
            title: "",
            message: "",
            customTemplate: (<PlanSelectionPopup {...props}></PlanSelectionPopup>),
        }))
        dispatch(toggleDialog(true))
    }

    return (<></>)
}

export default PlanSelectionPopupOpener; 