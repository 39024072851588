import React, { Component } from 'react';
import { gantt } from '../../packages/gantt_8';
import './Gantt.css';
import { reformatText } from "../../helpers/Utils";
import dayjs from "dayjs";

var zoomConfig = {
    levels: [
        {
            name:"daily",
            scale_height: 27,
            min_column_width:80,
            scales:[
                {unit: "day", step: 1, format: "%d %M"}
            ]
        },
        {
            name:"weekly",
            scale_height: 50,
            min_column_width:50,
            scales:[
                {unit: "week", step: 1, format: function (date) {
                        var dateToStr = gantt.date.date_to_str("%d %M");
                        var endDate = gantt.date.add(date, 6, "day");
                        var weekNum = gantt.date.date_to_str("%W")(date);
                        return "#" + weekNum + ", " + dateToStr(date) + " - " + dateToStr(endDate);
                    }},
                {unit: "day", step: 1, format: "%j %D"}
            ]
        },
        {
            name:"monthly",
            scale_height: 50,
            min_column_width:120,
            scales:[
                {unit: "month", format: "%F, %Y"},
                {unit: "week", format: "Week #%W"}
            ]
        },
        /*{
            name:"month",
            scale_height: 50,
            min_column_width:120,
            scales:[
                {unit: "month", format: "%F, %Y"},
                {unit: "week", format: "Week #%W"}
            ]
        },
        {
            name:"quarter",
            height: 50,
            min_column_width:90,
            scales:[
                {unit: "month", step: 1, format: "%M"},
                {
                    unit: "quarter", step: 1, format: function (date) {
                        var dateToStr = gantt.date.date_to_str("%M");
                        var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
                        return dateToStr(date) + " - " + dateToStr(endDate);
                    }
                }
            ]},
        {
            name:"year",
            scale_height: 50,
            min_column_width: 30,
            scales:[
                {unit: "year", step: 1, format: "%Y"}
            ]}*/
    ]
};

export default class Gantt extends Component {
    initZoom() {
        gantt.ext.zoom.init({
            levels: [
                /*{
                    name: 'Daily',
                    scale_height: 60,
                    min_column_width: 30,
                    scales: [
                        { unit: 'day', step: 1, format: '%d %M' },
                        { unit: 'hour', step: 1, format: '%H' }
                    ]
                },
                {
                    name: 'Weekly',
                    scale_height: 60,
                    min_column_width: 70,
                    scales: [
                        { unit: 'week', step: 1, format: 'Week #%W' },
                        { unit: 'day', step: 1, format: '%d %M' }
                    ]
                },
                {
                    name: 'Monthly',
                    scale_height: 60,
                    min_column_width: 70,
                    scales: [
                        { unit: "month", step: 1, format: '%F' },
                        { unit: 'week', step: 1, format: '#%W' }
                    ]
                }*/
                {
                    name: 'Daily',
                    scale_height: 60,
                    min_column_width: 70,
                    scales: [
                        { unit: 'week', step: 1, format: 'Week #%W' },
                        { unit: 'day', step: 1, format: '%d %M' }
                    ]
                },
                {
                    name: 'Weekly',
                    scale_height: 60,
                    min_column_width: 130,
                    scales: [
                        { unit: "month", step: 1, format: '%F %Y' },
                        { unit: 'day', step: 7, format: function(date){
                            const val = new Date(date).getTime() + 6*24*60*60*1000
                                return `${dayjs(date).format('DD MMM')} - ${dayjs(val).format('DD MMM')}`;
                            }
                        }
                    ]
                }
                /*{
                    name: 'Months',
                    scale_height: 60,
                    min_column_width: 70,
                    scales: [
                        { unit: "month", step: 1, format: '%F' },
                        { unit: 'week', step: 1, format: '#%W' }
                    ]
                }*/
            ]
        });
    }


    setZoom(value) {
        if(!gantt.$initialized){
            this.initZoom();
        }
        gantt.ext.zoom.setLevel(value);
    }

    setHandleTaskClick(func) {
        gantt.attachEvent("onBeforeLightbox", function(id) {
            var task = gantt.getTask(id);
            if (task.headerType === "CAMPAIGN") {
                func(task)
            }
/*            task.my_template = `<div class="flex border-1 rounded-[6px] p-3">
<div class="px-3"><div style="background: url(${BackgroundImage}); background-size: contain; width: 25px; height: 25px"></div></div>
<div><span style="font-weight: bolder">You’ve ${task.activity} activities within this campaign.</span> You’ll prompt to review and approve campaign activities in the next step.</div>
</div>`;*/
            //return true; for use default item
            return false;
        });
    }

    componentWillMount() {
        gantt.ext.zoom.init(zoomConfig)
    }

    shouldComponentUpdate(nextProps) {
        gantt.sort((a,b) => {
            return dayjs(a.startDate).valueOf() - dayjs(b.startDate).valueOf()
        })
        return this.props.zoom !== nextProps.zoom;
    }

    componentDidUpdate() {
        gantt.sort((a,b) => {
            return dayjs(a.startDate).valueOf() - dayjs(b.startDate).valueOf()
        })
        gantt.render();
        gantt.sort((a,b) => {
            return dayjs(a.startDate).valueOf() - dayjs(b.startDate).valueOf()
        })
    }

    ganttInit() {
        if (!this.props?.isPreview) {
            gantt.config.columns = [
                {name: "text", tree: true, width: 420, label:'Task Name',resize: true},
                {name: "start_date", align: "center",label:'Start Time', width: 240, resize: true},
                {name: "activity", align: "center", label:'Activity', width: 50},
                {name: "duration", align: "center", width: 60},
                {name: "status", align: "center", label:'Status', min_width: 100},
            ];
        } else {
            gantt.config.columns = [
                {name: "text", tree: true, width: 220, resize: true},
                {name: "start_date", align: "center",label:'Start Time', width: 120, resize: true},
                {name: "activity", align: "center", label:'Activity', width: 50},
                {name: "duration", align: "center", width: 60}
            ];
        }
        gantt.config.lightbox.sections = [
            // {name:"description", height:38, map_to:"text", type:"textarea", focus:true},
            {name:"time", height:32, type:"duration", map_to:"auto"},
            {name:"template", height:90, type:"template", map_to:"my_template"},
        ];
        gantt.config.drag_lightbox = false;


        gantt.license = '30676128';
        gantt.locale.labels.section_template = "";
        gantt.locale.labels.section_time = "Timeline";

        gantt.templates.task_class  = function(start, end, task){
            switch (task.text){
                case 'Product Rollout':
                    return "redline";
                    break;
                case 'Feature Unveiling':
                    return "feature-unveiling";
                    break;
                case 'Awareness':
                    return "awareness";
                    break;
                case 'Engagement':
                    return "engagement";
                    break;
                case 'Traffic':
                    return "traffic";
                    break;
                case 'Sales Conversion':
                    return "sales-conversion";
                    break;
                case 'Lead Generation':
                    return "lead-generation";
                    break;
                case 'App Promotion':
                    return "app-promotion";
                    break;
                default:
                    return "redline";
            }
        };

        gantt.templates.grid_row_class  = function(start, end, task, a, s){
            switch (task.text){
                case 'Feature Unveiling':
                    return "feature-unveiling";
                    break;
                case 'Awareness':
                    return "awareness";
                    break;
                case 'Engagement':
                    return "engagement";
                    break;
                case 'Traffic':
                    return "traffic";
                    break;
                case 'Sales Conversion':
                    return "sales-conversion";
                    break;
                case 'Lead Generation':
                    return "lead-generation";
                    break;
                case 'App Promotion':
                    return "app-promotion";
                    break;
                default:
                    return task.headerType === 'campaign' ? "sales-conversion" : "feature-unveiling";
            }
        };


        gantt.sort((a,b) => {
            return dayjs(a.startDate).valueOf() - dayjs(b.startDate).valueOf()
        })


        // console.log(gantt.config.task_attribute)
        // console.log(gantt.skins)
        gantt.skin = 'material'

        gantt.attachEvent("onTaskRowClick", function(id,row){
            //any custom logic here
            console.log('asdasdasdasd')
        });



        gantt.config.date_format = "%Y-%m-%d %H:%i";
        gantt.config.multiselect = false;
        gantt.config.drag_timeline = false;
        gantt.config.drag_mode = false;
        gantt.config.show_links = false;
        /*gantt.templates = {
            grid_folder: task => {
                return 'Rocket'
            }
        }*/
        if (this.props?.isPreview) {
            gantt.config.grid_width = window.innerWidth * 0.22
        } else {
            gantt.config.grid_width = window.innerWidth * 0.32
        }

        gantt.init(this.ganttContainer);
        gantt.clearAll()
        gantt.parse(this.props.tasks);
    }


    componentDidMount() {
        this.ganttInit()
    }

    render() {
        const { zoom, handleTaskClick, setGantt, initGantt } = this.props;
        setGantt(gantt)
        initGantt(initGantt)
        //deleteItem(gantt.deleteTask)
        this.setHandleTaskClick(handleTaskClick)
        {zoom && this.setZoom(zoom)};
        return (
            <div
                ref={(input) => { this.ganttContainer = input }}
                style={{ width: '100%', height: '100%' }}
            ></div>
        );
    }
}
