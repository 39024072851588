import * as React from "react";
import type { SVGProps } from "react";
const SvgCheckboxUnchecked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="1.25"
      width="14.5"
      height="14.5"
      rx="1.25"
      stroke="#EAEBE6"
      strokeWidth="1.5"
    />
  </svg>
);
export default SvgCheckboxUnchecked;
<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.75" y="1.25" width="14.5" height="14.5" rx="1.25" stroke="#EAEBE6" stroke-width="1.5" />
</svg>
