import { PickerModel } from "../models/PickerModel";

export const ContentLanguages: PickerModel[] = [
  {
    key: "English",
    title: "🇬🇧 English",
    value: "0",
  },
  {
    key: "Turkish",
    title: "🇹🇷 Turkish",
    value: "1",
  },
];
