import { PickerModel } from "../models/PickerModel";

export const TonOfVoices: PickerModel[] = [    
    {
        key: "Authoritative",
        title: "Authoritative 👔💼",
        value: "0"
    },
    {
        key: "Friendly",
        title: "Friendly 😊👋",
        value: "1"
    },
    {
        key: "Inspirational",
        title: "Inspirational 🌟✨",
        value: "2"
    },
    {
        key: "Professional/Formal",
        title: "Professional/Formal 👔📊",
        value: "3"
    },
    {
        key: "Humorous",
        title: "Humorous 😄🎭",
        value: "4"
    },
    {
        key: "Educational/Informative",
        title: "Educational/Informative 📚🔍",
        value: "5"
    },
    {
        key: "Playful",
        title: "Playful 🎉🎈",
        value: "6"
    },
    {
        key: "Edgy/Provocative",
        title: "Edgy/Provocative 🔥💥",
        value: "7"
    },
    {
        key: "Empathetic",
        title: "Empathetic 🤗💙",
        value: "8"
    },
    {
        key: "Sustainable/Eco-Friendly",
        title: "Sustainable/Eco-Friendly 🌿🌍",
        value: "9"
    },
    {
        key: "Luxurious",
        title: "Luxurious 💎🍾",
        value: "10"
    },
]






