import * as React from "react";
const LeadGenerationIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={19}
        fill="none"
        {...props}
    >
        <path opacity="0.2"
              d="M10 13.5C12.7614 13.5 15 11.2614 15 8.5C15 5.73858 12.7614 3.5 10 3.5C7.23858 3.5 5 5.73858 5 8.5C5 11.2614 7.23858 13.5 10 13.5Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path opacity="0.5"
              d="M13.309 12.2231C12.426 13.0081 11.275 13.5001 10 13.5001C8.725 13.5001 7.574 13.0081 6.691 12.2231C5.29291 12.8576 4.10708 13.8813 3.27521 15.1717C2.44335 16.4621 2.00064 17.9648 2 19.5001C2 19.7654 2.10536 20.0197 2.29289 20.2073C2.48043 20.3948 2.73478 20.5001 3 20.5001H17C17.2652 20.5001 17.5196 20.3948 17.7071 20.2073C17.8946 20.0197 18 19.7654 18 19.5001C17.9994 17.9648 17.5566 16.4621 16.7248 15.1717C15.8929 13.8813 14.7071 12.8576 13.309 12.2231Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path
            d="M18.3339 13.4999C18.2026 13.5001 18.0725 13.4742 17.9512 13.424C17.8299 13.3737 17.7197 13.2999 17.6269 13.2069L16.2929 11.8739C16.1108 11.6853 16.01 11.4327 16.0122 11.1705C16.0145 10.9083 16.1197 10.6575 16.3051 10.4721C16.4905 10.2867 16.7413 10.1815 17.0035 10.1793C17.2657 10.177 17.5183 10.2778 17.7069 10.4599L18.3329 11.0859L20.2929 9.12593C20.4815 8.94377 20.7341 8.84297 20.9963 8.84525C21.2585 8.84753 21.5093 8.9527 21.6947 9.13811C21.8801 9.32352 21.9853 9.57433 21.9876 9.83652C21.9899 10.0987 21.8891 10.3513 21.7069 10.5399L19.0409 13.2069C18.9482 13.2999 18.838 13.3737 18.7167 13.424C18.5953 13.4742 18.4653 13.5001 18.3339 13.4999Z"
            fill={props.fill ?? "#FFFFFF"}/>
    </svg>
);
export default LeadGenerationIcon;
