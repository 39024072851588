import * as React from "react";
import type { SVGProps } from "react";
const SvgGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#4285F4"
      d="M18.304 10.159c0-.696-.057-1.205-.18-1.732h-7.952v3.144h4.668c-.094.781-.602 1.957-1.732 2.748l-.016.105 2.515 1.948.174.018c1.6-1.478 2.523-3.652 2.523-6.231Z"
    />
    <path
      fill="#34A853"
      d="M10.172 18.442c2.287 0 4.207-.753 5.61-2.052l-2.674-2.07c-.715.498-1.675.846-2.936.846-2.24 0-4.142-1.478-4.82-3.52l-.099.009-2.614 2.023-.035.095a8.465 8.465 0 0 0 7.568 4.669Z"
    />
    <path
      fill="#FBBC05"
      d="M5.353 11.646a5.214 5.214 0 0 1-.283-1.675c0-.584.104-1.148.273-1.676l-.005-.112-2.647-2.056-.087.041a8.479 8.479 0 0 0-.903 3.803c0 1.364.33 2.654.903 3.802l2.749-2.127Z"
    />
    <path
      fill="#EB4335"
      d="M10.172 4.775c1.59 0 2.663.687 3.275 1.262l2.39-2.335C14.37 2.338 12.46 1.5 10.173 1.5a8.465 8.465 0 0 0-7.568 4.668l2.74 2.127c.686-2.042 2.588-3.52 4.828-3.52Z"
    />
  </svg>
);
export default SvgGoogle;
