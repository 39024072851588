import * as React from "react";
import type { SVGProps } from "react";
const SvgViewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill="#999"
      d="M4.376 13.667c-.29 0-.537-.102-.74-.305a1.01 1.01 0 0 1-.303-.74v-4.91c0-.291.102-.538.306-.74a1.01 1.01 0 0 1 .741-.305c.291 0 .538.101.74.304.202.203.303.45.303.74v4.91c0 .291-.102.538-.305.741a1.01 1.01 0 0 1-.742.305Zm4.122 0c-.291 0-.538-.102-.74-.305a1.01 1.01 0 0 1-.303-.74V4.378c0-.29.102-.537.305-.74a1.01 1.01 0 0 1 .742-.305c.29 0 .538.102.74.305.202.203.303.45.303.74v8.244c0 .29-.102.537-.305.74a1.01 1.01 0 0 1-.742.305Zm4.122 0c-.291 0-.538-.102-.74-.305a1.01 1.01 0 0 1-.303-.74v-2.244c0-.29.102-.537.305-.74a1.01 1.01 0 0 1 .742-.305c.29 0 .537.102.74.305.202.203.303.45.303.74v2.244c0 .29-.102.537-.306.74-.203.203-.45.305-.741.305Z"
    />
  </svg>
);
export default SvgViewIcon;
