import { FunctionComponent } from "react";
import { WithContext as ReactTags } from 'react-tag-input';
import { Keyword } from "../../services/KeywordApi";

interface TrendingKeywordSelectorPopupProps {
    width: number
    keywords: Keyword[]
    tagSelected: (tag: Keyword) => void
}

const TrendingKeywordSelectorPopup: FunctionComponent<TrendingKeywordSelectorPopupProps> = (props: TrendingKeywordSelectorPopupProps) => {
    return (
        <>
            <div className="flex flex-col p-[20px] pl-[10px] rounded-[7px] border-border border-1 min-h-[105px] bg-white" style={{ width: props.width }}>
                <p className="text-H8 text-black mb-2 ml-[10px]">Trending Keywords</p>

                <ReactTags
                    tags={props.keywords?.map(k => ({ id: k.id, text: k.name }))}
                    allowDragDrop={false}
                    handleTagClick={(e, l) => {
                        const tag = (props.keywords ?? [])[e]
                        props.tagSelected(tag)
                    }}
                    autofocus={false}
                    readOnly
                />
            </div>
        </>
    );
}

export default TrendingKeywordSelectorPopup;