const RecommendationBar = (props) => {
    console.log(props);
    return (
        <>
            {props.result && <div className={'mb-1'}>
                <span className={'text-sm text-gray'}>{props.result}</span>
            </div>}
            <div className={'flex justify-between'}>
                <div
                    className={`h-[5px] w-1/3 rounded-[3px] mr-1 ${props.level === 1 ? 'bg-red' : 'bg-neutral-200'}`}></div>
                <div
                    className={`h-[5px] w-1/3 rounded-[3px] mr-1 ${props.level === 2 ? 'bg-green' : 'bg-neutral-200'}`}></div>
                <div
                    className={`h-[5px] w-1/3 rounded-[3px] ${props.level === 3 ? 'bg-warn' : 'bg-neutral-200'}`}></div>
            </div>
            {props.recommendedAmount && <div className={'flex justify-between'}>
                <div className={'text-sm text-gray'}>Recommended: {props.recommendedAmount}</div>
                <div className={'text-sm text-gray'}>{props.comment}</div>
            </div>}
        </>
    )
}

export default RecommendationBar;
