import * as React from "react";
import type { SVGProps } from "react";
const GoogleLogo = (props: SVGProps<SVGSVGElement>) => (
    <svg width="66" height="20" viewBox="0 0 66 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1860_10453)">
            <path d="M8.15865 7.13636V9.27273H13.295C13.1586 10.4545 12.7496 11.3636 12.1132 11.9545C11.3859 12.6818 10.2041 13.5 8.15865 13.5C5.02228 13.5 2.56774 10.9545 2.56774 7.81818C2.56774 4.68182 4.97683 2.13636 8.15865 2.13636C9.84047 2.13636 11.1132 2.81818 12.0223 3.68182L13.5223 2.18182C12.2496 0.954545 10.5223 0 8.15865 0C3.84047 0 0.204102 3.5 0.204102 7.81818C0.204102 12.1364 3.84047 15.6364 8.15865 15.6364C10.4768 15.6364 12.2496 14.8636 13.6132 13.4545C15.0223 12.0455 15.4768 10.0455 15.4768 8.45455C15.4768 7.95455 15.4314 7.5 15.3859 7.13636H8.15865Z" fill="#4285F4" />
            <path d="M21.8409 5.36365C19.0682 5.36365 16.75 7.50001 16.75 10.4091C16.75 13.3182 19.0227 15.4546 21.8409 15.4546C24.6591 15.4546 26.9318 13.3636 26.9318 10.4546C26.9318 7.50001 24.6591 5.36365 21.8409 5.36365ZM21.8409 13.5C20.2955 13.5 18.9773 12.2273 18.9773 10.4546C18.9773 8.63637 20.2955 7.4091 21.8409 7.4091C23.3864 7.4091 24.7045 8.63637 24.7045 10.4546C24.7045 12.2273 23.3864 13.5 21.8409 13.5Z" fill="#EA4335" />
            <path d="M46.7041 6.50001H46.6132C46.1132 5.9091 45.1586 5.36365 43.9314 5.36365C41.3859 5.36365 39.2041 7.54547 39.2041 10.4091C39.2041 13.2273 41.3859 15.4546 43.9314 15.4546C45.1586 15.4546 46.1132 14.9091 46.6132 14.3182H46.7041V15.0455C46.7041 16.9546 45.6586 18 44.0223 18C42.6586 18 41.8405 17.0455 41.4768 16.1818L39.5677 17C40.1132 18.3182 41.6132 20 44.0677 20C46.6586 20 48.8859 18.4546 48.8859 14.7273V5.63637H46.795V6.50001H46.7041ZM44.1586 13.5C42.6132 13.5 41.4768 12.1818 41.4768 10.4546C41.4768 8.68183 42.6586 7.4091 44.1586 7.4091C45.6586 7.4091 46.8405 8.72728 46.8405 10.5C46.8859 12.2273 45.7041 13.5 44.1586 13.5Z" fill="#4285F4" />
            <path d="M33.1583 5.36365C30.3856 5.36365 28.0674 7.50001 28.0674 10.4091C28.0674 13.3182 30.3401 15.4546 33.1583 15.4546C35.9765 15.4546 38.2492 13.3636 38.2492 10.4546C38.2492 7.50001 35.9765 5.36365 33.1583 5.36365ZM33.1583 13.5C31.6128 13.5 30.2947 12.2273 30.2947 10.4546C30.2947 8.63637 31.6128 7.4091 33.1583 7.4091C34.7037 7.4091 36.0219 8.63637 36.0219 10.4546C36.0219 12.2273 34.7037 13.5 33.1583 13.5Z" fill="#FBBC05" />
            <path d="M50.5674 0.227295H52.7492V15.5H50.5674V0.227295Z" fill="#34A853" />
            <path d="M59.4766 13.5C58.3402 13.5 57.5675 13 57.022 11.9546L63.7947 9.18183L63.5675 8.59092C63.1584 7.45456 61.8402 5.36365 59.2493 5.36365C56.6584 5.36365 54.4766 7.4091 54.4766 10.4091C54.4766 13.2273 56.6129 15.4546 59.4766 15.4546C61.7947 15.4546 63.1129 14.0455 63.7038 13.2273L61.9766 12.0909C61.3856 12.9546 60.6129 13.5 59.4766 13.5ZM59.3402 7.27274C60.2493 7.27274 61.022 7.72728 61.2493 8.36365L56.7038 10.2727C56.6584 8.18183 58.2038 7.27274 59.3402 7.27274Z" fill="#EA4335" />
        </g>
        <defs>
            <clipPath id="clip0_1860_10453">
                <rect width="63.5909" height="20" fill="white" transform="translate(0.204102)" />
            </clipPath>
        </defs>
    </svg>

);

export default GoogleLogo;