import {t} from "i18next";
import PrimaryButton from "../../../components/PrimaryButton";
import {useAppSelector} from "../../../helpers/hooks";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import {MarketingPlannerResponse, useDeleteEventAndCampaignsMutation} from "../../../services/MarketingPlanner";
import ContentCalendar from "../../ContentCalendar/ContentCalendar";
import {Button, Modal} from "antd";
import {useBlocker, useNavigate} from "react-router-dom";
import {useGenerateContentMutation} from "../../../services/Activity";
import ConfirmIcon from "../../../assets/IconComponents/ConfirmIcon";
import UpgradeIcon from "../../../assets/IconComponents/UpgradeIcon";
import WarningIconRed from "../../../assets/IconComponents/WarningIconRed";

const ReviewActivities = (props) => {
    const navigate = useNavigate()
    const {activities} = useAppSelector((k) => k.activity);
    const [calendarData, setCalendarData] = useState<any[]>()
    const [totalCampaignsCredit, setTotalCampaignsCredit] = useState<number>()
    const [generateContent] = useGenerateContentMutation()

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCloseHappyPath, setIsCloseHappyPath] = useState(false);
    const [isLowCreditModalOpen, setIsLowCreditModalOpen] = useState(false);
    const [closeModal, setCloseModal] = useState(false);
    const [wantToRedirectPath, setWantToRedirectPath] = useState('');
    const [leaveType, setLeaveType] = useState('outOfPage');
    const [deleteEventAndCampaigns] = useDeleteEventAndCampaignsMutation();
    const { savedData } = useAppSelector((k) => k.planner);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    let blocker = useBlocker(
        ({ currentLocation, nextLocation }) => {
            setWantToRedirectPath(nextLocation.pathname)
            if (props.type !== 'event') {
                setLeaveType('outOfPage')
                setCloseModal(!isCloseHappyPath && currentLocation.pathname !== nextLocation.pathname)
            }
            return (props.type !== 'event' && !isCloseHappyPath) && ( currentLocation.pathname !== nextLocation.pathname)
        }
    );

    const handleOk = () => {
        const selectedIds: string[] = [];
        activities?.activityResponseList?.forEach(item => {
            if (item?.id) {
                selectedIds.push(item.id)
            }
        })
        generateContent({body: selectedIds})
        props.onComplate();
        navigate(`/app/content-calendar?success=true`);
    }

    useEffect(() => {
        if (activities) {
            let sum = 0;
            const data = activities?.activityResponseList?.map((item, i) => {
                if (item.credit) {
                    sum += item.credit
                }
                const ganttObj = {
                    ...item,
                    id: item.id,
                    text: `${item.eventType}/${item.campaignType}`,
                    start_date: dayjs(item.startDate).format('YYYY-MM-DD'),
                } as any

                // @ts-ignore
                ganttObj.end_date = dayjs(item.endDate).format('YYYY-MM-DD')

                return ganttObj as MarketingPlannerResponse
            })
            setTotalCampaignsCredit(sum);
            setCalendarData(data)
        }
    }, [activities]);

    const leavePage = () => {
        if (props.type !== 'event') {
            if (activities && activities.activityResponseList && activities.activityResponseList.length) {
                deleteEventAndCampaigns({body: [activities.activityResponseList[0].rootId ?? '']})
            }
            switch(leaveType) {
                case 'back':
                    let hasPaid = false;
                    if(props[props.type === "event" ? "event" : "plan"]?.channels?.length >0 ){

                        props[props.type === "event" ? "event" : "plan"]?.channels?.forEach(channel =>
                            {
                                if (channel === "GOOGLE_ADS" || channel === "META_ADS") {
                                    hasPaid = true
                                }
                            }
                        )
                    }
                    props.onCancel({pageCount: props.type === "event" ? 1 :  (hasPaid ? 2 : 3)  });
                    break;
                case 'outOfPage':
                    setIsCloseHappyPath(true);
                    setTimeout(() => navigate(wantToRedirectPath),100)
                    break;
                default:
                // code block
            }
        } else {
            setTimeout(() => navigate(wantToRedirectPath),100)
        }
    }

    return (
        <div className={'w-full h-full'}>
            <div className={'mb-7 ml-2'}>
                <div className={'text-H4'}>
                    <h2>Review Activities</h2>
                </div>
                <div className={'text-BodySmall mt-2'}>
                    Once you confirm, Evercopy will start creating the creative assets.
                </div>
            </div>
            <div className={'h-[calc(100vh-320px)]'}>
                <ContentCalendar isInReview />
            </div>
            <div className="flex justify-between mt-8">
                <PrimaryButton
                    className="h-10 w-full lg:w-[140px]"
                    noFill
                    title={"Back"}
                    onClick={() => {
                        if (props.type !== 'event') {
                            setLeaveType('back');
                            setCloseModal(true)
                        } else {
                            let hasPaid = false;
                            if (props[props.type === "event" ? "event" : "plan"]?.channels?.length > 0) {
                                props[props.type === "event" ? "event" : "plan"]?.channels?.forEach(channel => {
                                        if (channel === "GOOGLE_ADS" || channel === "META_ADS") {
                                            hasPaid = true
                                        }
                                    }
                                )

                            }
                            props.onCancel({pageCount: props.type === "event" ? 1 : (hasPaid ? 2 : 3)});
                        }
                    }}
                />
                <PrimaryButton
                    className="h-10 w-full lg:w-[150px] lg:ml-2 max-lg:mt-1"
                    title={`Confirm (${ totalCampaignsCredit ?? 0} Credits)`}
                    onClick={() => {
                        if ((props.userCredit ?? 0) < (totalCampaignsCredit ?? 0)) {
                            setIsLowCreditModalOpen(true)
                        } else {
                            setIsModalOpen(true)
                        }
                    }}
                />
            </div>
            <Modal open={isModalOpen} onCancel={handleCancel} closeIcon={false} footer={<div className={'flex justify-center'}>
                <Button size={'large'} type="default" className={'mr-3'} onClick={handleCancel}>Cancel</Button>
                <Button size={'large'} type="primary" onClick={() => {
                    setIsCloseHappyPath(true);
                    setTimeout(()=>handleOk(), 100)
                }}>Yes, Confirm</Button>
            </div>} cancelText={'Back'} okText={'OK'}>
                <div className={'flex justify-center items-center flex-col text-center'}>
                    <ConfirmIcon />
                    <h1 className={'text-H5 text-black mt-3'}>Confirm & Create ({ totalCampaignsCredit ?? 0} Credits)</h1>
                    <p className={'text-gray block mt-3'}>This action will consume credits and cannot be reversed.</p>
                </div>
            </Modal>
            <Modal open={isLowCreditModalOpen} onCancel={() => {
                setIsLowCreditModalOpen(false)
            }} closeIcon={false} footer={<div className={'flex justify-center'}>
                <Button size={'large'} type="primary" onClick={() => {
                    if (activities && activities.activityResponseList && props.type !== 'event') {
                        deleteEventAndCampaigns({body: [activities.activityResponseList[0].rootId ?? '']})
                    } else {
                        deleteEventAndCampaigns({body: savedData?.map(a => a.id ?? '')})
                    }
                    navigate('/app/settings?view=plans')
                }}>View Plans</Button>
            </div>} cancelText={'Back'} okText={'OK'}>
                <div className={'flex justify-center items-center flex-col text-center'}>
                    <UpgradeIcon />
                    <h1 className={'text-H5 text-black mt-3'}>Need More Credits?</h1>
                    <p className={'text-gray block mt-3'}>Upgrade your plan for more credits and never hit a roadblock in your workflow.</p>
                </div>
            </Modal>
            <Modal open={closeModal} onCancel={() => {}} closeIcon={false} footer={<div className={'flex justify-center'}>
                <Button type="default" className={'mr-3'} onClick={() => {
                    leavePage()
                }} size={'large'}>Yes, Delete</Button>
                <Button type="primary" onClick={() => setCloseModal(false)} size={'large'}>Go Back</Button>
            </div>} cancelText={'Back'} okText={'OK'}>
                <div className={'flex justify-center items-center flex-col text-center mb-6'}>
                    <WarningIconRed />
                    <h1 className={'text-H5 text-black mt-3'}>Are you sure? Data will be lost.</h1>
                    <p className={'text-gray block mt-3'}>If you cancel, all campaign details and inputs entered so far will be deleted, and you will need to start from scratch.</p>
                </div>
            </Modal>
        </div>
    )
}

export default ReviewActivities
