import { t } from "i18next";
import ContentContainer from "../../../components/ContentContainer";
import { useEffect, useState } from "react";
import PrimaryButton from "../../../components/PrimaryButton";
import { useAppDispatch } from "../../../helpers/hooks";
import { setEvent, setPlan, setSavedData } from "../../../store/plannerSlice";
import Icon from "../../../components/Icon";
import { Button, Form, InputNumber, Modal, Select, Slider } from "antd";
import IconSelect from "../../../assets/IconComponents/IconSelect";
import {
    Facebook,
    FacebookColor, Google,
    Instagram,
    InstagramColor
} from "../../../assets/IconComponents";
import RecommendationBar from "../../../components/MarketingPlanner/RecommendationBar";
import WarnIcon from "../../../assets/IconComponents/WarnIcon";
import AISmartPlacementsIcon from "../../../assets/IconComponents/AISmartPlacementsIcon";
import { useSaveMarketingPlannerMutation, useSaveCampaignMutation } from "../../../services/MarketingPlanner";
import { setActivities } from "../../../store/activitySlice";
import GoogleAdsColor from "../../../assets/IconComponents/GoogleAdsColor";
import GoogleIcon from "../../../assets/IconComponents/GoogleIcon";
import YoutubeIcon from "../../../assets/IconComponents/YoutubeIcon";
import PlayStoreIcon from "../../../assets/IconComponents/PlayStoreIcon";
import SvgMetaSocialIcon from "../../../assets/IconComponents/MetaSocialIcon";
import { useGetSuggestedBudgetMutation } from "../../../services/AdManagerApi";
import { toggleLoading } from "../../../store/appSlice";

const budgetDistractions = {
    minDailyBudget: 2,
    maxDailyBudget: 1000,
    minTotalBudget: 25,
    maxTotalBudget: 35000,
}

const PaidChannelDetails = (props) => {
    const dispatch = useAppDispatch()
    const [processData, setProcessData] = useState({
        budget: props[props.type === 'event' ? 'event' : 'plan']?.paidProperties?.budget,
        metaAdsDistribution: props[props.type === 'event' ? 'event' : 'plan']?.paidProperties?.budget / 2,
        googleAdsDistribution: props[props.type === 'event' ? 'event' : 'plan']?.paidProperties?.budget / 2,
        budgetType: props[props.type === 'event' ? 'event' : 'plan']?.paidProperties?.budgetType ?? 'DAILY_BUDGET'
    })
    const [getSuggestedBudget, { data: reachEstimate, isLoading: reachEstimateLoading }] = useGetSuggestedBudgetMutation()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [save2, { data: createdData, isLoading: createIsLoading }] = useSaveMarketingPlannerMutation()
    const [saveCampaign] = useSaveCampaignMutation()
    const [approximateData, setApproximateData] = useState<any>({})
    const [showBudgetDistribution, setShowBudgetDistribution] = useState<boolean>(false)
    const [metaAdsDistributionRate, setMetaAdsDistributionRate] = useState(50)
    const [googleAdsDistributionRate, setGoogleAdsDistributionRate] = useState(50)

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);

        if (props.type === 'event') {
            props.slidePageProgressBar();
            dispatch(setEvent({
                ...props.event,
                paidProperties: {
                    budget: processData.budget,
                    budgetType: processData.budgetType,
                    budgetDistribution: {
                        metaAdsDistribution: processData.metaAdsDistribution,
                        googleAdsDistribution: processData.googleAdsDistribution
                    },
                    placements: []
                }
            }))
            save2({
                marketingPlannerRequest: {
                    ...props.event,
                    paidProperties: {
                        budget: processData.budget,
                        budgetType: processData.budgetType,
                        budgetDistribution: {
                            metaAdsDistribution: processData.metaAdsDistribution,
                            googleAdsDistribution: processData.googleAdsDistribution
                        },
                        placements: []
                    }
                }
            }).then(aa => {
                dispatch(setSavedData(aa))
                dispatch(toggleLoading(false));
                console.log(aa)
                props.onComplate({ pageCount: 1 });
                props.stopPageProgressBar();
            })
        } else {
            props.slidePageProgressBar();
            dispatch(setPlan({
                ...props.plan,
                paidProperties: {
                    budget: processData.budget,
                    budgetType: processData.budgetType,
                    budgetDistribution: {
                        metaAdsDistribution: processData.metaAdsDistribution,
                        googleAdsDistribution: processData.googleAdsDistribution
                    },
                    placements: []
                }
            }))
            saveCampaign({
                marketingPlannerRequest: {
                    ...props.plan,
                    paidProperties: {
                        budget: processData.budget,
                        budgetType: processData.budgetType,
                        budgetDistribution: {
                            metaAdsDistribution: processData.metaAdsDistribution,
                            googleAdsDistribution: processData.googleAdsDistribution
                        },
                        placements: []
                    }
                }
            }).then(activities => {
                console.log(activities)
                // @ts-ignore
                dispatch(setActivities(activities.data))
                props.onComplate({ pageCount: 2 });
                props.stopPageProgressBar();
            })
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [channels, setChannels] = useState([
        { key: 'facebook', label: 'Facebook', services: ['News Feed', 'Stories'], icon: Facebook, colorIcon: FacebookColor },
        { key: 'instagram', label: 'Instagram', services: ['News Feed', 'Explore', 'Stories'], icon: Instagram, colorIcon: InstagramColor },
        { key: 'google', label: 'Google', services: ['Search', 'Display'], icon: Google, colorIcon: GoogleAdsColor },
    ])

    const options = [
        {
            value: 'DAILY_BUDGET',
            label: 'Daily Budget',
        },
        {
            value: 'TOTAL_BUDGET',
            label: 'Total Budget',
        },
    ];

    useEffect(() => {
        getEstimateData({ budgetType: 'DAILY_BUDGET' });
    }, []);


    const getEstimateData = (param) => {
        dispatch(toggleLoading(true))
        getSuggestedBudget({
            suggestedBudgetRequest: {
                ...props[props.type === 'event' ? 'event' : 'plan'].audience,
                budget_type: param.budgetType,
                final_url: props[props.type === 'event' ? 'event' : 'plan'].productAboutUrl,
                event_type: props.type === 'event' ? props['event'].eventType : null,
                product_type: props[props.type === 'event' ? 'event' : 'plan'].productAbout,
                paid_channel_count: props[props.type === 'event' ? 'event' : 'plan'].channels?.filter(channel => {
                    if (channel === "GOOGLE_ADS" || channel === "META_ADS") {
                        return true;
                    } else {
                        return false;
                    }
                }
                ).length
            }
        }).then(data => {
            // @ts-ignore
            setEstimateData(data?.data)
            dispatch(toggleLoading(false))
        })
    }

    const setEstimateData = (data) => {
        const recommendedLevel = data?.dailySuggestedBudget * 1.2 < processData.budget ? 3 : (data?.dailySuggestedBudget * 0.8 >= (processData.budget) ? 1 : 2);
        const comment = data?.dailySuggestedBudget * 1.2 < (processData.budget) ? 'high' : (data?.dailySuggestedBudget * 0.8 >= (processData.budget) ? 'low' : 'optimum')

        const totalRecommendedLevel = data?.totalSuggestedBudget * 1.2 < processData.budget ? 3 : (data?.totalSuggestedBudget * 0.8 >= (processData.budget) ? 1 : 2);
        const totalComment = data?.totalSuggestedBudget * 1.2 < (processData.budget) ? 'high' : (data?.totalSuggestedBudget * 0.8 >= (processData.budget) ? 'low' : 'optimum')

        console.log(`${processData.budget}  ${data?.totalSuggestedBudget}  ${totalRecommendedLevel}`)

        setApproximateData({
            daily: { recommendedAmount: data?.dailySuggestedBudget, recommendedLevel: recommendedLevel, comment: comment },
            total: { recommendedAmount: data?.totalSuggestedBudget, recommendedLevel: totalRecommendedLevel, comment: totalComment }
        })
    }

    const handleForm = (field, value) => {
        setProcessData(prevState => ({ ...prevState, [field]: value }))
        if (field === 'metaAdsDistribution') {
            setProcessData(prevState => ({ ...prevState, ['googleAdsDistribution']: processData.budget - value }))
        }
        if (field === 'googleAdsDistribution') {
            setProcessData(prevState => ({ ...prevState, ['metaAdsDistribution']: processData.budget - value }))
        }
    }

    useEffect(() => {
        if (approximateData && approximateData.daily && approximateData.total) {
            setEstimateData({ dailySuggestedBudget: approximateData?.daily.recommendedAmount, totalSuggestedBudget: approximateData?.total.recommendedAmount })
        }
    }, [processData])

    useEffect(() => {
        setShowBudgetDistribution(props[props.type === 'event' ? 'event' : 'plan'].channels.filter((a) => a === 'GOOGLE_ADS')?.length && props[props.type === 'event' ? 'event' : 'plan'].channels.filter((a) => a === 'META_ADS')?.length)
    }, []);

    return (
        <div className={'w-7/12'}>
            <div className={'mb-7 ml-2'}>
                <div className={'text-H4'}>
                    <h2>Paid Channel Details</h2>
                </div>
                <div className={'text-BodySmall mt-2'}>
                    Define your budget and target outreach for each selected paid channels.
                </div>
            </div>
            <ContentContainer
                ref={props.ref}
                className={`w-full`}
                hoverable={false}
                children={
                    <>
                        <div className="flex flex-col w-full p-[20px]">
                            <Form.Item label={<p className={'text-H8 text-black'}>Budget</p>}
                                labelCol={{ span: 9 }} labelAlign={'left'} tooltip="Enter your total or daily budget for paid advertising. This amount will be distributed across the selected channels based on your preferences.">
                                <div className={'flex'}>
                                    <div className={'w-[35%]'}>
                                        <Select
                                            suffixIcon={<IconSelect />}
                                            defaultValue={processData !== undefined ? processData.budgetType : "DAILY_BUDGET"}
                                            options={options}
                                            size="large"
                                            onChange={(val) => {
                                                console.log(val)
                                                handleForm('budgetType', val)
                                            }}
                                            className={'w-full'}
                                        />
                                    </div>
                                    <div className={'w-[65%] pl-3'}>
                                        <InputNumber
                                            status={(processData.budgetType === 'DAILY_BUDGET' && processData.budget < budgetDistractions.minDailyBudget) || (processData.budgetType === 'DAILY_BUDGET' && processData.budget > budgetDistractions.maxDailyBudget) || (processData.budgetType !== 'DAILY_BUDGET' && processData.budget < budgetDistractions.minTotalBudget) || (processData.budgetType !== 'DAILY_BUDGET' && processData.budget > budgetDistractions.maxTotalBudget) ? 'error' : ''}
                                            addonBefore="$"
                                            min={0}
                                            placeholder="Enter Budget"
                                            className={'w-full'}
                                            size="large"
                                            onChange={(val) => {
                                                handleForm('budget', val)
                                                setProcessData(prevState => ({
                                                    ...prevState,
                                                    ['googleAdsDistribution']: val * googleAdsDistributionRate / 100
                                                }))
                                                setProcessData(prevState => ({
                                                    ...prevState,
                                                    ['metaAdsDistribution']: val * metaAdsDistributionRate / 100
                                                }))
                                            }}
                                            value={processData.budget}
                                        />
                                        {processData.budget && approximateData[processData.budgetType === 'DAILY_BUDGET' ? 'daily' : 'total']?.recommendedLevel ? (
                                            <div className={'mt-2'}>
                                                <RecommendationBar level={approximateData[processData.budgetType === 'DAILY_BUDGET' ? 'daily' : 'total']?.recommendedLevel}
                                                    recommendedAmount={approximateData[processData.budgetType === 'DAILY_BUDGET' ? 'daily' : 'total']?.recommendedAmount}
                                                    comment={approximateData[processData.budgetType === 'DAILY_BUDGET' ? 'daily' : 'total']?.comment} />
                                            </div>
                                        ) : <div className={'text-sm text-gray'}>Recommended: {approximateData[processData.budgetType === 'DAILY_BUDGET' ? 'daily' : 'total']?.recommendedAmount}</div>}
                                        {processData.budget > 0 && ((processData.budgetType === 'DAILY_BUDGET' && processData.budget < budgetDistractions.minDailyBudget) || (processData.budgetType === 'DAILY_BUDGET' && processData.budget > budgetDistractions.maxDailyBudget) || (processData.budgetType !== 'DAILY_BUDGET' && processData.budget < budgetDistractions.minTotalBudget) || (processData.budgetType !== 'DAILY_BUDGET' && processData.budget > budgetDistractions.maxTotalBudget)) && (
                                            <p style={{ color: "red" }}>{`Min ${processData.budgetType === 'DAILY_BUDGET' ? '2' : '25'} USD, Max ${processData.budgetType === 'DAILY_BUDGET' ? '1.000' : '35.000'} USD`}</p>
                                        )}
                                    </div>
                                </div>
                            </Form.Item>
                            {(processData.budget && showBudgetDistribution) ?
                                <Form.Item label={<p className={'text-H8 text-black'}>Budget Distribution</p>}
                                    labelCol={{ span: 9 }} labelAlign={'left'}
                                    tooltip="AI pre-sets budget allocation based on channel performance data and your campaign's target audience. You can fine-tune the distribution using the sliders to match your strategic priorities.">
                                    <div>
                                        <div className={'w-full flex justify-between items-center'}>
                                            <div className="flex flex-row items-center">
                                                <Icon width="20" height="20" viewBox="0 0 20 20"
                                                    component={GoogleAdsColor}
                                                    color="#999999" className="mr-1 w-[20px] " />
                                                <Icon width="20" height="20" viewBox="0 0 20 20"
                                                    component={GoogleIcon}
                                                    color="#999999" className="mr-1 w-[20px] " />
                                                <Icon width="20" height="20" viewBox="0 0 20 20"
                                                    component={YoutubeIcon}
                                                    color="#999999" className="mr-1 w-[20px] " />
                                                <Icon width="20" height="20" viewBox="0 0 20 20"
                                                    component={PlayStoreIcon}
                                                    color="#999999" className="mr-1 w-[20px] " />
                                                <div className="text-BodySmall">Google Ads</div>
                                            </div>

                                            <div className={'flex items-center'}>
                                                <span>${processData.googleAdsDistribution?.toFixed(1) || processData.googleAdsDistribution}</span>
                                                <InputNumber
                                                    step="1"
                                                    className={'ml-2 w-[110px]'}
                                                    min={0}
                                                    max={100}
                                                    formatter={(value) => `%${value}`}
                                                    parser={(value) => {
                                                        if (value) {
                                                            return Number(Number(value.replace('%', '')).toFixed(1))
                                                        } else {
                                                            return 50
                                                        }
                                                    }}
                                                    value={googleAdsDistributionRate}
                                                    onChange={(e) => {
                                                        if (e) {
                                                            let newVal: number = e;
                                                            if (e.toString().includes('.')) {
                                                                newVal.toFixed(1)
                                                            }

                                                            setGoogleAdsDistributionRate(newVal)
                                                            setMetaAdsDistributionRate(100 - newVal)
                                                            const val1 = processData.budget * newVal / 100
                                                            handleForm('googleAdsDistribution', Number(val1.toFixed(1)))
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <Slider
                                            styles={{
                                                handle: {
                                                    border: '12px solid #f03d30',
                                                    borderRadius: '50%',
                                                    top: '0'
                                                }
                                            }}
                                            min={0}
                                            max={processData.budget}
                                            step={processData.budget / 100}
                                            onChange={(e) => {
                                                let newVal: number = e;
                                                if (e.toString().includes('.')) {
                                                    newVal.toFixed(1)
                                                }
                                                handleForm('googleAdsDistribution', newVal)
                                                const val1 = (processData.budget - newVal) / (processData.budget / 100)
                                                const val2 = newVal / (processData.budget / 100)
                                                setMetaAdsDistributionRate(Number(val1.toFixed(1)))
                                                setGoogleAdsDistributionRate(Number(val2.toFixed(1)))
                                            }}
                                            value={processData.googleAdsDistribution}
                                        />

                                        <div className={'flex justify-between items-center'}>
                                            <div className="flex flex-row items-center">
                                                <Icon width="20" height="20" viewBox="0 0 20 20"
                                                    component={SvgMetaSocialIcon}
                                                    color="#999999" className="mr-1 w-[20px] " />
                                                <Icon width="20" height="20" viewBox="0 0 20 20"
                                                    component={FacebookColor}
                                                    color="#999999" className="mr-1 w-[20px] " />
                                                <Icon width="20" height="20" viewBox="0 0 20 20"
                                                    component={InstagramColor}
                                                    color="#999999" className="mr-1 w-[20px] " />

                                                <div className="text-BodySmall">Meta Ads</div>
                                            </div>


                                            <div className={'flex items-center'}>
                                                <span>${processData.metaAdsDistribution?.toFixed(1) || processData.metaAdsDistribution}</span>
                                                <InputNumber
                                                    step="1"
                                                    min={0}
                                                    max={100}
                                                    formatter={(value) => `%${value}`}
                                                    parser={(value) => {
                                                        if (value) {
                                                            return Number(Number(value.replace('%', '')).toFixed(1))
                                                        } else {
                                                            return 50
                                                        }
                                                    }}
                                                    className={'ml-2 w-[110px]'}
                                                    value={metaAdsDistributionRate}
                                                    onChange={(e) => {
                                                        if (e) {
                                                            let newVal: number = e;
                                                            if (e.toString().includes('.')) {
                                                                newVal.toFixed(1)
                                                            }
                                                            setMetaAdsDistributionRate(newVal)
                                                            setGoogleAdsDistributionRate(100 - newVal)
                                                            const val1 = processData.budget * newVal / 100
                                                            handleForm('metaAdsDistribution', Number(val1.toFixed(1)))
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <Slider
                                            styles={{
                                                handle: {
                                                    border: '12px solid #f03d30',
                                                    borderRadius: '50%',
                                                    top: '0'
                                                }
                                            }}
                                            min={0}
                                            max={processData.budget}
                                            step={processData.budget / 100}
                                            onChange={(e) => {
                                                let newVal: number = e;
                                                if (e.toString().includes('.')) {
                                                    newVal.toFixed(1)
                                                }
                                                const val1 = (processData.budget - newVal) / (processData.budget / 100)
                                                const val2 = newVal / (processData.budget / 100)
                                                handleForm('metaAdsDistribution', newVal)
                                                setGoogleAdsDistributionRate(Number(val1.toFixed(1)))
                                                setMetaAdsDistributionRate(Number(val2.toFixed(1)))
                                            }}
                                            value={processData.metaAdsDistribution}
                                        />
                                    </div>
                                </Form.Item> : <></>}
                            <Form.Item label={<p className={'text-H8 text-black'}>Ad Placement</p>}
                                labelCol={{ span: 9 }} labelAlign={'left'}
                                tooltip="Explore and select specific ad placements within each channel, such as news feeds, sidebars, or in-stream videos, to enhance ad visibility.">
                                <div className={'w-[62%] ml-[38%] mt-[-30px]'}>
                                    <div className={'border-1 rounded-[4px] p-3'}>
                                        <div className="text-H8 text-black mb-2 w-5/12 flex items-center"
                                            title={''}
                                        >
                                            AI Smart Placements
                                            <Icon
                                                width="20"
                                                height="20"
                                                component={AISmartPlacementsIcon}
                                                viewBox="0 0 20 20"
                                                className="ml-1 inline-block"
                                            />
                                        </div>
                                        <div className={'text-sm text-gray'}>
                                            Our smart AI choose the best placements across ad networks to show your
                                            ads
                                            to more people as possible.
                                        </div>
                                        {channels.map((channel, index) => (
                                            <div
                                                className={`h-[42px] mt-2 flex items-center p-2 justify-between border-1 rounded-[4px]`}>
                                                <div className="flex flex-row items-center">
                                                    <Icon width="15" height="15" viewBox="0 0 20 20"
                                                        component={channel.colorIcon}
                                                        color="#999999" className="mr-1 w-[20px] " />
                                                    <text
                                                        className="text-BodyMedium text-black ml-2">{channel.label}</text>
                                                </div>
                                                <div className={'text-sm text-gray'}>
                                                    {channel.services.map((service, i) => (
                                                        <span>{i !== 0 ? ', ' : ''}{service}</span>))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Form.Item>
                        </div>
                    </>
                }
            />
            <div className="flex justify-between mt-3">
                <PrimaryButton
                    className="h-10 w-full lg:w-[140px]"
                    noFill
                    title={"Back"}
                    onClick={() => {
                        props.onCancel();
                    }}
                />
                <PrimaryButton
                    className="h-10 w-full lg:w-[140px] lg:ml-2 max-lg:mt-1"
                    title={t("continue")}
                    isDisabled={!processData.budget}
                    onClick={() => {
                        if (processData.metaAdsDistribution === 0 || processData.googleAdsDistribution === 0) {
                            showModal();
                            return;
                        }
                        handleOk();
                    }}
                />
            </div>

            <Modal
                open={isModalOpen}
                closeIcon={false}
                centered
                width={400}
                footer={() => (<div className={'flex w-full justify-center'}>
                    <Button className={'mr-3'} size={'large'} onClick={handleCancel}>Back</Button>
                    <Button danger type={'primary'} size={'large'} onClick={handleOk}>{t("continue")}</Button>
                </div>)}
            >
                <div className={'flex justify-center items-center flex-col text-center p-3'}>
                    <WarnIcon />
                    <h1 className={'text-H5 text-black mt-3'}>Heads Up: Only 1 channel is activated</h1>
                    <p className={'text-gray block mt-3'}>You've allocated 0% of the budget to one of the paid
                        channels you've selected earlier. No activities will be prepared and executed.</p>
                </div>
            </Modal>
        </div>
    )
}

export default PaidChannelDetails
