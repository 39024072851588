import { PickerModel } from "../models/PickerModel";

export const TextToSpeechFileFormats: PickerModel[] = [
  {
    key: "mp3",
    title: "Mp3 (Popular for music streaming.)",
    value: "mp3",
  },
  {
    key: "wav",
    title: "WAW (Uncompressed, low latency.)",
    value: "wav",
  },
  {
    key: "opus",
    title: "Opus (Low latency streaming.)",
    value: "opus",
  },
  {
    key: "aac",
    title: "AAC (Used by YouTube, Android, iOS.)",
    value: "aac",
  },
  {
    key: "flac",
    title: "FLAC (Lossless, ideal for archiving.)",
    value: "flac",
  },
];
