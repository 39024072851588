import * as React from "react";
const SvgGoogleIcon = (props) => (
  
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_9432_12164"  style={{
            maskType: "luminance",
          }} maskUnits="userSpaceOnUse" x="3" y="3" width="14" height="14">
<path d="M16.5227 8.72727H10V11.4318H13.7545C13.4045 13.15 11.9409 14.1364 10 14.1364C7.70909 14.1364 5.86364 12.2909 5.86364 10C5.86364 7.70909 7.70909 5.86364 10 5.86364C10.9864 5.86364 11.8773 6.21364 12.5773 6.78636L14.6136 4.75C13.3727 3.66818 11.7818 3 10 3C6.11818 3 3 6.11818 3 10C3 13.8818 6.11818 17 10 17C13.5 17 16.6818 14.4545 16.6818 10C16.6818 9.58636 16.6182 9.14091 16.5227 8.72727Z" fill="white"/>
</mask>
<g mask="url(#mask0_9432_12164)">
<path d="M2.36328 14.1364V5.86365L7.77237 10L2.36328 14.1364Z" fill="#FBBC05"/>
</g>
<mask id="mask1_9432_12164" style={{
            maskType: "luminance",
          }} maskUnits="userSpaceOnUse" x="3" y="3" width="14" height="14">
<path d="M16.5227 8.72727H10V11.4318H13.7545C13.4045 13.15 11.9409 14.1364 10 14.1364C7.70909 14.1364 5.86364 12.2909 5.86364 10C5.86364 7.70909 7.70909 5.86364 10 5.86364C10.9864 5.86364 11.8773 6.21364 12.5773 6.78636L14.6136 4.75C13.3727 3.66818 11.7818 3 10 3C6.11818 3 3 6.11818 3 10C3 13.8818 6.11818 17 10 17C13.5 17 16.6818 14.4545 16.6818 10C16.6818 9.58636 16.6182 9.14091 16.5227 8.72727Z" fill="white"/>
</mask>
<g mask="url(#mask1_9432_12164)">
<path d="M2.36328 5.86365L7.77237 10L9.99964 8.0591L17.636 6.81819V2.36365H2.36328V5.86365Z" fill="#EA4335"/>
</g>
<mask id="mask2_9432_12164"  style={{
            maskType: "luminance",
          }} maskUnits="userSpaceOnUse" x="3" y="3" width="14" height="14">
<path d="M16.5227 8.72727H10V11.4318H13.7545C13.4045 13.15 11.9409 14.1364 10 14.1364C7.70909 14.1364 5.86364 12.2909 5.86364 10C5.86364 7.70909 7.70909 5.86364 10 5.86364C10.9864 5.86364 11.8773 6.21364 12.5773 6.78636L14.6136 4.75C13.3727 3.66818 11.7818 3 10 3C6.11818 3 3 6.11818 3 10C3 13.8818 6.11818 17 10 17C13.5 17 16.6818 14.4545 16.6818 10C16.6818 9.58636 16.6182 9.14091 16.5227 8.72727Z" fill="white"/>
</mask>
<g mask="url(#mask2_9432_12164)">
<path d="M2.36328 14.1364L11.9087 6.81819L14.4224 7.13637L17.636 2.36365V17.6364H2.36328V14.1364Z" fill="#34A853"/>
</g>
<mask id="mask3_9432_12164" style={{
            maskType: "luminance",
          }} maskUnits="userSpaceOnUse" x="3" y="3" width="14" height="14">
<path d="M16.5227 8.72727H10V11.4318H13.7545C13.4045 13.15 11.9409 14.1364 10 14.1364C7.70909 14.1364 5.86364 12.2909 5.86364 10C5.86364 7.70909 7.70909 5.86364 10 5.86364C10.9864 5.86364 11.8773 6.21364 12.5773 6.78636L14.6136 4.75C13.3727 3.66818 11.7818 3 10 3C6.11818 3 3 6.11818 3 10C3 13.8818 6.11818 17 10 17C13.5 17 16.6818 14.4545 16.6818 10C16.6818 9.58636 16.6182 9.14091 16.5227 8.72727Z" fill="white"/>
</mask>
<g mask="url(#mask3_9432_12164)">
<path d="M17.6364 17.6364L7.77273 10L6.5 9.04547L17.6364 5.86365V17.6364Z" fill="#4285F4"/>
</g>
</svg>

);
export default SvgGoogleIcon;
