import * as React from "react";
import type { SVGProps } from "react";
const SvgLandscapeImageSizeColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={70}
    height={50}
    viewBox="0 0 70 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect opacity="0.1" width="70" height="50" rx="8" fill="#FF5555" />
    <path
      opacity="0.35"
      d="M27.5833 16.3335H12.4166C10.6217 16.3335 9.16663 17.7886 9.16663 19.5835V30.4168C9.16663 32.2118 10.6217 33.6668 12.4166 33.6668H27.5833C29.3782 33.6668 30.8333 32.2118 30.8333 30.4168V19.5835C30.8333 17.7886 29.3782 16.3335 27.5833 16.3335Z"
      fill="#FF5555"
    />
    <path
      d="M25.4167 22.8333C26.6133 22.8333 27.5833 21.8633 27.5833 20.6667C27.5833 19.47 26.6133 18.5 25.4167 18.5C24.22 18.5 23.25 19.47 23.25 20.6667C23.25 21.8633 24.22 22.8333 25.4167 22.8333Z"
      fill="#FF5555"
    />
    <path
      d="M11.3334 29.3333C11.3334 29.6207 11.4475 29.8962 11.6507 30.0994C11.8538 30.3025 12.1294 30.4167 12.4167 30.4167H27.5834C27.8707 30.4167 28.1462 30.3025 28.3494 30.0994C28.5526 29.8962 28.6667 29.6207 28.6667 29.3333C28.6667 27.8557 26.838 26.0833 25.9584 26.0833C25.0787 26.0833 24.2814 27.1667 22.1667 27.1667C19.5212 27.1667 17.8334 21.75 15.6667 21.75C13.5 21.75 11.3334 26.4755 11.3334 29.3333Z"
      fill="#FF5555"
    />
    <path
      d="M59.375 21.375H37.625C36.728 21.375 36 20.6481 36 19.75C36 18.8519 36.728 18.125 37.625 18.125H59.375C60.272 18.125 61 18.8519 61 19.75C61 20.6481 60.272 21.375 59.375 21.375Z"
      fill="#FF5555"
    />
    <path
      d="M59.375 26.625H37.625C36.728 26.625 36 25.8981 36 25C36 24.1019 36.728 23.375 37.625 23.375H59.375C60.272 23.375 61 24.1019 61 25C61 25.8981 60.272 26.625 59.375 26.625Z"
      fill="#FF5555"
    />
    <path
      d="M44.125 31.875H37.625C36.728 31.875 36 31.1481 36 30.25C36 29.3519 36.728 28.625 37.625 28.625H44.125C45.022 28.625 45.75 29.3519 45.75 30.25C45.75 31.1481 45.022 31.875 44.125 31.875Z"
      fill="#FF5555"
    />
  </svg>
);
export default SvgLandscapeImageSizeColorIcon;
