import * as React from "react";
import type { SVGProps } from "react";
const SvgFilters = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M13.542 2.083a1.877 1.877 0 0 0-1.875 1.875v.209H2.292a.625.625 0 1 0 0 1.25h9.375v.208c0 1.034.84 1.875 1.875 1.875a1.877 1.877 0 0 0 1.875-1.875v-.208h2.291a.625.625 0 1 0 0-1.25h-2.291v-.209a1.877 1.877 0 0 0-1.875-1.875ZM6.458 7.292a1.877 1.877 0 0 0-1.875 1.875v.208H2.292a.625.625 0 1 0 0 1.25h2.291v.208c0 1.034.842 1.875 1.875 1.875a1.877 1.877 0 0 0 1.875-1.875v-.208h9.375a.624.624 0 1 0 0-1.25H8.333v-.208a1.877 1.877 0 0 0-1.875-1.875Zm6.25 5.208a1.877 1.877 0 0 0-1.875 1.875v.208H2.292a.626.626 0 1 0 0 1.25h8.541v.209c0 1.033.841 1.875 1.875 1.875a1.877 1.877 0 0 0 1.875-1.875v-.209h3.125a.624.624 0 1 0 0-1.25h-3.125v-.208a1.877 1.877 0 0 0-1.875-1.875Z" />
  </svg>
);
export default SvgFilters;
