import { Add } from "../../assets/IconComponents";
import { SVGProps, useEffect, useState } from "react";
import {Popover} from "antd";

export enum ButtonState {
    normal,
    focus,
    hover
}

export interface ILeftMenuButtonProps {
  title: string;
  onClick: () => void;
  className?: string;
  icon: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | undefined;
  deactiveColor?: string;
  state?: ButtonState;
  textColor?: string;
  collapsedMode?: boolean;
  collapsedModeIcon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  tooltip? : string
}
function LeftMenuButton(props: ILeftMenuButtonProps) {
    const { icon: IconComp = Add, collapsedModeIcon: IconComp1 = Add} = props

    const [localState, setLocalState] = useState<ButtonState>(ButtonState.normal)

    useEffect(() => {
        setLocalState(props.state ?? ButtonState.normal)
    }, [props.state])

    return (
      <>
        <div
          onClick={(e) => {
            //setLocalState(ButtonState.focus)
            e.stopPropagation();
            props.onClick();
          }}
        >
          {!(props.collapsedMode ?? false) && (
            <button
              className={`
            bg-black
            border-l-2 border-l-black
            focus:bg-black
            hover:bg-white/[0.1] 
            h-[50px]
             pl-[10px]
             ${
               localState === ButtonState.focus
                 ? "focus !border-l-red "
                 : localState === ButtonState.hover
                 ? "hover"
                 : "!border-l-black"
             }
             ${props.className}
            `}
              onMouseEnter={() => {
                if (localState != ButtonState.focus)
                  setLocalState(ButtonState.hover);
              }}
              onMouseLeave={() => {
                if (localState != ButtonState.focus)
                  setLocalState(ButtonState.normal);
              }}
              title={props.title}
            >
                    <div className="flex flex-row items-center">
                        <IconComp
                            fill={
                                localState === ButtonState.focus
                                    ? "#ffffff"
                                    : props.deactiveColor ?? "#ffffff"
                            }
                            className="w-[20px] h-[20px]"
                        />
                        <p
                            className={`${
                                props.textColor ? props.textColor : "text-gray"
                            } w-full lg:w-[140px] ml-[15px] whitespace-nowrap text-left font-inner-tight text-BodyMedium text-ellipsis overflow-hidden`}
                        >
                            {props.title}
                        </p>
                    </div>

            </button>
          )}

            {(props.collapsedMode ?? false) && (
                <Popover content={props.title} placement="right">
                <div className="flex  items-center w-full h-[50px] pl-1">
                    <IconComp1
                        fill={
                            localState === ButtonState.focus
                                ? "#ffffff"
                                : props.deactiveColor ?? "#ffffff"
                        }
                        className="w-[40px] h-[40px]"
                        height={40}
                        width={40}
                    />
                </div>
                </Popover>
            )}
        </div>
      </>
    );
}

export default LeftMenuButton;
