import "../../styles/index.css";
import { redirect, useNavigate } from "react-router-dom";

import Testimonials from "./Testimonials";
import Features from "./Features";
import Footer from "./Footer";
import Main from "./Main";
import Navigation from "./Navigation";
import PR from "./PR";
import { useEffect } from "react";

function LandingPage() {
    const navigate = useNavigate()
    
    useEffect(() => {
        navigate("/app/homepage");
    }, [])

    return (
        <>
            <div className="flex h-full flex-col">
                <Navigation />
                <Main />
                <Features className="mt-40" />

                <PR className="mt-40" />

                <Testimonials className="mt-40" />

                <Footer className="mt-40" />
            </div>
        </>
    );
}

export default LandingPage;
