import * as React from "react";
import type { SVGProps } from "react";
const SvgCheckSingleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="13"
    height="9"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3202 0.493484C14.147 0.498645 13.9826 0.571031 13.8619 0.695307L5.66656 8.89062L2.13791 5.36197C2.07648 5.29799 2.0029 5.24691 1.92149 5.21172C1.84007 5.17653 1.75244 5.15794 1.66375 5.15704C1.57506 5.15614 1.48707 5.17294 1.40496 5.20647C1.32284 5.23999 1.24824 5.28957 1.18552 5.35228C1.1228 5.415 1.07322 5.48961 1.0397 5.57173C1.00617 5.65384 0.989367 5.74183 0.990269 5.83052C0.991171 5.91921 1.00976 6.00683 1.04495 6.08825C1.08014 6.16967 1.13122 6.24325 1.19521 6.30468L5.19521 10.3047C5.32023 10.4297 5.48978 10.4999 5.66656 10.4999C5.84334 10.4999 6.01288 10.4297 6.13791 10.3047L14.8046 1.63802C14.9009 1.54437 14.9667 1.42381 14.9934 1.29213C15.02 1.16045 15.0062 1.0238 14.9539 0.900074C14.9015 0.77635 14.813 0.671331 14.6999 0.598778C14.5868 0.526225 14.4545 0.489524 14.3202 0.493484Z"
      fill="#FF5555"
      stroke="#FF5555"
      stroke-width="0.5"
    />
  </svg>
);
export default SvgCheckSingleIcon;
