import { FC } from "react";
import { SVGProps } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../../components/Icon";

interface MarketingSuiteCardProps {
    title: string;
    description: string;
    disabled: boolean;
    route: string;
    icon?: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | undefined;
    color?: string;
    bgColor?: string;
    isHovered: boolean;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    collapsedModeEnabled: boolean;
}

const MarketingSuiteCard: FC<MarketingSuiteCardProps> = ({
    title,
    description,
    disabled,
    route,
    icon,
    color,
    bgColor,
    isHovered,
    onMouseEnter,
    onMouseLeave,
    collapsedModeEnabled,
}) => {
    const navigate = useNavigate();

    return (
        <div
            className="flex flex-row sm:w-full md:w-1/3 lg:w-1/4 cursor-pointer align-middle items-center my-5"
            onClick={() => !disabled && navigate(route)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div
                style={{ backgroundColor: disabled ? 'rgba(153, 153, 153, 0.1)' : bgColor }}
                className="rounded w-11 h-11 flex items-center justify-center"
            >
                <Icon component={icon} width="20px" height="20px" color={disabled ? "#8E8E8E" : color} />
            </div>

            <div className="ml-2">
                <div className={`flex items-center justify-between ${collapsedModeEnabled ? '' : 'max-w-[200px]'}`}>
                    <span className={`text-black text-BodyMedium font-medium truncate ${disabled ? "!text-gray" : ""}`}>
                        {title}
                    </span>
                    {(disabled && isHovered) && (
                        <span className="ml-3 px-1 bg-red text-white text-BodySmall text-[10px] font-semibold uppercase rounded-full">
                            Soon
                        </span>
                    )}
                </div>
                <span className={`text-BodySmall mt-2 ${disabled ? "!text-gray" : "text-footnote"}`}>
                    {description}
                </span>
            </div>
        </div>
    );
};

export default MarketingSuiteCard;
