import { t } from "i18next";
import { Close, Refresh } from "../assets/IconComponents";
import { Content } from "../services/ContentApi";
import { Revision, useEditTextMutation, useFavoriteMutation } from "../services/RevisionApi";
import PrimaryButton from "./PrimaryButton";
import ContentImage from "./ContentImage";
import GenerateSimilarFiltersItem from "./GenerateSimilarFiltersItem";
import { useEffect, useState } from "react";
import WarningMessage from "./WarningMessage";
import { IGenericSelectorData } from "./GenericSelector/GenericSelector";
import SvgGenerateSimilarWhiteIcon from "../assets/IconComponents/GenerateSimilarWhiteBg";
import ActionSheet from "./ActionSheet/ActionSheet";

export interface IGenerateSimilarFilterPopupProps {
  items: IGenericSelectorData[];
  show: boolean;
  onClose: () => void;
  itemsSelected: (data: IGenericSelectorData[]) => void;
  className?: string;
  closable?: boolean;
  fullScreen?: boolean;
  revision?: Revision;
  imageContent?: string;
}

function GenerateSimilarFilterPopup (props: IGenerateSimilarFilterPopupProps) {
  const [selectedItems, setSelectedItems] = useState<IGenericSelectorData[]>(
    []
  );


  const [warningMessage, setWarningMessage] = useState("");

  useEffect(() => {
    if(warningMessage !== "")
    {
                          setTimeout(() => {
                            setWarningMessage("");
                          }, 5000);
    }
  },[warningMessage]);



  return (
    <>
      {props.show && (
        <>
          <div
            className={`${
              props.fullScreen
                ? "fixed left-0 right-0 top-0 bottom-0"
                : "absolute  w-full h-[500px] lg:w-[calc(100%-220px)]"
            } backdrop-blur-sm z-40 flex items-center justify-center  max-lg:hidden lg:flex ${
              props.className
            }`}
          >
            <div
              className={`flex flex-col mt-5 mx-2 sm:mt-0 lg:mx-0 sm:justify-center items-center h-[00px] w-full lg:w-[calc(45%)]`}
            >
              <div
                className="flex flex-col w-full bg-white border-border border-1 rounded-xl shadow-lg shadow-red-100"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <p className="text-black outline-none text-H5 p-[15px]">
                  {t("generate-similar")}
                </p>
                <hr className="bg-border -ml-5 -mr-5" />
                <p className="text-black outline-none text-BodyMedium p-[15px] mt-1">
                  {"Choose what you would like to keep on the selected design"}
                </p>
                <div className="flex flex-row mt-1 mb-5">
                  <ContentImage
                    id={props.revision?.generated_content ?? ""}
                    className="mt-2 ml-2 py-1 h-[210px] bg-bg w-[calc(40.33%)] rounded-lg object-contain"
                  />
                  <div className="flex flex-col">
                    {/*Body*/}
                    <div className="p-[20px] pt-[30px] h-full overflow-scroll scrollbar-hide">
                      <div className="h-12">
                        <WarningMessage
                          title={warningMessage}
                          show={warningMessage !== ""}
                          className="w-full"
                        />
                      </div>

                      {props.items.map((k) => (
                        <GenerateSimilarFiltersItem
                          key={k.id} // Added key prop for optimization
                          data={k}
                          selected={selectedItems.some(
                            (item) => item.id === k.id
                          )} // Check if the item is in the selectedItems array
                          itemSelected={() => {
                            const isSelected = selectedItems.some(
                              (item) => item.id === k.id
                            );
                            if (isSelected) {
                              setSelectedItems((prevSelected) =>
                                prevSelected.filter((item) => item.id !== k.id)
                              );
                            } else {
                              setSelectedItems((prevSelected) => [
                                ...prevSelected,
                                k,
                              ]);
                            }
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                
                  <div className="w-full flex  mt-3 p-[15px] justify-end items-end">
                    <PrimaryButton
                      className="h-10 w-[100px]"
                      noFill
                      title={t("cancel")}
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                    <PrimaryButton
                      icon={<SvgGenerateSimilarWhiteIcon />}
                      className="h-10 w-30 ml-2"
                      title={t("generate-similiar")}
                      onClick={() => {
                        if (selectedItems.length === props.items.length) {
                          setWarningMessage(
                            "Great, You Don't Want To Change Anything"
                          );
                        } else {
                          props.itemsSelected(selectedItems);
                          props.onClose();
                        }
                      }}
                    />
                  </div>
                
              </div>
            </div>
          </div>
          <div className="lg:hidden max-lg:flex flex-col  fixed left-0 right-0 top-0 bottom-0 z-50">
            <ActionSheet
              title="Generate Similar"
              isExpanded={true}
              setIsExpanded={() => {
                props.onClose();
              }}
              triggerView={<div></div>}
              body={
                <div
                  className={`flex flex-col mt-5 mx-2 sm:mt-0 lg:mx-0 sm:justify-center items-center   w-full lg:w-[calc(45%)]`}
                >
                  <div
                    className="flex flex-col w-full bg-white  shadow-lg shadow-red-100"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <p className="text-black outline-none text-BodyMedium p-[15px] mt-1">
                      {
                        "Choose what you would like to keep on the selected design"
                      }
                    </p>
                    <div className="flex flex-row mt-1 mb-2">
                      <ContentImage
                        id={props.revision?.generated_content ?? ""}
                        className="mt-2 ml-2 py-1 h-[210px] bg-bg w-[calc(40.33%)] rounded-lg object-contain"
                      />
                      <div className="flex flex-col">
                        {/*Body*/}
                        <div className="pt-[30px] h-full overflow-scroll scrollbar-hide">
                          <div className="h-12">
                            <WarningMessage
                              title={warningMessage}
                              show={warningMessage !== ""}
                              className="w-full"
                            />
                          </div>

                          {props.items.map((k) => (
                            <GenerateSimilarFiltersItem
                              key={k.id} // Added key prop for optimization
                              data={k}
                              selected={selectedItems.some(
                                (item) => item.id === k.id
                              )} // Check if the item is in the selectedItems array
                              itemSelected={() => {
                                const isSelected = selectedItems.some(
                                  (item) => item.id === k.id
                                );
                                if (isSelected) {
                                  setSelectedItems((prevSelected) =>
                                    prevSelected.filter(
                                      (item) => item.id !== k.id
                                    )
                                  );
                                } else {
                                  setSelectedItems((prevSelected) => [
                                    ...prevSelected,
                                    k,
                                  ]);
                                }
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>

                      <div className="h-12 mb-[30px] ml-[130px]  w-3/4 flex flex-row p-[15px]">
                        <PrimaryButton
                          className="h-12 w-1/3"
                          noFill
                          title={t("cancel")}
                          onClick={() => {
                            props.onClose();
                          }}
                        />
                        <PrimaryButton
                          icon={<SvgGenerateSimilarWhiteIcon />}
                          className="h-12 w-1/2 ml-2"
                          title={t("generate-similiar")}
                          onClick={() => {
                            if (selectedItems.length === props.items.length) {
                              setWarningMessage(
                                "Great, You Don't Want To Change Anything"
                              );
                            } else {
                              props.itemsSelected(selectedItems);
                              props.onClose();
                            }
                          }}
                        />
                      </div>
                  </div>
                </div>
              }
            />
          </div>
        </>
      )}
    </>
  );
}

export default GenerateSimilarFilterPopup;
