import { t } from "i18next";
import { Add } from "../../../assets/IconComponents";
import PrimaryButton from "../../../components/PrimaryButton";




export interface ISocialAccountsEmptyProps {
    onClick: () => void
}

function SocialAccountsEmpty(props: ISocialAccountsEmptyProps) {


    return (<div className="flex flex-row w-full ">
        <div className="flex flex-col w-full items-center h-full overflow-scroll scrollbar-hide gap-[24px]">
            <img
                className="mt-[20px] w-[260px] h-[64px]"
                src={require("../../../assets/card-placeholder.png")}
                alt="Social Accounts" // Set the width and height of the image
            />
            <div className="flex flex-row items-center text-H6 text-black">No Social Accounts Added</div>
            <div className="flex flex-row items-center font-normal text-black px-[40px] text-center">{t("no-social-accounts-warning")}</div>
            <div>
                <PrimaryButton
                    onClick={() => {
                        props.onClick();
                    }}
                    icon={<Add fill="#ffffff" className="mr-2" />}
                    title={t('add-new')}
                    className="h-[40px] w-full md:w-[175px] mt-5 md:mt-0"
                />
            </div>
        </div>

    </div>)
}

export default SocialAccountsEmpty;