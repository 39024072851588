import { FunctionComponent, useEffect, useState } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { blobToDataURL, dataURLToBlob } from "blob-util";
import { useRef } from "react";
import borderSvg from "../../../assets/icons/dashed-border.svg";
import borderSvgRed from "../../../assets/icons/dashed-border-red.svg";
import { FileProps } from "../../../services/BrandApi";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import {
  useDeleteFileDeleteByIdMutation,
  useLazyDownloadQuery,
  useUploadMutation,
} from "../../../services/FileApi";
import { toggleLoading } from "../../../store/appSlice";
import SvgUploadIcon from "../../../assets/IconComponents/UploadIcon";
import ContentImage from "../../../components/ContentImage";
import { newContent, setNewContent } from "../../../store/contentSlice";
import { ContentWizardStep } from "../../../models/ContentWizardStep";
import { ContentWizardView } from "../../../models/ContentWizardView";
import AddYourOwnInspirationPopup from "../../ContentWizard/AddYourOwnInspirationPopup";
import ReactFlipCard from "reactjs-flip-card";
import SvgPlus from "../../../assets/IconComponents/Plus";
import SvgAddPlusIcon from "../../../assets/IconComponents/SvgAddPlus";
import SvgPlusBlack from "../../../assets/IconComponents/PlusBlack";

interface IChooseAnAspirationOrThemeProps {
  title: string;
  description: string;
  className?: string;
  maxFileCount?: number;
  acceptedFileTypes?: Accept;
  maxFileSize?: number;
  disabled?: boolean;
  uploadButtonEnabled?: boolean;
  fileChanged: (filesIds: File) => void;
  selectionChanged?: (selectedFiles: FileProps) => void;
  uploadedFiles?: FileProps[];
  selectionEnabled: boolean;
  isGrid?: boolean;
  selectedFileId?: string;
  isAspiration?: boolean;
  type?: string;
  setGuidelinesState?: (state: number) => void;
  onRefresh?: (currentPage: number) => void;
  currentPage?: number;
  isInSide?: boolean;
}

const ChooseAnAspirationOrTheme: FunctionComponent<
  IChooseAnAspirationOrThemeProps
> = (props: IChooseAnAspirationOrThemeProps) => {
  const newContent = useAppSelector((k) => k.content.newContent);
  const refreshInputRef = useRef<HTMLInputElement | null>(null);
  const buttonClickRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState("");
  const brandInfo = useAppSelector((k) => k.brand.newBrand);
  const [selectedFiles, setFiles] = useState<any[]>([]);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const sidebarRef = useRef<HTMLDivElement>(null);
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [sidebarHeight, setSidebarHeight] = useState(500);
  const lastRequestTimeRef = useRef<number>(0);
  const [
    uploadFiles,
    {
      data: uploadedFileData,
      isSuccess: filesUploaded,
      isLoading: filesUploading,
    },
  ] = useUploadMutation();
  const [showAddOwnInspirationPopup, setShowAddOwnInspirationPopup] =
    useState(false);
  const [
    deleteFile,
    { data: fileDeletedData, isSuccess: fileDeleted, isLoading: fileDeleting },
  ] = useDeleteFileDeleteByIdMutation();
  const [
    downloadFile,
    {
      data: fileDownloaddData,
      isSuccess: fileDownloaded,
      isLoading: fileDownloading,
      currentData: dt,
    },
  ] = useLazyDownloadQuery();

  const [errorIndex, setErrorIndex] = useState(-1);

  useEffect(() => {
    console.log("New COntent: ", newContent?.wizardView);
    if((newContent?.wizardView ?? ContentWizardView.blogDetails) == ContentWizardView.inspoLibrary) {

    }
    const updateHeight = () => {
      console.log("Window Inner H is: ", window.innerHeight);
      // Subtract any known heights from other elements if necessary
      const calculatedHeight = window.innerHeight - 160; // Example: 50px for header/footer
       console.log("calculated height: ", calculatedHeight);
      setSidebarHeight(calculatedHeight);
    };


    // Calculate on mount
    updateHeight();

    // Add event listener for window resize
    window.addEventListener("resize", updateHeight);

    // Cleanup event listener
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  useEffect(() => {
    const sidebarElement = sidebarRef.current;
    if (!sidebarElement) return;

  

    sidebarElement.addEventListener("scroll", handleScroll);

    return () => sidebarElement.removeEventListener("scroll", handleScroll);
  }, [sidebarHeight]);


  useEffect(() => {
    if (props.uploadedFiles && props.uploadedFiles.length > 0) {
      Promise.all(
        props.uploadedFiles?.map((uploadedFile, index) =>
          downloadFileFrom(uploadedFile)
        )
      ).then((k) => {
        setFiles(k);
      });
    } else {
      setFiles(props.uploadedFiles || []);
    }
  }, [props.uploadedFiles]);


const handleScroll = () => {
  const sidebar = sidebarRef.current;
  const currentTime = new Date().getTime(); // Get current time in milliseconds

  if (sidebar) {
    console.log(
      "Handle Scroll scrollHeight: ",
      sidebar.scrollHeight,
      "scrollTop: ",
      sidebar.scrollTop,
      "clientHeight: ",
      sidebar.clientHeight
    );

    const bottomReached =
      sidebar.scrollHeight - sidebar.scrollTop <= sidebar.clientHeight;

    if (bottomReached && currentTime - lastRequestTimeRef.current > 5000) {
      lastRequestTimeRef.current = currentTime; 
      loadMoreRef.current?.click(); 
    }
  }
};


  useEffect(() => {
    setSelectedImage(
      props.type === "theme"
        ? newContent?.creative_theme ?? ""
        : props.type === "inspiration" && newContent?.adId
        ? newContent?.adId
        : newContent?.creativeImageId ?? ""
    );
  }, [props.type, props.uploadedFiles]);

  useEffect(() => {
    if (error !== "") {
      setTimeout(() => {
        setError("");
        setErrorIndex(-1);
      }, 3000);
    }
  }, [error]);

  const downloadFileFrom = async (param: FileProps) => {
    const fileId = props.type === "theme" ? param.imageId : param.id;
    const name = props.type === "theme" ? param.subChildConcept : param.name;
    const parentConcept =
      props.type === "theme" ? param.parentConcept : param.objective;
    return new Promise<any>((resolve) => {
      if (fileId) {
        downloadFile({
          id: fileId,
        })
          .unwrap()
          .then(async (k) => {
            const item = {
              adId: param.adId,
              uploadedId: fileId,
              id: param.id,
              name: name,
              type: "other",
              preview: await blobToDataURL(k.file),
              subname: parentConcept,
              industry: param.industry
            };

            return resolve(item);
          })
          .catch((e) => {
            console.log("error", e);
          });
      }
    });
  };

  const handleRefreshButtonClick = (index: number) => {
    buttonClickRef.current?.click();
  };



const thumbs = selectedFiles.map((file, index) => (
  <>
    {file ? (
      <>
        {!props.isGrid && (
          <div
            key={file.name}
            style={{
              backgroundSize: "cover", // Adjust the sizing as needed
              backgroundRepeat: "no-repeat", // Prevent the SVG from repeating
              borderRadius: "6px",
            }}
            className={`${`relative flex mt-[8px] mr-[5px] cursor-pointer rounded-[8px] h-[200px] w-[121px]  justify-center`}`}
            onClick={() => {
              if (props.type === "theme") {
                setSelectedImage(file.id);
              } else if (props.type === "inspiration" && !file.adId) {
                setSelectedImage(file.id);
              } else {
                setSelectedImage(file.adId);
              }
              if (props.selectionEnabled) {
                if (props.selectionChanged) {
                  props.selectionChanged(file);
                }
              }
            }}
          >
            <div className="flex-col w-full mt-2 items-center">
              <div
                className={`flex flex-col rounded-[6px] border-1 ${
                  selectedImage === file.adId || selectedImage === file.id
                    ? "border-red "
                    : "border-white"
                }`}
              >
                {((props.type === "inspiration") ||
                  props.type !== "inspiration") && (
                  <>
                    <ContentImage
                      title={file.name}
                      id={file.uploadedId ?? ""}
                      forceDirectRender={true}
                      className={
                        "py-1 h-[140px] w-[140px] bg-bg w-full rounded-[10px] object-contain"
                      }
                    />
                  </>
                )}
                { /*
                {props.type === "inspiration" && !file.adId && (
                  <img
                    title={file.name}
                    src={file.preview}
                    alt={file.name}
                    style={{
                      marginTop: "",
                      width: `105px`,
                      height: `105px`,
                      borderRadius: 10,
                      backgroundColor: "rgb(234 235 230)",
                    }}
                    className="object-contain"
                  />
                )}
                  */}
              </div>
              {file.name != file.id && (
                <div className="flex flex-col ml-1 justify-start w-full mt-2 mb-2 px-1">
                  <span className="text-BodyMedium font-inter-tight font-medium text-black truncate text-ellipsis overflow-hidden">
                    {file.name}
                  </span>
                  {/* New text added below the file name */}
                  <span className="text-BodySmall font-inter-tight text-gray-500 truncate text-ellipsis overflow-hidden">
                    {file.subname || "Default Parent Concept"}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}

        {props.isGrid && (
          <div
            className="flex flex-col h-full relative"
            onClick={() => {
              if (props.type === "theme") {
                setSelectedImage(file.id);
              } else if (props.type === "inspiration" && !file.adId) {
                setSelectedImage(file.id);
              } else {
                setSelectedImage(file.adId);
              }
              if (props.selectionEnabled) {
                if (props.selectionChanged) {
                  props.selectionChanged(file);
                }
              }
            }}
          >
            <ReactFlipCard
              containerStyle={{ width: "100%", height: "100%" }}
              frontComponent={
                <div
                  key={file.name}
                  style={{
                    backgroundSize: "cover", // Adjust the sizing as needed
                    backgroundRepeat: "no-repeat", // Prevent the SVG from repeating
                    borderRadius: "6px",
                  }}
                  className={`${
                    props.isGrid
                      ? "flex flex-col justify-center items-center gap-[10px] flex-1"
                      : `relative flex mt-[8px] mr-[5px] cursor-pointer rounded-[8px] h-[180px] w-[121px] border-1 border-border justify-center`
                  } ${
                    selectedImage === file.adId || selectedImage === file.id
                      ? "border-1 border-red"
                      : ""
                  }`}
                >
                    <ContentImage
                      id={file?.uploadedId ?? ""}
                      className="w-full h-full object-contain rounded-[8px]"
                    />
                
                </div>
              }
              backComponent={
                <div className="flex-col relative h-full bg-white rounded-[10px] border-1 border-red">
                  <ContentImage
                    id={file?.uploadedId ?? ""}
                    className="w-full h-full object-contain rounded-[8px]"
                  />
                  <div
                    className="absolute inset-0 rounded-[8px]"
                    style={{
                      background:
                        "linear-gradient(180deg, rgba(18, 19, 21, 0) 0%, #121315 100%)",
                    }}
                  ></div>
                  <div className="absolute bottom-20 left-5">
                    <span className="text-white text-BodyMedium">
                      {file.name}
                    </span>
                  </div>
                  <div className="absolute bottom-12 left-5 flex gap-1">
                    <button
                      className="px-1 py-2 text-white text-BodySmall flex items-center  truncate whitespace-nowrap overflow-ellipsis"
                      style={{
                        background: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "100px",
                        border: "none",
                        height: "24px",
                        maxWidth: "80px",
                      }}
                    >
                      {props.type === "inspiration"
                        ? file?.industry
                        : file?.subname}
                    </button>
                    {props.type === "inspiration" && (
                      <button
                        className="px-1 py-2 text-white text-BodySmall flex items-center  truncate whitespace-nowrap overflow-ellipsis"
                        style={{
                          background: "rgba(255, 255, 255, 0.1)",
                          borderRadius: "100px",
                          border: "none",
                          height: "24px",
                          maxWidth: "80px",
                        }}
                      >
                        {file.subname ?? ""}
                      </button>
                    )}
                  </div>
                </div>
              }
            />
            {file.name != file.id && (
              <div className="flex flex-col justify-start w-full mt-2 mb-2">
                <span className="text-BodyMedium font-inter-tight font-medium text-black truncate whitespace-nowrap overflow-ellipsis">
                  {file.name}
                </span>
                {/* New text added below the file name */}
                <span className="text-BodySmall font-inter-tight text-gray-500 truncate whitespace-nowrap overflow-ellipsis">
                  {file.subname || "Default Parent Concept"}
                </span>
              </div>
            )}
          </div>
        )}
      </>
    ) : (
      <></>
    )}
  </>
));


  return (
    <>
      <div
        className={`relative flex flex-col w-full  ${props.className} ${
          props.isGrid ? "pb-[100px]" : ""
        }`}
        ref={sidebarRef}
        onScroll={handleScroll}
        style={{
          height: `${sidebarHeight}px`,
          display: "flex",
          overflow: "auto",
        }}
      >
        <AddYourOwnInspirationPopup
          show={showAddOwnInspirationPopup}
          fileUploaded={(file) => {
            console.log("File is: ", file);
            props.fileChanged(file);
            dispatch(toggleLoading(false));
            if (props.type === "inspiration") {
              dispatch(
                setNewContent({
                  ...newContent,
                  adId: undefined,
                  creative_theme: undefined,
                  creativeImageId: file.uploadedId,
                  isCreativeAdapted: file.isAdapted
                })
              );
            }
          }}
          onClose={() => {
            setShowAddOwnInspirationPopup(false);
          }}
        />
        <div
          className={`${
            props.isGrid ?? false ? "grid grid-cols-4 gap-1" : "flex flex-row"
          } ${props.isInSide ? "flex-wrap" : ""}`}
        >
          {props.uploadButtonEnabled && (
            <>
              {!error && (
                <div
                  key={`upload_without_error`}
                  style={{
                    backgroundColor: "rgba(250, 251, 249, 1)",
                  }}
                  className={`flex mr-2 cursor-pointer flex-col  justify-center items-center border-1 border ${
                    props.isGrid ? "h-[220px]" : "h-[140px] w-1/4"
                  } ${
                    props.isInSide
                      ? "h-[149px] w-[121px] border-1 border-dashed mr-0 mt-0"
                      : ""
                  } rounded-[8px] mt-4 items`}
                  onClick={() => {
                    /*
                    setErrorIndex(0);
                    handleRefreshButtonClick(-1);
                    */
                    setShowAddOwnInspirationPopup(true);
                  }}
                >
                  {/*
                  <input {...getInputProps()} ref={buttonClickRef} />
                  */}
                  <SvgPlusBlack />
                  <p className="text-BodyMedium text-black mt-2 text-center">
                    {props.description}
                  </p>
                </div>
              )}
            </>
          )}
          {thumbs}
        </div>
      </div>
      {props.onRefresh && selectedFiles?.length && (
        <div className={"flex justify-center mt-4"} style={{ display: "none" }}>
          <div
            ref={loadMoreRef}
            className={`flex flex-col items-center  mt-2 justify-center w-[88px] h-[33px] bg-slightRed rounded-[100px] cursor-pointer`}
            onClick={() =>
            {
              console.log("Props", props.onRefresh, props.currentPage);
              props.currentPage !== undefined && props.onRefresh
                ? props.onRefresh(props.currentPage + 1)
                : null;
            }
            }
          >
            <text className="text-BodySmall text-red">Load More</text>
          </div>
        </div>
      )}
    </>
  );
};

export default ChooseAnAspirationOrTheme;
