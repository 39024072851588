import * as React from "react";
import type {SVGProps} from "react";

const UpgradeIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.1" width="56" height="56" rx="4" fill="#FF5555"/>
        <path opacity="0.2"
              d="M28 41.3332C35.364 41.3332 41.3334 35.3638 41.3334 27.9998C41.3334 20.6358 35.364 14.6665 28 14.6665C20.636 14.6665 14.6667 20.6358 14.6667 27.9998C14.6667 35.3638 20.636 41.3332 28 41.3332Z"
              fill="#FF5555"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M27.0572 21.057C27.5779 20.5363 28.4221 20.5363 28.9428 21.057L33.6095 25.7237C34.1302 26.2444 34.1302 27.0886 33.6095 27.6093C33.0888 28.13 32.2446 28.13 31.7239 27.6093L28 23.8855L24.2761 27.6093C23.7554 28.13 22.9112 28.13 22.3905 27.6093C21.8698 27.0886 21.8698 26.2444 22.3905 25.7237L27.0572 21.057ZM22.3905 31.7237L27.0572 27.057C27.5779 26.5363 28.4221 26.5363 28.9428 27.057L33.6095 31.7237C34.1302 32.2444 34.1302 33.0886 33.6095 33.6093C33.0888 34.13 32.2446 34.13 31.7239 33.6093L28 29.8855L24.2761 33.6093C23.7554 34.13 22.9112 34.13 22.3905 33.6093C21.8698 33.0886 21.8698 32.2444 22.3905 31.7237Z"
              fill="#FF5555"/>
    </svg>

);
export default UpgradeIcon;
