import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateName: build.mutation<UpdateNameApiResponse, UpdateNameApiArg>({
      query: (queryArg) => ({
        url: `/content/update-name`,
        method: "PUT",
        body: queryArg.contentUpdateRequest,
      }),
    }),
    updateBrand: build.mutation<UpdateBrandApiResponse, UpdateBrandApiArg>({
      query: (queryArg) => ({
        url: `/content/update-brand`,
        method: "PUT",
        body: queryArg.contentBrandUpdateRequest,
      }),
    }),
    favorite1: build.mutation<Favorite1ApiResponse, Favorite1ApiArg>({
      query: (queryArg) => ({
        url: `/content/favorite/${queryArg.contentId}`,
        method: "PUT",
        params: { isFavorite: queryArg.isFavorite },
      }),
    }),
    save4: build.mutation<Save4ApiResponse, Save4ApiArg>({
      query: (queryArg) => ({
        url: `/content`,
        method: "POST",
        body: queryArg.contentRequest,
      }),
    }),
    shareContent: build.mutation<ShareContentApiResponse, ShareContentApiArg>({
      query: (queryArg) => ({
        url: `/content/share/${queryArg.contentId}`,
        method: "POST",
      }),
    }),
    generateContentFromRevision: build.mutation<
      GenerateContentFromRevisionApiResponse,
      GenerateContentFromRevisionApiArg
    >({
      query: (queryArg) => ({
        url: `/content/generate_from/${queryArg.revisionId}`,
        method: "POST",
      }),
    }),
    clone: build.mutation<CloneApiResponse, CloneApiArg>({
      query: (queryArg) => ({
        url: `/content/clone`,
        method: "POST",
        body: queryArg.cloneContentRequest,
      }),
    }),
    contentInspiration: build.mutation<
      ContentInspirationApiResponse,
      ContentInspirationApiArg
    >({
      query: (queryArg) => ({
        url: `/brand/content-inspiration`,
        method: "POST",
        body: queryArg.contentInspirationRequest,
      }),
    }),
    findRevisionsByContentId: build.query<
      FindRevisionsByContentIdApiResponse,
      FindRevisionsByContentIdApiArg
    >({
      query: (queryArg) => ({ url: `/revision/content/${queryArg.contentId}` }),
    }),
    findByContentId1: build.query<
      FindByContentId1ApiResponse,
      FindByContentId1ApiArg
    >({
      query: (queryArg) => ({ url: `/content/${queryArg.contentId}` }),
    }),
    deleteByContentId: build.mutation<
      DeleteByContentIdApiResponse,
      DeleteByContentIdApiArg
    >({
      query: (queryArg) => ({
        url: `/content/${queryArg.contentId}`,
        method: "DELETE",
      }),
    }),
    loops: build.query<LoopsApiResponse, LoopsApiArg>({
      query: (queryArg) => ({
        url: `/content/loops`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          filter_text: queryArg.filterText,
          favorite: queryArg.favorite,
          platform: queryArg.platform,
          content_type: queryArg.contentType,
          brand_id: queryArg.brandId,
        },
      }),
    }),
    list5: build.query<List5ApiResponse, List5ApiArg>({
      query: (queryArg) => ({
        url: `/content/list`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    listCreatives: build.query<ListCreativesApiResponse, ListCreativesApiArg>({
      query: (queryArg) => ({
        url: `/content/list-creative`,
        params: { start: queryArg.start, size: queryArg.size },
      }),
    }),
    deleteShared: build.mutation<DeleteSharedApiResponse, DeleteSharedApiArg>({
      query: (queryArg) => ({
        url: `/content/delete-shared/${queryArg.contentId}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UpdateNameApiResponse = /** status 200 OK */ Content;
export type UpdateNameApiArg = {
  contentUpdateRequest: ContentUpdateRequest;
};
export type UpdateBrandApiResponse = /** status 200 OK */ Content;
export type UpdateBrandApiArg = {
  contentBrandUpdateRequest: ContentBrandUpdateRequest;
};
export type Favorite1ApiResponse = unknown;
export type Favorite1ApiArg = {
  contentId: string;
  isFavorite?: boolean;
};
export type Save4ApiResponse = /** status 200 OK */ Content;
export type Save4ApiArg = {
  contentRequest: ContentRequest;
};
export type ShareContentApiResponse = unknown;
export type ShareContentApiArg = {
  contentId: string;
};
export type GenerateContentFromRevisionApiResponse =
  /** status 200 OK */ GenerateContentFromRevisionResponse;
export type GenerateContentFromRevisionApiArg = {
  revisionId: string;
};
export type CloneApiResponse = /** status 200 OK */ Content;
export type CloneApiArg = {
  cloneContentRequest: CloneContentRequest;
};
export type ContentInspirationApiResponse =
  /** status 200 OK */ AiContentInspirationsResponse;
export type ContentInspirationApiArg = {
  contentInspirationRequest: ContentInspirationRequest;
};
export type FindRevisionsByContentIdApiResponse =
  /** status 200 OK */ Revision[];
export type FindRevisionsByContentIdApiArg = {
  contentId: string;
};
export type FindByContentId1ApiResponse = /** status 200 OK */ Content;
export type FindByContentId1ApiArg = {
  contentId: string;
};
export type DeleteByContentIdApiResponse = unknown;
export type DeleteByContentIdApiArg = {
  contentId: string;
};
export type LoopsApiResponse = /** status 200 OK */ LoopsResponse[];
export type LoopsApiArg = {
  page?: number;
  size?: number;
  filterText?: string;
  favorite?: boolean;
  platform?: string;
  contentType?: "image" | "text";
  brandId?: string;
};
export type List5ApiResponse = /** status 200 OK */ Content[];
export type List5ApiArg = {
  page?: number;
  size?: number;
};
export type ListCreativesApiResponse =
  /** status 200 OK */ ListCreativeContentResponse;
export type ListCreativesApiArg = {
  start?: string;
  size?: number;
};
export type DeleteSharedApiResponse = unknown;
export type DeleteSharedApiArg = {
  contentId: string;
};
export type Id = {
  content_id?: string;
  revision_id?: string;
};
export type Content = {
  id?: Id;
  name?: string;
  type?: "image" | "text";
  platform?: string;
  purpose?: string;
  filters?: string;
  revisionGroupCount?: number;
  creative?: string;
  activityData?: string;
  photoshootContent?: string;
  cta?: boolean;
  created_date?: string;
  created_user?: string;
  updated_date?: string;
  updated_user?: string;
  words_limit?: number;
  sentences_limit?: number;
  image_height?: number;
  image_width?: number;
  brand_id?: string;
  logo_file_id?: string;
  product_file_id?: string;
  is_favorite?: boolean;
  read_only?: boolean;
};
export type ContentUpdateRequest = {
  updated_name: string;
  content_id: string;
};
export type ContentBrandUpdateRequest = {
  brand_id: string;
  content_id: string;
};
export type FileProps = {
  id?: string;
  name?: string;
  uploaded?: boolean;
  summary?: string;
  create_date?: number;
  file_name?: string;
  file_type?: string;
};
export type RequestFilters = {
  bot_type: string;
  tone_of_voice?: string;
  gender_group?: string;
  age_group?: string;
  education_level?: string;
  audience_id?: string;
  reference_files?: FileProps[];
  message?: string;
  objective?: string;
  creativity?: string;
};
export type ContentRequest = {
  content_id?: string;
  filters: RequestFilters;
  words_limit?: number;
  sentences_limit?: number;
  image_height?: number;
  image_width?: number;
  brand_id?: string;
  logo_file_id?: string;
  product_file_id?: string;
  cta?: boolean;
  ad_id?: string;
  creative_image_id?: string;
  creative_is_adapted?: boolean;
  creative_theme?: string;
  creative_topic?: string;
  creative_referred_image_id?: string;
  name: string;
  type: "image" | "text";
  platform: string;
  purpose?: string;
};
export type GenerateContentFromRevisionResponse = {
  content_id?: string;
};
export type CloneContentRequest = {
  content_id: string;
  content_name: string;
};
export type AiContentInspirationsResponse = {
  content?: Content[];
};
export type ContentInspirationRequest = {
  brand_id?: string;
  sm_type: "twitter" | "linkedin" | "instagram" | "facebook";
  sort_order?: string;
  keywords?: string;
};
export type Revision = {
  id?: Id;
  type?: string;
  fileId?: string;
  aiImage?: string;
  headerText?: string;
  footerText?: string;
  templateID?: string;
  colorSet?: string;
  fontSet?: string;
  generatedFrom?: string;
  original?: boolean;
  photoshootRevision?: string;
  fontSetIndex?: {
    [key: string]: number;
  };
  colorSetIndex?: {
    [key: string]: number;
  };
  ctatext?: string;
  created_date?: string;
  created_user?: string;
  updated_date?: string;
  updated_user?: string;
  revision_parent_id?: string;
  revision_group?: number;
  generated_content?: string;
  is_favorite?: boolean;
  ai_model?: string;
};
export type LoopsResponse = {
  content?: Content;
  revision?: Revision;
};
export type ListCreativeContentResponse = {
  last_content_id?: string;
  ai_images?: string[];
};
export const {
  useUpdateNameMutation,
  useUpdateBrandMutation,
  useFavorite1Mutation,
  useSave4Mutation,
  useShareContentMutation,
  useGenerateContentFromRevisionMutation,
  useCloneMutation,
  useContentInspirationMutation,
  useFindRevisionsByContentIdQuery,
  useLazyFindRevisionsByContentIdQuery,
  useFindByContentId1Query,
  useLazyFindByContentId1Query,
  useDeleteByContentIdMutation,
  useLoopsQuery,
  useLazyLoopsQuery,
  useList5Query,
  useLazyList5Query,
  useListCreativesQuery,
  useLazyListCreativesQuery,
  useDeleteSharedMutation,
} = injectedRtkApi;
