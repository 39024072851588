import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { FunctionComponent, useState } from "react";
import { setNewContent } from "../../../store/contentSlice";
import { ContentWizardView } from "../../../models/ContentWizardView";
import CheckBox from "../../../components/CheckBox";

interface ControlButtonsProps {
    onSaveClicked: () => void
    onSaveToBrandChanged: (e: boolean) => void
    isBrandAudience: boolean
}

const ControlButtons: FunctionComponent<ControlButtonsProps> = (props: ControlButtonsProps) => {

    const dispatch = useAppDispatch();
    const newContent = useAppSelector((k) => k.content.newContent);
    const [saveAudience, setSaveAudience] = useState<boolean>(false)


    return <>
        <div className="w-full h-[85px] py-[15px] bg-white shadow justify-between items-center shrink-0 flex">
            {!props.isBrandAudience && <CheckBox title="Save audience for later use" onChange={(e: boolean) => { setSaveAudience(e); props.onSaveToBrandChanged(e); }} />}
            {props.isBrandAudience && <div></div>}
            <div className={`self-stretch justify-start items-center gap-2.5 flex`}>
                <PrimaryButton
                    title={"Cancel"}
                    noFill
                    className="mr-2"
                    onClick={() => {
                        dispatch(setNewContent({ ...newContent, wizardView: ContentWizardView.contentDetails }));
                    }}
                />
                <PrimaryButton
                    title={props.isBrandAudience ? "Save Audience" : (saveAudience ? "Save & Use Audience" : "Use Audience")}
                    className={ `mr-2`}
                    onClick={props.onSaveClicked}
                />
            </div>
        </div >
    </>


}

export default ControlButtons;