import { PickerModel } from "../models/PickerModel";

export const Interests: PickerModel[] = [
  {
    key: "all",
    title: "All",
    value: "0",
  },
  {
    key: "technology",
    title: "Technology",
    value: "1",
  },
  {
    key: "sports",
    title: "Sports",
    value: "2",
  },
  {
    key: "travel",
    title: "Travel",
    value: "3",
  },
  {
    key: "fashion",
    title: "Fashion",
    value: "4",
  },
  {
    key: "music",
    title: "Music",
    value: "5",
  },
  {
    key: "cooking-and-culinary-arts",
    title: "Cooking and Culinary Arts",
    value: "6",
  },
  {
    key: "fitness-and-health",
    title: "Fitness and Health",
    value: "7",
  },
  {
    key: "movies-and-entertainment",
    title: "Movies and Entertainment",
    value: "8",
  },
  {
    key: "reading-and-literature",
    title: "Reading and Literature",
    value: "9",
  },
  {
    key: "art-and-crafts",
    title: "Art and Crafts",
    value: "10",
  },
];
