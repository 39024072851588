import { useEffect, useState } from "react";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { Tooltip } from "@mui/material";
import { Button } from "antd";

export interface IPrimaryButtonProps {
    title?: string | null
    onClick: () => void
    className?: string
    icon?: JSX.Element
    iconComponent?: any
    noFill?: boolean
    isDisabled?: boolean
    iconColor?: string
    strokeColor?: string
    tooltip?: string,
    size?: SizeType
}
function SecondaryButton(props: IPrimaryButtonProps) {
    const { title = undefined, icon, noFill, isDisabled = false, size } = props
    const [iconComponentToShow, setIconComponentToShow] = useState<any>()

    useEffect(() => {
        setIconComponentToShow(props.iconComponent)
    }, [props.iconComponent])

    return (<>
        <Tooltip title={props.tooltip}>
            <Button
                type={"default"}
                disabled={isDisabled}
                size={size}
                className={`${isDisabled ? "!bg-gray !text-white" :
                    !noFill ? `
                text-red 
                bg-[#FFEEEE] hover:bg-[#FFCCCC]
                ` : "boder-border border-1 text-black bg-white hover:bg-lightBg"}
                shrink-0  ${props.className}`}
                icon={icon}
                onClick={(e) => {
                    e.stopPropagation();
                    props.onClick();
                }}
            >
                {title}
            </Button >
        </Tooltip>
    </>);
}

export default SecondaryButton;
