import * as React from "react";
import type { SVGProps } from "react";
const SvgVideoAdGeneratorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    width={20}
    height={20}
    fill="none"
    {...props}>
    <path fill={props.fill ?? "#FFFFFF"} d="M3.83268 3.83301L5.18685 6.54134C5.28407 6.73579 5.42296 6.88856 5.60352 6.99967C5.78407 7.11079 5.97852 7.16634 6.18685 7.16634C6.60352 7.16634 6.92296 6.98926 7.14518 6.63509C7.3674 6.28092 7.38129 5.91634 7.18685 5.54134L6.33268 3.83301H7.99935L9.35352 6.54134C9.45074 6.73579 9.58963 6.88856 9.77018 6.99967C9.95074 7.11079 10.1452 7.16634 10.3535 7.16634C10.7702 7.16634 11.0896 6.98926 11.3118 6.63509C11.5341 6.28092 11.548 5.91634 11.3535 5.54134L10.4993 3.83301H12.166L13.5202 6.54134C13.6174 6.73579 13.7563 6.88856 13.9368 6.99967C14.1174 7.11079 14.3118 7.16634 14.5202 7.16634C14.9368 7.16634 15.2563 6.98926 15.4785 6.63509C15.7007 6.28092 15.7146 5.91634 15.5202 5.54134L14.666 3.83301H17.166C17.6243 3.83301 18.0167 3.9962 18.3431 4.32259C18.6695 4.64898 18.8327 5.04134 18.8327 5.49967V15.4997C18.8327 15.958 18.6695 16.3504 18.3431 16.6768C18.0167 17.0031 17.6243 17.1663 17.166 17.1663H3.83268C3.37435 17.1663 2.98199 17.0031 2.6556 16.6768C2.32921 16.3504 2.16602 15.958 2.16602 15.4997V5.49967C2.16602 5.04134 2.32921 4.64898 2.6556 4.32259C2.98199 3.9962 3.37435 3.83301 3.83268 3.83301ZM3.83268 8.83301V15.4997H17.166V8.83301H3.83268Z" />
  </svg>
);
export default SvgVideoAdGeneratorIcon;