import { useEffect, useState } from "react";
import Icon from "../Icon";
import SvgCheckboxUnchecked from "../../assets/IconComponents/CheckboxUnchecked";
import SvgCheckboxSelectedIcon from "../../assets/IconComponents/CheckboxSelectedIcon";
import { IContentTypeData } from "./ContentTypeSelector";


export interface IContentTypeSelectorItemProps {
    data: IContentTypeData
    selected: boolean
    itemSelected: (data: IContentTypeData, isSelected: boolean) => void
}

function ContentTypeSelectorItem(props: IContentTypeSelectorItemProps) {
    const [selected, setSelected] = useState<boolean>()

    useEffect(() => {
        if (props.selected !== undefined)
            setSelected(props.selected)
        else
            setSelected(false)
    }, [props.selected])

    return (
        <div className={`h-[42px] mt-1 hover:bg-lightBg hover:border-1 hover:border-border cursor-pointer hover:rounded-[5px] flex items-center p-2 justify-between
        ${selected ? "bg-lightBg border-1 border-border rounded-[5px]" : ""}
        `}
            onClick={() => {
                setSelected(!selected)
                props.itemSelected(props.data, !selected)
            }}
        >
            <div className="flex flex-row items-center justify-content">
                <div>
                    <Icon width="20" height="20" viewBox="0 0 20 20" component={props.data.icon} color="#000000" className="h-[20px] w-[20px]  mt-[4px]" />
                </div>
                <text className="text-BodyMedium text-black ml-2">{props.data.title}</text>
            </div>
            {selected &&
                <SvgCheckboxSelectedIcon />
            }
            {!selected &&
                <SvgCheckboxUnchecked />
            }
        </div>
    );
}

export default ContentTypeSelectorItem;