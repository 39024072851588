import * as React from "react";
import type { SVGProps } from "react";
const SvgAddPlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.56126 9.93857H4.13971C3.87896 9.93857 3.65735 9.84733 3.47487 9.66485C3.29241 9.48237 3.20117 9.26076 3.20117 9.00001C3.20117 8.73926 3.29241 8.51764 3.47487 8.33517C3.65735 8.15269 3.87896 8.06145 4.13971 8.06145H7.56126V4.6399C7.56126 4.37915 7.6525 4.15753 7.83498 3.97505C8.01746 3.79259 8.23908 3.70135 8.49983 3.70135C8.76057 3.70135 8.98219 3.79259 9.16467 3.97505C9.34715 4.15753 9.43838 4.37915 9.43838 4.6399V8.06145H12.8599C13.1207 8.06145 13.3423 8.15269 13.5248 8.33517C13.7072 8.51764 13.7985 8.73926 13.7985 9.00001C13.7985 9.26076 13.7072 9.48237 13.5248 9.66485C13.3423 9.84733 13.1207 9.93857 12.8599 9.93857H9.43838V13.3601C9.43838 13.6209 9.34715 13.8425 9.16467 14.025C8.98219 14.2074 8.76057 14.2987 8.49983 14.2987C8.23908 14.2987 8.01746 14.2074 7.83498 14.025C7.6525 13.8425 7.56126 13.6209 7.56126 13.3601V9.93857Z"
      fill="#FF5555"
    />
  </svg>
);
export default SvgAddPlusIcon;
