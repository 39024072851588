import * as React from "react";
import type { SVGProps } from "react";
const SvgEditRevisionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1911_23372)">
      <path
        d="M15.4244 8.12485C15.3982 8.15528 15.3739 8.18798 15.3454 8.21614C14.8046 8.75135 14.2634 9.28645 13.722 9.82144C13.7067 9.83665 13.6938 9.85381 13.6802 9.87032C13.6436 9.88003 13.6258 9.91273 13.6019 9.93506C12.5315 11.0046 11.4613 12.0744 10.3913 13.1444C10.3725 13.1631 10.356 13.1839 10.3382 13.2039L7.84558 15.7001C7.66462 15.8814 7.48496 16.0639 7.30303 16.2439C7.08679 16.4579 6.8194 16.5741 6.52612 16.6324C6.2597 16.6835 5.99069 16.7204 5.72298 16.7619C5.71487 16.7618 5.70681 16.7606 5.69903 16.7583C5.69709 16.7373 5.67993 16.7259 5.66666 16.7133C5.06714 16.0819 4.45208 15.4654 3.82148 14.8639C3.79688 14.8406 3.77811 14.8086 3.74185 14.7992C3.73246 14.6982 3.76645 14.603 3.78005 14.5053C3.81242 14.266 3.8422 14.0258 3.90694 13.7931C3.97335 13.5449 4.10489 13.3191 4.28796 13.1389C5.27593 12.1569 6.26154 11.1727 7.24476 10.1863C7.25966 10.1711 7.27325 10.1539 7.28782 10.1384C7.29638 10.1322 7.30459 10.1256 7.31242 10.1186C8.40355 9.03136 9.49339 7.94444 10.5819 6.85783C10.5962 6.84391 10.6201 6.83485 10.6169 6.80831C10.6522 6.80215 10.669 6.7714 10.691 6.74939C11.207 6.23145 11.7228 5.71264 12.2384 5.19297C12.3031 5.12823 12.3727 5.06867 12.4397 5.00684C12.4712 5.02204 12.4987 5.04418 12.5203 5.07158C13.4759 6.0138 14.4255 6.96175 15.369 7.91541C15.4318 7.9795 15.4839 8.03454 15.4244 8.12485Z"
        fill="#FF5555"
      />
      <path
        d="M10.3385 13.2037C10.3563 13.1837 10.3728 13.1629 10.3916 13.1442C11.4616 12.0744 12.5318 11.0046 13.6022 9.93486C13.6261 9.91091 13.6439 9.87821 13.6805 9.87012L14.1719 10.3703C14.1985 10.3971 14.2224 10.4263 14.249 10.4531C15.104 11.3101 15.9585 12.1674 16.8125 13.025C16.9543 13.1681 17.1038 13.3067 17.2187 13.4737C17.6091 14.0318 17.5781 14.844 17.1022 15.3713C16.7115 15.8044 16.2913 16.2065 15.8721 16.6086C15.6235 16.8465 15.3166 16.9692 14.9702 16.997C14.4769 17.0349 14.0638 16.8721 13.7161 16.5241C12.8069 15.6149 11.8965 14.7066 10.985 13.7994C10.794 13.6093 10.5965 13.4268 10.4023 13.2406C10.3822 13.2202 10.3663 13.2018 10.3385 13.2037Z"
        fill="#FFB9B9"
      />
      <path
        d="M10.6166 6.80858C10.6198 6.83513 10.5958 6.84419 10.5816 6.85811C9.49327 7.94472 8.40462 9.03121 7.31564 10.1176C7.30781 10.1246 7.2996 10.1312 7.29104 10.1373C7.16932 9.9784 7.02236 9.84276 6.88446 9.69935C6.51413 9.31478 6.14186 8.93183 5.75987 8.55891C5.29696 8.10571 4.84052 7.6483 4.38409 7.1896C4.19245 6.99537 3.99563 6.80826 3.82277 6.5972C3.6321 6.3638 3.53498 6.09026 3.50747 5.79018C3.46409 5.32144 3.61365 4.9223 3.9338 4.5824C4.32226 4.17031 4.71784 3.76535 5.13608 3.38466C5.38429 3.15583 5.70524 3.02183 6.04248 3.00624C6.53031 2.97678 6.93819 3.14479 7.28133 3.48469C7.71996 3.91911 8.16216 4.34997 8.59464 4.79055C9.04029 5.24375 9.48949 5.69447 9.94226 6.1427C10.1491 6.34729 10.3631 6.54508 10.5735 6.74611C10.591 6.76294 10.6182 6.77621 10.6166 6.80858Z"
        fill="#FFB9B9"
      />
      <path
        d="M13.7151 3.73739C13.7206 3.72541 13.7229 3.7102 13.7319 3.70211C14.0822 3.38551 14.4625 3.12169 14.9397 3.03849C15.7166 2.90318 16.3786 3.12525 16.9195 3.69466C17.3235 4.1197 17.508 4.63699 17.4896 5.21936C17.4734 5.74151 17.2672 6.19535 16.9393 6.59579C16.8898 6.65632 16.8363 6.7133 16.7845 6.77189C16.7522 6.76315 16.7321 6.73466 16.7094 6.71265C15.7266 5.75446 14.7555 4.78482 13.796 3.80375C13.7698 3.77947 13.7523 3.74613 13.7151 3.73739Z"
        fill="#FF5555"
      />
      <path
        d="M13.7151 3.73736C13.7524 3.7461 13.7698 3.77944 13.7935 3.80372C14.753 4.78544 15.7241 5.75507 16.7069 6.71262C16.7296 6.73463 16.7483 6.76312 16.782 6.77186C16.523 7.02533 16.2615 7.27815 16.0028 7.53291C15.8261 7.70685 15.6553 7.88641 15.4904 8.07157C15.4716 8.09261 15.458 8.12175 15.423 8.12499C15.4826 8.03467 15.4305 7.97964 15.368 7.91651C14.4236 6.96285 13.4741 6.01491 12.5193 5.07268C12.4977 5.04528 12.4701 5.02315 12.4387 5.00794C12.4811 4.93316 12.5562 4.89205 12.6148 4.83378C12.9615 4.49129 13.3072 4.14783 13.6416 3.79304C13.6627 3.77006 13.6766 3.73574 13.7151 3.73736Z"
        fill="#FFB9B9"
      />
      <path
        d="M3.74504 14.7988C3.7813 14.8082 3.80007 14.8403 3.82468 14.8636C4.45462 15.465 5.06968 16.0815 5.66985 16.713C5.68247 16.7265 5.69963 16.7369 5.70222 16.7579C5.31732 16.802 4.94505 16.9143 4.56274 16.9706C4.35978 17.0007 4.15583 17.0085 3.95546 16.9434C3.70522 16.8625 3.55535 16.6657 3.52297 16.4041C3.49384 16.1698 3.52912 15.9435 3.57315 15.7162C3.63174 15.4106 3.71073 15.1089 3.74504 14.7988Z"
        fill="#FFB9B9"
      />
    </g>
    <defs>
      <clipPath id="clip0_1911_23372">
        <rect
          width="13.9909"
          height="14"
          fill="white"
          transform="translate(3.5 3)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgEditRevisionIcon;
