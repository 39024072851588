import { FunctionComponent, useState } from "react";
import TrendingKeywordSelectorPopup from "./TrendingKeywordSelectorPopup";
import { t } from "i18next";
import { Popover } from "react-tiny-popover";
import BrandLogo from "../BrandLogo";
import SearchInput from "../SearchInput";
import { Keyword } from "../../services/KeywordApi";

interface TrendingKeywordSelectorProps {
    keywords: Keyword[]
    tagSelected: (tag: Keyword) => void
    placeholder?: string
}

const TrendingKeywordSelector: FunctionComponent<TrendingKeywordSelectorProps> = (props: TrendingKeywordSelectorProps) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [enteredKeyword, setEnteredKeyword] = useState("")

    return (
        <>
            <Popover
                isOpen={isPopoverOpen}
                positions={['bottom']}
                align="start"
                padding={10}
                onClickOutside={() => { setIsPopoverOpen(false) }}
                content={({ position, childRect, popoverRect }) => (
                    <TrendingKeywordSelectorPopup
                        width={childRect.width}
                        keywords={props.keywords}
                        tagSelected={e => {
                            props.tagSelected(e)
                            setIsPopoverOpen(false)
                        }}
                    />
                )}

            >
                <div className="h-[41px] w-full"
                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                >
                    <SearchInput
                        text={enteredKeyword}
                        placeholder={props.placeholder ?? t('enter-keywords') ?? ""}
                        className="flex w-full flex-1"
                        onChange={(e) => {
                            setEnteredKeyword(e)
                        }}
                        onEnter={e => {
                            props.tagSelected({ name: e })
                            setIsPopoverOpen(false)
                            setEnteredKeyword("")
                        }}
                    />

                </div>
            </Popover >
        </>
    );
}

export default TrendingKeywordSelector;