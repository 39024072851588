import * as React from "react";
import type { SVGProps } from "react";
const SvgCheckboxSelectedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <rect
      width={14.5}
      height={14.5}
      x={0.75}
      y={0.75}
      stroke="#F55"
      strokeWidth={1.5}
      rx={1.25}
    />
    <path
      fill="#F55"
      stroke="#F55"
      strokeWidth={0.5}
      d="M12.74 4.245a.5.5 0 0 0-.343.151L6.25 10.543 3.604 7.896a.5.5 0 1 0-.707.708l3 3a.5.5 0 0 0 .707 0l6.5-6.5a.5.5 0 0 0-.363-.859Z"
    />
  </svg>
);
export default SvgCheckboxSelectedIcon;
