import * as React from "react";
import type { SVGProps } from "react";
const SvgGenerateSimilarBlackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.5583 12.3959C10.5194 12.3959 10.4806 12.3861 10.4417 12.3667C10.4028 12.3473 10.3736 12.3132 10.3542 12.2646L9.93125 11.3167L8.88125 10.8646C8.83264 10.8452 8.79861 10.8184 8.77917 10.7844C8.75972 10.7504 8.75 10.7091 8.75 10.6604C8.75 10.6118 8.75972 10.5705 8.77917 10.5365C8.79861 10.5025 8.83264 10.4757 8.88125 10.4563L9.93125 10.0042L10.3542 8.99795C10.3736 8.94934 10.4003 8.91531 10.4344 8.89587C10.4684 8.87642 10.5097 8.8667 10.5583 8.8667C10.6069 8.8667 10.6483 8.87642 10.6823 8.89587C10.7163 8.91531 10.7431 8.94934 10.7625 8.99795L11.1854 10.0042L12.2354 10.4563C12.284 10.4757 12.3181 10.5025 12.3375 10.5365C12.3569 10.5705 12.3667 10.6118 12.3667 10.6604C12.3667 10.7091 12.3569 10.7504 12.3375 10.7844C12.3181 10.8184 12.284 10.8452 12.2354 10.8646L11.1854 11.3167L10.7625 12.2646C10.7431 12.3132 10.7139 12.3473 10.675 12.3667C10.6361 12.3861 10.5972 12.3959 10.5583 12.3959Z"
      fill="#121315"
    />
    <path
      d="M10.5583 5.10412C10.5097 5.10412 10.4683 5.0944 10.4343 5.07495C10.4003 5.05551 10.3735 5.02148 10.3541 4.97287L9.93119 3.9812L8.8666 3.51453C8.81799 3.49509 8.78396 3.46835 8.76452 3.43433C8.74507 3.4003 8.73535 3.35898 8.73535 3.31037C8.73535 3.26176 8.74507 3.22044 8.76452 3.18641C8.78396 3.15238 8.81799 3.12565 8.8666 3.1062L9.93119 2.65412L10.3541 1.7062C10.3735 1.65759 10.4003 1.62356 10.4343 1.60412C10.4683 1.58467 10.5097 1.57495 10.5583 1.57495C10.6069 1.57495 10.6482 1.58467 10.6822 1.60412C10.7163 1.62356 10.743 1.65759 10.7624 1.7062L11.1854 2.65412L12.2499 3.1062C12.2985 3.12565 12.3326 3.15238 12.352 3.18641C12.3715 3.22044 12.3812 3.26176 12.3812 3.31037C12.3812 3.35898 12.3715 3.4003 12.352 3.43433C12.3326 3.46835 12.2985 3.49509 12.2499 3.51453L11.1854 3.9812L10.7624 4.97287C10.743 5.02148 10.7163 5.05551 10.6822 5.07495C10.6482 5.0944 10.6069 5.10412 10.5583 5.10412Z"
      fill="#121315"
    />
    <path
      d="M4.85635 10.5729C4.77857 10.5729 4.70322 10.5511 4.63031 10.5073C4.55739 10.4636 4.50149 10.4028 4.4626 10.325L3.51468 8.29795L1.47301 7.3792C1.39524 7.34031 1.33447 7.28441 1.29072 7.21149C1.24697 7.13857 1.2251 7.06323 1.2251 6.98545C1.2251 6.90767 1.24697 6.83232 1.29072 6.75941C1.33447 6.68649 1.39524 6.63059 1.47301 6.5917L3.51468 5.67295L4.4626 3.66045C4.50149 3.57295 4.55739 3.50732 4.63031 3.46357C4.70322 3.41982 4.77857 3.39795 4.85635 3.39795C4.93413 3.39795 5.00947 3.41982 5.08239 3.46357C5.15531 3.50732 5.21121 3.56809 5.2501 3.64587L6.2126 5.67295L8.23968 6.5917C8.32718 6.63059 8.39281 6.68649 8.43656 6.75941C8.48031 6.83232 8.50218 6.90767 8.50218 6.98545C8.50218 7.06323 8.48031 7.13857 8.43656 7.21149C8.39281 7.28441 8.32718 7.34031 8.23968 7.3792L6.2126 8.29795L5.2501 10.325C5.21121 10.4125 5.15531 10.4757 5.08239 10.5146C5.00947 10.5535 4.93413 10.5729 4.85635 10.5729Z"
      fill="#121315"
    />
  </svg>
);
export default SvgGenerateSimilarBlackIcon;
