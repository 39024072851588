import * as React from "react";
import type { SVGProps } from "react";
const SvgLockIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="#8E8E8E"
        {...props}
    >
        <g id="Icons">
            <path id="Vector" d="M4.00008 15.1667C3.63341 15.1667 3.31953 15.0362 3.05841 14.7751C2.7973 14.514 2.66675 14.2001 2.66675 13.8334V7.16675C2.66675 6.80008 2.7973 6.48619 3.05841 6.22508C3.31953 5.96397 3.63341 5.83342 4.00008 5.83342H4.66675V4.50008C4.66675 3.57786 4.99175 2.79175 5.64175 2.14175C6.29175 1.49175 7.07786 1.16675 8.00008 1.16675C8.9223 1.16675 9.70841 1.49175 10.3584 2.14175C11.0084 2.79175 11.3334 3.57786 11.3334 4.50008V5.83342H12.0001C12.3667 5.83342 12.6806 5.96397 12.9417 6.22508C13.2029 6.48619 13.3334 6.80008 13.3334 7.16675V13.8334C13.3334 14.2001 13.2029 14.514 12.9417 14.7751C12.6806 15.0362 12.3667 15.1667 12.0001 15.1667H4.00008ZM4.00008 13.8334H12.0001V7.16675H4.00008V13.8334ZM8.00008 11.8334C8.36675 11.8334 8.68064 11.7029 8.94175 11.4417C9.20286 11.1806 9.33341 10.8667 9.33341 10.5001C9.33341 10.1334 9.20286 9.81953 8.94175 9.55842C8.68064 9.2973 8.36675 9.16675 8.00008 9.16675C7.63341 9.16675 7.31953 9.2973 7.05841 9.55842C6.7973 9.81953 6.66675 10.1334 6.66675 10.5001C6.66675 10.8667 6.7973 11.1806 7.05841 11.4417C7.31953 11.7029 7.63341 11.8334 8.00008 11.8334ZM6.00008 5.83342H10.0001V4.50008C10.0001 3.94453 9.80564 3.4723 9.41675 3.08341C9.02786 2.69453 8.55564 2.50008 8.00008 2.50008C7.44453 2.50008 6.9723 2.69453 6.58341 3.08341C6.19453 3.4723 6.00008 3.94453 6.00008 4.50008V5.83342Z" fill={props.fill} />
        </g>
    </svg>
);
export default SvgLockIcon;
