import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveTokenInfo: build.mutation<
      SaveTokenInfoApiResponse,
      SaveTokenInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/social-media`,
        method: "POST",
        body: queryArg.socialMediaRequest,
      }),
    }),
    sendPost: build.query<SendPostApiResponse, SendPostApiArg>({
      query: (queryArg) => ({ url: `/social-media/${queryArg.activityId}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type SaveTokenInfoApiResponse = unknown;
export type SaveTokenInfoApiArg = {
  socialMediaRequest: SocialMediaRequest;
};
export type SendPostApiResponse = unknown;
export type SendPostApiArg = {
  activityId: string;
};
export type SocialMediaRequest = {
  brandId?: string;
  channelType?:
    | "FACEBOOK"
    | "INSTAGRAM"
    | "X"
    | "LINKEDIN"
    | "BLOG"
    | "NEWSLETTER"
    | "GOOGLE_ADS"
    | "META_ADS";
  code?: string;
  accessToken?: string;
  consumerKey?: string;
  consumerSecret?: string;
  userId?: string;
  redirectUri?: string;
};
export const {
  useSaveTokenInfoMutation,
  useSendPostQuery,
  useLazySendPostQuery,
} = injectedRtkApi;
