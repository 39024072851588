import { ReactNode, useState } from "react";

interface Tab {
  title: string;
  element: ReactNode;
}

interface IGeneralHorizontalTabProps {
  tabs: Tab[];
  additionalContent?: React.ReactNode;
  noBorder?: boolean;
  additionalTopBarContent?: React.ReactNode;
  tabChangedTo?: (index: number) => void;
}

function GeneralHorizontalTab(props: IGeneralHorizontalTabProps) {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index: number) => {
    if(props.tabChangedTo){
      props.tabChangedTo(index);
    }
    setActiveTab(index);
  };
  const { tabs } = props;

  return (
    <div className="flex flex-col w-full ">
      <div className=" h-full w-full lg:h-[calc(100vh-150px)] flex flex-col ">
        <div
          className={`w-full min-h-[60px]  flex flex-row justify-start bg-white rounded-xl ${
            props.noBorder ?? false
              ? ""
              : "border-1 border-border justify-between"
          }`}
        >
          <div className="flex flex-row w-full">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`flex cursor-pointer py-2 px-4 ml-2 pl-2 mt-2 justify-end `}
                onClick={() => handleTabClick(index)}
              >
                <span
                  className={`h-full mt-2 text-black text-BodySmall font-semibold font-['Inter Tight'] leading-tight cursor-pointer ${
                    activeTab === index ? "text-black" : "text-gray"
                  } ${activeTab === index ? "border-b-2 border-red" : ""} px-3`}
                >
                  {tab.title}
                </span>
                {activeTab === index && (
                  <div
                    className="w-full h-[2px] ml-2"
                    style={{
                      position: "absolute",
                      bottom: "0",
                    }}
                  ></div>
                )}
              </div>
            ))}
          </div>
          <div className="flex flex-row mt-2 mr-4 items-center gap-4">{props.additionalTopBarContent}</div>
        </div>
        <div>{props.additionalContent}</div>
        <div className="mt-4 pb-4 flex justify-center items-center">
          {tabs[activeTab].element}
        </div>
      </div>
    </div>
  );
}

export default GeneralHorizontalTab;
