import React, { useState } from "react";
import { useEffect } from "react";

interface IToggleButtonProps {
  isChecked: boolean;
  handleCheckBoxChange: (boolean) => void;
}

const ToggleButtonSmall = (props: IToggleButtonProps) => {
  const [isChecked, setIsChecked] = useState(props.isChecked);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    props.handleCheckBoxChange(!isChecked);
  };

  useEffect(() => {
    setIsChecked(props.isChecked)
  }, [props.isChecked]);


  return (
    <>
      <label className="flex cursor-pointer select-none items-center">
        <div className="relative">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            className="sr-only"
          />
          <div
            className={`box block h-[15px] w-[25px] rounded-full ${
              isChecked ? "bg-rose-100" : "bg-white "} border border-red`}
          >
            <div
              className={`absolute left-[3px] top-[2px] h-[11px] w-[11px] rounded-full bg-red transition ${
                isChecked ? "translate-x-3/4" : ""
              }`}
            ></div>
          </div>
        </div>
      </label>
    </>
  );
};

export default ToggleButtonSmall;
