import * as React from "react";
import type { SVGProps } from "react";
const SvgPinterestColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#E60023"
      fillRule="evenodd"
      d="M18.333 10.5a8.333 8.333 0 1 1-16.667 0 8.333 8.333 0 0 1 16.667 0Zm-13.655-.2c0-2.629 1.91-5.043 5.507-5.043 2.89 0 5.137 2.06 5.137 4.813 0 2.872-1.81 5.183-4.324 5.183-.845 0-1.638-.439-1.91-.957l-.52 1.981c-.187.724-.695 1.631-1.035 2.184a8.215 8.215 0 0 1-.57-.207c-.073-.66-.138-1.667.03-2.385.15-.65.976-4.142.976-4.142s-.249-.5-.249-1.237c0-1.16.672-2.024 1.508-2.024.711 0 1.054.534 1.054 1.174 0 .457-.186 1.059-.382 1.692-.11.358-.223.725-.308 1.082-.197.83.416 1.507 1.234 1.507 1.48 0 2.62-1.562 2.62-3.816 0-1.996-1.434-3.39-3.481-3.39-2.371 0-3.763 1.778-3.763 3.616 0 .716.276 1.484.62 1.902a.25.25 0 0 1 .058.239c-.038.16-.105.43-.158.645l-.073.3c-.037.153-.121.184-.279.111-1.04-.485-1.692-2.006-1.692-3.229Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPinterestColor;
