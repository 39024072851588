import * as React from "react";
import type { SVGProps } from "react";
const SvgRightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    fill="none"
    {...props}
  >
    <path
      fill="#1A8CF5"
      d="M10.125 13.569a.55.55 0 0 1-.169-.403.52.52 0 0 1 .15-.385l2.757-2.756h-9.3A.546.546 0 0 1 3 9.462a.546.546 0 0 1 .563-.562h9.3l-2.775-2.775a.508.508 0 0 1-.16-.394.576.576 0 0 1 .178-.393c.113-.1.247-.15.403-.15a.52.52 0 0 1 .385.15l3.731 3.73a.571.571 0 0 1 .131.188.55.55 0 0 1 .038.207.55.55 0 0 1-.038.206.571.571 0 0 1-.131.187l-3.713 3.713a.54.54 0 0 1-.393.168.54.54 0 0 1-.394-.168Z"
    />
  </svg>
);
export default SvgRightArrow;
