import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserAddress, UserPaymentMethod, UserSubscription } from '../services/UsertApi'

export interface UserSettings {
    email_address: string;
    allow_notification_email: boolean;
    subscription?: UserSubscription;
    payment_method?: UserPaymentMethod;
    billing_address?: UserAddress;
    auth_provider?: string;
    trialed_before?: boolean
}


// Define the initial state using that type
const initialState: UserSettings = {
    email_address: "",
    allow_notification_email: false,
    subscription: undefined,
    payment_method: undefined,
    billing_address: undefined,
    auth_provider: undefined,
    trialed_before: undefined
}

export const userSettingsSlice = createSlice({
    name: 'userSettings',
    initialState,
    reducers: {
        setUserSettings: (state, newUserSettings: PayloadAction<UserSettings>) => {
            state.email_address = newUserSettings.payload.email_address
            state.allow_notification_email = newUserSettings.payload.allow_notification_email
            state.subscription = newUserSettings.payload.subscription
            state.payment_method = newUserSettings.payload.payment_method
            state.billing_address = newUserSettings.payload.billing_address
            state.auth_provider = newUserSettings.payload.auth_provider
            state.trialed_before = newUserSettings.payload.trialed_before
        },
    },
})

export const { setUserSettings } = userSettingsSlice.actions

export default userSettingsSlice.reducer