import * as React from "react";
const SvgPlayStoreIcon = (props) => (
  

<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.81 7.80225C12.1836 6.8926 9.57329 5.43192 5.52442 3.16487C5.25145 2.98459 4.93738 2.96096 4.66602 3.05281L11.6127 9.99952L13.81 7.80225Z" fill="#32BBFF"/>
<path d="M4.66755 3.05273C4.61669 3.06996 4.56719 3.09074 4.51986 3.11584C4.2202 3.27794 4 3.58985 4 3.98434V16.0146C4 16.409 4.22017 16.721 4.51986 16.883C4.56711 16.9081 4.61658 16.929 4.66738 16.9463L11.6143 9.99945L4.66755 3.05273Z" fill="#32BBFF"/>
<path d="M11.6129 9.99951L4.66602 16.9464C4.93746 17.0389 5.25153 17.0171 5.52459 16.8342C9.45111 14.6354 12.032 13.1921 13.6772 12.2747C13.723 12.249 13.7679 12.2238 13.8122 12.1989L11.6129 9.99951Z" fill="#32BBFF"/>
<path d="M4 9.99951V16.0146C4 16.4091 4.22017 16.721 4.51986 16.8831C4.56711 16.9082 4.61658 16.929 4.66738 16.9464L11.6143 9.99951H4Z" fill="#2C9FD9"/>
<path d="M5.52562 3.16483C5.20146 2.95078 4.81919 2.95688 4.51953 3.11591L11.5085 10.1049L13.8112 7.80221C12.1848 6.89256 9.57449 5.43188 5.52562 3.16483Z" fill="#29CC5E"/>
<path d="M11.5085 9.89404L4.51953 16.883C4.81922 17.042 5.20146 17.0512 5.52562 16.8341C9.45215 14.6354 12.0331 13.192 13.6783 12.2746C13.7241 12.2489 13.7689 12.2237 13.8133 12.1988L11.5085 9.89404Z" fill="#D93F21"/>
<path d="M16.7111 9.99947C16.7111 9.66615 16.5429 9.32976 16.2095 9.14322C16.2095 9.14322 15.5861 8.79538 13.6752 7.72656L11.4023 9.99947L13.6775 12.2746C15.5674 11.2135 16.2095 10.8557 16.2095 10.8557C16.5429 10.6692 16.7111 10.3328 16.7111 9.99947Z" fill="#FFD500"/>
<path d="M16.2095 10.8558C16.5429 10.6692 16.7111 10.3328 16.7111 9.99951H11.4023L13.6775 12.2747C15.5674 11.2136 16.2095 10.8558 16.2095 10.8558Z" fill="#FFAA00"/>
</svg>



);
export default SvgPlayStoreIcon;
