import * as React from "react";
import type { SVGProps } from "react";
const SvgPlusTritoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="40" height="40" rx="5" fill="#FF5555" />
    <path
      d="M19.0062 20.9938H15.3834C15.1073 20.9938 14.8727 20.8972 14.6794 20.704C14.4862 20.5107 14.3896 20.2761 14.3896 20C14.3896 19.7239 14.4862 19.4893 14.6794 19.2961C14.8727 19.1028 15.1073 19.0062 15.3834 19.0062H19.0062V15.3834C19.0062 15.1073 19.1028 14.8727 19.296 14.6795C19.4892 14.4863 19.7239 14.3897 20 14.3897C20.2761 14.3897 20.5107 14.4863 20.7039 14.6795C20.8971 14.8727 20.9938 15.1073 20.9938 15.3834V19.0062H24.6166C24.8927 19.0062 25.1273 19.1028 25.3205 19.2961C25.5137 19.4893 25.6103 19.7239 25.6103 20C25.6103 20.2761 25.5137 20.5107 25.3205 20.704C25.1273 20.8972 24.8927 20.9938 24.6166 20.9938H20.9938V24.6166C20.9938 24.8927 20.8971 25.1273 20.7039 25.3205C20.5107 25.5137 20.2761 25.6103 20 25.6103C19.7239 25.6103 19.4892 25.5137 19.296 25.3205C19.1028 25.1273 19.0062 24.8927 19.0062 24.6166V20.9938Z"
      fill="white"
    />
  </svg>
);
export default SvgPlusTritoneIcon;


