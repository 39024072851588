import * as React from "react";
const SvgBrandColorsPaletteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <rect width={28} height={28} fill="#F55" opacity={0.1} rx={4} />
    <path
      fill="#F55"
      d="M12 20.667H9.335a2.002 2.002 0 0 1-2-2V9.333a2.002 2.002 0 0 1 2-2h2.667a2.002 2.002 0 0 1 2 2v9.334a2.003 2.003 0 0 1-2 2Z"
      opacity={0.2}
    />
    <path
      fill="#F55"
      d="M19.528 10.357 17.643 8.47a2.003 2.003 0 0 0-2.829 0l-.82.819c0 .015.005.029.005.043v9.334c0 .018-.004.034-.005.053l5.534-5.535a2.002 2.002 0 0 0 0-2.828Z"
      opacity={0.5}
    />
    <path
      fill="#F55"
      d="M10.667 18a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333ZM18.71 14.005l-4.715 4.714c0-.018.005-.034.005-.052a2.002 2.002 0 0 1-2 2h6.667a2.002 2.002 0 0 0 2-2V16a2 2 0 0 0-1.957-1.995Z"
    />
  </svg>
);
export default SvgBrandColorsPaletteIcon;
