import { useLayoutEffect, useState } from 'react'
import { Plan } from '../../../services/PlanApi'
import { t } from 'i18next'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import SvgDone from '../../../assets/IconComponents/Done'
import SvgDone2 from '../../../assets/IconComponents/Done-2'
import SvgQuestionMark from '../../../assets/IconComponents/QuestionMark'
import { FacebookColor, PinterestColor, LinkedinColor, InstagramColor, TwitterColor, Medium } from '../../../assets/IconComponents'
import { useAppDispatch } from "../../../helpers/hooks";
import { toggleDialog, toggleMenu } from "../../../store/appSlice";
import SubscriptionModelMobilePopup from '../SubscriptionPopup/SubscriptionModelMobilePopup';
import '../../LandingPage/Styles/Carousel.scss'

interface IBenefitsTableMobileProps {
    plans: Plan[]
    selectedPlanType: "monthly" | "yearly"
}

export interface PlanBenefit {
    groupId: string,
    id: string,
    soon: boolean
    value: boolean | string | number
    mostPopular: boolean
    planId: string
}

const BenefitsTableMobile = (props: IBenefitsTableMobileProps) => {
    const [benefits, setBenefits] = useState<PlanBenefit[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<number>(0);
    const [paymentModelOpen, setPaymentModalOpen] = useState(false);
    const iconMap = {
        "social-media": <> <FacebookColor /><TwitterColor /><InstagramColor /><LinkedinColor /><PinterestColor /></>,
        "blog": <><Medium /></>,
    }
    const [skipTrialFlag, setSkipTrialFlag] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    
    useLayoutEffect(() => {
        setBenefits(JSON.parse(props.plans[selectedPlan]?.benefits ?? "[]"));
    }, [props.plans, selectedPlan])

    return(
        <div>
            {paymentModelOpen && <SubscriptionModelMobilePopup skipTrial={skipTrialFlag} setSkipTrialFlag={setSkipTrialFlag} isOpen={paymentModelOpen} selectedPlan={props.plans[selectedPlan]} paymentTimeframe={props.selectedPlanType} setIsOpen={setPaymentModalOpen} onSubscriptionCompleted={(trialEndDateParam) => {
                                setPaymentModalOpen(false);
                                dispatch(toggleDialog(false));
                                dispatch(toggleMenu(true));
                            }}/>}
            {props.plans.length && <Carousel className='m-4' onChange={(index) => {setSelectedPlan(index)}} showThumbs={false} showStatus={false}  swipeable={true} emulateTouch={true} swipeScrollTolerance={50} preventMovementUntilSwipeScrollTolerance={true} >
                {props.plans.map((p, index) => (
                    <div key={index} className='flex p-[16px] flex-col justify-between border-solid border-1 border-grey-500 rounded-lg' style={{height: "300px"}}>
                        <div className='flex flex-col gap-[20px]'>
                            <div className='flex align-center gap-[10px] items-center'>
                                <div className='h1 text-left text-black'>
                                    ${props.selectedPlanType === "monthly" ? p.monthlyPrice : p.yearlyPrice}
                                </div>
                                <div className='flex flex-col gap-2px'>
                                    <div className='body-big text-left text-black'>
                                        /month
                                    </div>
                                    <div className='body-medium' style={{color: "#8E8E8E"}}>
                                        {props.selectedPlanType === "monthly" ? "(billed monthly)" : "(billed annually)"}
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col gap-[10px] text-left'>
                                <div className='h5 text-black'>
                                    {p.name}
                                </div>
                                <div className='body-small' style={{color: "#8E8E8E"}}>
                                    {p.description}
                                </div>
                            </div>
                        </div>
                        <button className='flex px-[12px] py-[14px] justify-center items-center h7 text-white border-solid rounded-md border-1 border-grey-500 bg-red' onClick={() => {setPaymentModalOpen(true)}}>Start 7-day Free Trial</button>
                        <span className="text-red text-[13px] py-2 font-normal font-['Inter Tight'] underline leading-[18.20px] cursor-pointer" onClick={() => {
                            setSkipTrialFlag(true);
                            setPaymentModalOpen(true);
                        }}>Skip trial, get %20 off</span>
                    </div>))}
            </Carousel>}
            <div id={window.innerWidth < 768 ? "BENEFITS_TABLE" : ""} className='flex px-[20px] py-[15px] flex-col border-solid border-1 border-grey-500 rounded-lg m-4'>
                <div className='flex gap-[10px] items-center px-[10px] py-[15px]'>
                    <SvgDone className="w-4 h-4 relative" />
                    <div className='h7 text-rose-400'>
                        {`${props.plans[selectedPlan]?.credits}`} Monthly Credits
                    </div>
                </div>
                <div className='flex pt-[30px] px-[10px] pb-[15px] gap-10px h5 text-black'>AI-Powered Ad Generation</div>
                {benefits.filter((b) => b.groupId === "ai-powered-ad-generation").map((b, index) => (
                    <div key={index} className='flex gap-[10px] items-center px-[10px] py-[15px]'>
                        {b.value ? <SvgDone className="w-4 h-4 relative" /> : <SvgDone2 className="w-4 h-4 relative" />}
                        <div className={`${b.value ? "text-rose-400" : "text-neutral-400"} h7 text-sm font-semibold font-['Inter Tight'] leading-tight`}>{t(b.id)}</div>
                        {b.id !== "video" && <SvgQuestionMark className="w-4 h-4 relative" data-tooltip-id={`app-tooltip`} data-tooltip-html={t(`${b.id}-benefit-tooltip`)}/>}
                        {b.soon && <div className="text-right text-red text-[10px] font-bold font-['Inter Tight'] leading-[14px] tracking-wide">
                            SOON
                        </div>}
                    </div>
                ))}
                <div className='flex pt-[30px] px-[10px] pb-[15px] gap-10px h5 text-black'>AI Marketing Automation</div>
                {benefits.filter((b) => b.groupId === "ai-marketing-operation").map((b, index) => (
                    <div key={index} className='flex gap-[10px] items-center px-[10px] py-[15px]'>
                        {b.value ? <SvgDone className="w-4 h-4 relative" /> : <SvgDone2 className="w-4 h-4 relative" />}
                        <div className={`${b.value ? "text-rose-400" : "text-neutral-400"} h7 text-sm font-semibold font-['Inter Tight'] leading-tight`}>{t(b.id)}</div>
                        <SvgQuestionMark className="w-4 h-4 relative" data-tooltip-id={`app-tooltip`} data-tooltip-html={t(`${b.id}-benefit-tooltip`)}/>
                        {b.soon && <div className="text-right text-red text-[10px] font-bold font-['Inter Tight'] leading-[14px] tracking-wide">
                            SOON
                        </div>}
                    </div>
                ))}
                <div className='flex pt-[30px] px-[10px] pb-[15px] gap-10px h5 text-black'>Integrations</div>
                {benefits.filter((b) => b.groupId === "integrations").map((b, index) => (
                    <div key={index} className='flex gap-[10px] items-center px-[10px] py-[15px]'>
                        {b.value ? <SvgDone className="w-4 h-4 relative" /> : <SvgDone2 className="w-4 h-4 relative" />}
                        <div className={`${b.value ? "text-rose-400" : "text-neutral-400"} h7 text-sm font-semibold font-['Inter Tight'] leading-tight`}>{t(b.id)}</div>
                        <SvgQuestionMark className="w-4 h-4 relative" data-tooltip-id={`app-tooltip`} data-tooltip-html={t(`${b.id}-benefit-tooltip`)}/>
                        {b.soon && <div className="text-right text-red text-[10px] font-bold font-['Inter Tight'] leading-[14px] tracking-wide">
                            SOON
                        </div>}
                        {iconMap[b.id] && <div className="w-[120px] h-5 justify-start items-center gap-[5px] inline-flex">
                             {iconMap[b.id]}
                        </div>}
                    </div>
                ))}
                <div className='flex pt-[30px] px-[10px] pb-[15px] gap-10px h5 text-black'>Access to AI Models</div>
                {benefits.filter((b) => b.groupId === "access-to-ai-models").map((b, index) => (
                    <div key={index} className='flex gap-[10px] items-center px-[10px] py-[15px]'>
                        {b.value ? <SvgDone className="w-4 h-4 relative" /> : <SvgDone2 className="w-4 h-4 relative" />}
                        <div className={`${b.value  ? "text-rose-400" : "text-neutral-400"} h7 text-sm font-semibold font-['Inter Tight'] leading-tight`}>{t(b.id)}</div>
                        <SvgQuestionMark className="w-4 h-4 relative" data-tooltip-id={`app-tooltip`} data-tooltip-html={t(`${b.id}-benefit-tooltip`)} />
                        {b.soon && <div className="text-right text-red text-[10px] font-bold font-['Inter Tight'] leading-[14px] tracking-wide">
                            SOON
                        </div>}
                    </div>
                ))}
                <div className='flex pt-[30px] px-[10px] pb-[15px] gap-10px h5 text-black'>Brand Hub</div>
                {benefits.filter((b) => b.groupId === "brand-hub").map((b, index) => (
                    <div key={index} className='flex gap-[10px] items-center px-[10px] py-[15px]'>
                        {b.value ? <SvgDone className="w-4 h-4 relative" /> : <SvgDone2 className="w-4 h-4 relative" />}
                        <div className={`${b.value  ? "text-rose-400" : "text-neutral-400"} h7 text-sm font-semibold font-['Inter Tight'] leading-tight`}>{t(b.id)}</div>
                        <SvgQuestionMark className="w-4 h-4 relative" data-tooltip-id={`app-tooltip`} data-tooltip-html={t(`${b.id}-benefit-tooltip`)} />
                        {b.id === "number-of-brands" && <div className="h7 text-sm font-semibold font-['Inter Tight'] leading-tight text-black">
                            {b.value}
                        </div>}
                        {b.soon && <div className="text-right text-red text-[10px] font-bold font-['Inter Tight'] leading-[14px] tracking-wide">
                            SOON
                        </div>}
                    </div>
                ))}
                                <div className='flex pt-[30px] px-[10px] pb-[15px] gap-10px h5 text-black'>Support</div>
                {benefits.filter((b) => b.groupId === "support").map((b, index) => (
                    <div key={index} className='flex gap-[10px] items-center px-[10px] py-[15px]'>
                        {b.value ? <SvgDone className="w-4 h-4 relative" /> : <SvgDone2 className="w-4 h-4 relative" />}
                        <div className={`${b.value ? "text-rose-400" : "text-neutral-400"} h7 text-sm font-semibold font-['Inter Tight'] leading-tight`}>{t(b.id)}</div>
                        <div className="h7 text-sm font-semibold font-['Inter Tight'] leading-tight text-black">
                            {b.value}
                        </div>
                        {b.soon && <div className="text-right text-red text-[10px] font-bold font-['Inter Tight'] leading-[14px] tracking-wide">
                            SOON
                        </div>}
                    </div>
                ))}
            </div>
                
                
        </div>
        
    )
}

export default BenefitsTableMobile;