import * as React from "react";
import type { SVGProps } from "react";
const SvgDisabledDavinciIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="#999"
      d="M20.276 9.004a5.489 5.489 0 0 0-.47-4.501 5.545 5.545 0 0 0-5.97-2.66A5.48 5.48 0 0 0 9.735 0h-.048a5.545 5.545 0 0 0-5.274 3.838A5.482 5.482 0 0 0 .749 6.497a5.547 5.547 0 0 0 .682 6.5 5.483 5.483 0 0 0 .47 4.5 5.544 5.544 0 0 0 5.97 2.66 5.484 5.484 0 0 0 4.1 1.843h.049a5.543 5.543 0 0 0 5.275-3.84 5.482 5.482 0 0 0 3.664-2.659 5.538 5.538 0 0 0-.683-6.497Zm-8.269 11.558h-.006a4.115 4.115 0 0 1-2.633-.954l.13-.074 4.38-2.53a.713.713 0 0 0 .36-.62v-6.18l1.852 1.07a.066.066 0 0 1 .036.05v5.115a4.128 4.128 0 0 1-4.119 4.123Zm-8.858-3.784a4.114 4.114 0 0 1-.492-2.762l.13.078 4.38 2.53a.714.714 0 0 0 .72 0l5.348-3.088v2.142a.067.067 0 0 1-.026.053L8.78 18.287a4.132 4.132 0 0 1-4.122 0 4.128 4.128 0 0 1-1.51-1.509ZM1.997 7.217a4.11 4.11 0 0 1 2.146-1.808l-.002.151V10.624a.712.712 0 0 0 .359.618l5.348 3.088-1.851 1.069a.067.067 0 0 1-.063.006l-4.428-2.56a4.128 4.128 0 0 1-1.51-5.63Zm15.212 3.54L11.86 7.668l1.851-1.069a.066.066 0 0 1 .062-.006l4.429 2.557a4.124 4.124 0 0 1-.637 7.44v-5.217a.712.712 0 0 0-.357-.617Zm1.842-2.774a6.068 6.068 0 0 0-.13-.078l-4.38-2.53a.715.715 0 0 0-.72 0L8.473 8.462V6.32c0-.02.01-.04.027-.053l4.428-2.554a4.124 4.124 0 0 1 6.123 4.269ZM7.467 11.793l-1.852-1.07a.066.066 0 0 1-.036-.05V5.56a4.123 4.123 0 0 1 6.761-3.166l-.13.073-4.38 2.53a.712.712 0 0 0-.36.62v.004l-.003 6.173Zm1.005-2.168 2.382-1.376 2.382 1.375v2.75l-2.382 1.375-2.382-1.375v-2.75Z"
    />
  </svg>
);
export default SvgDisabledDavinciIcon;
