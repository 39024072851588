import { FunctionComponent, useEffect } from "react";
import Content from "./Content";
import ControlButtons from "./ControlButtons";
import Header from "./Header";
import { FileProps } from "../../../services/BrandApi";
import { AudienceResponse } from "../../../services/AudiencetApi";

interface ContentDetailsProps {
    logoSelectionList: FileProps[]
    productSelectionList: FileProps[]
    onAudienceEdit: (data: AudienceResponse) => void
    onAudienceCreate: () => void
    recentlyAddedAudience: AudienceResponse[]
    isInSide?: boolean
    setContentCreationDataID?: (id: string) => void
    setIsInspirationThemeSelectionOpen?: (status: boolean) => void
    setIsLogoSelectionOpen?: (status: boolean) => void
    setIsProductSelectorOpen?: (status: boolean) => void
    referenceFileSelectionList?: FileProps[]
    onCreditError?: () => void
}

const ContentDetails: FunctionComponent<ContentDetailsProps> = (props: ContentDetailsProps) => {

    return <div className={`flex flex-col w-full ${props.isInSide ? 'relative' : 'h-full relative'} justify-between`}>
        <div className="flex flex-col w-full">
            {!props.isInSide && <Header />}
            <Content
                setIsInspirationThemeSelectionOpen={props.setIsInspirationThemeSelectionOpen}
                setIsLogoSelectionOpen={props.setIsLogoSelectionOpen}
                setIsProductSelectorOpen={props.setIsProductSelectorOpen}
                isInSide={props.isInSide}
                logoSelectionList={props.logoSelectionList}
                productSelectionList={props.productSelectionList}
                onAudienceEdit={props.onAudienceEdit}
                onAudienceCreate={props.onAudienceCreate}
                recentlyAddedAudience={props.recentlyAddedAudience}
                referenceFilesSelectionList={props.referenceFileSelectionList || []}
            />
        </div>
        <div className={`${props.isInSide ? 'absolute right-0 bottom-0' : ''}`}><ControlButtons isInSide={props.isInSide} setContentCreationDataID={props.setContentCreationDataID} onCreditError={props.onCreditError} /></div>
    </div >
}

export default ContentDetails;
