import { useCallback, useRef, useState } from "react";
import { Back, Close, Search } from "../../../assets/IconComponents";
import IconButton from "../../../components/IconButton";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { ContentWizardView } from "../../../models/ContentWizardView";
import { toggleContentWizard } from "../../../store/appSlice";
import { setCreativeThemeSearchTerm, setNewContent } from "../../../store/contentSlice";
import debounce from 'lodash.debounce';

interface IHeaderProps {
    isThemeGallery: boolean
}
function Header(props: IHeaderProps) {
    const newContent = useAppSelector(k => k.content.newContent)
    const dispatch = useAppDispatch();
    const [searchTerm, setSearchTerm] = useState('')

    const handleSearchBar = useCallback(
            debounce((value) => {
                dispatch(setCreativeThemeSearchTerm(value));
            }, 300), []
    )

    const handleChange = (event) => {
        setSearchTerm(event.target.value)
        handleSearchBar(event.target.value)
    }

    return (<>
        <div className="w-full h-14 px-5 bg-white shadow-inner justify-start items-center gap-5 inline-flex border-b-1 shrink-0">
            <div className="w-5 h-5 relative">
                <IconButton
                    title={""}
                    icon={Back}
                    onClick={function (e: any): void {
                        dispatch(setCreativeThemeSearchTerm(""))
                        dispatch(setNewContent({
                            ...newContent,
                            wizardView: ContentWizardView.contentDetails
                        }));
                    }} />
            </div>
            <div className="grow shrink basis-0 h-[22px] justify-start items-center gap-2.5 flex">
                <div className="grow shrink basis-0 text-black text-base font-semibold font-['Inter Tight'] leading-snug">{`${props.isThemeGallery ? "View Theme Gallery" : "View Inspiration Gallery"}`} </div>
            </div>
            {
            /*props.isThemeGallery && <div className= "w-[500px] flex h-[50px] justify-between items-center rounded bg-white relative">
                <input className="text-black flex-1 box-border w-full h-full rounded pl-2 focus:outline-none font-normal" value={searchTerm} placeholder="Search for a Theme" onChange={handleChange}/>
                <span className="absolute right-2">
                    <Search width="20" height="20" fill="#FF5555" className="cursor-pointer"/>
                </span>
            </div>
            */}
            <div className="w-5 h-5 relative">
                <IconButton
                    title={""}
                    icon={Close}
                    onClick={function (e: any): void {
                        dispatch(setCreativeThemeSearchTerm(""))
                        dispatch(setNewContent(undefined));
                        dispatch(toggleContentWizard());
                    }} />
            </div>
        </div>
    </>)
}

export default Header;
