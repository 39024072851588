import * as React from "react";
const TrafficIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={19}
        fill="none"
        {...props}
    >
        <path opacity="0.2"
              d="M6 8.5H2C1.73478 8.5 1.48043 8.60536 1.29289 8.79289C1.10536 8.98043 1 9.23478 1 9.5C1.00084 10.917 1.50343 12.288 2.41868 13.3698C3.33392 14.4516 4.60268 15.1744 6 15.41V8.5Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path opacity="0.5"
              d="M15 22.5H9C8.20459 22.4992 7.44199 22.1829 6.87956 21.6204C6.31712 21.058 6.00079 20.2954 6 19.5V4.5C6.00079 3.70459 6.31712 2.94199 6.87956 2.37956C7.44199 1.81712 8.20459 1.50079 9 1.5H15C15.7954 1.50079 16.558 1.81712 17.1204 2.37956C17.6829 2.94199 17.9992 3.70459 18 4.5V19.5C17.9992 20.2954 17.6829 21.058 17.1204 21.6204C16.558 22.1829 15.7954 22.4992 15 22.5Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path
            d="M6 4.5C6 3.728 6.301 3.032 6.78 2.5H2C1.73478 2.5 1.48043 2.60536 1.29289 2.79289C1.10536 2.98043 1 3.23478 1 3.5C1.00084 4.91704 1.50343 6.28801 2.41868 7.36982C3.33392 8.45164 4.60268 9.17442 6 9.41V4.5ZM6 19.5V15.41C5.17373 15.2722 4.38567 14.9621 3.687 14.5H2C1.73478 14.5 1.48043 14.6054 1.29289 14.7929C1.10536 14.9804 1 15.2348 1 15.5C1.00097 17.0505 1.60249 18.5403 2.67832 19.6567C3.75415 20.7732 5.22064 21.4296 6.77 21.488C6.27697 20.943 6.00274 20.2349 6 19.5ZM22 14.5H20.313C19.6143 14.9622 18.8263 15.2722 18 15.41V19.5C17.9973 20.2349 17.723 20.943 17.23 21.488C18.7794 21.4296 20.2458 20.7732 21.3217 19.6567C22.3975 18.5403 22.999 17.0505 23 15.5C23 15.2348 22.8946 14.9804 22.7071 14.7929C22.5196 14.6054 22.2652 14.5 22 14.5Z"
            fill={props.fill ?? "#FFFFFF"}/>
        <path opacity="0.2"
              d="M22 8.5H18V15.41C19.3973 15.1744 20.6661 14.4516 21.5813 13.3698C22.4966 12.288 22.9992 10.917 23 9.5C23 9.23478 22.8946 8.98043 22.7071 8.79289C22.5196 8.60536 22.2652 8.5 22 8.5Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path
            d="M22 2.5H17.22C17.699 3.032 18 3.728 18 4.5V9.41C19.3973 9.17442 20.666 8.45164 21.5813 7.36982C22.4965 6.28801 22.9991 4.91704 23 3.5C23 3.23478 22.8946 2.98043 22.7071 2.79289C22.5195 2.60536 22.2652 2.5 22 2.5Z"
            fill={props.fill ?? "#FFFFFF"}/>
        <path
            d="M12 8.5C12.8284 8.5 13.5 7.82843 13.5 7C13.5 6.17157 12.8284 5.5 12 5.5C11.1716 5.5 10.5 6.17157 10.5 7C10.5 7.82843 11.1716 8.5 12 8.5Z"
            fill={props.fill ?? "#FFFFFF"}/>
        <path
            d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z"
            fill={props.fill ?? "#FFFFFF"}/>
        <path
            d="M12 18.5C12.8284 18.5 13.5 17.8284 13.5 17C13.5 16.1716 12.8284 15.5 12 15.5C11.1716 15.5 10.5 16.1716 10.5 17C10.5 17.8284 11.1716 18.5 12 18.5Z"
            fill={props.fill ?? "#FFFFFF"}/>
    </svg>
);
export default TrafficIcon;
