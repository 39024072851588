import * as React from "react";
import type { SVGProps } from "react";
const SvgMedia = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M6.042 9.583A3.546 3.546 0 0 1 2.5 6.042 3.546 3.546 0 0 1 6.042 2.5a3.546 3.546 0 0 1 3.541 3.542 3.546 3.546 0 0 1-3.541 3.541ZM7.9 17.5H4.183a1.662 1.662 0 0 1-1.448-.84 1.706 1.706 0 0 1-.007-1.72l1.858-3.255a1.66 1.66 0 0 1 1.456-.852 1.66 1.66 0 0 1 1.455.852l1.858 3.256c.308.54.306 1.182-.006 1.72A1.66 1.66 0 0 1 7.9 17.5Zm7.308-8.333h-2.5a1.877 1.877 0 0 1-1.875-1.875v-2.5c0-1.034.842-1.875 1.875-1.875h2.5c1.034 0 1.875.84 1.875 1.875v2.5a1.877 1.877 0 0 1-1.875 1.875Zm-.232 8.33h-.006l-2.077-.007a1.938 1.938 0 0 1-1.843-1.348l-.635-1.977a1.937 1.937 0 0 1 .713-2.17l1.684-1.214a1.938 1.938 0 0 1 2.283.007l1.676 1.226c.681.498.962 1.372.698 2.174l-.648 1.973a1.936 1.936 0 0 1-1.845 1.336Z" />
  </svg>
);
export default SvgMedia;
