import { FunctionComponent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import SvgAddBriefCreativeTritoneLogo from "../../../assets/IconComponents/AddBriefCreativeTritoneLogo";
import SvgQuestionMark from "../../../assets/IconComponents/QuestionMark";
import UploadSingleFile from "../../../components/UploadFile/UploadSingleFile";
import UploadFileInfinite from "../../../components/UploadFile/UploadFileInfinite";
import ChooseAnAspirationOrTheme from "./ChooseAnInspirationOrTheme";
import { FileProps } from "../../../services/BrandApi";
import { InspirationResponse, useLazyGetCreativeInspirationQuery } from "../../../services/InspirationApi";
import { toggleLoading } from "../../../store/appSlice";
import { setGuidelinesState, setNewContent } from "../../../store/contentSlice";
import { ContentWizardView } from "../../../models/ContentWizardView";
import { useLazyThemesQuery, useLazyTrendingThemesQuery } from "../../../services/CreativeApi";
import { Mediums } from "../../../constants/Mediums";
import { ContentType } from "../../../constants/ContentType";
import AddYourOwnInspirationPopup from "../../ContentWizard/AddYourOwnInspirationPopup";

interface ICreativeAddBriefProps {
}


const CreativeAddBrief: FunctionComponent<ICreativeAddBriefProps> = (
  props: ICreativeAddBriefProps
) => {
  const newContent = useAppSelector((k) => k.content.newContent);
  const guidelinesState = useAppSelector((k) => k.content.guidelinesState);
  const dispatch = useAppDispatch();
  const [uploadedImagesList, setUploadedImagesList] = useState<FileProps[]>([]);
  const [selectedTheme, setSelectedTheme] = useState<string>(newContent?.creative_theme ?? '');
  const [selectedInspirations, setSelectedInspirations] = useState<string>(newContent?.adId ?? '');
  const [inspirations, setInspirations] = useState<FileProps[]>([]);
  const [trendingThemes, setTrendingThemes] = useState<FileProps[]>([]);
  const [jointInspirations, setJointInspirations] = useState<FileProps[]>([]);
  const [jointThemes, setJointThemes] = useState<FileProps[]>([]);
  const { selectedInspirationForYou, selectedThemeImage } = useAppSelector((k) => k.content);
  const [
    getInspirations,
    {
      error: errorInspirationsLoading,
      isFetching: inspirationsFetching,
      data: inspirationsList,
    },
  ] = useLazyGetCreativeInspirationQuery();
  const [
    getThemes,
    {
      /*error: errorThemesLoading,
      isFetching: themesFetching,*/
      data: themesList
    },
  ] = useLazyThemesQuery();

  const [
    getTrendingThemes,
    {
      error: errorTrendingThemesLoading,
      isFetching: trendingThemesFetching,
      data: trendingThemesList,
    },
  ] = useLazyTrendingThemesQuery();

  const setTab = (val) => {
    if (val !== null) {
      dispatch(setGuidelinesState(val));
    }
  }

  useEffect(() => {
    if (inspirationsList && inspirationsList.length > 0) {
      let inspoFileList: FileProps[] = inspirationsList.map(inspo => {
        return { adId: inspo.adId, id: inspo.fileId, name: inspo.subCategory, uploaded: false, objective: inspo.objective, industry: inspo.industry }
      })
      if (selectedInspirationForYou && inspirationsList.filter(item => item.adId == selectedInspirationForYou.adId).length == 0) {
        inspoFileList = [selectedInspirationForYou, ...inspoFileList]
      }
      setInspirations(inspoFileList);
    }
  }, [inspirationsList]);

  useEffect(() => {
    if (themesList && themesList.length > 0) {
      let themeFileList: FileProps[] = themesList
      if (selectedThemeImage && themeFileList.filter(item => item.id == selectedThemeImage.id).length == 0) {
        themeFileList = [selectedThemeImage, ...themeFileList]
      }
      if (themeFileList) {
        if (selectedTheme) {
          setJointThemes([...themeFileList.filter(theme => theme.id === selectedTheme), ...themeFileList.filter(theme => theme.id !== selectedTheme).slice(0, 3)])
        } else {
          setJointThemes(themeFileList.slice(0, 4))
        }
      }
    }
  }, [themesList, selectedThemeImage]);

  useEffect(() => {
    if (trendingThemesList && trendingThemesList.length > 0) {

      const trendingFilesList: FileProps[] = trendingThemesList.map((theme) => {
        const file: FileProps = {
          id: theme.imageId,
          name: theme.id,
          uploaded: false,
          fileDescription: theme.childConcept + " " + theme.subChildConcept
        };
        return file;
      });
      setTrendingThemes(trendingFilesList);
    }
  }, [trendingThemesList]);

  useEffect(() => {
    getInspirations({
      page: 0,
      size: 20,
    });
    getThemes({
      page: 0,
      size: 20,
    });
    getTrendingThemes({page: 0, size: 10});
  }, []);

  useEffect(() => {
    dispatch(toggleLoading(inspirationsFetching));
  }, [inspirationsFetching]);
  // spinner

  useEffect(() => {
    setJointInspirationsList();
  }, [uploadedImagesList, inspirations]);

  const setJointInspirationsList = (data?) => {
    const list = data ?? [...inspirations]

    if (list) {
      if (selectedInspirations) {
        if (uploadedImagesList) {
          setJointInspirations([...uploadedImagesList, ...list.filter(inspiration => inspiration.adId === selectedInspirations), ...list.filter(inspiration => inspiration.adId !== selectedInspirations).slice(0, 2 - uploadedImagesList.length)])
        } else {
          setJointInspirations([...list.filter(inspiration => inspiration.adId === selectedInspirations), ...list.filter(inspiration => inspiration.adId !== selectedInspirations).slice(0, 2)])
        }
      } else {
        if (uploadedImagesList) {
          setJointInspirations([...uploadedImagesList, ...list.slice(0, 3 - uploadedImagesList.length)])
        } else {
          setJointInspirations(list.slice(0, 3))
        }
      }
    }
  }

  return (
    <div
      className={`self-stretch justify-start items-start gap-3 flex flex-row`}
    >
      <div className="w-[240px] h-7 justify-start items-center gap-2.5 flex shrink-0">
        <SvgAddBriefCreativeTritoneLogo />
        <div className="text-black text-sm font-semibold font-['Inter Tight'] leading-tight">
          Creative Direction
        </div>
        <SvgQuestionMark
          className="mt-1 pr-1"
          data-tooltip-id={"app-tooltip"}
          data-tooltip-html={
            "Inspired by an Ad? Upload to create similar ones. \n\n Simply upload an ad you love, or choose one from our gallery. We'll explore its creative elements to design ads that uniquely reflect your brand's identity."
          }
        ></SvgQuestionMark>
      </div>
      <div>
        <div className="flex flex-col  h-[260px] w-full">
          <div className="flex-row border-1 border-border grid grid-cols-2 w-[520px] p-[4px] h-[40px] items-center rounded-[4px]">
            <div
              onClick={() => setTab(0)}
              className="flex flex-row rounded-[2px] h-full items-center justify-center cursor-pointer"
              style={{
                backgroundColor: guidelinesState === 0 ? "#FFEEEE" : "",
                transition: "background-color 0.3s ease",
              }}
            >
              <p
                className={`${
                  guidelinesState === 0 ? "text-red" : "text-gray"
                } text-BodySmall`}
              >
                Choose an Inspiration
              </p>
            </div>
            <div
              onClick={() => setTab(1)}
              className="flex flex-row rounded-[2px] h-full items-center justify-center cursor-pointer"
              style={{
                backgroundColor: guidelinesState === 1 ? "#FFEEEE" : "",
                transition: "background-color 0.3s ease",
              }}
            >
              <p
                className={`${
                  guidelinesState === 1 ? "text-red" : "text-gray"
                } text-BodySmall`}
              >
                Choose a Theme
              </p>
            </div>
          </div>
          {guidelinesState == 0 ? (
            <>
              <ChooseAnAspirationOrTheme
                uploadButtonEnabled={true}
                type="inspiration"
                uploadedFiles={jointInspirations}
                title="Add up to 2 Logo Files (Ex. Light and Dark Logos)"
                description="Add New"
                fileChanged={(f: any) => {
                  console.log("File Changed");
                  if (f) {
                    const lastFile: FileProps = {
                      id: f.uploadedId,
                      name: f.name,
                      uploaded: true,
                      isAdapted: f.isAdapted
                    };
                    console.log("Last file is:", lastFile);
                    setUploadedImagesList((prevItems) => [
                      lastFile,
                      ...prevItems,
                    ]);
                  }
                }}
                selectionChanged={(pickedFile) => {
                  if (pickedFile.adId) {
                    setSelectedInspirations(pickedFile.adId ?? "");
                    dispatch(
                      setNewContent({
                        ...newContent,
                        adId: pickedFile.adId,
                        creative_theme: undefined,
                        creativeImageId: undefined,
                        isCreativeAdapted: pickedFile.isAdapted,
                      })
                    );
                  } else {
                    setSelectedInspirations(pickedFile.id ?? "");
                    dispatch(
                      setNewContent({
                        ...newContent,
                        adId: undefined,
                        creative_theme: undefined,
                        creativeImageId: pickedFile.id,
                        isCreativeAdapted: pickedFile.isAdapted
                      })
                    );
                  }
                }}
                selectionEnabled={true}
                selectedFileId={undefined}
              />
              <div
                onClick={() => {
                  dispatch(
                    setNewContent({
                      ...newContent,
                      wizardView: ContentWizardView.inspoLibrary,
                      medium: Mediums.filter(
                        (m) =>
                          m.contentType === ContentType.image &&
                          m.isCustom === true
                      ).at(0),
                      mediumOption: Mediums.find(
                        (k) =>
                          k.name === "Gallery" &&
                          k.contentType === ContentType.image
                      )?.options?.at(0),
                    })
                  );
                }}
                className="self-stretch  text-center text-red text-[13px] font-normal font-['Inter Tight'] underline leading-none cursor-pointer"
              >
                <a>View Inspiration Gallery</a>
              </div>
            </>
          ) : (
            <></>
          )}
          {guidelinesState == 1 ? (
            <>
              <ChooseAnAspirationOrTheme
                uploadButtonEnabled={false}
                type="theme"
                uploadedFiles={jointThemes}
                title="Add up to 2 Logo Files (Ex. Light and Dark Logos)"
                description="Add New"
                fileChanged={(f: any) => {
                  console.log("File Changed");
                  /*if (f) {
                    const lastFile: FileProps = {
                      id: f.uploadedId,
                      name: f.uploadedId,
                      uploaded: true,
                    };
                    console.log("Last file is:", lastFile);
                    setUploadedThemeImagesList([lastFile]);
                  }*/
                }}
                selectionChanged={(pickedFile) => {
                  setSelectedTheme(pickedFile.id ?? "");
                  dispatch(
                    setNewContent({
                      ...newContent,
                      creative_theme: pickedFile.id,
                      adId: undefined,
                      creativeImageId: undefined,
                    })
                  );
                }}
                selectionEnabled={true}
                selectedFileId={undefined}
              />
              <div
                onClick={() => {
                  dispatch(
                    setNewContent({
                      ...newContent,
                      wizardView: ContentWizardView.inspoLibrary,
                      medium: Mediums.filter(
                        (m) =>
                          m.contentType === ContentType.image &&
                          m.isCustom === true
                      ).at(0),
                      mediumOption: Mediums.find(
                        (k) =>
                          k.name === "Gallery" &&
                          k.contentType === ContentType.image
                      )?.options?.at(1),
                    })
                  );
                }}
                className="self-stretch mb-[8px] text-center text-red text-[13px] font-normal font-['Inter Tight'] underline leading-none cursor-pointer"
              >
                <a>View Theme Gallery</a>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreativeAddBrief;
