import { useEffect, useState } from "react";
import InputText, { InputTextState } from "./InputText";
import PrimaryButton from "./PrimaryButton";
import { useLazyListFontsQuery } from "../services/FontApi";
import PickerView from "./PickerView";
import { t } from "i18next";
import { FileProps } from "../services/BrandApi";
import { PickerModel } from "../models/PickerModel";
import ColorPicker from "./ColorPicker/ColorPicker";
import SvgGenerateSimilarWhiteIcon from "../assets/IconComponents/GenerateSimilarWhiteBg";
import TextView from "./TextView";


export interface IRevisionTextEditorProps {
    className?: string
    title?: string
    text?: string
    color?: string
    fontName?: string
    fontSelected: (font: FileProps | undefined) => void
    colorSelected: (color: string | undefined) => void
    onTextChange: (newText: string) => void
    onGenerateWithAiClicked?: () => void
    isDisabled?: boolean
}

function RevisionTextEditor(props: IRevisionTextEditorProps) {
    const [getFonts, { data: fontsToShow }] = useLazyListFontsQuery()
    const [selectedFontId, setSelectedFontId] = useState<string>()
    useEffect(() => {
        getFonts()
    }, [])

    const isCTA = props.title === "Call to Action";
    const textClass = isCTA ? "w-full h-[65px] border-border" : "w-full h-[105px] border-border";

    useEffect(() => {
        setSelectedFontId(getSelectedFontId(props.fontName ?? ""))
    }, [props.fontName, fontsToShow])

    function getSelectedFontId(fontName: string) {
        console.log(`selected font ${fontName}`)
        return fontsToShow?.filter(font => font.name === fontName).at(0)?.file_id
    }

    return (

        <div className={`flex flex-col ${props.className}`}>
            <div className="flex flex-row">
                <div className="text-H6 text-black">{props.title}</div>
            </div>
            <div className="flex gap-[10px] w-full mt-2">
                <PickerView
                    isFontPicker={true}
                    className="w-full"
                    isDisabled={props.isDisabled}
                    allowMultiSelect={false}
                    placeholder={t('select-a-font') ?? undefined}
                    selectedValue={[selectedFontId ?? ""]}
                    items={fontsToShow?.map(k => ({ title: k.name, value: k.file_id } as PickerModel)) ?? []}
                    onChange={e => {
                        let s = fontsToShow && fontsToShow.find(k => k.file_id === e[0].value)!
                        s && props.fontSelected({ id: s.file_id, name: s.name, uploaded: false } as FileProps)
                    }}
                    hideTitle={true}
                />

                <ColorPicker
                    className="shrink-0 rounded-full h-[40px] w-[40px]"
                    hideDeleteButton={true}
                    isDisabled={props.isDisabled}
                    selectedColor={props.color}
                    colorSelected={function (color: string | undefined): void {
                        props.colorSelected(color)
                    }}
                    popoverClassName="relative -left-[116px] -bottom-[143px]"
                />
                {/* <div className="flex border-r-[1px]">
                </div>
                <PrimaryButton
                    className="h-[40px] w-[40px] border-1 border-border radius-[6px]"
                    isDisabled={props.isDisabled}
                    icon={<SvgGenerateSimilarWhiteIcon />}
                    tooltip="Generate With Ai"
                    onClick={() => {
                        props.onGenerateWithAiClicked && props.onGenerateWithAiClicked()
                    }}
                /> */}
            </div>
            <div className="flex gap-[10px] w-full mt-2">
                <TextView
                    className={textClass}
                    initialValue={props.text}
                    maxCharCount={200}
                    innerClassName="rounded-[4px]"
                    placeholder={t("subject") ?? ""}
                    title={""}
                    onChange={(e) => {
                        props.onTextChange(e)
                    }}
                />
                {/* 
                <InputText
                    value={props.text}
                    className="h-[55px]"
                    innerClassName="rounded-[4px]"
                    state={props.isDisabled ? InputTextState.disabled : InputTextState.normal}
                    onChange={newValue => {
                        props.onTextChange(newValue)
                    }} ></InputText> */}
            </div>
        </div >

    );
}

export default RevisionTextEditor;
