import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'
import { IDialogModel } from '../models/Dialog'

interface AppState {
  isLoading: boolean
  dialog?: IDialogModel
  showDialog?: boolean
  updateMenu: boolean
  showMenu: boolean
  refreshPage: boolean
  activePage?: string
  activeItem?: string
  collapsedModeEnabled: boolean
  isMenuCollapsed: boolean
  sharedContentId?: string | undefined
  brandEditing?: boolean
  contentWizardActive?: boolean
  notificationsSidebarActive?: boolean
}

// Define the initial state using that type
const initialState: AppState = {
  isLoading: false,
  showMenu: true,
  updateMenu: false,
  refreshPage: false,
  collapsedModeEnabled: false,
  isMenuCollapsed: false
}

export const appSlice = createSlice({
  name: "app",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    toggleLoading: (state, isLoading: PayloadAction<boolean>) => {
      state.isLoading = isLoading.payload;
    },
    toggleMenu: (state, status: PayloadAction<boolean | undefined>) => {
      state.showMenu = status.payload ?? !state.showMenu;
    },
    toggleDialog: (state, isLoading: PayloadAction<boolean>) => {
      state.showDialog = isLoading.payload;
    },
    setDialog: (state, isLoading: PayloadAction<IDialogModel>) => {
      state.dialog = isLoading.payload;
    },
    updateMenu: (state) => {
      state.updateMenu = !state.updateMenu;
    },
    refreshPage: (state) => {
      state.refreshPage = !state.refreshPage;
    },
    setActivePage: (state, activePage: PayloadAction<string | undefined>) => {
      state.activePage = activePage.payload;
    },
    setActiveItem: (state, activeItem: PayloadAction<string | undefined>) => {
      state.activeItem = activeItem.payload;
    },
    setCollapsedModeEnabled: (state, activeItem: PayloadAction<boolean>) => {
      state.collapsedModeEnabled = activeItem.payload;
    },
    setMenuCollapsed: (state, activeItem: PayloadAction<boolean>) => {
      state.isMenuCollapsed = activeItem.payload;
    },
    setSharedContentId: (state, content: PayloadAction<string | undefined>) => {
      state.sharedContentId = content.payload;
    },
    toggleBrandEditing: (state, isBrandEditing: PayloadAction<boolean>) => {
      state.brandEditing = isBrandEditing.payload;
    },
    toggleContentWizard: (state) => {
      state.contentWizardActive = !state.contentWizardActive;
    },
    toggleNotificationsSidebar: (state) => {
      state.notificationsSidebarActive = !state.notificationsSidebarActive;
    }
  },
});

export const {
  toggleLoading,
  setDialog,
  toggleDialog,
  updateMenu,
  refreshPage,
  setSharedContentId,
  setCollapsedModeEnabled,
  setMenuCollapsed,
  toggleMenu,
  setActivePage,
  setActiveItem,
  toggleBrandEditing,
  toggleContentWizard,
  toggleNotificationsSidebar,
} = appSlice.actions;

export const isLoading = (state: RootState) => state.app.isLoading

export default appSlice.reducer
