import { ActivityResponse } from "../../services/Activity";

export interface ICampaignPreviewProps {
    activity: ActivityResponse
}

function CampaignPreview(props: ICampaignPreviewProps) {
    return (
        <>
            <div className="flex flex-col overflow-scroll scrollbar-hide">

                <div className="flex flex-row items-center">
                    <div className="w-[32px] h-[32px] rounded-full border-1 border-[#EAEBE6] mr-2 flex justify-center items-center"><span className={'inline-block text-H6'}>{props.activity.companyName?.slice(0,1)?.toUpperCase()}</span></div>
                    <div className="ml-1 flex flex-col">
                        <text className="text-H8 company-name">{props.activity.companyName}</text>
                        <text className="text-BodySmall">Sponsored</text>
                    </div>
                </div>
                <div className="mt-2">
                    <text className="text-BodySmall text-black shortHeadlines">{
                        props.activity.creatives?.shortHeadlines &&
                        props.activity.creatives?.shortHeadlines!.length > 0
                        &&
                        props.activity.creatives?.shortHeadlines[0].textContent
                    }</text>
                </div>
                <div className="mt-2 border-1 border-[#EAEBE6] rounded-md">
                    {
                        props.activity.imageContentDetails &&
                        props.activity.imageContentDetails!.length > 0
                        &&
                        <img src={`${process.env.REACT_APP_BASE_URL}file/download/image/${props.activity.imageContentDetails.filter(a=> a.active)[0]?.imageFileId}`} className="h-[290px] rounded-t-md object-cover m-auto" />
                    }

                    <div className="flex flex-row justify-between p-1">
                        <div className="flex flex-col">
                            <text className="text-sm text-black longHeadlines">
                                {
                                    props.activity.creatives?.longHeadlines &&
                                    props.activity.creatives?.longHeadlines!.length > 0
                                    &&
                                    props.activity.creatives?.longHeadlines[0].textContent
                                }
                            </text>
                            <text className="text-H7 text-black longHeadlines">
                                {
                                    props.activity.creatives?.description &&
                                    props.activity.creatives?.description!.length > 0
                                    &&
                                    props.activity.creatives?.description[0].textContent
                                }
                            </text>
                        </div>
                        {
                            props.activity.creatives?.ctas &&
                            props.activity.creatives?.ctas!.length > 0
                            &&
                            <span className="text-H7 text-black border-1 border-[#BEBEBE] rounded-md h-[36px] max-w-[85px] min-w-[85px] ml-2 pt-2 text-center align-middle">
                                {props.activity.creatives?.ctas[0].textContent}
                            </span>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default CampaignPreview;
