
import { t } from "i18next";
import SvgQuestionMark from "../../assets/IconComponents/QuestionMark";
import PrimaryButton from "../../components/PrimaryButton";
import { Plan } from "../../services/PlanApi";
import SvgUploadIconNew from "../../assets/IconComponents/UploadIconNew";

export interface IPlanCardProps {
  plan: Plan;
  planType: "yearly" | "monthly";
  onSubscribe: (plan: Plan, skipTrial: boolean, applyDiscount: boolean) => void;
  onTrialClicked: () => void;
  isUpdate: boolean;
  isSelectedPlan?: boolean
  showSkipTrial?: boolean
  isLowerThanCurrentPlan?: boolean
  trialedBefore?: boolean
}

function PlanCard(props: IPlanCardProps) {

  return (
    <div
      className={`relative flex flex-col justify-start items-start gap-5 w-1/4 p-4 bg-white rounded-[8px] border ${props.plan?.mostPopular ? `border-red` : `border-stone-200`
        }`}
    >
      {props.plan.mostPopular && (
        <div className="absolute top-[-10px] right-[10px] w-[100px] h-5 px-1.5 py-[3px] bg-red rounded-[100px] justify-center items-center gap-2.5 inline-flex">
          <div className="text-right text-white text-[10px] font-bold font-['Inter Tight'] leading-[14px] tracking-wide">
            MOST POPULAR
          </div>
        </div>
      )}
      <div className="flex flex-col justify-start items-start gap-5 w-full h-full">
        <div className="flex flex-col w-full h-full justify-start items-start gap-1.5">
          <div className="justify-center items-center gap-2.5 inline-flex">
            <div className="text-black text-[40px] font-semibold font-['Inter Tight'] leading-[56px]">
              {props.planType === "yearly"
                ? `$${props.plan?.yearlyPrice ?? 0} `
                : `$${props.plan?.monthlyPrice ?? 0} `}
            </div>
            <div className="flex-col justify-start items-start gap-0.5 inline-flex">
              <p className="text-neutral-900 text-[15px] leading-[21px]">
                /month
              </p>
              <div className="text-neutral-400 text-[13px] leading-[18.20px]">
                {props.planType === "monthly" ? `(billed monthly)` : `(billed annualy)`}
              </div>
            </div>
          </div>
          {props.plan?.futureMonthlyPrice && (
            <div className="justify-center items-center gap-2.5 inline-flex">
              <div>
                <span className="text-neutral-900 text-[13px] font-normal font-['Inter Tight'] line-through leading-[18.20px]">
                  {props.planType === "yearly"
                    ? `$${props.plan?.futureYearlyPrice ?? 0} `
                    : `$${props.plan?.futureMonthlyPrice ?? 0} `}{" "}
                  +{" "}
                </span>
                <span className="text-neutral-400 text-[13px] font-normal font-['Inter Tight'] line-through leading-[18.20px]">
                  {props.planType === "yearly"
                    ? `${props.plan?.futureYearlyAddSpendsPercentage ?? 0
                    }% of ads spends`
                    : `${props.plan?.futureMonthlyAddSpendsPercentage ?? 0
                    }% of ads spends`}
                </span>
              </div>
              <div className="justify-center items-center gap-[5px] flex">
                <div className="text-right text-red text-[10px] font-bold font-['Inter Tight'] leading-[14px] tracking-wide">
                  FUTURE PRICE
                </div>
                <SvgQuestionMark
                  className="w-4 h-4 relative"
                  data-tooltip-id={"app-tooltip"}
                  data-tooltip-html={t("plan-future-price-tooltip")}
                ></SvgQuestionMark>
              </div>
            </div>
          )}
        </div>
        <div className="self-stretch h-full flex-col justify-start items-start gap-2.5 flex">
          <div className={props.plan.mostPopular ? `text-red text-lg font-semibold font-['Inter Tight'] leading-[25.20px]` : `text-neutral-900 text-lg font-semibold font-['Inter Tight'] leading-[25.20px]`}>
            {props.plan?.name ?? ""}
          </div>
          <div className="w-full text-neutral-400 text-xs font-normal font-['Inter Tight']">
            {props.plan?.description ?? ""}
          </div>
        </div>
      </div>
      <div className="w-full flex-col justify-start items-start gap-[5px] inline-flex">
        <div className="self-stretch p-2.5 bg-neutral-100 rounded justify-start items-start gap-2.5 inline-flex">
          <div className="grow shrink basis-0">
            <span className="text-neutral-900 text-[13px] font-semibold font-['Inter Tight'] leading-[18.20px]">
              {(props.plan?.credits ?? "Unlimited").toLocaleString("en")}
            </span>
            <span className="text-neutral-900 text-[13px] font-normal font-['Inter Tight'] leading-[18.20px]">
              {" "}
              Monthly Credits
            </span>
          </div>
        </div>
        <div className="self-stretch p-2.5 bg-neutral-100 rounded justify-start items-start gap-2.5 inline-flex">
          <div className="grow shrink basis-0">
            <span className="text-neutral-900 text-[13px] font-semibold font-['Inter Tight'] leading-[18.20px]">
              {props.plan?.numberOfBrands ?? "Unlimited"}
            </span>
            <span className="text-neutral-900 text-[13px] font-normal font-['Inter Tight'] leading-[18.20px]">
              {" "}
              Number of Brands
            </span>
          </div>
        </div>
        {props.plan?.aiModels && (
          <div className="self-stretch p-2.5 bg-neutral-100 rounded justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 text-neutral-900 text-[13px] font-normal font-['Inter Tight'] leading-[18.20px]">
              {props.plan.aiModels}
            </div>
            <SvgQuestionMark
              className="w-4 h-4 relative"
              data-tooltip-id={"app-tooltip"}
              data-tooltip-html={props.plan.aiModels.includes("Custom") ? t("custom-advanced-ai-models-benefit-tooltip") : props.plan.aiModels.includes("Basic") ? t("basic-ai-models-benefit-tooltip") : t("advanced-ai_models-benefit-tooltip")} >
            </SvgQuestionMark>
          </div>
        )}
        {props.plan?.adCreation && (
          <div className="self-stretch p-2.5 bg-neutral-100 rounded justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 text-neutral-900 text-[13px] font-normal font-['Inter Tight'] leading-[18.20px]">
              {props.plan.adCreation}
            </div>
          </div>
        )}
        {props.plan?.creativeAds && (
          <div className="self-stretch p-2.5 bg-neutral-100 rounded justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 text-neutral-900 text-[13px] font-normal font-['Inter Tight'] leading-[18.20px]">
              {props.plan.creativeAds}
            </div>
            <SvgQuestionMark
              className="w-4 h-4 relative"
              data-tooltip-id={"app-tooltip"}
              data-tooltip-html={t("creative-ads-benefit-tooltip")} >
            </SvgQuestionMark>
          </div>
        )}
        {false && props.plan?.marketingOperator && (
          <div className="self-stretch p-2.5 bg-neutral-100 rounded justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 text-neutral-900 text-[13px] font-normal font-['Inter Tight'] leading-[18.20px]">
              {props.plan.marketingOperator}
            </div>
            {/* <SvgQuestionMark className="w-4 h-4 relative"></SvgQuestionMark> */}
          </div>
        )}
        {false && props.plan?.support && (
          <div className="self-stretch p-2.5 bg-neutral-100 rounded justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 text-neutral-900 text-[13px] font-normal font-['Inter Tight'] leading-[18.20px]">
              {props.plan.support}
            </div>
            {/* <SvgQuestionMark className="w-4 h-4 relative"></SvgQuestionMark> */}
          </div>
        )}
      </div>
      <div className="self-stretch h-full flex-col justify-start items-start gap-2.5 inline-flex">
        <div className="self-stretch h-11 justify-start items-start gap-2.5 inline-flex">
          {(!props.showSkipTrial || props.trialedBefore) &&
            < PrimaryButton
              className="grow shrink basis-0 h-11 px-3.5 py-3rounded-[5px]  justify-center items-center gap-2.5 flex"
              title={`${props.isUpdate ?? false
                ? (props.isSelectedPlan ? "Current Plan" : (props.isLowerThanCurrentPlan ? "Choose Plan" : "Upgrade Plan"))
                : (props.trialedBefore ?? false ? "Choose Plan" : "Start 30-day Free Trial")
                }`}
              icon={
                props.isUpdate ?? false
                  ? <SvgUploadIconNew fill={props.plan?.mostPopular ? '' : '#000'} />
                  : undefined
              }
              noFill={!props.plan?.mostPopular}
              onClick={function () {
                if (props.isUpdate) {
                  props.onSubscribe(props.plan, props.isUpdate, false);
                } else {
                  if (props.trialedBefore) {
                    props.onSubscribe(props.plan, true, false);
                  } else {
                    props.onTrialClicked();
                  }
                }
              }}

            />
          }
          {props.showSkipTrial && !props.trialedBefore &&

            < PrimaryButton
              className="grow shrink basis-0 h-11 px-3.5 py-3rounded-[5px] border border-stone-200 justify-center items-center gap-2.5 flex"
              title={`Skip Trial Get 20% Off`}
              icon={
                props.isUpdate ?? false
                  ? <SvgUploadIconNew fill="#FF5555" />
                  : undefined
              }
              noFill={!props.plan?.mostPopular}
              onClick={function () {
                props.onSubscribe(props.plan, true, true);
              }}

            />
          }
        </div>
        {/* {props.showSkipTrial && !props.trialedBefore &&
          (<div className="self-stretch text-center">
            <span className="text-red text-[13px] font-normal font-['Inter Tight'] underline leading-[18.20px] cursor-pointer" onClick={() => {
              props.onTrialClicked()
            }}>Continue With 30-Day Free Trial</span>
          </div>)
        } */}
      </div>
    </div >
  );
}

export default PlanCard;
