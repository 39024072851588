import {LeftOutlined} from "@ant-design/icons";
import AddPhotos from "../../../pages/MarketingPlanner/MarketingPlannerDetails/AddPhotos";
import {useEffect, useState} from "react";

const AddCustomImageStep = (props) => {
    const [selectedProductImages, setSelectedProductImages] = useState<string[]>(props.selectedImages ?? []);

    console.log(props.selectedImages);
    return (
        <div className={'w-full h-full'}>
            {props.title && (
                <div>
                    <div className="p-3 flex justify-between items-center">
                        <div className={'flex items-center'}>
                            <span className="text-black mr-1 cursor-pointer" onClick={props.closeSideModal}><LeftOutlined/></span>
                            <span className="text-H8"> {props.title}</span>
                        </div>
                        <div>
                           <span className="text-gray text-xs"> {props.description ?? ''}</span>
                        </div>
                    </div>
                    <hr className="bg-border mx-1"/>
                </div>
            )}
            <div className={'h-[calc(100%-55px)] pl-5 pr-3'}>
                {<AddPhotos
                    activity
                    setSelectedImages={(param) => {
                        props.setSelectedProductImages(param)
                        setSelectedProductImages(param)
                    }}
                    isInside
                    selectedImages={selectedProductImages}
                    closeSideModal={props.closeSideModal}
                />}
            </div>
        </div>
    )
}

export default AddCustomImageStep;
