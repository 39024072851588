import Icon from "../../components/Icon";
import twitter from '../../assets/icons/twitter.svg';
import mailIcon from '../../assets/icons/mail.svg';
import googleIcon from '../../assets/icons/google.svg';
import instaIcon from '../../assets/icons/instagram.svg';
import linkedinIcon from '../../assets/icons/linkedin.svg';
import shopifyIcon from '../../assets/icons/shopify.svg';
import Train from "../../components/Train/Train";
import "./Styles/SupportedMedia.scss"

export interface ISupportedMediaProps {
    className: string
}

function SupportedMedia(props: ISupportedMediaProps) {
    return (<>
        <div className={props.className}>
            <p className="font-display text-base text-slate-300 flex justify-center">
                Create Text & Image Contents With Ease
            </p>
            <Train className="train mt-8 v-screen overflow-hidden" base={-800}>
                <li>
                    <Icon data={twitter} size="1rem" className="inline flex-none" />
                    <span>Twitter post</span>
                </li>
                <li>
                    <Icon data={instaIcon} size="1rem" className="inline flex-none" />
                    <span> Instagram story </span>
                </li>
                <li>
                    <Icon data={linkedinIcon} size="1rem" className="inline flex-none" />
                    <span> Linkedin ads </span>
                </li>
                <li>Blog post</li>
                <li>
                    <Icon data={shopifyIcon} size="1rem" className="inline flex-none" />
                    <span> E-commerce product description </span>
                </li>
                <li>
                    <Icon data={mailIcon} size="1rem" className="inline flex-none" />
                    <span> Email </span>
                </li>
                <li>Website copy</li>
                <li>
                    <Icon data={googleIcon} size="1rem" className="inline flex-none" />
                    <span> Google ads banner </span>
                </li>

                <li>
                    <Icon data={twitter} size="1rem" className="inline flex-none" />
                    <span>Twitter post</span>
                </li>
                <li>
                    <Icon data={instaIcon} size="1rem" className="inline flex-none" />
                    <span> Instagram story </span>
                </li>
                <li>
                    <Icon data={linkedinIcon} size="1rem" className="inline flex-none" />
                    <span> Linkedin ads </span>
                </li>
                <li>Blog post</li>
                <li>
                    <Icon data={shopifyIcon} size="1rem" className="inline flex-none" />
                    <span> E-commerce product description </span>
                </li>
                <li>
                    <Icon data={mailIcon} size="1rem" className="inline flex-none" />
                    <span> Email </span>
                </li>
                <li>Website copy</li>
                <li>
                    <Icon data={googleIcon} size="1rem" className="inline flex-none" />
                    <span> Google ads banner </span>
                </li>
            </Train>

            <Train className="train mt-8 v-screen overflow-hidden" direction="left" baseX={-600}>
                <li>
                    <Icon data={twitter} size="1rem" className="inline flex-none" />
                    <span>Twitter post</span>
                </li>
                <li>
                    <Icon data={instaIcon} size="1rem" className="inline flex-none" />
                    <span> Instagram story </span>
                </li>
                <li>
                    <Icon data={linkedinIcon} size="1rem" className="inline flex-none" />
                    <span> Linkedin ads </span>
                </li>
                <li>Blog post</li>
                <li>
                    <Icon data={shopifyIcon} size="1rem" className="inline flex-none" />
                    <span> E-commerce product description </span>
                </li>
                <li>
                    <Icon data={mailIcon} size="1rem" className="inline flex-none" />
                    <span> Email </span>
                </li>
                <li>Website copy</li>
                <li>
                    <Icon data={googleIcon} size="1rem" className="inline flex-none" />
                    <span> Google ads banner </span>
                </li>

                <li>
                    <Icon data={twitter} size="1rem" className="inline flex-none" />
                    <span>Twitter post</span>
                </li>
                <li>
                    <Icon data={instaIcon} size="1rem" className="inline flex-none" />
                    <span> Instagram story </span>
                </li>
                <li>
                    <Icon data={linkedinIcon} size="1rem" className="inline flex-none" />
                    <span> Linkedin ads </span>
                </li>
                <li>Blog post</li>
                <li>
                    <Icon data={shopifyIcon} size="1rem" className="inline flex-none" />
                    <span> E-commerce product description </span>
                </li>
                <li>
                    <Icon data={mailIcon} size="1rem" className="inline flex-none" />
                    <span> Email </span>
                </li>
                <li>Website copy</li>
                <li>
                    <Icon data={googleIcon} size="1rem" className="inline flex-none" />
                    <span> Google ads banner </span>
                </li>
            </Train>
        </div>

    </>);
}

export default SupportedMedia;