import * as React from "react";
import type { SVGProps } from "react";
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M8.542 2.5C5.215 2.5 2.5 5.215 2.5 8.542c0 3.326 2.715 6.041 6.042 6.041 1.363 0 2.62-.46 3.633-1.229l3.902 3.902a.834.834 0 1 0 1.179-1.178l-3.902-3.903a5.998 5.998 0 0 0 1.23-3.633c0-3.327-2.716-6.042-6.042-6.042Zm0 1.667a4.362 4.362 0 0 1 4.375 4.375 4.357 4.357 0 0 1-1.188 2.998.834.834 0 0 0-.187.187 4.357 4.357 0 0 1-3 1.19 4.362 4.362 0 0 1-4.375-4.375 4.362 4.362 0 0 1 4.375-4.375Z" />
  </svg>
);
export default SvgSearch;
