import * as React from "react";
const LogoSelectorIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
    <rect opacity="0.1" width="28" height="28" rx="4" fill={props.fill} />
    <path opacity="0.5" d="M14.5519 20.6667C14.2892 20.6673 14.029 20.6158 13.7863 20.5152C13.5436 20.4146 13.3232 20.2669 13.1379 20.0807L7.52859 14.4713C7.40355 14.3463 7.33329 14.1768 7.33325 14V8C7.33325 7.82319 7.40349 7.65362 7.52851 7.5286C7.65354 7.40357 7.82311 7.33333 7.99992 7.33333H13.9999C14.1767 7.33337 14.3463 7.40363 14.4713 7.52867L20.0806 13.138C20.2663 13.3237 20.4137 13.5441 20.5142 13.7867C20.6147 14.0293 20.6664 14.2894 20.6664 14.552C20.6664 14.8146 20.6147 15.0747 20.5142 15.3173C20.4137 15.5599 20.2663 15.7803 20.0806 15.966L15.9659 20.0807C15.7808 20.267 15.5606 20.4147 15.318 20.5153C15.0754 20.6159 14.8152 20.6673 14.5526 20.6667H14.5519Z" fill={props.fill} />
    <path d="M11 12C11.5523 12 12 11.5523 12 11C12 10.4477 11.5523 10 11 10C10.4477 10 10 10.4477 10 11C10 11.5523 10.4477 12 11 12Z" fill={props.fill} />
  </svg>

);
export default LogoSelectorIcon;
