import * as React from "react";
import type { SVGProps } from "react";
const SvgUploadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={`${props.fill ? props.fill : "#999"}`}
      d="M16.25 8.75h-.242A6.049 6.049 0 0 0 10 3.333 6.049 6.049 0 0 0 3.992 8.75H3.75A3.756 3.756 0 0 0 0 12.5a3.756 3.756 0 0 0 3.75 3.75h12.5A3.756 3.756 0 0 0 20 12.5a3.756 3.756 0 0 0-3.75-3.75Zm-2.667 1.467a.626.626 0 0 1-.883.033l-2.075-1.938v5.23a.624.624 0 1 1-1.25 0v-5.23L7.3 10.25a.625.625 0 0 1-.85-.917l3.125-2.916a.628.628 0 0 1 .85 0l3.125 2.916a.625.625 0 0 1 .033.884Z"
    />
  </svg>
);
export default SvgUploadIcon;
