import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { FunctionComponent, useEffect, useState } from "react";

import { ContentType } from "../../../constants/ContentType";


import { FileProps } from "../../../services/BrandApi";
import { ContentSize } from "../../../constants/ContentSize";
import LogoSelectorIcon from "../../../assets/IconComponents/LogoSelectorIcon";
import ToggleButton from "../../../components/ToggleButton";
import { setNewContent } from "../../../store/contentSlice";
import ProductSelectorIcon from "../../../assets/IconComponents/ProductSelectorIcon";
import Subject from "../ContentDetails/Subject";
import LogoSelector from "../ContentDetails/LogoSelector";
import ProductSelector from "../ContentDetails/ProductSelector";
import SliderCounter from "./SliderCounter";

interface ProductPhotoshootContentProps {
  logoSelectionList: FileProps[];
  productSelectionList: FileProps[];
  isInSide?: boolean;
  setIsLogoSelectionOpen?: (status: boolean) => void;
  numberOfImagesChanged?: (num: number) => void;
  bgRemoved?: (string) => void;
}

const ProductPhotoshootContent: FunctionComponent<
  ProductPhotoshootContentProps
> = (props: ProductPhotoshootContentProps) => {
  const dispatch = useAppDispatch();
  const newContent = useAppSelector((k) => k.content.newContent);

  return (
    <>
      <div
        className={`w-full ${
          props.isInSide
            ? "min-h-[calc(70vh-135px)] max-h-[calc(85vh-80px)] overflow-scroll mb-[80px]"
            : "h-[calc(100vh-135px)]"
        } flex-col p-[20px] gap-[12px] inline-flex border-b-1 overflow-scroll scrollbar-hide`}
      >
        {props.isInSide && newContent?.contentType === ContentType.image && (
          <>
            {props.setIsLogoSelectionOpen && (
              <>
                <div
                  className={`w-full flex justify-between items-center relative border-1 p-3 rounded-[6px]`}
                >
                  <div className="justify-start items-center gap-2.5 flex">
                    <LogoSelectorIcon
                      fill={!newContent?.logoFileId ? "#8E8E8E" : "#FF5555"}
                      className="flex shrink-0"
                    />
                    <text className="text-sm font-semibold text-black w-72 sm:w-full truncate text-ellipsis overflow-hidden">
                      Add Logo
                    </text>
                  </div>

                  <ToggleButton
                    isChecked={!!newContent?.logoFileId}
                    handleCheckBoxChange={function (checked: boolean): void {
                      if (props.setIsLogoSelectionOpen) {
                        props.setIsLogoSelectionOpen(checked);
                      }
                      if (!checked) {
                        dispatch(
                          setNewContent({
                            ...newContent,
                            logoFileId: undefined,
                          })
                        );
                      }
                    }}
                    checkedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                    checkedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition translate-x-full"
                    uncheckedBoxClassName="box block h-6 w-[40px] rounded-full bg-neutral-200 border-2 border-neutral"
                    uncheckedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-neutral-400 transition"
                  />
                </div>
              </>
            )}
            <>
              <div
                className={`w-full flex justify-between items-center relative border-1 p-3 rounded-[6px]`}
              >
                <div className="justify-start items-center gap-2.5 flex">
                  <ProductSelectorIcon
                    fill={!newContent?.productFileId ? "#8E8E8E" : "#FF5555"}
                    className="flex shrink-0"
                  />
                  <text className="text-sm font-semibold text-black w-72 sm:w-full truncate text-ellipsis overflow-hidden">
                    Add Images
                  </text>
                </div>
              </div>
            </>
          </>
        )}
        {!props.isInSide &&
          newContent?.contentType === ContentType.image &&
          newContent?.contentSize !== ContentSize.mobileleaderb && (
            <div
              className={`w-full ${
                props.isInSide
                  ? ""
                  : "h-[214px] justify-start items-start gap-3 inline-flex"
              } `}
            >
              <ProductSelector
                callingParent="productphotoshoot"
                productSelectionList={props.productSelectionList}
                bgRemoved={(str) => {
                  if (props.bgRemoved) {
                    props.bgRemoved(str);
                  }
                }}
              />
              <LogoSelector
                callingParent="productphotoshoot"
                logoSelectionList={
                  newContent.logoFileId
                    ? [
                        {
                          id: newContent.logoFileId,
                          name: "",
                          uploaded: true,
                        } as FileProps,
                      ]
                    : []
                }
              />
            </div>
          )}

        <div className="w-full p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-5 inline-flex">
          <SliderCounter
            minCount={0}
            maxCount={4}
            numberOfImagesChanged={(num) => {
              if (props.numberOfImagesChanged) {
                props.numberOfImagesChanged(num);
              }
              dispatch(
                setNewContent({
                  ...newContent,
                  sliderCount: num,
                })
              );
            }}
          />
        </div>

        <div className="w-full  p-3.5 bg-white rounded-md border border-stone-200 flex-col justify-start items-start gap-5 inline-flex">
          <Subject
            callingParent="productphotoshoot"
            hideTitle={props.isInSide}
            useKeywords={false}
          />
        </div>
      </div>
    </>
  );
};

export default ProductPhotoshootContent;
