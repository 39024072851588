import * as React from "react";
import type { SVGProps } from "react";
const SvgSupriseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      // fill="#121315"
      d="m16.917 5.854-.709-1.562-1.562-.709 1.562-.708.709-1.563.708 1.563 1.563.708-1.563.709-.708 1.562Zm-10.042 0-.708-1.562-1.563-.709 1.563-.708.708-1.563.708 1.563 1.563.708-1.563.709-.708 1.562Zm10.042 10.042-.709-1.563-1.562-.708 1.562-.708.709-1.563.708 1.563 1.563.708-1.563.708-.708 1.563ZM3.896 18.583l-1.98-1.979a.812.812 0 0 1-.25-.531c-.013-.202.07-.392.25-.573l9.376-9.375a.823.823 0 0 1 .604-.25c.236 0 .437.083.604.25L14.375 8c.167.167.25.368.25.604a.823.823 0 0 1-.25.604L5 18.583c-.167.167-.35.25-.552.25-.201 0-.385-.083-.552-.25Zm.479-1.187 6.52-6.521-1.29-1.292-6.522 6.521 1.292 1.292Z"
    />
  </svg>
);
export default SvgSupriseIcon;
