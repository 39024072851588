import * as React from "react";
const Globe1 = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={19}
        fill="none"
        {...props}
    >
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M18.9349 1.81454C19.3135 1.41242 19.9463 1.39333 20.3485 1.7719C21.4241 2.78454 22.2809 4.00683 22.8659 5.36337C23.4509 6.7199 23.7518 8.18192 23.75 9.65923C23.7493 15.646 18.896 20.4993 12.9092 20.5C11.4319 20.5018 9.9699 20.2009 8.61337 19.6159C7.25683 19.0309 6.03454 18.1741 5.0219 17.0985C4.64333 16.6963 4.66242 16.0635 5.06454 15.6849C5.46666 15.3063 6.09954 15.3254 6.47811 15.7275C7.30348 16.6043 8.29972 17.3026 9.40538 17.7794C10.511 18.2562 11.7027 18.5015 12.9068 18.5H12.908C17.7912 18.5 21.75 14.5412 21.75 9.658V9.65678C21.7515 8.45268 21.5062 7.26105 21.0294 6.15538C20.5526 5.04972 19.8543 4.05348 18.9775 3.22811C18.5754 2.84954 18.5563 2.21666 18.9349 1.81454Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd"
              d="M12.75 16.5C16.616 16.5 19.75 13.366 19.75 9.5C19.75 5.634 16.616 2.5 12.75 2.5C8.884 2.5 5.75 5.634 5.75 9.5C5.75 13.366 8.884 16.5 12.75 16.5Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12.75 18.5C13.3023 18.5 13.75 18.9477 13.75 19.5V21.5H15.75C16.3023 21.5 16.75 21.9477 16.75 22.5C16.75 23.0523 16.3023 23.5 15.75 23.5H9.75C9.19772 23.5 8.75 23.0523 8.75 22.5C8.75 21.9477 9.19772 21.5 9.75 21.5H11.75V19.5C11.75 18.9477 12.1977 18.5 12.75 18.5Z"
              fill={props.fill ?? "#FFFFFF"}/>
    </svg>
);
export default Globe1;
