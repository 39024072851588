import { useEffect, useRef, useState } from "react";

export interface ILoadingBarAnimationProps {
    animating?: boolean
}

function LoadingBarAnimation(props: ILoadingBarAnimationProps) {
    const ref = useRef<HTMLDivElement>(null);
    const [barLength, setBarLength] = useState(0)
    const [bar2Length, setBar2Length] = useState(0)
    const [viewWidth, setViewWidth] = useState(0)
    const [timer, setTimer] = useState<NodeJS.Timer>()

    const startAnimating = () => {
        setBarLength(-viewWidth)
        setBar2Length(-(viewWidth + viewWidth + (viewWidth / 2)))
        let lTimer = setInterval(() => {
            setBarLength(barLength => barLength + 1)
            setBar2Length(bar2Length => bar2Length + 1)
        }, 1)

        setTimer(lTimer)
    }

    useEffect(() => {
        if (props.animating == undefined || props.animating == false) {
            clearInterval(timer)
        }
        else if (props.animating == true) {
            setViewWidth(ref.current ? ref.current.offsetWidth : 0)
            startAnimating()
        }

    }, [props.animating])

    useEffect(() => {
        if (barLength >= viewWidth) {
            setBarLength(-viewWidth)
        }
    }, [barLength])

    useEffect(() => {
        if (bar2Length >= viewWidth) {
            setBar2Length(-(viewWidth + viewWidth + (viewWidth / 2)))
        }
    }, [bar2Length])

    useEffect(() => {
        setViewWidth(ref.current ? ref.current.offsetWidth : 0)
    }, [ref.current]);

    return (
        <>
            <div ref={ref} className={`h-[2px] w-[100%] absolute z-50 bottom-0 left-0 right-0 overflow-hidden flex-col ${props.animating ? "flex" : "hidden"}`}>
                <div className="h-full w-[90%]  bg-red absolute" style={{ left: `${bar2Length}px` }}></div>
                <div className="h-full w-[90%]  bg-red absolute" style={{ left: `${barLength}px` }}></div>
            </div>
        </>
    );
}

export default LoadingBarAnimation;