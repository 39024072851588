import * as React from "react";
import type { SVGProps } from "react";
const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M10 2.875A7.133 7.133 0 0 0 2.875 10 7.133 7.133 0 0 0 10 17.125 7.133 7.133 0 0 0 17.125 10 7.133 7.133 0 0 0 10 2.875ZM2.127 6.487a.564.564 0 0 0-.554.373A8.972 8.972 0 0 0 1 10c0 1.108.21 2.164.573 3.14a.562.562 0 1 0 1.054-.393A7.836 7.836 0 0 1 2.125 10c0-.966.182-1.887.502-2.747a.563.563 0 0 0-.5-.766Zm15.785 0a.563.563 0 0 0-.54.766c.321.86.503 1.781.503 2.747s-.182 1.887-.502 2.747a.562.562 0 1 0 1.053.393A8.973 8.973 0 0 0 19 10c0-1.108-.21-2.164-.573-3.14a.562.562 0 0 0-.515-.373ZM10 6.625c.31 0 .563.252.563.563v3.375a.562.562 0 1 1-1.126 0V7.187c0-.31.252-.562.563-.562Zm0 5.25a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5Z" />
  </svg>
);
export default SvgWarning;
