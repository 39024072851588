import { useEffect, useState } from 'react'
import { t } from 'i18next'
import SvgQuestionMark from '../../../assets/IconComponents/QuestionMark'
import { Plan } from '../../../services/PlanApi'
import SvgDone from '../../../assets/IconComponents/Done'

interface IPlanFeaureLineProps {
  groupId: string
  id: string
  plans: Plan[]
  retrieveFromPlan?: boolean
  lastLine?: boolean
  lineStyleClassName?: string
  icons?: JSX.Element
  questionMark?: boolean
  tooltip?: string
}

export interface PlanBenefit {
  groupId: string,
  id: string,
  soon: boolean
  value: boolean | string | number
  mostPopular: boolean
  planId: string
}

function PlanBenefitLine(props: IPlanFeaureLineProps) {
  const [benefits, setBenefits] = useState<PlanBenefit[]>([])
  const [mostPopularIndex, setMostPopularIndex] = useState<number>()

  useEffect(() => {
    setMostPopularIndex(props.plans.findIndex(p => p.mostPopular))
  }, [props.plans])

  useEffect(() => {
    if (!props.retrieveFromPlan) {
      const benefits = props.plans.map(p => {
        var benefits = JSON.parse(p.benefits ?? "[]"); return benefits.map(b => { return { ...b, mostPopular: p.mostPopular, planId: p.id } })
      }).flatMap(b => b.filter(c => c.groupId === props.groupId && c.id === props.id));

      setBenefits(benefits)
    } else {
      if (props.id === "monthly-credits") {
        setBenefits(props.plans.map(p => {
          return {
            "groupId": "",
            "id": "monthly-credits",
            "soon": false,
            "value": p.credits ?? "Unlimited",
            "mostPopular": p.mostPopular,
            "planId": p.id,
          } as PlanBenefit
        }))
      }
    }
  }, [props.plans])

  function renderFeatureValue(b: PlanBenefit) {
    if (typeof b.value === "boolean") {
      return b.value === true ? <SvgDone className="w-4 h-4 relative" /> : <div className="text-neutral-400 text-sm font-semibold font-['Inter Tight'] leading-tight">-</div>
    }
    else {
      return (
        <div className="text-black text-sm font-semibold font-['Inter Tight'] leading-tight">
          {(b.value === 1000 || b.value === 10000) ? b.value.toLocaleString("en") : b.value}
        </div>
      );
    }
  }

  return (
    <div
      key={`benefit_line_${props.groupId}_${props.id}`}
      className={`w-full h-[50px] justify-start items-start inline-flex ${props.lineStyleClassName}`}
    >
      <div className="w-[360px] h-[50px] px-2.5 py-[15px] justify-start items-center gap-2.5 flex">
        <div className="text-neutral-400 text-sm font-semibold font-['Inter Tight'] leading-tight">
          {t(props.id)}
        </div>
        {(props.questionMark ?? true) && (props.tooltip) && (
          <SvgQuestionMark
            className="w-4 h-4 relative"
            data-tooltip-id={`app-tooltip`}
            data-tooltip-html={props.tooltip} />
        )}

        {props.icons && (
          <div className="w-[120px] h-5 justify-start items-center gap-[5px] inline-flex">
            {props.icons}
          </div>
        )}
        {benefits.length > 0 && benefits[0].soon && (
          <div className="text-right text-red text-[10px] font-bold font-['Inter Tight'] leading-[14px] tracking-wide">
            SOON
          </div>
        )}
      </div>
      {benefits.map((b) => (
        <div
          key={`benefit_line_value_${b.planId}`}
          className={`grow shrink basis-0 h-[50px] px-2.5 py-[15px] justify-center items-center gap-2.5 flex ${b.mostPopular ? "border-l-1 border-rose-400 border-r-1" : ""
            } ${props.lastLine && b.mostPopular
              ? "border-b-1 rounded-b-[10px]"
              : ""
            }`}
        >
          {renderFeatureValue(b)}
        </div>
      ))}
    </div>
  );
}

export default PlanBenefitLine