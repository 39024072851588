import { useNavigate } from "react-router-dom";
import SvgArrowRight from "../../assets/IconComponents/ArrowRight";
import SvgArrowRightWithTip from "../../assets/IconComponents/ArrowRightWithTip";
import SvgNotificationAwaitingConfirmation from "../../assets/IconComponents/NotificationAwaitingConfirmation";
import SvgNotificationLaunchEventsCreating from "../../assets/IconComponents/NotificationLaunchEventsCreating";
import SvgNotificationManualScheduledActivity from "../../assets/IconComponents/NotificationManualScheduledActivity";
import SvgNotificationPostedSuccess from "../../assets/IconComponents/NotificationPostedSuccess";
import SvgNotificationSubscriptionRenewed from "../../assets/IconComponents/NotificationSubscriptionRenewed";
import SvgNotificationUnreadIcon from "../../assets/IconComponents/NotificationUnreadIcon";
import SvgNotificationsWarningLogo from "../../assets/IconComponents/NotificationsWarningLogo";
import { NotificationResponse, useMarkAsReadMutation } from "../../services/NotificationsManager";
import { useDispatch } from "react-redux";
import { toggleNotificationsSidebar } from "../../store/appSlice";
import {
  fbSVG,
  xSVG,
  instagramSVG,
  linkedInSVG,
  blogSVG,
  newsletterSVG,
  googleSVG,
  metaSVG,
  PLACEHOLDER_SVG,
} from "../../assets/IconComponents/svgs";
import { useAppSelector } from "../../helpers/hooks";


export interface ISingleNotificationProps {
  notificationResponse: NotificationResponse;
  isAllNotifications? : boolean;
}
const SingleNotification = (props: ISingleNotificationProps) => {
  function formatCreatedAt(createdAt: string | undefined): string {
    
    if(createdAt){
      const inputDate = new Date(createdAt);
      const now = new Date();

      // Normalize the time portion for both dates
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const targetDate = new Date(
        inputDate.getFullYear(),
        inputDate.getMonth(),
        inputDate.getDate()
      );

      let datePart: string;

      if (targetDate.getTime() === today.getTime()) {
        datePart = "Today";
      } else if (
        targetDate.getTime() ===
        today.getTime() - 24 * 60 * 60 * 1000
      ) {
        datePart = "Yesterday";
      } else if (
        targetDate.getTime() ===
        today.getTime() + 24 * 60 * 60 * 1000
      ) {
        datePart = "Tomorrow";
      } else {
        const day = ("0" + targetDate.getDate()).slice(-2);
        const month = ("0" + (targetDate.getMonth() + 1)).slice(-2);
        const year = targetDate.getFullYear();
        datePart = `${day}/${month}/${year}`;
      }

      const hours = ("0" + inputDate.getHours()).slice(-2);
      const minutes = ("0" + inputDate.getMinutes()).slice(-2);

      return `${datePart} ${hours}:${minutes}`;

    }
    else {
      return ""
    }
    
  }

  const navigate = useNavigate();
  const [markNotificationAsRead, { isLoading: markAsReadInProgress }] =
    useMarkAsReadMutation();
  const dispatch = useDispatch();
  const getNotificationLogo = (
    notificationResponse: NotificationResponse
  ): JSX.Element => {
    switch (notificationResponse.type ?? "") {
      case "ACTIVITY_PLANNER_ACTIVITIES_GETTING_READY":
        return <SvgNotificationLaunchEventsCreating />;
      case "ACTIVITY_PLANNER_ALL_ACTIVITIES_READY":
        return <SvgNotificationAwaitingConfirmation />;
      case "ACTIVITY_PLANNER_SINGLE_ACTIVITY_WAITING_APPROVAL":
        return <SvgNotificationAwaitingConfirmation />;
      case "ACTIVITY_PLANNER_ACTIVITY_POSTED":
        return <SvgNotificationPostedSuccess />;
      case "SUBSCRIPTION_CREDIT_LOW":
        return <SvgNotificationsWarningLogo />;
      case "SUBSCRIPTION_SUBSCRIPTION_RENEWED":
        return <SvgNotificationSubscriptionRenewed />;
      case "SUBSCRIPTION_CREDIT_CARD_DUE_DATE":
        return <SvgNotificationsWarningLogo />;
      case "SUBSCRIPTION_TRIAL_PERIOD_CLOSE_TO_END":
        return <SvgNotificationsWarningLogo />;
      default:
        return <SvgNotificationsWarningLogo />;
    }
  };

  const getTextContentForEvent = (event) => {
    const postCaps = event.postCategory.toUpperCase();
    if (postCaps == "PAID_META") {
      return `<p style = "color: ${
        "#121315"
      };" class="max-h-[34px] text-BodySmall leading-[17px] line-clamp-2 whitespace-pre-wrap text-wrap">Meta Ads</p>`;
    } else if (postCaps == "PAID_GOOGLE") {
      return `<p style = "color: ${
         "#121315"
      };" class="max-h-[34px] text-BodySmall leading-[17px] line-clamp-2 whitespace-pre-wrap text-wrap">Google Ads</p>`;
    }

    return `<p style = "color: ${"#121315"};" class="max-h-[34px] text-BodySmall leading-[17px] line-clamp-2 whitespace-pre-wrap text-wrap"> ${
      event.textContent
    }</p> `;

    return "";
  };

  const getImageContentForEvent = (event) => {
    const postCaps = event.postCategory.toUpperCase();
    if (postCaps == "PAID_META" || postCaps == "PAID_GOOGLE") {
      return "";
    }

    if (event.imageFileId != undefined) {
      return `<img src = "${process.env.REACT_APP_BASE_URL}file/download/image/${event.imageFileId}" alt = "" class="ml-1 w-[48px] h-[48px] rounded-sm" /> `;
    }

    return "";
  };

  

  function getSocialIcon(event) {
    const channels = event.channels;

    if (channels != undefined && channels.length > 0) {
      var channel = channels[0];

      switch (channel) {
        case "FACEBOOK":
          return fbSVG;

        case "X":
          return xSVG;

        case "INSTAGRAM":
          return instagramSVG;

        case "LINKEDIN":
          return linkedInSVG;

        case "BLOG":
          return blogSVG;

        case "NEWSLETTER":
          return newsletterSVG;

        case "GOOGLE_ADS":
          return googleSVG;

        case "META_ADS":
          return metaSVG;

        default:
          return "";
      }
    }

    return "";
  }

  /*
      | "ACTIVITY_PLANNER_ALL_ACTIVITIES_READY"
    | "ACTIVITY_PLANNER_SINGLE_ACTIVITY_WAITING_APPROVAL"
    | "ACTIVITY_PLANNER_ACTIVITY_POSTED"
    | "ACTIVITY_PLANNER_ACTIVITIES_GETTING_READY"
    | "SUBSCRIPTION_CREDIT_LOW"
    | "SUBSCRIPTION_SUBSCRIPTION_RENEWED"
    | "SUBSCRIPTION_CREDIT_CARD_DUE_DATE"
    | "SUBSCRIPTION_TRIAL_PERIOD_CLOSE_TO_END";
    */


      const getEventContent = (event) => {
        var textContent = getTextContentForEvent(event);
        var imageContent = getImageContentForEvent(event);
        var icon = `<div class="mx-1">${getSocialIcon(event)}</div>`;

        const postCategoryCaps = event.postCategory.toUpperCase();
        console.log("Post CAPS", postCategoryCaps)
        if (postCategoryCaps == "PAID_META") {
          icon = `<div class="mx-1">${metaSVG}</div>`;
        } else if (postCategoryCaps == "PAID_GOOGLE") {
          icon = `<div class="mx-1">${googleSVG}</div>`;
        }

        var content = `${icon}${textContent}${imageContent} `;

        if (content == "") {
          content = `<p style = "color: ${
            event.color?.textColor ?? "#121315"
          };" class="max-h-[34px] text-BodySmall leading-[17px] line-clamp-2 whitespace-pre-wrap text-wrap"> No Content Available.</p> `;
        }
        return content;
      };
  
  return (
    <div className="flex flex-row w-full h-fit bg-inputBg border-1 border-border rounded-[5px]">
      <div className="mt-2 ml-2">
        {getNotificationLogo(props.notificationResponse)}
      </div>
      <div className="flex flex-col w-full mt-2 ml-2">
        <text className={`text-H8 text-black`}>
          {props.notificationResponse.text ?? ""}
        </text>
        <div
          className={`mt-1 ${
            props.isAllNotifications ? "w-[400px]" : ""
          }`}
        >
          {(props.notificationResponse.type ===
            "ACTIVITY_PLANNER_SINGLE_ACTIVITY_WAITING_APPROVAL" ||
            props.notificationResponse.type ===
              "ACTIVITY_PLANNER_ACTIVITY_POSTED") && (
            <div
              style={{
                background: "#F59D1A1A",
                borderColor: "#F59D1A80",
              }}
              className="flex flex-row items-center h-[60px] border-1 rounded-md p-[5px]"
              dangerouslySetInnerHTML={{
                __html: getEventContent(
                  props.notificationResponse.activityPlannerNotificationData
                ),
              }}
            />
          )}
        </div>

        <div className="flex flex-row w-full mt-3">
          <text className={`text-BodySmall text-gray`}>
            {props.notificationResponse.brandName ?? ""}
          </text>
          <text className={`ml-2 text-BodySmall text-gray`}>
            {formatCreatedAt(props.notificationResponse.createdAt)}
          </text>
        </div>
        <div
          className="flex flex-row w-fit items-center mt-5 cursor-pointer mb-2"
          onClick={() => {
            if (props.notificationResponse.notificationId) {
              markNotificationAsRead({
                markAsReadRequest: {
                  notification_ids: [props.notificationResponse.notificationId],
                },
              });
              if (!props.isAllNotifications) {
                dispatch(toggleNotificationsSidebar());
              }

              if (
                props.notificationResponse.type ===
                  "ACTIVITY_PLANNER_ALL_ACTIVITIES_READY" ||
                props.notificationResponse.type ===
                  "ACTIVITY_PLANNER_ACTIVITIES_GETTING_READY"
              ) {
                if (
                  props.notificationResponse.activityPlannerNotificationData
                    ?.campaignId
                ) {

                  window.location.href = `/app/content-calendar?selectedCampaign=${props.notificationResponse.activityPlannerNotificationData?.campaignId}`;
                  
                  /*
                navigate(
                  `/app/content-calendar?selectedCampaign=${props.notificationResponse.activityPlannerNotificationData?.campaignId}&redirectToActivity=${props.notificationResponse.activityPlannerNotificationData.activityId}`
                );
                */
                }
              }

              if (
                props.notificationResponse.type ===
                  "ACTIVITY_PLANNER_ACTIVITY_POSTED" ||
                props.notificationResponse.type ===
                  "ACTIVITY_PLANNER_SINGLE_ACTIVITY_WAITING_APPROVAL"
              ) {
                if (
                  props.notificationResponse.activityPlannerNotificationData
                    ?.campaignId
                ) {
                  if (
                    props.notificationResponse.activityPlannerNotificationData
                      .activityId
                  ){
                    /*
                    navigate(
                      `/app/content-calendar?selectedCampaign=${props.notificationResponse.activityPlannerNotificationData?.campaignId}&redirectToActivity=${props.notificationResponse.activityPlannerNotificationData.activityId}`
                    );
                    */
                   window.location.href = `/app/content-calendar?selectedCampaign=${props.notificationResponse.activityPlannerNotificationData?.campaignId}&redirectToActivity=${props.notificationResponse.activityPlannerNotificationData.activityId}`;
                  }
                
                }
              }
            }
          }}
        >
          <text className={`text-BodySmall underline text-red `}>
            {props.notificationResponse.cta ?? ""}
          </text>
          <SvgArrowRightWithTip />
        </div>
      </div>
      {!(props.notificationResponse.isRead ?? false) && (
        <div className="mr-2 mt-3">
          <SvgNotificationUnreadIcon />
        </div>
      )}
    </div>
  );
};

export default SingleNotification;
