import {createSlice} from '@reduxjs/toolkit'
import {MarketingPlannerRequest, MarketingPlannerResponse} from "../services/MarketingPlanner";
import {periods} from "../components/MarketingPlanner/periodData";
import {reformatText} from "../helpers/Utils";
import dayjs from "dayjs";

export interface IPlan {
    plan: MarketingPlannerRequest
    event: MarketingPlannerRequest
    savedData: MarketingPlannerResponse[]
    ganttData: any[]
}
// Define the initial state using that type
const initialState: IPlan = {
    plan: {
        name: '',
        headerType: "CAMPAIGN"
    },
    event: {
        name: '',
        headerType: "EVENT",
    },
    savedData: [],
    ganttData: [],
}

export const plannerSlice = createSlice({
    name: "planner",
    initialState,
    reducers: {
        setPlan: (state, {payload}) => {
            state.plan = payload;
        },
        setEvent: (state, {payload}) => {
            state.event = payload;
        },
        setSavedData: (state, {payload}) => {
            state.savedData = payload.data;
        },
        setGanttData: (state, {payload}) => {
            const data = payload.map((item, i) => {
                const ganttObj = {
                    ...item,
                    realId: item.id,
                    id: item.id,
                    text: reformatText(item.headerType === "EVENT" ? item.eventType : item.campaignType),
                    activity: 0,
                    start_date: dayjs(item.startDate).format('YYYY-MM-DD'),
                    end_date: dayjs(item.endDate).format('YYYY-MM-DD'),
                } as any
                if (item.rootId !== item.id) {
                    payload.forEach((baseItem , index) => {
                        if (item.rootId === baseItem.id) {
                            ganttObj.parent = index + 1
                        }
                    })

                }
                if (item.rootId === item.id) {
                    ganttObj.open = true
                }

                return ganttObj as MarketingPlannerResponse
            })
            state.ganttData = data

            console.log(data)
        }
    },
});

export const {setPlan, setEvent, setSavedData, setGanttData} = plannerSlice.actions

export default plannerSlice.reducer
