import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import PrimaryButton from "../PrimaryButton";
import SvgCheckSingleIcon from "../../assets/IconComponents/CheckSingleIcon";
import { clickOutside } from "../../helpers/ClickOutside";
import { useApplyDiscountMutation } from "../../services/PaymentApi";
import { toggleLoading } from "../../store/appSlice";
import { UserSubscription } from "../../services/UsertApi";
import { Plan, useLazyListPQuery as usePlanQuery } from "../../services/PlanApi";


export interface IStayDiscountPopupProps {
  show: boolean;
  onClose: () => void;
  onClickApplyDiscount: () => void;
  onClickCancelPlan: () => void;
  className?: string;
  isDiscountApplied: boolean;
  onDiscountAlreadyApplied: () => void;
  currentPlan?: UserSubscription
}

function StayDiscountPopup(props: IStayDiscountPopupProps) {
  const dispatch = useAppDispatch();
  const [currentPlan, setCurrentPlan] = useState<Plan>()
  const [listPlans, { data: planData }] = usePlanQuery();
  const newContent = useAppSelector((k) => k.content.newContent);
  const ref = useRef<HTMLDivElement>(null);
  const [
    applyDiscount,
    {
      isLoading: discountApplying
    },
  ] = useApplyDiscountMutation();

  useEffect(() => {
    if (props.currentPlan) {
      listPlans();
    }
  }, [props.currentPlan]);


  useEffect(() => {
    if (planData && props.currentPlan) {
      const subscribedPlan = planData.filter(pd => pd.id === props.currentPlan?.plan_id).at(0)
      setCurrentPlan(subscribedPlan)
    }
  }, [planData, props.currentPlan]);

  useEffect(() => {
    if (ref.current) {
      return clickOutside(ref.current, () => {
        props.onClose();
      });
    }
  }, [ref.current, props.show]);

  useEffect(() => {
    dispatch(toggleLoading(discountApplying));
  }, [discountApplying]);
  return (
    <>
      {props.show && (
        <div
          className={`fixed left-0 right-0 top-0 bottom-0
        backdrop-brightness-50 z-30 flex items-center justify-center ${props.className} `}
          onClick={() => { }}
        >
          <div
            ref={ref}
            className="w-7/12 flex flex-row"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="flex flex-col w-7/12 bg-white border-border border-1 shadow-lg shadow-red-100 px-3 rounded-tl-[10px] rounded-bl-[10px] ">
              <div className="w-full flex justify-between items-center">
                <p className="text-black outline-none text-H6 pt-[15px] pb-[15px]">
                  {t("consider-staying-with-a-25-percent-discount")}
                </p>
              </div>

              <div className="flex w-full flex-col rounded-[4px] border-1 border-border mb-3">
                <img
                  className="relative  object-cover"
                  alt="Coupon Image"
                  src={require("../../assets/images/coupon_img.png")}
                />
                <div className="flex flex-col ml-2">
                  <p className="text-red outline-none text-H5 pt-[15px]">
                    {t("25-percent-discount-for-12-months")}
                  </p>

                  <p className="text-gray outline-none text-BodySmall  pt-[5px]">
                    {t("we-value-you-and-love-for-you-to-stay")}
                  </p>
                </div>
                <hr className="bg-border mb-2 mt-4 mr-4 ml-4" />
                <div className="flex w-full">
                  <div className="flex w-full flex-row mb-2 mr-4 ml-4 gap-2 justify-stretch">
                    <PrimaryButton
                      noFill
                      className="flex-1 h-[40px] px-2"
                      title={t("cancel-my-plan")}
                      onClick={() => {
                        props.onClickCancelPlan();
                      }}
                    />
                    <PrimaryButton
                      className="flex-1 h-[40px] px-2"
                      title={t("claim-25-percent-discount")}
                      onClick={() => {
                        if (props.isDiscountApplied) {
                          props.onDiscountAlreadyApplied();
                        } else {
                          applyDiscount().then((resp) => props.onClickApplyDiscount());
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-5/12 bg-lightBg rounded-tr-[10px] rounded-br-[10px] shadow-lg shadow-red-100  p-[15px]">
              <p className="text-black  outline-none text-H6">
                {t("you-will-miss-out-features")}
              </p>
              <div className="flex flex-row mt-6 items-center ">
                <SvgCheckSingleIcon />
                <p className="text-black ml-3  outline-none text-H8">
                  {currentPlan?.credits ?? "UNLIMITED"}&nbsp;
                </p>
                <p className="text-BodySmall text-black  outline-none ">
                  {t("monthly-credits")}
                </p>
              </div>
              <div className="flex flex-row mt-3 items-center ">
                <SvgCheckSingleIcon />
                <p className="text-black ml-3  outline-none text-H8">{currentPlan?.numberOfBrands}&nbsp;</p>
                <p className="text-BodySmall text-black  outline-none ">
                  {t("number-of-brands")}
                </p>
              </div>
              {currentPlan?.aiModels && (<div className="flex flex-row mt-3 items-center ">
                <SvgCheckSingleIcon />
                <p className="ml-3 text-BodySmall text-black  outline-none ">
                  {currentPlan.aiModels}
                </p>
              </div>)}
              {currentPlan?.marketingOperator && (<div className="flex flex-row mt-3 items-center ">
                <SvgCheckSingleIcon />
                <p className="ml-3 text-BodySmall text-black  outline-none ">
                  {currentPlan.marketingOperator}
                </p>
              </div>)}
              {currentPlan?.support && (<div className="flex flex-row mt-3 items-center ">
                <SvgCheckSingleIcon />
                <p className="ml-3 text-BodySmall text-black  outline-none ">
                  {currentPlan.support}
                </p>
              </div>)}
            </div>
          </div>
        </div >
      )
      }
    </>
  );
}

export default StayDiscountPopup;
