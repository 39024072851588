import * as React from "react";
import type { SVGProps } from "react";
const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M16.243 2.904a.833.833 0 0 0-.582.257L10 8.82l-5.66-5.66a.833.833 0 1 0-1.18 1.178L8.823 10 3.16 15.66a.834.834 0 1 0 1.178 1.18L10 11.177l5.662 5.661a.832.832 0 0 0 1.434-.585.834.834 0 0 0-.256-.593L11.18 10l5.66-5.66a.833.833 0 0 0-.596-1.436Z" />
  </svg>
);
export default SvgClose;
