import * as React from "react";
import type { SVGProps } from "react";
const SvgCustomImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      d="M4.988 2.155A.833.833 0 0 0 4.167 3v1.667H2.5a.833.833 0 1 0 0 1.666h1.667v7.084a2.93 2.93 0 0 0 2.916 2.916h7.084V18a.832.832 0 0 0 1.427.597.834.834 0 0 0 .24-.597v-1.667H17.5a.832.832 0 1 0 0-1.666H7.083c-.7 0-1.25-.55-1.25-1.25V3a.834.834 0 0 0-.845-.845Zm1.679 2.512v1.666h6.25c.7 0 1.25.55 1.25 1.25v6.25h1.666v-6.25a2.93 2.93 0 0 0-2.916-2.916h-6.25Z"
    />
  </svg>
);
export default SvgCustomImage;
