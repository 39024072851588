import { t } from "i18next";
import { useEffect, useState } from "react";
import SvgLockIcon from "../../../assets/IconComponents/Lock";
import Warning from "../../../assets/IconComponents/Warning";
import InputText, { InputTextState } from "../../../components/InputText";
import PrimaryButton from "../../../components/PrimaryButton";
import { useAppDispatch } from "../../../helpers/hooks";
import { TextUtils } from "../../../helpers/TextUtils";
import { instanceOfApiErrorWithCode } from "../../../services/Core/BaseApi";
import { SubscribeResponse, useChangePlanMutation, useSubscribeMutation } from "../../../services/PaymentApi";
import { Plan } from "../../../services/PlanApi";
import { UserPaymentMethod } from "../../../services/UsertApi";
import { toggleLoading } from "../../../store/appSlice";

interface IExistingCardFormProperties {
    plan: Plan;
    selectedPlanType: "monthly" | "yearly";
    paymentMethod: UserPaymentMethod;
    onPlanUpdated: (effectiveAt?: string) => void;
    onSubscriptionCreated: (subscriptionResponse?: SubscribeResponse) => void;
    onUpdatePaymentMethod: () => void;
    promotionCode: string;
    mode: "subscription" | "update";
    skipTrial: boolean;
    applyDiscount?: boolean;
}

function ExistingCardForm(props: IExistingCardFormProperties) {
    const [subscribe, { data: subscriptionResult, isLoading: subscribing, error: subscribeError }] = useSubscribeMutation();
    const [changePlan, { data: planChangeResult, isLoading: isChanging, error: changePlanError, isSuccess: planChanged }] = useChangePlanMutation();
    const [errorMessage, setErrorMessage] = useState<string>();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(toggleLoading(isChanging));
    }, [isChanging]);

    useEffect(() => {
        console.log(props.mode);
    }, [props.mode]);

    useEffect(() => {
        if (changePlanError && instanceOfApiErrorWithCode(changePlanError)) {
            const errorData = changePlanError.data;
            setErrorMessage(errorData.message);
        }
    }, [changePlanError]);

    useEffect(() => {
        if (planChanged) {
            props.onPlanUpdated(planChangeResult?.effective_at);
        }
    }, [planChanged, planChangeResult]);

    useEffect(() => {
        if (subscriptionResult) {
            props.onSubscriptionCreated(subscriptionResult);
        }
    }, [subscriptionResult]);

    useEffect(() => {
        dispatch(toggleLoading(subscribing));
    }, [subscribing]);

    useEffect(() => {
        if (subscribeError && instanceOfApiErrorWithCode(subscribeError)) {
            const errorData = subscribeError.data;
            setErrorMessage(errorData.message);
        }
    }, [subscribeError]);

    return (
        <div className="md:w-[440px] h-full p-5 bg-stone-50 rounded-tr-xl rounded-br-xl border border-stone-200 flex-col justify-start items-center gap-10 inline-flex">
            <div className="md:w-[400px] h-[276px] flex-col justify-center items-center gap-5 inline-flex">
                <div className="self-stretch justify-start items-center gap-5 inline-flex">
                    <div className="grow shrink basis-0 flex-col justify-center items-center gap-5 inline-flex">
                        <div className="self-stretch text-neutral-900 text-lg font-semibold font-['Inter Tight'] leading-[25.20px]">Payment Details</div>
                    </div>
                    <div className="justify-start items-start gap-1.5 flex">
                        <div className="w-6 h-6 pt-[4.59px] pb-[4.57px] justify-center items-center flex">
                            <div className="w-6 h-[14.83px] relative">
                            </div>
                        </div>
                        <div className="w-6 h-6 relative" />
                        <div className="w-6 h-6 pt-[7.59px] pb-[7.64px] justify-center items-center flex">
                            <div className="w-6 h-[8.77px] relative">
                            </div>
                        </div>
                    </div>
                </div>
                {errorMessage && (
                    <div className="inline-flex items-center justify-start">
                        <Warning width="20" height="20" fill="#FF5555" className="mr-2" />
                        <span className="text-red text-BodySmall">{errorMessage}</span>
                    </div>
                )}
                <div className="self-stretch h-[231px] flex-col justify-center items-center gap-[30px] flex">
                    <div className="self-stretch h-[130px] flex-col justify-center items-center gap-2.5 flex">
                        <div className="self-stretch h-full p-4 bg-white rounded-lg border border-stone-200 flex-col justify-start items-start gap-5 flex">
                            <div className="self-stretch h-[70px] flex-col justify-start items-start gap-3 flex">
                                <InputText
                                    title={t("credit-card") ?? ""}
                                    color="gray"
                                    className="mt-4"
                                    onChange={(e) => {}}
                                    value={`${
                                        TextUtils.capitalizeFirstLetter(
                                            props.paymentMethod?.brand ?? "",
                                        )
                                    } **** ${props.paymentMethod?.last4}, Expiration ${props?.paymentMethod?.exp_year}`}
                                    sideBySide={false}
                                    state={InputTextState.disabled}
                                    sideBySideText={t("edit-credit-card") || ""}
                                    onClickSideText={() => {
                                        props.onUpdatePaymentMethod();
                                    }}
                                    innerClassName="w-1/2 rounded-[4px]"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="self-stretch h-[71px] flex-col justify-center items-center gap-2.5 flex">
                        <PrimaryButton
                            className="self-stretch px-3.5 py-3 bg-red rounded-[5px] justify-center items-center gap-2.5 inline-flex"
                            title={props.mode === "update" ? "Update Plan" : (!props.skipTrial ? "Start 7-day Free Trial" : `Start Your Plan`)}
                            noFill={false}
                            onClick={function() {
                                if (props.mode === "subscription") {
                                    subscribe({
                                        subscribeRequest: {
                                            plan_id: props.plan.id,
                                            subscription_type: props.selectedPlanType === "monthly" ? "MONTHLY" : "YEARLY",
                                            skip_trial: props.skipTrial ?? false,
                                            apply_discount: props.applyDiscount ?? false,
                                            promotion_code: props.promotionCode,
                                        },
                                    });
                                } else {
                                    changePlan({
                                        changePlanRequest: {
                                            new_plan_id: props.plan.id,
                                            new_subscription_type: props.selectedPlanType === "monthly" ? "MONTHLY" : "YEARLY",
                                            promotion_code: props.promotionCode,
                                        },
                                    });
                                }
                            }}
                        />
                        <div className="self-stretch justify-center items-center gap-[5px] inline-flex">
                            <SvgLockIcon className="w-4 h-4 relative"></SvgLockIcon>
                            <div className="grow shrink basis-0 text-neutral-400 text-xs font-normal font-['Inter Tight'] leading-none">
                                All payments are secure and encrypted.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExistingCardForm;
