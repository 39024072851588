import * as React from "react";
import type { SVGProps } from "react";
const SvgTritoneText = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={50}
    fill="none"
    {...props}
  >
    <rect width={50} height={50} fill="#999" opacity={0.1} rx={8} />
    <path
      fill="#999"
      d="M16.333 35.833A4.338 4.338 0 0 1 12 31.5V19.583a4.338 4.338 0 0 1 4.333-4.333 1.625 1.625 0 1 1 0 3.25c-.598 0-1.083.486-1.083 1.083V31.5c0 .597.485 1.083 1.083 1.083a1.625 1.625 0 1 1 0 3.25ZM33.667 35.833a1.625 1.625 0 1 1 0-3.25c.598 0 1.083-.486 1.083-1.083V19.583c0-.597-.485-1.083-1.083-1.083a1.625 1.625 0 1 1 0-3.25A4.338 4.338 0 0 1 38 19.583V31.5a4.338 4.338 0 0 1-4.333 4.333Z"
      opacity={0.35}
    />
    <path
      fill="#999"
      d="M29.875 23.917h-9.75a1.625 1.625 0 1 1 0-3.25h9.75a1.625 1.625 0 1 1 0 3.25ZM26.625 30.417h-6.5a1.625 1.625 0 1 1 0-3.25h6.5a1.625 1.625 0 1 1 0 3.25Z"
    />
  </svg>
);
export default SvgTritoneText;
