export function debounce<T, A extends unknown[], R>(
	fn: (this: T, ...args: A) => R,
	duration: number = 200
) {
	let timeout: number;

	function debounceInner(this: T, ...args: A) {
		if (timeout) {
			window.clearTimeout(timeout);
		}
		window.setTimeout(() => fn.apply(this, args));
	}

	return debounceInner;
}

export function clickOutside(node: Node, handle: () => void) {
	function checkOutsideClick(event: Event) {
		if (
			node &&
			event &&
			'target' in event &&
			event.target instanceof Element &&
			!node.contains(event.target) &&
			!event.defaultPrevented
		) {
			handle.apply(node);
		}
	}

	document.addEventListener('click', checkOutsideClick, true);

	return () => { document.removeEventListener('click', checkOutsideClick, true); };
}
