import * as React from "react";
const Rocket = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={25}
        fill="none"
        {...props}
    >
        <rect opacity="0.1" width="28" height="28" rx="4" fill="#FF5555"/>
        <mask id="mask0_8184_51516" maskUnits="userSpaceOnUse" x="7" y="6" width="14"
              height="16">
            <path
                d="M13.9622 19.2964C16.9089 19.2964 19.2956 16.9098 19.2956 13.9631C19.2956 11.0164 16.9089 8.62976 13.9622 8.62976C11.0156 8.62976 8.62891 11.0164 8.62891 13.9631C8.62891 16.9098 11.0156 19.2964 13.9622 19.2964Z"
                fill="#555555" stroke="white" stroke-width="1.5" stroke-miterlimit="2" stroke-linejoin="round"/>
            <path
                d="M13.9616 16.6297C15.4349 16.6297 16.6283 15.4364 16.6283 13.9631C16.6283 12.4897 15.4349 11.2964 13.9616 11.2964C12.4883 11.2964 11.2949 12.4897 11.2949 13.9631C11.2949 15.4364 12.4883 16.6297 13.9616 16.6297Z"
                fill="#555555" stroke="white" stroke-width="1.5" stroke-miterlimit="2" stroke-linejoin="round"/>
            <path
                d="M13.9616 14.6297C14.3283 14.6297 14.6283 14.3297 14.6283 13.9631C14.6283 13.5964 14.3283 13.2964 13.9616 13.2964C13.5949 13.2964 13.2949 13.5964 13.2949 13.9631C13.2949 14.3297 13.5949 14.6297 13.9616 14.6297Z"
                fill="white"/>
            <path d="M13.9616 8.62972V7.29639M18.6283 20.6297L17.2949 18.2964M10.6283 18.2964L9.29492 20.6297"
                  stroke="white" stroke-width="1.5" stroke-miterlimit="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
        </mask>
        <g mask="url(#mask0_8184_51516)">
            <path d="M6 6H22V22H6V6Z" fill="#FF5555"/>
        </g>
    </svg>
);
export default Rocket;
