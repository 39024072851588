import { DonutChart } from "react-circle-chart";
import SvgCheckboxSelectedIcon from "../../../../assets/IconComponents/CheckboxSelectedIcon";
import SvgDefaultBrandLogo from "../../../../assets/IconComponents/DefaultBrandLogo";
import SvgWarningIcon from "../../../../assets/IconComponents/WarningIcon";
import Icon from "../../../../components/Icon";
import { BrandRequest } from "../../../../services/BrandApi";
import BrandLogo from "../../../../components/BrandLogo";
import IconButton from "../../../../components/IconButton";
import SvgEdit from "../../../../assets/IconComponents/Edit";


export interface ILoopDetailBrandSelectorItemProps {
    data: BrandRequest
    selected: boolean
    itemSelected: (data: BrandRequest) => void
    onItemEdit: (data: BrandRequest) => void
}

function LoopDetailBrandSelectorItem(props: ILoopDetailBrandSelectorItemProps) {
    const { selected = false } = props

    return (
        <div className={`p-[10px] h-[42px] hover:bg-lightBg hover:border-1 hover:border-border cursor-pointer hover:rounded-[5px] flex items-center justify-between
        ${selected ? "p-[10px] bg-lightBg border-1 border-border rounded-[5px]" : ""}
        `}
            onClick={() => {
                props.itemSelected(props.data)
            }}
        >
            <div className="flex flex-row items-center justify-start gap-[10px]">
                <div>
                    <BrandLogo brand={props.data} />
                </div>
                <div className="inline-flex items-center justify-start gap-[5px]">
                    <span className="text-BodyMedium text-black">{props.data.brand_name}</span>
                    <IconButton title={""} icon={SvgEdit} onClick={function (e: any): void {
                        props.onItemEdit(props.data)
                        e.stopPropagation()
                    }} />
                </div>
            </div>

            <div className="flex flex-row items-center justify-end w-[76px] gap-[5px]">
                {(props.data.definition_ratio !== undefined && props.data.definition_ratio < 100) &&
                    <div className="w-12 h-[18px] justify-start items-center gap-[5px] inline-flex">
                        <DonutChart
                            items={[{ value: 38, label: "", color: "#FF5555" }]}
                            showTotal={false}
                            size={20}
                            trackColor="#EAEBE6"
                        />
                        <span className="text-H8 text-red">%{props.data.definition_ratio}</span>
                    </div>
                }
                {(props.data.definition_ratio !== undefined && props.data.definition_ratio < 100 && selected) &&
                    <div className="w-px h-[11px] bg-stone-200 border-1"></div>
                }
                {selected &&
                    <SvgCheckboxSelectedIcon />
                }
            </div>

        </div>
    );
}

export default LoopDetailBrandSelectorItem;
