import { t } from "i18next";
import { useEffect, useState } from "react";
import { useRef } from "react";
import PrimaryButton from "../../components/PrimaryButton";
import ToggleButton from "../../components/ToggleButton";
import { clickOutside } from "../../helpers/ClickOutside";
import { useAppDispatch } from "../../helpers/hooks";
import { Plan, useLazyListPQuery as usePlanQuery } from "../../services/PlanApi";
import { UserPaymentMethod } from "../../services/UsertApi";
import { setCollapsedModeEnabled, setDialog, toggleDialog } from "../../store/appSlice";
import BenefitsTable from "./BenefitTable/BenefitsTable";
import PlanCard from "./PlanCard";
import { SubscribePlanParameters } from "./PlanPage";
import SubscriptionPopupOpener from "./SubscriptionPopup/SubscriptionPopup";

export interface IPlanEditPopupProps {
    onClosed: () => void;
    onPlanUpdateSucceeded: (effectiveAt?: string) => void;
    currentPlanId?: string;
    paymentMethod?: UserPaymentMethod;
    onUpdatePaymentMethod: () => void;
}

function PlanEditPopupOpener(props: IPlanEditPopupProps) {
    const dispatch = useAppDispatch();
    useEffect(() => {
        showSubscriptionDialog();
        dispatch(setCollapsedModeEnabled(true));
    }, []);
    const showSubscriptionDialog = () => {
        dispatch(
            setDialog({
                actions: <></>,
                title: "",
                message: "",
                customTemplate: <PlanEditPopup {...props}></PlanEditPopup>,
                // customZIndex: "z-[51]",
            }),
        );
        dispatch(toggleDialog(true));
    };
    return <></>;
}

function PlanEditPopup(props: IPlanEditPopupProps) {
    const [plans, setPlans] = useState<Plan[]>([]);
    const [selectedPlanType, setSelectedPlanType] = useState<"monthly" | "yearly">("monthly");
    const [subscribePlanParameters, setSubscribePlanParameters] = useState<SubscribePlanParameters>();
    const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
    const [listPlans, { data: planData }] = usePlanQuery();
    const [currentPlan, setCurrentPlan] = useState<Plan>();

    const ref = useRef<HTMLDivElement>(null);
    const benefitTableRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        listPlans();
    }, []);

    useEffect(() => {
        if (ref.current) {
            return clickOutside(ref.current, () => {
                props.onClosed();
            });
        }
    }, [ref.current]);

    useEffect(() => {
        if (planData) {
            setPlans(planData);
        }
    }, [planData]);

    useEffect(() => {
        if (planData) {
            planData.forEach(p => {
                if (p.id === props.currentPlanId) {
                    setCurrentPlan(p);
                }
            });
        }
    }, [planData, props.currentPlanId]);

    return (
        <>
            (
            <div className="fixed lg:top-0 left-0 right-0 bottom-0 z-[52]  flex flex-col  h-full items-center overflow-scroll scrollbar-hide " style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div
                    ref={ref}
                    className="w-[calc(90%)] mx-2 my-[40px] md:mx-0 flex flex-col justify-center bg-white p-2 rounded-[10px]"
                >
                    <div className="flex flex-col mt-[8px] gap-[80px]">
                        <div className="w-full flex-col justify-start items-start gap-10 inline-flex relative ">
                            <div className="self-stretch h-[180px] flex-col justify-center items-center gap-[30px] flex">
                                <div className="self-stretch h-20 flex-col justify-center items-center gap-5 flex">
                                    <div className="self-stretch text-center text-black text-[28px] font-semibold font-['Inter Tight'] leading-[39.20px]">
                                        Select Plan
                                    </div>
                                    <div className="self-stretch text-center text-black text-[15px] font-normal font-['Inter Tight'] leading-[21px]">
                                        Let’s setup your account and get you started!
                                    </div>
                                </div>
                                <div className="relative justify-center items-center gap-2.5 inline-flex">
                                    <div className="text-center text-black text-base font-semibold font-['Inter Tight'] leading-snug">
                                        Monthly
                                    </div>
                                    <ToggleButton
                                        isChecked={selectedPlanType === "yearly"}
                                        handleCheckBoxChange={function (yearly: any) {
                                            yearly
                                                ? setSelectedPlanType("yearly")
                                                : setSelectedPlanType("monthly");
                                        }}
                                        checkedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                                        checkedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition translate-x-full"
                                        uncheckedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                                        uncheckedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition transition"
                                    />
                                    <div className="text-center text-black text-base font-semibold font-['Inter Tight'] leading-snug">
                                        Yearly
                                    </div>
                                    <div className="absolute right-[-90px] text-right text-red text-[10px] font-bold font-['Inter Tight'] leading-[14px] tracking-wide">
                                        2 MONTHS FREE
                                    </div>
                                </div>
                                <div
                                    onClick={() => {
                                        benefitTableRef.current?.scrollIntoView(true);
                                    }}
                                    className="self-stretch text-center text-red text-[13px] font-normal font-['Inter Tight'] underline leading-[18.20px]"
                                >
                                    <a href="#BENEFITS_TABLE">See full list of features</a>
                                </div>
                            </div>
                            <div className="w-full justify-start items-start gap-2.5 inline-flex mt-5">
                                {plans.map((p) => (
                                    <PlanCard
                                        plan={p}
                                        isUpdate={true}
                                        planType={selectedPlanType}
                                        onTrialClicked={() => {
                                            // this should not be called from plan edit popup
                                        }}
                                        onSubscribe={function (
                                            plan: Plan,
                                            skipTrial: boolean,
                                            applyDiscount: boolean,
                                        ): void {
                                            setSubscribePlanParameters({
                                                plan,
                                                skipTrial,
                                                applyDiscount,
                                            });
                                            setShowSubscriptionPopup(true);
                                        }}
                                        isSelectedPlan={props.currentPlanId === p.id}
                                        isLowerThanCurrentPlan={(p.monthlyPrice ?? 0) < (currentPlan?.monthlyPrice ?? 0)}
                                    />
                                ))}
                            </div>
                        </div>
                        <div id="BENEFITS_TABLE">
                            <BenefitsTable ref={benefitTableRef} plans={plans} />
                        </div>
                        {showSubscriptionPopup && (
                            <SubscriptionPopupOpener
                                mode={"update"}
                                plan={subscribePlanParameters?.plan ?? plans[0]}
                                selectedPlanType={selectedPlanType}
                                onClose={function (): void {
                                    setShowSubscriptionPopup(false);
                                    props.onClosed();
                                }}
                                onSubscriptionCompleted={(trialEndDateParam) => { }}
                                paymentMethod={props.paymentMethod}
                                onPlanUpdated={props.onPlanUpdateSucceeded}
                                onUpdatePaymentMethod={props.onUpdatePaymentMethod}
                                applyDiscount={subscribePlanParameters?.applyDiscount ?? false}
                                skipTrial={subscribePlanParameters?.skipTrial ?? false}
                            />
                        )}
                    </div>
                </div>
            </div>
            );
        </>
    );
}

export default PlanEditPopupOpener;
