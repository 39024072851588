import PlanBenefitGroupHeading from './PlanBenefitGroupHeading'
import PlanBenefitLine from './PlanBenefitLine'
import { Plan } from '../../../services/PlanApi'
import BenefitsTableHeading from './BenefitsTableHeading'
import { FacebookColor, PinterestColor, LinkedinColor, InstagramColor, TwitterColor, Medium } from '../../../assets/IconComponents'
import { RefObject } from 'react'
import { t } from 'i18next'

interface IBenefitsTableProps {
    plans: Plan[]
    ref: RefObject<HTMLDivElement>
}

function BenefitsTable(props: IBenefitsTableProps) {
    return (
        <div ref={props.ref} className="text-[0px]">
            <div className="w-full px-5 border-stone-200 flex-col justify-start items-start inline-flex">
                <BenefitsTableHeading plans={props.plans}></BenefitsTableHeading>
            </div>
            <div className="w-full px-5 pb-5 bg-white rounded-lg border border-stone-200 flex-col justify-start items-start inline-flex">
                <PlanBenefitLine questionMark={false} groupId={""} id={"monthly-credits"} plans={props.plans} retrieveFromPlan={true}></PlanBenefitLine>
                <PlanBenefitGroupHeading groupId={"ai-powered-ad-generation"} plans={props.plans}></PlanBenefitGroupHeading>
                <PlanBenefitLine groupId={"ai-powered-ad-generation"} id={"text"} plans={props.plans} tooltip={t("text-benefit-tooltip") ?? undefined}></PlanBenefitLine>
                <PlanBenefitLine groupId={"ai-powered-ad-generation"} id={"image"} plans={props.plans} lineStyleClassName="bg-neutral-100" tooltip={t("image-benefit-tooltip") ?? undefined}></PlanBenefitLine>
                <PlanBenefitLine groupId={"ai-powered-ad-generation"} id={"creative-image"} plans={props.plans} tooltip={t("creative-image-benefit-tooltip") ?? undefined}></PlanBenefitLine>
                <PlanBenefitLine groupId={"ai-powered-ad-generation"} id={"video"} plans={props.plans} lineStyleClassName="bg-neutral-100"></PlanBenefitLine>

                <PlanBenefitGroupHeading groupId={"ai-marketing-operation"} plans={props.plans}></PlanBenefitGroupHeading>
                <PlanBenefitLine groupId={"ai-marketing-operation"} id={"ai-marketing-plan-generator"} plans={props.plans} tooltip={t("ai-marketing-plan-generator-benefit-tooltip") ?? undefined}></PlanBenefitLine>
                <PlanBenefitLine groupId={"ai-marketing-operation"} id={"ai-auto-ads-manager"} plans={props.plans} lineStyleClassName="bg-neutral-100" tooltip={t("ai-auto-ads-manager-benefit-tooltip") ?? undefined}></PlanBenefitLine>
                <PlanBenefitLine groupId={"ai-marketing-operation"} id={"ai-social-engagers"} plans={props.plans} tooltip={t("ai-social-engagers-benefit-tooltip") ?? undefined}></PlanBenefitLine>

                <PlanBenefitGroupHeading groupId={"integrations"} plans={props.plans}></PlanBenefitGroupHeading>
                <PlanBenefitLine groupId={"integrations"} id={"social-media"} plans={props.plans} icons={<> <FacebookColor /><TwitterColor /><InstagramColor /><LinkedinColor /><PinterestColor /></>} tooltip={t("social-media-benefit-tooltip") ?? undefined}></PlanBenefitLine>
                <PlanBenefitLine groupId={"integrations"} id={"blog"} plans={props.plans} lineStyleClassName="bg-neutral-100" icons={<><Medium /></>} tooltip={t("blog-benefit-tooltip") ?? undefined}></PlanBenefitLine>
                <PlanBenefitLine groupId={"integrations"} id={"ad-accounts"} plans={props.plans} tooltip={t("ad-accounts-benefit-tooltip") ?? undefined}></PlanBenefitLine>
                <PlanBenefitLine groupId={"integrations"} id={"emails"} plans={props.plans} lineStyleClassName="bg-neutral-100" tooltip={t("emails-benefit-tooltip") ?? undefined}></PlanBenefitLine>


                <PlanBenefitGroupHeading groupId={"access-to-ai-models"} plans={props.plans}></PlanBenefitGroupHeading>
                <PlanBenefitLine groupId={"access-to-ai-models"} id={"advanced-models"} plans={props.plans} tooltip={t("advanced-models-benefit-tooltip") ?? undefined}></PlanBenefitLine>
                <PlanBenefitLine groupId={"access-to-ai-models"} id={"custom"} plans={props.plans} lineStyleClassName="bg-neutral-100" tooltip={t("custom-ai-models-benefit-tooltip") ?? undefined}></PlanBenefitLine>

                <PlanBenefitGroupHeading groupId={"brand-personalization"} plans={props.plans}></PlanBenefitGroupHeading>
                <PlanBenefitLine groupId={"brand-hub"} id={"number-of-brands"} plans={props.plans} lineStyleClassName="bg-neutral-100" tooltip={t("number-of-brands-benefit-tooltip") ?? undefined}></PlanBenefitLine>
                <PlanBenefitLine groupId={"brand-hub"} id={"brand-summary-generator"} plans={props.plans} tooltip={t("brand-summary-generator-benefit-tooltip") ?? undefined} ></PlanBenefitLine>
                <PlanBenefitLine groupId={"brand-hub"} id={"brand-contents-keyword-generator"} plans={props.plans} lineStyleClassName="bg-neutral-100" tooltip={t("brand-contents-keyword-generator-benefit-tooltip") ?? undefined}></PlanBenefitLine>
                <PlanBenefitLine groupId={"brand-hub"} id={"tone-of-voice-generator"} plans={props.plans} tooltip={t("tone-of-voice-generator-benefit-tooltip") ?? undefined}></PlanBenefitLine>

                <PlanBenefitGroupHeading groupId={"support-heading"} plans={props.plans}></PlanBenefitGroupHeading>
                <PlanBenefitLine questionMark={false} groupId={"support"} id={"support"} plans={props.plans} lastLine={true} lineStyleClassName="bg-neutral-100"></PlanBenefitLine>

            </div >
        </div >
    )
}

export default BenefitsTable