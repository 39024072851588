import * as React from "react";
import type { SVGProps } from "react";
const SvgCollapse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      d="M16.15 15.45C16.3 15.3 16.375 15.125 16.375 14.925C16.375 14.725 16.3 14.55 16.15 14.4L11.725 9.97499L16.175 5.54999C16.325 5.39999 16.3959 5.22915 16.3875 5.03749C16.3792 4.84582 16.3 4.67499 16.15 4.52499C16 4.37499 15.825 4.29999 15.625 4.29999C15.425 4.29999 15.25 4.37499 15.1 4.52499L10.175 9.44999C10.0917 9.53332 10.0334 9.61665 10 9.69999C9.96669 9.78332 9.95003 9.87499 9.95003 9.97499C9.95003 10.075 9.96669 10.1708 10 10.2625C10.0334 10.3542 10.0917 10.4417 10.175 10.525L15.125 15.475C15.275 15.625 15.4459 15.6958 15.6375 15.6875C15.8292 15.6792 16 15.6 16.15 15.45ZM9.82503 15.45C9.97503 15.3 10.05 15.125 10.05 14.925C10.05 14.725 9.97503 14.55 9.82503 14.4L5.40003 9.97499L9.85003 5.54999C10 5.39999 10.0709 5.22915 10.0625 5.03749C10.0542 4.84582 9.97503 4.67499 9.82503 4.52499C9.67503 4.37499 9.50003 4.29999 9.30003 4.29999C9.10003 4.29999 8.92503 4.37499 8.77503 4.52499L3.85003 9.44999C3.76669 9.53332 3.70836 9.61665 3.67503 9.69999C3.64169 9.78332 3.62503 9.87499 3.62503 9.97499C3.62503 10.075 3.64169 10.1708 3.67503 10.2625C3.70836 10.3542 3.76669 10.4417 3.85003 10.525L8.80003 15.475C8.95003 15.625 9.12086 15.6958 9.31253 15.6875C9.50419 15.6792 9.67503 15.6 9.82503 15.45Z"
      fill={props.fill ?? "#8E8E8E"}
    />
  </svg>
);
export default SvgCollapse;

