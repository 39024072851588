import * as React from "react";
import type { SVGProps } from "react";
const SvgStatuIcon = (props: SVGProps<SVGSVGElement>) => (


<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect opacity="0.1" width="28" height="28" rx="4" fill="#FF5555"/>
<path opacity="0.5" d="M14.5527 21.1663C14.2899 21.1669 14.0297 21.1155 13.787 21.0149C13.5443 20.9143 13.3239 20.7666 13.1387 20.5803L7.52932 14.971C7.40428 14.846 7.33402 14.6765 7.33398 14.4997V8.49967C7.33398 8.32286 7.40422 8.15329 7.52925 8.02827C7.65427 7.90325 7.82384 7.83301 8.00065 7.83301H14.0007C14.1774 7.83305 14.347 7.90331 14.472 8.02834L20.0813 13.6377C20.2671 13.8233 20.4144 14.0438 20.5149 14.2864C20.6154 14.529 20.6672 14.7891 20.6672 15.0517C20.6672 15.3143 20.6154 15.5743 20.5149 15.817C20.4144 16.0596 20.2671 16.28 20.0813 16.4657L15.9667 20.5803C15.7815 20.7666 15.5613 20.9144 15.3187 21.015C15.0761 21.1155 14.8159 21.167 14.5533 21.1663H14.5527Z" fill="#FF5555"/>
<path d="M11 12.5C11.5523 12.5 12 12.0523 12 11.5C12 10.9477 11.5523 10.5 11 10.5C10.4477 10.5 10 10.9477 10 11.5C10 12.0523 10.4477 12.5 11 12.5Z" fill="#FF5555"/>
</svg>



);
export default SvgStatuIcon;
