import * as React from "react";
import type { SVGProps } from "react";
const SvgCloseLittle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      d="M11.37 2.033a.583.583 0 0 0-.407.18L7 6.175 3.037 2.213a.583.583 0 1 0-.824.824L6.175 7l-3.962 3.963a.583.583 0 1 0 .824.824L7 7.825l3.963 3.962a.583.583 0 1 0 .824-.824L7.825 7l3.962-3.963a.583.583 0 0 0-.417-1.004Z"
    />
  </svg>
);
export default SvgCloseLittle;
