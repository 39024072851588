import { Close, InstagramColor } from "../../../assets/IconComponents";
import CloseLittle from "../../../assets/IconComponents/CloseLittle";
import SvgCloseLittle from "../../../assets/IconComponents/CloseLittle";
import Icon from "../../../components/Icon";
import IconButton from "../../../components/IconButton";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { toggleContentWizard } from "../../../store/appSlice";
import { setNewContent } from "../../../store/contentSlice";


function Header() {
    const newContent = useAppSelector(k => k.content.newContent)
    const dispatch = useAppDispatch();

    return (<>
        <div className="w-full h-14 px-5 bg-white shadow-inner justify-start items-center gap-5 inline-flex border-b-1 shrink-0">
            <div className="grow shrink basis-0 h-[22px] justify-start items-center gap-2.5 flex">
                <div className="w-5 h-5 relative" >{newContent?.medium?.iconColor && (
                    <Icon width="20" height="20" component={newContent.medium.iconColor} />
                )}</div>
                <div className="grow shrink basis-0 text-black text-base font-semibold font-['Inter Tight'] leading-snug">
    {newContent?.medium?.name === newContent?.mediumOption?.title ? (
        `Create a ${newContent?.medium?.name}`
    ) : (
        `Create a ${newContent?.medium?.name} ${newContent?.mediumOption?.title}`
    )}
</div>
            </div>
            <div className="w-5 h-5 relative">
                <IconButton
                    title={""}
                    icon={Close}
                    onClick={function (e: any): void {
                        dispatch(setNewContent(undefined));
                        dispatch(toggleContentWizard());
                    }} />
            </div>
        </div>
    </>)
}

export default Header;
