import { dataURLToBlob } from "blob-util";
import { t } from "i18next";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Add, Close } from "../../../assets/IconComponents";
import SvgBrandTritoneBig from "../../../assets/IconComponents/BrandTritoneBig";
import PrimaryButton from "../../../components/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import {
  BrandRequest,
  BrandKeywords,
  useRefreshKeywordMutation,
  useSave5Mutation,
} from "../../../services/BrandApi";
import { useUploadMutation } from "../../../services/FileApi";
import {
  setDialog,
  toggleDialog,
  toggleLoading,
} from "../../../store/appSlice";
import BrandHubBrandInfo from "./BrandHubBrandInfo";
import BrandHubBrandTone from "./BrandHubBrandTone";
import BrandHubBrandVisuals from "./BrandHubBrandVisuals";
import { useLocation, useNavigate } from "react-router-dom";
import { setNewBrandInfo } from "../../../store/brandSlice";
import { TonOfVoices } from "../../../constants/TonOfVoices";
import Warning from "../../../assets/IconComponents/Warning";
import WarningMessage from "../../../components/WarningMessage";
import { useLazyRefreshQuery } from "../../../services/KeywordApi";
import { setLastSelectedBrandId } from "../../../store/persistedSlice";
import { toggleBrandEditing } from "../../../store/appSlice";
import ConfirmationPopupOpener from "../../../components/ConfirmationPopup/ConfirmationPopup";
import { instanceOfApiErrorWithCode } from "../../../services/Core/BaseApi";
import { PaymentErrorCode } from "../../../services/Error/PaymentErrorCode";
import PlanEditPopupOpener from "../../PlanPage/PlanEditPopup";
import {
  UserSettingsResponse,
  useLazyUserSettingsQuery,
} from "../../../services/UsertApi";
import GeneralHorizontalTab from "../../Tabs/GeneralHorizontalTab";
import AccountFrozenPage from "../../ErrorPage/AccountFrozenPage";
import AccountSettingsPage from "../../AccountSettings/AccountSettingsPage";
import BrandHubGeneral from "./BrandHubGeneral";
import BrandHubSocials from "./BrandHubSocials";
import BrandHubAudiences from "./BrandHubAudiences";
import BrandHubBrandIdentity from "./BrandHubBrandIdentity";
import BrandHubGallery from "./BrandHubGallery";
import BrandHubCustomTraining from "./BrandHubCustomTraining";

interface BrandHubCreateNewPageProps { }

const BrandHubCreateNewPageWithTab: FunctionComponent<
  BrandHubCreateNewPageProps
> = () => {
  const BrandInfoRef = useRef<HTMLDivElement>(null);
  const BrandToneRef = useRef<HTMLDivElement>(null);
  const BrandVisualsRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [error, setError] = useState("");
  const [
    getUserSettings,
    {
      isLoading: userSettingsFetching,
      isSuccess: userSettingsSuccess,
      data: userSettingsResponse,
    },
  ] = useLazyUserSettingsQuery();
  const [
    saveBrandInfo,
    { isSuccess: brandSaved, data: savedBrandData, error: saveBrandError },
  ] = useSave5Mutation();
  const [refreshKeywords, { data: keywords, isLoading: keywordsLoading }] =
    useRefreshKeywordMutation();
  const [showUpgradePlanDialog, setShowUpgradePlanDialog] = useState(false);
  const [isUpgradePlanPopupOpen, setIsUpgradePlanPopupOpen] = useState(false);
  const [showPlanUpdateSuccessPopup, setShowPlanUpdateSuccessPopup] =
    useState(false);
  const [effectiveAt, setEffectiveAt] = useState("");
  const [userSettings, setUserSettings] = useState<UserSettingsResponse>();

  const brandInfo = useAppSelector((k) => k.brand.newBrand);
  const brandInfoMostRecentRef = useRef(brandInfo);

  useEffect(() => {
    if (state?.brand) {
      const brandToUse = state?.brand as BrandRequest;
      dispatch(
        setNewBrandInfo({
          id: brandToUse.id,
          brandName: brandToUse.brand_name,
          webSiteUrl: brandToUse.web_url,
          twitter: brandToUse.twitter_url,
          instagram: brandToUse.instagram_url,
          linkedIn: brandToUse.linkedin_url,
          facebook: brandToUse.facebook_url,
          aboutCompany: brandToUse.about_company,
          brandColors: brandToUse.brand_colors?.split(","),
          brandTones:
            brandToUse.brand_tone
              ?.split(",")
              .map((k) => TonOfVoices.find((l) => l.key === k)?.value ?? "") ??
            [],
          selectedCompanyLogos: brandToUse.logo_file_ids,
          selectedCompanyAccentFont: brandToUse.accent_font_file_id,
          selectedCompanyFiles: brandToUse.company_materials_file_ids,
          selectedCompanyIcons: brandToUse.icon_file_ids,
          selectedProductPhotos: brandToUse.product_file_ids,
          selectedCompanyPrimaryFont: brandToUse.primary_font_file_id,
          selectedCompanySecondaryFont: brandToUse.secondary_font_file_id,
          sector: brandToUse.sector,
          subSector: brandToUse.sub_sector,
          technicalLinks: brandToUse.technical_links,
          selectedKeywords: brandToUse.keywords?.filter(
            (k) => k.source === "AI"
          ),
          userEnteredKeywords: brandToUse.keywords?.filter(
            (k) => k.source === "USER"
          ),
          metaAdsSettings: brandToUse.meta_ads_settings
        })
      );
    }
  }, [state?.brand]);

  const isFormValid = () => {
    if (!brandInfo?.aboutCompany || brandInfo?.aboutCompany === "") {
      setError("Please Check About Company Field");
      return false;
    }
    if (!brandInfo?.webSiteUrl || brandInfo?.webSiteUrl === "") {
      setError("Please Check Web Site Url Field");
      return false;
    }
    if (!brandInfo?.brandName || brandInfo?.brandName === "") {
      setError("Please Check Brand Name Field");
      return false;
    }

    return true;
  };

  const saveBrand = () => {
    if (!isFormValid()) {
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    console.log("Brand Audiences:", brandInfo?.associatedAudiences);
    saveBrandInfo({
      brandRequest: {
        id: brandInfo.id,
        about_company: brandInfo?.aboutCompany ?? "",
        web_url: brandInfo?.webSiteUrl ?? "f",
        brand_name: brandInfo?.brandName ?? "",
        twitter_url: brandInfo?.twitter,
        linkedin_url: brandInfo?.linkedIn,
        instagram_url: brandInfo?.instagram,
        facebook_url: brandInfo?.facebook,
        logo_file_ids: brandInfo.selectedCompanyLogos,
        icon_file_ids: brandInfo.selectedCompanyIcons,
        product_file_ids: brandInfo.selectedProductPhotos,
        reference_files: brandInfo.referenceFiles,
        accent_font_file_id: brandInfo.selectedCompanyAccentFont,
        primary_font_file_id: brandInfo.selectedCompanyPrimaryFont,
        secondary_font_file_id: brandInfo.selectedCompanySecondaryFont,
        company_materials_file_ids: brandInfo.selectedCompanyFiles,
        brand_tone: TonOfVoices.find((k) =>
          brandInfo.brandTones?.find((l) => l === k.value)
        )?.key,
        brand_colors: brandInfo.brandColors?.filter((k) => k != "").join(","),
        keywords: [
          ...(brandInfo.selectedKeywords ?? []),
          ...(brandInfo.userEnteredKeywords ?? []),
        ],
        sector: brandInfo?.sector,
        sub_sector: brandInfo?.subSector,
        technical_links: brandInfo?.technicalLinks || [],
        audience_ids: brandInfo?.associatedAudiences?.map(
          (audience) => audience.audience_id ?? ""
        ),
        meta_ads_settings: brandInfo.metaAdsSettings

      },
    });
  };

  useEffect(() => {
    if (brandSaved) {
      showSuccessDialog();
      dispatch(setLastSelectedBrandId(savedBrandData?.id));
    }
  }, [brandSaved]);

  const showSuccessDialog = () => {
    dispatch(
      setDialog({
        actions: <></>,
        title: "",
        message: "",
        customTemplate: (
          <>
            <div className="flex flex-col items-center justify-center ">
              <SvgBrandTritoneBig width={72} height={72} viewBox="0 0 72 72" />
              <p className="mt-[30px] text-H6 text-black">
                Your brand template successfully added!
              </p>
              <p className="mt-[10px] text-BodyMedium text-gray text-center">
                Now you can use your brand template while creating contents.
              </p>
              <div className="w-full border-b-1 border-border mt-5 -ml-5 -mr-5"></div>
              <div className="mt-[15px] h-[40px] w-full flex flex-row items-end justify-end">
                <PrimaryButton
                  className="h-full w-1/5 md:w-[80px]"
                  noFill
                  onClick={() => {
                    dispatch(toggleDialog(false));
                    navigate("/app/brandhub");
                  }}
                  title="Close"
                />
                <PrimaryButton
                  className="h-full ml-2 mt-2 md:mt-0 w-7/13 md:w-[200px] pr-2"
                  icon={<Add fill="#fff" className="max-md:ml-2 mr-2" />}
                  onClick={() => {
                    dispatch(toggleDialog(false));
                    navigate("/app/homepage");
                  }}
                  title="Create New Content"
                />
              </div>
            </div>
          </>
        ),
      })
    );
    dispatch(toggleDialog(true));
  };

  const refreshBrandKeywords = () => {
    const currentBrandInfo = brandInfoMostRecentRef.current;

    refreshKeywords({
      aiRefreshKeywordRequest: {
        about: currentBrandInfo.aboutCompany,
        instagram: currentBrandInfo.instagram,
        linkedin: currentBrandInfo.linkedIn,
        twitter: currentBrandInfo.twitter,
      },
    })
      .unwrap()
      .then((k) => {
        let l = ((k.keywords ?? "").split(",") ?? []).map(
          (k) => ({ source: "AI", value: k } as BrandKeywords)
        );
        let u = currentBrandInfo.userEnteredKeywords ?? [];

        if (l.length !== 0) {
          dispatch(
            setNewBrandInfo({
              ...currentBrandInfo,
              selectedKeywords: [...l],
            })
          );
        }
      });

  };

  useEffect(() => {
    dispatch(toggleBrandEditing(true));
  }, []);

  useEffect(() => {
    brandInfoMostRecentRef.current = brandInfo;
  }, [brandInfo]);

  useEffect(() => {
    if (saveBrandError && instanceOfApiErrorWithCode(saveBrandError)) {
      const errorData = saveBrandError.data;
      if (errorData.errorCode === PaymentErrorCode.BrandLimitExceeded) {
        setShowUpgradePlanDialog(true);
        getUserSettings();
      } else {
        setError(errorData.message);
      }
    }
  }, [saveBrandError]);

  useEffect(() => {
    dispatch(toggleLoading(userSettingsFetching));
  }, [userSettingsFetching]);

  useEffect(() => {
    if (userSettingsResponse) {
      setUserSettings(userSettingsResponse);
    }
  }, [userSettingsResponse]);

  const exitWizard = () => {
    dispatch(
      setDialog({
        message:
          t(
            "are-you-sure-you-want-to-leave-this-page-unsaved-changes-will-be-lost"
          ) ?? "",
        title: t("unsaved-changes"),
        actions: (
          <>
            <div className="flex w-full flex-col lg:flex-row lg:justify-end">
              <PrimaryButton
                className="h-10 lg:h-12 w-full lg:w-[140px]"
                noFill
                title={t("cancel")}
                onClick={() => {
                  dispatch(toggleDialog(false));
                }}
              />
              <PrimaryButton
                className="h-10 lg:h-12 w-full lg:w-[140px] lg:ml-2 max-lg:mt-1"
                title={t("yes-leave")}
                onClick={() => {
                  dispatch(toggleDialog(false));
                  dispatch(toggleBrandEditing(false));
                  navigate("/app/brandhub");
                }}
              />
            </div>
          </>
        ),
        onClose: () => {
          dispatch(toggleDialog(false));
        },
      })
    );

    dispatch(toggleDialog(true));
  };

  return (
    <>
      {showPlanUpdateSuccessPopup && (
        <ConfirmationPopupOpener
          heading={"Your Plan Has Been Updated Successfully!"}
          subHeading={
            effectiveAt == "IMMEDIATELY"
              ? "Your plan has been successfully updated. Adjusted features are now in effect."
              : "The current plan continues until the end of your billing cycle. Your new plan will start in the next billing cycle."
          }
          buttonText={"Continue"}
          onClose={() => {
            setShowPlanUpdateSuccessPopup(false);
            dispatch(toggleDialog(false));
          }}
        />
      )}
      {showUpgradePlanDialog && (
        <ConfirmationPopupOpener
          heading={"Managing More Brands?"}
          subHeading={"Step up your plan to add more brands seamlessly."}
          buttonText={"View Plans"}
          onClose={function (): void {
            setShowUpgradePlanDialog(false);
            dispatch(toggleDialog(false));
            setIsUpgradePlanPopupOpen(true);
          }}
        />
      )}
      {isUpgradePlanPopupOpen && (
        <PlanEditPopupOpener
          onClosed={() => {
            setIsUpgradePlanPopupOpen(false);
            dispatch(toggleDialog(false));
          }}
          currentPlanId={userSettings?.subscription?.plan_id}
          paymentMethod={userSettings?.payment_method}
          onPlanUpdateSucceeded={(effectiveAt?: string) => {
            setEffectiveAt(effectiveAt ?? "");
            setIsUpgradePlanPopupOpen(false);
            dispatch(toggleDialog(false));
            setShowPlanUpdateSuccessPopup(true);
          }}
          onUpdatePaymentMethod={() => {
            setIsUpgradePlanPopupOpen(false);
            dispatch(toggleDialog(false));
          }}
        />
      )}
      <div className="relative min-w-full h-[56px] text-xl flex flex-row bg-white lg:-ml-[8px] -mr-[12px] border-1 border-border justify-between items-center">
        <div className="flex flex-row items-center">
          <button
            disabled={false}
            className="w-[56px] h-[56px] border-r-1 border-border"
            onClick={() => {
              exitWizard();
            }}
          >
            <div className="flex justify-center items-center">
              <Close
                width="18"
                height="18"
                viewBox="0 0 20 20"
                fill="#999999"
                className=""
              />
            </div>
          </button>
          <span className="text-H6 text-black ml-[15px] ">
            {t("add-new-brand")}
          </span>
        </div>
        <PrimaryButton
          onClick={() => {
            saveBrand();
            dispatch(toggleBrandEditing(false));
          }}
          title={t("save-brand")}
          className="h-[40px] w-[110px] mr-[30px]"
        />
      </div>
      <div className="flex flex-col w-full md:w-2/3 h-[calc(100vh-140px)] md:h-[calc(100vh-60px)] overflow-scroll scrollbar-hide p-3  mx-auto mt-2">
        <GeneralHorizontalTab
          additionalContent={
            <WarningMessage
              title={error}
              show={error != ""}
              className="w-3/4 mt-5"
              onClose={() => {
                setError("");
              }}
            />
          }
          tabs={[
            {
              title: "General",
              element: (
                <>
                  <BrandHubGeneral
                    keywords={(keywords?.keywords?.split(",") ?? []).map(
                      (k) => ({ source: "AI", value: k } as BrandKeywords)
                    )}
                    refreshKeywords={() => {
                      refreshBrandKeywords();
                    }}
                    ref={BrandInfoRef}
                    expanded={() => {
                      BrandInfoRef?.current?.scrollIntoView();
                    }}
                    brandAboutEntered={() => {
                      refreshBrandKeywords();
                    }}
                    errorOccured={(e) => {
                      setError(e);
                      setTimeout(() => {
                        setError("");
                      }, 3000);
                    }}
                  />
                </>
              ),
            },

            {
              title: "Social Accounts",
              element: <BrandHubSocials />,
            },

            {
              title: "Audiences",
              element: <BrandHubAudiences />,
            },
            {
              title: "Brand Identity",
              element: <BrandHubBrandIdentity />,
            },
            {
              title: "Gallery",
              element: <BrandHubGallery />,
            },
            {
              title: "Custom Training",
              element: <BrandHubCustomTraining />
            }
          ]}
        />
        {/*
            <WarningMessage
          title={error}
          show={error != ""}
          className="w-3/4 mt-5"
          onClose={() => {
            setError("");
          }}
        />
        <BrandHubBrandInfo
          className="md:w-3/4"
          ref={BrandInfoRef}
          expanded={() => {
            BrandInfoRef?.current?.scrollIntoView();
          }}
          brandAboutEntered={() => {
            refreshBrandKeywords();
          }}
          errorOccured={(e) => {
            setError(e);
            setTimeout(() => {
              setError("");
            }, 3000);
          }}
        />
        <BrandHubBrandTone
          className="md:w-3/4"
          keywords={(keywords?.keywords?.split(",") ?? []).map(
            (k) => ({ source: "AI", value: k } as BrandKeywords)
          )}
          refreshKeywords={() => {
            refreshBrandKeywords();
          }}
          ref={BrandToneRef}
          expanded={() => {
            BrandToneRef?.current?.scrollIntoView();
          }}
        />
        <BrandHubBrandVisuals
          className="md:w-3/4"
          ref={BrandVisualsRef}
          expanded={() => {
            BrandVisualsRef?.current?.scrollIntoView();
          }}
        />
    */}
      </div>
    </>
  );
};

export default BrandHubCreateNewPageWithTab;
