import * as React from "react";
const SvgMediumSocialsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M6.736 5.938c2.334 0 4.235 1.817 4.235 4.06 0 2.244-1.892 4.061-4.235 4.061C4.393 14.06 2.5 12.242 2.5 10c0-2.244 1.893-4.062 4.236-4.062Zm6.753.237c1.168 0 2.118 1.714 2.118 3.823 0 2.11-.95 3.824-2.118 3.824-1.167 0-2.117-1.714-2.117-3.824s.95-3.823 2.117-3.823Zm3.269.395c.409 0 .742 1.532.742 3.428s-.334 3.43-.742 3.43c-.409 0-.742-1.534-.742-3.43 0-1.896.333-3.428.742-3.428Z"
    />
  </svg>
);
export default SvgMediumSocialsIcon;
