import * as React from "react";
import type { SVGProps } from "react";
const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      
      d="M9.988 2.489a.833.833 0 0 0-.821.844v7.989l-1.078-1.078a.835.835 0 0 0-1.366.908.833.833 0 0 0 .188.27l2.5 2.5a.833.833 0 0 0 1.178 0l2.5-2.5a.833.833 0 1 0-1.178-1.178l-1.078 1.078V3.333a.833.833 0 0 0-.845-.844ZM3.32 12.905a.833.833 0 0 0-.821.845V15c0 1.37 1.13 2.5 2.5 2.5h10c1.37 0 2.5-1.13 2.5-2.5v-1.25a.834.834 0 1 0-1.667 0V15c0 .47-.363.833-.833.833H5A.821.821 0 0 1 4.167 15v-1.25a.833.833 0 0 0-.846-.845Z"
    />
  </svg>
);
export default SvgDownload;
