import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CopyIcon from '../../assets/Icon/Copy.png';
import DeleteIcon from '../../assets/Icon/Delete.png';
import DownloadIcon from '../../assets/Icon/Download.png';
import LikeIcon from '../../assets/Icon/Like.png';
import LikedIcon from '../../assets/Icon/Liked.png';
import ContentContainer from "../../components/ContentContainer";
import ContentImage from "../../components/ContentImage";
import CopyLoop from "../../components/CopyLoop";
import Icon from "../../components/Icon";
import IconButton from "../../components/IconButton";
import PrimaryButton from "../../components/PrimaryButton";
import { Mediums } from "../../constants/Mediums";
import { useAppDispatch } from "../../helpers/hooks";
import { useDeleteContentMutation, useEditNameMutation } from "../../services/ContentApi.old/ContentApi";
import { useFavoriteImageMutation } from "../../services/ImageApi.old/ImageApi";
import { useEditTextMutation, useFavoriteMutation } from "../../services/TextApi.old/TextApi";
import { setDialog, toggleDialog } from "../../store/appSlice";
import { SelectedLoopsPageListType } from "./LoopsPageListTypeSelector";
import { Copy, Delete, Download, Like, Liked } from "../../assets/IconComponents";
import { LoopsResponse, Revision, useFavorite1Mutation } from "../../services/ContentApi";
import SvgEyeIcon from "../../assets/IconComponents/EyeIcon";
import LoopsCopyLoop from "../../components/LoopsCopyLoop/LoopsCopyLoop";

export interface ILoopsResponseProps {
    loop: LoopsResponse;
    updateLoops: () => void
    onSelect: (contentId: string) => void
    displayType: SelectedLoopsPageListType
}

function LoopListCard(props: ILoopsResponseProps) {
    const [hover, setHover] = useState(false)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()


    const [updateText, { isSuccess: textUpdated }] = useEditTextMutation()
    const [editName, { isSuccess: nameUpdated }] = useEditNameMutation()

    const [deleteContent, { isSuccess: contentDeleted }] = useDeleteContentMutation()
    const [setTextFavorite, { isSuccess: textFavoriteUpdated }] = useFavoriteMutation()
    const [setImageFavorite, { isSuccess: imageFavoriteUpdated }] = useFavoriteImageMutation()

    const [isFavori, setFavori] = useState(false)
    const [cardContent, setCardContent] = useState<Revision>()
    const [showCopyRevision, setShowCopyRevision] = useState(false)
    const [updateFav, { isSuccess: favUpdated }] = useFavorite1Mutation()

    useEffect(() => {
        if (textUpdated || nameUpdated || textFavoriteUpdated || imageFavoriteUpdated || contentDeleted) {
            props.updateLoops()
        }
    }, [textUpdated, nameUpdated, contentDeleted])

    useEffect(() => {
        setCardContent({
            revision_id: props.loop.content?.type == "text" ? (props.loop.revision?.id?.revision_id ?? "") : (props.loop.revision?.id?.revision_id ?? ""),
            revision_parent_id: props.loop.content?.type == "text" ? (props.loop.revision?.revision_parent_id ?? "") : (props.loop.revision?.revision_parent_id ?? ""),
            generated_content: props.loop.content?.type == "text" ? (props.loop.revision?.generated_content ?? "") : (""),
            is_favorite: props.loop.content?.type == "text" ? (props.loop.revision?.is_favorite ?? "") : (props.loop.revision?.is_favorite ?? ""),
            fileId: props.loop.content?.type == "image" ? (props.loop.revision?.generated_content ?? "") : (""),
            contentId: props.loop.content?.id?.content_id ?? ""
        } as Revision)
    }, [props.loop])

    useEffect(() => {
        setFavori((props.loop.content?.is_favorite ?? false) || (props.loop.revision?.is_favorite ?? false))
    }, [cardContent])

    const getMediaIcon = (mediaName: string) => {
        const platformName = mediaName.split(" | ")[0]
        const icon = Mediums.find(k => k.name === platformName)?.iconColor
        return icon
    }

    const updateFavorite = () => {
            updateFav({
                contentId: props.loop.revision?.id?.content_id ?? "",
                isFavorite: !isFavori ?? false
            })
        setFavori(!isFavori);
    }

    const showDeleteDialog = () => {
        dispatch(setDialog({
            actions: <>
                <PrimaryButton className="h-12 w-[100px]" noFill title={t('cancel')} onClick={() => { dispatch(toggleDialog(false)) }} />
                <PrimaryButton className="h-12 w-20 ml-2" title={t('delete')} onClick={() => { dispatch(toggleDialog(false)); deleteRevision() }} />
            </>,
            title: t('delete'),
            message: t('are-you-sure-you-would-like-to-delete-content'),
            onClose: () => { dispatch(toggleDialog(false)) }
        }))
        dispatch(toggleDialog(true))
    }

    const deleteRevision = () => {
        deleteContent({
            id: props.loop.content?.id?.content_id ?? ""
        })
    }

    return (
        <ContentContainer className={`p-3 cursor-pointer`}
            onClick={() => { props.onSelect(props.loop.content?.id?.content_id ?? "") }}
            hoverable
            onHover={() => setHover(true)}
            onBlur={() => setHover(false)}>
            <div className={`flex w-full flex-row ${props.loop.content?.type === "image" ? "h-[150px]" : "h-[140px]"}`}>
                <div className="flex flex-1 flex-col w-full justify-start">
                    <div className="flex flex-row items-center justify-between p-2 pb-0" >
                        <div className={`flex flex-col md:flex-row items-center`}>
                            <div className={`flex flex-row items-center`}>
                                <span className="text-H6 text-black">{`${props.loop.content?.name}`}</span>
                                <Icon component={getMediaIcon(props.loop.content?.platform ?? "")} width="20" height="20" className="ml-2" />
                                {
                                    props.loop.content?.read_only &&
                                    <SvgEyeIcon className="ml-2" />
                                }
                            </div>
                            <div className={`flex flex-col md:flex-row rounded-xl text-BodySmall text-black p-2 pl-2 items-center`}>
                                <span className="ml-2 first-letter:text-black text-BodySmall">{props.loop.content?.updated_user}</span>
                                <span className="ml-1 text-gray text-BodySmall">edited on {moment(props.loop.content?.updated_date).format("DD.MM.yyyy HH:mm")}</span>
                            </div>
                        </div>

                        {
                            props.loop.content?.read_only === false &&
                            <div className={`flex justify-end items-center`}>
                                <div className="flex flex-row justify-end">
                                    <div>
                                        <IconButton className={`${isFavori ? "hover:w-[75px]" : "hover:w-[60px]"}`} title={isFavori ? t('unlike') : t('like')}
                                            color={isFavori ? "red" : "gray"}
                                            icon={isFavori ? Liked : Like}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                updateFavorite()
                                            }} />
                                    </div>
                                    <div className="ml-3">
                                        <LoopsCopyLoop contentId={props.loop.content?.id?.content_id ?? ""} />
                                    </div>
                                    <div className="ml-3">
                                        <IconButton className="hover:w-28" title={t('download')} icon={Download} onClick={() => { }} />
                                    </div>
                                    <div className="ml-3">
                                        <IconButton className="hover:w-20" title={t('delete')} icon={Delete} onClick={(e) => {
                                            e.stopPropagation()
                                            showDeleteDialog()
                                        }} />
                                    </div>
                                </div>
                            </div>
                        }



                    </div>

                    {
                        props.loop.content?.type === "text" &&
                        <span className={`mt-[12px] h-full  w-full overflow-scroll scrollbar-hide  bg-bg rounded-xl text-BodySmall text-black p-2`} style={{ whiteSpace: "pre-wrap" }}>
                            {props.loop.revision ? props.loop.revision.generated_content?.slice(0, 300) : ""}
                        </span>

                    }
                    {
                        props.loop.content?.type === "image" && props.loop.content?.image_height === 1920 &&
                        <ContentImage id={cardContent?.fileId ?? ""} className="object-contain max-w-[8%] h-[100px] rounded-lg" />

                    }
                    {
                        props.loop.content?.type === "image" && props.loop.content?.image_height !== 1920 &&
                        <ContentImage id={cardContent?.fileId ?? ""} className="object-contain w-[100px] max-w-[20%] h-[210px] rounded-lg" />

                    }
                </div>
            </div>

        </ContentContainer >

    );
}

export default LoopListCard;
