import { FunctionComponent, useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";
import { IGenericSelectorData } from "../GenericSelector/GenericSelector";
import GenericSelectorPopup from "../GenericSelector/GenericSelectorPopup";
import Icon from "../Icon";
import IconButton from "../IconButton";
import { t } from "i18next";
import { Copy } from "../../assets/IconComponents";
import CopyLoop from "../CopyLoop";

interface LoopsCopyLoopProps {
    contentId: string
}

const LoopsCopyLoop: FunctionComponent<LoopsCopyLoopProps> = (props: LoopsCopyLoopProps) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    return (
        <>
            <Popover
                isOpen={isPopoverOpen}
                positions={['bottom']}
                align="end"
                padding={0}
                onClickOutside={(e) => {e.stopPropagation(); setIsPopoverOpen(false) }}
                clickOutsideCapture
                content={
                    <div className="w-[300px] h-[250px] transform-gpu">
                        <CopyLoop
                        className="border-1 border-red"
                            contentId={props.contentId}
                            onClose={() => {
                                setIsPopoverOpen(false)
                            }}
                            onCopy={() => {

                            }}
                        />
                    </div>
                }
            >
                <div className="h-[41px]"
                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                >
                    <IconButton className="hover:w-16" title={t('copy')} icon={Copy} onClick={(e) => {
                        e.stopPropagation()
                        setIsPopoverOpen(true)
                    }} />
                </div>
            </Popover>
        </>
    );
}

export default LoopsCopyLoop;