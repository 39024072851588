import * as React from "react";
import type { SVGProps } from "react";
const SvgGenerateSimilarBrushIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8421 3.74791L11.3462 2.65416L10.2525 2.15833L11.3462 1.6625L11.8421 0.568748L12.3379 1.6625L13.4316 2.15833L12.3379 2.65416L11.8421 3.74791ZM4.81289 3.74791L4.31706 2.65416L3.22331 2.15833L4.31706 1.6625L4.81289 0.568748L5.30872 1.6625L6.40247 2.15833L5.30872 2.65416L4.81289 3.74791ZM11.8421 10.7771L11.3462 9.68333L10.2525 9.1875L11.3462 8.69166L11.8421 7.59791L12.3379 8.69166L13.4316 9.1875L12.3379 9.68333L11.8421 10.7771ZM2.72747 12.6583L1.34206 11.2729C1.23511 11.166 1.17678 11.042 1.16706 10.901C1.15734 10.7601 1.21567 10.6264 1.34206 10.5L7.90456 3.9375C8.02122 3.82083 8.1622 3.7625 8.32747 3.7625C8.49275 3.7625 8.63372 3.82083 8.75039 3.9375L10.0629 5.25C10.1796 5.36666 10.2379 5.50764 10.2379 5.67291C10.2379 5.83819 10.1796 5.97916 10.0629 6.09583L3.50039 12.6583C3.38372 12.775 3.2549 12.8333 3.11393 12.8333C2.97296 12.8333 2.84414 12.775 2.72747 12.6583ZM3.06289 11.8271L7.62747 7.2625L6.72331 6.35833L2.15872 10.9229L3.06289 11.8271Z"
      fill="#FF5555"
    />
  </svg>
);
export default SvgGenerateSimilarBrushIcon;
