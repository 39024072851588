import * as React from "react";
import type { SVGProps } from "react";
const SvgTipIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={19}
    fill="none"
    {...props}
  >
    <g clipPath="url(#TipIcon_svg__a)">
      <path
        fill="#1A8CF5"
        d="m16.226 7.013-1.008-.464 1.008-.46.455-1.007.462 1.009 1.005.458-1.005.463-.462 1.005-.455-1.004Zm-2.623-3.871-1.495-.701 1.495-.702.696-1.49.706 1.49 1.49.702-1.489.702-.707 1.49-.696-1.491ZM6.645 17.206c-.436 0-.804-.154-1.105-.463a1.565 1.565 0 0 1-.452-1.136H8.21c0 .449-.151.827-.455 1.136a1.498 1.498 0 0 1-1.11.463Zm-2.478-2.789a.579.579 0 0 1-.59-.593.572.572 0 0 1 .59-.586h4.96a.576.576 0 0 1 .59.591.57.57 0 0 1-.59.588h-4.96Zm-.495-2.35a6.355 6.355 0 0 1-2.016-2.061C1.16 9.181.91 8.25.91 7.212c0-1.554.567-2.899 1.7-4.034 1.135-1.135 2.48-1.703 4.036-1.703 1.555 0 2.9.568 4.036 1.703 1.134 1.135 1.702 2.48 1.702 4.034 0 1.036-.246 1.967-.737 2.793a6.198 6.198 0 0 1-2.025 2.063h-5.95Zm.462-1.277h5.042a4.32 4.32 0 0 0 1.412-1.54 4.17 4.17 0 0 0 .519-2.037c0-1.226-.437-2.276-1.311-3.148-.874-.872-1.924-1.308-3.151-1.308-1.227 0-2.276.436-3.147 1.308-.87.872-1.306 1.922-1.306 3.148 0 .728.172 1.407.516 2.037.344.63.82 1.144 1.426 1.54Z"
      />
    </g>
    <defs>
      <clipPath id="TipIcon_svg__a">
        <path fill="#fff" d="M0 .5h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTipIcon;
