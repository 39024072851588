import { FunctionComponent, useEffect, useState } from "react";

import { FileProps } from "../../../services/BrandApi";
import { AudienceResponse } from "../../../services/AudiencetApi";
import ControlButtons from "./ControlButtons";
import Content from "../ContentDetails/Content";
import { Close } from "../../../assets/IconComponents";
import IconButton from "../../../components/IconButton";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import Icon from "../../../components/Icon";
import { setNewContent } from "../../../store/contentSlice";
import { toggleContentWizard } from "../../../store/appSlice";
import SvgAIProductPhotoshootHeaderLogo from "../../../assets/IconComponents/AIProductPhotoshootHeaderLogo";
import ProductPhotoshootContent from "./ProductPhotoshootContent";


interface ContentDetailsProps {
  logoSelectionList: FileProps[];
  productSelectionList: FileProps[];
  isInSide?: boolean;
  setContentCreationDataID?: (id: string) => void;
  setIsLogoSelectionOpen?: (status: boolean) => void;
}

const ProductPhotoshootDetails: FunctionComponent<ContentDetailsProps> = (
  props: ContentDetailsProps
) => {
    const newContent = useAppSelector((k) => k.content.newContent);
    const dispatch = useAppDispatch();
    const [currentImageCount, setCurrentImageCount] = useState<number>(4);
    const [lastRemovedBg, setLastRemovedBG] = useState<string>("");
  return (
    <div
      className={`flex flex-col w-full ${
        props.isInSide ? "relative" : "h-full relative"
      } justify-between`}
    >
      <div className="flex flex-col w-full">
        {!props.isInSide && (
          <div className="w-full h-14 px-5 bg-white shadow-inner justify-start items-center gap-5 inline-flex border-b-1 shrink-0">
            <div className="grow shrink basis-0 h-[22px] justify-start items-center gap-2.5 flex">
              <div className="w-5 h-5 relative">
                {newContent?.medium?.iconColor && (
                  <Icon
                    width="20"
                    height="20"
                    component={SvgAIProductPhotoshootHeaderLogo}
                  />
                )}
              </div>
              <div className="grow shrink basis-0 text-black text-base font-semibold font-['Inter Tight'] leading-snug">
                AI Product Photoshoot
              </div>
            </div>
            <div className="w-5 h-5 relative">
              <IconButton
                title={""}
                icon={Close}
                onClick={function (e: any): void {
                  dispatch(setNewContent(undefined));
                  dispatch(toggleContentWizard());
                }}
              />
            </div>
          </div>
        )}
        
        <ProductPhotoshootContent
          setIsLogoSelectionOpen={props.setIsLogoSelectionOpen}
          numberOfImagesChanged={(number) => setCurrentImageCount(number)}
          isInSide={props.isInSide}
          bgRemoved={(str) => setLastRemovedBG(str)}
          logoSelectionList={props.logoSelectionList}
          productSelectionList={props.productSelectionList}
        />
      </div>
      <div className={`${props.isInSide ? "absolute right-0 bottom-0" : ""}`}>
        <ControlButtons
          lastRemovedBg= {lastRemovedBg}
          numberOfImages = {currentImageCount}
          isInSide={props.isInSide}
          setContentCreationDataID={props.setContentCreationDataID}
        />
      </div>
    </div>
  );
};

export default ProductPhotoshootDetails;
