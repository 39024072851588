import { SVGProps, useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";
import Icon from "../Icon";
import GenericSelectorPopup from "./GenericSelectorPopup";
import ActionSheet from "../ActionSheet/ActionSheet";
import GenericSelectorItem from "./GenericSelectorItem";
import PrimaryButton from "../PrimaryButton";
import { t } from "i18next";

export interface IGenericSelectorData {
    id: string,
    title: string,
}

export interface IGenericSelectorProps {
    icon?: ((props: SVGProps<SVGSVGElement>) => JSX.Element) | undefined
    title?: string
    subTitle: string
    ItemeSelected: (data: IGenericSelectorData | undefined) => void
    Items: IGenericSelectorData[]
    tooltip?: string
    selectedItem?: IGenericSelectorData
    closeOnSelect?: boolean
    hideActionButton?: boolean
    buttonClassName?: string
    popupClassName?: string
    buttonText?: React.ComponentType<{ className?: string; }>
}

function GenericSelector(props: IGenericSelectorProps) {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<IGenericSelectorData | undefined>()
    const [actionSheetOpened, setActionSheetOpened] = useState(false)

    useEffect(() => {
        setSelectedItem(props.selectedItem)
    }, [props.selectedItem])

    return (
        <>
            <div className={`max-lg:hidden lg:flex`}>
                <Popover
                    isOpen={isPopoverOpen}
                    positions={['bottom']}
                    align="start"
                    padding={20}
                    onClickOutside={() => { setIsPopoverOpen(false) }}
                    content={
                        <GenericSelectorPopup
                            subTitle={props.subTitle}
                            className={props.popupClassName}
                            data={props.Items}
                            closePopup={() => setIsPopoverOpen(false)}
                            selectedItem={selectedItem}
                            selecteItemChanged={(e) => {
                                setSelectedItem(e)
                                props.ItemeSelected(e)
                                setIsPopoverOpen(false)
                            }}
                            hideActionButton={props.hideActionButton}
                        />}
                >
                    <div className="h-[36px]"
                        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        data-tooltip-id={props.tooltip ? "app-tooltip" : ""}
                        data-tooltip-html={props.tooltip}
                    >
                        <button
                            className={`
                                    h-[36px]
                                    w-[36px]
                                    bg-white p-[10px]
                                    rounded-lg flex flex-row items-center justify-center
                                    border-1 border-border ${props.buttonClassName}
                                `}
                            onClick={() => {

                            }}>
                            <div className=" rounded-sm p-[2px]">
                                <Icon component={props.icon} className="w-[18px] h-[18px] overflow-hidden" />
                            </div>
                            {props.buttonText && <props.buttonText />}
                        </button>
                    </div>
                </Popover>
            </div>
            <div className={`lg:hidden max-lg:flex`}>
                <ActionSheet
                    title={props.subTitle}
                    isExpanded={actionSheetOpened}
                    setIsExpanded={setActionSheetOpened}
                    triggerView={(
                        <button
                            className={`
                                    h-full
                                    bg-white p-[10px]
                                    rounded-lg flex flex-row items-center
                                    border-1 border-border
                                `}
                            onClick={() => {

                            }}>
                            <div className=" rounded-sm p-[2px]">
                                <Icon component={props.icon} className="w-[20px] overflow-hidden" />
                            </div>
                        </button>
                    )}
                    body={(
                        <div className="w-full p-[15px] pt-[5px] flex flex-col">
                            <div className="h-full overflow-scroll scrollbar-hide">
                                {
                                    props.Items.map(k => (
                                        <GenericSelectorItem
                                            data={k}
                                            selected={selectedItem?.id === k.id}
                                            itemSelected={(e) => {
                                                setSelectedItem(e)
                                            }} />
                                    ))
                                }
                                <PrimaryButton
                                    className="w-full h-[36px] mt-2"
                                    title={t('sort')}
                                    onClick={() => {
                                        props.ItemeSelected(selectedItem)
                                        setActionSheetOpened(false)
                                    }} />
                            </div>
                        </div>
                    )}
                />
            </div>
        </>
    );
}

export default GenericSelector;