import * as React from "react";
import type { SVGProps } from "react";
const SvgBudgetIcon = (props: SVGProps<SVGSVGElement>) => (

<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect opacity="0.1" width="28" height="28" rx="4" fill="#FF5555"/>
<path opacity="0.5" d="M19.3327 11.833H8.66602C8.13575 11.8335 7.62734 12.0444 7.25239 12.4194C6.87743 12.7943 6.66655 13.3027 6.66602 13.833V19.1663C6.66655 19.6966 6.87743 20.205 7.25239 20.58C7.62734 20.9549 8.13575 21.1658 8.66602 21.1663H19.3327C19.863 21.1658 20.3714 20.9549 20.7463 20.58C21.1213 20.205 21.3322 19.6966 21.3327 19.1663V13.833C21.3322 13.3027 21.1213 12.7943 20.7463 12.4194C20.3714 12.0444 19.863 11.8335 19.3327 11.833ZM13.9993 18.4997C13.4689 18.4997 12.9602 18.289 12.5851 17.9139C12.2101 17.5388 11.9993 17.0301 11.9993 16.4997C11.9993 15.9692 12.2101 15.4605 12.5851 15.0855C12.9602 14.7104 13.4689 14.4997 13.9993 14.4997C14.5298 14.4997 15.0385 14.7104 15.4136 15.0855C15.7886 15.4605 15.9993 15.9692 15.9993 16.4997C15.9993 17.0301 15.7886 17.5388 15.4136 17.9139C15.0385 18.289 14.5298 18.4997 13.9993 18.4997Z" fill="#FF5555"/>
<path d="M14.0007 13.1663C13.8238 13.1663 13.6543 13.0961 13.5292 12.9711C13.4042 12.8461 13.334 12.6765 13.334 12.4997V8.49967C13.334 8.32286 13.4042 8.15329 13.5292 8.02827C13.6543 7.90325 13.8238 7.83301 14.0007 7.83301C14.1775 7.83301 14.347 7.90325 14.4721 8.02827C14.5971 8.15329 14.6673 8.32286 14.6673 8.49967V12.4997C14.6673 12.6765 14.5971 12.8461 14.4721 12.9711C14.347 13.0961 14.1775 13.1663 14.0007 13.1663Z" fill="#FF5555"/>
<path d="M15.5003 10.6662C15.4127 10.6663 15.326 10.6491 15.2451 10.6156C15.1642 10.582 15.0908 10.5329 15.0289 10.4709L14.0003 9.4422L12.9716 10.4709C12.8459 10.5923 12.6775 10.6595 12.5027 10.658C12.3279 10.6565 12.1607 10.5863 12.0371 10.4627C11.9135 10.3391 11.8433 10.1719 11.8418 9.99713C11.8403 9.82234 11.9075 9.65393 12.0289 9.5282L13.5289 8.0282C13.654 7.90322 13.8235 7.83301 14.0003 7.83301C14.177 7.83301 14.3466 7.90322 14.4716 8.0282L15.9716 9.5282C16.0648 9.62143 16.1283 9.74021 16.154 9.86951C16.1797 9.99881 16.1665 10.1328 16.1161 10.2546C16.0656 10.3764 15.9802 10.4805 15.8706 10.5538C15.761 10.6271 15.6321 10.6662 15.5003 10.6662Z" fill="#FF5555"/>
<path opacity="0.2" d="M14 18.5C13.4696 18.5 12.9609 18.2893 12.5858 17.9142C12.2107 17.5391 12 17.0304 12 16.5C12 15.9696 12.2107 15.4609 12.5858 15.0858C12.9609 14.7107 13.4696 14.5 14 14.5C14.5304 14.5 15.0391 14.7107 15.4142 15.0858C15.7893 15.4609 16 15.9696 16 16.5C16 17.0304 15.7893 17.5391 15.4142 17.9142C15.0391 18.2893 14.5304 18.5 14 18.5Z" fill="#FF5555"/>
<path d="M10.0007 17.1663C10.3688 17.1663 10.6673 16.8679 10.6673 16.4997C10.6673 16.1315 10.3688 15.833 10.0007 15.833C9.63246 15.833 9.33398 16.1315 9.33398 16.4997C9.33398 16.8679 9.63246 17.1663 10.0007 17.1663Z" fill="#FF5555"/>
<path d="M18.0007 17.1663C18.3688 17.1663 18.6673 16.8679 18.6673 16.4997C18.6673 16.1315 18.3688 15.833 18.0007 15.833C17.6325 15.833 17.334 16.1315 17.334 16.4997C17.334 16.8679 17.6325 17.1663 18.0007 17.1663Z" fill="#FF5555"/>
</svg>

);
export default SvgBudgetIcon;
