import { useEffect, useRef, useState } from "react";
import SvgRoundAudioPlayButton from "../../assets/IconComponents/RoundAudioPlayButton";
import SvgRoundAudioPauseButton from "../../assets/IconComponents/RoundAudioPauseButton";
import { Slider } from "antd";
import PrimaryButton from "../../components/PrimaryButton";
import SvgDownload from "../../assets/IconComponents/Download";
import { useLazyDownloadQuery } from "../../services/FileApi";
import { blobToDataURL, dataURLToBlob } from "blob-util";
import SvgTextToSpeechDownload from "../../assets/IconComponents/TextToSpeechDownloadIcon";
import { FileProps } from "../../services/BrandApi";

export interface IAudioPlayerProps {
  audioSrc?: string;
  className?: string;
  audioFile?: FileProps;
}
function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}

function AudioPlayer(props: IAudioPlayerProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);


  useEffect(() => {
    console.log("Audio File is: ", props.audioFile);
  }, [props.audioFile]);
  
   useEffect(() => {
     const audio = audioRef.current;

     const handleLoadedMetadata = () => {
       if (audio?.duration) {
         const durationSeconds = Math.floor(audio.duration);
         setDuration(durationSeconds);
       }
     };

     audio?.addEventListener("loadedmetadata", handleLoadedMetadata);

     return () => {
       audio?.removeEventListener("loadedmetadata", handleLoadedMetadata);
     };
   }, [audioRef]);


     const downloadFileToDesktop = () => {
           var link = document.createElement("a");
           link.download =
             props.audioFile ? props.audioFile.file_name +
             "." +
             (props.audioFile.file_type ?? "mp3") : "file.mp3";
           link.href = props.audioSrc!;
           document.body.appendChild(link);
           link.click();
           document.body.removeChild(link);
     };

  const timeUpdated = () => {
    if (audioRef.current?.currentTime)
      setCurrentTime(Math.floor(audioRef.current?.currentTime));
  }
  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if(!prevValue){
        audioRef.current?.play();
    }
    else {
        audioRef.current?.pause();
    }
  }
  return (
    <>
      <div
        className={`flex-col w-full px-3 border border-1 rounded-[6px] ${props.className}`}
      >
        <div className="mt-3">
          <text className="text-BodyMedium text-black truncate text-ellipsis overflow-hidden">
            {props.audioFile
              ? props.audioFile.file_name +
                "." +
                (props.audioFile.file_type ?? "mp3")
              : ""}
          </text>
        </div>

        <div className="w-full h-full flex gap-2 justify-between mt-2 items-center">
          <audio
            ref={audioRef}
            src={props.audioSrc ?? "/sample.mp3"}
            preload="metadata"
            onTimeUpdate={timeUpdated}
            onEnded={() => {
              setIsPlaying(false);
              setCurrentTime(0);
              if (audioRef.current?.currentTime) {
                audioRef.current.currentTime = 0;
              }
            }}
          />
          <text className=" text-BodySmall w-12 text-black truncate text-ellipsis overflow-hidden">
            {formatTime(currentTime)}
          </text>
          <div className="w-2/3">
            <Slider
              tooltip={{ formatter: null }}
              styles={{
                track: {
                  borderRadius: "5px",
                  height: "4px",
                  backgroundColor: "#ff0d00",
                  color: "#ff0d00",
                },
                rail: {
                  height: "4px",
                },
                handle: {
                  width: "14px", // Adjust width and height as needed
                  height: "14px",
                  borderRadius: "50%",
                  top: 3,
                  border: "2px solid #ff0d00", // 1px red border
                  backgroundColor: "#ffffff", // White background
                },
              }}
              defaultValue={0}
              min={0}
              max={duration}
              onChange={(value) => {
                setCurrentTime(value);
                if (audioRef.current?.currentTime) {
                  audioRef.current.currentTime = value;
                }
              }}
              value={currentTime}
            />
          </div>
          <text className="text-BodySmall w-12  text-black  truncate text-ellipsis overflow-hidden">
            {formatTime(duration)}
          </text>
          <div onClick={togglePlayPause}>
            {!isPlaying && <SvgRoundAudioPlayButton />}
            {isPlaying && <SvgRoundAudioPauseButton />}
          </div>
        </div>
        <div className="flex justify-end">
          <PrimaryButton
            onClick={() => {
              downloadFileToDesktop();
            }}
            icon={<SvgTextToSpeechDownload />}
            title={"Download"}
            className="h-[37px] w-[128px] mt-2 mb-2"
          />
        </div>
      </div>
    </>
  );
}

export default AudioPlayer;
