import { FunctionComponent, useEffect } from "react";
import AudienceSelector from "../../../components/AudienceSelector/AudienceSelector";
import { CSSObjectWithLabel, StylesConfig } from "react-select";
import PickerView, { PickerOptions } from "../../../components/PickerView";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { AudienceResponse } from "../../../services/AudiencetApi";
import { setNewContent } from "../../../store/contentSlice";
import { INewContent } from "../../../models/NewContent";
import { TonOfVoices } from "../../../constants/TonOfVoices";
import { ContentObjectives } from "../../../constants/ContentObjectives";
import { t } from "i18next";
import SvgTritoneApplyFiltersLogo from "../../../assets/IconComponents/TritoneApplyFiltersLogo";
import { useLazyFindById4Query } from "../../../services/BrandApi";
import { TooltipWrapper } from "react-tooltip";

interface ApplyFiltersProps {
    className?: string
    briefContent?: INewContent
    contentUpdated?: (content: INewContent) => void
    onAudienceEdit: (data: AudienceResponse) => void
    onAudienceCreate: () => void
    recentlyAddedAudience: AudienceResponse[]
    audiencePopupClassname?: string
    hideTitle?:boolean
}

const ApplyFilters: FunctionComponent<ApplyFiltersProps> = (props: ApplyFiltersProps) => {

    const dispatch = useAppDispatch();
    const newContent = useAppSelector((k) => k.content.newContent);
    const selectedBrandId = useAppSelector((k) => k.persisted.lastSelectedBrandId);
    const [getBrand, { data: brand, isLoading: brandLoading }] = useLazyFindById4Query()

    useEffect(() => {
        if (selectedBrandId) {
            getBrand({ id: selectedBrandId })
        }
    }, [selectedBrandId])

    useEffect(() => {
        if (!props.briefContent) {
            if (brand?.brand_tone && newContent && !newContent.tonOfVoice) {
                dispatch(
                    setNewContent({
                        ...newContent,
                        tonOfVoice: TonOfVoices.filter(t => t.key === brand.brand_tone),
                    } as INewContent)
                );
            }
        }
    }, [brand])

    const getPickerStyles = (smallItemms?: boolean, optionsOnSide?: boolean, isFontPicker?: boolean): StylesConfig<PickerOptions> => {
        return {
            multiValue: (styles) => ({
                ...styles,
                backgroundColor: "#FAFBF9",
                borderColor: "#EAEBE6",
                borderWidth: 1,
                maxHeight: 40,
                borderRadius: 7,
                padding: 6,
                margin: 0,
                gap: 10
            } as CSSObjectWithLabel),
            singleValue: (styles, { data }) => ({
                ...styles,
                fontFamily: isFontPicker ? data.title : ""
            } as CSSObjectWithLabel),
            valueContainer: (styles,) => ({
                ...styles,
                paddingBottom: 6,
                paddingTop: 6,
                paddingLeft: 6,
                paddingRight: 6,
                gap: 10,
            } as CSSObjectWithLabel),
            multiValueLabel: (styles) => ({
                ...styles,
                fontSize: 15,
                padding: 0,
            } as CSSObjectWithLabel),
            multiValueRemove: (styles) => ({
                ...styles,
                color: "black",
                padding: 10,
            } as CSSObjectWithLabel),
            indicatorSeparator: (styles) => ({ color: "white" }),
            control: (styles) => ({
                ...styles,
                backgroundColor: "white",
                borderColor: "#EAEBE6",
                borderWidth: 1,
                borderRadius: 4,
                minHeight: 40,
                fontSize: 15,
                border: "1px solid #EAEBE6",
                width: optionsOnSide ? "150px" : undefined,
                boxShadow: "none",
                "&:hover": {
                    border: "1px solid #EAEBE6",
                    boxShadow: "none",
                },
            } as CSSObjectWithLabel),
            menu: (styles) => ({
                ...styles,
                borderRadius: 12,
                overflow: "hidden",
                borderColor: "#EAEBE6",
                borderWidth: 1,
                padding: 5,
                paddingTop: 0,
                outline: "none",
                zIndex: 9,
            } as CSSObjectWithLabel),

            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                    ...styles,
                    padding: 10,
                    paddingLeft: smallItemms ? 5 : 10,
                    backgroundColor: isFocused
                        ? "#FAFBF9"
                        : isSelected
                            ? "#FAFBF9"
                            : "white",
                    borderWidth: isFocused ? 1 : isSelected ? 1 : 0,
                    borderColor: isSelected ? "#EAEBE6" : "",
                    fontSize: smallItemms ? 12 : 15,
                    borderRadius: 7,
                    height: 41,
                    color: "#121315",
                    fontFamily: isFontPicker ? data.title : "",
                    cursor: isDisabled ? "not-allowed" : "default",

                    ":active": {
                        ...styles[":active"],
                        backgroundColor: "#FAFBF9",
                    } as CSSObjectWithLabel,
                } as CSSObjectWithLabel;
            },
        };
    };

    return <div className={`self-stretch justify-start items-start gap-3 flex flex-row ${props.className}`}>
        {!props.hideTitle && <div className="w-[240px] h-7 justify-start items-center gap-2.5 flex shrink-0">
            <SvgTritoneApplyFiltersLogo/>
            <div className="text-black text-sm font-semibold font-['Inter Tight'] leading-tight">Apply Filters</div>
        </div>}

        <div className="flex flex-col w-full justify-center items-start gap-2">
            <AudienceSelector
                popupClassname={props.audiencePopupClassname}
                className="self-stretch flex h-[45px] p-[6px] bg-white rounded-[5px] border border-stone-200 justify-between items-center gap-2.5"
                selectDefaultFirst={false}
                selectedAudienceId={props.briefContent ? props.briefContent.audienceId : newContent?.audienceId}
                audienceSelected={function (data: AudienceResponse | undefined): void {
                    if (data) {
                        if (props.briefContent) {
                            if (props.contentUpdated) {
                                props.contentUpdated({ ...props.briefContent, audienceId: data?.audience_id })
                            }
                        } else {
                            dispatch(
                                setNewContent({
                                    ...newContent,
                                    audienceId: data?.audience_id,
                                } as INewContent)
                            );
                        }
                    }
                }}
                brandId={props.briefContent ? (props.briefContent.brand?.id ?? "") : (newContent?.brand?.id ?? "")}
                onCreateAudience={() => {
                    props.onAudienceCreate()
                }}
                onAudienceEdit={props.onAudienceEdit}
                audiences={props.recentlyAddedAudience}
                editingDisabled={props.briefContent !== undefined || props.hideTitle}
            />
            <PickerView
                className="self-stretch h-[45px] flex-1"
                title={""}
                items={TonOfVoices}
                selectedValue={props.briefContent ? props.briefContent.tonOfVoice?.map((k) => k.value) : newContent?.tonOfVoice?.map((k) => k.value)}
                maxSelectionCount={2}
                placeholder={"Set Tone of Voice"}
                info={""}
                onChange={(e) => {
                    if (e) {
                        if (props.briefContent) {
                            if (props.contentUpdated) {
                                props.contentUpdated({ ...props.briefContent, tonOfVoice: e })
                            }
                        } else {
                            dispatch(
                                setNewContent({
                                    ...newContent,
                                    tonOfVoice: e,
                                } as INewContent)
                            );
                        }
                    }
                }}
                getPickerStyles={getPickerStyles}
            />
            <PickerView
                className="self-stretch h-[45px] flex-1"
                placeholder="Select an Objective"
                items={ContentObjectives}
                selectedValue={props.briefContent ? props.briefContent.contentObjective?.map((k) => k.value) : newContent?.contentObjective?.map(
                    (k) => k.value
                )}
                onChange={(e) => {
                    if (e) {
                        if (props.briefContent) {
                            if (props.contentUpdated) {
                                props.contentUpdated({ ...props.briefContent, contentObjective: e })
                            }
                        } else {
                            dispatch(
                                setNewContent({
                                    ...newContent,
                                    contentObjective: e,
                                } as INewContent)
                            );
                        }
                    }
                }}
                getPickerStyles={getPickerStyles}
            />
        </div>
    </div>
}

export default ApplyFilters;
