import { t } from "i18next";
import { FunctionComponent, useState } from "react";
import SvgCloseLittle from "../../assets/IconComponents/CloseLittle";
import ContentContainer from "../ContentContainer";
import InputText from "../InputText";
import PrimaryButton from "../PrimaryButton";
import { Copy } from "../../assets/IconComponents";

interface DuplicateLoopPopProps {
    closePopup: () => void
    duplicateLoopWithName: (name: string | undefined) => void
}

const DuplicateLoopPop: FunctionComponent<DuplicateLoopPopProps> = (props: DuplicateLoopPopProps) => {
    const [name, setName] = useState<string | undefined>()

    return (
      <ContentContainer
        hoverable={false}
        className="w-[400px] h-[150px]  overflow-auto"
        children={
          <>
            {/*Body*/}
            <div className="p-[15px] pt-[10px] flex flex-col">
              <InputText
                title="Content Name"
                placeholder="Name Content (Optional)"
                onChange={(e) => {
                  setName(e);
                }}
              />
              <PrimaryButton
                icon={
                  <Copy
                    fill={"#ffffff"}
                    className="mr-2"
                  />
                }
                className="mt-2 h-[40px]"
                title={t("duplicate-on-new-tab") ?? ""}
                onClick={() => {
                  props.duplicateLoopWithName(name);
                }}
              />
            </div>
          </>
        }
      />
    );
}

export default DuplicateLoopPop;