import * as React from "react";
import type { SVGProps } from "react";
const SvgBardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <g clipPath="url(#BardIcon_svg__a)">
      <path
        fill="url(#BardIcon_svg__b)"
        d="m10.493 14.785-.878 2.01a.946.946 0 0 1-1.744 0l-.878-2.01a7.742 7.742 0 0 0-3.94-3.99L.637 9.721c-.768-.341-.768-1.458 0-1.8l2.34-1.038a7.747 7.747 0 0 0 3.998-4.125L7.864.617a.946.946 0 0 1 1.758 0l.89 2.142a7.747 7.747 0 0 0 3.996 4.125l2.34 1.039c.769.34.769 1.458 0 1.799l-2.415 1.072a7.742 7.742 0 0 0-3.94 3.991Z"
      />
      <path
        fill="url(#BardIcon_svg__c)"
        d="m18.649 21.124-.247.565a.507.507 0 0 1-.935 0l-.247-.565A4.365 4.365 0 0 0 15 18.873l-.761-.338a.53.53 0 0 1 0-.963l.718-.32a4.368 4.368 0 0 0 2.253-2.326l.253-.61a.507.507 0 0 1 .943 0l.253.61a4.368 4.368 0 0 0 2.254 2.327l.718.319a.53.53 0 0 1 0 .963l-.76.338a4.366 4.366 0 0 0-2.222 2.25Z"
      />
    </g>
    <defs>
      <radialGradient
        id="BardIcon_svg__b"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(78.858 .957 13.133) scale(13.5565 13.5631)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1BA1E3" />
        <stop offset={0} stopColor="#1BA1E3" />
        <stop offset={0.3} stopColor="#5489D6" />
        <stop offset={0.546} stopColor="#9B72CB" />
        <stop offset={0.825} stopColor="#D96570" />
        <stop offset={1} stopColor="#F49C46" />
      </radialGradient>
      <radialGradient
        id="BardIcon_svg__c"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(78.858 .957 13.133) scale(13.5565 13.5631)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1BA1E3" />
        <stop offset={0} stopColor="#1BA1E3" />
        <stop offset={0.3} stopColor="#5489D6" />
        <stop offset={0.546} stopColor="#9B72CB" />
        <stop offset={0.825} stopColor="#D96570" />
        <stop offset={1} stopColor="#F49C46" />
      </radialGradient>
      <clipPath id="BardIcon_svg__a">
        <path fill="#fff" d="M0 0h22v22H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBardIcon;
