import * as React from "react";
import type { SVGProps } from "react";
const SvgTargetUrlIcon = (props: SVGProps<SVGSVGElement>) => (


<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect opacity="0.1" width="28" height="28" rx="4" fill="#FF5555"/>
<path d="M12.5 18.25H10.25C9.25544 18.25 8.30161 17.8549 7.59835 17.1517C6.89509 16.4484 6.5 15.4946 6.5 14.5C6.5 13.5054 6.89509 12.5516 7.59835 11.8483C8.30161 11.1451 9.25544 10.75 10.25 10.75H12.5C12.6989 10.75 12.8897 10.829 13.0303 10.9697C13.171 11.1103 13.25 11.3011 13.25 11.5C13.25 11.6989 13.171 11.8897 13.0303 12.0303C12.8897 12.171 12.6989 12.25 12.5 12.25H10.25C9.65326 12.25 9.08097 12.4871 8.65901 12.909C8.23705 13.331 8 13.9033 8 14.5C8 15.0967 8.23705 15.669 8.65901 16.091C9.08097 16.5129 9.65326 16.75 10.25 16.75H12.5C12.6989 16.75 12.8897 16.829 13.0303 16.9697C13.171 17.1103 13.25 17.3011 13.25 17.5C13.25 17.6989 13.171 17.8897 13.0303 18.0303C12.8897 18.171 12.6989 18.25 12.5 18.25ZM17.75 18.25H15.5C15.3011 18.25 15.1103 18.171 14.9697 18.0303C14.829 17.8897 14.75 17.6989 14.75 17.5C14.75 17.3011 14.829 17.1103 14.9697 16.9697C15.1103 16.829 15.3011 16.75 15.5 16.75H17.75C18.3467 16.75 18.919 16.5129 19.341 16.091C19.7629 15.669 20 15.0967 20 14.5C20 13.9033 19.7629 13.331 19.341 12.909C18.919 12.4871 18.3467 12.25 17.75 12.25H15.5C15.3011 12.25 15.1103 12.171 14.9697 12.0303C14.829 11.8897 14.75 11.6989 14.75 11.5C14.75 11.3011 14.829 11.1103 14.9697 10.9697C15.1103 10.829 15.3011 10.75 15.5 10.75H17.75C18.7446 10.75 19.6984 11.1451 20.4017 11.8483C21.1049 12.5516 21.5 13.5054 21.5 14.5C21.5 15.4946 21.1049 16.4484 20.4017 17.1517C19.6984 17.8549 18.7446 18.25 17.75 18.25Z" fill="#FF5555"/>
<path opacity="0.5" d="M16.25 15.25H11.75C11.5511 15.25 11.3603 15.171 11.2197 15.0303C11.079 14.8897 11 14.6989 11 14.5C11 14.3011 11.079 14.1103 11.2197 13.9697C11.3603 13.829 11.5511 13.75 11.75 13.75H16.25C16.4489 13.75 16.6397 13.829 16.7803 13.9697C16.921 14.1103 17 14.3011 17 14.5C17 14.6989 16.921 14.8897 16.7803 15.0303C16.6397 15.171 16.4489 15.25 16.25 15.25Z" fill="#FF5555"/>
</svg>


);
export default SvgTargetUrlIcon;
