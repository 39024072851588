import * as React from "react";
const SvgNotificationSubscriptionRenewed = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={40} height={40} fill="#F55" opacity={0.1} rx={5} />
    <g fill="#F55" clipPath="url(#a)">
      <path
        d="M20 11c.7 0 1.376.255 1.902.717l.138.13.629.63c.172.17.397.279.639.306l.121.007h.9a2.88 2.88 0 0 1 2.876 2.717l.005.164v.9c0 .243.082.48.232.668l.08.09.628.629a2.88 2.88 0 0 1 .133 3.944l-.13.139-.63.628a1.08 1.08 0 0 0-.306.639l-.007.122v.9a2.88 2.88 0 0 1-2.717 2.876l-.164.004h-.9a1.08 1.08 0 0 0-.668.232l-.09.081-.629.628a2.88 2.88 0 0 1-3.944.132l-.139-.13-.628-.629a1.08 1.08 0 0 0-.639-.307l-.122-.007h-.9a2.88 2.88 0 0 1-2.876-2.716l-.004-.164v-.9a1.08 1.08 0 0 0-.232-.67l-.081-.09-.628-.628a2.88 2.88 0 0 1-.132-3.944l.13-.138.629-.629a1.08 1.08 0 0 0 .307-.639l.007-.121v-.9l.005-.164a2.88 2.88 0 0 1 2.712-2.712l.163-.005h.9c.243 0 .479-.082.67-.232l.09-.08.628-.628A2.881 2.881 0 0 1 20 11Z"
        opacity={0.3}
      />
      <path
        d="M18.734 23.071a.748.748 0 0 1-.53-.22l-2.11-2.11a.75.75 0 0 1 1.06-1.06l1.58 1.58 4.11-4.11a.75.75 0 0 1 1.06 1.06l-4.64 4.64a.748.748 0 0 1-.53.22Z"
        opacity={0.99}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M11 11h18v18H11z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNotificationSubscriptionRenewed;
