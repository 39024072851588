import { PickerModel } from "../models/PickerModel";

export const GenderGroups: PickerModel[] = [
    {
        title: "All",
        value: "0"
    },
    {
        title: "Male",
        value: "1"
    },
    {
        title: "Female",
        value: "2"
    },
]