import * as React from "react";
import type { SVGProps } from "react";
const SvgCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
    {...props}
  >
    <g clipPath="url(#CheckIcon_svg__a)">
      <path
        fill="#000"
        d="M15.465 4.66a.833.833 0 0 0-.568.264L8.32 11.8 6.335 9.535a.833.833 0 1 0-1.255 1.097l2.082 2.379-.816.853L2.364 9.52a.834.834 0 1 0-1.229 1.127l4.583 5a.833.833 0 0 0 1.217.012l1.33-1.389 1.19 1.362a.835.835 0 0 0 1.23.027l9.167-9.583a.833.833 0 1 0-1.205-1.152l-8.536 8.925-.69-.788 6.68-6.985a.833.833 0 0 0-.636-1.417Z"
      />
    </g>
    <defs>
      <clipPath id="CheckIcon_svg__a">
        <path fill="#fff" d="M.5.5h20v20H.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCheckIcon;
