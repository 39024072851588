import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { ContentRequest, useSave4Mutation } from "../../../services/ContentApi";
import { useEffect, useState } from "react";
import { ContentType } from "../../../constants/ContentType";
import { toggleContentWizard, toggleDialog } from "../../../store/appSlice";
import { setNewContent } from "../../../store/contentSlice";
import { TonOfVoices } from "../../../constants/TonOfVoices";
import { instanceOfApiErrorWithCode } from "../../../services/Core/BaseApi";
import { PaymentErrorCode } from "../../../services/Error/PaymentErrorCode";
import ConfirmationPopupOpener from "../../../components/ConfirmationPopup/ConfirmationPopup";
import PlanEditPopupOpener from "../../PlanPage/PlanEditPopup";

function ControlButtons() {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const newContent = useAppSelector((k) => k.content.newContent);
    const [showPlanUpdateSuccessPopup, setShowPlanUpdateSuccessPopup] = useState(false);
    const [showUpgradePlanDialog, setShowUpgradePlanDialog] = useState(false);
    const [isUpgradePlanPopupOpen, setIsUpgradePlanPopupOpen] = useState(false);
    const userSettings = useAppSelector(state => state.userSettings);
    const [effectiveAt, setEffectiveAt] = useState("");

    const [
        createContent,
        {
            isLoading: isContentCreationLoading,
            isSuccess: contentCreationSuccess,
            data: contentCreationData,
            error: createContentError
        },
    ] = useSave4Mutation();


    useEffect(() => {
        if (contentCreationSuccess) {
            //dispatch(updateMenu())
            dispatch(toggleContentWizard());
            navigate(
                "/app/loopdetails/" + contentCreationData?.id?.content_id ?? "",
                { state: { newLoopCreated: true, isCreative: true } }
            );
        }
    }, [contentCreationSuccess]);


    useEffect(() => {
        if (createContentError && instanceOfApiErrorWithCode(createContentError)) {
            const errorData = createContentError.data;
            if (errorData.errorCode === PaymentErrorCode.CreditLimitExceeded) {
                setShowUpgradePlanDialog(true);
            } else {
                console.log(errorData.message);
            }
        }
    }, [createContentError]);


    const getTonOfVoices = () => {
        const values = newContent?.tonOfVoice?.map((k) => k.value);
        const filteredVoices = TonOfVoices.filter(
            (k) => values?.includes(k.value)!
        );

        return filteredVoices.map((k) => k.key).join(" & ");
    };


    const getFilters = () => {
        var payload = {
            age_group: newContent?.ageGroup?.map((k) => k.title).join(" & ") ?? "",
            bot_type: "marketing",
            gender_group:
                newContent?.genderGroup?.map((k) => k.title).join(" & ") ?? "",
            education_level:
                newContent?.education?.map((k) => k.title).join(" & ") ?? "",
            objective:
                newContent?.contentObjective?.map((k) => k.title).join(" & ") ?? "",
            tone_of_voice: getTonOfVoices(),
            message: newContent?.contentAbout ?? "",
            audience_id: newContent?.audienceId,
        };
        if (newContent?.contentType === ContentType.image) {
            payload["purpose"] = "Background";
            payload["bot_type"] = "image-gen-prompt";
        }

        return payload;
    };

    const createLoop = () => {
        var payload = {
            name: newContent?.loopName ?? "",
            type: ContentType[newContent?.contentType ?? ContentType.text],
            platform: `${newContent?.medium?.name}`,
            image_width: newContent?.imageWidth,
            image_height: newContent?.imageHeight,
            logo_file_id:
                newContent?.logoFileId !== undefined && newContent?.logoFileId !== ""
                    ? newContent.logoFileId
                    : undefined,
            product_file_id:
                newContent?.productFileId !== undefined &&
                    newContent?.productFileId !== ""
                    ? newContent.productFileId
                    : undefined,
            filters: getFilters(),
            brand_id: newContent?.brand?.id,
            cta: newContent?.cta,
            ad_id: newContent?.adId,
            creative_image_id: newContent?.creativeImageId,
            creative_theme: newContent?.creative_theme,
            creative_topic: newContent?.contentAbout,
            creative_referred_image_id: newContent?.creative_referred_image_id,
            creative_is_adapted: newContent?.isCreativeAdapted
        } as ContentRequest;


        if (newContent?.contentType === ContentType.text) {
            if (newContent?.mediumOption?.description == "Words") {
                payload.words_limit = Number(newContent?.mediumOption?.value) ?? 100
            } else if (newContent?.mediumOption?.description == "Sentences") {
                payload.sentences_limit = Number(newContent?.mediumOption?.value) ?? 100
            }
        }

        createContent({
            contentRequest: payload,
        });

    };

    return <>
        {showPlanUpdateSuccessPopup && (
            <ConfirmationPopupOpener
                heading={"Your Plan Has Been Updated Successfully!"}
                subHeading={effectiveAt == "IMMEDIATELY" ? "Your plan has been successfully updated. Adjusted features are now in effect." : "The current plan continues until the end of your billing cycle. Your new plan will start in the next billing cycle."}
                buttonText={"Continue"}
                onClose={() => {
                    setShowPlanUpdateSuccessPopup(false);
                    dispatch(toggleDialog(false));
                }}
            />
        )}
        {isUpgradePlanPopupOpen &&
            (
                <PlanEditPopupOpener
                    onClosed={() => {
                        setIsUpgradePlanPopupOpen(false);
                        dispatch(toggleDialog(false));
                    }}
                    currentPlanId={userSettings?.subscription?.plan_id}
                    paymentMethod={userSettings?.payment_method}
                    onPlanUpdateSucceeded={(effectiveAt?: string) => {
                        setEffectiveAt(effectiveAt ?? "");
                        setIsUpgradePlanPopupOpen(false);
                        dispatch(toggleDialog(false));
                        setShowPlanUpdateSuccessPopup(true);
                    }}
                    onUpdatePaymentMethod={() => {
                        setIsUpgradePlanPopupOpen(false);
                        dispatch(toggleDialog(false));
                    }}
                />
            )}
        {showUpgradePlanDialog &&
            (
                <ConfirmationPopupOpener
                    heading={"Need More Credits?"}
                    subHeading={"Upgrade your plan for more credits and never hit a roadblock in your workflow.."}
                    buttonText={"View Plans"}
                    onClose={function (): void {
                        setShowUpgradePlanDialog(false);
                        dispatch(toggleDialog(false));
                        setIsUpgradePlanPopupOpen(true);
                    }}
                />
            )}
        <div className="w-full h-[85px] py-[15px] bg-white shadow justify-between items-center shrink-0 flex">
            <div className="w-[52px] self-stretch px-2.5 py-2 opacity-0 bg-white rounded-[5px] border border-stone-200 justify-center items-center gap-2.5 flex">
                <div className="text-neutral-900 text-sm font-semibold font-['Inter Tight'] leading-tight">Back</div>
            </div>
            <div className="w-[200px] self-stretch justify-start items-center gap-2.5 flex">
                <PrimaryButton
                    title={"Cancel"}
                    noFill
                    className="w-[72px] h-11 mr-2"
                    onClick={function (): void {
                        dispatch(setNewContent(undefined));
                        dispatch(toggleContentWizard());
                    }}
                />
                <PrimaryButton
                    title={"Get Started"}
                    className="w-[101px] h-11 mr-2"
                    onClick={function (): void {
                        createLoop();
                    }}
                    isDisabled={newContent?.adId === undefined && newContent?.creativeImageId === undefined && newContent?.creative_theme === undefined}
                />
            </div>
        </div>
    </>


}

export default ControlButtons;
