import SvgCloseLittle from "../assets/IconComponents/CloseLittle";

export interface ITagProps {
    className?: string
    tagText?: string
    onClose?: () => void
}

function Tag(props: ITagProps) {

    return (
        <div
            className={`p-1.5 bg-stone-50 rounded border border-stone-200 justify-start items-center gap-2.5 inline-flex ${props.className ?? ""}`}>
            <p className="text-BodyMedium text-black">{props.tagText}</p>
            <button
                className="rounded-[7px] w-[18px] h-[18px] bg-bg border-1 border-border"
                onClick={(e) => {
                    e.stopPropagation()
                    props.onClose && props.onClose()
                }}
            >
                <SvgCloseLittle fill="#000" viewBox="0 0 20 20" className="mt-[3px] ml-[3px]" />
            </button>
        </div>
    )
}


export default Tag;