import ContentContainer from "../ContentContainer";
import ChannelSelectorItem from "./ChannelSelectorItem";
import { useEffect, useState } from "react";
import { IChannelTypeData } from "./ChannelSelector";

export interface IChannelSelectorPopupProps {
    data: IChannelTypeData[]
    closePopup: () => void
    selectedChannels: IChannelTypeData[] | undefined
    selectedChannelsChanged: (data: IChannelTypeData[] | undefined) => void
    deselectable?: boolean
}

function ChannelSelectorPopup(props: IChannelSelectorPopupProps) {
    const [selectedItems, setSelectedItems] = useState<IChannelTypeData[]>()

    useEffect(() => {
        if (props.selectedChannels) {
            setSelectedItems(props.selectedChannels)
        }
    }, [props.selectedChannels])


    return (
        <ContentContainer
            hoverable={false}
            className="w-[250px] h-[200px]  overflow-hidden"
            children={
                <>
                    {/*Body*/}
                    <div className="p-[12px] h-full overflow-scroll scrollbar-hide">
                        {
                            props.data.map(k => (
                                <ChannelSelectorItem
                                    key={`channel_sellector_item_${k.id}`}
                                    data={k}
                                    selected={!!(selectedItems?.find((items) => items.id === k.id))}
                                    itemSelected={(e, isSelected) => {
                                        if (isSelected) {
                                            if (selectedItems) {
                                                const newSelectedItems = [...selectedItems, e]
                                                setSelectedItems(newSelectedItems)
                                                props.selectedChannelsChanged(newSelectedItems)
                                            } else {
                                                setSelectedItems([e])
                                                props.selectedChannelsChanged([e])
                                            }
                                        } else {
                                            if (selectedItems) {
                                                const newSelectedItems = selectedItems.filter((a) => a.id !== e.id)
                                                setSelectedItems([...newSelectedItems])
                                                props.selectedChannelsChanged([...newSelectedItems])
                                            } else {
                                                console.log(`can not remove from empty array`)
                                            }
                                        }

                                    }} />
                            ))
                        }
                    </div>
                </>
            } />
    );
}

export default ChannelSelectorPopup;
