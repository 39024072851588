import ContentImage from "../../components/ContentImage"
import { Utils } from "../../helpers/Utils"
import { useGenerateContentFromRevisionMutation } from "../../services/ContentApi"
import { useDispatch } from "react-redux"
import { toggleLoading, toggleContentWizard } from "../../store/appSlice"
import { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { setNewContent } from "../../store/contentSlice"
import { useAppSelector } from "../../helpers/hooks"
import { ContentType } from "../../constants/ContentType"
import { ContentWizardView } from "../../models/ContentWizardView"
import moment from "moment"
import { INewContent } from "../../models/NewContent"
import { Popover } from "react-tiny-popover";
import { SearchApiResponse, useLazySearchQuery } from "../../services/InspirationApi";
import BackgroundImage from '../../assets/search-bar-placeholder.png'
import { Input } from 'antd';

export interface ISearchPopoverContentsProps {
    inspirations?: SearchApiResponse
}

const SearchInspirations = (props: ISearchPopoverContentsProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const newContent = useAppSelector((k) => k.content.newContent);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const [queryResult, setQueryResult] = useState<SearchApiResponse>([]);
    const inputRef = useRef<HTMLInputElement>(null);
    const [
        searchInspirations,
        {
            data: inspirationsSearchResult,
        },
    ] = useLazySearchQuery();

    const handleSearchBar = (query) => {
        setIsPopoverOpen(true)
        if (query === "") {
            setIsPopoverOpen(false)
        }
        searchInspirations({
            returnSize: 5,
            searchTerm: query,
        })
    }

    useEffect(() => {
        if (inspirationsSearchResult && inspirationsSearchResult.length > 0) {
            setQueryResult(inspirationsSearchResult);
        }
    }, [inspirationsSearchResult]);

    const lastSelectedBrandId = useAppSelector(
        (k) => k.persisted.lastSelectedBrandId
    );

    const [
        generateContentFromRevision,
        {
            isLoading: generatingContentFromRevision,
        },
    ] = useGenerateContentFromRevisionMutation();

    const handleSearchClick = (type, revisionId, inspiration) => {
        if (type === "creative") {
            dispatch(
                setNewContent({
                    ...newContent,
                    adId: inspiration.adId,
                    contentType: ContentType.image,
                    wizardView: ContentWizardView.contentDetails,
                    loopName: `Loop_${moment().format(
                        "DD_MM_yyyy_hh_mm"
                    )}`,
                    brand: { id: lastSelectedBrandId },
                    isCreative: true,
                } as INewContent)
            );
            dispatch(toggleContentWizard());
        }
        else {
            generateContentFromRevision({ revisionId: revisionId })
                .unwrap()
                .then((k) => {
                    const contentId = k.content_id ?? "";
                    navigate(`/app/loopdetails/` + contentId);
                });
        }
    }

    useEffect(() => {
        dispatch(toggleLoading(generatingContentFromRevision));
    }, [dispatch, generatingContentFromRevision]);

    return (
        <div className="w-[1050px] h-[163px] bg-gray my-6 rounded-[10px] p-8 pt-[80px]" style={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: "contain" }}>
            <Popover
                isOpen={isPopoverOpen}
                onClickOutside={() => {
                    setIsPopoverOpen(false)
                }}
                padding={10}
                align="start"
                positions={['bottom', 'right', 'top', 'left']}
                content={
                    <div className="flex flex-col w-[500px] p-[12px] items-start justify-center gap-[20px] rounded bg-white border-solid border-1 border-gray-300">
                        <div className="flex justify-between items-start self-stretch">
                            <span className="text-bodySmall text-gray">
                                Templates
                            </span>
                            <span className="text-bodySmall text-red underline cursor-pointer" onClick={() => handleSearchBar(inputRef?.current?.value)}>
                                View More
                            </span>
                        </div>

                        <div className="flex gap-[7px]">
                            {queryResult?.map((i) =>
                                <div key={i.adId} className="flex flex-col justify-center items-center gap-[5px] flex-1" onClick={() => handleSearchClick(i.type ?? "", i.revisionId ?? "", i)}>
                                    <ContentImage
                                        id={i?.fileId ?? ""}
                                        className="py-1 h-[100px] bg-bg w-full rounded-[8px] object-contain"
                                    />
                                    <span className="text-H6 text-black font-normal">
                                        {Utils.formatPlatformName(i.platform ?? "") === ""
                                            ? "Creative"
                                            : Utils.formatPlatformName(i.platform ?? "")}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                }
            >
                <div className="w-[500px] flex  justify-between items-center rounded bg-white relative">
                    <Input.Search size="large"
                        placeholder={"Search what do you want to create"}
                        className={` ant-input-searh focus:outline-none`}
                        onChange={(e) => handleSearchBar(e.target.value)}
                        onSearch={(value, _e, info) => handleSearchBar(value)} />
                </div>

            </Popover>
        </div>

    )
}

export default SearchInspirations
