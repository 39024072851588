import {t} from "i18next";
import ContentContainer from "../../../components/ContentContainer";
import {useEffect, useState} from "react";
import PrimaryButton from "../../../components/PrimaryButton";
import {useAppDispatch} from "../../../helpers/hooks";
import {setEvent, setPlan} from "../../../store/plannerSlice";
import {IGenericSelectorData} from "../../../components/GenericSelector/GenericSelector";
import RadioSelectorItem from "../../../components/RadioSelector/RadioSelectorItem";
import WarningMessage from "../../../components/WarningMessage";
import {Button, Input} from "antd";
import { testReg } from "../../../helpers/Utils";

const ProductAbout = (props) => {
    const dispatch = useAppDispatch()
    const [processData, setProcessData] = useState({
        productAbout: props[props.type === 'event' ? 'event' : 'plan']?.productAbout,
    })

    const [selectedItem, setSelectedItem] = useState<
        IGenericSelectorData | undefined>();
    const [enteredURL, setEnteredURL] = useState(props[props.type === 'event' ? 'event' : 'plan']?.productAboutUrl?.replace('https://', '') ?? 'https://')
    const [showTest, setShowTest] = useState(false)
    const [error, setError] = useState("");


    const productAbouts = [
        { id: "WEB_SITE", title: "It’s a website", placeHolder: 'Website URL', description: 'Drive traffic and convert sales on your website.' },
        { id: "MOBILE_APP", title: "It’s a mobile application", placeHolder: 'App Download URL', description: 'Boost your app visibility, gain users and reviews.' },
        { id: "PHYSICAL_PRODUCT", title: "It’s a physical product", placeHolder: 'Product URL', description: 'Provide the link to your product page or e-commerce listing' },
        { id: "OTHER", title: "Something else", placeHolder: 'Target URL', description: 'Share the relevant URL to your unique product (e.g. event, social media) to help us promote.' },
    ];

    useEffect(() => {
        productAbouts.forEach(item => {
            if (props[props.type === 'event' ? 'event' : 'plan']?.productAbout === item.id) {
                setSelectedItem(item);
            }
        })
    }, []);


    const handleForm = (field, value) => {
        setProcessData(prevState => ({...prevState, [field]: value}))
    }

    return (
        <div className={'w-full'}>
            <div className={'mb-7 ml-2'}>
                <div className={'text-H4'}>
                    <h2>What’s your product about?</h2>
                </div>
                <div className={'text-BodySmall mt-2'}>
                    Tell us where your product lives online or offline – website, app, physical, or something else!
                </div>
            </div>
            <WarningMessage
                title={error}
                show={error != ""}
                className="w-3/4 my-5"
                onClose={() => {
                    setError("");
                }}
            />
            <div className={'w-full flex justify-between'}>
                <div className={'w-[55%]'}>
                    <ContentContainer
                    ref={props.ref}
                    className={`w-full`}
                    hoverable={false}
                    children={
                        <>
                            <div className="flex flex-row justify-between w-full p-[20px]">
                                <div className="text-H8 text-black mb-2">
                                    Please choose one
                                </div>
                                <div className={'max-w-[55%]'}>
                                    <div>
                                        {productAbouts.map((k) => (
                                            <div className={'border-1 rounded-[8px] mb-2 p-2 bg-white'}>
                                                <RadioSelectorItem
                                                    data={k}
                                                    selected={selectedItem?.id === k.id}
                                                    itemSelected={(e) => {
                                                        if (e.id !== selectedItem?.id) {
                                                            console.log(e)
                                                            setSelectedItem(e);
                                                            setEnteredURL("")
                                                        }
                                                    }}
                                                />
                                                <div className={'ml-6 text-gray text-sm'}>{k.description}</div>
                                                {selectedItem?.id === k.id ? (
                                                    <div className={'ml-3'}>
                                                        <div
                                                            className={`flex flex-col bg-white w-full h-[50px] `}
                                                        >
                                                            <div
                                                                className="flex w-full pl-3 flex-col md:flex-row justify-between">
                                                                <Input
                                                                    className={'w-full'}
                                                                    addonBefore="https://"
                                                                    placeholder={k.placeHolder}
                                                                    size="large"
                                                                    onChange={(e) => {
                                                                        setEnteredURL(`${e.target.value}`)
                                                                        setShowTest(false);
                                                                    }}
                                                                    value={enteredURL}
                                                                />
                                                                <Button
                                                                    danger
                                                                    size="large"
                                                                    type="text"
                                                                    onClick={() => {
                                                                        if (testReg.test(`https://${enteredURL}`)) {
                                                                            setShowTest(true);
                                                                        } else {
                                                                            setError("Please enter a valid URL!");
                                                                            setTimeout(() => {
                                                                                setError("");
                                                                            }, 3000);
                                                                        }
                                                                    }}>
                                                                    Test Url
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ''}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                />
                </div>
                <div className={'w-[45%]'}>
                    {showTest ?
                        (<iframe className={'w-10/12 h-2/3 mx-auto '} src={`https://${enteredURL}`}></iframe>) : ''
                    }
                </div>
            </div>

            <div className="flex justify-between mt-3 w-[55%]">
                <PrimaryButton
                    className="h-10 w-full lg:w-[140px]"
                    noFill
                    title={"Back"}
                    onClick={() => {
                        props.onCancel();
                    }}
                />
                <PrimaryButton
                    isDisabled={!testReg.test(`https://${enteredURL}`)}
                    className="h-10 w-full lg:w-[140px] lg:ml-2 max-lg:mt-1"
                    title={t("continue")}
                    onClick={() => {
                        props.onComplate();
                        if (props.type === 'event') {
                            dispatch(setEvent({
                                ...props.event,
                                productAbout: selectedItem?.id,
                                productAboutUrl: `https://${enteredURL}`,
                            }))
                        } else {
                            dispatch(setPlan({
                                ...props.plan,
                                productAbout: selectedItem?.id,
                                productAboutUrl: `https://${enteredURL}`,
                            }))
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default ProductAbout
