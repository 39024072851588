
import * as React from "react";
const SvgNotificationLaunchEventsCreating = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect width={40} height={40} fill="#F55" opacity={0.1} rx={5} />
    <path
      fill="#F55"
      d="M22.333 11.25h-4.666c-.7 0-1.167-.467-1.167-1.167s.467-1.167 1.167-1.167h4.666c.7 0 1.167.467 1.167 1.167s-.467 1.166-1.167 1.166Z"
    />
    <path
      fill="#F55"
      d="M20 12.416c-5.133 0-9.333 4.2-9.333 9.333 0 5.134 4.2 9.334 9.333 9.334s9.333-4.2 9.333-9.334c0-5.133-4.2-9.333-9.333-9.333Z"
      opacity={0.3}
    />
    <path
      fill="#F55"
      d="M20.178 21.809c0-1.115.197-1.939.592-2.47.398-.532 1.017-.798 1.855-.798.832 0 1.446.266 1.841.797.395.532.593 1.356.593 2.47 0 1.122-.198 1.948-.593 2.48-.395.531-1.009.797-1.841.797-.838 0-1.457-.266-1.855-.797-.395-.532-.592-1.358-.592-2.48Zm1.066 0c0 .817.11 1.41.328 1.781.219.368.57.552 1.053.552.48 0 .828-.184 1.044-.552.218-.367.328-.961.328-1.781 0-.815-.11-1.407-.328-1.778-.216-.37-.564-.556-1.044-.556-.48 0-.83.187-1.053.56-.218.372-.328.962-.328 1.774Z"
    />
    <path
      fill="#F55"
      fillRule="evenodd"
      d="M20.537 19.164c.467-.624 1.184-.914 2.088-.914.898 0 1.611.29 2.075.915.45.606.65 1.505.65 2.644 0 1.145-.2 2.047-.65 2.653-.464.625-1.177.916-2.075.916-.904 0-1.621-.29-2.088-.915-.451-.607-.65-1.51-.65-2.654 0-1.139.199-2.038.65-2.644Zm2.881 4.278c.177-.297.287-.825.287-1.633 0-.8-.11-1.328-.287-1.629l-.001-.001c-.157-.27-.4-.411-.792-.411-.39 0-.639.143-.802.417-.177.301-.287.827-.287 1.624 0 .804.11 1.332.287 1.633.159.267.406.409.802.409.392 0 .636-.142.792-.407l.001-.002Zm-1.846-3.405c.222-.374.573-.561 1.053-.561s.828.185 1.044.556c.218.37.328.963.328 1.777 0 .82-.11 1.415-.328 1.782-.216.368-.564.552-1.044.552-.483 0-.834-.184-1.053-.552-.218-.37-.328-.965-.328-1.782 0-.811.11-1.402.328-1.773Zm-.802-.698c-.395.532-.592 1.355-.592 2.47 0 1.121.197 1.948.592 2.48.398.531 1.017.797 1.855.797.832 0 1.446-.266 1.841-.798.395-.531.593-1.358.593-2.479 0-1.115-.198-1.938-.593-2.47-.395-.531-1.009-.797-1.841-.797-.838 0-1.457.265-1.855.797Z"
      clipRule="evenodd"
    />
    <path
      fill="#F55"
      d="M14.81 23.76c0-.134.048-.25.145-.347.097-.1.213-.15.346-.15.152 0 .301.082.447.246.18.207.366.365.56.474.198.106.438.16.72.16.408 0 .715-.096.921-.288.207-.19.31-.457.31-.797 0-.747-.37-1.121-1.107-1.121h-.58a.498.498 0 0 1-.36-.141.458.458 0 0 1-.145-.342c0-.116.041-.225.123-.328l1.313-1.5H15.63a.46.46 0 0 1-.338-.141.46.46 0 0 1-.141-.337c0-.13.047-.242.141-.333a.46.46 0 0 1 .338-.141h2.984a.46.46 0 0 1 .338.141.445.445 0 0 1 .141.333.473.473 0 0 1-.123.319l-1.363 1.54c.32.037.593.117.82.242.232.124.41.282.534.474.128.191.22.396.278.615.058.219.087.459.087.72 0 .647-.195 1.145-.584 1.495-.389.35-.96.524-1.713.524-.462 0-.881-.097-1.258-.292a2.4 2.4 0 0 1-.88-.747.49.49 0 0 1-.082-.278Z"
    />
    <path
      fill="#F55"
      fillRule="evenodd"
      d="M15.748 23.51c-.146-.164-.295-.246-.447-.246-.133 0-.249.05-.346.15a.473.473 0 0 0-.146.346.49.49 0 0 0 .082.278c.213.304.506.553.88.748a2.7 2.7 0 0 0 1.258.291c.753 0 1.324-.174 1.713-.524.39-.349.584-.847.584-1.494 0-.262-.03-.502-.087-.72a2.041 2.041 0 0 0-.278-.616 1.427 1.427 0 0 0-.533-.474 1.974 1.974 0 0 0-.483-.182 2.754 2.754 0 0 0-.338-.06l1.363-1.54a.473.473 0 0 0 .123-.319.445.445 0 0 0-.141-.332.46.46 0 0 0-.337-.142H15.63a.46.46 0 0 0-.338.142.445.445 0 0 0-.141.332c0 .131.047.244.141.338a.46.46 0 0 0 .338.141h1.873l-1.313 1.5a.52.52 0 0 0-.123.327c0 .134.049.248.146.342a.497.497 0 0 0 .36.142h.579c.738 0 1.107.373 1.107 1.12 0 .34-.103.607-.31.798-.206.191-.513.287-.92.287a1.5 1.5 0 0 1-.72-.16 2.147 2.147 0 0 1-.561-.473Zm.701.218a1.862 1.862 0 0 1-.48-.41l-.003-.002c-.176-.198-.398-.344-.665-.344a.758.758 0 0 0-.554.237.764.764 0 0 0-.23.551c0 .158.043.308.132.44l.003.006c.243.347.575.625.984.838.422.218.889.325 1.393.325.794 0 1.446-.184 1.908-.599.465-.417.68-1.003.68-1.711 0-.283-.031-.548-.096-.795a2.333 2.333 0 0 0-.317-.701 1.72 1.72 0 0 0-.637-.57 2.172 2.172 0 0 0-.407-.17l1.029-1.162.001-.002a.764.764 0 0 0 .195-.51.737.737 0 0 0-.229-.541.751.751 0 0 0-.541-.225H15.63a.751.751 0 0 0-.542.225.737.737 0 0 0-.229.54c0 .21.08.396.227.544a.752.752 0 0 0 .544.227h1.23l-.89 1.015-.008.01a.81.81 0 0 0-.187.51.75.75 0 0 0 .235.552c.155.15.349.223.563.223h.579c.323 0 .512.082.623.194.111.113.192.306.192.636 0 .277-.081.458-.216.583-.133.123-.356.21-.722.21-.246 0-.435-.046-.58-.124Z"
      clipRule="evenodd"
    />
    <path
      fill="#F55"
      d="M12.65 16.033a10.816 10.816 0 0 1 1.633-1.633l-1.05-1.05a1.128 1.128 0 0 0-1.633 0 1.128 1.128 0 0 0 0 1.633l1.05 1.05Zm14.7 0 1.05-1.05a1.128 1.128 0 0 0 0-1.633 1.128 1.128 0 0 0-1.633 0l-1.05 1.05c.583.467 1.166 1.05 1.633 1.633Z"
    />
  </svg>
);
export default SvgNotificationLaunchEventsCreating;
