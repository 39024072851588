import {useEffect} from "react";
import {setDialog, toggleDialog} from "../../store/appSlice";
import {useAppDispatch} from "../../helpers/hooks";
import {t} from "i18next";
import PrimaryButton from "../PrimaryButton";
import {useRef} from "react";
import {clickOutside} from "../../helpers/ClickOutside";
import CampaignCard from "./CampaignCard";
import SvgForYouLightBulb from "../../assets/IconComponents/ForYouLightBulb";
import {useNavigate} from "react-router-dom";
import Rocket from "../../assets/IconComponents/Rocket";
import Event from "../../assets/IconComponents/Event";
import SoloCampaign from "../../assets/IconComponents/SoloCampaign";

export interface IMarketingPlannerModalProps {
    open: boolean
    close: () => void
    title?: string
    submit?: () => void
    disabledClickOutside?: boolean
    hideFooter?: boolean
    customSubmitText?: string
    additionButtonText?: string
    additionButtonClick?: () => void
}

function MarketingPlannerModalOpener(props: IMarketingPlannerModalProps) {
    const dispatch = useAppDispatch();
    useEffect(() => {
        showSubscriptionDialog();
    }, [props.open])

    const showSubscriptionDialog = () => {
        dispatch(setDialog({
            actions: <></>,
            title: "",
            message: "",
            customTemplate: (
                <DefaultModal {...props}></DefaultModal>
            ),
        }));
        dispatch(toggleDialog(props.open));
    }

    return (<></>)
}

function DefaultModal(props: IMarketingPlannerModalProps) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const ref = useRef<HTMLDivElement>(null);

    const cards = [
        {
            title: "Event",
            icon: Event,
            onClick: () => {
                navigate("/app/marketing-planner/new?type=event");
                props.close()
            },
            description: 'Create multiple campaigns for an upcoming company event.'
        },
        {
            title: "Solo-campaign",
            icon: SoloCampaign,
            onClick: () => {
                navigate("/app/marketing-planner/new?type=campaign");
                props.close()
            },
            description: 'Create a campaign for multiple channels.'
        },
    ]


    useEffect(() => {
        if (!props.disabledClickOutside && ref.current) {
            return clickOutside(ref.current, () => {
                props.close();
            });
        }
    }, [ref.current]);


    return (
        <>
            <div
                className="fixed lg:top-0 left-0 right-0 bottom-0 z-50 flex flex-col items-center max-lg:justify-end max-lg:mb-1 justify-center h-screen" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                <div
                    ref={ref}
                    className="mx-2 md:mx-0 flex flex-col justify-center bg-white p-5 rounded-[10px]"
                >
                    <>
                        {props.title && (<>
                            <div className="h-7 items-center flex justify-between">
                                <span className="text-black text-H6 mb-3">
                                    {props.title}
                                </span>
                            </div>
                            <hr className="bg-border -ml-5 -mr-5"/>
                        </>)}
                        <div className="w-full mt-[20px] flex-col">
                            <div className="w-full">
                                <div className={'flex justify-center'}>
                                    {cards.map(card => (
                                        <CampaignCard onClick={card.onClick} title={card.title} key={card.title}
                                                      icon={card.icon} description={card.description}/>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {(!props.hideFooter) && (
                            <>
                                <hr className="bg-border -ml-5 -mr-5 my-5"/>
                                <div className={'flex justify-between'}>
                                    <div>
                                        {props.additionButtonText && (
                                            <PrimaryButton
                                                className="h-12 w-20 text-red"
                                                noFill
                                                // title={t('delete')}
                                                title={props.additionButtonText}
                                                onClick={() => props.additionButtonClick ? props.additionButtonClick() : null}/>
                                        )}
                                    </div>
                                    <div className="flex-col lg:flex lg:flex-row lg:justify-end">
                                        <PrimaryButton
                                            className="h-12 w-full lg:ml-1 lg:w-[100px]"
                                            noFill
                                            title={t("cancel")}
                                            onClick={props.close}
                                        />
                                        {props.submit ? (
                                            <PrimaryButton
                                                className="h-12 w-full lg:ml-1 lg:w-[100px]"
                                                isDisabled={false}
                                                title={props.customSubmitText ?? t("save")}
                                                onClick={() => props.submit ? props.submit() : ''}
                                            />
                                        ) : ''}
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                </div>
            </div>
        </>
    );
}

export default MarketingPlannerModalOpener;
