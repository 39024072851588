import * as React from "react";
import type { SVGProps } from "react";
const SvgPicture = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={15}
    fill="none"
    {...props}
  >
    <path
      d="M2.333.834A1.68 1.68 0 0 0 .667 2.501v10c0 .91.756 1.666 1.666 1.666h13.334a1.68 1.68 0 0 0 1.666-1.666v-10A1.68 1.68 0 0 0 15.667.834H2.333Zm0 1.667h13.334v10H2.333v-10Zm5 1.666a.833.833 0 1 0 0 1.667.833.833 0 0 0 0-1.667Zm3.75 2.5-2.916 3.334-2.084-2.084-2.268 2.917h10.393l-3.125-4.167Z"
    />
  </svg>
);
export default SvgPicture;
