import * as React from "react";
import type { SVGProps } from "react";
const SvgEmail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    width={20}
    height={20}
    fill="none"
    {...props}>
    <path fill={props.fill ?? "#FFFFFF"} d="M3.33335 16.6667C2.87502 16.6667 2.48266 16.5035 2.15627 16.1771C1.82988 15.8507 1.66669 15.4583 1.66669 15V5C1.66669 4.54167 1.82988 4.1493 2.15627 3.82292C2.48266 3.49653 2.87502 3.33333 3.33335 3.33333H16.6667C17.125 3.33333 17.5174 3.49653 17.8438 3.82292C18.1702 4.1493 18.3334 4.54167 18.3334 5V15C18.3334 15.4583 18.1702 15.8507 17.8438 16.1771C17.5174 16.5035 17.125 16.6667 16.6667 16.6667H3.33335ZM16.6667 6.66667L10.4375 10.5625C10.3681 10.6042 10.2952 10.6354 10.2188 10.6562C10.1424 10.6771 10.0695 10.6875 10 10.6875C9.93058 10.6875 9.85766 10.6771 9.78127 10.6562C9.70488 10.6354 9.63196 10.6042 9.56252 10.5625L3.33335 6.66667V15H16.6667V6.66667ZM10 9.16667L16.6667 5H3.33335L10 9.16667ZM3.33335 6.875V5.64583V5.66667V5.65625V6.875Z" />
  </svg>
);
export default SvgEmail;
