import { t } from "i18next";
import { Close, Picture } from "../../assets/IconComponents";
import SvgCloseLittle from "../../assets/IconComponents/CloseLittle";
import SvgText from "../../assets/IconComponents/Text";
import ContentContainer from "../ContentContainer";
import IconButton from "../IconButton";
import { useEffect, useState } from "react";
import { IGenericSelectorData } from "./GenericSelector";
import GenericSelectorItem from "./GenericSelectorItem";
import PrimaryButton from "../PrimaryButton";

export interface IGenericSelectorPopupProps {
    data: IGenericSelectorData[]
    subTitle: string
    closePopup: () => void
    selectedItem: IGenericSelectorData | undefined
    selecteItemChanged: (data: IGenericSelectorData | undefined) => void
    hideActionButton?: boolean
    className?: string
}

function GenericSelectorPopup(props: IGenericSelectorPopupProps) {
    const [selectedItem, setSelectedItem] = useState<IGenericSelectorData>()

    useEffect(() => {
        setSelectedItem(props.selectedItem)
    }, [props.selectedItem])


    return (
        <ContentContainer
            hoverable={false}
            className={`w-[300px] h-fit overflow-hidden ${props.className}`}
            children={
                <>
                    {/* Header
                    <div className="h-[56px] w-full flex items-center border-b-1 border-border justify-between">
                        <text className="text-H6 text-black ml-[15px] ">{props.subTitle}</text>
                        <button onClick={(e) => { props.closePopup() }}>
                            <SvgCloseLittle width={14} height={14} className="mr-3" />
                        </button>
                    </div> */}
                    {/*Body*/}
                    <div className="p-[10px] h-full overflow-scroll scrollbar-hide">
                        {
                            props.data.map(k => (
                                <GenericSelectorItem
                                    data={k}
                                    selected={selectedItem?.id === k.id}
                                    itemSelected={(e) => {
                                        setSelectedItem(e)
                                        if (props.hideActionButton) {
                                            props.selecteItemChanged(e)
                                        }
                                    }} />
                            ))
                        }
                        {!props.hideActionButton &&
                            <PrimaryButton
                                className="w-full h-[40px] mt-2"
                                title={t('apply-filters-0')}
                                onClick={() => { props.selecteItemChanged(selectedItem) }} />
                        }
                    </div>

                </>
            } />
    );
}

export default GenericSelectorPopup;