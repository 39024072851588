import Logo, { LogoType } from "../../components/Logo";
import GetStartedFree from "./GetStartedFree";

function Navigation() {
    return (<>
        <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <nav className="relative z-50 flex justify-between">
                    <div className="flex items-center md:gap-x-12">
                        <a aria-label="Home" href="/#">
                            <div className="h-6 sm:h-8">
                                <Logo type={LogoType.light} className="h-6 sm:h-8" />
                            </div>
                        </a>
                    </div>
                    <div className="flex items-center gap-x-5 md:gap-x-8">
                        <div className="hidden md:block">
                            <a
                                className="inline-block rounded-lg py-1 px-2 text-sm text-slate-100 hover:bg-slate-100 hover:text-slate-900"
                                href="/login">Sign in</a>
                        </div>
                        <GetStartedFree />
                        <div className="-mr-1 hidden">
                            <div data-headlessui-state="">
                                <button
                                    className="relative z-10 flex h-8 w-8 items-center justify-center [&amp;:not(:focus-visible)]:focus:outline-none"
                                    aria-label="Toggle Navigation"
                                    type="button"
                                    aria-expanded="false"
                                    data-headlessui-state=""
                                    id="headlessui-popover-button-:r2:"
                                ><svg
                                    aria-hidden="true"
                                    className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
                                    fill="none"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                ><path d="M0 1H14M0 7H14M0 13H14" className="origin-center transition" /><path
                                            d="M2 2L12 12M12 2L2 12"
                                            className="origin-center transition scale-90 opacity-0"
                                        /></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    </>);
}

export default Navigation;
