import * as React from "react";
import type { SVGProps } from "react";
const SvgContentManagerCollapsedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="40" height="40" fill="#121315" />
    <path
      d="M20 16.6667C20 16.4306 19.9201 16.2326 19.7604 16.0729C19.6007 15.9132 19.4028 15.8333 19.1667 15.8333L16.6667 15.8333C16.4306 15.8333 16.2326 15.9132 16.0729 16.0729C15.9132 16.2326 15.8333 16.4306 15.8333 16.6667C15.8333 16.9028 15.9132 17.1007 16.0729 17.2604C16.2326 17.4201 16.4306 17.5 16.6667 17.5L19.1667 17.5C19.4028 17.5 19.6007 17.4201 19.7604 17.2604C19.9201 17.1007 20 16.9028 20 16.6667ZM24.1667 23.3333C24.1667 23.0972 24.0868 22.8993 23.9271 22.7396C23.7674 22.5799 23.5694 22.5 23.3333 22.5L16.6667 22.5C16.4306 22.5 16.2326 22.5799 16.0729 22.7396C15.9132 22.8993 15.8333 23.0972 15.8333 23.3333C15.8333 23.5694 15.9132 23.7674 16.0729 23.9271C16.2326 24.0868 16.4306 24.1667 16.6667 24.1667L23.3333 24.1667C23.5694 24.1667 23.7674 24.0868 23.9271 23.9271C24.0868 23.7674 24.1667 23.5694 24.1667 23.3333ZM21.3333 20C21.3333 19.7639 21.2535 19.566 21.0937 19.4062C20.934 19.2465 20.7361 19.1667 20.5 19.1667L19.6667 19.1667C19.4306 19.1667 19.2326 19.2465 19.0729 19.4062C18.9132 19.566 18.8333 19.7639 18.8333 20C18.8333 20.2361 18.9132 20.434 19.0729 20.5937C19.2326 20.7535 19.4306 20.8333 19.6667 20.8333L20.5 20.8333C20.7361 20.8333 20.934 20.7535 21.0937 20.5937C21.2535 20.434 21.3333 20.2361 21.3333 20ZM12.5 14.1667C12.5 13.7083 12.6632 13.316 12.9896 12.9896C13.316 12.6632 13.7083 12.5 14.1667 12.5L25.8333 12.5C26.2917 12.5 26.684 12.6632 27.0104 12.9896C27.3368 13.316 27.5 13.7083 27.5 14.1667L27.5 25.8333C27.5 26.2917 27.3368 26.684 27.0104 27.0104C26.684 27.3368 26.2917 27.5 25.8333 27.5L14.1667 27.5C13.7083 27.5 13.316 27.3368 12.9896 27.0104C12.6632 26.684 12.5 26.2917 12.5 25.8333L12.5 14.1667ZM14.1667 14.1667L14.1667 25.8333L25.8333 25.8333L25.8333 14.1667L14.1667 14.1667Z"
      fill={props.fill ?? "#8E8E8E"}
    />
  </svg>
);
export default SvgContentManagerCollapsedIcon;


