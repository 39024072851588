import { useEffect, useRef } from "react";
import { EventCalendar } from "../../packages/event-calendar_2";
import './Calendar.css';
import { format } from 'date-fns'
import { fbSVG, xSVG, instagramSVG, linkedInSVG, blogSVG, newsletterSVG, googleSVG, metaSVG,PLACEHOLDER_SVG } from "../../assets/IconComponents/svgs";
import {reformatText} from "../../helpers/Utils";


const timeline_settings = {
    maxEventsPerCell: 50,
    eventHeight: 60,
    colsCount: 90,
    minEventWidth: 50,
    colsWidth: 90,
    minSectionHeight: 100,
    sectionWidth: 158,

    step: [7, "month"],
    key: "section",
    header: [
        { unit: "month", step: 1, format: "MMM" },
        { unit: "day", step: 1, format: "d MMM" },
    ],
    sections: [
        { id: "1", label: "Section 1" },
        { id: "2", label: "Section 2" },
        { id: "3", label: "Section 3" },
    ],
    unassignedCol: true,
};

function getEventName(event) {
    var eventName = event.eventType
    var campaignName = event.campaignType

    if (eventName != undefined && campaignName != undefined) {
        return `<div class="m-1 p-3">
            <div><span class="w-[20px] h-[20px]"></span><span>${reformatText(eventName)} / ${reformatText(campaignName)}</span></div>
        </div>`
    } else if (campaignName != undefined) {
        return `<div class="m-1 p-3">
            <div><span class="w-[20px] h-[20px]"></span><span> ${reformatText(campaignName)}</span></div>
        </div>`
    }

    return ``
}

function getPoopupContent(event) {

    var textContent = ""
    //if (event.textContentDetails != undefined && event.textContentDetails.length > 0) {
        textContent = `<div class="overflow-scroll scrollbar-hide w-full mr-3">${event.text} </div>`
    //}

    var imageContent = ""
    if(event.status == "Awaiting Confirmation" || event.status == "Content Generation")
    {
        imageContent = ` <div  >${PLACEHOLDER_SVG}</div>`
    }
    else if (event.heroImage != undefined) {
        imageContent = `<img src="${process.env.REACT_APP_BASE_URL}file/download/image/${event.heroImage}" alt="" class="h-[100px] w-[100px] rounded-md" />`
    }

    return `${textContent}${imageContent}`
}

function getSocialIcon(event) {
    const channels = event.channels
    //debugger
    if (channels != undefined && channels.length > 0) {
        var channel = channels[0]

        switch (channel) {
            case "FACEBOOK":
                return fbSVG

            case "X":
                return xSVG

            case "INSTAGRAM":
                return instagramSVG

            case "LINKEDIN":
                return linkedInSVG

            case "BLOG":
                return blogSVG

            case "NEWSLETTER":
                return newsletterSVG

            case "GOOGLE_ADS":
                return googleSVG

            case "META_ADS":
                return metaSVG

            default:
                return ""
        }
    }

    return ""
}

function popup({ event, calendar, isInReview, editActivity, deleteActivity }) {
    console.log(isInReview)
    var activity = event
    setTimeout(() => {
        const deleteButton = document.getElementById('Delete_Activity')
        deleteButton?.addEventListener('click', () => deleteActivity(activity))
        const editButton = document.getElementById('Edit_Activity')
        editButton?.addEventListener('click', () => editActivity(activity))
    }, 0)
    // debugger
    return `
        <div class="popup_wrapper w-[400px]">
		<div class="flex items-center justify-between p-3">
		    <div class="flex items-center">
                <div style="background-color: ${calendar.color.border}; width: 15px; height: 15px"></div>
                <div class="ml-2">${calendar.label}</div>
		    </div>
		    <div>${getSocialIcon(event)}</div>
        </div>
        <div style="background-color: #eee" class="flex justify-between p-3 h-[120px]">
            ${getPoopupContent(event)}
            
        </div>
        ${getEventName(event)}

    <div class="popup_info m-3">
        <div class="text-gray">
            ${format(event.start_date, "MMM, do  h:mm")}
            -
            ${format(event.end_date, "MMM, do h:mm")}
        </div>
        <div class="popup_controls mt-3 flex">
            <div class="popup_control flex items-center mr-3 cursor-pointer" id="Edit_Activity" style="${(isInReview || event.status === 'Awaiting Confirmation' || event.status === 'Content Generation') ? 'display: none' : ''}">
                <svg class="mr-1" width="16" height="17" viewBox="0 0 16 17" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13.274 2.50098C13.0883 2.50098 12.9025 2.57123 12.761 2.71257L7.24341 8.23079L6.99927 9.50098L8.26945 9.25684L13.787 3.73926C13.924 3.60226 13.9999 3.4199 13.9999 3.22624C13.9999 3.03257 13.924 2.8499 13.787 2.71257C13.6455 2.57123 13.4598 2.50098 13.274 2.50098ZM4.16659 2.83366C3.16196 2.83366 2.33325 3.66237 2.33325 4.66699V12.3337C2.33325 13.3383 3.16196 14.167 4.16659 14.167H11.8333C12.8379 14.167 13.6666 13.3383 13.6666 12.3337V6.83366C13.6678 6.74532 13.6515 6.65761 13.6186 6.57563C13.5856 6.49365 13.5367 6.41904 13.4747 6.35612C13.4127 6.29321 13.3387 6.24325 13.2572 6.20916C13.1757 6.17506 13.0883 6.1575 12.9999 6.1575C12.9116 6.1575 12.8241 6.17506 12.7426 6.20916C12.6611 6.24325 12.5872 6.29321 12.5251 6.35612C12.4631 6.41904 12.4142 6.49365 12.3813 6.57563C12.3483 6.65761 12.332 6.74532 12.3333 6.83366V12.3337C12.3333 12.6177 12.1173 12.8337 11.8333 12.8337H4.16659C3.88254 12.8337 3.66659 12.6177 3.66659 12.3337V4.66699C3.66659 4.38295 3.88254 4.16699 4.16659 4.16699H9.66658C9.75493 4.16824 9.84263 4.15192 9.92461 4.11898C10.0066 4.08604 10.0812 4.03713 10.1441 3.9751C10.207 3.91307 10.257 3.83915 10.2911 3.75765C10.3252 3.67614 10.3427 3.58868 10.3427 3.50033C10.3427 3.41198 10.3252 3.32451 10.2911 3.243C10.257 3.1615 10.207 3.08758 10.1441 3.02555C10.0812 2.96352 10.0066 2.91462 9.92461 2.88167C9.84263 2.84873 9.75493 2.83241 9.66658 2.83366H4.16659Z"
                        fill="black" />
                </svg>
                <span class="popup_label">Edit Activity</span>
            </div>

            <div class="popup_control flex items-center cursor-pointer" id="Delete_Activity">
                <svg class="mr-1" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.6667 1.83301L6.00003 2.49967H2.53337C2.16518 2.49967 1.8667 2.79815 1.8667 3.16634C1.8667 3.53453 2.16518 3.83301 2.53337 3.83301H3.33337V13.833C3.33337 14.1812 3.46092 14.5361 3.71227 14.7874C3.96363 15.0388 4.31855 15.1663 4.6667 15.1663H11.3334C11.6815 15.1663 12.0364 15.0388 12.2878 14.7874C12.5391 14.5361 12.6667 14.1812 12.6667 13.833V3.83301H13.4667C13.8349 3.83301 14.1334 3.53453 14.1334 3.16634C14.1334 2.79815 13.8349 2.49967 13.4667 2.49967H10L9.33337 1.83301H6.6667ZM4.6667 3.83301H11.3334V13.833H4.6667V3.83301ZM6.00003 5.16634V12.4997H7.33337V5.16634H6.00003ZM8.6667 5.16634V12.4997H10V5.16634H8.6667Z" fill="#FF5555" />
                </svg>
                <span class="popup_label" style="color: #FF5555">Delete Activity</span>
            </div>
        </div>
    </div>
		</div>
        `;
}

export default function CalendarView(props) {
    let container = useRef();

    const getTextContentForEvent = (event) => {

        if (event.postCategory == "PAID_META") {
            return `<p style = "color: ${event.color?.textColor ?? "#121315"};" class="max-h-[34px] text-BodySmall leading-[17px] line-clamp-2 whitespace-pre-wrap text-wrap">Meta Ads</p>`
        } else if (event.postCategory == "PAID_GOOGLE") {
            return `<p style = "color: ${event.color?.textColor ?? "#121315"};" class="max-h-[34px] text-BodySmall leading-[17px] line-clamp-2 whitespace-pre-wrap text-wrap">Google Ads</p>`
        }


            return `<p style = "color: ${event.color?.textColor ?? "#121315"};" class="max-h-[34px] text-BodySmall leading-[17px] line-clamp-2 whitespace-pre-wrap text-wrap"> ${event.text}</p> `


        return ""
    }

    const getImageContentForEvent = (event) => {
        if (event.postCategory == "PAID_META" || event.postCategory == "PAID_GOOGLE") { return "" }

        if (event.heroImage != undefined) {
            return `<img src = "${process.env.REACT_APP_BASE_URL}file/download/image/${event.heroImage}" alt = "" class="ml-1 w-[48px] h-[48px] rounded-sm" /> `
        }

        return ""
    }

    const getEventContent = (event) => {
        var textContent = getTextContentForEvent(event)
        var imageContent = getImageContentForEvent(event)
        var icon = `<div class="mx-1">${getSocialIcon(event)}</div>`

        if (event.postCategory == "PAID_META") {
            icon = `<div class="mx-1">${metaSVG}</div>`
        } else if (event.postCategory == "PAID_GOOGLE") {
            icon = `<div class="mx-1">${googleSVG}</div>`
        }

        var content = `${icon}${textContent}${imageContent} `


        if (content == "") {
            content = `<p style = "color: ${event.color?.textColor ?? "#121315"};" class="max-h-[34px] text-BodySmall leading-[17px] line-clamp-2 whitespace-pre-wrap text-wrap"> No Content Available.</p> `;
        }
        return content
    }

    useEffect(() => {
        const calendar = new EventCalendar("#content-calendar-root", {
            license: '30676173',
            templates: {
                monthEvent: ({ event, calendar }) => {
                    return `<div style = "background: ${event.color?.background ?? "#F59D1A1A"}; border-color:${event.color?.border ?? "#F59D1A80"};" class="flex flex-row items-center h-[60px] border-1 rounded-md p-[5px]">
                    ${getEventContent(event)}
                            </div> `;
                },
                multievent: ({ event, calendar }) => {
                    return getEventContent(event)

                },
                popup: (params) => popup({
                    ...params,
                    isInReview: props.isInReview,
                    editActivity: (activity) => {
                        props.triggeredEdit(activity)
                    },
                    deleteActivity: (activity) => {
                        props.deleteActivity(activity)
                    }
                })
                // the event template of the "Week" and "Day" modes
                /*weekEvent: ({ event, calendar }) => {
                    console.log(event)
                    const start_date = format(event.start_date, "HH:mm");
                    const end_date = format(event.end_date, "HH:mm");
                    return `
        <div className = "week_event_wrapper">
                    <div>${event.text}</div>
                    <div> ${start_date} - ${end_date}</div>
                    ${ event.img ? `<img src=${event.img} alt="" />` : "" }
                </div> `;
                },*/
                // the event template of the "Month" mode
                /*monthEvent: ({ event, calendar }) => {
                    console.log(event)
                    return `
        <div>
                    <i className="mdi mdi-account-multiple"></i>
                    <span className="label"> ${event.text} </span>
                </div> `;
                },*/
                // the event template of the "Year" mode
                /*yearEvent: ({ event, calendar }) => {
                    console.log(event)
                    return `
        <div className = "wrapper">
                    <i className="mdi mdi-calendar-multiple"> </i>
                    <span className="multievent_label">${event.text}</span>
                </div> `;
                },*/
                // the event template of the "Agenda" mode
                /*agendaEvent: ({ event, calendar }) => {
                    console.log(event)
                    const { start_date, end_date, text } = event;
                    const start = format(start_date, "HH:mm");
                    const end = format(end_date, "HH:mm");
                    const label = `
        <b> Event:</b> ${ text } </br>
            <b>Time:</b> ${ start } -${ end } `;
                    return `
                <div>
                <span className="label"> ${label} </span>
                </div>
        `;
                },*/
                // the template of the date cell in the "Agenda" mode
                /*agendaDate: ({ event, calendar, date }) => {
                    console.log(event)
                    const day = format(date, "do");
                    const week = format(date, "dddd");
                    return `
        <div className = "custom-date">
            <b>${day}</b>
                    ${ week }
                </div>
        `;
                },*/
                // the template of the grid header
                /*header: ({ date, dateFormat }) => {
                    console.log(date)
                    const label = format(date, "dd mm yyyy");
                    return `
        <div className = "custom-date">
            ${ label }
                </div> `;
                },*/
                // the multievent template
                /*multievent: ({ event, calendar }) => {
                    console.log(event)
                    return `
        <div className = "wrapper">
                    <i
                        className="mdi mdi-calendar-multiple"
                        style={{color: '${calendar.color.border}'}}> </i>
                    <span className="multievent_label">${event.text}</span>
                </div> `;
                },*/
                // the info popup template
                /*popup: ({ event, calendar }) => {
                    console.log(event)
                    const start_date = format(event.start_date, "MMM, do h:mm");
                    const end_date = format(event.end_date, "MMM, do h:mm");
                    return `
        <div className = "popup_wrapper">
                    <h1>${event.text}</h1>
                    <div className="popup_info">
                        <span><b>Description:</b></span>
                        <span>${event.details}</span>
                        <span><b>Date:</b></span>
                        <div>${start_date} - ${end_date}</div>
                    </div>
                </div> `;
                },*/
                // the "Timeline" mode section template
                /*timelineSection: (section) => {
                    console.log(section)
                    return `
        <div className = "template-wrapper">
                    <img src=${section.img} alt=${section.label} className="section-img" />
                    <div className="section-label">${section.label}</div>
                </div> `;
                },*/
            },
            events: props.events,
            date: props.date,
            mode: "month",
            sidebar: { show: true },
            calendars: [ // data for calendars (event types) located on sidebar
                {
                    id: "Awaiting Confirmation",
                    label: "Awaiting Confirmation",
                    readonly: true,
                    active: true,
                    color: {
                        background: "#e3c7ff",
                        border: "#A041FF",
                        textColor: "#222222"
                    }
                },
                {
                    id: "Content Generation",
                    label: "Content Generation",
                    readonly: true,
                    active: true,
                    color: {
                        background: "#e3c7ff",
                        border: "#A041FF",
                        textColor: "#222222"
                    }
                },
                {
                    id: "Awaiting Approval",
                    label: "Awaiting Approval",
                    readonly: true,
                    active: true,
                    color: {
                        background: "#F59D1A",
                        border: "#F59D1A",
                        textColor: "#222222"
                    }
                },
                {
                    id: "Scheduled",
                    label: "Scheduled",
                    readonly: true,
                    active: true,
                    color: {
                        background: "#1A8CF5",
                        border: "#1A8CF5",
                        textColor: "#222222"
                    }
                },
                {
                    id: "Draft",
                    label: "Draft",
                    readonly: true,
                    active: true,
                    color: {
                        background: "#CECECE",
                        border: "#8E8E8E",
                        textColor: "#222222"
                    }
                },
                {
                    id: "Posted",
                    label: "Posted",
                    readonly: true,
                    active: true,
                    color: {
                        background: "#16C26C",
                        border: "#16C26C",
                        textColor: "#222222"
                    }
                },
            ],
            config: {
                dragCreate: false,
                dragResize: false,
                dragMove: false,
                eventInfoOnClick: true,
                editorOnDblClick: true,
                createEventOnDblClick: false,
                defaultEventDuration: 60,
                eventsOverlay: true,
                autoSave: true,
                readonly: true,
                license: '30676173',
                views: [
                    {
                        id: "week",
                        label: "Week",
                        layout: "week",
                        config: timeline_settings
                    },
                    {
                        id: "month",
                        label: "Month",
                        layout: "month",
                        config: timeline_settings,
                    }
                ],
                onclick: (e) => console.log(e)
            },
        });

        calendar.api.on("edit-event", (obj) => {
            if (props.triggeredEdit) {
                props.triggeredEdit(obj)
            }
        });

        calendar.api.on("set-mode", (obj) => {
            updateCellHeight();
        });

        calendar.api.on("set-bound", (obj) => {
            updateCellHeight()
        });

        if (props.getCalendar) {
            props.getCalendar(calendar)
        }

        window.eventCalendar = calendar;

        return () => {
            calendar.events = []
            return container.current ? container.current.innerHTML = "" : null
        };
    }, []);

    if (document.getElementsByClassName('wx-event-calendar-name')[0]) {
        document.getElementsByClassName('wx-event-calendar-name')[0].innerHTML = 'Status';
    }

    useEffect(() => {
        if (props.events) {
            updateCellHeight()
        }
    }, [props.events])

    function updateCellHeight() {
        setTimeout(() => {
            const getStyle = (el, ruleName) => getComputedStyle(el)[ruleName];
            const setStyle = (el, rule, val) => (el.style[rule] = val);
            var elems = document.getElementsByClassName('wx-event-calendar-calendar-week')
            for (let i = 0; i < elems.length; i++) {
                const element = elems[i];
                var maxHeightStyle = getStyle(element, 'max-height');

                var str = maxHeightStyle?.substring(0, maxHeightStyle?.length - 2);
                if (Number(str) < 200)
                    maxHeightStyle = "200px"

                setStyle(element, 'min-height', maxHeightStyle);
            }
        }, 200);
    }

    return <div ref={container} style={{ width: "100%" }}></div>;
}
