import { useEffect, useState } from "react";
import { setDialog, toggleDialog } from "../../store/appSlice";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import { useRef } from "react";
import { clickOutside } from "../../helpers/ClickOutside";
import Rocket from "../../assets/IconComponents/Rocket";
import TextArea from "antd/es/input/TextArea";
import SecondaryButton from "../SecondaryButton";
import SvgGenerateSimilarIcon from "../../assets/IconComponents/GenerateSimilarIcon";
import { Button, DatePicker, Form, Input, InputNumber, notification, Popover, Select, Tabs, TabsProps } from "antd";
import dayjs from "dayjs";
import { ClockCircleOutlined, CloseOutlined, FileImageOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import AddCustomImageStep from "./AIActivityModalSideSteps/AddCustomImageStep";
import { useLazyFindById4Query } from "../../services/BrandApi";
import OrganicImages from "./AIActivityModalSideSteps/OrganicImages";
import AddChannelStep from "./AIActivityModalSideSteps/AddChannelStep";
import GenerateCaptionWithAI from "./AIActivityModalSideSteps/GenerateCaptionWithAI";
import GenerateImageWithAI from "./AIActivityModalSideSteps/GenerateImageWithAI";
import activitySlice, { setNewActivity } from "../../store/activitySlice";
import EditPlacementStep from "./AIActivityModalSideSteps/EditPlacementStep";
import EditCreativesStep from "./AIActivityModalSideSteps/EditCreativesStep";
import { ActivityRequest, ActivityResponse, ContentDetails, Creative, useDeleteActivityByIdMutation, useSaveActivityMutation, useScheduleOrDraftActivityMutation } from "../../services/Activity";
import { AudienceResponse, useLazyGetAudienceByIdQuery, useLazyGetAudienceQuery } from "../../services/AudiencetApi";
import EditAudienceStep from "./AIActivityModalSideSteps/EditAudienceStep";
import SvgCalendar from "../../assets/IconComponents/Calendar";
import SvgChannel from "../../assets/IconComponents/Channel";
import SvgChannel1 from "../../assets/IconComponents/Channel1";
import { toggleLoading } from "../../store/appSlice";
import moment from "moment";
import { Facebook, FacebookColor, TwitterColor, Instagram, InstagramColor, Linkedin, LinkedinColor, BlogColor } from "../../assets/IconComponents";
import GoogleAdsColor from "../../assets/IconComponents/GoogleAdsColor";
import SvgMetaSocialIcon from "../../assets/IconComponents/MetaSocialIcon";
import NewsLetterColor from "../../assets/IconComponents/NewsLetterColor";
import Twitter from "../../assets/IconComponents/Twitter";
import Icon from "../Icon";
import SvgDelete from "../../assets/IconComponents/Delete";
import CampaignPreview from "./CampaignPreview";
import SvgChannelSelected from "../../assets/IconComponents/ChannelSelected";
import SvgTritoneColorCreativeIcon from "../../assets/IconComponents/TritoneColorCreativesIcon";
import SvgBudgetIcon from "../../assets/IconComponents/BudgetIcon";
import SvgPlacementIcon from "../../assets/IconComponents/PlacementIcon";
import SvgCompanyIcon from "../../assets/IconComponents/CompanyIcon";
import SvgTargetUrlIcon from "../../assets/IconComponents/TargetUrlIcon";
import SvgStatuIcon from "../../assets/IconComponents/StatuIcon";
import AISmartPlacementsIcon from "../../assets/IconComponents/AISmartPlacementsIcon";
import AISmartPlacementsBlueIcon from "../../assets/IconComponents/AISmartPlacementsBlueIcon";
import { reformatText, testReg } from "../../helpers/Utils";
import { setNewAudienceInfo } from "../../store/brandSlice";
import getSymbolFromCurrency from "currency-symbol-map"

export interface IMarketingPlannerModalProps {
    open: boolean
    close: () => void
    title?: string
    submit?: () => void
    disabledClickOutside?: boolean
    hideFooter?: boolean
    customSubmitText?: string
    additionButtonText?: string
    additionButtonClick?: () => void
    selectedActivity?: any
    refreshCalendar: () => void
    onCreditError: () => void
}


const sideModalSteps = {
    addCustomImage: 'addCustomImage',
    addAIImage: 'addAIImage',
    addChannel: 'addChannel',
    generateCaptionWithAI: 'generateCaptionWithAI',
    editPlacements: 'editPlacements',
    editCreatives: 'editCreatives',
    editAudience: 'editAudience',
}

const sideModals = {
    [sideModalSteps.addCustomImage]: {
        title: 'Add Image',
        description: 'Select in order to add your images',
    },
    [sideModalSteps.addChannel]: {
        title: 'Select Channel',
        description: '',
    },
    [sideModalSteps.generateCaptionWithAI]: {
        title: 'Generate with AI',
        description: '',
    },
    [sideModalSteps.addAIImage]: {
        title: 'Generate with AI',
        description: '',
    },
    [sideModalSteps.editPlacements]: {
        title: 'AI Smart Placements',
        description: '',
    },
    [sideModalSteps.editCreatives]: {
        title: 'Edit Creatives',
        description: '',
    },
    [sideModalSteps.editAudience]: {
        title: 'Edit Audience',
        description: '',
    }
}

export const ActivityType = {
    Organic: 'ORGANIC',
    PaidMeta: 'PAID_META',
    PaidGoogle: 'PAID_GOOGLE',
    Holiday: 'HOLIDAY',
}

function AIActivityModal(props: IMarketingPlannerModalProps) {
    const dispatch = useAppDispatch();
    useEffect(() => {
        showSubscriptionDialog();
    }, [props.open])

    const showSubscriptionDialog = () => {
        dispatch(setDialog({
            actions: <></>,
            title: "",
            message: "",
            customTemplate: (
                <DefaultModal {...props}></DefaultModal>
            ),
        }));
        dispatch(toggleDialog(props.open));
    }

    return (<></>)
}

function DefaultModal(props: IMarketingPlannerModalProps) {
    const dispatch = useAppDispatch();
    const ref = useRef<HTMLDivElement>(null);
    const [isSideModalOpen, setIsSideModalOpen] = useState<boolean>(false)
    const [activeSideModalStep, setActiveSideModalStep] = useState<string>('')
    const [selectedProductImages, setSelectedProductImages] = useState<string[]>([]);
    const [savedImages, setSavedImages] = useState<any[]>(props?.selectedActivity?.imageContentDetails ?? []);
    const [selectedImages, setSelectedImages] = useState<any[]>(savedImages ?? []);
    const [selectedChannels, setSelectedChannels] = useState<any[]>([])
    const [generatedAIText, setGeneratedAIText] = useState('')
    const [generatedAIImages, setGeneratedAIImages] = useState<any[]>([])
    const [targetURLPopoverIsOpen, setTargetURLPopoverIsOpen] = useState<boolean>(false);
    const [companyNamePopoverIsOpen, setCompanyNamePopoverIsOpen] = useState<boolean>(false);
    const [budgetPopoverIsOpen, setBudgetPopoverIsOpen] = useState<boolean>(false);
    const [statusPopoverIsOpen, setStatusPopoverIsOpen] = useState<boolean>(false)
    const [targetURL, setTargetURL] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [budget, setBudget] = useState<number>();
    const [creatives, setCreatives] = useState<Creative>();
    const [save, { data: activityCreated, isLoading: activityCreating }] = useSaveActivityMutation()
    const [getContentAudience, { data: contentAudience }] = useLazyGetAudienceByIdQuery();
    const [getBrandAudience, { data: brandAudience }] = useLazyGetAudienceQuery()
    const selectedBrandId = useAppSelector((k) => k.persisted.lastSelectedBrandId);
    const [getBrand, { data: brand, isLoading: brandLoading }] = useLazyFindById4Query()
    const [deleteActivityTrigger, { isSuccess: activityDeleted }] = useDeleteActivityByIdMutation()
    const [updateActivityTrigger, { isSuccess: activityUpdated }] = useScheduleOrDraftActivityMutation()
    const [isSubSideOpen, setIsSubSideOpen] = useState<boolean>(false)
    const [downloadedImages, setDownloadedImages] = useState<any[]>([]);
    const lastSelectedBrandId = useAppSelector(k => k.persisted.lastSelectedBrandId)
    const [activity, setActivity] = useState<any>({
        ...props?.selectedActivity,
        targetURL: '',
        audience: props?.selectedActivity?.audience ?? {},
        selectedStatus: props?.selectedActivity?.status ?? '',
        companyName: props?.selectedActivity?.companyName ?? '',
        budget: props?.selectedActivity?.budgetPrice ?? '',
        type: ActivityType.Organic ?? '',
        textContentDetails: props?.selectedActivity?.textContentDetails ?? [],
        period: moment(props?.selectedActivity?.endDate).diff(moment(props?.selectedActivity?.startDate), "day") ?? 0,
        startDate: dayjs(props?.selectedActivity?.startDate ?? new Date()).valueOf(),
        endDate: dayjs(props?.selectedActivity?.endDate ?? new Date()).valueOf(),
    });

    const [selectedAudience, setSelectedAudience] = useState<AudienceResponse>(activity.audience)
    const [compareActivity, setCompareActivity] = useState({})

    const [channels, setChannels] = useState([
        { key: 'FACEBOOK', label: 'Facebook', isSelected: true, isPaid: false, icon: Facebook, colorIcon: <FacebookColor /> },
        { key: 'X', label: 'X (Twitter)', isSelected: true, isPaid: false, icon: Twitter, colorIcon: <TwitterColor /> },
        { key: 'INSTAGRAM', label: 'Instagram', isSelected: true, isPaid: false, icon: Instagram, colorIcon: <InstagramColor /> },
        { key: 'LINKEDIN', label: 'Linkedin', isSelected: true, isPaid: false, icon: Linkedin, colorIcon: <LinkedinColor /> },
        { key: 'BLOG', label: 'Blog', isSelected: true, isPaid: false, icon: Facebook, colorIcon: <BlogColor /> },
        { key: 'NEWSLETTER', label: 'Newsletter', isSelected: true, isPaid: false, icon: Facebook, colorIcon: <NewsLetterColor /> },
        { key: 'GOOGLE_ADS', label: 'Google Ads', isSelected: true, isPaid: true, icon: Facebook, colorIcon: <GoogleAdsColor /> },
        { key: 'META_ADS', label: 'Meta Ads', isSelected: true, isPaid: true, icon: Facebook, colorIcon: <SvgMetaSocialIcon /> },
    ])

    const getChannelDetails = (key: string) => {
        return channels.find(k => k.key == key);
    }


    useEffect(() => {
        dispatch(toggleLoading(activityCreating));
    }, [activityCreating])


    useEffect(() => {
        console.log({ ...activity.audience })
        dispatch(setNewAudienceInfo({ ...activity.audience }));
    }, []);

    useEffect(() => {
        if (activityCreated) {
            props.close()
        }
    }, [activityCreated])

    useEffect(() => {
        if (selectedBrandId) {
            getBrand({ id: selectedBrandId })
        }
    }, [selectedBrandId])

    useEffect(() => {
        if (contentAudience) {
            setSelectedAudience(contentAudience)
        }
    }, [contentAudience]);


    useEffect(() => {
        if (brand?.id) {
            getBrandAudience({ brandId: brand?.id })
        }
    }, [brand])

    useEffect(() => {

        if (brandAudience?.length) {
            getContentAudience({ audienceId: 'audience_id' })
        }
    }, [brandAudience])

    useEffect(() => {
        const newList: any[] = [...generatedAIImages.map(img => (
            {
                imageFileId: img?.generated_content,
                revisionId: img.id.revision_id,
                contentId: img.id.content_id,
                generated: true,
                active: false
            })), ...selectedProductImages.map(id => (
                {
                    imageFileId: id,
                    generated: false,
                    active: false
                }))]
        selectedImages.forEach(im => {
            if (!newList.filter(a => im.imageFileId === a.imageFileId).length) {
                newList.push(im)
            }
        })
        setSelectedImages(newList)
    }, [selectedProductImages, generatedAIImages]);


    useEffect(() => {
        //  debugger
        if (props.selectedActivity) {
            let url = { host: '' };
            if (props?.selectedActivity?.productAboutUrl) {
                url = new URL(props?.selectedActivity?.productAboutUrl)
                //setActivityField('targetURL', url.host);
                setTargetURL(url.host)
            }
            console.log(props?.selectedActivity?.channels)
            var ac = props.selectedActivity
            var period = moment(props?.selectedActivity?.endDate).diff(moment(props?.selectedActivity?.startDate), "day") ?? 0;
            setActivity({ ...ac, period: period, targetURL: url.host })
            setSavedImages(props?.selectedActivity?.imageContentDetails ?? [])
            setSelectedChannels((props?.selectedActivity?.channels ?? []).map(k => channels.find(l => l.key == k)))
            setCreatives(props?.selectedActivity?.creatives)
            setBudget(props?.selectedActivity?.budgetPriceInAccountCurrency)
            setCompareActivity({ ...ac, period: period, targetURL: url.host })
        }
    }, [props.selectedActivity]);

    console.log(props.selectedActivity)
    console.log(selectedAudience)

    const setActivityField = (field, val) => {
        setActivity(prevState => {
            return { ...prevState, [field]: val }
        })
    }

    useEffect(() => {
        if (!props.disabledClickOutside && ref.current) {
            return clickOutside(ref.current, () => {
                props.close();
            });
        }
    }, [ref.current]);

    const onChange = (field, value) => {
        setActivity(prevState => ({ ...prevState, [field]: value }))
    }

    const closeSideModal = () => {
        /*if (activeSideModalStep === sideModalSteps.addCustomImage && selectedProductImages.length) {
            setDownloadedImages([]);
            selectedProductImages.forEach(imgID => downloadFileFrom(imgID))
        }*/
        setIsSideModalOpen(false)
        setActiveSideModalStep('')
        dispatch(toggleLoading(false));
    }

    var getColorByStatus = (status: string) => {
        if (status == "Awaiting Confirmation") {
            return {
                background: "#e3c7ff",
                border: "#A041FF",
                textColor: "#121315",
                status: "#A041FF"
            }
        }
        else if (status == "Awaiting Approval") {
            return {
                background: "#F59D1A1A",
                border: "#F59D1A80",
                textColor: "#121315",
                status: "#F59D1A"
            }
        }
        else if (status == "Content Generation") {
            return {
                background: "#e3c7ff",
                border: "#A041FF",
                textColor: "#121315",
                status: "#A041FF"
            }
        }
        else if (status == "Scheduled") {
            return {
                background: "#1A8CF51A",
                border: "#1A8CF580",
                textColor: "#121315",
                status: "#1A8CF5"
            }
        }
        else if (status == "Draft") {
            return {
                background: "#CECECE1A",
                border: "#8E8E8E80",
                textColor: "#121315",
                status: "#CECECE"
            }
        }
        else if (status == "Posted") {
            return {
                background: "#16C26C1A",
                border: "#16C26C80",
                textColor: "#121315",
                status: "#16C26C"
            }
        }
    }


    const googleAdsTabs: TabsProps['items'] = [
        {
            key: '1',
            label: 'Search Ads',
            children: <div className={'mt-5'}>
                <div className={''} style={{ textDecoration: 'darkblue solid underline', color: '#1A8CF5' }}>
                    {
                        activity.creatives?.shortHeadlines &&
                        activity.creatives?.shortHeadlines!.length > 0
                        &&
                        `${activity.creatives?.shortHeadlines[0].textContent}  ${activity.creatives?.shortHeadlines.length >= 2 ? " - " + activity.creatives?.shortHeadlines[1].textContent : ""}  ${activity.creatives?.shortHeadlines.length >= 3 ? " - " + activity.creatives?.shortHeadlines[2].textContent : ""}`

                    }
                </div>
                <div className={'text-BodySmall text-brightGreen mt-2'}>{props?.selectedActivity?.productAboutUrl ? new URL(props?.selectedActivity?.productAboutUrl).origin : ''}</div>
                <hr className={'px-3 mt-2'} />
                <div className={'mt-3 text-BodySmall text-gray'}>{
                    activity.creatives?.description &&
                    activity.creatives?.description!.length > 0
                    &&
                    `${activity.creatives?.description[0].textContent}  ${activity.creatives?.description.length >= 2 ? " - " + activity.creatives?.description[1].textContent : ""}  `

                }
                </div>
            </div>,
        },
        {
            key: '2',
            label: 'Display Ads',
            children:
                <div className={'text-BodySmall text-brightGreen mt-2'}>We’re working on AI Marketing Planner</div>
            //  {downloadedImages[0] ? <img src={downloadedImages[0].preview}
            //    alt={downloadedImages[0].name} /> : <span>no images</span>}
            ,
        },
    ];

    useEffect(() => {
        if (activityDeleted || activityUpdated) {
            props.close()
            props.refreshCalendar()
        }
    }, [activityDeleted, activityUpdated])

    const deleteActivity = (activity: ActivityResponse) => {
        deleteActivityTrigger({
            id: activity.id || "",
            rootId: activity.rootId || "",
        })
    }

    const isScheduleButtonEnabled = () => {
        var textEntered = (activity?.textContentDetails != undefined && activity?.textContentDetails?.length != 0)
        let isChannelSelected = selectedChannels != undefined && selectedChannels?.length > 0

        if (textEntered && activity?.textContentDetails[0]?.textContent?.trim() != "")
            return isChannelSelected
        else {
            textEntered = false
        }

        let imageSelected = (selectedImages != undefined && selectedImages?.length != 0)

        return (textEntered || imageSelected) && isChannelSelected
    }

    const checkScheduleDate = () => {
        return dayjs().valueOf() < dayjs(activity.startDate).valueOf()
    }

    return (
        <>
            <div
                className="fixed  w-full lg:top-0 left-0 right-0 bottom-0 z-50 flex flex-col items-center max-lg:justify-end max-lg:mb-1 justify-center h-screen" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div ref={ref} className="rounded-[10px] w-full flex justify-center items-center">
                    <div className="w-full flex justify-center pr-[70px] pl-[70px]">
                        {activeSideModalStep !== sideModalSteps.editCreatives && activeSideModalStep !== sideModalSteps.editAudience && (
                            <div className={'bg-white text-black rounded-[5px] relative pb-[50px] '}>
                                {props.title && (<>
                                    <div className="p-3 flex items-center mb-1 w-full">
                                        <span className="text-black text-H6 mr-1 ml-1 cursor-pointer"
                                            onClick={() => {
                                                props.close()
                                            }}>
                                            <CloseOutlined />
                                        </span>
                                        <span className="text-black text-H4 ml-2">
                                            {props.title}
                                        </span>
                                    </div>
                                    <hr className="bg-border mx-1" />
                                </>)}
                                <div className={'p-5 w-full'}>
                                    {
                                        <div className={'flex justify-between w-full'}>
                                            <div className={''}>
                                                {activity.postCategory != ActivityType.Holiday &&
                                                    activity.postCategory != ActivityType.PaidGoogle &&
                                                    activity.postCategory != ActivityType.PaidMeta &&
                                                    <div className={'rounded-[5px] border-1 w-full'}>
                                                        <TextArea
                                                            placeholder="Write a caption"
                                                            className={'w-full border-0 border-b-1'}
                                                            value={activity?.textContentDetails?.filter(k => k.active).length ? activity.textContentDetails.filter(k => k.active)[0].textContent : ''}
                                                            onChange={(e) => {
                                                                let isGeneratedEdited = true;
                                                                const temp = activity?.textContentDetails?.map(k => {
                                                                    const newK = { ...k }
                                                                    if (!k.generated && k.active) {
                                                                        isGeneratedEdited = false;
                                                                        newK.textContent = e.target.value
                                                                    }
                                                                    return newK
                                                                })
                                                                if (isGeneratedEdited) {
                                                                    const tA = temp?.map(k => ({ ...k, active: false }));
                                                                    tA.push({ textContent: e.target.value, active: true, generated: false })
                                                                    onChange('textContentDetails', tA)
                                                                } else {
                                                                    onChange('textContentDetails', temp)
                                                                }
                                                            }}
                                                            style={{ minHeight: 200, resize: 'none', minWidth: '400px' }}
                                                        />
                                                        <div className={'w-full flex justify-end p-2'}>
                                                            <SecondaryButton
                                                                className="h-[30px] border-1 border-border radius-[6px] px-2"
                                                                icon={<SvgGenerateSimilarIcon />}
                                                                title={'Generate with AI'}
                                                                onClick={() => {
                                                                    setIsSideModalOpen(true);
                                                                    setActiveSideModalStep(sideModalSteps.generateCaptionWithAI);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>}
                                                {(activity.postCategory == ActivityType.PaidMeta || activity.postCategory == ActivityType.PaidGoogle) &&
                                                    <div className={'py-4 flex w-full'}>
                                                        <div className={'flex w-5/12 items-center'}>
                                                            <SvgTritoneColorCreativeIcon />
                                                            <span className={'ml-2 text-H7 text-black align-middle'}>Creatives</span>
                                                        </div>
                                                        <div className={'w-7/12'}>
                                                            <div className={'text-gray text-end cursor-pointer'}
                                                                onClick={() => {
                                                                    setIsSideModalOpen(true);
                                                                    setActiveSideModalStep(sideModalSteps.editCreatives);
                                                                }}>
                                                                <div className={'flex justify-end text-black items-center'}
                                                                    style={{ fontSize: "13px", fontWeight: 400, lineHeight: "180%", }}
                                                                >
                                                                    {(creatives?.shortHeadlines?.length ?? 0) +
                                                                        (creatives?.longHeadlines?.length ?? 0) +
                                                                        (creatives?.headlines?.length ?? 0) +
                                                                        (creatives?.description?.length ?? 0) +
                                                                        (creatives?.ctas?.length ?? 0)} Creatives
                                                                    <RightOutlined className={'ml-1'} /></div>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                <div className={'py-4 flex w-full'}>
                                                    <div className={'flex w-5/12 items-center'}>
                                                        {selectedChannels.length == 0 &&
                                                            <SvgChannel1 />
                                                        }
                                                        {selectedChannels.length > 0 &&
                                                            <SvgChannelSelected />
                                                        }
                                                        <text className={'ml-2 text-H7 text-black align-middle'}>Channel</text>
                                                    </div>
                                                    <div className={'w-7/12'}>
                                                        <div className={'text-gray justify-end text-end cursor-pointer flex flex-row '}
                                                            onClick={() => {
                                                                if (activity.postCategory != ActivityType.PaidGoogle) {
                                                                    setIsSideModalOpen(true);
                                                                    setActiveSideModalStep(sideModalSteps.addChannel);
                                                                }
                                                            }}>
                                                            {selectedChannels.length ? (
                                                                <div className="flex flex-row items-center justify-end">
                                                                    {
                                                                        selectedChannels?.map((selectedChannel, index) => selectedChannel != null && (

                                                                            <div className={'flex flex-row justify-center mr-2 text-black items-center'}
                                                                                style={{ fontSize: "13px", fontWeight: 400, lineHeight: "180%", }}
                                                                            >
                                                                                {selectedChannel.colorIcon}<span
                                                                                    className={'mr-1'}></span>{selectedChannel.label}
                                                                                {index != selectedChannels.length - 1 && <span>,</span>}
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    <RightOutlined className={'ml-1'} />
                                                                </div>
                                                            ) :
                                                                props.selectedActivity?.channels?.length ? (
                                                                    <div className="flex flex-row items-center justify-end">
                                                                        {
                                                                            props.selectedActivity?.channels?.map(selectedChannel => (
                                                                                <div className="flex flex-row">
                                                                                    {getChannelDetails(selectedChannel)?.colorIcon}
                                                                                    <span
                                                                                        className={'ml-1'}></span>
                                                                                    <div className={'flex flex-row items-center justify-center mr-2 text-black items-center'}
                                                                                        style={{ fontSize: "13px", fontWeight: 400, lineHeight: "180%", }}
                                                                                    >
                                                                                        {getChannelDetails(selectedChannel)?.label}
                                                                                    </div>
                                                                                    <RightOutlined
                                                                                        className={'ml-1'} />
                                                                                </div>
                                                                            ))
                                                                        }
                                                                        <RightOutlined className={'ml-1'} />
                                                                    </div>
                                                                )
                                                                    : (
                                                                        <span className="text-gray text-BodyMedium">Select a channel
                                                                            <RightOutlined className={'ml-1'} /></span>
                                                                    )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {(activity.postCategory == ActivityType.PaidMeta || activity.postCategory == ActivityType.PaidGoogle) &&
                                                    <div className={'py-4 flex w-full'}>
                                                        <div className={'flex w-5/12 items-center'}>
                                                            <SvgBudgetIcon />
                                                            <span className={'ml-2 text-H7 text-black'}>{activity.paidProperties !== undefined ? (activity.paidProperties.budgetType === "DAILY_BUDGET" ? "Daily Budget" : "Total Budget") : "Budget"}</span>
                                                        </div>
                                                        <div className={'w-7/12'}>
                                                            <div className={'text-gray text-end cursor-pointer'}>
                                                                <Popover
                                                                    content={<div className={'p-2'}>
                                                                        <div>
                                                                            <Form.Item label="Budget"
                                                                                tooltip={<>
                                                                                    <p>Converted to the currency in your ad account</p>
                                                                                    <br />
                                                                                    <p>Google Ads budgets are set on a daily basis; if you have specified a total budget for your campaign, it will be divided by the number of days in the campaign and allocated daily.</p>
                                                                                </>}>
                                                                                <InputNumber
                                                                                    addonBefore={getSymbolFromCurrency(activity.accountCurrency)}
                                                                                    placeholder="Enter Budget"
                                                                                    value={budget}
                                                                                    onChange={(val) => {
                                                                                        if (val) {
                                                                                            setBudget(val)
                                                                                        }
                                                                                    }} />
                                                                            </Form.Item>
                                                                        </div>
                                                                        <div className={'flex justify-end'}>
                                                                            <Button className={'mr-1'} onClick={() => {
                                                                                setBudgetPopoverIsOpen(false);
                                                                                setBudget(activity.budgetPriceInAccountCurrency);
                                                                            }}>Cancel</Button>
                                                                            <Button type={'primary'} onClick={() => {
                                                                                setBudgetPopoverIsOpen(false);
                                                                                setActivityField('budgetPriceInAccountCurrency', budget);
                                                                            }}>Save</Button>
                                                                        </div>
                                                                    </div>}
                                                                    title="Edit Budget"
                                                                    trigger="click"
                                                                    open={budgetPopoverIsOpen}
                                                                    onOpenChange={() => setBudgetPopoverIsOpen(true)}
                                                                    onVisibleChange={(status) => {
                                                                        setBudgetPopoverIsOpen(status);
                                                                    }}
                                                                >
                                                                    <div className={'flex justify-end text-black items-center'}
                                                                        style={{ fontSize: "13px", fontWeight: 400, lineHeight: "180%", }}
                                                                    >

                                                                        {activity.budgetPriceInAccountCurrency ? (
                                                                            <>
                                                                                <span
                                                                                    className={'ml-1'}></span>{activity.budgetPriceInAccountCurrency + getSymbolFromCurrency(activity.accountCurrency)}
                                                                                <RightOutlined
                                                                                    className={'ml-1'} /> </>
                                                                        ) : (
                                                                            <>
                                                                                Enter a Budget
                                                                                <RightOutlined
                                                                                    className={'ml-1'} /></>
                                                                        )}
                                                                    </div>
                                                                </Popover>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                {(activity.postCategory == ActivityType.PaidMeta || activity.postCategory == ActivityType.PaidGoogle) &&
                                                    <div className={'py-4 flex w-full'}>
                                                        <div className={'flex w-5/12 items-center'}>
                                                            <SvgPlacementIcon />
                                                            <span className={'ml-2 text-H7 text-black'}>Placement</span>
                                                        </div>
                                                        <div className={'w-7/12'}>
                                                            <div className={'text-gray text-end cursor-pointer'}
                                                                onClick={() => {
                                                                    setIsSideModalOpen(true);
                                                                    setActiveSideModalStep(sideModalSteps.editPlacements);
                                                                }}>
                                                                <div className={'flex justify-end text-black items-center'}
                                                                    style={{ fontSize: "13px", fontWeight: 400, lineHeight: "180%", }}
                                                                >AI
                                                                    Smart
                                                                    Placements
                                                                    <AISmartPlacementsIcon className={'ml-1'} />
                                                                    <RightOutlined className={'ml-1'} /></div>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                {(activity.postCategory == ActivityType.PaidMeta || activity.postCategory == ActivityType.PaidGoogle) &&
                                                    <div className={'py-4 flex w-full'}>
                                                        <div className={'flex w-5/12 items-center'}>
                                                            <SvgTritoneColorCreativeIcon />
                                                            <span className={'ml-2 text-H7 text-black'}>Audience</span>
                                                        </div>
                                                        <div className={'w-7/12'}>
                                                            <div className={'text-gray text-end cursor-pointer'}
                                                                onClick={() => {
                                                                    setIsSideModalOpen(true);
                                                                    setActiveSideModalStep(sideModalSteps.editAudience);
                                                                }}>
                                                                <div className={'flex justify-end text-black items-center'}
                                                                    style={{ fontSize: "13px", fontWeight: 400, lineHeight: "180%", }}
                                                                >
                                                                    {selectedAudience ? (
                                                                        <>
                                                                            <span
                                                                                className={'ml-1'}></span>{selectedAudience.name}

                                                                            <RightOutlined
                                                                                className={'ml-1'} /></>
                                                                    ) : (
                                                                        <>Select a audience <RightOutlined
                                                                            className={'ml-1'} /></>

                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                {(activity.postCategory == ActivityType.PaidMeta || activity.postCategory == ActivityType.PaidGoogle) &&
                                                    <div className={'py-4 flex w-full'}>
                                                        <div className={'flex w-5/12 items-center'}>
                                                            <SvgCompanyIcon />
                                                            <span className={'ml-2 text-H7 text-black'}>Company Name</span>
                                                        </div>
                                                        <div className={'w-7/12'}>
                                                            <div className={'text-gray text-end cursor-pointer'}>
                                                                <Popover
                                                                    content={<div className={'p-2'}>
                                                                        <div>
                                                                            <Form.Item label="Company Name"
                                                                                tooltip="This is a required field">
                                                                                <Input placeholder="companyName"
                                                                                    value={activity.companyName}
                                                                                    onChange={(e) => {
                                                                                        setCompanyName(e.target.value)
                                                                                        //@ts-ignore
                                                                                        setActivity(k => ({ ...k, companyName: e.target.value }))
                                                                                    }} />
                                                                            </Form.Item>
                                                                        </div>
                                                                        <div className={'flex justify-end'}>
                                                                            <Button className={'mr-1'} onClick={() => {
                                                                                setCompanyNamePopoverIsOpen(false);
                                                                                setCompanyName(activity.companyName ?? '');
                                                                            }}>Cancel</Button>
                                                                            <Button type={'primary'} onClick={() => {
                                                                                setCompanyNamePopoverIsOpen(false);
                                                                                setActivityField('companyName', companyName);
                                                                            }}>Save</Button>
                                                                        </div>
                                                                    </div>}
                                                                    title="Edit Company Name"
                                                                    trigger="click"
                                                                    open={companyNamePopoverIsOpen}
                                                                    onOpenChange={() => setCompanyNamePopoverIsOpen(true)}
                                                                    onVisibleChange={(status) => {
                                                                        setCompanyNamePopoverIsOpen(status);
                                                                    }}
                                                                >
                                                                    <div className={'flex justify-end text-black items-center'}
                                                                        style={{ fontSize: "13px", fontWeight: 400, lineHeight: "180%", }}
                                                                    >
                                                                        {activity.companyName ? (
                                                                            <div
                                                                                className={'flex justify-end items-center'}>
                                                                                <span
                                                                                    className={'ml-1'}></span>{activity.companyName}<RightOutlined
                                                                                    className={'ml-1'} /></div>
                                                                        ) : (
                                                                            <>Enter a Company Name <RightOutlined
                                                                                className={'ml-1'} /></>
                                                                        )}
                                                                    </div>
                                                                </Popover>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                {(activity.postCategory == ActivityType.PaidMeta || activity.postCategory == ActivityType.PaidGoogle) &&
                                                    <div className={'py-4 flex w-full'}>
                                                        <div className={'flex w-5/12 items-center'}>
                                                            <SvgTargetUrlIcon />
                                                            <span className={'ml-2 text-H7 text-black'}>Target URL</span>
                                                        </div>
                                                        <div className={'w-7/12'}>
                                                            <div className={'text-gray text-end cursor-pointer'}>
                                                                <Popover
                                                                    content={<div className={'p-2'}>
                                                                        <div>
                                                                            <Form.Item label="Target URL"
                                                                                tooltip="This is a required field">
                                                                                <Input placeholder="url"
                                                                                    value={targetURL}
                                                                                    onChange={(e) => setTargetURL(e.target.value)} />
                                                                            </Form.Item>
                                                                        </div>
                                                                        <div className={'flex justify-end'}>
                                                                            <Button className={'mr-1'} onClick={() => {
                                                                                setTargetURLPopoverIsOpen(false);
                                                                                setTargetURL(activity.targetURL ? new URL(activity.targetURL).host : '');
                                                                            }}>Cancel</Button>
                                                                            <Button type={'primary'} disabled={!testReg.test(`https://${targetURL}`)} onClick={() => {
                                                                                setTargetURLPopoverIsOpen(false);
                                                                                setActivityField('targetURL', targetURL);
                                                                            }}>Save</Button>
                                                                        </div>
                                                                    </div>}
                                                                    title="Edit Target URL"
                                                                    trigger="click"
                                                                    open={targetURLPopoverIsOpen}
                                                                    onOpenChange={() => setTargetURLPopoverIsOpen(true)}
                                                                    onVisibleChange={(status) => {
                                                                        setTargetURLPopoverIsOpen(status);
                                                                    }}
                                                                >
                                                                    <div className={'flex justify-end text-black items-center'}
                                                                        style={{ fontSize: "13px", fontWeight: 400, lineHeight: "180%", }}
                                                                    >
                                                                        {targetURL ? (
                                                                            <div
                                                                                className={'flex justify-end items-center'}>
                                                                                <span
                                                                                    className={'ml-1'}></span>{targetURL}
                                                                                <RightOutlined className={'ml-1'} /></div>
                                                                        ) : (
                                                                            <>Select a target URL <RightOutlined
                                                                                className={'ml-1'} /></>
                                                                        )}
                                                                    </div>
                                                                </Popover>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                {(activity.postCategory == ActivityType.PaidGoogle || activity.postCategory == ActivityType.PaidMeta) &&
                                                    <div className={'py-4 flex'}>
                                                        <div className={'flex w-5/12 items-center'}>
                                                            <SvgCalendar />
                                                            <span className={'ml-2 text-H7 text-black'}>Timeline</span>
                                                        </div>
                                                        <div className={'w-7/12 flex justify-end'}>
                                                            <DatePicker
                                                                onChange={(val) => {
                                                                    onChange('startDate', dayjs(val).valueOf())
                                                                    setActivity(a => ({ ...a, "endDate": dayjs(val).add(Number(activity.period), 'd').valueOf() }))
                                                                }}
                                                                variant="borderless"
                                                                value={dayjs(activity?.startDate ?? moment().toNow())}
                                                                minDate={dayjs(new Date())}
                                                                className={' w-[220px] mr-1 bg-[#F3F3F3] text-black rounded-md'}
                                                                style={{ fontSize: "13px", fontWeight: 400, lineHeight: "180%", }}
                                                                format="DD dddd MMM HH:mm A"
                                                                showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm') }}
                                                                allowClear={false}
                                                            />
                                                            <Select className="w-[120px] border-0 bg-[#F3F3F3] text-black rounded-md"
                                                                style={{ fontSize: "13px", fontWeight: 400, lineHeight: "180%", }}
                                                                variant="borderless"
                                                                value={activity.period}
                                                                onSelect={e => {
                                                                    var endDate = dayjs(activity.startDate).add(Number(e), 'd').valueOf()
                                                                    // @ts-ignore
                                                                    setActivity(a => ({ ...a, "period": Number(e), "endDate": endDate }))
                                                                }}
                                                                options={[
                                                                    { value: 7, label: '7 Days' },
                                                                    { value: 6, label: '6 Days' },
                                                                    { value: 5, label: '5 Days' },
                                                                    { value: 4, label: '4 Days' },
                                                                    { value: 3, label: '3 Days' },
                                                                    { value: 2, label: '2 Days' },
                                                                    { value: 1, label: '1 Days' },
                                                                ]} />
                                                        </div>
                                                    </div>}
                                                {props.selectedActivity != undefined &&
                                                    <div className={'py-4 flex w-full'}>
                                                        <div className={'flex w-5/12 items-center'}>
                                                            <SvgStatuIcon />
                                                            <span className={'ml-2 text-H7 text-black'}>Status</span>
                                                        </div>
                                                        <div className={'w-7/12'}>
                                                            <div className={'flex justify-end text-black items-center'}
                                                                style={{ fontSize: "13px", fontWeight: 400, lineHeight: "180%", }}
                                                            >
                                                                {activity.selectedStatus ? (
                                                                    <Popover
                                                                        content={<div>
                                                                            <a onClick={() => setStatusPopoverIsOpen(false)}>Close</a>
                                                                        </div>}
                                                                        title="Title"
                                                                        trigger="click"
                                                                        open={statusPopoverIsOpen}
                                                                        onOpenChange={() => setStatusPopoverIsOpen(true)}
                                                                        onVisibleChange={(status) => {
                                                                            setStatusPopoverIsOpen(status);
                                                                        }}
                                                                    >
                                                                        <><SvgGenerateSimilarIcon /><span
                                                                            className={'ml-1'}></span>{reformatText(activity.selectedStatus)}<RightOutlined
                                                                                className={'ml-1'} /></>
                                                                    </Popover>
                                                                ) :

                                                                    props.selectedActivity?.status ? (
                                                                        <div className="flex flex-row items-center justify-end">
                                                                            <div style={{
                                                                                borderRadius: 1, marginRight: 5, width: 10, height: 10,
                                                                                backgroundColor: getColorByStatus(props.selectedActivity?.status)?.status ?? ""
                                                                            }}></div>
                                                                            <span
                                                                                className={'ml-1'}>{reformatText(props.selectedActivity?.status)}</span>
                                                                        </div>
                                                                    )
                                                                        :
                                                                        (
                                                                            <>Select a status <RightOutlined className={'ml-1'} /></>
                                                                        )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {activity.postCategory != ActivityType.Holiday &&
                                                    activity.postCategory != ActivityType.PaidGoogle &&
                                                    activity.postCategory != ActivityType.PaidMeta &&
                                                    <div className={'py-4 flex items-center'}>
                                                        <div className={'flex w-5/12 items-center'}>
                                                            <SvgCalendar />
                                                            <span className={'ml-2 text-H7 text-black'}>Schedule Post</span>
                                                        </div>
                                                        <div className={'w-7/12 flex justify-end'}>
                                                            <DatePicker
                                                                onChange={(val) => {
                                                                    onChange('startDate', dayjs(val).valueOf())
                                                                    onChange('endDate', dayjs(val).valueOf())
                                                                }}
                                                                value={dayjs(activity?.startDate ?? moment().toNow())}
                                                                minDate={dayjs(new Date())}
                                                                className={'border-0 bg-[#F3F3F3]'}
                                                                format="DD dddd MMM HH:mm A"
                                                                showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm') }}
                                                                allowClear={false}
                                                            />
                                                        </div>
                                                    </div>}

                                            </div>
                                            <div className={'min-w-[300px]  ml-2'}>
                                                {(activity.postCategory != ActivityType.Holiday &&
                                                    activity.postCategory != ActivityType.PaidGoogle &&
                                                    activity.postCategory != ActivityType.PaidMeta) ?
                                                    <>{selectedImages.length ? (
                                                        <div className={'ml-2 p-1'}>
                                                            <OrganicImages
                                                                images={selectedImages}
                                                                deleteItemImage={(id) => {
                                                                    setGeneratedAIImages(prevState => prevState.filter(AIImg => AIImg.generated_content !== id));
                                                                    setSelectedProductImages(prevState => prevState.filter(imgID => imgID !== id))
                                                                    setSelectedImages(images => images.filter(k => k.imageFileId !== id))
                                                                }}
                                                                setActiveImage={(id) => {
                                                                    setSelectedImages(prevState => (prevState.map(k => ({ ...k, active: k.imageFileId === id }))))
                                                                }}
                                                                openSide={(type) => {
                                                                    setIsSideModalOpen(true)
                                                                    if (type === 'custom') {
                                                                        setActiveSideModalStep(sideModalSteps.addCustomImage)
                                                                    } else {
                                                                        setActiveSideModalStep(sideModalSteps.addAIImage)
                                                                    }
                                                                }} />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={'ml-2 p-10 border-1 rounded-[5px] w-full min-h-[300px] flex items-center justify-center'}>
                                                            <div className={'flex flex-col items-center'}>
                                                                <SecondaryButton
                                                                    noFill
                                                                    className="h-[30px] border-1 border-border radius-[6px] px-2"
                                                                    icon={<FileImageOutlined />}
                                                                    title={'Add Image'}
                                                                    onClick={() => {
                                                                        //props.onGenerateWithAiClicked && props.onGenerateWithAiClicked()
                                                                        setIsSideModalOpen(true)
                                                                        setActiveSideModalStep(sideModalSteps.addCustomImage)
                                                                    }}
                                                                />
                                                                <div className={'text-gray text-center text-sm'}>or
                                                                </div>
                                                                <SecondaryButton
                                                                    className="h-[30px] border-1 border-border radius-[6px] px-2"
                                                                    icon={<SvgGenerateSimilarIcon />}
                                                                    title={'Generate with AI'}
                                                                    onClick={() => {
                                                                        setIsSideModalOpen(true)
                                                                        setActiveSideModalStep(sideModalSteps.addAIImage)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}</> : <>
                                                        <div className={'ml-2 p-4 mb-2 border-1 rounded-[5px] max-w-[350px]'}>
                                                            <div className={'flex items-center justify-between'}>
                                                                <div
                                                                    className={'w-[28px] h-[28px] border-1 radius-[4px]  '}
                                                                    style={{ borderColor: "#1A8CF5" }}>
                                                                    <AISmartPlacementsBlueIcon /></div>
                                                                <div className={'text-BodySmall ml-2'}>
                                                                    <span className={'font-bold'}>You’ve  {(creatives?.shortHeadlines?.length ?? 0) +
                                                                        (creatives?.longHeadlines?.length ?? 0) +
                                                                        (creatives?.headlines?.length ?? 0) +
                                                                        (creatives?.description?.length ?? 0) +
                                                                        (creatives?.ctas?.length ?? 0) +
                                                                        (activity?.imageContentDetails?.length ?? 0)}  ads planned within this active. </span>
                                                                    Please review your creatives and activity
                                                                    information.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {activity.postCategory == ActivityType.PaidMeta &&
                                                            <div
                                                                className={'ml-2 p-4 mb-2 border-1 rounded-[5px] max-w-[350px]'}>
                                                                {downloadedImages[0] ?
                                                                    <img src={downloadedImages[0].preview}
                                                                        alt={downloadedImages[0].name} /> :
                                                                    <CampaignPreview activity={activity} />}
                                                            </div>}
                                                        {activity.postCategory == ActivityType.PaidGoogle && <div
                                                            className={'ml-2 p-4 mb-2 border-1 rounded-[5px] max-w-[350px]'}>
                                                            <Tabs defaultActiveKey="1" items={googleAdsTabs} />
                                                        </div>}
                                                    </>}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className={'absolute bottom-0 w-full'}>
                                    <hr className="bg-border mx-1" />
                                    <div className={'w-full flex justify-between px-3 py-3'}>
                                        {props.selectedActivity != undefined &&
                                            <Button
                                                className="border-1 border-[#EAEBE6] text-red flex flex-row justify-center text-H7"
                                                onClick={() => {
                                                    deleteActivity(activity)
                                                }}
                                                icon={<SvgDelete fill="#FF5555" />}
                                            > Delete Activity</Button>
                                        }
                                        {
                                            props.selectedActivity == undefined && <>
                                                <Button
                                                    onClick={() => {
                                                        if (!checkScheduleDate()) {
                                                            notification.error({ message: 'Please check the schedule date.' })
                                                            return;
                                                        }
                                                        dispatch(toggleLoading(true))
                                                        dispatch(setNewActivity({
                                                            totalBudget: activity?.totalBudget,
                                                            audience: activity.audience,
                                                            productAboutUrl: activity.targetURL ? `https://${activity.targetURL}` : undefined,
                                                            channels: selectedChannels,
                                                            scheduleDate: String(activity.startDate),
                                                            startDate: String(activity.startDate),
                                                            endDate: String(activity.endDate),
                                                            generatedVisuals: selectedImages,
                                                            imageContentDetails: selectedImages,
                                                            textContentDetails: activity.textContentDetails,
                                                        } as ActivityRequest));
                                                        save({
                                                            activityRequest: {
                                                                totalBudget: activity?.totalBudget,
                                                                audience: activity.audience,
                                                                productAboutUrl: activity.targetURL ? `https://${activity.targetURL}` : undefined,
                                                                status: 'Draft',
                                                                channels: selectedChannels.map((channel) => channel.key),
                                                                scheduleDate: String(activity.startDate),
                                                                startDate: String(activity.startDate),
                                                                endDate: String(activity.endDate),
                                                                generatedVisuals: selectedImages,
                                                                brandId: lastSelectedBrandId,
                                                                imageContentDetails: selectedImages,
                                                                textContentDetails: activity.textContentDetails,
                                                            } as ActivityRequest
                                                        }).then(a => {
                                                            props.refreshCalendar();
                                                            props.close();
                                                            dispatch(toggleLoading(false))
                                                        })
                                                    }}
                                                >Save Draft</Button>
                                                <Button
                                                    type="primary"
                                                    onClick={() => {
                                                        if (!checkScheduleDate()) {
                                                            notification.error({ message: 'Please check the schedule date.' })
                                                            return;
                                                        }
                                                        dispatch(toggleLoading(true))
                                                        dispatch(setNewActivity({
                                                            totalBudget: activity?.totalBudget,
                                                            audience: activity.audience,
                                                            productAboutUrl: activity.targetURL ? `https://${activity.targetURL}` : undefined,
                                                            channels: selectedChannels.map((channel) => channel),
                                                            scheduleDate: String(activity.startDate),
                                                            startDate: String(activity.startDate),
                                                            endDate: String(activity.endDate),
                                                            generatedVisuals: selectedImages,
                                                            imageContentDetails: selectedImages,
                                                            textContentDetails: activity.textContentDetails,
                                                        } as ActivityRequest));

                                                        save({
                                                            activityRequest: {
                                                                totalBudget: activity?.totalBudget,
                                                                audience: activity.audience,
                                                                productAboutUrl: activity.targetURL ? `https://${activity.targetURL}` : undefined,
                                                                status: 'Scheduled',
                                                                channels: selectedChannels.map((channel) => channel.key),
                                                                scheduleDate: String(activity.startDate),
                                                                startDate: String(activity.startDate),
                                                                endDate: String(activity.endDate),
                                                                generatedVisuals: selectedImages,
                                                                brandId: lastSelectedBrandId,
                                                                textContentDetails: activity.textContentDetails,
                                                                imageContentDetails: selectedImages
                                                            } as ActivityRequest
                                                        }).then(a => {
                                                            props.refreshCalendar();
                                                            props.close();
                                                            dispatch(toggleLoading(false))
                                                        })
                                                    }}
                                                    className={'bg-green'}
                                                    icon={<ClockCircleOutlined />}
                                                    disabled={!isScheduleButtonEnabled()}
                                                >
                                                    Schedule Post
                                                </Button>
                                            </>}
                                        {
                                            props.selectedActivity != undefined && <>
                                                <div>
                                                    <Button
                                                        className="mr-2 text-H7"
                                                        onClick={() => {
                                                            if (!checkScheduleDate()) {
                                                                notification.error({ message: 'Please check the schedule date.' })
                                                                return;
                                                            }
                                                            dispatch(toggleLoading(true))
                                                            dispatch(setNewActivity({
                                                                totalBudget: activity?.totalBudget,
                                                                audience: activity.audience,
                                                                id: activity.id,
                                                                status: 'Draft',
                                                                channels: selectedChannels.map((channel) => channel?.key).filter(k => k !== ("META_ADS") || k !== "GOOGLE_ADS"),
                                                                scheduleDate: String(activity.startDate),
                                                                startDate: String(activity.startDate),
                                                                endDate: String(activity.endDate),
                                                                generatedVisuals: selectedImages,
                                                                imageContentDetails: selectedImages,
                                                                textContentDetails: activity.textContentDetails,
                                                                credit: activity.credit,
                                                                creatives: activity.creatives,
                                                                campaignObjectiveType: activity.campaignObjectiveType,
                                                                eventType: activity.eventType,
                                                                campaignType: activity.campaignType,
                                                                productAbout: activity.productAbout,
                                                                postCategory: activity.postCategory,
                                                                adType: activity.adType,
                                                                budgetPrice: activity.budgetPrice,
                                                                budgetPriceInAccountCurrency: activity.budgetPriceInAccountCurrency,
                                                                budgetDetails: activity.budgetDetails,
                                                                objective: activity.objective,
                                                                priority: activity.priority,
                                                                companyName: activity.companyName,
                                                                actionRequired: activity.actionRequired,
                                                                productAboutUrl: activity.targetURL ? `https://${activity.targetURL}` : undefined,
                                                            } as ActivityRequest));

                                                            updateActivityTrigger({
                                                                activityRequest: {
                                                                    totalBudget: activity?.totalBudget,
                                                                    audience: activity.audience,
                                                                    id: activity.id,
                                                                    rootId: activity.rootId,
                                                                    status: 'Draft',
                                                                    channels: selectedChannels.map((channel) => channel?.key).filter(k => k !== ("META_ADS") || k !== "GOOGLE_ADS"),
                                                                    scheduleDate: String(activity.startDate),
                                                                    startDate: String(activity.startDate),
                                                                    endDate: String(activity.endDate),
                                                                    generatedVisuals: selectedImages,
                                                                    brandId: lastSelectedBrandId,
                                                                    imageContentDetails: selectedImages,
                                                                    textContentDetails: activity.textContentDetails,
                                                                    credit: activity.credit,
                                                                    creatives: activity.creatives,
                                                                    campaignObjectiveType: activity.campaignObjectiveType,
                                                                    eventType: activity.eventType,
                                                                    campaignType: activity.campaignType,
                                                                    productAbout: activity.productAbout,
                                                                    postCategory: activity.postCategory,
                                                                    adType: activity.adType,
                                                                    budgetPrice: activity.budgetPrice,
                                                                    budgetPriceInAccountCurrency: activity.budgetPriceInAccountCurrency,
                                                                    budgetDetails: activity.budgetDetails,
                                                                    objective: activity.objective,
                                                                    priority: activity.priority,
                                                                    companyName: activity.companyName,
                                                                    actionRequired: activity.actionRequired,
                                                                    productAboutUrl: activity.targetURL ? `https://${activity.targetURL}` : undefined,
                                                                } as ActivityRequest
                                                            }).then(() => {
                                                                props.refreshCalendar();
                                                                props.close();
                                                                dispatch(toggleLoading(false))
                                                            })
                                                        }}
                                                    >Save Draft</Button>
                                                    <Button
                                                        onClick={() => {
                                                            if (!checkScheduleDate()) {
                                                                notification.error({ message: 'Please check the schedule date.' })
                                                                return;
                                                            }
                                                            dispatch(toggleLoading(true))
                                                            dispatch(setNewActivity({
                                                                totalBudget: activity?.totalBudget,
                                                                audience: activity.audience,
                                                                id: activity.id,
                                                                status: 'Scheduled',
                                                                channels: selectedChannels.map((channel) => channel.key),
                                                                scheduleDate: String(activity.startDate),
                                                                startDate: String(activity.startDate),
                                                                endDate: String(activity.endDate),
                                                                generatedVisuals: selectedImages,
                                                                imageContentDetails: selectedImages,
                                                                textContentDetails: activity.textContentDetails,
                                                                credit: activity.credit,
                                                                creatives: activity.creatives,
                                                                campaignObjectiveType: activity.campaignObjectiveType,
                                                                eventType: activity.eventType,
                                                                campaignType: activity.campaignType,
                                                                productAbout: activity.productAbout,
                                                                postCategory: activity.postCategory,
                                                                adType: activity.adType,
                                                                budgetPrice: activity.budgetPrice,
                                                                budgetPriceInAccountCurrency: activity.budgetPriceInAccountCurrency,
                                                                budgetDetails: activity.budgetDetails,
                                                                objective: activity.objective,
                                                                priority: activity.priority,
                                                                companyName: activity.companyName,
                                                                actionRequired: activity.actionRequired,
                                                                productAboutUrl: activity.targetURL ? `https://${activity.targetURL}` : undefined,
                                                            } as ActivityRequest));

                                                            updateActivityTrigger({
                                                                activityRequest: {
                                                                    totalBudget: activity?.totalBudget,
                                                                    audience: activity.audience,
                                                                    status: 'Scheduled',
                                                                    rootId: activity.rootId,
                                                                    id: activity.id,
                                                                    channels: selectedChannels.map((channel) => channel.key),
                                                                    scheduleDate: String(activity.startDate),
                                                                    startDate: String(activity.startDate),
                                                                    endDate: String(activity.endDate),
                                                                    generatedVisuals: selectedImages,
                                                                    brandId: lastSelectedBrandId,
                                                                    textContentDetails: activity.textContentDetails,
                                                                    imageContentDetails: selectedImages,
                                                                    credit: activity.credit,
                                                                    creatives: activity.creatives,
                                                                    campaignObjectiveType: activity.campaignObjectiveType,
                                                                    eventType: activity.eventType,
                                                                    campaignType: activity.campaignType,
                                                                    productAbout: activity.productAbout,
                                                                    postCategory: activity.postCategory,
                                                                    adType: activity.adType,
                                                                    budgetPrice: activity.budgetPrice,
                                                                    budgetPriceInAccountCurrency: activity.budgetPriceInAccountCurrency,
                                                                    budgetDetails: activity.budgetDetails,
                                                                    objective: activity.objective,
                                                                    priority: activity.priority,
                                                                    companyName: activity.companyName,
                                                                    actionRequired: activity.actionRequired,
                                                                    productAboutUrl: activity.targetURL ? `https://${activity.targetURL}` : undefined,
                                                                } as ActivityRequest
                                                            }).then(() => {
                                                                props.refreshCalendar();
                                                                dispatch(toggleLoading(false))
                                                                props.close();
                                                            })
                                                        }}
                                                        className={'bg-[#16C26C] text-H7 text-white'}
                                                        icon={<ClockCircleOutlined />}
                                                        disabled={!selectedChannels?.length || (
                                                            props.selectedActivity.status == "Scheduled" && (JSON.stringify({ ...compareActivity }) === JSON.stringify({ ...activity }))
                                                        )
                                                        }
                                                    >
                                                        {
                                                            props.selectedActivity.status == "Scheduled" ? "Save & Schedule Post" : "Approve & Schedule Post"
                                                        }

                                                    </Button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                        {isSideModalOpen ? <div
                            className={`bg-white text-black ml-2 ${(activeSideModalStep === sideModalSteps.editCreatives) && !isSubSideOpen ? '' : activeSideModalStep === sideModalSteps.editAudience ? ' w-2/3' : ' w-1/3'} rounded-[5px]`}>
                            {activeSideModalStep === sideModalSteps.addCustomImage && (
                                <AddCustomImageStep
                                    title={sideModals[activeSideModalStep].title}
                                    description={sideModals[activeSideModalStep].description}
                                    closeSideModal={closeSideModal}
                                    selectedImages={selectedProductImages}
                                    setSelectedProductImages={k => {
                                        setSelectedProductImages([...k, ...selectedProductImages])
                                    }}
                                />
                            )}
                            {activeSideModalStep === sideModalSteps.addChannel && (
                                <AddChannelStep
                                    title={sideModals[activeSideModalStep].title}
                                    description={sideModals[activeSideModalStep].description}
                                    closeSideModal={closeSideModal}
                                    setSelectedChannel={(ch) => {
                                        if (selectedChannels.filter(k => k.key == ch.key).length) {
                                            setSelectedChannels(k => k.filter(l => l.key !== ch.key));
                                        } else {
                                            setSelectedChannels(k => [...k, ch])
                                        }
                                    }}
                                    activity={activity}
                                    selectedChannels={selectedChannels}
                                />
                            )}
                            {activeSideModalStep === sideModalSteps.generateCaptionWithAI && (
                                <GenerateCaptionWithAI
                                    title={sideModals[activeSideModalStep].title}
                                    description={sideModals[activeSideModalStep].description}
                                    closeSideModal={closeSideModal}
                                    setGeneratedAIText={(val) => {
                                        if (val !== undefined) {
                                            onChange('textContentDetails', [
                                                ...activity.textContentDetails.map(k => ({ ...k, active: false })),
                                                { textContent: val.generated_content, active: true, generated: true, contentId: val.id.content_id, revisionId: val.id.revision_id }
                                            ])
                                        }
                                    }}
                                    onCreditError={props.onCreditError}
                                />
                            )}
                            {activeSideModalStep === sideModalSteps.addAIImage && (
                                <GenerateImageWithAI
                                    title={sideModals[activeSideModalStep].title}
                                    description={sideModals[activeSideModalStep].description}
                                    closeSideModal={closeSideModal}
                                    setGeneratedAIImages={setGeneratedAIImages}
                                    onCreditError={props.onCreditError}
                                />
                            )}
                            {activeSideModalStep === sideModalSteps.editPlacements && (
                                <EditPlacementStep
                                    title={sideModals[activeSideModalStep].title}
                                    description={sideModals[activeSideModalStep].description}
                                    closeSideModal={closeSideModal}
                                />
                            )}
                            {activeSideModalStep === sideModalSteps.editCreatives && (
                                <EditCreativesStep
                                    title={sideModals[activeSideModalStep].title}
                                    description={sideModals[activeSideModalStep].description}
                                    closeSideModal={closeSideModal}
                                    activity={activity}
                                    isSubSideOpen={setIsSubSideOpen}
                                    creativesChanged={activity => {
                                        setActivity(activity)
                                    }}
                                    setImageContentDetails={(imageContentDetails) => {
                                        setSelectedImages(imageContentDetails);
                                    }}
                                    onCreditError={props.onCreditError}
                                />
                            )}
                            {activeSideModalStep === sideModalSteps.editAudience && (
                                <EditAudienceStep
                                    title={sideModals[activeSideModalStep].title}
                                    description={sideModals[activeSideModalStep].description}
                                    closeSideModal={closeSideModal}
                                    selectedAudience={selectedAudience}
                                    setSelectedAudience={(audience) => {
                                        setActivityField('audience', audience);
                                        setSelectedAudience(audience)
                                    }}
                                    selectedBrandId={selectedBrandId}
                                />
                            )}
                        </div> : ''}
                    </div>
                </div >
            </div >
        </>
    );
}

export default AIActivityModal;
