import * as React from "react";
import type { SVGProps } from "react";
const SvgBrandhubCollapsedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {/* <rect width="40" height="40" fill="#121315" /> */}
    <path
      d="M13.125 28.25C12.7469 28.25 12.4232 28.1154 12.1539 27.8461C11.8846 27.5768 11.75 27.2531 11.75 26.875V16.9063C11.75 16.5281 11.8846 16.2044 12.1539 15.9352C12.4232 15.6659 12.7469 15.5313 13.125 15.5313H15.5313V13.125C15.5313 12.7469 15.6659 12.4232 15.9352 12.1539C16.2044 11.8846 16.5281 11.75 16.9063 11.75H23.0938C23.4719 11.75 23.7956 11.8846 24.0648 12.1539C24.3341 12.4232 24.4688 12.7469 24.4688 13.125V19.3125H26.875C27.2531 19.3125 27.5768 19.4471 27.8461 19.7164C28.1154 19.9857 28.25 20.3094 28.25 20.6875V26.875C28.25 27.2531 28.1154 27.5768 27.8461 27.8461C27.5768 28.1154 27.2531 28.25 26.875 28.25H21.2146V24.4688H18.7854V28.25H13.125ZM13.125 26.875H15.5313V24.4688H13.125V26.875ZM13.125 23.0938H15.5313V20.6875H13.125V23.0938ZM13.125 19.3125H15.5313V16.9063H13.125V19.3125ZM16.9063 23.0938H19.3125V20.6875H16.9063V23.0938ZM16.9063 19.3125H19.3125V16.9063H16.9063V19.3125ZM16.9063 15.5313H19.3125V13.125H16.9063V15.5313ZM20.6875 23.0938H23.0938V20.6875H20.6875V23.0938ZM20.6875 19.3125H23.0938V16.9063H20.6875V19.3125ZM20.6875 15.5313H23.0938V13.125H20.6875V15.5313ZM24.4688 26.875H26.875V24.4688H24.4688V26.875ZM24.4688 23.0938H26.875V20.6875H24.4688V23.0938Z"
      fill={props.fill ?? "#8E8E8E"}
    />
  </svg>
);
export default SvgBrandhubCollapsedIcon;
