import * as React from "react";
import type { SVGProps } from "react";
const SvgTritoneColorCreativeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect opacity="0.1" width="28" height="28" rx="4" fill="#FF5555"/>
  <path d="M18.2048 14.6475C17.5998 15.1932 16.8149 15.4968 16.0001 15.5001C15.8188 15.5001 15.6421 15.4835 15.4694 15.4548C15.4514 15.4388 15.4301 15.4275 15.4121 15.4115C16.0133 15.9098 16.4972 16.5346 16.8293 17.2413C17.1614 17.948 17.3335 18.7193 17.3334 19.5001C17.3334 19.6769 17.2632 19.8465 17.1382 19.9715C17.0132 20.0966 16.8436 20.1668 16.6668 20.1668H20.6668C20.8436 20.1668 21.0132 20.0966 21.1382 19.9715C21.2632 19.8465 21.3334 19.6769 21.3334 19.5001C21.3332 18.4762 21.0379 17.474 20.4831 16.6135C19.9283 15.7529 19.1373 15.0704 18.2048 14.6475Z" fill="#FF5555"/>
  <path opacity="0.2" d="M11.9993 15.4997C11.1153 15.4997 10.2674 15.1485 9.64233 14.5234C9.01721 13.8982 8.66602 13.0504 8.66602 12.1663C8.66602 11.2823 9.01721 10.4344 9.64233 9.80932C10.2674 9.1842 11.1153 8.83301 11.9993 8.83301C12.8834 8.83301 13.7312 9.1842 14.3564 9.80932C14.9815 10.4344 15.3327 11.2823 15.3327 12.1663C15.3327 13.0504 14.9815 13.8982 14.3564 14.5234C13.7312 15.1485 12.8834 15.4997 11.9993 15.4997Z" fill="#FF5555"/>
  <path opacity="0.5" d="M14.204 14.6475C13.5991 15.1932 12.8141 15.4968 11.9993 15.5001C11.1842 15.498 10.3986 15.1948 9.79335 14.6488C8.86129 15.0718 8.07073 15.7542 7.51616 16.6145C6.96158 17.4748 6.66645 18.4766 6.66602 19.5001C6.66602 19.6769 6.73625 19.8465 6.86128 19.9715C6.9863 20.0966 7.15587 20.1668 7.33268 20.1668H16.666C16.8428 20.1668 17.0124 20.0966 17.1374 19.9715C17.2624 19.8465 17.3327 19.6769 17.3327 19.5001C17.3324 18.4762 17.0372 17.474 16.4823 16.6135C15.9275 15.7529 15.1365 15.0704 14.204 14.6475Z" fill="#FF5555"/>
  <path opacity="0.5" d="M16 8.83301C15.2467 8.83301 14.5587 9.09301 14 9.51501C14.2318 9.69093 14.4396 9.89646 14.618 10.1263C14.7737 10.3229 14.9065 10.5365 15.014 10.763C15.042 10.8237 15.0707 10.8837 15.0953 10.9463C15.16 11.107 15.212 11.273 15.2507 11.4463C15.2593 11.4863 15.2653 11.5283 15.2733 11.5697C15.3087 11.763 15.3333 11.9617 15.3333 12.1663C15.3333 12.3763 15.3093 12.5797 15.2713 12.7777C15.2653 12.807 15.2627 12.8377 15.256 12.8663C15.2184 13.0321 15.1678 13.1946 15.1047 13.3523C15.0807 13.415 15.0573 13.4777 15.0293 13.539C14.9733 13.659 14.9093 13.7737 14.8407 13.8857C14.6673 14.1717 14.4533 14.4257 14.204 14.6477C14.6647 14.857 15.0907 15.1283 15.4693 15.4543C15.6427 15.483 15.8187 15.4997 16 15.4997C16.8841 15.4997 17.7319 15.1485 18.357 14.5234C18.9821 13.8982 19.3333 13.0504 19.3333 12.1663C19.3333 11.2823 18.9821 10.4344 18.357 9.80932C17.7319 9.1842 16.8841 8.83301 16 8.83301Z" fill="#FF5555"/>
  </svg>
);
export default SvgTritoneColorCreativeIcon;
