import {
    BookFilled, FacebookFilled, InstagramFilled, LinkedinFilled,
    MailFilled, TwitterSquareFilled
} from "@ant-design/icons";
import { Button } from "antd";
import { setNewContent } from "../../../../store/contentSlice";
import { ContentType } from "../../../../constants/ContentType";
import { ContentWizardView } from "../../../../models/ContentWizardView";
import { Mediums } from "../../../../constants/Mediums";
import moment from "moment/moment";
import { INewContent } from "../../../../models/NewContent";
import { useAppSelector } from "../../../../helpers/hooks";
import { useDispatch } from "react-redux";
import { AudienceResponse } from "../../../../services/AudiencetApi";
import ContentDetails from "../../../../pages/NewContentWizard/ContentDetails/ContentDetails";
import { useEffect, useState } from "react";
import { FileProps, useLazyFindById4Query } from "../../../../services/BrandApi";
import LoopDetails from "../../../../pages/LoopDetails/LoopDetails";
import { BlogColor, FacebookColor, InstagramColor, LinkedinColor, PinterestColor, TwitterColor } from "../../../../assets/IconComponents";

const channels = [
    { name: 'Twitter', title: 'X (Twitter) Post', icon: <TwitterColor />, optionAt: 0 },
    { name: 'Twitter', title: 'X (Twitter) Thread', icon: <TwitterColor />, optionAt: 1 },
    { name: 'Linkedin', title: 'Linkedin Post', icon: <LinkedinColor />, optionAt: 0 },
    { name: 'Blog', title: 'Blog', icon: <BlogColor />, optionAt: 0 },
    { name: 'Instagram', title: 'Instagram Post', icon: <InstagramColor />, optionAt: 0 },
    { name: 'Pinterest', title: 'Pinterest Post', icon: <PinterestColor />, optionAt: 0 },
    { name: 'Facebook', title: 'Facebook Post', icon: <FacebookColor />, optionAt: 0 },
]

const SelectTextContentType = (props) => {

    const dispatch = useDispatch();
    const lastSelectedBrandId = useAppSelector((k) => k.persisted.lastSelectedBrandId);
    const newContent = useAppSelector((k) => k.content.newContent);
    const [step, setStep] = useState(0)

    const [editingAudience, setEditingAudience] = useState<AudienceResponse>()
    const [logoSelectionList, setLogoSelectionList] = useState<FileProps[]>([]);
    const [productSelectionList, setProductSelectionList] = useState<FileProps[]>([]);
    const [recentlyAddedAudience, setRecentlyAddedAudience] = useState<AudienceResponse[]>([])
    const [getBrand, { data: brand, isLoading: brandLoading }] = useLazyFindById4Query()
    const [contentCreationDataID, setContentCreationDataID] = useState('')
    const [createdAIText, setCreatedAIText] = useState<any>()

    useEffect(() => {
        if (newContent) {
            getBrand({ id: newContent?.brand?.id ?? "" })
        }
    }, [newContent?.brand?.id]);

    useEffect(() => {
        if (brand?.logo_file_ids && logoSelectionList.length === 0) {
            setLogoSelectionList(brand.logo_file_ids)
        }
    }, [brand])

    useEffect(() => {
        if (brand?.product_file_ids && productSelectionList.length === 0) {
            setProductSelectionList(brand.product_file_ids)
        }
    }, [brand])

    useEffect(() => {
        if (contentCreationDataID.length) {
            setStep(2)
        }
    }, [contentCreationDataID]);

    useEffect(() => {
        if (createdAIText) {
            props.setGeneratedAIText(createdAIText)
            //props.closeSideModal()
        }
    }, [createdAIText]);


    return (
        <div className={'w-full h-full mt-3'}>
            {step === 0 && channels.map(channel => (
                <Button className={`w-full text-start mt-1 flex flex-row items-center`}
                    icon={channel.icon}
                    onClick={() => {
                        dispatch(
                            setNewContent({
                                ...newContent,
                                contentType: ContentType.text,
                                wizardView: ContentWizardView.contentDetails,
                                medium: Mediums.find(
                                    (k) =>
                                        k.name === channel.name && k.contentType === ContentType.text
                                ),
                                mediumOption: Mediums.find(
                                    (k) =>
                                        k.name === channel.name && k.contentType === ContentType.text
                                )?.options?.at(channel.optionAt),
                                loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
                                brand: { id: lastSelectedBrandId },
                                isCreative: false,
                            } as INewContent)
                        );
                        setStep(1)
                    }}>
                    {channel.title}
                </Button>
            ))}
            {step === 1 && <ContentDetails
                isInSide
                setContentCreationDataID={setContentCreationDataID}
                logoSelectionList={logoSelectionList}
                productSelectionList={productSelectionList}
                onAudienceEdit={(data: AudienceResponse) => {
                    setEditingAudience(data);
                    dispatch(
                        setNewContent({
                            ...newContent,
                            wizardView: ContentWizardView.audience,
                        } as INewContent)
                    );
                }}
                onAudienceCreate={() => {
                    setEditingAudience(undefined);
                    dispatch(
                        setNewContent({
                            ...newContent,
                            wizardView: ContentWizardView.audience,
                        } as INewContent)
                    );
                }}
                recentlyAddedAudience={recentlyAddedAudience}
                onCreditError={props.onCreditError}
            />}
            {step === 2 && <LoopDetails
                isInSide
                newLoopCreated
                contentCreationDataID={contentCreationDataID}
                moveContent={setCreatedAIText}
            />}
        </div>
    )
}

export default SelectTextContentType;
