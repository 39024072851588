import * as React from "react";
const SvgTritoneWhatsYourContentAbout = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <rect width={28} height={28} fill="#F55" opacity={0.1} rx={4} />
    <path
      fill="#F55"
      d="M12.162 18H9.333a.667.667 0 0 1-.667-.667v-2.828c0-.177.07-.347.196-.472l6.506-6.504a.667.667 0 0 1 .943 0l2.827 2.826a.666.666 0 0 1 0 .943l-6.505 6.507a.667.667 0 0 1-.471.195Z"
      opacity={0.5}
    />
    <path
      fill="#F55"
      d="M16.311 7.529a.667.667 0 0 0-.942 0l-2.36 2.359 3.77 3.77.003-.003 2.356-2.357a.667.667 0 0 0 0-.943L16.311 7.53Zm3.69 13.138H8a.667.667 0 0 1 0-1.334h12a.667.667 0 0 1 0 1.334Z"
    />
  </svg>
);
export default SvgTritoneWhatsYourContentAbout;
