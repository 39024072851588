import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContentContainer from "../../../../components/ContentContainer";
import PrimaryButton from "../../../../components/PrimaryButton";
import { BrandRequest } from "../../../../services/BrandApi";
import LoopDetailBrandSelectorItem from "./LoopDetailBrandSelectorItem";
import { useAppDispatch } from "../../../../helpers/hooks";
import { setNewBrandInfo } from "../../../../store/brandSlice";


export interface ILoopDetailBrandSelectorPopupProps {
    data: BrandRequest[]
    closePopup: () => void
    selectedBrand: BrandRequest | undefined
    selectedBrandChanged: (data: BrandRequest | undefined) => void
    onBrandEdit: (data: BrandRequest | undefined) => void
}

function LoopDetailBrandSelectorPopup(props: ILoopDetailBrandSelectorPopupProps) {
    const [selectedItem, setSelectedItem] = useState<BrandRequest>()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        setSelectedItem(props.selectedBrand)
    }, [props.selectedBrand])


    return (
        <ContentContainer
            hoverable={false}
            className="w-[400px] h-fit  overflow-hidden"
            children={
                <>
                    {/*Body*/}
                    <div className="p-[12px] gap-[12px] h-full max-h-[170px] overflow-scroll scrollbar-hide">
                        <div className="flex flex-col gap-[5px]">
                            {
                                props.data.map(k => (
                                    <LoopDetailBrandSelectorItem
                                        data={k}
                                        selected={selectedItem?.id === k.id}
                                        itemSelected={(e) => {
                                            props.selectedBrandChanged(e)
                                        }}
                                        onItemEdit={
                                            (e) => {
                                                props.onBrandEdit(e)
                                            }
                                        }
                                    />
                                ))
                            }
                        </div>
                        <PrimaryButton
                            className="w-full h-[40px] mt-2"
                            title={t('add-new-brand')}
                            onClick={() => {
                                dispatch(setNewBrandInfo({}))
                                navigate("/app/brandhub/new")
                            }} />
                    </div>

                </>
            } />
    );
}

export default LoopDetailBrandSelectorPopup;