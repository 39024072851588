import { FunctionComponent, LegacyRef, useCallback, useEffect, useRef, useState } from "react";
import { Add } from "../../assets/IconComponents";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { clickOutside } from "../../helpers/ClickOutside";
import { useClickAway } from "@uidotdev/usehooks";
import PrimaryButton from "../PrimaryButton";
import { Delete } from "../../assets/IconComponents";

interface ColorPickerProps {
    className?: string
    colorSelected: (color: string | undefined) => void
    selectedColor?: string | undefined
    isDisabled?: boolean
    popoverClassName?: string
    hideDeleteButton?: boolean
}

const ColorPicker: FunctionComponent<ColorPickerProps> = (props: ColorPickerProps) => {
    let popover: HTMLDivElement;
    const [isOpen, toggle] = useState(false);
    const [color, setColor] = useState<string | undefined>();
    const [colorSelected, setColorSelected] = useState(false);
    const close = useCallback(() => toggle(false), []);

    useEffect(() => {
        if (props.selectedColor && props.selectedColor !== "") {
            setColor(props.selectedColor!)
            setColorSelected(true)
        }
    }, [props.selectedColor])

    useEffect(() => {
        props.colorSelected(color)
    }, [colorSelected])

    useEffect(() => {
        if (props.isDisabled) {
            close()
        }
    }, [props.isDisabled])

    const ref = useClickAway(() => {
        close()
    });

    useEffect(() => {
        clickOutside(popover, () => {
            close()
        })
    }, [])

    return (
        <div
            style={{ backgroundColor: color }}
            className={` flex border-1 border-border rounded-[10px] justify-center items-center ${props.className}`}
            onClick={() => {
                !props.isDisabled && toggle(true);
            }}
        >
            {(!isOpen && colorSelected && !props.hideDeleteButton) && (
                <div className="flex flex-row h-10 -right-1 -top-1 ml-4">
                    <PrimaryButton
                        icon={<Delete fill={"#121315"} />}
                        noFill
                        className=" ml-[3px] h-[20px] w-[20px] bg-bg border-1 border-border "
                        isDisabled={false}
                        tooltip="Delete Color"
                        onClick={() => {
                            setColor(undefined);
                            setColorSelected(false);
                            props.colorSelected(undefined);
                        }}
                    />
                </div>
            )}
            {!colorSelected && <Add fill="#000" width={20} height={20} />}
            {isOpen && (
                <div
                    className={`popover border-1 border-border p-[15px] rounded-[10px] bg-white z-50 ${props.popoverClassName}`}
                    ref={ref as LegacyRef<HTMLDivElement>}
                >
                    <HexColorPicker
                        color={color}
                        onChange={(e) => {
                            setColor(e);
                            setColorSelected(true);
                            props.colorSelected(e);
                        }}
                    />
                    <HexColorInput
                        className="w-full mt-[15px] text-black text-BodyMedium p-2 text-center rounded-md border-1 border-border"
                        color={color}
                        onChange={(e) => {
                            setColor(e);
                            setColorSelected(true);
                            props.colorSelected(e);
                        }}
                        prefixed
                    />
                </div>
            )}
        </div>
    );
}

export default ColorPicker;