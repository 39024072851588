import * as React from "react";
import type { SVGProps } from "react";
const SvgInspirations = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#inspirations_svg__a)">
      <path
        d="m7.6 12.575 1.252-2.723L11.575 8.6 8.852 7.348 7.6 4.625 6.348 7.348 3.625 8.6l2.723 1.252L7.6 12.575Zm0 3.625-2.375-5.225L0 8.6l5.225-2.375L7.6 1l2.375 5.225L15.2 8.6l-5.225 2.375L7.6 16.2Zm8.6 2.8-1.175-2.625L12.4 15.2l2.625-1.2 1.175-2.6 1.2 2.6 2.6 1.2-2.6 1.175L16.2 19Z"
      />
    </g>
    <defs>
      <clipPath id="inspirations_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgInspirations;
