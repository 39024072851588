import { default as React, useEffect, useState } from "react";
import PrimaryButton from "./PrimaryButton";
import { useCopyLoopMutation, useCopyRevisionMutation } from "../services/ContentApi.old/ContentApi";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import Icon from "./Icon";
import InputText from "./InputText";
import CheckBox from "./CheckBox";
import { refreshPage, updateMenu } from "../store/appSlice";

import { useAppDispatch } from "../helpers/hooks";
import moment from "moment";
import { Close } from "../assets/IconComponents";

export interface ICopyLoopProps {
    onClose: () => void
    className?: string
    contentId?: string
    onCopy: () => void
}
export interface ICopyContent {
    loopName?: string
    onlyThisRevision?: boolean
    fromInputtoCurrentRevision?: boolean

}

function CopyLoop(props: ICopyLoopProps) {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [copyLoop, { isSuccess: loopCopied, data: copiedLoopData, isLoading: isCopying }] = useCopyLoopMutation()


    const [copyContent, setCopyContent] = useState<ICopyContent>(
        {
            onlyThisRevision: true,
            fromInputtoCurrentRevision: false,
            loopName: `Loop_${moment().format("DD_MM_yyyy_hh_mm")}`,
        }
    )

    useEffect(() => {
        if (loopCopied) {
            setTimeout(() => {
                dispatch(refreshPage())
                dispatch(updateMenu())
                navigate(`../loopdetails/`+ copiedLoopData?.id?.content_id ?? "")
                props.onCopy();
            }, 1000);
        }
    }, [loopCopied])

    const doCopyAction = () => {
        copyLoop({
            content_name: copyContent?.loopName ?? "",
            content_id: props.contentId ?? ""
        })
    }

    return (
        <div className={`fixed left-0 rounded-xl right-0 top-0 bottom-0 bg-black/30 backdrop-blur-sm z-50 flex items-center justify-end ${props.className}`}
            onClick={(e) => { e.stopPropagation(); props.onClose(); }}>
            <div className="w-full  rounded-xl bg-white h-full flex flex-col" onClick={(e) => { e.stopPropagation() }}>
                <div className="h-[62px] w-full border-b-1 border-border flex items-center">
                    <div className="h-[62px] w-[62px] border-border border-r-1 flex items-center justify-center"
                        onClick={() => { props.onClose() }}>
                        <Close width={20} height={20} fill="#999999" />
                    </div>
                    <text className="text-H6 text-black ml-5">{t('copy-loop')}</text>
                </div>
                <div className="max-h-full w-full overflow-y-scroll scrollbar-hide flex-1 p-5">
                    <div className="mt-1 flex flex-1 flex-row justify-stretch">
                        <InputText
                            title={t('loop-name') ?? ""}
                            placeholder={t('loop-name-optional') ?? ""}
                            onChange={(e) => {
                                setCopyContent({
                                    ...copyContent,
                                    loopName: e
                                } as ICopyContent)

                            }}
                        />
                    </div>
                </div>
                <div className="h-[70px] w-full border-t-1 border-border flex items-center justify-end pr-5">
                    <PrimaryButton
                        noFill
                        className="w-32 h-11 mr-2"
                        isDisabled={isCopying || loopCopied}
                        title={t('cancel')}
                        onClick={() => props.onClose()} />
                    <PrimaryButton
                        className="w-32 h-11"
                        title={t('copy')}
                        isDisabled={isCopying || loopCopied}
                        onClick={() => {
                            doCopyAction()
                        }} />
                </div>
            </div>
        </div>
    );
}

export default CopyLoop;
