import * as React from "react";
const EngagementIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={19}
        fill="none"
        {...props}
    >
        <path opacity="0.5"
              d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 13.6 2.376 15.112 3.043 16.453C3.221 16.809 3.28 17.216 3.177 17.601L2.582 19.827C2.52307 20.0473 2.52312 20.2792 2.58216 20.4995C2.64119 20.7197 2.75712 20.9206 2.91831 21.0819C3.07951 21.2432 3.2803 21.3593 3.50053 21.4184C3.72075 21.4776 3.95267 21.4778 4.173 21.419L6.399 20.823C6.78541 20.7258 7.19403 20.7731 7.548 20.956C8.93092 21.6446 10.4551 22.0021 12 22Z"
              fill={props.fill ?? "#FFFFFF"}/>
        <path
            d="M7.5 11.11C7.5 12.478 8.819 13.914 10.029 14.943C10.852 15.643 11.264 15.993 12 15.993C12.736 15.993 13.148 15.643 13.971 14.943C15.181 13.913 16.5 12.478 16.5 11.109C16.5 8.43203 14.025 7.43303 12 9.50103C9.975 7.43303 7.5 8.43303 7.5 11.11Z"
            fill={props.fill ?? "#FFFFFF"}/>
    </svg>
);
export default EngagementIcon;
