import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import InputText from "../../../components/InputText";
import ToggleButton from "../../../components/ToggleButton";
import { clickOutside } from "../../../helpers/ClickOutside";
import { useAppDispatch } from "../../../helpers/hooks";
import { CouponResponse, SubscribeResponse, useLazyGetCouponQuery } from "../../../services/PaymentApi";
import { Plan } from "../../../services/PlanApi";
import { UserPaymentMethod } from "../../../services/UsertApi";
import { setDialog, toggleDialog } from "../../../store/appSlice";
import { PlanBenefit } from "../BenefitTable/PlanBenefitLine";
import CreditCardForm from "./CreditCardForm";
import ExistingCardForm from "./ExistingCardForm";



export interface ISubscriptionPopupProps {
    plan: Plan;
    skipTrial: boolean;
    applyDiscount: boolean;
    onClose: () => void;
    selectedPlanType: "yearly" | "monthly";
    onSubscriptionCompleted: (trialEndDate: Date) => void;
    onPlanUpdated: (effectiveAt?: string) => void;
    paymentMethod?: UserPaymentMethod;
    onUpdatePaymentMethod: () => void;
    zIndex?: string;
    mode: "update" | "subscription";
}

function SubscriptionPopupOpener(props: ISubscriptionPopupProps) {
    const dispatch = useAppDispatch();
    useEffect(() => {
        showSubscriptionDialog();
    }, []);
    const showSubscriptionDialog = () => {
        dispatch(
            setDialog({
                actions: <></>,
                title: "",
                message: "",
                customTemplate: (
                    <SubscriptionPopup
                        {...props}
                    >
                    </SubscriptionPopup>
                ),
                // customZIndex: props.zIndex ?? "z-50",
            }),
        );
        dispatch(toggleDialog(true));
    };

    return <></>;
}

function SubscriptionPopup(props: ISubscriptionPopupProps) {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? "");
    const [selectedPlanType, setSelectedPlanType] = useState<"yearly" | "monthly">("yearly");
    const [benefits, setBenefits] = useState<PlanBenefit[]>([]);
    const [subscriptionDetails, setSubscriptionDetails] = useState<SubscribeResponse>();
    const [trialEndDate, setTrialEndDate] = useState<Date>();
    const [updateCardMode, setUpdateCardMode] = useState<boolean>(false);
    const [price, setPrice] = useState<number>(0);
    const [promotionCode, setPromotionCode] = useState<string>("");
    const ref = useRef<HTMLDivElement>(null);
    const [getCoupon, { data: couponData, isError: couponNotValid, isSuccess: couponValid, isFetching: isCouponFetching }] = useLazyGetCouponQuery();
    const [promotionPrice, setPromotionPrice] = useState<number>(0);
    const [referral, setReferral] = useState<string>("")

    useEffect(() => {
        if (typeof window.Rewardful == 'undefined') {
            console.log('Rewardful not loaded')
        } else {
            console.log(`referral code ${window.Rewardful.referral}`)
            setReferral(window.Rewardful.referral);
        }
    }, [])

    useEffect(() => {
        setTrialEndDate(moment().add(7, "days").toDate());
    }, []);

    useEffect(() => {
        const benefits = JSON.parse(props.plan.benefits ?? "[]");
        setBenefits(benefits);
    }, [props.plan]);

    useEffect(() => {
        setSelectedPlanType(props.selectedPlanType);
    }, [props.selectedPlanType]);

    useEffect(() => {
        if (ref.current) {
            return clickOutside(ref.current, () => {
                props.onClose();
            });
        }
    }, [ref.current]);

    useEffect(() => {
        if (selectedPlanType == "monthly") {
            if (props.applyDiscount && promotionPrice < 0) {
                setPrice(Math.round(((props.plan.monthlyPrice ?? 0) * 0.8) * 10) / 10);
            } else {
                setPrice(props.plan.monthlyPrice ?? 0);
            }
        } else {
            if (props.applyDiscount && promotionPrice < 0) {
                setPrice(Math.round(((props.plan.yearlyPrice ?? 0) * 12 * 0.8) * 10) / 10);
            } else {
                setPrice((props.plan.yearlyPrice ?? 0) * 12);
            }
        }
    }, [props.applyDiscount, selectedPlanType, props.plan, promotionPrice]);

    useEffect(() => {
        getCoupon({ promotionCode });
    }, [promotionCode]);

    useEffect(() => {
        setPromotionPrice(-1)
    }, [couponNotValid])


    useEffect(() => {
        if (couponData && couponValid && !isCouponFetching) {

            if (selectedPlanType == "monthly") {
                if (couponData.percent_off) {
                    setPromotionPrice(Math.round(((props.plan.monthlyPrice ?? 0) * (1 - (couponData.percent_off / 100))) * 10) / 10);
                } else if (couponData.amount_off && couponData.amount_off > 0) {
                    setPromotionPrice(Math.round(((props.plan.monthlyPrice ?? 0) - couponData.amount_off) * 10) / 10);
                } else {
                    console.log(`unexpected coupon info ${couponData.id}`)
                }
            } else {
                if (couponData.percent_off && couponData.percent_off > 0) {
                    setPromotionPrice(Math.round(((props.plan.yearlyPrice ?? 0) * 12 * (1 - (couponData.percent_off / 100))) * 10) / 10);
                } else if (couponData.amount_off && couponData.amount_off > 0) {
                    setPromotionPrice(((props.plan.yearlyPrice ?? 0) - couponData.amount_off) * 12);
                }
                else {
                    console.log(`unexpected coupon info ${couponData.id}`)
                }
            }
        }
    }, [couponData, couponValid, isCouponFetching, selectedPlanType, props.plan])


    return (
        <>
            <div className="fixed lg:top-0 left-0 right-0 bottom-0 z-50 flex flex-col items-center max-lg:justify-end max-lg:mb-1 justify-center h-screen" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div
                    ref={ref}
                    className="w-full mx-2 md:mx-0 md:w-2/5 flex flex-row justify-center h-3/5"
                >
                    <div className="self-stretch h-full p-5 bg-white rounded-tl-xl rounded-bl-xl border border-stone-200 flex-col justify-start items-center gap-10 inline-flex">
                        <div className="self-stretch h-full flex-col justify-start items-center gap-5 flex">
                            <div className="self-stretch h-[67px] flex-col justify-center items-start gap-5 flex">
                                <div className="self-stretch h-[25px] flex-col justify-center items-center gap-5 flex">
                                    <div className="self-stretch text-neutral-900 text-lg font-semibold font-['Inter Tight'] leading-[25.20px]">
                                        {!props.skipTrial ? `Start 7-day Free Trial` : `Start Your Plan`}
                                    </div>
                                </div>
                                <div className="justify-center items-center gap-2.5 inline-flex">
                                    <div className="text-center text-black text-base font-semibold font-['Inter Tight'] leading-snug">Monthly</div>
                                    <ToggleButton
                                        isChecked={props.selectedPlanType === "yearly"}
                                        handleCheckBoxChange={function (yearly: any): void {
                                            yearly ? setSelectedPlanType("yearly") : setSelectedPlanType("monthly");
                                        }}
                                        checkedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                                        checkedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition translate-x-full"
                                        uncheckedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
                                        uncheckedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition transition"
                                    />
                                    <div className="text-center text-black text-base font-semibold font-['Inter Tight'] leading-snug">Yearly</div>
                                    <div className="text-right text-red text-[10px] font-bold font-['Inter Tight'] leading-[14px] tracking-wide">
                                        2 MONTHS FREE
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch h-px bg-stone-200 border-1" />
                            <div className="w-full justify-start items-start gap-2.5 inline-flex">
                                <div className="grow shrink basis-0 self-stretch p-4 bg-white rounded-lg border border-stone-200 flex-col justify-between items-start inline-flex">
                                    <div
                                        className={`self-stretch ${(props.paymentMethod || props.skipTrial) ? `h-[150px]` : `h-[251px]`
                                            } flex-col justify-start items-start gap-5 flex`}
                                    >
                                        <div className="self-stretch h-[74px] flex-col justify-start items-start gap-5 flex">
                                            <div className="self-stretch h-[74px] flex-col justify-start items-start gap-2.5 flex">
                                                <div className="self-stretch justify-between items-start inline-flex">
                                                    <div className="text-neutral-900 text-sm font-semibold font-['Inter Tight'] leading-tight">
                                                        {`${props.plan.name} Plan`}
                                                    </div>
                                                </div>
                                                <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                                                    <div className="grow shrink basis-0 text-neutral-400 text-xs font-normal font-['Inter Tight'] leading-none">
                                                        Monthly Credits
                                                    </div>
                                                    <div className="grow shrink basis-0 text-right text-neutral-400 text-xs font-normal font-['Inter Tight'] leading-none">
                                                        {props.plan.credits ?? "Unlimited"}
                                                    </div>
                                                </div>
                                                <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
                                                    <div className="grow shrink basis-0 text-neutral-400 text-xs font-normal font-['Inter Tight'] leading-none">
                                                        Number of Brands
                                                    </div>
                                                    <div className="grow shrink basis-0 text-right text-neutral-400 text-xs font-normal font-['Inter Tight'] leading-none">
                                                        {props.plan.numberOfBrands ?? "Unlimited"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="self-stretch h-px bg-stone-200" />
                                        <div className="self-stretch h-[38px] flex-col justify-start items-start gap-2.5 flex">
                                            <div className="self-stretch justify-start items-start gap-0.5 inline-flex">
                                                <div className="w-[240.50px] h-[18px] text-neutral-900 text-[13px] font-semibold font-['Inter Tight'] leading-[18.20px]">
                                                    {`${selectedPlanType === "monthly" ? `Monthly` : `Yearly Total`} on ${moment(trialEndDate).format("D MMM, YYYY")
                                                        }`}
                                                </div>
                                                <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
                                                    <div className="w-[240.50px] h-[18px] text-right text-neutral-900 text-sm font-semibold font-['Inter Tight'] leading-tight">
                                                        {`$${price}`}
                                                    </div>
                                                    <div className="w-[240.50px] h-[18px] text-right text-neutral-400 text-xs font-normal font-['Inter Tight'] leading-none">
                                                        plus applicable tax
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {!props.paymentMethod && !props.skipTrial &&
                                            (
                                                <>
                                                    <div className="self-stretch h-px bg-stone-200" />
                                                    <div className="self-stretch h-[57px] flex-col justify-start items-start gap-2.5 flex">
                                                        <div className="self-stretch justify-start items-start gap-0.5 inline-flex">
                                                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-[5px] inline-flex">
                                                                <div className="w-[240.50px] h-[18px] text-red text-sm font-semibold font-['Inter Tight'] leading-tight">
                                                                    Total Today (7 Days Free)
                                                                </div>
                                                                <div className="self-stretch text-neutral-400 text-xs font-normal font-['Inter Tight'] leading-none">
                                                                    {`You won’t be charged for your free trial. On ${moment(trialEndDate).format("D MMM, YYYY")
                                                                        }, your monthly subscription will start. Cancel anytime before then.`}
                                                                </div>
                                                            </div>
                                                            <div className="text-red text-sm font-semibold font-['Inter Tight'] leading-tight">$0</div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch h-px bg-stone-200 border-1" />
                            <div className="self-stretch h-[70px] flex-col justify-start items-start gap-3 flex">
                                <InputText
                                    title={t("promotion-code") ?? ""}
                                    iconPosition="end"
                                    placeholder={t("enter-promotion-code") ?? ""}
                                    onChange={(e) => {
                                        setPromotionCode(e);
                                    }}
                                    sideBySide={false}
                                    innerClassName="w-1/2 rounded-[4px]"
                                />
                            </div>
                            <div className="self-stretch h-px bg-stone-200 border-1" />
                            {(promotionPrice > -1 && couponData && couponValid) && (
                                <div className="grow shrink basis-0 self-stretch p-4 bg-white rounded-lg border border-stone-200 flex-col justify-between items-start inline-flex">
                                    <div className="self-stretch h-[38px] flex-col justify-start items-start gap-2.5 flex">
                                        <div className="self-stretch justify-start items-start gap-0.5 inline-flex">
                                            <div className="w-[240.50px] h-[18px] text-red text-[13px] font-semibold font-['Inter Tight'] leading-[18.20px]">
                                                {`${selectedPlanType === "monthly" ? `Monthly` : `Yearly Total`} with ${couponData?.percent_off + "%" ?? couponData?.amount_off + "$"} off valid ${couponData?.duration_in_months} months`}
                                            </div>
                                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
                                                <div className="w-[240.50px] h-[18px] text-right text-neutral-900 text-sm font-semibold font-['Inter Tight'] leading-tight">
                                                    {`$${promotionPrice}`}
                                                </div>
                                                <div className="w-[240.50px] h-[18px] text-right text-neutral-400 text-xs font-normal font-['Inter Tight'] leading-none">
                                                    plus applicable tax
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            }
                        </div>
                    </div>
                    {(!props.paymentMethod || updateCardMode) &&
                        (
                            <Elements stripe={stripePromise ?? Promise.resolve(null)} options={{ mode: "subscription", currency: "usd", amount: 100 }}>
                                <CreditCardForm
                                    mode={props.mode}
                                    plan={props.plan}
                                    selectedPlanType={selectedPlanType}
                                    onSubscriptionCreated={(subscription) => {
                                        setSubscriptionDetails(subscription);
                                        props.onSubscriptionCompleted(trialEndDate ?? new Date());
                                    }}
                                    onPlanUpdated={props.onPlanUpdated}
                                    skipTrial={props.skipTrial}
                                    applyDiscount={props.applyDiscount}
                                    promotionCode={promotionCode}
                                    referral={referral}
                                />
                            </Elements>
                        )}
                    {(props.paymentMethod && !updateCardMode) &&
                        (
                            <ExistingCardForm
                                plan={props.plan}
                                selectedPlanType={selectedPlanType}
                                paymentMethod={props.paymentMethod}
                                onPlanUpdated={props.onPlanUpdated}
                                onUpdatePaymentMethod={() => setUpdateCardMode(true)}
                                promotionCode={promotionCode}
                                onSubscriptionCreated={function (subscription?: SubscribeResponse | undefined): void {
                                    setSubscriptionDetails(subscription);
                                    props.onSubscriptionCompleted(trialEndDate ?? new Date());
                                }}
                                mode={props.mode}
                                skipTrial={props.skipTrial}
                                applyDiscount={props.applyDiscount}
                            />
                        )}
                </div>
            </div>
        </>
    );
}
export default SubscriptionPopupOpener;
