import { FunctionComponent, createRef, useEffect, useRef, useState } from "react";
import { WithContext as ReactTags } from 'react-tag-input';
import "./style.css"
import { t } from "i18next";
import SvgRefreshIcon from "../../assets/IconComponents/RefreshIcon";
import { Keyword } from "../../services/KeywordApi";
import { BrandKeywords } from "../../services/BrandApi";
import { useAppDispatch, useAppSelector } from "../../helpers/hooks";
import { setNewBrandInfo } from '../../store/brandSlice';

interface TagSelectorProps {
    tagsSelected: (tags: BrandKeywords[]) => void
    // ketywords: BrandKeywords[]
    selectedTags?: BrandKeywords[]
    refreshKeywords: () => void
}


const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];


const TagSelector: FunctionComponent<TagSelectorProps> = (props: TagSelectorProps) => {
    const [selectedTags, setSelectedTags] = useState<BrandKeywords[]>([])
    const brandInfo = useAppSelector(k => k.brand.newBrand)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (props.selectedTags) {

            clearActive()
            let a = props.selectedTags.filter(k => k.source === "USER")
            let b = [...brandInfo.userEnteredKeywords ?? [], ...a]

            dispatch(setNewBrandInfo({
                ...brandInfo,
                userEnteredKeywords: b
            }))

            setSelectedTags(props.selectedTags ?? [])
            clearActive()
        }

    }, [props.selectedTags])

    useEffect(() => {
        if (brandInfo.selectedKeywords) {
            clearActive()
            const sTags = [...brandInfo.selectedKeywords, ...brandInfo.userEnteredKeywords ?? []]
            setSelectedTags(sTags)
            clearActive()
        }
    }, [brandInfo.selectedKeywords])


    const handleDelete = i => {
        const keywordToDelete = selectedTags[i]
        if (keywordToDelete.source === "USER") {
            dispatch(setNewBrandInfo({
                ...brandInfo,
                userEnteredKeywords: (brandInfo.userEnteredKeywords ?? []).filter(k => k.value != keywordToDelete.value)
            }))
        } else {
            dispatch(setNewBrandInfo({
                ...brandInfo,
                selectedKeywords: (brandInfo.selectedKeywords ?? []).filter(k => k.value != keywordToDelete.value)
            }))
        }

        const afterDeletion = selectedTags.filter(k => k.value !== keywordToDelete.value)
        setSelectedTags(afterDeletion);
        // props.tagsSelected(afterDeletion)
    };

    const clearActive = () => {
        let a = document.getElementsByClassName("tag-wrapper ReactTags__tag active") as HTMLCollection

        for (let i = 0; i < a.length; i++) {
            const element = a[i];
            element.className = "tag-wrapper ReactTags__tag"
        }
    }

    const handleAddition = tag => {
        const sTags = [...selectedTags, { source: "USER", value: tag.text }]
        setSelectedTags(sTags);
        dispatch(setNewBrandInfo({
            ...brandInfo,
            userEnteredKeywords: [...(brandInfo.userEnteredKeywords ?? []), { source: "USER", value: tag.text }]
        }))
        // props.tagsSelected(sTags)
    };

    useEffect(() => {
        if (selectedTags)
            setTimeout(() => {
                clearActive()
                let a = document.getElementsByClassName("tag-wrapper ReactTags__tag") as HTMLCollection
                for (let i = 0; i < a.length; i++) {
                    const element = a.item(i);
                    const title = element?.textContent?.slice(0, -1)

                    const tagToControl = selectedTags.find(k => (k.value === title))
                    if (tagToControl) {
                        if (tagToControl.source === "USER")
                            element?.setAttribute("class", element.getAttribute("class") + " active")
                    }
                }
            }, 100);
    }, [selectedTags])

    return (
      <div className="mt-[20px]">
        <div className="flex flex-row justify-between  justify-start">
          <p className="text-H8 mt-2 text-black">{t("related-keywords")}</p>
          <div className="border-1 border-border rounded-md  pt-[5px]  mt-1 w-3/4 h-full">
            <div className="pb-[60px] px-[5px]">
              <ReactTags
                maxLength="50"
                tags={selectedTags.map((k) => ({ id: k.value, text: k.value }))}
                allowDragDrop={false}
                delimiters={delimiters}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                inputFieldPosition="inline"
                autofocus={false}
                placeholder="Add Keyword"
                handleInputBlur={(e) => {
                  if (e != "") handleAddition({ text: e });
                }}
              />
            </div>

            <div className="flex flex-col w-full border-t-1 py-2 pr-2 items-end ">
              <button
                className="flex flex-row"
                onClick={() => {
                  props.refreshKeywords();
                }}
              >
                <SvgRefreshIcon />
                <text className="ml-2 text-H8 text-red">{t("refresh")}</text>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
}

export default TagSelector;