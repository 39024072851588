import { FunctionComponent, useState } from "react";
import { Delete, Refresh } from "../../../assets/IconComponents";
import SvgArrowUp from "../../../assets/IconComponents/ArrowUp";
import SvgRenewIcon from "../../../assets/IconComponents/RenewIcon";
import IconButton from "../../../components/IconButton";
import { Revision } from "../../../services/RevisionApi";
import ContentGroupItem from "./ContentGroupItem";
import ContentGroupImageItem from "./ContentGroupImageItem";
import { t } from "i18next";
import { Content } from "../../../services/ContentApi";
import * as Scroll from "react-scroll";
import { useAppSelector } from "../../../helpers/hooks";
import Icon from "../../../components/Icon";
import SvgMoreIcon from "../../../assets/IconComponents/MoreIcon";
import ActionSheet from "../../../components/ActionSheet/ActionSheet";
import PrimaryButton from "../../../components/PrimaryButton";
import { useWindowSize } from "@uidotdev/usehooks";
import { useEffect } from "react";
import { LoopDetailChannel } from "../LoopDetailsHeader/Channel/LoopDetailChannels";

interface ContentGroupProps {
  content: Content;
  isRenewable: boolean;
  revisions: Revision[];
  type: string;
  generateSimilar: (revision: Revision, optionalArg?: any) => void;
  deleteRevisionGroup: (groupId: number) => void;
  deleteRevisionItem: (revision: Revision) => void;
  refreshRevisionGroup: (groupId: number) => void;
  updatePage: () => void;
  incrementDownloadedCount: () => void;
  className?: string;
  imageContentVisible: boolean;
  isInSide?: boolean;
  moveContent?: (param: any) => void;
  setSelectedImageItems?: (param: any) => void;
  selectedImageItems?: any[];
  loopChannels?: LoopDetailChannel[];
}

const ContentGroup: FunctionComponent<ContentGroupProps> = (
  props: ContentGroupProps
) => {
  const [collapsed, setCollapsed] = useState(false);
  const [groupOptionsOpened, setGroupOptionsOpened] = useState(false)

  var Element = Scroll.Element;
  var Events = Scroll.Events;
  const isContentLoading = useAppSelector(k => k.content.isContentLoading)
  const size = useWindowSize();


  const blinkItem = (id: string) => {
    activateItem(id);
    setTimeout(() => {
      deactivateItem(id);
      setTimeout(() => {
        activateItem(id);
        setTimeout(() => {
          deactivateItem(id);
          setTimeout(() => {
            activateItem(id);
            setTimeout(() => {
              deactivateItem(id);
            }, 500);
          }, 500);
        }, 500);
      }, 500);
    }, 500);
  };

  const activateItem = (id: string) => {
    let revisionItemClass = `revision_item_${id}`;
    let a = document.getElementsByClassName(
      revisionItemClass
    ) as HTMLCollection;
    if (a.length > 0) {
      let f = a[0];
      f.setAttribute(
        "class",
        f.getAttribute("class") + " !border-acti drop-shadow-activeContainer"
      );
    }
  };

  //console.log('props.revisions ',props.revisions)

  const deactivateItem = (id: string) => {
    let revisionItemClass = `revision_item_${id}`;
    let a = document.getElementsByClassName(
      `${revisionItemClass} !border-acti`
    ) as HTMLCollection;
    const element = a[0];
    element?.classList.remove("!border-acti")
    element?.classList.remove("drop-shadow-activeContainer");
  };

  return (
    <Element name={`Revision_${props.revisions[0].revision_group}`}>
      <div
        id={`Revision_${props.revisions[0].revision_group}`}
        className={`${!collapsed ? "h-fit" : "h-[37px]"} w-full ${props.isInSide == true ? "" : "mt-[40px]"} ${props.className
          }`}
      >
        {!props.isInSide && <div
            className="h-[37px] w-[100%-30px] flex flex-row border-b-1 border-border pb-[12px] items-center justify-between mx-[15px]">
          <div className="flex flex-col md:flex-row md:items-center">
            {props.revisions[0].type !== "loading" && (
                <>
                  <p className="text-H7 text-black">
                    Revision {props.revisions[0].revision_group}
                  </p>
                  {props.revisions[0].id?.revision_id !==
                      props.revisions[0].revision_parent_id && (
                          <div className="flex flex-row">
                            <p className="text-BodyMedium text-gray md:ml-[15px]">
                              Generated from
                            </p>
                            <p
                                className="text-BodyMedium text-gray underline  cursor-pointer ml-[4px]  rounded-[4px]"
                                onClick={() => {
                                  let target = props.revisions[0].generatedFrom
                                      ?.split("-")[0]
                                      .replaceAll(" ", "_");
                                  Scroll.scroller.scrollTo(target, {
                                    smooth: true,
                                    containerId: "revisionGroupsContainer",
                                  });
                                  Events.scrollEvent.register(
                                      "end",
                                      function (to, element) {
                                        blinkItem(
                                            props.revisions[0].revision_parent_id ?? ""
                                        );
                                      }
                                  );
                                }}
                            >
                              {props.revisions[0].generatedFrom?.replace("-null", "")}
                            </p>
                          </div>
                      )}
                </>
            )}
            {props.revisions[0].type === "loading" && (
                <>
                  <p className="text-H7 text-black">{t("loading")}</p>
                </>
            )}
          </div>
          {props.revisions[0].type !== "loading" && (
              <>
                <div className="hidden lg:flex flex-row">
                  {props.content.read_only === false && !isContentLoading && (
                      <IconButton
                          className="mr-2"
                          icon={Delete}
                          onClick={() => {
                            props.deleteRevisionGroup(
                                props.revisions[0].revision_group ?? -1
                            );
                          }}
                          title={""}
                      />
                  )}

                  {props.content.read_only === false &&
                      props.isRenewable &&
                      !isContentLoading && (
                          <IconButton
                              icon={Refresh}
                              title={""}
                              onClick={() => {
                                props.refreshRevisionGroup(
                                    props.revisions[0].revision_group ?? -1
                                );
                              }}
                          />
                      )}
                  <SvgArrowUp
                      onClick={() => {
                        setCollapsed(!collapsed);
                      }}
                      fill="#999999"
                      width={22}
                      height={22}
                      className={`ml-2 transition-all transform-gpu ${collapsed ? "rotate-180" : "rotate-0"
                      }`}
                  />
                </div>
                {size.width && size.width < 1024 && (
                    <ActionSheet
                        isExpanded={groupOptionsOpened}
                        setIsExpanded={(status) => {
                          setGroupOptionsOpened(status);
                        }}
                        title={`Revision ${props.revisions[0].revision_group}`}
                        triggerView={
                          <button
                              className={`lg:hidden p-2 pr-0 rounded-lg flex flex-row items-center`}
                              onClick={() => {
                              }}
                          >
                            <div className="rounded-sm">
                              <Icon
                                  component={SvgMoreIcon}
                                  viewBox="0 0 20 20"
                                  className="w-[20px]"
                              />
                            </div>
                          </button>
                        }
                        body={
                          <div className="w-full p-[15px] flex flex-col">
                            <PrimaryButton
                                icon={
                                  <SvgArrowUp
                                      fill="#000"
                                      width={22}
                                      height={22}
                                      className={`ml-2 transition-all transform-gpu ${collapsed ? "rotate-180" : "rotate-0"
                                      }`}
                                  />
                                }
                                className="h-[41px]"
                                noFill
                                title={collapsed ? "Show Revision" : "Hide Revision"}
                                onClick={() => {
                                  setCollapsed(!collapsed);
                                  setGroupOptionsOpened(false);
                                }}
                            />
                            {props.isRenewable && (
                                <PrimaryButton
                                    icon={<Refresh fill="#000"/>}
                                    className="h-[41px] mt-2"
                                    noFill
                                    title="Refresh Revision"
                                    onClick={() => {
                                      setGroupOptionsOpened(false);
                                      props.refreshRevisionGroup(
                                          props.revisions[0].revision_group ?? -1
                                      );
                                    }}
                                />
                            )}
                            <PrimaryButton
                                icon={<Delete fill="#000"/>}
                                className="h-[41px] mt-2"
                                noFill
                                title="Delete Revision"
                                onClick={() => {
                                  setGroupOptionsOpened(false);
                                  props.deleteRevisionGroup(
                                      props.revisions[0].revision_group ?? -1
                                  );
                                }}
                            />
                          </div>
                        }
                    />
                )}
              </>
          )}
        </div>}
        {!collapsed && (
            <>
              <div
                  className={`grid ${props.type === "text"
                      ? "grid-cols-1 md:grid-cols-2"
                      : `grid-cols-1 ${props.isInSide ? 'md:grid-cols-1' : 'md:grid-cols-3'} `
                  } ${props.isInSide ? props.type === "text" ? 'sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1' : 'sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1' : ''} gap-[12px] pt-[15px]`}
              >
                {props.type === "text" && (
                    <>
                      {props.revisions.map((k) => (
                          <ContentGroupItem
                              loopChannels={props.loopChannels}
                              isInSide={props.isInSide}
                              moveContent={props.moveContent}
                              key={k.id?.revision_id}
                              revision={k}
                              generateSimilar={(revision) => {
                                props.generateSimilar(revision);
                              }}
                              deleteItem={() => {
                                props.deleteRevisionItem(k);
                              }}
                              content={props.content}
                              updatePage={props.updatePage}
                          />
                      ))}
                    </>
                )}
                {props.type === "image" && (
                    <>

                      {props.revisions.map((k) => (
                          <ContentGroupImageItem
                              isInSide={props.isInSide}
                              selectedImageItems={props.selectedImageItems}
                              setSelectedImageItems={props.setSelectedImageItems}
                              revision={k}
                              generateSimilar={(revision, optionalArg) => {
                                props.generateSimilar(revision, optionalArg);
                              }}
                              imageContentVisible={props.imageContentVisible}
                              incrementDownloadedCount={() =>
                                  props.incrementDownloadedCount()
                              }
                              deleteItem={() => {
                                props.deleteRevisionItem(k);
                              }}
                              content={props.content}
                              updatePage={props.updatePage}
                          />
                      ))}
                    </>
                )}
              </div>
            </>
        )}
      </div>
    </Element>
  );
};

export default ContentGroup;
